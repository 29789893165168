import * as React from 'react'

import {Center, Flex, StackProps, Stack, Text, Heading} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {HOME} from '@/router/paths'

type Props = StackProps & {
  onClose: () => void
}

const ConfirmEmail = ({onClose, ...rest}: Props) => {
  return (
    <Stack {...rest} direction="column" alignItems="center" spacing={8}>
      <Heading size="md">Prawie gotowe!</Heading>
      <Text textAlign="center" fontSize="md" maxWidth="550px">
        Na podany adres e-mail została wysłana wiadomość z linkiem aktywacyjnym. Przejdź do poczty i potwierdź
        swój e-mail aby dokończyć rejestrację.
      </Text>
      <ArrowButton
        as={Link}
        to={HOME}
        onClick={onClose}
        bg="brand.yellow.600"
        hoverBg="brand.yellow.700"
        color="white"
        height={40}
        fontSize="lg"
        fontWeight={500}
      >
        Strona główna
      </ArrowButton>
    </Stack>
  )
}

export default ConfirmEmail
