import {
  REACT_APP_GOOGLE_ADMIN_AUTH_REDIRECT_URI,
  REACT_APP_GOOGLE_AUTH_REDIRECT_URI,
  REACT_APP_GOOGLE_CLIENT_ID,
} from './constants'

const authURL = 'https://accounts.google.com/o/oauth2/auth'

export const generateGoogleAuthURL = (admin?: boolean) => {
  const url = new URL(authURL)
  url.searchParams.append('prompt', 'select_account consent')
  url.searchParams.append('access_type', 'offline')
  url.searchParams.append('client_id', REACT_APP_GOOGLE_CLIENT_ID)
  url.searchParams.append(
    'redirect_uri',
    admin ? REACT_APP_GOOGLE_ADMIN_AUTH_REDIRECT_URI : REACT_APP_GOOGLE_AUTH_REDIRECT_URI
  )
  url.searchParams.append('response_type', 'code')
  url.searchParams.append('scope', 'https://www.googleapis.com/auth/calendar')
  url.searchParams.append('state', 'state-token')
  return url.toString()
}
