import React from 'react'

import {AddIcon} from '@chakra-ui/icons'
import {Box, Grid, GridItem, Heading, IconButton, SkeletonText, Stack, useDisclosure} from '@chakra-ui/react'

import {selectIsAdmin} from '@/auth/state'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'
import {useAppSelector} from '@/store'

import CreateBlogPostSuggestionModal from './create-blog-post-suggestion-modal'
import OtherBlogPostItem from './other-blog-post-item'
import {RelatedItem} from './related-item'

const OtherBlogPosts = ({editing, forBlogPost}: {editing: boolean; forBlogPost: number}) => {
  const isAdmin = useAppSelector(selectIsAdmin)

  const {loading, data, fetch} = useSupabaseQuery<RelatedItem>(
    React.useMemo(
      () => ({
        fields: '*',
        table: 'blog_post_suggested_blogs_view',
        match: {blog_post_id: forBlogPost},
        errSnackbarTitle: 'Nie udało się pobrać sugestii postu',
      }),
      [forBlogPost]
    )
  )

  const {
    isOpen: isAddSuggestionOpen,
    onOpen: onAddSuggestionOpen,
    onClose: onAddSuggestionClose,
  } = useDisclosure()

  const {
    onClose: onDeleteDialogClose,
    onOpen: onDeleteDialogOpen,
    value: deleteDialogValue,
  } = useValueDisclosure<string>()

  if (!loading && !data?.length && !isAdmin) {
    return null
  }

  return (
    <>
      <DeleteResourceModal
        table="blog_post_suggested_blogs"
        id={deleteDialogValue ?? ''}
        onClose={onDeleteDialogClose}
        open={!!deleteDialogValue}
        onComplete={fetch}
      />
      <CreateBlogPostSuggestionModal
        blogPost={forBlogPost}
        open={isAddSuggestionOpen}
        onClose={onAddSuggestionClose}
        onComplete={fetch}
      />
      <Stack
        direction={['column', null, 'row']}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={[8, null, 40]}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <Box
            position="absolute"
            left="0"
            width="100%"
            height={`${36 + 105}px`}
            top="auto"
            backgroundColor="brand.green.600"
            zIndex={-5}
            mt={[-4, null, 0]}
          />
          <Heading size="lg" color="white">
            To też może Cię <Box as="br" display={['none', null, 'inline']} />
            zainteresować
          </Heading>
          {isAdmin && editing && (data?.length || 0) < 2 && (
            <IconButton
              onClick={onAddSuggestionOpen}
              icon={<AddIcon />}
              aria-label="Add suggestion"
              colorScheme="green"
            />
          )}
        </Stack>

        <SkeletonText noOfLines={10} isLoaded={!loading} flex={1}>
          <Grid templateColumns="repeat(2, 1fr)" gap={[4, null, 8]} mt={[-4, null, 0]} mb={[0, null, 8]}>
            {data?.slice(0, 2).map((blogPost, index) => (
              <GridItem key={index}>
                <OtherBlogPostItem editing={editing} item={blogPost} onDelete={onDeleteDialogOpen} />
              </GridItem>
            ))}
          </Grid>
        </SkeletonText>
      </Stack>
    </>
  )
}

export default OtherBlogPosts
