import * as React from 'react'

import {Box, useBreakpointValue, useToken} from '@chakra-ui/react'
import {CSSEffect} from 'parallax-controller'
import {Parallax} from 'react-scroll-parallax'

import {FeaturedReview} from '@/api/models'
import Reviews from '@/common/reviews'
import useSupabaseQuery from '@/common/use-custom-query'

export const FEATURED_REVIEWS_LIMIT = 4

const HomeReviews = () => {
  const [yellow300] = useToken('colors', ['brand.yellow.300'])
  const translateConfig = useBreakpointValue<CSSEffect>({
    base: [-60, 80],
    md: [0, 25],
  })

  const {data, loading} = useSupabaseQuery<FeaturedReview>({
    table: 'featured_reviews',
    fields: '*',
    itemsPerPage: FEATURED_REVIEWS_LIMIT,
    errSnackbarTitle: 'Nie udało się wczytać wyróżnionych opinii',
  })

  return (
    <Box position="relative">
      <Box
        left="0"
        top="20%"
        width="100%"
        height="100%"
        position="absolute"
        zIndex={-1}
        overflow={['visible', null, 'hidden']}
      >
        <Parallax
          style={{
            backgroundColor: yellow300,
            height: '40%',
          }}
          translateY={translateConfig}
        />
      </Box>
      <Reviews
        title={
          <>
            Dlaczego <Box as="br" display={['none', null, 'inline']} />
            warto?
          </>
        }
        reviews={data ?? []}
        loading={loading}
        px={4}
      />
    </Box>
  )
}

export default HomeReviews
