import React from 'react'

import {Box, Button, Flex, FlexProps, FormLabel, Input, Stack, Text, useToast} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom'

import {supabase} from '@/api'
import {selectRedirectPath} from '@/auth/state'
import {ArrowButton} from '@/common/arrow-button'
import useLoadingState from '@/common/use-loading-state'
import {useAppSelector} from '@/store'

import Social from '../social'

export type Props = FlexProps & {
  onComplete?: () => void
  switchToSignUp?: () => void
  switchToResetPassword?: () => void
}

const SignInForm = ({switchToSignUp, switchToResetPassword, onComplete, ...rest}: Props) => {
  const toast = useToast()
  const history = useHistory()

  const redirectPath = useAppSelector(selectRedirectPath)

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const handleEmailChange = React.useCallback(({target: {value}}) => setEmail(value), [setEmail])
  const handlePasswordChange = React.useCallback(({target: {value}}) => setPassword(value), [setPassword])

  const _handleSubmit = React.useCallback(async () => {
    if (!email || !password) return

    try {
      const session = await supabase.auth.signInWithPassword({email, password})
      if (session.error) throw new Error(session.error.message)

      onComplete && onComplete()

      if (redirectPath && redirectPath !== window.location.pathname + window.location.search) {
        history.push(redirectPath)
      }
    } catch (e) {
      toast({isClosable: true, status: 'error', title: 'Nieprawidłowy adres e-mail lub hasło'})
    }
  }, [email, password, toast, redirectPath, onComplete, history])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)
  const handleFormSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      handleSubmit()
    },
    [handleSubmit]
  )

  return (
    <Flex
      as="form"
      direction="column"
      alignItems="center"
      justifyContent="center"
      onSubmit={handleFormSubmit}
      {...rest}
    >
      <Text fontSize="lg" fontWeight={700} mb={8}>
        Zaloguj się
      </Text>
      <Stack direction="column" width="100%" mb={8}>
        <FormLabel htmlFor="email" fontSize="sm" fontWeight={300} mb={0}>
          Wpisz swój e-mail
        </FormLabel>
        <Input
          name="email"
          type="email"
          placeholder="przyklad@email.com"
          size="lg"
          autoFocus={true}
          onChange={handleEmailChange}
          value={email}
        />
      </Stack>
      <Stack direction="column" width="100%" mb={8}>
        <FormLabel htmlFor="password" fontSize="sm" fontWeight={300} mb={0}>
          Hasło
        </FormLabel>
        <Input
          name="password"
          type="password"
          placeholder="Hasło"
          size="lg"
          onChange={handlePasswordChange}
          value={password}
        />
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%" mb={8}>
        {switchToResetPassword ? (
          <Button variant="brandLink" onClick={switchToResetPassword} display={['none', null, 'block']}>
            Nie pamiętasz hasła?
          </Button>
        ) : (
          <Box display={['none', null, 'block']} />
        )}

        <ArrowButton
          type="submit"
          bg="brand.yellow.600"
          hoverBg="brand.yellow.700"
          color="white"
          height={40}
          fontSize="lg"
          fontWeight={500}
          isDisabled={loading || email === '' || password === ''}
          isLoading={loading}
          width={['100%', null, 'auto']}
          marginLeft={['0px !important', null, 0]}
        >
          <Text px={8} fontSize="lg">
            Zaloguj się
          </Text>
        </ArrowButton>
      </Stack>
      {!!switchToResetPassword && (
        <Button
          variant="brandLink"
          onClick={switchToResetPassword}
          display={['block', null, 'none']}
          mb={8}
          width="100%"
          textAlign="left"
        >
          Nie pamiętasz hasła?
        </Button>
      )}
      <Social mb={switchToSignUp ? 16 : 0} />
      {switchToSignUp && (
        <Stack direction="row" spacing={2} justifyContent="center" width="100%">
          <Text fontSize="lg">Nie masz konta?</Text>
          <Button variant="brandLink" fontSize="lg" fontWeight={700} onClick={switchToSignUp}>
            Zarejestruj się
          </Button>
        </Stack>
      )}
    </Flex>
  )
}

export default SignInForm
