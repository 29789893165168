import React from 'react'

import {AccordionButton, AccordionItem, AccordionPanel, Box, Text} from '@chakra-ui/react'

import {ArrowIcon} from '@/common/arrow-button'

import QuestionsAndAnswersEditor from '../editor'
import {QuestionAnswerContent} from '../types'

type Props = {
  content: QuestionAnswerContent
  isLoading: boolean
}

const QuestionsAndAnswersItem = ({content: {answer, id, question}, isLoading}: Props) => (
  <AccordionItem borderColor="transparent" py={2}>
    {({isExpanded}) => (
      <>
        <AccordionButton mx={-2} px={2}>
          <ArrowIcon
            color="brand.yellow.600"
            fontSize="30px"
            transform={isExpanded ? 'rotate(90deg)' : undefined}
          />
          <Box as="h2" flex="1" textAlign="left" marginLeft={5} fontSize="2xl">
            {question}
          </Box>
        </AccordionButton>
        <AccordionPanel marginLeft={[0, null, 9]} pb={4}>
          {answer && (
            <Text fontWeight={300} fontSize={16}>
              <QuestionsAndAnswersEditor
                entryID={id || ''}
                loading={isLoading}
                readOnly={true}
                value={answer}
              />
            </Text>
          )}
        </AccordionPanel>
      </>
    )}
  </AccordionItem>
)

export default QuestionsAndAnswersItem
