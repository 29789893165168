import * as React from 'react'

import {UserOrderItem} from '@/api/models'

import CourseOrderFulfilled from './course'
import MeetingOrderFulfilled from './meeting'
import SupportGroupOrderFulfilled from './support-group'
import WebinarOrderFulfilled from './webinar'

const Fulfilled = ({item}: {item: UserOrderItem}) => {
  switch (item.type) {
    case 'course':
      return <CourseOrderFulfilled item={item} />
    case 'meeting':
      return <MeetingOrderFulfilled />
    case 'webinar':
      return <WebinarOrderFulfilled />
    case 'support_group':
      return <SupportGroupOrderFulfilled />
    default:
      return null
  }
}

export default Fulfilled
