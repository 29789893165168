import React from 'react'

import {
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  SkeletonText,
  Stack,
  Text,
} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {MdClose} from 'react-icons/md'
import {Link, generatePath, useHistory, useLocation, useParams} from 'react-router-dom'
import {Element, scroller} from 'react-scroll'

import {ArrowButton} from '@/common/arrow-button'
import ShowcaseDescription from '@/common/components/product-showcase/description'
import ScreenWideBar from '@/common/components/screen-wide-bar'
import LoadingView from '@/common/loading-view'
import NotFoundView from '@/common/not-found-view'
import StarRating from '@/common/reviews/star-rating'
import useMetaTags from '@/common/use-meta-tags'
import useRunOnReconnect from '@/common/use-run-on-reconnect'
import {PSYCHOTHERAPISTS, PSYCHOTHERAPY, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'

import {
  fetchTherapist,
  resetPsychotherapyState,
  selectOrderService,
  selectTherapist,
  selectTherapistLoading,
  startPsychotherapyOrder,
} from '../state'
import TherapistCertificates from './certificates'
import MeetingSelector from './meeting-selector'
import TherapistReviews from './reviews'

type Params = {
  id: string
  slug?: string
}

const TherapistShowcase = () => {
  const {id, slug} = useParams<Params>()
  const {replace} = useHistory()
  const {search} = useLocation()

  const dispatch = useAppDispatch()
  const therapist = useAppSelector(selectTherapist)
  const therapistLoading = useAppSelector(selectTherapistLoading)
  const service = useAppSelector(selectOrderService)

  const fetch = React.useCallback(() => {
    const searchParams = new URLSearchParams(search)
    const service = searchParams.get('service')
    if (!service) {
      dispatch(fetchTherapist(+id))
      return
    }
    const date = searchParams.get('date')

    dispatch(
      startPsychotherapyOrder({
        therapist: +id,
        service,
        date: date ? new Date(+date) : undefined,
        fetch: true,
      })
    )
    replace('?') // clear search params
  }, [id, search, replace, dispatch])

  React.useEffect(() => {
    fetch()

    return () => {
      dispatch(resetPsychotherapyState())
    }
  }, [fetch]) // eslint-disable-line

  useRunOnReconnect(fetch)

  useMetaTags({
    title: therapist?.full_name,
    description: therapist?.meta_description,
    'og:image': therapist?.signed_avatar,
  })

  React.useEffect(() => {
    if (therapist?.full_name_slug && therapist.full_name_slug !== slug) {
      replace(generatePath(PSYCHOTHERAPY_THERAPIST, {id, slug: therapist.full_name_slug}))
    }
  }, [therapist, slug]) // eslint-disable-line

  const handleScrollToSelector = React.useCallback(() => {
    scroller.scrollTo('therapist-services' as any, {
      duration: 500,
      offset: -200,
      smooth: true,
    })
  }, [])

  React.useEffect(() => {
    if (!therapist || !service) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `meeting:${therapist.id}:${service?.id}`,
              item_name: `Psychoterapeuta - ${therapist.full_name} - ${service?.name}`,
              value: (service?.price || 0) / 100,
            },
          ],
          value: (service?.price || 0) / 100,
        },
        event: 'view_item',
      },
    })
  }, [therapist, service])

  if (therapistLoading) return <LoadingView />

  if (!therapist?.id) return <NotFoundView />

  return (
    <Container
      maxW="container.xl"
      as={Stack}
      spacing={[8, null, 24]}
      mt={[8, null, 16]}
      mb={[8, null, 24]}
      px={[8, null, 4]}
    >
      <Stack spacing={[8, null, 16]}>
        <Breadcrumb fontWeight={300}>
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={PSYCHOTHERAPY}>
              Psychoterapia
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={PSYCHOTHERAPISTS}>
              Psychoterapeuci
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
            {therapist ? (
              <BreadcrumbLink
                as={Link}
                to={generatePath(PSYCHOTHERAPY_THERAPIST, {
                  id: therapist.id,
                  slug: therapist.full_name_slug,
                })}
              >
                {therapist.full_name}
              </BreadcrumbLink>
            ) : (
              <BreadcrumbLink>
                <SkeletonText w="100px" noOfLines={1} />
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        </Breadcrumb>

        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={[12, null, 3]} px={[4, null, 0]}>
            <AspectRatio ratio={1} borderRadius="0px 60px" overflow="hidden" maxW="200px">
              {therapist?.signed_avatar ? (
                <Image src={therapist.signed_avatar} objectFit="cover" />
              ) : (
                <Flex objectFit="cover" bg="gray.600" alignItems="center" justifyContent="center">
                  <MdClose size={64} />
                </Flex>
              )}
            </AspectRatio>
          </GridItem>
          <GridItem
            as={Stack}
            direction="column"
            colStart={[0, null, 5]}
            colSpan={[12, null, 8]}
            py={[8, null, 4]}
            spacing={8}
          >
            <ScreenWideBar py={8} bg="brand.yellow.300">
              <Stack
                w="100%"
                direction={['column', null, 'row']}
                align={['flex-start', null, 'center']}
                spacing={4}
              >
                <Text as="h1" flex={1} fontWeight={700} fontSize="xl">
                  {therapist?.full_name}
                </Text>
                {!!therapist?.rating && (
                  <HStack display={['flex', null, 'none']}>
                    <StarRating value={therapist.rating} />
                    <Text>ocena: {therapist.rating.toFixed(2)}</Text>
                  </HStack>
                )}
                <ArrowButton
                  bg="brand.green.600"
                  hoverBg="brand.green.700"
                  color="white"
                  onClick={handleScrollToSelector}
                  width={['100%', null, 'auto']}
                >
                  Umów wizytę
                </ArrowButton>
              </Stack>
            </ScreenWideBar>
            {!!therapist?.rating && (
              <HStack display={['none', null, 'flex']}>
                <StarRating value={therapist.rating} />
                <Text>ocena: {therapist.rating.toFixed(2)}</Text>
              </HStack>
            )}
            <Text fontSize="sm">{therapist?.specialties?.join(' | ') ?? 'Brak specjalizacji'}</Text>
            <ShowcaseDescription
              id="therapist-showcase"
              shortDescription={therapist?.short_description}
              fullDescription={therapist.full_description}
            />
          </GridItem>
        </Grid>
      </Stack>

      <TherapistCertificates therapist={+id} />

      <TherapistReviews therapist={+id} />

      <Box>
        <Element name="therapist-services" />
        <MeetingSelector />
      </Box>
    </Container>
  )
}

export default TherapistShowcase
