import * as React from 'react'

import {selectBillingInfo} from '@/auth/state'
import {useAppSelector} from '@/store'

export const useShippingAddress = () => {
  const addresses = useAppSelector(selectBillingInfo)
  const shippingAddress = React.useMemo(() => {
    const basic = addresses?.find(({type}) => type === 'basic')
    if (basic?.same_shipping_address) {
      return basic
    }
    return addresses?.find(({type}) => type === 'shipping')
  }, [addresses])

  return shippingAddress
}

export const useBillingAddress = () => {
  const addresses = useAppSelector(selectBillingInfo)
  const billingAddress = React.useMemo(() => {
    const invoice = addresses?.find(({type}) => type === 'invoice')
    if (invoice?.address) {
      return invoice
    }

    return addresses?.find(({type}) => type === 'basic')
  }, [addresses])

  return billingAddress
}
