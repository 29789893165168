import * as React from 'react'

import {Grid, GridItem, Spinner, Stack, Text} from '@chakra-ui/react'

import {MyMeeting} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'

import MeetingsHistory from './history'
import JoinableMeeting from './joinable-meeting'
import PlannedMeeting from './planned-meeting'
import {groupMeetings} from './utils'

const Meetings = () => {
  const {data, loading} = useSupabaseQuery<MyMeeting>(
    React.useMemo(
      () => ({
        table: 'my_meetings',
        fields: '*',
        order: 'starts_at',
        match: {type: 'therapy'},
        errSnackbarTitle: 'Nie udało się pobrać informacji o nadchodzących spotkaniach',
        filter: (b) => b.gte('joinable_to', new Date().toUTCString()),
      }),
      []
    )
  )

  const {joinable, planned} = React.useMemo(() => groupMeetings(data), [data])

  return (
    <Stack spacing={8} ml={[0, null, 24]} mt={6} mb={[0, null, 16]} px={[4, null, 0]}>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        joinable.length > 0 && (
          <>
            <Text fontSize="lg" fontWeight={500}>
              Nadchodzące terapie
            </Text>
            {joinable.map((meeting, i) => (
              <JoinableMeeting key={i} meeting={meeting} />
            ))}
          </>
        )
      )}
      <Text fontSize="lg" fontWeight={500}>
        Zaplanowane terapie
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={8}>
        {planned.length > 0 ? (
          planned.map((meeting, i) => (
            <PlannedMeeting
              key={i}
              meeting={meeting}
              borderRadius={(i + 2) % 3 === 0 ? '0px 50px' : '50px 0px'}
            />
          ))
        ) : (
          <GridItem colSpan={3}>Brak zaplanowanych terapii</GridItem>
        )}
      </Grid>
      <MeetingsHistory />
    </Stack>
  )
}

export default Meetings
