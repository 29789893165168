import * as React from 'react'

import {
  Box,
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Text,
  useToast,
  HStack,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {OrderDiscountCode} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import useLoadingState from '@/common/use-loading-state'

type Props = {
  value?: string
  isOpen: boolean
  onClose: () => void
  onSubmit: (c: OrderDiscountCode) => void
  doesApply?: (code: OrderDiscountCode) => boolean
}

const DiscountCodeModal = ({value, isOpen, onClose, onSubmit, doesApply}: Props) => {
  const toast = useToast()

  const [code, setCode] = React.useState('')
  React.useEffect(() => setCode(value ?? ''), [value, isOpen])

  const handleChange = React.useCallback(
    ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => setCode(value.trim()),
    []
  )

  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      try {
        const {data, error} = await supabase.rpc('get_discount_code', {code})
        if (error) throw error
        if (!data) throw new Error('Kod nie istnieje')

        const discuntCode = data as any as OrderDiscountCode
        if (!discuntCode.is_active || discuntCode.uses_left === 0) throw new Error('Kod nie jest już aktywny')
        if (doesApply && !doesApply(discuntCode)) throw new Error('Kod nie dotyczy tego produktu')

        onSubmit(discuntCode)
        onClose()
      } catch (e) {
        toast({
          title: 'Nieprawidłowy kod rabatowy',
          description: (e as Error).message,
          isClosable: true,
          status: 'error',
        })
      }
    }, [code, toast, onSubmit, onClose, doesApply])
  )

  const handleEnter = React.useCallback(
    ({key}: React.KeyboardEvent<HTMLInputElement>) => key === 'Enter' && handleSubmit(),
    [handleSubmit]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent px={8} py={6}>
        <ModalHeader>
          <Text fontSize="lg" fontWeight={700}>
            Dodaj kod rabatowy
          </Text>
        </ModalHeader>
        <ModalCloseButton top={8} right={12} boxSize={10} fontSize="20px" rounded="full" />
        <ModalBody mt={4} mb={4}>
          <Stack spacing={4}>
            <Text fontSize="sm" fontWeight={500}>
              Wpisz kod rabatowy
            </Text>
            <Input variant="outline" value={code} onChange={handleChange} onKeyDown={handleEnter} />
            <HStack spacing={16} direction="row" alignItems="center" width="100%">
              <Button variant="link" color="brand.yellow.600" onClick={onClose}>
                Anuluj
              </Button>
              <Box flex={1}>
                <ArrowButton
                  bg="brand.yellow.600"
                  hoverBg="brand.yellow.700"
                  color="white"
                  height={40}
                  fontSize="lg"
                  fontWeight={500}
                  width="100%"
                  isDisabled={loading}
                  isLoading={loading}
                  onClick={handleSubmit}
                >
                  Dodaj
                </ArrowButton>
              </Box>
            </HStack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default DiscountCodeModal
