import * as React from 'react'

import {Box, Container, Heading, Image, Stack, Text, useBreakpointValue} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import Header from '@/common/components/header'
import Section from '@/common/components/section'
import NotForMe from '@/common/not-for-me'
import {PSYCHOTHERAPISTS, SUPPORT_GROUPS_LIST} from '@/router/paths'

import headerImage from './header.jpg'
import section1Image from './section1.jpg'
import section2Image from './section2.jpg'
import section3Image from './section3.jpg'
import trailMobile from './trail-mobile.svg'
import trail from './trail.svg'

const SupportGroupsLandingPage = () => {
  return (
    <>
      <Header img={headerImage} title="Grupy wsparcia">
        <Text fontSize="md" fontWeight={400}>
          Chcesz zrozumieć siebie i swoje funkcjonowanie w danej sytuacji? Szukasz osób, które zmagają się z
          tym, co Ty? Kiedy dzielisz się swoimi doświadczeniami i inni robią to samo, możecie się uczyć od
          siebie i wspierać się nawzajem.
        </Text>
      </Header>
      <Container
        as={Stack}
        maxW="container.xl"
        align="center"
        justify="center"
        my={[8, null, 20]}
        spacing={[16, null, 24]}
      >
        <Image
          w="100%"
          h="100%"
          src={useBreakpointValue({base: trailMobile, md: trail})}
          position="absolute"
        />

        {/* First section */}
        <Section title="Czym są grupy wsparcia?" img={section1Image} flip={true}>
          <Text fontSize="md" fontWeight={400}>
            Grupy wsparcia to regularne spotkania 8-11 osób w różnym wieku, sytuacji zawodowej i rodzinnej,
            które łączą podobne trudności życiowe. Celem spotkań jest budowanie zaufania i akceptacji,
            dyskutowanie oraz dzielenie się postępami z pracy nad sobą i z mierzenia się z wyzwaniami.
            Otwarciu się na rozmowę sprzyja anonimowość członków grupy.
          </Text>
        </Section>

        {/* Second section */}
        <Section title="W czym pomoże Ci grupa wsparcia?" img={section2Image}>
          <Text fontSize="md" fontWeight={400}>
            Dzięki grupie wsparcia będzie Ci łatwiej rozmawiać na trudne tematy i dzielić się doświadczeniami.
            Zyskasz większą świadomość siebie i swoich emocji. Nauczysz się słuchać, dawać i przyjmować
            wsparcie, utrwalać pozytywne nawyki, a także motywować się w drodze do zmiany. To początek
            lepszego życia.
          </Text>
        </Section>

        {/* Third section */}
        <Section
          title="Badania dowodzą, że grupy wsparcia online są tak samo skuteczna jak spotkania w gabinecie"
          img={section3Image}
          barColor="brand.yellow.400"
          flip={true}
        >
          <Text fontSize="md" fontWeight={400}>
            W grupie wsparcia poznasz ludzi, którzy przychodzą z podobną historią. To łączy i stwarza
            bezpieczną przestrzeń do rozmów. Uczestnicy są anonimowi, okazują życzliwość, zrozumienie i
            wsparcie. Niezależnie od miejsca spotkań, online czy w gabinecie, możesz czuć się bezpiecznie, bo
            zasady pracy są takie same i przynoszą identyczne efekty.
          </Text>
        </Section>
      </Container>
      <Box py={24} mb={36} bg="brand.green.800" color="white">
        <Container maxW="container.xl" as={Stack} align="center" spacing={[8, null, 24]}>
          <Heading fontWeight={300} textAlign="center">
            Znajdź swoją grupę wsparcia.
            <br />
            <Text fontWeight={500}>Umów się na pierwsze spotkanie!</Text>
          </Heading>
          <ArrowButton as={Link} to={SUPPORT_GROUPS_LIST} height={55} fontSize="lg" fontWeight={500}>
            <Text px={8} fontSize="lg">
              Wybierz grupę wsparcia
            </Text>
          </ArrowButton>
        </Container>
      </Box>
      <NotForMe title="Szukasz innej metody niż grupa wsparcia?" selected="support-groups" />
    </>
  )
}

export default SupportGroupsLandingPage
