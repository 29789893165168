import * as React from 'react'

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  useToast,
  Button,
  ModalFooter,
} from '@chakra-ui/react'

import {AdminUser} from '@/admin/types'
import {supabase} from '@/api'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

const UserDeleteModal = ({
  onClose,
  open,
  item,
  onComplete,
  fullRemove,
}: CommonModalProps & {item?: AdminUser; fullRemove: boolean}) => {
  const toast = useToast()

  const handleDelete = React.useCallback(async () => {
    if (!item) {
      return
    }
    try {
      const {error} = await supabase.rpc('admin_delete_user_account', {
        full_remove: fullRemove,
        user_id: item.id,
      })
      if (error) {
        throw error
      }
      onComplete && onComplete()
    } catch (e) {
      console.error('Failed to delete user', e)
      toast({
        isClosable: true,
        title: 'Nie udało się usunąć zasobu',
        variant: 'error',
      })
    } finally {
      onClose()
    }
  }, [item, toast, onClose, onComplete, fullRemove])
  const {loading, handleSubmit} = useLoadingState(handleDelete)

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{fullRemove ? 'Usuń użytkownika' : 'Oznacz użytkownika jako usuniętego'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {item?.deleted && !fullRemove
            ? 'Użytkownik jest już oznaczony jako usunięty'
            : fullRemove
            ? 'Użytkownik zostanie trwale usunięty'
            : 'Użytkownik zostanie oznaczony jako usunięty i nie będzie mógł się zalogować'}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            {((fullRemove && item?.deleted) || (!fullRemove && !item?.deleted)) && (
              <Button isLoading={loading} onClick={handleSubmit} colorScheme="red" variant="outline">
                {fullRemove ? 'Usuń' : 'Oznacz jako usunięty'}
              </Button>
            )}
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserDeleteModal
