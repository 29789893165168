import React from 'react'

import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  HStack,
  Button,
  Checkbox,
} from '@chakra-ui/react'
import {TNode} from '@udecode/plate'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'
import {GetQuestionAnswerItemResult, QuestionAnswerItem, UpsertQuestionAnswerItemResult} from '@/api/models'
import {initialValue} from '@/common/content-editor/constants'
import {toPayload} from '@/common/content-editor/utils'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

import QuestionsAndAnswersEditor from '../../faq/questions-and-answers/editor'

type Props = CommonModalProps & {item: QuestionAnswerItem; mode: 'create' | 'edit'; pageID: string}

const QuestionsAndAnswersEditorModal = ({onClose, open, item, onComplete, mode, pageID}: Props) => {
  const toast = useToast()
  const [input, setInput] = React.useState(item)
  const [editorValue, setEditorValue] = React.useState<TNode[]>(initialValue)
  const [isEditorLoading, setIsEditorLoading] = React.useState(false)

  const fetchEditorValue = React.useCallback(
    async (itemID) => {
      setIsEditorLoading(true)
      try {
        const {data, error} = await supabase.rpc('get_question_answer_item', {
          item_id: itemID,
          page_id: pageID,
        })
        if (error) {
          throw error
        }
        if (!data) {
          throw new Error('No data received')
        }
        const result = data as any as GetQuestionAnswerItemResult
        if ('error' in result) {
          throw result.error
        }
        setEditorValue(result.data ?? initialValue)
      } catch (e) {
        console.error('Failed to fetch editor value', e)
      }
      setIsEditorLoading(false)
    },
    [pageID]
  )

  React.useEffect(() => {
    if (!item) {
      return
    }
    setInput(item)
    if (mode === 'create') {
      setEditorValue(initialValue)
      setIsEditorLoading(false)
      return
    }
    fetchEditorValue(item.id)
  }, [fetchEditorValue, item, mode, open])

  const alterInput = React.useCallback(
    (field: keyof QuestionAnswerItem, value: any) => setInput({...input, [field]: value}),
    [setInput, input]
  )

  const handleQuestionChange = React.useCallback(
    ({target: {value}}) => alterInput('question', value),
    [alterInput]
  )
  const handleOrderChange = React.useCallback(({target: {value}}) => alterInput('order', value), [alterInput])

  const handleSave = React.useCallback(async () => {
    if (!input.id) {
      return
    }
    try {
      const {data, error} = await supabase.rpc('upsert_question_answer_item', {
        answer: editorValue ? toPayload(editorValue) : null,
        collapsed: input.collapsed,
        is_published: input.is_published,
        item_id: input.id,
        order: input.order || null,
        page_id: pageID,
        question: input.question || '',
      })
      if (error) {
        throw error?.message || error
      }
      if (!data) {
        throw new Error(`Invalid saving result: ${data}`)
      }
      const result = data as any as UpsertQuestionAnswerItemResult
      if ('error' in result) {
        throw result.error
      }
      toast({isClosable: true, status: 'success', title: 'Dodano'})
      onComplete && onComplete()
    } catch (e) {
      console.error(`Failed to upsert ${pageID} item`, e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się dodać zasobu',
      })
    }
  }, [editorValue, input, onComplete, pageID, toast])

  const {handleSubmit, loading} = useLoadingState(handleSave)

  const handleCollapsedChange = React.useCallback(({target: {checked}}) => {
    setInput((input) => ({...input, collapsed: checked}))
  }, [])
  const handleIsPublishedChange = React.useCallback(({target: {checked}}) => {
    console.log(checked)
    setInput((input) => ({...input, is_published: checked}))
  }, [])

  if (!input.id) {
    const newID = uuidv4()
    setInput({...input, id: newID})
    console.warn(`No question and answer ID found, generated ID: ${newID}`)
    return null
  }

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{mode === 'edit' ? 'Edytuj element' : 'Utwórz element'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading}>
              <FormLabel>Pytanie</FormLabel>
              <Input value={input.question ?? ''} onChange={handleQuestionChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Numer porządkowy</FormLabel>
              <Input type="number" value={input.order || ''} onChange={handleOrderChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Zwinięte</FormLabel>
              <Checkbox isChecked={input.collapsed ?? false} onChange={handleCollapsedChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opublikowane</FormLabel>
              <Checkbox isChecked={input.is_published ?? false} onChange={handleIsPublishedChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Odpowiedź</FormLabel>
              <QuestionsAndAnswersEditor
                entryID={input.id}
                loading={isEditorLoading}
                readOnly={false}
                setValue={setEditorValue}
                value={editorValue || undefined}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button isLoading={loading} onClick={handleSubmit}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default QuestionsAndAnswersEditorModal
