import * as React from 'react'

import {As, Center, Flex, FlexProps, Icon, Stack, Text} from '@chakra-ui/react'

import ScreenWideBar from '../screen-wide-bar'

export type DetailItemProps = {
  title: string
  children: React.ReactNode
  icon?: As<any>
}

const ShowcaseDetailItem = ({title, children, icon}: DetailItemProps) => (
  <Stack spacing={4} align="center" flex={1}>
    <Stack align="center">
      {icon && <Icon as={icon} boxSize="60px" />}
      <Text fontSize="md" fontWeight={700} textAlign="center">
        {title}
      </Text>
    </Stack>
    <Center flex={1} fontWeight={300} textAlign="center">
      {children}
    </Center>
  </Stack>
)

type Props = FlexProps & {
  items: DetailItemProps[]
}

const ShowcaseDetailsBanner = ({items, ...rest}: Props) => {
  return (
    <ScreenWideBar py={[16, null, 10]} {...rest}>
      <Stack width="100%" justify="space-evenly" spacing={[12, null, 6]} direction={['column', null, 'row']}>
        {items.map((props, i) => (
          <ShowcaseDetailItem key={i} {...props} />
        ))}
      </Stack>
    </ScreenWideBar>
  )
}

export default ShowcaseDetailsBanner
