import React from 'react'

import {Box, Button, Container, Grid, GridItem, Heading, Text, Wrap, WrapItem} from '@chakra-ui/react'
import {Link as RouterLink} from 'react-router-dom'

import {ArrowIcon} from '@/common/arrow-button'
import {SUPPORT_GROUPS_FEATURE} from '@/constants'
import {BLOG, COURSES, PSYCHOTHERAPY, SUPPORT_GROUPS} from '@/router/paths'

const NotForMeLink = ({title, to}: {title: string; to: string}) => (
  <WrapItem>
    <Button
      as={RouterLink}
      to={to}
      rightIcon={<ArrowIcon boxSize={8} color="brand.yellow.600" />}
      variant="link"
      color="black"
      fontWeight={300}
    >
      {title}
    </Button>
  </WrapItem>
)

const NotForMe = ({title, selected}: {title: string; selected: string}) => {
  return (
    <Box bg="brand.yellow.300" py={[16, null, 24]}>
      <Container maxW="container.xl">
        <Grid templateColumns="repeat(12, 1fr)" columnGap={[0, null, 16]}>
          <GridItem colSpan={[12, null, 4]}>
            <Heading size="lg" fontWeight={500} mb={[6, null, 0]}>
              {title}
            </Heading>
          </GridItem>
          <GridItem colSpan={[12, null, 7]}>
            <Text fontSize="md" fontWeight={300} mb={8}>
              Są różne drogi do pełniejszego życia! U nas możesz rozwijać się na różne sposoby. Poznaj inne
              metody pracy nad sobą lub skontaktuj się z terapeutą, aby dobrać metodę do Twoich potrzeb.
            </Text>

            <Wrap spacing={8}>
              {selected !== 'courses' && <NotForMeLink title="Warsztaty" to={COURSES} />}
              {selected !== 'psychotherapy' && <NotForMeLink title="Psychoterapia" to={PSYCHOTHERAPY} />}
              {selected !== 'blog' && <NotForMeLink title="Blog" to={BLOG} />}
              {SUPPORT_GROUPS_FEATURE === 'true' && selected !== 'support_groups' && (
                <NotForMeLink title="Grupy wsparcia" to={SUPPORT_GROUPS} />
              )}
            </Wrap>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  )
}

export default NotForMe
