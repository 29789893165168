import * as React from 'react'

import {AspectRatio, Box, Button, Center, Flex, Image, Progress, Stack, Text} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import {Link, generatePath} from 'react-router-dom'

import {PublicCourse} from '@/api/models'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {COURSES, COURSE_SHOWCASE} from '@/router/paths'
import {formatDate} from '@/utils/string'

type Props = PublicCourse & {
  progress?: number | null
}

const CourseItem = ({
  id,
  signed_logo,
  name,
  name_slug,
  progress,
  short_description,
  roles,
  is_new,
  release_at,
}: Props) => {
  const banner = React.useMemo(
    () =>
      roles?.length
        ? {title: 'Masz dostęp', bg: 'brand.yellow.500'}
        : release_at && new Date(release_at).getTime() > Date.now()
        ? {title: `Dostępny od ${formatDate(release_at)}`, bg: 'brand.green.800'}
        : is_new
        ? {title: 'Nowość', bg: 'brand.green.600'}
        : null,
    [roles, release_at, is_new]
  )

  const path = React.useMemo(
    () => (id ? generatePath(COURSE_SHOWCASE, {id, slug: name_slug}) : COURSES),
    [id, name_slug]
  )

  return (
    <Stack direction={['column', null, 'row']} spacing={8}>
      <Box
        as={Link}
        to={path}
        width={['100%', null, '50%']}
        alignSelf="flex-start"
        position="relative"
        borderRadius="0 60px"
        overflow="hidden"
      >
        <AspectRatio ratio={1}>
          {signed_logo ? (
            <Image src={signed_logo} objectFit="cover" />
          ) : (
            <Center bg="gray.600">
              <MdClose size={64} />
            </Center>
          )}
        </AspectRatio>
        {!!roles?.length && <Box position="absolute" top={0} w="100%" h="100%" bg="#3E3E3E" opacity={0.7} />}
        {banner && (
          <Box
            position="absolute"
            top={8}
            bg={banner.bg}
            color="white"
            py={2}
            px={8}
            minWidth="60%"
            maxWidth="90%"
            textAlign="center"
            boxShadow="0px 4px 4px 0px #00000040"
            borderBottomEndRadius="full"
          >
            {banner.title}
          </Box>
        )}
      </Box>
      <Flex direction="column" width={['100%', null, '50%']}>
        <Text
          as="h2"
          fontWeight={500}
          fontSize="xl"
          mb={[4, null, 2]}
          noOfLines={[null, null, 2]}
          height={['auto', null, '60px']}
        >
          {name}
        </Text>
        <Box flex={1} mb={[2, null, 0]}>
          <ContentEditor
            id={`courses-list-${id}`}
            variant="basic"
            value={short_description ?? initialEditorValue}
            readOnly={true}
          />
        </Box>

        <Flex direction="row" justifyContent="flex-end">
          <Button
            as={Link}
            to={path}
            variant="link"
            color="black"
            pb={2}
            borderBottomWidth="1px"
            borderBottomColor="black"
            rounded="none"
            marginBottom="1px"
            _hover={{
              borderBottomWidth: '2px',
              fontWeight: 500,
              marginBottom: '0',
            }}
          >
            Zobacz więcej
          </Button>
        </Flex>
        {progress !== null && progress !== undefined && (
          <Flex w="100%" flexDir="row" alignItems="baseline" mt={4}>
            <Progress w="100%" value={progress} borderRadius="full" />
            <Text ml={3} fontWeight="semibold">
              {progress}%
            </Text>
          </Flex>
        )}
      </Flex>
    </Stack>
  )
}

export default CourseItem
