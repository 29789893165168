import * as React from 'react'

import {Flex, Spinner} from '@chakra-ui/react'

import {PublicCourse} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'
import Carousel from '@/courses/carousel'

const CoursesCarousel = ({course}: {course: number}) => {
  const {data, loading} = useSupabaseQuery<PublicCourse>({
    fields: '*',
    itemsPerPage: 5,
    table: 'public_courses',
    order: 'order',
    errSnackbarTitle: 'Nie udało się wczytać innych szkoleń',
    filter: React.useCallback((b) => b.not('id', 'eq', course), [course]),
  })

  return loading ? (
    <Flex my={4} mx={4} justifyt="center" align="center">
      <Spinner size="xl" />
    </Flex>
  ) : data ? (
    <Carousel courses={data} />
  ) : null
}

export default CoursesCarousel
