import * as React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  HStack,
  Button,
  Checkbox,
  InputLeftAddon,
  InputGroup,
} from '@chakra-ui/react'

import {TShirtSize} from '@/api/models'
import useEditorModalState from '@/common/use-editor-modal'
import {CommonModalProps} from '@/utils/types'

import {emptyTshirtSize} from '../constants'

const TshirtSizeEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: TShirtSize | null}) => {
  const {input, handleInputChange, handleUpsert, loading} = useEditorModalState<TShirtSize>({
    item,
    table: 'tshirt_sizes',
    emptyInput: emptyTshirtSize,
    open,
    onComplete,
    onClose,
  })

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj rozmiar koszulki' : 'Dodaj rozmiar koszulki'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl isDisabled={loading}>
                <InputGroup>
                  <InputLeftAddon children="ID" />
                  <Input
                    name="id"
                    value={input.id ?? ''}
                    onChange={handleInputChange}
                    isReadOnly={!!item?.id}
                    _readOnly={{
                      cursor: 'default',
                      _focus: {borderColor: 'inherit', boxShadow: 'none'},
                    }}
                  />
                </InputGroup>
              </FormControl>
              <FormControl isDisabled={loading}>
                <Checkbox name="available" isChecked={input.available ?? false} onChange={handleInputChange}>
                  Dostępny
                </Checkbox>
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Numer porządkowy</FormLabel>
                <Input name="order" type="number" value={input.order} onChange={handleInputChange} />
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={!input.id}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TshirtSizeEditorModal
