import * as React from 'react'

import {
  VStack,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
  ModalContent,
  ModalBody,
  Modal,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {minPasswordLength} from '@/auth/constants'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

import {INVALID_PWD, NEW_PWD_SAME_AS_OLD} from './error'
import {ChangePasswordResponse} from './types'

const ChangePassword = ({onClose, open}: CommonModalProps) => {
  const [oldPassword, setOldPassword] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [repeatPassword, setRepeatPassword] = React.useState('')
  const toast = useToast()

  const handleOldPasswordChange = React.useCallback(
    ({target: {value}}) => setOldPassword(value),
    [setOldPassword]
  )
  const handlePasswordChange = React.useCallback(({target: {value}}) => setPassword(value), [setPassword])
  const handleRepeatPasswordChange = React.useCallback(
    ({target: {value}}) => setRepeatPassword(value),
    [setRepeatPassword]
  )

  React.useEffect(() => {
    setOldPassword('')
    setPassword('')
    setRepeatPassword('')
  }, [open])

  const getDescription = React.useCallback((e: number) => {
    switch (e) {
      case INVALID_PWD:
        return 'Niepoprawne hasło'
      case NEW_PWD_SAME_AS_OLD:
        return 'Nowe hasło musi być inne niż stare hsało'
      default:
        return 'Nieznany błąd'
    }
  }, [])

  const _handleSubmit = React.useCallback(async () => {
    if (!oldPassword || !password || !repeatPassword || password !== repeatPassword) {
      return
    }
    try {
      const {data, error} = await supabase.rpc('change_password', {
        old_password: oldPassword,
        password: password,
      })
      if (error) {
        throw error
      }
      if ((data as any).error) {
        throw new Error(getDescription((data as any).error_code))
      }
      toast({isClosable: true, status: 'success', title: 'Hasło zmienione'})
      onClose()
    } catch (e) {
      console.error('Failed to change password', e)
      toast({
        description: (e as Error).message,
        isClosable: true,
        status: 'error',
        title: 'Nie udało się zmienić hasła',
      })
    }
  }, [oldPassword, password, repeatPassword, toast, getDescription, onClose])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)

  const isPasswordValid = React.useMemo(() => !password || password.length >= minPasswordLength, [password])
  const isRepeatPasswordValid = React.useMemo(
    () => !repeatPassword || password === repeatPassword,
    [password, repeatPassword]
  )

  const isSubmitDisabled = React.useMemo(
    () => !oldPassword || !password || !isPasswordValid || !repeatPassword || !isRepeatPasswordValid,
    [oldPassword, password, isPasswordValid, repeatPassword, isRepeatPasswordValid]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Zmień hasło</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center flexDirection="column">
            <VStack width="80%">
              <FormControl id="oldPassword" isDisabled={loading}>
                <FormLabel>Aktualne hasło</FormLabel>
                <Input type="password" value={oldPassword} onChange={handleOldPasswordChange} />
              </FormControl>
              <FormControl id="password" isInvalid={!isPasswordValid} isDisabled={loading}>
                <FormLabel>Nowe hasło</FormLabel>
                <Input type="password" value={password} onChange={handlePasswordChange} />
                <FormErrorMessage>Niepoprawne hsało. Hasło musi mieć conajmniej 6 znaków</FormErrorMessage>
              </FormControl>
              <FormControl id="repeatPassword" isInvalid={!isRepeatPasswordValid} isDisabled={loading}>
                <FormLabel>Powtórz nowe hasło</FormLabel>
                <Input type="password" value={repeatPassword} onChange={handleRepeatPasswordChange} />
                <FormErrorMessage>Hasła nie są identyczne</FormErrorMessage>
              </FormControl>
            </VStack>
          </Center>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={loading} isDisabled={isSubmitDisabled} onClick={handleSubmit}>
            Zmień hasło
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ChangePassword
