import * as React from 'react'

import {generatePath, useHistory} from 'react-router-dom'

import {PublicCourseWithDetails} from '@/api/models'
import {selectProfile, setModalState} from '@/auth/state'
import {ArrowButton, Props as ArrowButtonProps} from '@/common/arrow-button'
import {setOrderModel} from '@/courses/state'
import {COURSE_PAGE, COURSE_SHOWCASE} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatDate} from '@/utils/string'

export type Props = Omit<ArrowButtonProps, 'bg' | 'hoverBg' | 'color'> & {
  course?: PublicCourseWithDetails
}

const OrderButton = ({course, ...rest}: Props) => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectProfile)
  const {push} = useHistory()

  const releaseDate = React.useMemo(() => course?.release_at && new Date(course.release_at), [course])
  const isReleased = React.useMemo(() => !releaseDate || releaseDate.getTime() < Date.now(), [releaseDate])

  const handleClick = React.useCallback(() => {
    if (!course || !isReleased) return

    if (!profile) {
      const path = generatePath(COURSE_SHOWCASE, {
        id: course.id,
      })
      dispatch(
        setModalState({
          redirectPath: `${path}?order=true`,
          state: 'signin',
        })
      )
      return
    }

    if (course?.roles?.length) {
      course?.nextlvl_url
        ? window.open(course?.nextlvl_url, '_blank')
        : push(generatePath(COURSE_PAGE, {course: course.id}))
      return
    }

    dispatch(setOrderModel(course))
  }, [profile, isReleased, course, push, dispatch])

  return (
    <ArrowButton
      bg="brand.yellow.600"
      hoverBg="brand.yellow.700"
      color="white"
      height={40}
      fontSize="lg"
      fontWeight={500}
      onClick={handleClick}
      isLoading={!course}
      isDisabled={!isReleased}
      {...rest}
    >
      {!isReleased && releaseDate
        ? `Dostępny od ${formatDate(releaseDate)}`
        : course?.roles?.length
        ? 'Otwórz warsztat'
        : 'Kup teraz'}
    </ArrowButton>
  )
}

export default OrderButton
