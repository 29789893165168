import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'

import {supabase} from '@/api'
import {Review, ReviewType, SupabaseRPCResult} from '@/api/models'
import {toast} from '@/common/theme'
import {RootState} from '@/store'

import {emptyReview, reviewStateToUpsertArgs, reviewTypeToRPC} from './constants'

export type ReviewsState = {
  open: boolean
  submitting: boolean
  submitted: boolean
  review: Review
  type: ReviewType
  hostID?: number // support group host
  itemID?: string | number
  admin?: boolean
}

const initialState: ReviewsState = {
  open: false,
  admin: false,
  submitted: false,
  type: 'course',
  review: emptyReview,
  submitting: false,
}

export const submitReview = createAsyncThunk('reviews/submitReview', async (_, {getState}) => {
  const state = (getState() as RootState).reviews
  const {review, type, itemID} = state

  if (!itemID || !type || !review) return new Error('Missing review details')

  const {data, error} = await supabase.rpc(reviewTypeToRPC[type], reviewStateToUpsertArgs(state))
  if (error) throw error
  if (!data) throw new Error('No data')

  const res = data as any as SupabaseRPCResult
  if ('error' in res) throw new Error(res.error)
})

export const reviewsSlice = createSlice({
  initialState,
  name: 'reviews',
  reducers: {
    setReview: (state, {payload}: PayloadAction<Review>) => {
      state.review = payload
    },
    updateReview: (state, {payload}: PayloadAction<Partial<Review>>) => {
      state.review = {...state.review, ...payload}
    },
    setReviewSubmitted: (state, {payload}: PayloadAction<boolean>) => {
      state.submitted = payload
    },
    setReviewOpen: (state, {payload}: PayloadAction<boolean>) => {
      state.open = payload
    },
    setHostID: (state, {payload}: PayloadAction<number | undefined>) => {
      state.hostID = payload
    },
    setReviewState: (_, {payload}: PayloadAction<Partial<ReviewsState>>) => {
      return {...initialState, ...payload}
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitReview.pending, (state) => {
        state.submitting = true
        state.submitted = false
      })
      .addCase(submitReview.fulfilled, (state) => {
        toast({
          title: `${state.review.id ? 'Zaktualizowano' : 'Dodano'} recenzję`,
          status: 'success',
          isClosable: true,
        })
        return {...initialState, submitted: true}
      })
      .addCase(submitReview.rejected, (state, {error}) => {
        state.submitting = false

        console.error(error)
        toast({
          title: `Nie udało się ${state.review.id ? 'zaktualizować' : 'dodać'} recenzji`,
          status: 'error',
          isClosable: true,
        })
      })
  },
})

export const {setReview, updateReview, setReviewSubmitted, setReviewOpen, setReviewState, setHostID} =
  reviewsSlice.actions

export const selectReviewState = (state: RootState) => state.reviews
export const selectReviewSubmitted = (state: RootState) => state.reviews.submitted

export default reviewsSlice.reducer
