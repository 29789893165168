import {DataNode, Key} from 'rc-tree/lib/interface'

export const findTreeNode = (data: DataNode[], key: Key): [DataNode | null, number, DataNode[]] => {
  for (const [index, item] of data.entries()) {
    if (item.key === key) {
      return [item, index, data]
    }
    if (item.children) {
      const found = findTreeNode(item.children, key)
      if (found[0]) {
        return found
      }
    }
  }

  return [null, 0, []]
}

export const findTreeParent = (data: DataNode[], key: Key): DataNode | null => {
  for (const item of data) {
    if (item.children && item.children.findIndex((c) => c.key === key) !== -1) {
      return item
    }

    if (item.children) {
      const found = findTreeParent(item.children, key)
      if (found) {
        return found
      }
    }
  }

  return null
}

export type OnDropReturnType = {
  success: boolean
  tree?: DataNode[]
}
