import React from 'react'

import {Button, Container, useDisclosure} from '@chakra-ui/react'

import {AdminUser} from '@/admin/types'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import useSupabaseQuery from '@/common/use-custom-query'

import {columns, defaultSelectedColumns, filterFields} from './constants'
import UserDeleteModal from './delete-modal'
import UserEditorModal from './editor-modal'

const Users = () => {
  const tableState = useTableState({sortBy: 'email'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const [currentUser, setCurrentUser] = React.useState<AdminUser>()
  const [showDeleted, setShowDeleted] = React.useState(false)
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminUser>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    finalize: React.useCallback((b) => (showDeleted ? b.eq('deleted', true) : b), [showDeleted]),
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminUser,
    pageNumber: tableState.page,
    table: 'admin_users',
  })

  const handleEdit = React.useCallback(
    (item: AdminUser) => {
      setCurrentUser(item)
      onEditorOpen()
    },
    [setCurrentUser, onEditorOpen]
  )

  const handleDelete = React.useCallback(
    (item: AdminUser) => {
      setCurrentUser(item)
      onDeleteOpen()
    },
    [setCurrentUser, onDeleteOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDelete)],
    [handleEdit, handleDelete]
  )

  const handleShowDeleted = React.useCallback(() => {
    setShowDeleted(true)
  }, [setShowDeleted])

  const handleShowAll = React.useCallback(() => {
    setShowDeleted(false)
  }, [setShowDeleted])

  const showDeletedToggle = React.useMemo(
    () =>
      showDeleted ? (
        <Button variant="outline" onClick={handleShowAll} mr={2}>
          Pokaż wszystkich użytkowników
        </Button>
      ) : (
        <Button variant="outline" onClick={handleShowDeleted} mr={2}>
          Pokaż usuniętych użytkowników
        </Button>
      ),
    [showDeleted, handleShowAll, handleShowDeleted]
  )

  return (
    <Container maxW="container.xl" py={4}>
      <DataTable<AdminUser>
        columns={columns}
        defaultSelectedColumns={defaultSelectedColumns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        loading={loading}
        customToolbarActions={showDeletedToggle}
        filterFields={filterFields}
      />
      <UserEditorModal item={currentUser} onClose={onEditorClose} open={isEditorOpen} onComplete={fetch} />
      <UserDeleteModal
        item={currentUser}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
        fullRemove={showDeleted}
      />
    </Container>
  )
}

export default Users
