import {TShirtSize} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptyTshirtSize: TShirtSize = {
  available: false,
  created_at: new Date(),
  id: '',
  order: 0,
  updated_at: new Date(),
}

export const defaultSelectedColumns = ['id', 'available', 'order', 'created_at']

export const columns: Column<TShirtSize>[] = [
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
  },
  {
    Header: 'Dostępny',
    id: 'available',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Numer porządkowy',
    id: 'order',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Zaktualizowano',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'text',
  },
  {
    label: 'Dostępny',
    name: 'available',
    variant: 'boolean',
  },
  {
    label: 'Numer porządkowy',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Zaktualizowano',
    name: 'updated_at',
    variant: 'datetime',
  },
]
