import React from 'react'

import {Box, Center, Stack, Text} from '@chakra-ui/react'

import {UserBillingAddress} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'

import AddressItem from './address-item'
import {addressTypeToLabel} from './constants'
import useBillingEditorState from './use-billing-editor-state'

type EditMode = 'display' | 'edit'

type Props = {
  mode?: EditMode // defaults to 'display'

  controlledState?: ReturnType<typeof useBillingEditorState> // also hides "save" button if truthy
}

const UserBilling = ({mode = 'display', controlledState}: Props) => {
  const {
    handleSubmit: _handleSubmit,
    billingAddressLoading: _billingAddressLoading,
    response: _response,
    saving: _saving,
    draftBillingAddresses: _draftBillingAddresses,
    setDraftBillingAddresses: _setDraftBillingAddresses,
    isSubmitDisabled: _isSubmitDisabled,
  } = useBillingEditorState() // for uncontrolled component mode
  const handleSubmit = controlledState?.handleSubmit ?? _handleSubmit
  const billingAddressLoading = controlledState?.billingAddressLoading ?? _billingAddressLoading
  const response = controlledState?.response ?? _response
  const saving = controlledState?.saving ?? _saving
  const draftBillingAddresses = controlledState?.draftBillingAddresses ?? _draftBillingAddresses
  const setDraftBillingAddresses = controlledState?.setDraftBillingAddresses ?? _setDraftBillingAddresses
  const isSubmitDisabled = controlledState?.isSubmitDisabled ?? _isSubmitDisabled

  const handleBillingAddressChange = React.useCallback(
    (value: UserBillingAddress, index: number) => {
      const updatedBillingAddress = [...draftBillingAddresses]
      updatedBillingAddress[index] = value
      setDraftBillingAddresses([...updatedBillingAddress])
    },
    [draftBillingAddresses, setDraftBillingAddresses]
  )

  const sameShippingAddress = React.useMemo(
    () => !!draftBillingAddresses.find((a) => a.type === 'basic')?.same_shipping_address,
    [draftBillingAddresses]
  )

  const basicAddress = React.useMemo(
    () => draftBillingAddresses.find((a) => a.type === 'basic'),
    [draftBillingAddresses]
  )

  return (
    <Stack py={[2, null, 8]}>
      {draftBillingAddresses.map((address, index) => (
        <Box key={index}>
          <Text fontSize={20} fontWeight={500}>
            {addressTypeToLabel[address.type]}
          </Text>
          <AddressItem
            disabled={
              mode === 'display' ||
              billingAddressLoading ||
              (address.type === 'shipping' && sameShippingAddress) ||
              saving
            }
            index={index}
            value={address}
            basicValue={basicAddress}
            type={address.type}
            onChange={handleBillingAddressChange}
            response={response}
          />
        </Box>
      ))}
      <Center>
        {mode === 'edit' && !controlledState && (
          <ArrowButton
            isLoading={saving}
            isDisabled={isSubmitDisabled}
            onClick={handleSubmit}
            bg="brand.yellow.600"
            color="white"
            minWidth={250}
            mt={6}
          >
            Zapisz zmiany
          </ArrowButton>
        )}
      </Center>
    </Stack>
  )
}

export default UserBilling
