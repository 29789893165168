import * as React from 'react'

import SideMenu, {SideMenuItem} from '@/common/components/side-menu'

import {
  ADMIN_BLOG_POSTS,
  ADMIN_CALENDAR,
  ADMIN_COURSES,
  ADMIN_COURSE_CATEGORIES,
  ADMIN_COURSE_REVIEWS,
  ADMIN_DISCOUNT_CODES,
  ADMIN_FAQ,
  ADMIN_FRONT_PAGE_LOGOS,
  ADMIN_INVOICES,
  ADMIN_MEETINGS,
  ADMIN_ORDERS,
  ADMIN_ORDER_ITEMS,
  ADMIN_PAYMENTS,
  ADMIN_PAYMENT_REFUNDS,
  ADMIN_SUPPORT_GROUPS,
  ADMIN_SUPPORT_GROUP_CATEGORIES,
  ADMIN_SUPPORT_GROUP_REVIEWS,
  ADMIN_THERAPISTS,
  ADMIN_THERAPIST_REVIEWS,
  ADMIN_THERAPIST_SERVICES,
  ADMIN_THERAPIST_SPECIALTIES,
  ADMIN_TSHIRT_SIZES,
  ADMIN_USERS,
  ADMIN_WEBINARS,
  ADMIN_WEBINAR_REVIEWS,
} from './router'

const items: SideMenuItem[] = [
  {
    label: 'Warsztaty',
    link: ADMIN_COURSES,
  },
  {
    label: 'Kategorie warsztatów',
    link: ADMIN_COURSE_CATEGORIES,
  },
  {
    label: 'Oceny warsztatów',
    link: ADMIN_COURSE_REVIEWS,
  },
  {
    label: 'Rozmiary koszulek',
    link: ADMIN_TSHIRT_SIZES,
  },
  {
    label: 'Kody zniżkowe',
    link: ADMIN_DISCOUNT_CODES,
  },
  {
    label: 'Terapeuci',
    link: ADMIN_THERAPISTS,
  },
  {
    label: 'Spotkania',
    link: ADMIN_MEETINGS,
  },
  {
    label: 'Specjalizacje terapeutów',
    link: ADMIN_THERAPIST_SPECIALTIES,
  },
  {
    label: 'Usługi terapeutow',
    link: ADMIN_THERAPIST_SERVICES,
  },
  {
    label: 'Oceny terapeutów',
    link: ADMIN_THERAPIST_REVIEWS,
  },
  {
    label: 'Webinary',
    link: ADMIN_WEBINARS,
  },
  {
    label: 'Oceny webinarów',
    link: ADMIN_WEBINAR_REVIEWS,
  },
  {
    label: 'Wpisy na blogu',
    link: ADMIN_BLOG_POSTS,
  },
  {
    label: 'Grupy wsparcia',
    link: ADMIN_SUPPORT_GROUPS,
  },
  {
    label: 'Kategorie grup wsparcia',
    link: ADMIN_SUPPORT_GROUP_CATEGORIES,
  },
  {
    label: 'Oceny grup wsparcia',
    link: ADMIN_SUPPORT_GROUP_REVIEWS,
  },
  {
    label: 'Użytkownicy',
    link: ADMIN_USERS,
  },
  {
    label: 'Faktury',
    link: ADMIN_INVOICES,
  },
  {
    label: 'Zamówienia',
    link: ADMIN_ORDERS,
  },
  {
    label: 'Produkty zamówień',
    link: ADMIN_ORDER_ITEMS,
  },
  {
    label: 'Płatności',
    link: ADMIN_PAYMENTS,
  },
  {
    label: 'Zwroty',
    link: ADMIN_PAYMENT_REFUNDS,
  },
  {
    label: 'FAQ',
    link: ADMIN_FAQ,
  },
  {
    label: 'Logo na stronie głównej',
    link: ADMIN_FRONT_PAGE_LOGOS,
  },
  {
    label: 'Kalendarz Google',
    link: ADMIN_CALENDAR,
  },
]

export default () => <SideMenu items={items} />
