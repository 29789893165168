import React from 'react'

import _ from 'lodash'
import {generatePath, useHistory} from 'react-router-dom'

import {AdminCourse} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'
import AccessManagementModal from '@/courses/viewer/access-management-modal'
import {ADMIN_COURSE_PAGE} from '@/router/paths'

import CourseCategoriesModal from './categories-modal'
import {columns, defaultSelectedColumns, emptyAdminCourse, filterFields} from './constants'
import CourseEditorModal from './editor-modal'
import {accessAction, categoriesAction, openEditorAction, showSellsAction} from './utils'

const Courses = () => {
  const history = useHistory()
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminCourse>({
    table: 'admin_courses',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminCourse,
    filter: tableState.filter,
  })

  const {onClose: onDeleteClose, onOpen: onDeleteOpen, value: deleteValue} = useValueDisclosure<AdminCourse>()
  const {value: editorValue, onClose: onEditorClose, onOpen: onEditorOpen} = useValueDisclosure<AdminCourse>()
  const {
    value: categoriesModalValue,
    onOpen: onCategoriesModalOpen,
    onClose: onCategoriesModalClose,
  } = useValueDisclosure<number | undefined>()
  const {
    value: accessManagementModalValue,
    onOpen: onAccessManagementModalOpen,
    onClose: onAccessManagementModalClose,
  } = useValueDisclosure<number | undefined>()

  const handleOpenCourse = React.useCallback(
    ({id, nextlvl_url}) =>
      nextlvl_url
        ? window.open(nextlvl_url, '_blank')
        : history.push(generatePath(ADMIN_COURSE_PAGE, {course: id})),
    [history]
  )

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyAdminCourse)
  }, [onEditorOpen])

  const actions = React.useMemo(
    () => [
      deleteAction(onDeleteOpen),
      editAction(onEditorOpen),
      categoriesAction((course: AdminCourse) => onCategoriesModalOpen(course.id)),
      accessAction((course: AdminCourse) => onAccessManagementModalOpen(course.id)),
      openEditorAction(handleOpenCourse),
      showSellsAction,
    ],
    [onEditorOpen, handleOpenCourse, onCategoriesModalOpen, onDeleteOpen, onAccessManagementModalOpen]
  )

  const coursesData = React.useMemo(() => data ?? [], [data])

  return (
    <>
      <DataTable<AdminCourse>
        columns={columns}
        data={coursesData}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <CourseEditorModal item={editorValue} onClose={onEditorClose} open={!!editorValue} onComplete={fetch} />
      <CourseCategoriesModal
        open={!!categoriesModalValue}
        onClose={onCategoriesModalClose}
        course={categoriesModalValue || 0}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="courses"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
        headerText="Usunąć warsztat?"
        name={deleteValue?.name}
        onSuccessTitle="Warsztat został usunięty"
        onFailTitle="Nie udało się usunąć warsztatu"
      />
      <AccessManagementModal
        isOpen={!!accessManagementModalValue}
        onClose={onAccessManagementModalClose}
        course={accessManagementModalValue || 0}
      />
    </>
  )
}

export default Courses
