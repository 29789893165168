import React from 'react'

import {Button} from '@chakra-ui/react'
import {MdOutlineFileUpload as UploadIcon} from 'react-icons/md'

const fileInputStyle = {display: 'none'}

type Props = {accept?: string; onUpload: (file: File) => Promise<void>; loading?: boolean; disabled?: boolean}

const FileUpload = ({accept, onUpload, loading, disabled}: Props) => {
  const hiddenInputRef = React.useRef<HTMLInputElement | null>(null)

  const handleClick = React.useCallback(() => hiddenInputRef.current?.click(), [])

  const handleChange = React.useCallback(
    (event) => {
      const file = event.target.files[0]
      onUpload(file)
    },
    [onUpload]
  )

  return (
    <>
      <Button leftIcon={<UploadIcon />} onClick={handleClick} isLoading={loading} isDisabled={disabled}>
        Dodaj
      </Button>
      <input
        type="file"
        ref={hiddenInputRef}
        onChange={handleChange}
        style={fileInputStyle}
        accept={accept}
      />
    </>
  )
}

export default FileUpload
