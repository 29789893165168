import * as React from 'react'

import {Checkbox, FormControl, Stack, Text} from '@chakra-ui/react'

import {selectOrderAgreements, setOrderAgreements} from '@/courses/state'
import {useAppDispatch, useAppSelector} from '@/store'

const agreements = [
  `Żądam świadczenia usługi w zakresie udostępnienia mi materiałów szkoleniowych online przed upływem
   terminu na złożenie oświadczenia o odstąpieniu od umowy zawartej na odległość oraz przyjmuję do
   wiadomości, że z chwilą uzyskania dostępu do materiałów szkoleniowych online utracę prawo do
   odstąpienia od umowy w zakresie udostępnienia mi materiałów szkoleniowych.`,
  `Żądam świadczenia usługi w zakresie wykupienia licencji do korzystania z utworów szkoleniowych
   przed upływem terminu na złożenie oświadczenia o odstąpieniu od umowy zawartej na odległość oraz
   jestem świadomy, że z chwilą wykupienia licencji utracę prawo do odstąpienia od umowy w zakresie
   udostępnienia mi materiałów szkoleniowych.`,
  `Żądam wykonania Usługi przed upływem terminu na złożenie oświadczenia o odstąpieniu od umowy
   zawartej na odległość oraz przyjmuję do wiadomości, że z chwilą wykonania Usługi utracę prawo do
   odstąpienia od umowy.`,
]

const Agreements = () => {
  const dispatch = useAppDispatch()
  const agreementsChecked = useAppSelector(selectOrderAgreements)

  const handleChange = React.useCallback(
    ({target: {name, checked}}: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setOrderAgreements({i: +name, checked}))
    },
    [dispatch]
  )

  return (
    <Stack spacing={4} fontWeight={300}>
      {agreements.map((a, i) => (
        <FormControl key={i}>
          <Checkbox
            name={i.toString()}
            size="lg"
            py={0.5}
            alignItems="flex-start"
            onChange={handleChange}
            isChecked={agreementsChecked[i]}
          >
            <Text fontSize="xs" pl={4} my={-0.5}>
              {a}
              <Text as="span" color="red" pl={1}>
                *
              </Text>
            </Text>
          </Checkbox>
        </FormControl>
      ))}
      <Text fontWeight={300}>
        <Text as="span" color="red" pr={1}>
          *
        </Text>
        Pola wymagane
      </Text>
    </Stack>
  )
}

export default Agreements
