import * as React from 'react'

import {Button, Center, Divider, Spinner, Stack, Text, useToast} from '@chakra-ui/react'

import {generateGoogleAuthURL} from '@/api/google'
import {TherpistCalendarDetails} from '@/api/models'
import {selectProfile} from '@/auth/state'
import useFetchSingle from '@/common/use-fetch-single'
import {useAppSelector} from '@/store'
import {formatTimestamp} from '@/utils/string'

import ResetCalendar from './reset'

const GoogleCalendarSettings = () => {
  const toast = useToast()
  const user = useAppSelector(selectProfile)

  const {data, loading, fetch} = useFetchSingle<TherpistCalendarDetails>(
    React.useMemo(
      () => ({
        table: 'therapist_calendar_details',
        fields: '*',
        match: {therapist_id: user?.therapist_id},
      }),
      [user]
    )
  )

  const handleClick = React.useCallback(() => {
    try {
      const url = generateGoogleAuthURL(false) // TODO: generate on the server
      window.location.href = url
    } catch (e) {
      console.error('Failed to generate auth URL', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Niepoprawny URL',
      })
    }
  }, [toast])

  return (
    <Center flexDirection="column">
      {loading ? (
        <Spinner />
      ) : (
        <Stack py={3} align="center">
          <Button onClick={handleClick}>Połącz z Kalendarzem Google</Button>
          <Divider />
          <Text>Ostatnia synchronizacja:</Text>
          <Text fontWeight={500}>
            {data?.last_calendar_sync ? formatTimestamp(data.last_calendar_sync) : 'Brak'}
          </Text>
          {data?.google_calendar_id && (
            <>
              <Divider />
              <Text>ID połączonego kalendarza:</Text>
              <Text fontWeight={500}>{data.google_calendar_id}</Text>
              <Divider />
              <ResetCalendar onComplete={fetch} />
            </>
          )}
        </Stack>
      )}
    </Center>
  )
}

export default GoogleCalendarSettings
