import * as React from 'react'

import {Box, Button, Center, Divider, Flex, FlexProps, HStack, Stack, Text, useToast} from '@chakra-ui/react'
import {Provider} from '@supabase/supabase-js'
import {FaFacebook} from 'react-icons/fa'
import {FcGoogle} from 'react-icons/fc'

import {supabase} from '@/api'
import {selectRedirectPath} from '@/auth/state'
import {WEB_BASE_URI} from '@/constants'
import {useAppSelector} from '@/store'

export type Props = FlexProps

const Social = (props: Props) => {
  const toast = useToast()

  const redirectPath = useAppSelector(selectRedirectPath)

  const handleProvider = React.useCallback(
    async (provider: Provider) => {
      try {
        const {error} = await supabase.auth.signInWithOAuth({
          options: {
            redirectTo: WEB_BASE_URI + redirectPath,
          },
          provider,
        })
        if (error) {
          throw new Error(error.message)
        }
      } catch (e) {
        toast({isClosable: true, status: 'error', title: 'Logowanie nie powiodło się'})
      }
    },
    [toast, redirectPath]
  )
  const handleGoogle = React.useCallback(() => handleProvider('google'), [handleProvider])
  const handleFacebook = React.useCallback(() => handleProvider('facebook'), [handleProvider])
  const handleApple = React.useCallback(() => handleProvider('apple'), [handleProvider])

  return (
    <Flex direction="column" width="100%" {...props}>
      <Center width="100%" mb={8} position="relative">
        <Text color="brand.gray.400" fontSize="xl" bg="#fff" px={8} zIndex={1}>
          lub zaloguj się za pomocą
        </Text>
        <Box w="100%" h="2px" position="absolute" bg="brand.gray.300" />
      </Center>
      <Stack direction={['column', null, 'row']} spacing={8} width="100%" justifyContent="center">
        <Button
          flex={1}
          colorScheme="facebook"
          leftIcon={<FaFacebook size={18} />}
          borderRadius="full"
          size="md"
          onClick={handleFacebook}
          minH="40px"
          _hover={{
            boxShadow: 'lg',
          }}
        >
          Facebook
        </Button>
        <Button
          bg="white"
          color="rgba(0,0,0,0.54)"
          flex={1}
          leftIcon={<FcGoogle size={18} />}
          size="md"
          onClick={handleGoogle}
          minH="40px"
          border="1px solid"
          borderColor="brand.gray.300"
          borderRadius="full"
          _hover={{
            boxShadow: 'lg',
          }}
        >
          Google
        </Button>
        {/*
          <Button flex={1} colorScheme="apple" leftIcon={<FaApple />} size="md" onClick={handleApple}>
            Apple ID
          </Button>
        */}
      </Stack>
    </Flex>
  )
}

export default Social
