import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {generatePath} from 'react-router-dom'

import {supabase} from '@/api'
import {BuyResult, OrderDiscountCode, SupportGroupJoinedRaw} from '@/api/models'
import {setModalState} from '@/auth/state'
import {toast} from '@/common/theme'
import {SUPPORT_GROUP_SHOWCASE} from '@/router/paths'
import {RootState} from '@/store'

export type supportGroupsOrderState = {
  active: boolean
  agreement: boolean
  submitting: boolean
  discountCode?: OrderDiscountCode
}

type supportGroupsState = {
  group?: SupportGroupJoinedRaw
  groupLoading: boolean
  order: supportGroupsOrderState
}

const initialOrderState: supportGroupsOrderState = {
  active: false,
  agreement: false,
  submitting: false,
}

const initialState: supportGroupsState = {
  groupLoading: false,
  order: initialOrderState,
}

export const fetchSupportGroup = createAsyncThunk(
  'supportGroups/fetchSupportGroup',
  async (groupID: number) => {
    const {data, error} = await supabase.from('support_groups_view').select('*').eq('id', groupID).single()
    if (error) throw error

    return data
  }
)

type InitSupportGroupOrderArgs = {
  groupID: number
  fetch?: boolean
}

export const initSupportGroupOrder = createAsyncThunk(
  'supportGroups/initSupportGroupOrder',
  async ({groupID, fetch}: InitSupportGroupOrderArgs, {dispatch, getState}) => {
    const {auth} = getState() as RootState

    if (!auth.profile && !auth.profileLoading) {
      const path = generatePath(SUPPORT_GROUP_SHOWCASE, {
        id: groupID,
      })
      const params = new URLSearchParams()
      params.set('order', 'true')

      dispatch(resetOrderState())
      dispatch(
        setModalState({
          redirectPath: `${path}?${params.toString()}`,
          state: 'signin',
        })
      )
      return
    }

    fetch && (await dispatch(fetchSupportGroup(groupID)))
  }
)

export const submitSupportGroupOrder = createAsyncThunk(
  'psychotherapy/submitPsychotherapyOrder',
  async (_, {getState}) => {
    const {
      group,
      order: {discountCode},
    } = (getState() as RootState).supportGroups

    if (!group?.id) return new Error('Missing order details')

    const {data, error} = await supabase.rpc('create_support_group_order', {
      support_group: group.id,
      discount_code: discountCode?.code ?? null,
    })
    if (error) throw error
    if (!data) throw new Error('No data')

    const res = data as any as BuyResult
    if ('error' in res) throw new Error(res.error)
    if (!res.redirect_url) throw new Error('Invalid redirect URL')

    window.location.href = res.redirect_url
  }
)

export const supportGroupsSlice = createSlice({
  initialState,
  name: 'supportGroups',
  reducers: {
    resetSupportGroupsState: () => {
      return initialState
    },
    resetOrderState: (state) => {
      state.order = initialOrderState
    },
    setOrderAgreement: (state, {payload}: PayloadAction<boolean>) => {
      state.order.agreement = payload
    },
    setOrderDiscountCode: (state, {payload}: PayloadAction<OrderDiscountCode | undefined>) => {
      state.order.discountCode = payload
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch
      .addCase(fetchSupportGroup.pending, (state) => {
        state.groupLoading = true
      })
      .addCase(fetchSupportGroup.fulfilled, (state, {payload}) => {
        state.groupLoading = false
        state.group = payload
      })
      .addCase(fetchSupportGroup.rejected, (state, error) => {
        state.groupLoading = false

        console.error('Failed to fetch support group', error)
        toast({
          title: 'Nie znaleziono grupy wsparcia',
          status: 'error',
          isClosable: true,
        })
      })
      // init order
      .addCase(initSupportGroupOrder.pending, (state) => {
        state.order.active = true
      })
      .addCase(initSupportGroupOrder.rejected, (state, error) => {
        state.order = initialOrderState

        console.error('Failed to init support group order', error)
        toast({
          title: 'Nie udało się rozpocząć zamówienia',
          status: 'error',
          isClosable: true,
        })
      })
      // submit order
      .addCase(submitSupportGroupOrder.pending, (state) => {
        state.order.submitting = true
      })
      .addCase(submitSupportGroupOrder.rejected, (state, {error}) => {
        state.order.submitting = false

        console.error('Failed to submit support group order', error)
        toast({
          title: 'Nie udało się złożyć zamówienia',
          status: 'error',
          isClosable: true,
        })
      })
  },
})

export const {resetSupportGroupsState, resetOrderState, setOrderAgreement, setOrderDiscountCode} =
  supportGroupsSlice.actions

export const selectSupportGroup = (state: RootState) => state.supportGroups.group
export const selectSupportGroupLoading = (state: RootState) => state.supportGroups.groupLoading

export const selectOrder = (state: RootState) => state.supportGroups.order
export const selectOrderActive = (state: RootState) => state.supportGroups.order.active
export const selectOrderAgreement = (state: RootState) => state.supportGroups.order.agreement
export const selectOrderSubmitting = (state: RootState) => state.supportGroups.order.submitting
export const selectOrderDiscountCode = (state: RootState) => state.supportGroups.order.discountCode

export default supportGroupsSlice.reducer
