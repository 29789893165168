import React from 'react'

import {Text} from '@chakra-ui/react'
import {createPluginFactory, PlateRenderElementProps} from '@udecode/plate'

export const HeadingOneRenderer = ({attributes, children}: PlateRenderElementProps) => {
  return (
    <Text as="h1" fontSize="36px" mb={4} {...attributes}>
      {children}
    </Text>
  )
}

export const createHeadingOnePlugin = createPluginFactory({
  component: HeadingOneRenderer,
  isElement: true,
  key: 'heading-one',
  type: 'heading-one',
})
