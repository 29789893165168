import * as React from 'react'

import {Box, Container, Flex, Grid, GridItem, Image, Link, Text} from '@chakra-ui/react'
import {Link as RouterLink} from 'react-router-dom'

import {ArrowIcon} from '@/common/arrow-button'
import {SUPPORT_GROUPS_FEATURE} from '@/constants'
import {COURSES, PSYCHOTHERAPY, SUPPORT_GROUPS} from '@/router/paths'

import courses from './courses.jpg'
import psychotherapy from './psychotherapy.jpg'
import supportGroups from './support-groups.jpg'

const products: ProductProps[] = [
  {
    description:
      'Chcesz nauczyć się wykorzystywać swój potencjał i sprawnie pokonywać przeszkody? A może zależy Ci na wsparciu bliskiej osoby w walce z nałogiem lub depresją? Warsztaty to możliwość ciągłego rozwoju i sposób na radzenia sobie z codziennym stresem.',
    name: 'Warsztaty',
    srcSet: `${courses} 1x`,
    to: COURSES,
  },
  {
    description:
      'Chcesz żyć lepiej i pełniej? Szukasz wewnętrznej równowagi? A może czujesz smutek, lęk, zmagasz się z depresją? Psychoterapia pomoże Ci odzyskać radość życia. Znajdź  terapeutę z doświadczeniem i kompetencjami w obszarze, który Cię interesuje i rozpocznij terapię online.',
    name: 'Psychoterapia',
    srcSet: `${psychotherapy} 1x`,
    to: PSYCHOTHERAPY,
  },
  ...(SUPPORT_GROUPS_FEATURE === 'true'
    ? [
        {
          description:
            'Chcesz zrozumieć siebie i swoje funkcjonowanie w danej sytuacji? Szukasz osób, które zmagają się z tym, co Ty? Kiedy dzielisz się swoimi doświadczeniami i inni robią to samo, możecie się uczyć od siebie i wspierać się nawzajem.',
          name: 'Grupy wsparcia',
          srcSet: `${supportGroups} 1x`,
          to: SUPPORT_GROUPS,
        },
      ]
    : []),
]

type ProductProps = {
  srcSet: string
  name: string
  to: string
  description: string
}

const Product = ({srcSet, name, to, description}: ProductProps) => {
  return (
    <GridItem position="relative" role="group">
      <Box position="absolute" left="0px" top="0px" bottom="0px" right="0px" overflow="hidden">
        <Image
          srcSet={srcSet}
          objectFit="cover"
          objectPosition="center"
          width="100%"
          height="100%"
          transition="all .2s ease-in-out"
          transform="scale(1.02)"
          _groupHover={{transform: 'scale(1.1)'}}
          zIndex={-1}
        />
        <Box
          position="absolute"
          left="0px"
          top="0px"
          bottom="0px"
          right="0px"
          zIndex={0}
          backgroundColor="brand.green.800"
          transition="opacity .2s ease-in-out"
          opacity={0}
          _groupHover={{opacity: 0.5}}
        />
      </Box>
      <Link
        as={RouterLink}
        to={to}
        position="absolute"
        left="0px"
        top="0px"
        bottom="0px"
        right="0px"
        backgroundColor="rgba(7, 51, 44, 0.4)"
      />
      <Flex
        direction="column"
        position="absolute"
        left="0px"
        top="0px"
        bottom="0px"
        right="0px"
        pointerEvents="none"
      >
        <Flex
          direction="row"
          justifyContent="center"
          position="absolute"
          top={[null, null, 'calc(50% - 22.5px)']}
          height={['100%', null, 'auto']}
          width={['100%', null, 'auto']}
          alignItems="center"
        >
          <Flex direction="column" flex={1} alignItems="center">
            <Flex direction="row" alignItems="center" justifyContent="center" width="100%">
              <Text as="h3" pr={6} flexShrink={0}>
                {name}
              </Text>
              <ArrowIcon
                color="brand.yellow.600"
                _groupHover={{color: 'brand.yellow.700'}}
                width={46}
                flexShrink={0}
                transitionProperty="common"
                transitionDuration="normal"
              />
            </Flex>
            <Text
              fontSize="md"
              fontWeight={400}
              mt={12}
              width="60%"
              opacity={0}
              _groupHover={{opacity: 1}}
              transition="opacity .2s ease-in-out"
              display={['none', null, 'block']}
            >
              {description}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </GridItem>
  )
}

const Products = () => {
  return (
    <>
      <Flex
        height={[70, null, 100]}
        bg="brand.green.800"
        color="white"
        py={4}
        direction="row"
        alignItems="center"
      >
        <Container maxW="container.xl" px={[8, null, 4]}>
          <Text as="h2" fontSize={['xl', null, '3xl']}>
            Jaką formę pracy nad sobą wybierasz?
          </Text>
        </Container>
      </Flex>
      <Box height={580} position="relative">
        <Grid
          templateColumns={['repeat(1, 1fr)', null, `repeat(${products.length}, 1fr)`]}
          gap={0}
          fontSize={['xl', null, '3xl']}
          fontWeight={500}
          color="white"
          height="100%"
        >
          {products.map((product) => (
            <Product
              key={product.name}
              srcSet={product.srcSet}
              name={product.name}
              to={product.to}
              description={product.description}
            />
          ))}
        </Grid>
      </Box>
    </>
  )
}

export default Products
