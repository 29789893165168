import * as React from 'react'

import {
  Container,
  Stack,
  Text,
  Button,
  useToast,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom'

import {supabase} from '@/api'
import useLoadingState from '@/common/use-loading-state'
import {HOME} from '@/router/paths'

import {minPasswordLength} from '../constants'

const CompletePasswordReset = () => {
  const toast = useToast()
  const history = useHistory()
  const [password, setPassword] = React.useState('')
  const [repeatPassword, setRepeatPassword] = React.useState('')

  const handlePasswordChange = React.useCallback(({target: {value}}) => setPassword(value), [setPassword])
  const handleRepeatPasswordChange = React.useCallback(
    ({target: {value}}) => setRepeatPassword(value),
    [setRepeatPassword]
  )

  const _handleSubmitResetPassword = React.useCallback(async () => {
    if (!password) {
      return
    }
    try {
      const {error} = await supabase.auth.updateUser({password})
      if (error) {
        throw new Error(error.message)
      }
      toast({isClosable: true, status: 'success', title: 'Hasło zresetowane pomyślnie'})
      history.replace(HOME)
    } catch (e) {
      console.error('Failed to reset password', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Błąd przy resetowaniu hasła',
      })
    }
  }, [password, toast, history])
  const {loading: loadingResetPassword, handleSubmit: handleSubmitResetPassword} =
    useLoadingState(_handleSubmitResetPassword)

  const isPasswordValid = React.useMemo(() => !password || password.length >= minPasswordLength, [password])
  const isRepeatPasswordValid = React.useMemo(
    () => !repeatPassword || password === repeatPassword,
    [password, repeatPassword]
  )
  const isResetPasswordSubmitDisabled = React.useMemo(
    () => !password || !isPasswordValid || !repeatPassword || !isRepeatPasswordValid,
    [password, isPasswordValid, repeatPassword, isRepeatPasswordValid]
  )

  return (
    <Container maxW="container.xl">
      <Stack py={4} width="30%">
        <Text fontWeight="bold">Zresetuj swoje hasło</Text>
        <FormControl id="password" isInvalid={!isPasswordValid} isDisabled={loadingResetPassword}>
          <FormLabel>Hasło (co najmniej 6 znaków)</FormLabel>
          <Input type="password" value={password} onChange={handlePasswordChange} />
          <FormErrorMessage>Niepoprawne hasło</FormErrorMessage>
        </FormControl>
        <FormControl id="repeatPassword" isInvalid={!isRepeatPasswordValid} isDisabled={loadingResetPassword}>
          <FormLabel>Powtórz hasło</FormLabel>
          <Input type="password" value={repeatPassword} onChange={handleRepeatPasswordChange} />
          <FormErrorMessage>Hasła nie są identyczne</FormErrorMessage>
        </FormControl>
        <Button
          isLoading={loadingResetPassword}
          isDisabled={isResetPasswordSubmitDisabled}
          onClick={handleSubmitResetPassword}
        >
          Resetuj hasło
        </Button>
      </Stack>
    </Container>
  )
}

export default CompletePasswordReset
