import React from 'react'

import {supabase} from '@/api'
import {AdminSupportGroupUser, SupabaseRPCResult, SupportGroupRole} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, emptySupportGroupUser, filterFields} from './constants'
import SupportGroupUserEditorModal from './editor-modal'

const SupportGroupAccessManagementTable = ({
  supportGroupID,
  role,
}: {
  supportGroupID: number
  role: SupportGroupRole
}) => {
  const tableState = useTableState({sortBy: 'updated_at', descending: true})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminSupportGroupUser>({
    descending: tableState.descending,
    table: 'admin_support_group_users',
    fields: '*',
    pageNumber: tableState.page,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminSupportGroupUser,
    filter: tableState.filter,
    finalize: React.useCallback(
      (b) => b.eq('support_group', supportGroupID).eq('role', role),
      [supportGroupID, role]
    ),
  })

  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<AdminSupportGroupUser>()

  const {
    value: deleteValue,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useValueDisclosure<AdminSupportGroupUser>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptySupportGroupUser)
  }, [onEditorOpen])

  const handleDelete = React.useCallback(async () => {
    const {data, error} = await supabase.rpc('delete_support_group_user', {
      support_group_id: deleteValue?.support_group,
      user_id: deleteValue?.user,
    })
    if (error) throw error
    const res = data as any as SupabaseRPCResult
    if ('error' in res) throw new Error(res.error)
  }, [deleteValue])

  const actions = React.useMemo(
    () => [editAction(onEditorOpen), deleteAction(onDeleteOpen)],
    [onEditorOpen, onDeleteOpen]
  )

  return (
    <>
      <DataTable<AdminSupportGroupUser>
        columns={columns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
        loading={loading}
      />
      <SupportGroupUserEditorModal
        item={editorValue}
        role={role}
        supportGroupID={supportGroupID}
        open={!!editorValue}
        onComplete={fetch}
        onClose={onEditorClose}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
        headerText="Usunąć użytkownika z grupy wsparcia?"
        onSuccessTitle="Użytkownik został usunięty z grupy wsparcia"
        onFailTitle="Nie udało się usunąć użytkownika z grupy wsparcia"
      />
    </>
  )
}

export default SupportGroupAccessManagementTable
