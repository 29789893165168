import {formatDuration, Duration, intervalToDuration} from 'date-fns'

const formatDistanceLocale = {
  xHours: '{{count}} godz.',
  xMinutes: '{{count}} min.',
  xSeconds: '{{count}} sek.',
}
const shortPLLocale = {
  formatDistance: (token: keyof typeof formatDistanceLocale, count: string) =>
    formatDistanceLocale[token].replace('{{count}}', count),
}

export const formatInterval = (start: Date, end: Date) =>
  formatDurationShort(intervalToDuration({start: new Date(start), end: new Date(end)}))

export const formatDurationShort = (interval: Duration) => {
  return formatDuration(interval, {format: ['hours', 'minutes', 'seconds'], locale: shortPLLocale})
}

export const sleep = (time: number) => {
  return new Promise<void>((res) => setTimeout(() => res(), time))
}
