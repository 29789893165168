import React from 'react'

import {Box, ChakraProvider, ColorModeScript, Flex} from '@chakra-ui/react'
import '@fontsource/lexend/300.css'
import '@fontsource/lexend/400.css'
import '@fontsource/lexend/500.css'
import '@fontsource/lexend/600.css'
import 'pure-react-carousel/dist/react-carousel.es.css'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {ParallaxProvider} from 'react-scroll-parallax'

import {AbilityContext, defineAbilitiesFor} from '@/auth/abilities'
import SignInModal from '@/auth/signin/modal'
import {selectProfile} from '@/auth/state'
import ReviewModal from '@/common/reviews/review-modal'
import theme from '@/common/theme'
import CoursesOrderModal from '@/courses/order-modal'
import Footer from '@/layout/footer'
import Navbar from '@/layout/navbar'
import CookieConsentBar from '@/privacy-policy/cookie-consent-bar'
import SupportGroupOrderModal from '@/psychotherapy/support-groups/order-modal'
import PsychotherapyOrderModal from '@/psychotherapy/therapists/order-modal'
import Router from '@/router'
import {store, useAppSelector} from '@/store'

TagManager.initialize({
  gtmId: 'GTM-PZX82BX',
})

const App = () => {
  const user = useAppSelector(selectProfile)
  const context = React.useMemo(() => defineAbilitiesFor(user), [user])

  return (
    <AbilityContext.Provider value={context}>
      <SignInModal />
      <CoursesOrderModal />
      <PsychotherapyOrderModal />
      <SupportGroupOrderModal />
      <ReviewModal />
      <Flex minH="100vh" direction="column">
        <Navbar />
        <Box flex={1} width="100%">
          <Router />
        </Box>
        <Footer />
      </Flex>
      <CookieConsentBar />
    </AbilityContext.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <ParallaxProvider>
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>
      </ParallaxProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
