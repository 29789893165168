import {PayloadAction, SerializedError, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {formatISO, parseISO} from 'date-fns'
import {generatePath} from 'react-router-dom'

import {supabase} from '@/api'
import {
  BuyResult,
  MyCourse,
  OrderDiscountCode,
  PublicTherapist,
  TherapistAssignedServiceRaw,
} from '@/api/models'
import {setModalState} from '@/auth/state'
import {PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {RootState} from '@/store'

export type psychotherapyOrderState = {
  active: boolean
  agreement: boolean
  submitting: boolean
  summary: boolean
  discountCode?: OrderDiscountCode
  service?: TherapistAssignedServiceRaw
  date?: string
  course?: MyCourse // used when user selects course meeting service type
}

export type psychotherapyState = {
  therapist?: PublicTherapist
  therapistLoading: boolean
  services: TherapistAssignedServiceRaw[]
  servicesLoading: boolean
  order: psychotherapyOrderState
  error?: {title: string; error: SerializedError; description?: string}
}

const inititialOrderState: psychotherapyOrderState = {
  active: false,
  agreement: false,
  submitting: false,
  summary: false,
}

const initialState: psychotherapyState = {
  therapistLoading: false,
  services: [],
  servicesLoading: false,
  order: inititialOrderState,
}

type StartTherapyOrderArgs = {
  therapist: number
  service?: string
  date?: Date
  fetch?: boolean
}

export const fetchTherapist = createAsyncThunk(
  'psychotherapy/fetchTherapist',
  async (id: number, {dispatch}) => {
    const {data, error} = await supabase.from('public_therapists').select('*').match({id}).single()
    if (error) throw error

    await dispatch(fetchServices(id))

    return data
  }
)

export const fetchServices = createAsyncThunk('psychotherapy/fetchServices', async (id: number) => {
  const {data, error} = await supabase
    .from('therapist_assigned_services')
    .select('*')
    .match({therapist: id})
    .order('order')
  if (error) throw error

  return data
})

export const startPsychotherapyOrder = createAsyncThunk(
  'psychotherapy/startPsychotherapyOrder',
  async ({therapist, service, date, fetch}: StartTherapyOrderArgs, {dispatch, getState}) => {
    const {auth} = getState() as RootState

    if (!auth.profile && !auth.profileLoading) {
      const path = generatePath(PSYCHOTHERAPY_THERAPIST, {
        id: therapist,
      })
      const params = new URLSearchParams()
      service && params.set('service', service)
      date && params.set('date', date.getTime().toString())

      dispatch(setOrderDone())
      dispatch(
        setModalState({
          redirectPath: `${path}?${params.toString()}`,
          state: 'signin',
        })
      )
      return
    }

    fetch && (await dispatch(fetchTherapist(therapist)))

    return {
      service: (getState() as RootState).psychotherapy.services.find((s) => s.id === service),
      date: date ? formatISO(date) : undefined,
    }
  }
)

export const startCourseMeetingOrder = createAsyncThunk(
  'psychotherapy/startCourseMeetingOrder',
  async (courseID: number, {dispatch, getState}) => {
    // there is no way to start course meeting order without being logged in
    if (!(getState() as RootState).auth.profile) return

    const {data: course, error: courseError} = await supabase
      .from('my_courses')
      .select('*')
      .match({id: courseID})
      .single()
    if (courseError) throw courseError
    if (!course.coach.therapist_id) throw new Error('No coach assigned')
    if (!course.meetings_left) throw new Error('No meetings left')

    const {data: service, error: serviceError} = await supabase
      .from('course_meeting_service_type')
      .select('*')
      .single()
    if (serviceError) throw serviceError

    await dispatch(fetchTherapist(course.coach.therapist_id))

    return {
      service,
      course,
    }
  }
)

export const submitPsychotherapyOrder = createAsyncThunk(
  'psychotherapy/submitPsychotherapyOrder',
  async (_, {getState}) => {
    const {
      therapist,
      order: {service, date, course, discountCode},
    } = (getState() as RootState).psychotherapy

    if (!therapist?.id || !service || !date) return new Error('Missing order details')

    const {data, error} = await supabase.rpc('create_meeting_event', {
      at: date,
      service: service.id,
      therapist: therapist.id,
      course: course?.id ?? null,
      discount_code: discountCode?.code ?? null,
    })
    if (error) throw error
    if (!data) throw new Error('No data')

    const res = data as any as BuyResult
    if ('error' in res) throw new Error(res.error)
    if (!res.redirect_url) throw new Error('Invalid redirect URL')

    window.location.href = res.redirect_url
  }
)

export const encodeDate = (date: Date) => formatISO(date)
export const decodeDate = (date: string) => parseISO(date)

export const psychotherapySlice = createSlice({
  initialState,
  name: 'psychotherapy',
  reducers: {
    resetPsychotherapyState: () => {
      return initialState
    },
    setOrderActive: (state, {payload}: PayloadAction<boolean>) => {
      state.order.active = payload
    },
    setOrderAgreement: (state, {payload}: PayloadAction<boolean>) => {
      state.order.agreement = payload
    },
    setIsSummary: (state, {payload}: PayloadAction<boolean>) => {
      state.order.summary = payload
    },
    setOrderDate: (state, {payload}: PayloadAction<string | undefined>) => {
      state.order.date = payload
    },
    setOrderDone: (state) => {
      state.order = inititialOrderState
    },
    setOrderService: (state, {payload}: PayloadAction<TherapistAssignedServiceRaw | undefined>) => {
      state.order.service = payload
      state.order.date = undefined
      state.order.course = undefined
    },
    setOrderCourse: (state, {payload}: PayloadAction<MyCourse | undefined>) => {
      state.order.course = payload
    },
    setOrderDiscountCode: (state, {payload}: PayloadAction<OrderDiscountCode | undefined>) => {
      state.order.discountCode = payload
    },
  },
  extraReducers: (builder) => {
    builder
      // Therapist
      .addCase(fetchTherapist.pending, (state) => {
        state.therapistLoading = true
      })
      .addCase(fetchTherapist.fulfilled, (state, action) => {
        state.therapist = action.payload ?? undefined
        state.therapistLoading = false
      })
      .addCase(fetchTherapist.rejected, (state, {error}) => {
        state.therapist = undefined
        state.error = {error, title: 'Nie udało się pobrać informacji o psychoterapeucie'}
      })
      // Services
      .addCase(fetchServices.pending, (state) => {
        state.servicesLoading = true
      })
      .addCase(fetchServices.fulfilled, (state, {payload}) => {
        state.services = payload ?? []
        if (!state.order.service && payload?.length) state.order.service = payload[0]
        state.servicesLoading = false
      })
      .addCase(fetchServices.rejected, (state, {error}) => {
        state.services = []
        state.error = {error, title: 'Nie udało się pobrać informacji o usługach psychoterapeuty'}
      })
      // Therapy order
      .addCase(startPsychotherapyOrder.pending, (state) => {
        state.order.active = true
      })
      .addCase(startPsychotherapyOrder.fulfilled, (state, {payload}) => {
        state.order.service = payload?.service ?? state.order.service
        state.order.date = payload?.date ?? state.order.date
      })
      .addCase(startPsychotherapyOrder.rejected, (state, {error}) => {
        state.order = inititialOrderState
        state.error = {error, title: 'Nie udało się rozpocząć zamówienia'}
      })
      .addCase(submitPsychotherapyOrder.pending, (state) => {
        state.order.submitting = true
      })
      .addCase(submitPsychotherapyOrder.rejected, (state, {error}) => {
        state.order.submitting = false
        state.error = {
          error,
          title: 'Nie udało się złożyć zamówienia',
          description: error.message?.includes('locked')
            ? 'Termin jest już zarezerwowany przez innego użytkownika'
            : error.message?.includes('occupied')
            ? 'Termin został wykupiony przez innego użytkownika'
            : undefined,
        }
      })
      // Course meeting order
      .addCase(startCourseMeetingOrder.pending, (state) => {
        state.order.active = true
      })
      .addCase(startCourseMeetingOrder.fulfilled, (state, {payload}) => {
        state.order.service = payload?.service ?? state.order.service
        state.order.course = payload?.course
      })
      .addCase(startCourseMeetingOrder.rejected, (state, {error}) => {
        state.order = inititialOrderState
        state.error = {error, title: 'Nie udało się rozpocząć zamówienia'}
      })
  },
})

export const {
  resetPsychotherapyState,
  setOrderActive,
  setOrderAgreement,
  setOrderDate,
  setOrderDone,
  setOrderService,
  setIsSummary,
  setOrderCourse,
  setOrderDiscountCode,
} = psychotherapySlice.actions

export const selectPsychotherapyError = (state: RootState) => state.psychotherapy.error
export const selectTherapist = (state: RootState) => state.psychotherapy.therapist
export const selectTherapistLoading = (state: RootState) => state.psychotherapy.therapistLoading

export const selectServices = (state: RootState) => state.psychotherapy.services
export const selectServicesLoading = (state: RootState) => state.psychotherapy.servicesLoading

export const selectOrder = (state: RootState) => state.psychotherapy.order
export const selectOrderActive = (state: RootState) => state.psychotherapy.order.active
export const selectOrderAgreement = (state: RootState) => state.psychotherapy.order.agreement
export const selectOrderCourse = (state: RootState) => state.psychotherapy.order.course
export const selectOrderService = (state: RootState) => state.psychotherapy.order.service
export const selectOrderSubmitting = (state: RootState) => state.psychotherapy.order.submitting
export const selectOrderDate = (state: RootState) => state.psychotherapy.order.date
export const selectOrderDiscountCode = (state: RootState) => state.psychotherapy.order.discountCode
export const selectIsSummary = (state: RootState) => state.psychotherapy.order.summary

export default psychotherapySlice.reducer
