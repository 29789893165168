import * as React from 'react'

import {Box, Button, Flex, Spinner, Stack, Text} from '@chakra-ui/react'
import {formatDistanceToNowStrict} from 'date-fns'
import {pl} from 'date-fns/locale'
import {generatePath, Link as RouterLink} from 'react-router-dom'

import {PublicWebinar} from '@/api/models'
import {ArrowIconButton} from '@/common/arrow-button'
import useFetchSingle from '@/common/use-fetch-single'
import {HOME, PSYCHOTHERAPISTS, PSYCHOTHERAPY_THERAPIST, WEBINAR} from '@/router/paths'

const WebinarBanner = () => {
  const {data: webinar, loading} = useFetchSingle<PublicWebinar>({
    table: 'public_webinars',
    fields: '*',
    order: ['featured_index', 'start_at'],
  })

  if (loading)
    return (
      <Flex w="100%" position="absolute" top={0} bg="white" justify="center" py={4}>
        <Spinner />
      </Flex>
    )

  if (!webinar) return null

  return (
    <Flex w="100%" position="absolute" top={0} bg="white" justify="center">
      <Stack
        w="100%"
        maxW="container.xl"
        direction="row"
        spacing={10}
        alignItems="center"
        justifyContent="space-between"
        py={[2, null, 0]}
        px={[8, null, 4]}
      >
        <Stack
          flex={1}
          direction={['column', null, 'row']}
          justifyContent="space-between"
          alignItems={['flex-start', null, 'center']}
          spacing={4}
          minHeight={['none', null, '70px']}
        >
          <Stack direction={['column', null, 'row']} spacing={2}>
            <Text fontWeight={500} fontSize={['sm', null, 'md']}>
              {webinar.name}
            </Text>
            {!!webinar.hosts.length && (
              <>
                <Text fontWeight={300} display={['none', null, 'block']}>
                  Webinarium poprowadzi
                </Text>
                <Flex wrap="wrap">
                  {webinar?.hosts.map((h, i) => (
                    <Box key={i}>
                      {i > 0 && <Text mr={2}>i</Text>}
                      <Button
                        mr={2}
                        variant="brandLink"
                        as={RouterLink}
                        to={
                          h.therapist_id
                            ? generatePath(PSYCHOTHERAPY_THERAPIST, {
                                id: h.therapist_id,
                                slug: h.slug,
                              })
                            : PSYCHOTHERAPISTS
                        }
                      >
                        {h.full_name}
                      </Button>
                    </Box>
                  ))}
                </Flex>
              </>
            )}
          </Stack>
          <Flex direction="row" display={['none', null, 'block']}>
            <Text fontWeight={300}>
              {formatDistanceToNowStrict(new Date(webinar.start_at), {
                addSuffix: true,
                locale: pl,
              })}
            </Text>
          </Flex>
        </Stack>
        <ArrowIconButton
          aria-label="Więcej informacji o webinarze"
          color="brand.green.600"
          _hover={{color: 'brand.green.700'}}
          width={['28px', null, '46px']}
          as={RouterLink}
          to={webinar.id ? generatePath(WEBINAR, {id: webinar.id, slug: webinar.slug}) : HOME}
        />
      </Stack>
    </Flex>
  )
}

export default WebinarBanner
