import React from 'react'

import {TherapistService, TherapistServiceRaw} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import BreakDuration from './break-duration'
import {columns, defaultSelectedColumns, filterFields, emptyTherapistService} from './constants'
import TherapistServiceEditorModal from './editor-modal'
import {parseTherapistServiceType} from './utils'

const TherapistServices = () => {
  const tableState = useTableState({sortBy: 'order'})
  const {loading, data, fetch, rows} = useSupabaseQuery<TherapistServiceRaw>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof TherapistServiceRaw,
    pageNumber: tableState.page,
    table: 'therapist_service_types',
  })

  const {
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
    value: deleteValue,
  } = useValueDisclosure<TherapistService>()

  const {
    onClose: onEditorClose,
    onOpen: onEditorOpen,
    value: editorValue,
  } = useValueDisclosure<TherapistService>()

  const actions = React.useMemo(
    () => [editAction(onEditorOpen), deleteAction(onDeleteOpen)],
    [onEditorOpen, onDeleteOpen]
  )

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyTherapistService)
  }, [onEditorOpen])

  const servicesData = React.useMemo(() => data?.map((s) => parseTherapistServiceType(s)), [data])

  return (
    <>
      <DataTable<TherapistService>
        columns={columns}
        data={servicesData ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <BreakDuration />
      <TherapistServiceEditorModal
        item={editorValue}
        onClose={onEditorClose}
        open={!!editorValue}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="therapist_service_types"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default TherapistServices
