import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'

import OrdersTable from './table'

const Orders = () => {
  return (
    <Stack direction="column" spacing={8} ml={[0, null, 24]} mt={6} px={[4, null, 0]}>
      <Text fontSize="lg" fontWeight={500}>
        Wykaz płatności
      </Text>
      <OrdersTable />
    </Stack>
  )
}

export default Orders
