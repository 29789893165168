import * as React from 'react'

import {Center, Container, Heading, Spinner, Stack} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import {OrderModal} from '@/common/order'
import {useAppDispatch, useAppSelector} from '@/store'

import {resetOrderState, selectOrderActive, selectSupportGroup, selectSupportGroupLoading} from '../state'
import SupportGroupOrderButtons from './buttons'
import GroupFull from './group-full'
import SupportGroupOrderSummary from './summary'

const SupportGroupOrderModal = () => {
  const dispatch = useAppDispatch()

  const active = useAppSelector(selectOrderActive)
  const group = useAppSelector(selectSupportGroup)
  const groupLoading = useAppSelector(selectSupportGroupLoading)

  React.useEffect(() => {
    if (!group) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `support_group:${group.id}`,
              item_name: `Grupa wsparcia - ${group.name}`,
              value: (group.price || 0) / 100,
            },
          ],
          value: (group.price || 0) / 100,
        },
        event: 'begin_checkout',
      },
    })
  }, [group])

  const handleClose = React.useCallback(() => dispatch(resetOrderState()), [dispatch])

  return (
    <OrderModal open={active} onClose={handleClose}>
      {groupLoading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : !group ? (
        <Center>
          <Heading fontSize="xl">Coś poszło nie tak...</Heading>
        </Center>
      ) : group.occupied_slots >= group.max_members ? (
        <GroupFull />
      ) : (
        <Stack spacing={8}>
          <SupportGroupOrderSummary />
          <SupportGroupOrderButtons />
        </Stack>
      )}
    </OrderModal>
  )
}

export default SupportGroupOrderModal
