import * as React from 'react'

import {Box, Container, Flex, Grid, GridItem, Text} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {Element, scroller} from 'react-scroll'

import {PublicCourse} from '@/api/models'
import CategoriesFilter from '@/common/components/categories-filter'
import Header from '@/common/components/header'
import LoadingListItem from '@/common/components/loading-list-item'
import useTableState from '@/common/data-table/use-table-state'
import NotForMe from '@/common/not-for-me'
import {Pagination, usePagination} from '@/common/pagination'
import useSupabaseQuery from '@/common/use-custom-query'

import header from './header.jpg'
import CourseItem from './item'

const countPerPage = 6

const Courses = () => {
  const tableState = useTableState({sortBy: 'order'})
  const {data, loading, rows} = useSupabaseQuery<PublicCourse>({
    table: 'public_courses',
    fields: '*',
    order: tableState.sortBy.column as keyof PublicCourse,
    itemsPerPage: countPerPage,
    pageNumber: tableState.page,
    filter: tableState.filter,
  })

  const setPage = React.useCallback(
    (page: number) => {
      tableState.setPage(page - 1)
      scroller.scrollTo('courses-list' as any, {
        duration: 500,
        offset: -150,
        smooth: true,
      })
    },
    [tableState]
  )

  const {items} = usePagination({
    countPerPage,
    maxShownPages: 7,
    page: tableState.page + 1,
    totalCount: rows ?? 0,
  })

  React.useEffect(() => {
    if (!data) {
      return
    }

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          item_list_id: 'courses-list',
          item_list_name: 'Courses list',
          items: data.map((item) => ({
            currency: 'PLN',
            item_id: `course:${item.id}`,
            item_name: `Warsztat - ${item.name}`,
            value: (item.base_price || 0) / 100,
          })),
        },
        event: 'view_item_list',
      },
    })
  }, [data])

  return (
    <>
      <Header img={header} title="Warsztaty">
        <Text fontSize="md" fontWeight={400}>
          Chcesz nauczyć się wykorzystywać swój potencjał i sprawniej pokonywać trudności? A może zależy Ci na
          wsparciu w walce z nałogiem lub depresją? Warsztaty to możliwość nabycia konkretnych umiejętności –
          czeka Cię sporo ćwiczeń i zbierania z nich doświadczeń.
        </Text>
      </Header>
      <Element name="courses-list" />
      <Container maxW="container.xl" mt={[8, null, 16]}>
        <Box mb={[8, null, 16]}>
          <CategoriesFilter tableState={tableState} table="course_categories" />
        </Box>
        <Box display={['block', null, 'none']} mb={8}>
          <Text fontSize="xl" fontWeight={500}>
            Nasze warsztaty
          </Text>
        </Box>
        <Flex mb={[8, null, 16]} px={[8, null, 0]}>
          <Grid
            templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
            columnGap={8}
            rowGap={16}
            mb={8}
            width="100%"
          >
            {loading
              ? Array.from(Array(countPerPage), (_, i) => (
                  <GridItem key={i}>
                    <LoadingListItem />
                  </GridItem>
                ))
              : data?.map((course, i) => (
                  <GridItem key={i}>
                    <CourseItem {...course} />
                  </GridItem>
                ))}
          </Grid>
        </Flex>
        <Flex direction="row" justifyContent="center" mb={16}>
          <Pagination
            countPerPage={countPerPage}
            loading={loading}
            items={items}
            page={tableState.page + 1}
            setPage={setPage}
          />
        </Flex>
      </Container>
      <NotForMe title="Szukasz metody innej niż warsztaty?" selected="courses" />
    </>
  )
}

export default Courses
