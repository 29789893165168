import {ComponentStyleConfig} from '@chakra-ui/react'
import defaultTheme from '@chakra-ui/theme'

const baseStyle = () => ({
  borderRadius: 'full',
})

const brandLink = (props: any) => {
  return {
    ...defaultTheme.components.Button.variants?.link(props),
    color: 'brand.green.600',
    fontWeight: '400',
  }
}

const Button: ComponentStyleConfig = {
  baseStyle,
  variants: {
    brandLink,
  },
}

export default Button
