import * as React from 'react'

import {Flex, Image} from '@chakra-ui/react'

import logoImage from './logo.svg'
import textImage from './text.svg'

const Logo = () => {
  return (
    <Flex wrap="nowrap">
      <Image src={logoImage} mr={4} />
      <Image src={textImage} alt="Psychoforma" />
    </Flex>
  )
}

export default Logo
