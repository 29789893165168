import React from 'react'

import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {GetTherapistCertificatesResult} from '@/api/models'
import {uploadFile} from '@/api/utils'
import LabeledSpinner from '@/common/components/labeled-spinner'
import FileUpload from '@/common/file-upload'
import useLoadingState from '@/common/use-loading-state'
import {SUPABASE_THERAPIST_CERTIFICATES_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptyTherapistCertificate} from '../constants'
import {TherapistCertificate} from '../types'
import TherapistCertificateItem from './certificate-item'
import TherapistCertificates from './certificates'

const TherapistsCertificatesModal = ({
  open,
  onClose,
  therapist,
  onComplete,
}: Omit<CommonModalProps, 'onComplete'> & {
  therapist: number | null
  onComplete: () => void
}) => {
  const [newCertificate, setNewCertificate] = React.useState<TherapistCertificate>(emptyTherapistCertificate)
  const [newCertificateURL, setNewCertificateURL] = React.useState('')

  const [certificates, setCertificates] = React.useState<TherapistCertificate[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [isUploading, setIsUploading] = React.useState(false)

  const handleClose = React.useCallback(() => {
    setNewCertificate(emptyTherapistCertificate)
    setNewCertificateURL('')
    setCertificates([])
    onComplete()
    onClose()
  }, [onClose, onComplete])

  const fetchCertificates = React.useCallback(async () => {
    setIsLoading(true)
    try {
      const {data, error} = await supabase.rpc('get_therapist_certificates', {
        therapist_id: therapist,
      })
      if (error) {
        throw error
      }
      const result = data as any as GetTherapistCertificatesResult
      if ('error' in result) {
        throw new Error(result.error)
      }
      setCertificates(result.certificates ?? [])
    } catch (e) {
      console.error('Failed to fetch certificates', e)
    }
    setIsLoading(false)
  }, [therapist])

  React.useEffect(() => {
    if (!therapist) {
      return
    }
    fetchCertificates()
  }, [therapist]) // eslint-disable-line

  const handleCertificateUpload = React.useCallback(async (file: File) => {
    setIsUploading(true)
    try {
      const [filepath, signedURL] = await uploadFile(file, SUPABASE_THERAPIST_CERTIFICATES_BUCKET)
      setNewCertificate((c) => ({...c, path: filepath}))
      setNewCertificateURL(signedURL)
    } catch (e) {
      console.error('Failed to upload therapist certificate', e)
    }
    setIsUploading(false)
  }, [])

  const handleNameChange = React.useCallback(
    ({target: {value}}) => setNewCertificate((c) => ({...c, name: value})),
    []
  )

  const handleVisiblityChange = React.useCallback(
    async (_, visible: boolean) => setNewCertificate((c) => ({...c, public: visible})),
    []
  )

  const resetNewCertficiateFile = React.useCallback(() => setNewCertificateURL(''), [])

  const _handleSubmit = React.useCallback(async () => {
    console.log('submitted')
    try {
      const {error} = await supabase.rpc('insert_therapist_certificate', {
        name: newCertificate.name,
        path: newCertificate.path,
        public: newCertificate.public,
        therapist_id: therapist,
      })

      if (error) {
        throw error
      }

      setNewCertificate(emptyTherapistCertificate)
      setNewCertificateURL('')
      fetchCertificates()
    } catch (e) {
      console.error('Failed to submit therapist certificate', e)
    }
  }, [fetchCertificates, newCertificate, therapist])
  const {handleSubmit, loading: isSubmitting} = useLoadingState(_handleSubmit)

  const therapistCertificatePreview = React.useMemo(
    () => ({...newCertificate, path: newCertificateURL}),
    [newCertificate, newCertificateURL]
  )

  const isSubmitDisabled = React.useMemo(
    () => isSubmitting || !newCertificate.name || !newCertificateURL,
    [isSubmitting, newCertificate.name, newCertificateURL]
  )

  return (
    <>
      <Modal isOpen={open} onClose={handleClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Certyfikaty terapeuty</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <FormControl>
                <FormLabel htmlFor="Name">Nazwa</FormLabel>
                <Input id="Name" value={newCertificate.name} onChange={handleNameChange} />
              </FormControl>
              {newCertificateURL ? (
                <TherapistCertificateItem
                  isDisabled={isUploading || isSubmitting}
                  certificate={therapistCertificatePreview}
                  onDelete={resetNewCertficiateFile}
                  onVisibilityChange={handleVisiblityChange}
                />
              ) : (
                <FileUpload
                  accept="application/pdf"
                  onUpload={handleCertificateUpload}
                  loading={isUploading}
                  disabled={isUploading || isSubmitting}
                />
              )}
              <Button
                colorScheme="green"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                isDisabled={isSubmitDisabled}
              >
                Zatwierdź
              </Button>

              <Box py="2">
                <Divider />
              </Box>

              {isLoading ? (
                <LabeledSpinner />
              ) : (
                <TherapistCertificates
                  therapist={therapist}
                  certificates={certificates}
                  fetch={fetchCertificates}
                  isDisabled={isSubmitting}
                />
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={handleClose}>
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default TherapistsCertificatesModal
