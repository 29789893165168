import React from 'react'

import {BsStar} from 'react-icons/bs'

import {ADMIN_WEBINAR_REVIEWS} from '@/admin/router'
import {WebinarJoined} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'

import {generateFilterURL} from '../utils'

export const showReviewsAction: DataTableAction<WebinarJoined> = {
  icon: <BsStar />,
  id: 'showReviews',
  to: (item: WebinarJoined) =>
    generateFilterURL(ADMIN_WEBINAR_REVIEWS, {
      combinator: 'and',
      rules: [{field: 'webinar', operator: 'eq', value: item.meeting}],
    }),
  tooltip: 'Zobacz oceny',
}
