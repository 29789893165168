import React from 'react'

import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {Key} from 'rc-tree/lib/interface'
import {Link, generatePath, useHistory, useParams} from 'react-router-dom'

import {selectIsAdmin, selectProfile} from '@/auth/state'
import {CourseNode} from '@/courses/types'
import {COURSE_PAGE} from '@/router/paths'
import {useAppSelector} from '@/store'

import CourseCompletionSummary from './completion-summary'
import CourseEditor from './editor'
import Header from './header'
import {useCourseDetails, useCourseMenu, useCoursePrevNext, useTouchCoursePage} from './hooks'
import JournalExplorer from './journal/explorer'
import CourseJournal from './journal/journal'
import LoadMostRecentlyOpenedPage from './load-most-recently-opened-page'
import TreeMenu from './tree-menu'
import {CourseParams} from './utils'

export const AdminCourse = () => {
  const history = useHistory()
  const {course, page} = useParams<CourseParams>()
  const profile = useAppSelector(selectProfile)

  React.useEffect(() => {
    if (!profile || profile.role === 'admin') return
    history.replace(
      generatePath(COURSE_PAGE, {
        course,
        page,
      })
    )
  }, [course, history, page, profile])

  return <Course adminView={true} course={course} page={page} />
}

export const UserCourse = () => {
  const {course, page} = useParams<CourseParams>()
  return <Course course={course} page={page} />
}

type CourseProps = {
  adminView?: boolean
} & CourseParams

const Course = ({adminView, course, page}: CourseProps) => {
  const isAdmin = useAppSelector(selectIsAdmin)
  const isAdminView = !!adminView

  const [editing, setEditing] = React.useState(false)
  const [expandedKeys, setExpandedKeys] = React.useState<Key[]>([])

  const {data: courseData, loading: courseLoading, fetch: courseFetch} = useCourseDetails(course)
  const {
    data: menuData,
    loading: menuLoading,
    fetch: menuFetch,
  } = useCourseMenu({
    adminView: isAdminView,
    id: course,
    setExpandedKeys,
  })
  const {
    data: menuNavData,
    goToNextPage,
    loading: menuNavLoading,
  } = useCoursePrevNext({
    adminView: isAdminView,
    id: course,
    page,
  })
  const touchProgress = useTouchCoursePage(course, page)
  React.useEffect(() => {
    ;(async () => {
      await touchProgress()
      await menuFetch(false)
    })()
  }, [course, page]) // eslint-disable-line
  const handleNextPage = React.useCallback(async () => {
    await goToNextPage()
    courseFetch(false)
    menuFetch(false)
  }, [goToNextPage, courseFetch, menuFetch])
  const [localMenuData, setLocalMenuData] = React.useState<CourseNode[]>(menuData ?? [])
  React.useEffect(() => {
    setLocalMenuData(menuData ?? [])
  }, [menuData, setLocalMenuData])

  const canEdit = React.useMemo(() => {
    return courseData?.roles?.includes('course_author') || isAdmin
  }, [courseData, isAdmin])

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'full',
  })

  const shouldViewMenu = React.useMemo(
    () => variant !== 'mobile' && page !== 'journal-explorer',
    [variant, page]
  )
  const {isOpen, onClose, onOpen} = useDisclosure()
  const {
    location: {pathname},
  } = useHistory()
  React.useEffect(() => onClose(), [pathname, onClose])

  return (
    <>
      <Header
        adminView={isAdminView}
        canEdit={canEdit}
        page={page}
        course={+course}
        details={courseData}
        loading={courseLoading}
        editing={editing}
        setEditing={setEditing}
        fetch={courseFetch}
        onMenuOpen={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wybierz sekcję warsztatu</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TreeMenu
              adminView={adminView}
              course={+course}
              editing={editing}
              value={localMenuData}
              onChange={setLocalMenuData}
              isLoading={menuLoading}
              expandedKeys={expandedKeys}
              onExpand={setExpandedKeys}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Container maxW="container.xl" py={8}>
        <Grid templateColumns="repeat(12, 1fr)">
          {shouldViewMenu && (
            <GridItem colSpan={3} maxWidth="100%">
              <TreeMenu
                adminView={adminView}
                course={+course}
                editing={editing}
                value={localMenuData}
                onChange={setLocalMenuData}
                isLoading={menuLoading}
                expandedKeys={expandedKeys}
                onExpand={setExpandedKeys}
              />
            </GridItem>
          )}
          <GridItem colSpan={shouldViewMenu ? 9 : 12} pl={[0, null, 8]}>
            {page ? (
              page === 'journal-explorer' ? (
                <JournalExplorer course={course} />
              ) : page === 'journal' ? (
                <CourseJournal course={course} />
              ) : page === 'summary' ? (
                <CourseCompletionSummary course={course} />
              ) : (
                <Box textAlign="justify">
                  <CourseEditor courseID={+course} pageID={page} readOnly={!editing} />
                  <Flex justifyContent="space-between">
                    <Button
                      as={Link}
                      to={menuNavData[0] || '#'}
                      isDisabled={menuNavLoading || !menuNavData[0]}
                    >
                      Wstecz
                    </Button>
                    <Button isDisabled={menuNavLoading} onClick={handleNextPage}>
                      Dalej
                    </Button>
                  </Flex>
                </Box>
              )
            ) : (
              <LoadMostRecentlyOpenedPage adminView={isAdminView} course={course} />
            )}
          </GridItem>
        </Grid>
      </Container>
    </>
  )
}
