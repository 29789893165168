import * as React from 'react'

import {Heading, Stack, Text, useToast} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import {OrderDiscountCode} from '@/api/models'
import {selectProfile} from '@/auth/state'
import {DiscountCodeInput, OrderButtons, OrderModal} from '@/common/order'
import {selectOrderState, setOrderDiscountCode, setOrderDone, submitOrder} from '@/courses/state'
import {useAppDispatch, useAppSelector} from '@/store'
import {useBillingAddress, useShippingAddress} from '@/user/billing/hooks'

import AddressInput from './address-input'
import Agreements from './agreements'
import CoachInput from './coach-input'
import FormBox from './form-box'
import TShirtInput from './tshirt-input'

const OrderConfirmationModal = () => {
  const toast = useToast()
  const dispatch = useAppDispatch()

  const profile = useAppSelector(selectProfile)
  const {model, coach, discountCode, submitting, active, withMeetings, tshirt, agreements, error} =
    useAppSelector(selectOrderState)

  const billingAddress = useBillingAddress()
  const shippingAddress = useShippingAddress()

  const handleClose = React.useCallback(() => dispatch(setOrderDone()), [dispatch])

  const handleSubmit = React.useCallback(() => dispatch(submitOrder()), [dispatch])

  const handleDiscountCodeChange = React.useCallback(
    (v?: OrderDiscountCode) => dispatch(setOrderDiscountCode(v)),
    [dispatch]
  )

  React.useEffect(() => {
    error &&
      toast({
        title: error,
        isClosable: true,
        status: 'error',
      })
  }, [error, toast])

  React.useEffect(() => {
    if (!active || !model) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `course:${model.id}`,
              item_name: `Warsztat - ${model.name}`,
              value: (model.base_price || 0) / 100,
            },
          ],
          value: (model.base_price || 0) / 100,
        },
        event: 'begin_checkout',
      },
    })
  }, [active, model])

  const price = React.useMemo(
    () => (withMeetings ? model?.price_with_meetings : model?.base_price) || 0,
    [model, withMeetings]
  )

  const doesCodeApply = React.useCallback(
    ({product_type, products_ids}: OrderDiscountCode) =>
      !product_type ||
      (product_type === 'course' &&
        (!products_ids.length || products_ids.some((id) => id === model?.id?.toString()))),
    [model]
  )

  const discountedPrice = React.useMemo(() => {
    if (!discountCode || !model?.id || !price) return null

    if (!doesCodeApply(discountCode)) return null

    return (price / 100) * (1 - discountCode.discount_percentage / 100)
  }, [price, model, discountCode, doesCodeApply])

  const isSubmitDisabled = React.useMemo(
    () =>
      !billingAddress ||
      (tshirt && !shippingAddress) ||
      !(model?.nextlvl_id || !model?.coaches?.length || !withMeetings || coach) ||
      agreements.some((a) => !a),
    [billingAddress, shippingAddress, tshirt, model, withMeetings, coach, agreements]
  )

  return (
    <OrderModal open={active} onClose={handleClose}>
      <Stack spacing={8}>
        <Heading fontSize="lg" fontWeight={400} mb={6}>
          Zapisy na warsztat
        </Heading>
        <Stack direction={['column', null, 'row']} spacing={[4, null, 8]}>
          <Stack spacing={4} flex={1}>
            <FormBox flip={true}>
              <Text fontWeight={700}>{model?.name}</Text>
              cena: {model?.base_price ? model?.base_price / 100 : 0}zł
            </FormBox>
            <CoachInput />
            <FormBox flip={true}>
              <AddressInput
                address={billingAddress}
                title="Adres rozliczeniowy"
                notAvailableText="Brak adresu rozliczeniowego"
                disabled={submitting}
              />
            </FormBox>
          </Stack>
          <Stack spacing={8} flex={1} justify="space-between">
            <Stack spacing={4}>
              {profile?.can_get_tshirt && (
                <>
                  <TShirtInput />
                  <FormBox flip={true}>
                    <AddressInput
                      address={shippingAddress}
                      title="Adres do wysyłki"
                      notAvailableText="Brak adresu do wysyłki"
                      disabled={submitting}
                    />
                  </FormBox>
                </>
              )}
              <FormBox flip={true}>
                <Stack direction={discountCode ? 'column' : 'row'} align="flex-start" justify="space-between">
                  <Text fontWeight={700}>Kod zniżkowy</Text>
                  <DiscountCodeInput
                    value={discountCode}
                    onChange={handleDiscountCodeChange}
                    disabled={submitting}
                    doesApply={doesCodeApply}
                  />
                </Stack>
              </FormBox>
              <Agreements />
            </Stack>
            <OrderButtons
              price={price}
              discountedPrice={discountedPrice}
              isDisabled={isSubmitDisabled}
              isLoading={submitting}
              onPrevStep={handleClose}
              onNextStep={handleSubmit}
            />
          </Stack>
        </Stack>
      </Stack>
    </OrderModal>
  )
}

export default OrderConfirmationModal
