export const WEB_BASE_URI =
  process.env.REACT_APP_WEB_BASE_URI ?? `http://localhost:${process.env.PORT ?? 3000}`

export const SUPPORT_GROUPS_FEATURE = process.env.REACT_APP_SUPPORT_GROUPS_FEATURE

export const GOOGLE_SERVICE_CALENDAR = 'service'
export const SUPABASE_AVATARS_BUCKET = 'avatars'
export const SUPABASE_COURSE_LOGOS_BUCKET = 'course-logos'
export const SUPABASE_COURSE_CONTENT_BUCKET = 'course-content'
export const SUPABASE_BLOG_POST_IMAGES_BUCKET = 'blog-post-images'
export const SUPABASE_BLOG_POST_CONTENT_BUCKET = 'blog-post-content'
export const SUPABASE_FRONT_PAGE_LOGOS_BUCKET = 'front-page-logos'
export const SUPABASE_THERAPIST_CERTIFICATES_BUCKET = 'therapist-certificates'
export const SUPABASE_QUESTIONS_AND_ANSWERS_CONTENT_BUCKET = 'questions-and-answers-content'
export const SUPABASE_COURSE_BOOKLETS_BUCKET = 'course-booklets'
export const SUPABASE_WEBINARS_BUCKET = 'webinars'
export const SUPABASE_SUPPORT_GROUPS_BUCKET = 'support_groups'

export const hotlinePhoneNumber = {
  displayed: '+48 600-701-465',
  raw: '+48600701465',
}
