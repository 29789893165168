import React from 'react'

import {AspectRatio, Box, BoxProps, Flex, Image, Stack, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {ViewBlogPost} from '@/api/models'
import {calculateReadTime} from '@/blog/utils'
import {ArrowIcon} from '@/common/arrow-button'
import ContentEditor from '@/common/content-editor'
import {BLOG, BLOG_POST} from '@/router/paths'

type Props = {
  post: ViewBlogPost
  featured: boolean
} & BoxProps

export const BlogPostTile = ({post, featured, ...rest}: Props) => {
  const readTime = React.useMemo(() => calculateReadTime(post.words_count ?? 0), [post.words_count])

  if (featured) {
    return (
      <Stack direction={['column', null, 'row']} spacing={8}>
        <Box width={['100%', null, '50%']} position="relative" {...rest} overflow="hidden">
          <AspectRatio ratio={[5 / 3, null, 1]} zIndex={-1}>
            <Box position="relative" width="100%" overflow="hidden">
              <Image w="100%" h="100%" src={post.signed_image ?? undefined} objectFit="cover" />
            </Box>
          </AspectRatio>
        </Box>
        <Stack direction="column" spacing={[0, null, 4]} width={['100%', null, '50%']} pt={[0, null, 2]}>
          <Text as="h3" fontWeight={500} fontSize="3xl" display={['none', null, 'block']} noOfLines={2}>
            {post.name}
          </Text>
          <Text fontWeight={300} mb={8} display={['none', null, 'block']}>
            {post.author} | {readTime} {readTime === 1 ? 'minuta' : 'minut'} czytania
          </Text>
          <Text as={Box} mb={12} fontWeight={300} noOfLines={10}>
            <ContentEditor
              variant="basic"
              id={`homepage-featured-blog-post-description-${post.id}`}
              value={post.description}
              readOnly={true}
            />
          </Text>
          <Flex direction="row" alignItems="flex-end" justifyContent="flex-end">
            <Box
              as={Link}
              borderBottom="1px solid black"
              to={post.id ? generatePath(BLOG_POST, {id: post.id, slug: post.slug}) : BLOG}
            >
              Czytaj dalej
            </Box>
          </Flex>
        </Stack>
      </Stack>
    )
  }

  return (
    <Box position="relative" width="100%" overflow="hidden" {...rest}>
      <Box as={Link} to={post.id ? generatePath(BLOG_POST, {id: post.id, slug: post.slug}) : BLOG}>
        <Box position="relative">
          <AspectRatio ratio={[8 / 3, null, 1]}>
            <Image w="100%" h="100%" src={post.signed_image ?? undefined} objectFit="cover" />
          </AspectRatio>
        </Box>
        <Box
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          background="rgba(7, 51, 44, 0.69)"
        />
        <Stack
          direction="row"
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
          color="white"
          alignItems="center"
          p={5}
          fontSize={['xl', null, 'md']}
          fontWeight={[300, null, 500]}
          justifyContent="space-between"
          spacing={4}
        >
          <Text>{post.name}</Text>
          <Box display={['block', null, 'none']}>
            <ArrowIcon color="brand.yellow.600" width={28} />
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
