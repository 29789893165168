import * as React from 'react'

import {Stack, Tag, Tooltip} from '@chakra-ui/react'
import {add} from 'date-fns'

import {WebinarJoined} from '@/api/models'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptyWebinar: WebinarJoined = {
  created_at: new Date(),
  updated_at: new Date(),
  name: '',
  meeting: '',
  thumbnail: '',
  description: '',
  full_description: initialEditorValue,
  short_description: initialEditorValue,
  youtube_url: null,
  featured_index: null,
  hosts: [],
  price: 0,
  published: false,
  enable_chat: false,
  duration: {from: new Date(), to: add(new Date(), {hours: 1})},
}

export const defaultSelectedColumns = [
  'thumbnail',
  'name',
  'hosts',
  'price',
  'duration',
  'published',
  'featured_index',
]

export const columns: Column<WebinarJoined>[] = [
  {
    Header: 'Miniaturka',
    id: 'thumbnail',
    renderAs: 'avatar',
  },
  {
    Header: 'ID',
    id: 'id',
  },
  {
    Header: 'ID spotkania',
    id: 'meeting',
    renderAs: 'code',
    sortable: false,
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Opublikowany',
    id: 'published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Z czatem?',
    id: 'enable_chat',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Cena (PLN)',
    id: 'price',
    renderAs: 'monetary',
  },
  {
    Header: 'Indeks wyróżnienia',
    id: 'featured_index',
  },
  {
    Header: 'Opis',
    id: 'description',
  },
  {
    Header: 'Prowadzący',
    id: 'hosts',
    renderer: ({hosts}) => (
      <Stack spacing={2}>
        {hosts.map((h, i) => (
          <Tooltip key={i} label={h.full_name}>
            <Tag>{h.email}</Tag>
          </Tooltip>
        ))}
      </Stack>
    ),
  },
  {
    Header: 'Czas trwania',
    id: 'duration',
    renderAs: 'timeSlot',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Zaktualizowano',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'number',
  },
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Opis',
    name: 'description',
    variant: 'text',
  },
  {
    label: 'Prowadzący',
    name: 'hosts',
    variant: 'list',
  },
  {
    label: 'Opublikowany',
    name: 'published',
    variant: 'boolean',
  },
  {
    label: 'Z czatem?',
    name: 'enable_chat',
    variant: 'boolean',
  },
  {
    label: 'Cena (PLN)',
    name: 'price',
    variant: 'monetary',
  },
  {
    label: 'Indeks wyróżnienia',
    name: 'featured_index',
    variant: 'number',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Zaktualizowano',
    name: 'updated_at',
    variant: 'datetime',
  },
]
