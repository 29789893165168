import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {MySupportGroupMeeting} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import {MEETING} from '@/router/paths'
import {SpotlightItem} from '@/user/components/spotlight-item'
import {formatMeetingDuration} from '@/utils/string'

type Props = {
  item: MySupportGroupMeeting
}

const JoinableSupportGroupMeeting = ({item}: Props) => (
  <SpotlightItem
    image={item.thumbnail}
    title={item.name}
    button={
      <ArrowButton as={Link} to={generatePath(MEETING, {id: item.id})}>
        Dołącz do spotkania
      </ArrowButton>
    }
  >
    <Stack direction={['column', null, 'row']} align="center" spacing={[0, null, 2]}>
      <Text fontWeight={700}>Grupa wsparcia online</Text>
      <Text>{formatMeetingDuration(item.starts_at, item.ends_at)}</Text>
    </Stack>
  </SpotlightItem>
)

export default JoinableSupportGroupMeeting
