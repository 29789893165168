import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const BookIcon = (props: IconProps) => (
  <Icon viewBox="0 0 26 23" {...props} fill="none">
    <path
      d="M1.625 3.77219C3.06312 3.14781 5.12525 2.4745 7.1305 2.26525C9.29175 2.03912 11.1248 2.37156 12.1875 3.53425V19.9806C10.6681 19.0862 8.7425 18.9631 6.96638 19.1487C5.04888 19.3512 3.11512 19.9266 1.625 20.5173V3.77219ZM13.8125 3.53425C14.8752 2.37156 16.7083 2.03912 18.8695 2.26525C20.8748 2.4745 22.9369 3.14781 24.375 3.77219V20.5173C22.8832 19.9266 20.9511 19.3495 19.0336 19.1504C17.2559 18.9631 15.3319 19.0846 13.8125 19.9806V3.53425ZM13 2.00875C11.3994 0.579436 9.07888 0.366811 6.96638 0.586186C4.50612 0.844374 2.02313 1.72019 0.476125 2.45087C0.33418 2.51791 0.21381 2.62596 0.129384 2.7621C0.0449584 2.89825 4.31786e-05 3.05675 0 3.21869L0 21.7812C3.76382e-05 21.9224 0.0341854 22.0613 0.0993153 22.1852C0.164445 22.3091 0.258475 22.4141 0.372793 22.4905C0.48711 22.5669 0.61806 22.6123 0.753648 22.6225C0.889236 22.6327 1.02513 22.6074 1.14887 22.549C2.58213 21.874 4.89125 21.0623 7.12888 20.8277C9.4185 20.5881 11.3376 20.9746 12.3663 22.3077C12.4424 22.4062 12.5389 22.4858 12.6485 22.5404C12.7582 22.5951 12.8783 22.6234 13 22.6234C13.1217 22.6234 13.2418 22.5951 13.3515 22.5404C13.4611 22.4858 13.5576 22.4062 13.6338 22.3077C14.6624 20.9746 16.5815 20.5881 18.8695 20.8277C21.1088 21.0623 23.4195 21.874 24.8511 22.549C24.9749 22.6074 25.1108 22.6327 25.2464 22.6225C25.3819 22.6123 25.5129 22.5669 25.6272 22.4905C25.7415 22.4141 25.8356 22.3091 25.9007 22.1852C25.9658 22.0613 26 21.9224 26 21.7812V3.21869C26 3.05675 25.955 2.89825 25.8706 2.7621C25.7862 2.62596 25.6658 2.51791 25.5239 2.45087C23.9769 1.72019 21.4939 0.844374 19.0336 0.586186C16.9211 0.365124 14.6006 0.579436 13 2.00875Z"
      fill="currentColor"
    />
  </Icon>
)

export default BookIcon
