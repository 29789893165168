import React from 'react'

import {ChevronLeftIcon, HamburgerIcon} from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  IconButton,
  Progress,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {FaPen, FaShareAlt, FaUsers} from 'react-icons/fa'
import {Link, generatePath} from 'react-router-dom'

import CourseEditorModalButton from '@/admin/courses/editor-modal/button'
import {ADMIN_COURSES} from '@/admin/router'
import BookIcon from '@/common/icons/book'
import {CourseDetails} from '@/courses/types'
import {ADMIN_COURSE_PAGE, COURSES, COURSE_PAGE} from '@/router/paths'

import AccessManagementModal from './access-management-modal'

type Props = {
  adminView: boolean
  canEdit: boolean
  course: number
  details?: CourseDetails
  page: string
  loading: boolean
  editing: boolean
  setEditing: (editing: boolean) => void
  fetch: (markAsLoading: boolean) => Promise<void>
  onMenuOpen: () => void
}

const Header = ({
  course,
  adminView,
  canEdit,
  page,
  details,
  loading,
  editing,
  setEditing,
  onMenuOpen,
}: Props) => {
  const isLoaded = !!details && !loading
  const isCoach = details?.roles?.includes('coach')

  const {
    isOpen: isAccessManagementModalOpen,
    onOpen: onAccessManagementModalOpen,
    onClose: onAccessManagementModalClose,
  } = useDisclosure()

  const toggleEditing = React.useCallback(() => {
    setEditing(!editing)
  }, [editing, setEditing])

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'full',
  })

  return (
    <Box borderBottom="1px solid" borderColor="brand.yellow.600" py={6}>
      <AccessManagementModal
        isOpen={isAccessManagementModalOpen}
        onClose={onAccessManagementModalClose}
        course={course}
      />

      <Container maxW="container.xl">
        <Stack direction="row" spacing={4} alignItems="center">
          {variant === 'full' && (
            <IconButton
              aria-label="Powrót do wyboru szkoleń"
              as={Link}
              to={adminView ? ADMIN_COURSES : COURSES}
              icon={<ChevronLeftIcon fontSize="3xl" />}
              variant="outline"
              size="lg"
              borderColor="brand.yellow.600"
              borderWidth="2px"
              color="brand.yellow.600"
              _hover={{
                backgroundColor: 'brand.gray.100',
                borderColor: 'brand.yellow.700',
                color: 'brand.yellow.700',
              }}
              display={['none', null, 'flex']}
            />
          )}
          <Text
            fontSize={['md', null, '2xl']}
            fontWeight={500}
            flex={1}
            pl={[0, null, 8]}
            noOfLines={2}
            textOverflow="ellipsis"
          >
            {isLoaded ? details?.name : <Skeleton w="200px" height="28px" />}
          </Text>
          {editing && <CourseEditorModalButton id={course} />}
          {editing && (
            <Tooltip label="Edytuj uczestników warsztatu">
              <IconButton
                onClick={onAccessManagementModalOpen}
                aria-label="Edytuj uczestników warsztatu"
                icon={<FaUsers />}
                variant="ghost"
              />
            </Tooltip>
          )}
          <Stack direction="row" spacing={4} alignItems="center" px={4} display={['none', null, 'flex']}>
            <Text fontSize="md" fontWeight={500}>
              Ukończono: {details?.progress ?? 0}%
            </Text>
            <Progress
              width="200px"
              isIndeterminate={!isLoaded}
              value={details?.progress ?? 0}
              rounded="full"
              bg="brand.green.100"
              sx={{
                '& > div': {
                  bg: 'brand.green.600',
                },
              }}
            />
          </Stack>
          {canEdit && (
            <Tooltip label={`${editing ? 'Wyłącz' : 'Włącz'} tryb edycji`}>
              <IconButton
                aria-label="Włącz/wyłącz tryb edycji"
                isActive={editing}
                onClick={toggleEditing}
                icon={<FaPen />}
                variant="ghost"
              />
            </Tooltip>
          )}
          {editing || isCoach ? (
            <Tooltip label="Twój dziennik">
              <IconButton
                as={Link}
                to={generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
                  course: details?.id || '#',
                  page: 'journal',
                })}
                aria-label="Twój dziennik"
                icon={<BookIcon />}
                variant="ghost"
                color="brand.yellow.600"
              />
            </Tooltip>
          ) : (
            <>
              <Button
                as={Link}
                to={generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
                  course: details?.id || '#',
                  page: 'journal',
                })}
                leftIcon={<BookIcon color="brand.yellow.600" fontSize="xl" />}
                variant="ghost"
                isActive={page === 'journal'}
                display={['none', null, 'flex']}
              >
                Twój dziennik
              </Button>
              <IconButton
                as={Link}
                to={generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
                  course: details?.id || '#',
                  page: 'journal',
                })}
                aria-label="Twój dziennik"
                icon={<BookIcon />}
                variant="ghost"
                color="brand.yellow.600"
                display={['flex', null, 'none']}
              />
            </>
          )}
          {isCoach && (
            <Tooltip label="Dzienniki użytkowników">
              <IconButton
                as={Link}
                to={generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
                  course: details?.id || '#',
                  page: 'journal-explorer',
                })}
                aria-label="Dzienniki użytkowników"
                icon={<FaShareAlt />}
                variant="ghost"
                color="brand.yellow.600"
              />
            </Tooltip>
          )}
        </Stack>
        <Stack direction="row" display={['flex', null, 'none']} justifyContent="stretch" alignItems="center">
          <Text fontSize="sm" fontWeight={500} flexShrink={0}>
            Ukończono: {details?.progress ?? 0}%
          </Text>
          <Progress
            width="100%"
            isIndeterminate={!isLoaded}
            value={details?.progress ?? 0}
            rounded="full"
            colorScheme="green"
          />
          <IconButton
            flexShrink={0}
            onClick={onMenuOpen}
            aria-label="Wybierz stronę warsztatu"
            icon={<HamburgerIcon />}
            variant="ghost"
            color="brand.yellow.600"
            display={['flex', null, 'none']}
          />
        </Stack>
      </Container>
    </Box>
  )
}

export default Header
