import React from 'react'

import LabeledSpinner from '@/common/components/labeled-spinner'
import ContentEditor from '@/common/content-editor'
import {ContentEditorWrapperProps} from '@/common/content-editor/types'
import {SUPABASE_QUESTIONS_AND_ANSWERS_CONTENT_BUCKET} from '@/constants'

const QuestionsAndAnswersEditor = ({
  entryID,
  readOnly,
  loading,
  setValue,
  value,
}: ContentEditorWrapperProps) => {
  if (loading) {
    return <LabeledSpinner label="Ładowanie postu..." />
  }

  return (
    <ContentEditor
      variant="full"
      bucketName={SUPABASE_QUESTIONS_AND_ANSWERS_CONTENT_BUCKET}
      bucketScope={+entryID}
      value={value}
      onChange={setValue}
      readOnly={readOnly}
      id={`questions-and-answers-editor-${entryID}`}
    />
  )
}

export default QuestionsAndAnswersEditor
