import React from 'react'

import {ListItem, OrderedList, UnorderedList} from '@chakra-ui/react'
import {
  createPluginFactory,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_OL,
  ELEMENT_UL,
  onKeyDownList,
  PlateRenderElementProps,
  usePlateEditorState,
  withList,
  toggleList,
} from '@udecode/plate'
import {MdFormatListBulleted, MdFormatListNumbered} from 'react-icons/md'

import {BlockButton} from '../toolbar/buttons'

export const ListItemRenderer = ({attributes, children}: PlateRenderElementProps) => (
  <ListItem {...attributes}>{children}</ListItem>
)

export const OrderedListRenderer = ({attributes, children}: PlateRenderElementProps) => {
  return (
    <OrderedList mb={4} listStylePos="inside" {...attributes}>
      {children}
    </OrderedList>
  )
}

export const OrderedListButton = () => {
  const editor = usePlateEditorState()!

  const handleToggle = React.useCallback(() => {
    toggleList(editor, {type: 'ol'})
  }, [editor])

  return (
    <BlockButton
      format="ol"
      label="Lista numerowana"
      icon={<MdFormatListNumbered />}
      onClick={handleToggle}
    />
  )
}

export const UnorderedListRenderer = ({attributes, children}: PlateRenderElementProps) => {
  return (
    <UnorderedList mb={4} listStylePos="inside" {...attributes}>
      {children}
    </UnorderedList>
  )
}

export const UnorderedListButton = () => {
  const editor = usePlateEditorState()!

  const handleToggle = React.useCallback(() => {
    toggleList(editor, {type: 'ul'})
  }, [editor])

  return (
    <BlockButton
      format="ul"
      label="Lista numerowana"
      icon={<MdFormatListBulleted />}
      onClick={handleToggle}
    />
  )
}

export const createListPlugin = createPluginFactory({
  key: 'list',
  plugins: [
    {
      component: UnorderedListRenderer,
      handlers: {
        onKeyDown: onKeyDownList,
      },
      isElement: true,
      key: ELEMENT_UL,
      withOverrides: withList,
    },
    {
      component: OrderedListRenderer,
      handlers: {
        onKeyDown: onKeyDownList,
      },
      isElement: true,
      key: ELEMENT_OL,
    },
    {
      isElement: true,
      key: ELEMENT_LI,
    },
    {
      component: ListItemRenderer,
      isElement: true,
      key: ELEMENT_LIC,
      type: ELEMENT_LIC,
    },
  ],
})
