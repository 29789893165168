import * as React from 'react'

import {OrderButtons} from '@/common/order'
import {useAppDispatch, useAppSelector} from '@/store'
import {useBillingAddress} from '@/user/billing/hooks'

import {resetOrderState, selectOrder, selectSupportGroup, submitSupportGroupOrder} from '../state'
import {doesDiscountCodeApply} from './utils'

const SupportGroupOrderButtons = () => {
  const dispatch = useAppDispatch()

  const {agreement, submitting, discountCode} = useAppSelector(selectOrder)
  const group = useAppSelector(selectSupportGroup)
  const billingAddress = useBillingAddress()

  const handlePreviousStep = React.useCallback(() => dispatch(resetOrderState()), [dispatch])

  const handleNextStep = React.useCallback(() => dispatch(submitSupportGroupOrder()), [dispatch])

  const discountedPrice = React.useMemo(() => {
    if (!discountCode || !group?.id || !doesDiscountCodeApply(discountCode, group.id)) return null

    return +((group?.price * (1 - discountCode.discount_percentage / 100)) / 100).toFixed(2)
  }, [group, discountCode])

  return (
    <OrderButtons
      price={group?.price}
      discountedPrice={discountedPrice}
      isDisabled={!group || !agreement || !billingAddress}
      isLoading={submitting}
      onNextStep={handleNextStep}
      onPrevStep={handlePreviousStep}
    />
  )
}

export default SupportGroupOrderButtons
