import * as React from 'react'

import {Button, Text, HStack} from '@chakra-ui/react'

import {ReviewType} from '@/api/models'
import CheckmarkIcon from '@/common/icons/checkmark'

import useReviewModal from './use-review-modal'

type Props = {
  itemID: string | number
  type: ReviewType
  reviewed?: boolean
  withText?: boolean
  onComplete?: () => void
}

const ReviewModalButton = ({reviewed, type, itemID, withText, onComplete}: Props) => {
  const {startReview} = useReviewModal({onComplete})

  const handleClick = React.useCallback(() => {
    startReview({open: true, type, itemID})
  }, [startReview, type, itemID])

  return reviewed ? (
    // TODO: allow users to update reviews??
    <HStack color="brand.yellow.500">
      <CheckmarkIcon boxSize="24px" />
      <Text>{withText && 'Oceniono'}</Text>
    </HStack>
  ) : (
    <Button variant="brandLink" onClick={handleClick}>
      Oceń
    </Button>
  )
}

export default ReviewModalButton
