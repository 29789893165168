import React from 'react'

import {
  Text,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
  FormControl,
  FormErrorMessage,
  HStack,
  Link,
} from '@chakra-ui/react'
import {
  createPluginFactory,
  insertNodes,
  PlateEditor,
  PlateRenderElementProps,
  usePlateEditorState,
} from '@udecode/plate'
import randomstring from 'randomstring'
import {MdClose, MdHelpOutline} from 'react-icons/md'
import {SiCanva} from 'react-icons/si'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {CanvaElement} from '../custom-types'
import {EditorContext} from '../editor-context'
import {BlockButton} from '../toolbar/buttons'

const Canva = ({src}: {src: string}) => {
  return (
    <Box position="relative" w="100%" pt="56.25%" boxShadow="lg" overflow="hidden" borderRadius="md">
      <iframe
        loading="lazy"
        style={{
          border: 'none',
          height: '100%',
          left: 0,
          position: 'absolute',
          top: 0,
          width: '100%',
        }}
        src={`${src}?embed`}
        allow="fullscreen"
      />
    </Box>
  )
}

export const CanvaRenderer = ({attributes, children, element}: PlateRenderElementProps) => {
  const {readOnly} = React.useContext(EditorContext)
  const editor = usePlateEditorState()!

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: ReactEditor.findPath(editor, element)})
  }, [editor, element])

  return (
    <Flex position="relative" mb={4} {...attributes} contentEditable={false} userSelect="none" width="100%">
      {children}
      <Box contentEditable={false} width="100%">
        {!!element.embedURL && <Canva src={element.embedURL} />}
        {!readOnly && (
          <IconButton
            variant="outline"
            onClick={handleRemove}
            icon={<MdClose />}
            aria-label="Usuń film"
            position="absolute"
            top="5px"
            right="5px"
            zIndex={2}
          />
        )}
      </Box>
    </Flex>
  )
}

const canvaRegex = /^(https?:\/\/)?(?:www\.|(?!www))canva\.com\/design\/.*/

export const InsertCanvaButton = () => {
  const toast = useToast()

  const editor = usePlateEditorState()!
  const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure()
  const modalFocusedRef = React.useRef<HTMLInputElement>(null)

  const [embedURL, setEmbedURL] = React.useState('')

  const handleEmbedURLChange = React.useCallback(({target: {value}}) => {
    setEmbedURL(value.trim())
  }, [])

  const handleToolbarButtonClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      setEmbedURL('')
      onModalOpen()
    },
    [onModalOpen]
  )

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      try {
        if (!embedURL) throw new Error('Niepoprawne URL')

        insertCanva(editor, embedURL)
      } catch (e) {
        toast({
          description: (e as Error).message,
          isClosable: true,
          status: 'error',
          title: 'Nie udało się dodać prezentacji z Canva',
        })
      } finally {
        onModalClose()
      }
    },
    [editor, onModalClose, toast, embedURL]
  )

  const isValidURL = React.useMemo(() => !!embedURL.match(canvaRegex)?.length, [embedURL])

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={onModalClose} initialFocusRef={modalFocusedRef} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wstaw prezentację Canva</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormControl isInvalid={!!embedURL && !isValidURL}>
                <HStack mb={2}>
                  <Text>Canva smart embed link</Text>
                  <Link href="https://www.canva.com/help/embed-designs/" target="_blank">
                    <MdHelpOutline />
                  </Link>
                </HStack>
                <Input
                  ref={modalFocusedRef}
                  placeholder="https://www.canva.com/design/presentation_id/view"
                  variant="filled"
                  value={embedURL ?? ''}
                  onChange={handleEmbedURLChange}
                />
                <FormErrorMessage>Niepoprawne URL</FormErrorMessage>
              </FormControl>
              {!!embedURL && isValidURL && <Canva src={embedURL} />}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onModalClose}>
              Anuluj
            </Button>
            <Button colorScheme="green" onClick={handleSubmit} disabled={!isValidURL}>
              Wstaw
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <BlockButton
        label="Prezentacja Canva"
        format="canva"
        icon={<SiCanva />}
        onClick={handleToolbarButtonClick}
      />
    </>
  )
}

const insertCanva = (editor: PlateEditor, embedURL: string) => {
  const video: CanvaElement = {
    children: [{text: ''}],
    embedURL,
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    type: 'canva',
  }
  insertNodes<CanvaElement>(editor, video)
}

export const createCanvaPlugin = createPluginFactory({
  component: CanvaRenderer,
  editor: {
    insertData: {
      format: 'text/plain',
      getFragment: ({data}) => [
        {
          children: [{text: ''}],
          id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
          type: 'canva',
          embedURL: data,
        },
      ],
      query: ({data}) => !!data.match(canvaRegex)?.length,
    },
  },
  isElement: true,
  isVoid: true,
  key: 'canva',
  type: 'canva',
})
