import * as React from 'react'

import {IconButton, Tooltip, useDisclosure} from '@chakra-ui/react'
import {FaEdit} from 'react-icons/fa'

import {AdminCourse} from '@/api/models'
import useFetchSingle from '@/common/use-fetch-single'

import CourseEditorModal from '.'

// used to access editor modal from course viewer
const CourseEditorModalButton = ({id}: {id: number}) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {data, loading, fetch} = useFetchSingle<AdminCourse>(
    React.useMemo(
      () => ({
        table: 'admin_courses',
        fields: '*',
        match: {id},
        autoRefetch: false,
        errSnackbarTitle: 'Nie udało się wczytać warsztatu.',
      }),
      [id]
    )
  )

  const handleClick = React.useCallback(async () => {
    await fetch()
    onOpen()
  }, [fetch, onOpen])

  return (
    <>
      <Tooltip label="Edytuj szczegóły szkolenia">
        <IconButton
          variant="ghost"
          aria-label="Edytuj szczegóły szkolenia"
          icon={<FaEdit />}
          onClick={handleClick}
          isLoading={loading}
        />
      </Tooltip>
      {data && <CourseEditorModal item={data} open={isOpen} onClose={onClose} />}
    </>
  )
}

export default CourseEditorModalButton
