import * as React from 'react'

import {Redirect, Route, Switch} from 'react-router-dom'

import {THERAPIST_PANEL} from '@/router/paths'

export const THERAPIST_MEETINGS = `${THERAPIST_PANEL}/spotkania`
export const THERAPIST_PROFILE = `${THERAPIST_PANEL}/profil`
export const THERAPIST_SUPPORT_GROUPS = `${THERAPIST_PANEL}/grupy-wsparcia`
export const THERAPIST_CALENDAR = `${THERAPIST_PANEL}/kalendarz`

export default () => (
  <Switch>
    <Route path={THERAPIST_PROFILE} exact={true} component={require('./profile').default} />
    <Route path={THERAPIST_CALENDAR} exact={true} component={require('./calendar').default} />
    <Route path={THERAPIST_MEETINGS} exact={true} component={require('./meetings').default} />
    <Route path={THERAPIST_SUPPORT_GROUPS} exact={true} component={require('./support-groups').default} />
    <Route component={() => <Redirect to={{pathname: THERAPIST_PROFILE}} />} />
  </Switch>
)
