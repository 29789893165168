import * as React from 'react'

import {Payment} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, filterFields} from './constants'
import PaymentRefundModal from './refund-modal'
import {refundAction} from './utils'

const Payments = () => {
  const tableState = useTableState({descending: true, sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<Payment>({
    table: 'payments',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof Payment,
    filter: tableState.filter,
  })

  const {value: refundValue, onOpen: onRefundOpen, onClose: onRefundClose} = useValueDisclosure<Payment>()

  const actions = React.useMemo(() => [refundAction(onRefundOpen)], [onRefundOpen])

  return (
    <>
      <DataTable<Payment>
        table="payments"
        actions={actions}
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        onRefresh={fetch}
        loading={loading}
        filterFields={filterFields}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <PaymentRefundModal
        item={refundValue}
        onClose={onRefundClose}
        open={!!refundValue}
        onComplete={fetch}
      />
    </>
  )
}

export default Payments
