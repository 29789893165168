import {WebinarJoined} from '@/api/models'
import {stringifyPgTimestampRange} from '@/api/utils'

export const inputToUpsertArgs = (i: WebinarJoined) => ({
  meeting_id: i.meeting || undefined,
  name: i.name,
  published: i.published,
  enable_chat: i.enable_chat,
  description: i.description,
  full_description: i.full_description,
  short_description: i.short_description,
  price: i.price || 0,
  hosts: i.hosts.map((h) => h.id),
  youtube_url: i.youtube_url || undefined,
  featured_index: i.featured_index || undefined,
  duration: i.duration ? stringifyPgTimestampRange(i.duration) : undefined,
  // thumbnail upload is handled by `useUpsert` hook
})
