import * as React from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import {CarouselProvider, Dot, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel'
import {useHistory, Link, generatePath} from 'react-router-dom'

import {PublicCourse} from '@/api/models'
import {ArrowButton, ArrowIconButton} from '@/common/arrow-button'
import ContentEditor from '@/common/content-editor'
import {COURSES, COURSE_SHOWCASE} from '@/router/paths'

import './index.css'

const CourseItem = ({course}: {course: PublicCourse}) => {
  return (
    <>
      <Box display={['none', null, 'block']}>
        {course.signed_logo && (
          <AspectRatio ratio={1} mb={10}>
            <Image
              src={course.signed_logo}
              alt={course.name}
              objectFit="cover"
              roundedTopLeft="60px"
              roundedBottomRight="60px"
            />
          </AspectRatio>
        )}
        <Text as="h3" fontWeight={500} fontSize="2xl" mb={6} height="4.5rem" noOfLines={2}>
          {course.name}
        </Text>
      </Box>
      <Box display={['block', null, 'none']}>
        <Box roundedTopLeft="60px" roundedBottomRight="60px" overflow="hidden" mb={4} position="relative">
          <Box
            backgroundColor="brand.green.800"
            opacity={0.6}
            position="absolute"
            left={0}
            right={0}
            width="100%"
            height="100%"
            zIndex={0}
          />
          <Flex
            position="absolute"
            left={0}
            right={0}
            width="100%"
            height="100%"
            px={4}
            color="white"
            justifyContent="center"
            alignItems="center"
            fontSize="xl"
          >
            {course.name}
          </Flex>
          <AspectRatio ratio={1} zIndex={-1}>
            {course.signed_logo ? (
              <Image src={course.signed_logo} alt="" objectFit="cover" />
            ) : (
              <Box w="100%" h="100%" bg="brand.gray.500" />
            )}
          </AspectRatio>
        </Box>
      </Box>
      <Text fontWeight={300} mb={4} noOfLines={8}>
        <ContentEditor
          id={`other-courses-item-${course.id}`}
          variant="basic"
          value={course.short_description ?? []}
          readOnly={true}
        />
      </Text>
      {course.name_slug && (
        <Flex direction="row" alignItems="flex-end" justifyContent="flex-end">
          <Button
            as={Link}
            to={course.id ? generatePath(COURSE_SHOWCASE, {id: course.id, slug: course.name_slug}) : COURSES}
            variant="link"
            color="black"
          >
            Zobacz więcej
          </Button>
        </Flex>
      )}
    </>
  )
}

type Props = {
  courses: PublicCourse[]
}

const Courses = ({courses}: Props) => {
  const history = useHistory()
  const variant = useBreakpointValue({
    base: 'slider',
    md: 'stack',
  })

  const handleClick = React.useCallback(() => history.push(COURSES), [history])

  return (
    <>
      <Container maxW="container.xl">
        {variant === 'slider' ? (
          <Box mb={8}>
            <CarouselProvider
              naturalSlideWidth={500}
              naturalSlideHeight={400}
              totalSlides={courses.length}
              visibleSlides={1}
              infinite={true}
              isIntrinsicHeight={true}
              className="courses-carousel"
            >
              <Slider>
                {courses.map((course, i) => (
                  <Slide index={i} key={i}>
                    <Box px={4}>
                      <CourseItem course={course} />
                    </Box>
                  </Slide>
                ))}
              </Slider>
              <Flex direction="row" alignItems="center" justifyContent="center" pt={8}>
                <Stack direction="row" spacing={6}>
                  {courses.map((_, i) => (
                    <Dot key={i} slide={i}>
                      <Box borderRadius="full" width="14px" height="14px" />
                    </Dot>
                  ))}
                </Stack>
              </Flex>
            </CarouselProvider>
          </Box>
        ) : (
          <CarouselProvider
            naturalSlideWidth={500}
            naturalSlideHeight={400}
            totalSlides={courses.length}
            visibleSlides={3}
            infinite={true}
            isIntrinsicHeight={true}
            className="courses-carousel"
          >
            <Grid
              templateColumns="repeat(12, 1fr)"
              gap={8}
              alignItems="center"
              justifyContent="center"
              mb={12}
            >
              <GridItem colSpan={1} display={['none', null, 'block']}>
                <ButtonBack>
                  <ArrowIconButton
                    aria-label="Poprzedni warsztat"
                    width="64px"
                    color="brand.yellow.600"
                    _hover={{color: 'brand.yellow.700'}}
                    style={{transform: 'rotate(180deg)'}}
                  />
                </ButtonBack>
              </GridItem>
              <GridItem colSpan={[12, null, 10]} position="relative">
                <Slider>
                  {courses.map((course, i) => (
                    <Slide index={i} key={i}>
                      <Box px={8}>
                        <CourseItem course={course} />
                      </Box>
                    </Slide>
                  ))}
                </Slider>
              </GridItem>
              <GridItem colSpan={1} display={['none', null, 'block']}>
                <ButtonNext>
                  <ArrowIconButton
                    aria-label="Następny warsztat"
                    width="64px"
                    color="brand.yellow.600"
                    _hover={{color: 'brand.yellow.700'}}
                  />
                </ButtonNext>
              </GridItem>
            </Grid>
          </CarouselProvider>
        )}

        <Flex direction="row" justifyContent="center" alignItems="center" mb={20}>
          <ArrowButton
            bg="brand.yellow.600"
            hoverBg="brand.yellow.700"
            color="white"
            height={48}
            onClick={handleClick}
          >
            <Text px={8} fontSize="lg">
              Więcej warsztatów
            </Text>
          </ArrowButton>
        </Flex>
      </Container>
    </>
  )
}

export default Courses
