import * as React from 'react'

import {Heading, Spinner, Stack, Table, Tbody, Td, Th, Thead, Tr, Box, Button} from '@chakra-ui/react'
import {generatePath, Link} from 'react-router-dom'

import {MyMeeting} from '@/api/models'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import useSupabaseQuery from '@/common/use-custom-query'
import {PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {formatTimestamp} from '@/utils/string'

const MeetingsHistory = () => {
  const {data, loading, fetch} = useSupabaseQuery<MyMeeting>(
    React.useMemo(
      () => ({
        table: 'my_meetings',
        fields: '*',
        order: 'ends_at',
        descending: true,
        match: {type: 'therapy'},
        errSnackbarTitle: 'Nie udało się pobrać informacji o historii spotkań',
        filter: (b) => b.lt('joinable_to', new Date().toUTCString()),
      }),
      []
    )
  )

  return (
    <Stack spacing={12}>
      <Heading size="md">Historia spotkań</Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table>
          <Thead display={['none', null, 'table-header-group']}>
            <Tr>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500} pl={0}>
                Data
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Terapia
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Psychoterapeuta
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((m, i) => (
              <Row key={i} item={m} fetch={fetch} />
            ))}
          </Tbody>
        </Table>
      )}
    </Stack>
  )
}

const Row = ({item, fetch}: {item: MyMeeting; fetch: () => void}) => (
  <Tr fontWeight={300} fontSize="md">
    <Td display={['none', null, 'table-cell']} pl={0}>
      {formatTimestamp(item.starts_at)}
    </Td>
    <Td display={['none', null, 'table-cell']}>{item.name}</Td>
    <Td px={[0, null, 6]}>
      <Box display={['block', null, 'none']} mb={2}>
        {formatTimestamp(item.starts_at)}
      </Box>
      <Box display={['block', null, 'none']} mb={2}>
        {item.name}
      </Box>
      <Button
        variant="brandLink"
        as={Link}
        to={generatePath(PSYCHOTHERAPY_THERAPIST, {id: item.therapist_id})}
      >
        {item.therapist_full_name}
      </Button>
    </Td>
    <Td textAlign="center" px={[0, null, 6]}>
      <ReviewModalButton reviewed={item.reviewed} type="meeting" itemID={item.id} onComplete={fetch} />
    </Td>
  </Tr>
)

export default MeetingsHistory
