import React, {useCallback, useState} from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {BlogPost} from '@/api/models'
import AsyncSelect from '@/common/async-select'
import {CommonModalProps} from '@/utils/types'

const CreateBlogPostSuggestionModal = ({
  open,
  onClose,
  onComplete,
  blogPost,
}: CommonModalProps & {
  blogPost: number
}) => {
  const toast = useToast()

  const [selectedValue, setSelectedValue] = useState<BlogPost | null>(null)
  const [isCreating, setIsCreating] = useState(false)

  const handleClose = useCallback(() => {
    setSelectedValue(null)
    setIsCreating(false)
    onClose()
  }, [onClose])

  const asyncSelectLoadOptions = useCallback(async (searchValue) => {
    try {
      const query = supabase.from('blog_posts').select('id, author, name')
      if (searchValue) {
        query.like('name', `%${searchValue}%`)
      }
      const {data, error} = await query
      if (error) {
        throw error
      }
      return data
    } catch (e) {
      console.log(e)
    }
  }, [])

  const optionLabel = useCallback((o: BlogPost) => (o ? `${o.name} ${o.author && ` - ${o.author}`}` : ''), [])
  const optionValue = useCallback((o: BlogPost) => o.name, [])

  const handleSuggestionCreate = useCallback(async () => {
    if (!selectedValue) {
      return
    }

    try {
      setIsCreating(true)
      const {error} = await supabase
        .from('blog_post_suggested_blogs')
        .insert({blog_post: blogPost, suggested_blog_post: selectedValue.id})
      if (error) {
        throw error
      }
      toast({
        isClosable: true,
        status: 'success',
        title: 'Dodano sugestię postu',
      })
      onComplete && onComplete()
    } catch (e) {
      console.error('Failed to add blog post suggestion', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się dodać sugestii postu',
      })
    } finally {
      setIsCreating(false)
      handleClose()
    }
  }, [blogPost, handleClose, onComplete, selectedValue, toast])

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minW="500">
        <ModalHeader>Utwórz sugestię postu</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl id="name">
            <FormLabel>Nazwa postu</FormLabel>
            <AsyncSelect
              isDisabled={isCreating}
              value={selectedValue}
              defaultOptions={true}
              loadOptions={asyncSelectLoadOptions}
              getOptionLabel={optionLabel}
              getOptionValue={optionValue}
              onChange={setSelectedValue}
              placeholder="Nazwa..."
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={handleClose} isDisabled={isCreating}>
            Zamknij
          </Button>
          <Button
            colorScheme="green"
            onClick={handleSuggestionCreate}
            isLoading={isCreating}
            isDisabled={!selectedValue}
          >
            Zatwierdź
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateBlogPostSuggestionModal
