import React from 'react'

import {Spacer} from '@chakra-ui/layout'
import {Button, HStack} from '@chakra-ui/react'

import {ToolbarButtons} from './buttons'

type ToolbarProps = {
  position?: 'top' | 'navbar'
  isSaving?: boolean
  onSave?: () => void
  onCancel?: () => void
}

const Toolbar = ({isSaving, onSave, onCancel, position = 'top'}: ToolbarProps) => {
  const [stickyTop, setStickyTop] = React.useState<number>(0)
  React.useEffect(() => {
    position === 'top'
      ? setStickyTop(0)
      : setStickyTop(document.getElementById('navbar')?.getBoundingClientRect().height ?? 0)
  }, [position])

  return (
    <HStack
      borderWidth="0 0 1px 0"
      padding="10px 5px"
      spacing={2}
      wrap="wrap"
      bg="white"
      position="sticky"
      zIndex="sticky" // 'sticky' is a valid chakra zIndex
      top={`${stickyTop}px`}
    >
      <ToolbarButtons />
      <Spacer />
      {onCancel && (
        <Button isLoading={isSaving} onClick={onCancel} size="sm" colorScheme="red">
          Anuluj
        </Button>
      )}
      {onSave && (
        <Button isLoading={isSaving} onClick={onSave} size="sm" colorScheme="green">
          Zapisz
        </Button>
      )}
    </HStack>
  )
}

export default Toolbar
