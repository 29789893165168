import {formatISO} from 'date-fns'

import {Review, ReviewType} from '@/api/models'

import {ReviewsState} from './state'

export const emptyReview: Review = {
  id: '',
  created_at: formatISO(new Date()),
  updated_at: formatISO(new Date()),
  user: '',
  content: '',
  featured_index: null,
  rating: 0,
  published: false,
}

export const reviewTypeToRPC: Record<ReviewType, string> = {
  course: 'upsert_course_review',
  meeting: 'upsert_meeting_review',
  webinar: 'upsert_webinar_review',
  support_group: 'upsert_support_group_review',
}

export const reviewStateToUpsertArgs = ({review, itemID, type, hostID}: ReviewsState) => {
  // common for all review types
  const base = {
    review_id: review.id || undefined,
    user_id: review.user || undefined,
    rating: review.rating,
    content: review.content,
    published: review.published,
    featured_index: review.featured_index,
  }

  switch (type) {
    case 'course':
      return {
        ...base,
        course_id: itemID,
      }
    case 'meeting':
    case 'webinar':
      return {
        ...base,
        meeting_id: itemID,
      }
    case 'support_group':
      return {
        ...base,
        support_group_id: itemID,
        host_id: hostID,
      }
  }
}
