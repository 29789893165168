import * as React from 'react'

import jsonp from 'fetch-jsonp'

const getAjaxURL = (url: string) => url.replace('/post?', '/post-json?')

export const useMailchimpSubscribe = (url: string) => {
  const [loading, setLoading] = React.useState(false)
  const subscribe = React.useCallback(
    async (email: string) => {
      const params = new URLSearchParams({
        EMAIL: email,
      })
      const ajaxURL = getAjaxURL(url)
      setLoading(true)
      try {
        const data = await jsonp(ajaxURL + '&' + params.toString(), {
          jsonpCallback: 'c',
        })
        const result = await data.json()
        if (result.result !== 'success') {
          throw new Error(result.msg)
        }
        // otherwise resolve
      } finally {
        setLoading(false)
      }
    },
    [url]
  )
  return {loading, subscribe}
}
