import React, {useCallback, useEffect, useState} from 'react'

import {
  Button,
  Code,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {Payment} from '@/api/models'
import {CommonModalProps} from '@/utils/types'

const PaymentModal = ({
  open,
  onClose,
  payment,
}: CommonModalProps & {
  payment: string | null
}) => {
  const toast = useToast()

  const [paymentsDetails, setPaymentsDetails] = useState<Payment[]>()
  const [isLoading, setIsLoading] = useState(false)

  const fetchPaymentDetails = useCallback(async () => {
    if (!payment) {
      return
    }

    try {
      setIsLoading(true)
      const {data, error} = await supabase.from('payments').select().eq('id', payment).limit(1)
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('Did not find any payment')
      }
      setPaymentsDetails(data)
    } catch (e) {
      console.error('Failed to fetch payments for the course', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się pobrać szczegółów płatności.',
      })
    } finally {
      setIsLoading(false)
    }
  }, [payment, toast])

  useEffect(() => {
    fetchPaymentDetails()
  }, [fetchPaymentDetails])

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="500">
        <ModalHeader>Informacje o płatności</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          {isLoading
            ? 'Ładowanie...'
            : paymentsDetails &&
              paymentsDetails.map((payment) => (
                <Code key={payment.id} display="block" whiteSpace="pre" p={4}>
                  {JSON.stringify(payment, null, '  ')}
                </Code>
              ))}
        </ModalBody>

        <ModalFooter justifyContent="center">
          <Button mr={3} onClick={onClose}>
            Zamknij
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PaymentModal
