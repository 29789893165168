import React from 'react'

import {generatePath} from 'react-router'
import {useHistory} from 'react-router-dom'

import {BlogPost} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'
import {BLOG_POST} from '@/router/paths'

import {emptyBlogPost, defaultSelectedColumns, columns, filterFields} from './constants'
import UpsertBlogPostModal from './editor-modal'
import {openEditorAction} from './utils'

const BlogPosts = () => {
  const {push} = useHistory()
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<BlogPost>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof BlogPost,
    pageNumber: tableState.page,
    table: 'blog_posts',
  })

  const {onClose: onDeleteClose, onOpen: onDeleteOpen, value: deleteValue} = useValueDisclosure<BlogPost>()
  const {value: editorValue, onOpen: onEditorOpen, onClose: onEditorClose} = useValueDisclosure<BlogPost>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyBlogPost)
  }, [onEditorOpen])

  const actions = React.useMemo(
    () => [
      deleteAction(onDeleteOpen),
      editAction(onEditorOpen),
      openEditorAction(({id}: BlogPost) => id && push(generatePath(BLOG_POST, {id}))),
    ],
    [onDeleteOpen, onEditorOpen, push]
  )

  return (
    <>
      <DataTable<BlogPost>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <UpsertBlogPostModal
        item={editorValue}
        open={!!editorValue}
        onClose={onEditorClose}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="blog_posts"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
        headerText="Usunąć post?"
        name={deleteValue?.name}
        onSuccessTitle="Post został usunięty"
        onFailTitle="Nie udało się usunąć postu"
      />
    </>
  )
}

export default BlogPosts
