export type PGInterval = {
  hours: number
  minutes: number
  seconds: number
}

export const parsePGInterval = (raw: string) => {
  const split = raw.split(':')
  if (split.length !== 3) {
    return emptyPGInterval
  }
  const d: PGInterval = {
    hours: parseInt(split[0]),
    minutes: parseInt(split[1]),
    seconds: parseInt(split[2]),
  }
  return d
}

export const formatPGInterval = (d: PGInterval) => {
  return `${d.hours}:${d.minutes}:${d.seconds}`
}

export const emptyPGInterval: PGInterval = {
  hours: 0,
  minutes: 0,
  seconds: 0,
}
