import * as React from 'react'

import {Box, Container, Flex, Heading, Image, Text} from '@chakra-ui/react'
import {scroller} from 'react-scroll'

import {ArrowIconButton} from '@/common/arrow-button'

import hero from './hero.jpg'
import WebinarBanner from './webinar-banner'

const arrowStyle = {
  transform: 'rotate(90deg)',
}

const Hero = () => {
  const scrollDown = () => {
    scroller.scrollTo('home-steps' as any, {
      duration: 500,
      offset: -150,
      smooth: true,
    })
  }

  return (
    <Box height={['calc(100vh - 150px)', null, 'calc(100vh - 150px)']} width="100%" position="relative">
      <Image src={hero} width="100%" height="100%" objectFit="cover" objectPosition="top" />
      <Box position="absolute" left="0px" top="0px" width="100%" height="100%">
        <Container maxW="container.xl" width="100%" height="100%" px={[8, null, 4]}>
          <Flex
            direction="column"
            width="100%"
            height="100%"
            justifyContent="space-between"
            color="white"
            pt="100px"
            pb="50px"
          >
            <Flex direction="row" justifyContent="space-between">
              <Box>
                <Heading as="h1" fontWeight={[600, null, 700]} fontSize="4xl" mb={4}>
                  Psychoforma
                </Heading>
                <Heading fontWeight={400} fontSize={['xl', null, '4xl']} lineHeight={['25px', null, '45px']}>
                  Jesteśmy tu dla Ciebie
                  <br />
                  ale{' '}
                  <Text fontWeight={700} display="inline">
                    to TY decydujesz
                  </Text>
                </Heading>
              </Box>
            </Flex>
            <Flex direction="row" alignItems="flex-end" justifyContent="flex-end" mb={2}>
              <ArrowIconButton
                aria-label="Przewiń dalej"
                style={arrowStyle}
                width="58px"
                height="58px"
                color="brand.yellow.600"
                _hover={{color: 'brand.yellow.700'}}
                onClick={scrollDown}
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
      <WebinarBanner />
    </Box>
  )
}

export default Hero
