import React from 'react'

import {
  Text,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  FormControl,
  HStack,
  AspectRatio,
} from '@chakra-ui/react'
import {Widget} from '@typeform/embed-react'
import {
  createPluginFactory,
  insertNodes,
  PlateEditor,
  PlateRenderElementProps,
  usePlateEditorState,
} from '@udecode/plate'
import randomstring from 'randomstring'
import {MdClose} from 'react-icons/md'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {TypeformElement} from '../custom-types'
import {EditorContext} from '../editor-context'
import {BlockButton} from '../toolbar/buttons'

const Typeform = ({id}: {id: string}) => {
  return (
    <AspectRatio ratio={16 / 9} width="100%" boxShadow="lg" overflow="hidden" borderRadius="md">
      <Widget id={id} redirectTarget="_blank" />
    </AspectRatio>
  )
}

const TypeformHelp = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <IconButton
        aria-label="typeform-help"
        variant="outline"
        size="xs"
        icon={<Text>?</Text>}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={8}>
            <Stack align="center">
              <Text>Typeform ID znajduje się w linku</Text>
              <code>
                https://form.typeform.com/to/<b>form-id</b>
              </code>
              <Text>lub</Text>
              <code>
                https://admin.typeform.com/form/<b>form-id</b>/...
              </code>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export const TypeformRenderer = ({attributes, element}: PlateRenderElementProps) => {
  const {readOnly} = React.useContext(EditorContext)
  const editor = usePlateEditorState()!

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: ReactEditor.findPath(editor, element)})
  }, [editor, element])

  return (
    <Flex position="relative" mb={4} {...attributes} contentEditable={false} userSelect="none" width="100%">
      <Box contentEditable={false} width="100%">
        {!!element.formID && <Typeform id={element.formID} />}
        {!readOnly && (
          <IconButton
            variant="outline"
            onClick={handleRemove}
            icon={<MdClose />}
            aria-label="remove-typeform"
            position="absolute"
            top="5px"
            right="5px"
            zIndex={2}
          />
        )}
      </Box>
    </Flex>
  )
}

export const InsertTypeformButton = () => {
  const editor = usePlateEditorState()!
  const {isOpen, onOpen, onClose} = useDisclosure()
  const modalFocusedRef = React.useRef<HTMLInputElement>(null)

  const [formID, setFormID] = React.useState('')

  const handleFormIDChange = React.useCallback(({target: {value}}) => {
    setFormID(value.trim())
  }, [])

  const handleToolbarButtonClick = React.useCallback(() => {
    setFormID('')
    onOpen()
  }, [onOpen])

  const handleSubmit = React.useCallback(() => {
    if (!formID) return
    insertTypeform(editor, formID)
    onClose()
  }, [editor, onClose, formID])

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} initialFocusRef={modalFocusedRef} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wstaw ankietę Typeform</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormControl>
                <HStack mb={2}>
                  <Text>Typeform ID</Text>
                  <TypeformHelp />
                </HStack>
                <Input
                  ref={modalFocusedRef}
                  placeholder="ID"
                  variant="filled"
                  value={formID}
                  onChange={handleFormIDChange}
                />
              </FormControl>
              {!!formID && <Typeform id={formID} />}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Anuluj
            </Button>
            <Button colorScheme="green" onClick={handleSubmit} disabled={!formID}>
              Wstaw
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <BlockButton
        label="Typeform"
        format="typeform"
        icon={<Text>T</Text>}
        onClick={handleToolbarButtonClick}
      />
    </>
  )
}

const insertTypeform = (editor: PlateEditor, formID: string) => {
  const form: TypeformElement = {
    children: [{text: ''}],
    formID,
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    type: 'typeform',
  }
  insertNodes<TypeformElement>(editor, form)
}

export const createTypeformPlugin = createPluginFactory({
  component: TypeformRenderer,
  isElement: true,
  isVoid: true,
  key: 'typeform',
  type: 'typeform',
})
