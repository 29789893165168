import * as React from 'react'

import {supabase} from '@/api'
import {PGTimeRangeSlot} from '@/api/models'
import {parsePgTimestampRange} from '@/api/utils'
import useLoadingState from '@/common/use-loading-state'
import useRunOnReconnect from '@/common/use-run-on-reconnect'

type Args = {
  therapist?: number | null
  range?: PGTimeRangeSlot
}

type GetTherapistCalendarEventsRes =
  | {
      error: string
    }
  | {
      time_slots: string[]
    }

const loadingStateOptions = {
  onErrorToast: 'Nie udało się pobrać informacji o kalendarzu psychoterapeuty',
}

export const useTherapistCalendarEvents = ({therapist, range}: Args) => {
  const [events, setEvents] = React.useState<PGTimeRangeSlot[]>([])

  const {handleSubmit: fetch, loading} = useLoadingState(
    React.useCallback(async () => {
      if (!therapist || !range) return

      const {data, error} = await supabase.rpc('get_therapist_calendar_events', {
        therapist_id: therapist,
        start_date: range?.from.toDateString(),
        end_date: range?.to.toDateString(),
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      if (error) throw error
      const res = data as any as GetTherapistCalendarEventsRes
      if (!res) throw new Error('No result')
      if ('error' in res) throw new Error(res.error)

      setEvents(res.time_slots.map((s) => parsePgTimestampRange(s) as PGTimeRangeSlot).filter((s) => !!s))
    }, [therapist, range]),
    loadingStateOptions
  )

  useRunOnReconnect(fetch)

  React.useEffect(() => {
    fetch()
  }, [fetch])

  return {events, fetch, loading}
}
