import * as React from 'react'

import {Text, Stack, Box, Button} from '@chakra-ui/react'

export type SummaryItemProps = {
  title: string
  children: React.ReactNode
  onEdit?: () => void
}

const SummaryItem = ({title, children, onEdit}: SummaryItemProps) => (
  <Stack direction={['column', null, 'row']} fontSize="xl" spacing={[2, null, 4]}>
    <Text flex="3" fontWeight={700}>
      {title}
    </Text>
    <Stack flex="4" spacing={1} align="flex-start" justify="center">
      <Box>{children}</Box>
      {onEdit && (
        <Button variant="brandLink" onClick={onEdit} fontWeight={500}>
          Zmień
        </Button>
      )}
    </Stack>
  </Stack>
)

export default SummaryItem
