import React from 'react'

import {Text} from '@chakra-ui/react'
import {createPluginFactory, HotkeyPlugin, onKeyDownToggleMark, PlateRenderElementProps} from '@udecode/plate'

const BoldRenderer = ({children}: PlateRenderElementProps) => (
  <Text as="span" fontWeight="bold">
    {children}
  </Text>
)

export const createBoldPlugin = createPluginFactory<HotkeyPlugin>({
  component: BoldRenderer,
  handlers: {
    onKeyDown: onKeyDownToggleMark,
  },
  isLeaf: true,
  key: 'bold',
  options: {
    hotkey: 'mod+b',
  },
  type: 'bold',
})
