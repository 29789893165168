import * as React from 'react'

import {Redirect, Route, RouteProps, useLocation, useParams} from 'react-router-dom'

import {AbilityContext} from '@/auth/abilities'
import {selectProfileLoading, setModalState} from '@/auth/state'
import LoadingView from '@/common/loading-view'
import {useAppDispatch, useAppSelector} from '@/store'

import {HOME} from './paths'

type Props = RouteProps & {
  route?: string | ((params: Readonly<Record<string, string>>) => string)
}

const ProtectedRoute = ({route, ...rest}: Props) => {
  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectProfileLoading)
  const ability = React.useContext(AbilityContext)

  const {pathname} = useLocation()
  const params = useParams()

  if (loading) {
    return <LoadingView />
  }

  // if user is logged out redirect him to the sign in page,
  // he will be redirected back to the page he tried to access (considering he has the privilege) after signing in.
  if (ability.cannot('access', 'LoggedInRoute')) {
    dispatch(
      setModalState({
        redirectPath: pathname,
        state: 'signin',
      })
    )
    return <Redirect to={HOME} />
  }

  // if a logged in user tries to access a route he shouldn't redirect him to the homepage
  if (
    typeof route === 'string'
      ? ability.cannot('access', route)
      : route && params && ability.cannot('access', route(params))
  ) {
    return <Redirect to={HOME} />
  }

  return <Route {...rest} />
}

export default ProtectedRoute
