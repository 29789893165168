import * as React from 'react'

import {Button, Stack, Text, Skeleton, Grid, GridItem} from '@chakra-ui/react'
import {format} from 'date-fns'

import {useUpcomingTimeSlots} from './hooks'

export type Props = {
  therapist: number
  service: string
  onClick: (date: Date) => void
}

const TimeSlotSelector = ({therapist, service, onClick}: Props) => {
  const {data, fetch, loading} = useUpcomingTimeSlots(therapist, service)
  const dateString = React.useMemo(() => {
    if ((data?.length ?? 0) === 0) {
      return 'Brak'
    }
    const firstDate = data[0].from
    return format(firstDate, 'dd-MM')
  }, [data])

  return (
    <Stack
      direction="column"
      spacing={8}
      width="100%"
      opacity={loading ? 0.6 : 1}
      pointerEvents={loading ? 'none' : undefined}
    >
      <Stack direction="row" alignItems="center" spacing={2} fontWeight={500}>
        <Text>Najbliższy wolny termin:</Text>
        {loading ? <Skeleton w="50px" h="20px" /> : <Text>{dateString}</Text>}
      </Stack>

      <Grid templateColumns="repeat(4, 1fr)" gap={2}>
        {data?.map((slot, i) => (
          <GridItem key={i} as={Button} rounded="md" alignItems="center" onClick={() => onClick(slot.from)}>
            {format(slot.from, 'HH:mm')}
          </GridItem>
        ))}
      </Grid>
    </Stack>
  )
}

export default TimeSlotSelector
