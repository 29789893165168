import * as React from 'react'

import {Button, Flex, Stack, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {MySupportGroupMeeting} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import {MEETING, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {SpotlightItem} from '@/user/components/spotlight-item'
import {formatMeetingDuration} from '@/utils/string'

type Props = {
  item: MySupportGroupMeeting
  onReviewComplete?: () => void
}

const JoinableSupportGroupMeeting = ({item, onReviewComplete}: Props) => (
  <SpotlightItem
    image={item.thumbnail}
    title={item.name}
    button={
      <ArrowButton as={Link} to={generatePath(MEETING, {id: item.id})}>
        Dołącz do spotkania
      </ArrowButton>
    }
    reviewButton={
      <ReviewModalButton
        type="support_group"
        withText={true}
        itemID={item.support_group}
        reviewed={item.reviewed}
        onComplete={onReviewComplete}
      />
    }
  >
    <Stack spacing={0}>
      <Stack direction={['column', null, 'row']} align="center" spacing={[0, null, 2]}>
        <Text fontWeight={700}>Grupa wsparcia online</Text>
        <Text>{formatMeetingDuration(item.starts_at, item.ends_at)}</Text>
      </Stack>
      <Stack direction={['column', null, 'row']} align="center" spacing={[0, null, 2]}>
        <Text fontWeight={700}>Prowadzący</Text>
        <Flex>
          {item.hosts.map((h, i) => (
            <React.Fragment key={i}>
              {!!i && <Text>,</Text>}
              <Button
                variant="brandLink"
                ml={i ? 1 : 0}
                as={Link}
                to={generatePath(PSYCHOTHERAPY_THERAPIST, {id: h.therapist_id, slug: h.slug})}
              >
                {h.full_name}
              </Button>
            </React.Fragment>
          ))}
        </Flex>
      </Stack>
    </Stack>
  </SpotlightItem>
)

export default JoinableSupportGroupMeeting
