import * as React from 'react'

import {Table, TableProps, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react'

const cellProps = {
  px: [2, null, 6],
  py: [2, null, 3],
}

const TShirtSizesTable = (props: TableProps) => {
  return (
    <Table variant="simple" {...props}>
      <Thead>
        <Tr>
          <Th {...cellProps} />
          <Th {...cellProps} textAlign="center">
            XS
          </Th>
          <Th {...cellProps} textAlign="center">
            S
          </Th>
          <Th {...cellProps} textAlign="center">
            M
          </Th>
          <Th {...cellProps} textAlign="center">
            L
          </Th>
          <Th {...cellProps} textAlign="center">
            XL
          </Th>
          <Th {...cellProps} textAlign="center">
            XXL
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        <Tr>
          <Th {...cellProps} textAlign="right">
            USA
          </Th>
          <Td {...cellProps} textAlign="center">
            28
          </Td>
          <Td {...cellProps} textAlign="center">
            30
          </Td>
          <Td {...cellProps} textAlign="center">
            32
          </Td>
          <Td {...cellProps} textAlign="center">
            34
          </Td>
          <Td {...cellProps} textAlign="center">
            36
          </Td>
          <Td {...cellProps} textAlign="center">
            38
          </Td>
        </Tr>
        <Tr>
          <Th {...cellProps} textAlign="right">
            Francja
          </Th>
          <Td {...cellProps} textAlign="center">
            28
          </Td>
          <Td {...cellProps} textAlign="center">
            30
          </Td>
          <Td {...cellProps} textAlign="center">
            32
          </Td>
          <Td {...cellProps} textAlign="center">
            34
          </Td>
          <Td {...cellProps} textAlign="center">
            36
          </Td>
          <Td {...cellProps} textAlign="center">
            38
          </Td>
        </Tr>
        <Tr>
          <Th {...cellProps} textAlign="right">
            Niemcy
          </Th>
          <Td {...cellProps} textAlign="center">
            28
          </Td>
          <Td {...cellProps} textAlign="center">
            30
          </Td>
          <Td {...cellProps} textAlign="center">
            32
          </Td>
          <Td {...cellProps} textAlign="center">
            34
          </Td>
          <Td {...cellProps} textAlign="center">
            36
          </Td>
          <Td {...cellProps} textAlign="center">
            38
          </Td>
        </Tr>
        <Tr>
          <Th {...cellProps} textAlign="right">
            Hiszpania
          </Th>
          <Td {...cellProps} textAlign="center">
            28
          </Td>
          <Td {...cellProps} textAlign="center">
            30
          </Td>
          <Td {...cellProps} textAlign="center">
            32
          </Td>
          <Td {...cellProps} textAlign="center">
            34
          </Td>
          <Td {...cellProps} textAlign="center">
            36
          </Td>
          <Td {...cellProps} textAlign="center">
            38
          </Td>
        </Tr>
        <Tr>
          <Th {...cellProps} textAlign="right">
            Portugalia
          </Th>
          <Td {...cellProps} textAlign="center">
            28
          </Td>
          <Td {...cellProps} textAlign="center">
            30
          </Td>
          <Td {...cellProps} textAlign="center">
            32
          </Td>
          <Td {...cellProps} textAlign="center">
            34
          </Td>
          <Td {...cellProps} textAlign="center">
            36
          </Td>
          <Td {...cellProps} textAlign="center">
            38
          </Td>
        </Tr>
      </Tbody>
    </Table>
  )
}

export default TShirtSizesTable
