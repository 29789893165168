import React from 'react'

import {Text} from '@chakra-ui/react'
import {createPluginFactory, HotkeyPlugin, onKeyDownToggleMark, PlateRenderElementProps} from '@udecode/plate'

const UnderlineRenderer = ({children}: PlateRenderElementProps) => (
  <Text as="span" textDecoration="underline">
    {children}
  </Text>
)

export const createUnderlinePlugin = createPluginFactory<HotkeyPlugin>({
  component: UnderlineRenderer,
  handlers: {
    onKeyDown: onKeyDownToggleMark,
  },
  isLeaf: true,
  key: 'underline',
  options: {
    hotkey: 'mod+u',
  },
  type: 'underline',
})
