import {TNode} from '@udecode/plate'
import randomstring from 'randomstring'

import {AttachmentRenderer} from './plugins/attachment'
import {BlockquoteRenderer} from './plugins/blockquote'
import {CanvaRenderer} from './plugins/canva'
import {HeadingOneRenderer} from './plugins/heading-one'
import {HeadingTwoRenderer} from './plugins/heading-two'
import {ImageRenderer} from './plugins/image'
import {OrderedListRenderer, UnorderedListRenderer} from './plugins/list'
import {ParagraphRenderer} from './plugins/paragraph'
import {SurveyRenderer} from './plugins/survey'
import {TypeformRenderer} from './plugins/typeform'
import {VimeoRenderer} from './plugins/vimeo'
import {YouTubeRenderer} from './plugins/youtube'
import {ContentEditorVariant} from './types'
import withStyledDraggables from './with-styled-draggables'

export const EDITOR_BLOCK_ID_LENGTH = 8

export const initialValue: TNode[] = [
  {
    children: [{text: ''}],
    type: 'p',
  },
]

export const trailingBlockPluginConfig = {type: 'p'}

export const nodeIdPluginConfig = {
  options: {
    idCreator: () => randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
  },
}

export const basicComponents = {
  p: ParagraphRenderer,
}

export const extendedComponents = {
  ...basicComponents,
  blockquote: BlockquoteRenderer,
  canva: CanvaRenderer,
  'heading-one': HeadingOneRenderer,
  'heading-two': HeadingTwoRenderer,
  ol: OrderedListRenderer,
  ul: UnorderedListRenderer,
  typeform: TypeformRenderer,
  vimeo: VimeoRenderer,
  youtube: YouTubeRenderer,
}

export const surveysDisabledComponents = {
  ...extendedComponents,
  attachment: AttachmentRenderer,
  image: ImageRenderer,
}

export const allComponents = {...surveysDisabledComponents, survey: SurveyRenderer}

export const getComponents = (variant: ContentEditorVariant, surveys?: boolean) => {
  let c
  switch (variant) {
    case 'basic':
      c = basicComponents
      break
    case 'extended':
      c = extendedComponents
      break
    default:
      c = surveys ? allComponents : surveysDisabledComponents
  }
  return withStyledDraggables(c)
}
