import * as React from 'react'

import {SupportGroupReviewsView} from '@/api/models'
import Reviews from '@/common/reviews'
import useFetchSingle from '@/common/use-fetch-single'

const MAX_REVIEWS_COUNT = 4

const SupportGroupReviews = ({id}: {id: number}) => {
  const {data, loading} = useFetchSingle<SupportGroupReviewsView>(
    React.useMemo(
      () => ({
        table: 'support_group_reviews_view',
        fields: '*',
        itemsPerPage: MAX_REVIEWS_COUNT,
        match: {support_group: id},
        errSnackbarTitle: 'Nie udało się wczytać recenzji grupy wsparcia',
      }),
      [id]
    )
  )

  const reviews = React.useMemo(
    () =>
      data?.reviews?.map((v) => ({
        author: v.author,
        content: v.content,
        rating: v.rating,
      })) || [],
    [data]
  )

  return !reviews.length && !loading ? null : <Reviews reviews={reviews} loading={loading} />
}

export default SupportGroupReviews
