import * as React from 'react'

import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import isEmail from 'validator/lib/isEmail'

import {supabase} from '@/api'
import {MeetingInvitation} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import useLoadingState from '@/common/use-loading-state'

type Props = {
  isOpen: boolean
  onClose: () => void
  meeting: string
  invitations: MeetingInvitation[]
  fetchInvitations: () => Promise<void>
}

const statusToText: Record<string, string> = {
  accepted: 'Zaakceptowano',
  ignored: 'Odrzucono',
  pending: 'Oczekuje',
}

const InviteModal = (props: Props) => {
  const toast = useToast()

  const filteredInvitations = React.useMemo(
    () => props.invitations.filter((invitation) => !!invitation.to),
    [props.invitations]
  )

  const [email, setEmail] = React.useState('')
  const isEmailValid = React.useMemo(() => email && isEmail(email), [email])
  const _handleSubmit = React.useCallback(
    async (e?: React.FormEvent) => {
      !!e && e.preventDefault()

      try {
        const {error} = await supabase.rpc('create_meeting_invitation', {
          email: email,
          meeting_id: props.meeting,
        })
        if (error) {
          throw error
        }
        setEmail('')
        props.fetchInvitations()
        toast({
          isClosable: true,
          status: 'success',
          title: 'Wysłano zaproszenie do spotkania',
        })
      } catch (e) {
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zaprosić użytkownika',
        })
      }
    },
    [toast, email, props]
  )
  const handleEmailChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }, [])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Zaproś do spotkania</ModalHeader>
        <ModalCloseButton />
        <ModalBody mb={8}>
          <Stack direction="column" spacing={8}>
            <form onSubmit={handleSubmit}>
              <Stack direction="column" spacing={4}>
                <Text fontSize="sm" fontWeight={500}>
                  Wpisz adres e-mail
                </Text>
                <Input
                  type="email"
                  variant="outline"
                  placeholder="przyklad@email.com"
                  value={email}
                  onChange={handleEmailChange}
                />
                <Flex alignItems="flex-end" direction="column">
                  <ArrowButton
                    type="submit"
                    bg="brand.yellow.600"
                    hoverBg="brand.yellow.700"
                    color="white"
                    height={30}
                    fontSize="md"
                    fontWeight={500}
                    isDisabled={loading || !isEmailValid}
                    isLoading={loading}
                  >
                    Wyślij zaproszenie
                  </ArrowButton>
                </Flex>
              </Stack>
            </form>
            <Text fontSize="sm" fontWeight={500}>
              Lista zaproszonych
            </Text>
            <Stack direction="column" spacing={4}>
              {filteredInvitations.length === 0 ? (
                <Text fontSize="sm">Brak zaproszonych osób</Text>
              ) : (
                filteredInvitations.map((invitation, i) => (
                  <Stack direction="row" spacing={2} key={i}>
                    <Text flex={1} fontWeight={300}>
                      {invitation.to}
                    </Text>
                    <Text textAlign="right" fontWeight={500}>
                      {statusToText[invitation.status]}
                    </Text>
                  </Stack>
                ))
              )}
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InviteModal
