import React from 'react'

import {Accordion} from '@chakra-ui/react'

import {supabase} from '@/api'
import {GetQuestionAnswerItemsResult} from '@/api/models'
import useLoadingState from '@/common/use-loading-state'
import useRunOnReconnect from '@/common/use-run-on-reconnect'

import {QuestionAnswerContent} from '../types'
import QuestionsAndAnswersItem from './item'

type Props = {
  id: string
}

const QuestionsAndAnswers = ({id}: Props) => {
  const [items, setItems] = React.useState<QuestionAnswerContent[]>([])

  const {handleSubmit: fetch, loading} = useLoadingState(
    React.useCallback(async () => {
      const {data, error} = await supabase.rpc('get_question_answer_items', {
        page_id: id,
      })
      if (error) throw error
      if (!data) throw new Error('No data')
      const result = data as any as GetQuestionAnswerItemsResult
      if ('error' in result) throw new Error(result.error)

      setItems(result.data ?? [])
    }, [id])
  )

  useRunOnReconnect(fetch)

  React.useEffect(() => {
    fetch()
  }, []) // eslint-disable-line

  const expandedIndices = React.useMemo(
    () => items.map((item, ix) => (item.collapsed ? -1 : ix)).filter((ix) => ix !== -1),
    [items]
  )

  return !items?.length ? null : (
    <Accordion allowMultiple={true} defaultIndex={expandedIndices}>
      {items.map((item, index) => (
        <QuestionsAndAnswersItem key={index} content={item} isLoading={loading} />
      ))}
    </Accordion>
  )
}

export default QuestionsAndAnswers
