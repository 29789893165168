import * as React from 'react'

import {Progress, Stack, Text} from '@chakra-ui/react'
import {format} from 'date-fns'
import {Link, generatePath} from 'react-router-dom'

import {MyMeeting} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import {MEETING} from '@/router/paths'

export type Props = {
  meeting: MyMeeting
}

const UpcomingMeeting = ({meeting}: Props) => {
  const parsedDate = React.useMemo(() => new Date(meeting.starts_at), [meeting])

  const isJoinable = React.useMemo(
    () => new Date(meeting.joinable_from) < new Date() && new Date() < new Date(meeting.joinable_to),
    [meeting]
  )

  return (
    <Stack direction={['column', null, 'row']} justifyContent="space-between" alignItems="center">
      <Stack direction="column">
        <Text fontSize="lg" fontWeight={500} textAlign={['center', null, 'left']}>
          {meeting.therapist_full_name}
        </Text>
        <Stack direction="row" spacing={2} textAlign={['center', null, 'left']}>
          <Text as="span" fontWeight={500}>
            {meeting.service_name}
          </Text>
          <Text as="span" fontWeight={300}>
            {format(parsedDate, "dd.MM.yyyy | 'godz.' HH:mm")}
          </Text>
        </Stack>
      </Stack>
      <ArrowButton
        flexShrink={0}
        as={Link}
        to={generatePath(MEETING, {id: meeting.id})}
        bg="brand.yellow.600"
        hoverBg="brand.yellow.700"
        color="white"
        height={40}
        fontSize="lg"
        fontWeight={500}
        pointerEvents={isJoinable ? 'inherit' : 'none'}
        isDisabled={!isJoinable}
      >
        Rozpocznij rozmowę
      </ArrowButton>
    </Stack>
  )
}

export default UpcomingMeeting
