import * as React from 'react'

import {CheckIcon, CopyIcon} from '@chakra-ui/icons'
import {IconButton, Tooltip} from '@chakra-ui/react'

import useLoadingState from '@/common/use-loading-state'

const CopyToClipboardButton = ({value}: {value: string}) => {
  const [copied, setCopied] = React.useState(false)
  const timeoutID = React.useRef<NodeJS.Timeout | null>(null)

  const {handleSubmit: handleCopy, loading} = useLoadingState(
    React.useCallback(async () => {
      setCopied(false)
      if (timeoutID.current) {
        clearTimeout(timeoutID.current)
      }

      await navigator.clipboard.writeText(value)
      setCopied(true)

      timeoutID.current = setTimeout(() => {
        setCopied(false)
        timeoutID.current = null
      }, 2000)
    }, [value])
  )

  return (
    <Tooltip label={copied ? 'Skopiowano' : 'Kopiuj do schowka'}>
      <IconButton
        aria-label="copy-to-clipboard"
        icon={copied ? <CheckIcon color="green" /> : <CopyIcon />}
        onClick={handleCopy}
        isLoading={loading}
      />
    </Tooltip>
  )
}

export default CopyToClipboardButton
