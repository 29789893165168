import React from 'react'

import {Box, Button, Flex, Heading, Stack, Text} from '@chakra-ui/react'

import {supabase} from '@/api'
import {ToggleJournalSharedPageResult} from '@/api/models'
import {selectUser} from '@/auth/state'
import useLoadingState from '@/common/use-loading-state'
import {useAppSelector} from '@/store'

import {JournalPage} from './types'

const JournalPageItem = ({page}: {page: JournalPage}) => {
  const me = useAppSelector(selectUser)
  const [isShared, setIsShared] = React.useState(page.is_shared)

  const {handleSubmit: togglePageShare, loading} = useLoadingState(
    React.useCallback(async () => {
      try {
        const {data, error} = await supabase.rpc('toggle_journal_shared_page', {
          page_id: page.id,
        })
        if (error) throw error

        const response = data as any as ToggleJournalSharedPageResult
        if ('error' in response) throw new Error(response.error)

        setIsShared(response.shared)
      } catch (e) {
        console.error(e)
      }
    }, [page])
  )

  return (
    <Box bg="gray.100" p="4" borderRadius="xl">
      <Flex alignpages="center" justifyContent="space-between">
        <Heading size="md" fontWeight="semibold">
          {page.title}
        </Heading>
        {page.user === me?.id && (
          <Button
            size="sm"
            colorScheme={isShared ? 'red' : 'green'}
            onClick={togglePageShare}
            isLoading={loading}
          >
            {isShared ? 'Anuluj udostępnienie' : 'Udostępnij stronę'}
          </Button>
        )}
      </Flex>
      <Stack mt="5" spacing="4">
        {page.surveys.map((survey, i) => (
          <Stack key={i}>
            {survey.questions.map(({id, question}, q_ix) => (
              <Box key={id}>
                <Text fontWeight="semibold">
                  {q_ix + 1}. {question}
                </Text>
                <Text fontStyle="italic" mt=".5" whiteSpace="pre-wrap">
                  {survey.answers[id] || 'Brak odpowiedzi'}
                </Text>
              </Box>
            ))}
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}

export default JournalPageItem
