import React from 'react'

import {AddIcon} from '@chakra-ui/icons'
import {IconButton, SkeletonText, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {generatePath} from 'react-router-dom'

import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'
import {PSYCHOTHERAPISTS, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'

import CreateRelatedBlogPostTherapistModal from './create-related-therapist-modal'
import RelatedBlogPostItem, {RelatedItem} from './related-item'

const RelatedBlogPostTherapists = ({editing, forBlogPost}: {editing: boolean; forBlogPost: number}) => {
  const {loading, data, fetch} = useSupabaseQuery<RelatedItem>(
    React.useMemo(
      () => ({
        fields: '*',
        table: 'blog_post_suggested_therapists_view',
        match: {blog_post_id: forBlogPost},
        errSnackbarTitle: 'Nie udało się pobrać powiązanych terapeutów',
      }),
      [forBlogPost]
    )
  )

  const {
    isOpen: isAddSuggestionOpen,
    onOpen: onAddSuggestionOpen,
    onClose: onAddSuggestionClose,
  } = useDisclosure()

  const {
    onClose: onDeleteDialogClose,
    onOpen: onDeleteDialogOpen,
    value: deleteDialogValue,
  } = useValueDisclosure<string>()

  if (!loading && !data?.length && !editing) {
    return null
  }

  return (
    <>
      <DeleteResourceModal
        table="blog_post_suggested_therapists"
        id={deleteDialogValue ?? ''}
        onClose={onDeleteDialogClose}
        open={!!deleteDialogValue}
        onComplete={fetch}
      />
      <CreateRelatedBlogPostTherapistModal
        blogPost={forBlogPost}
        open={isAddSuggestionOpen}
        onClose={onAddSuggestionClose}
        onComplete={fetch}
      />
      <Stack direction="row" spacing={4} justifyContent="space-between" alignItems="center" mb={8}>
        <Text fontSize="xl">Porozmawiaj na ten temat</Text>
        {editing && (
          <IconButton
            size="xs"
            onClick={onAddSuggestionOpen}
            icon={<AddIcon />}
            aria-label="Add suggestion"
            colorScheme="green"
          />
        )}
      </Stack>
      <SkeletonText noOfLines={10} isLoaded={!loading} mb={6}>
        <Stack direction="column" spacing={4}>
          {data?.slice(0, 5).map((t, index) => (
            <RelatedBlogPostItem
              key={index}
              item={t}
              editing={editing}
              to={t.slug ? generatePath(PSYCHOTHERAPY_THERAPIST, {id: t.id, slug: t.slug}) : PSYCHOTHERAPISTS}
              onDelete={onDeleteDialogOpen}
            />
          ))}
        </Stack>
      </SkeletonText>
    </>
  )
}

export default RelatedBlogPostTherapists
