import React from 'react'

import {EditIcon} from '@chakra-ui/icons'
import {Stack, useToast} from '@chakra-ui/react'
import {MdAttachMoney as DollarSignIcon} from 'react-icons/md'

import {CourseRole, CourseUserAccessManagement} from '@/api/models'
import DataTable from '@/common/data-table'
import {Column} from '@/common/data-table/types'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction} from '@/common/data-table/utils'
import {FilterField} from '@/common/filter-builder/types'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import PaymentModal from '../payment-modal'
import CreateCourseUserModal from './create-course-user-modal'
import DeleteCourseUserDialog from './delete-course-user-dialog'

const CourseAccessManagementTable = ({course, type}: {course: number; type: CourseRole}) => {
  const toast = useToast()

  const tableState = useTableState({sortBy: 'id'})

  const {loading, data, fetch, rows} = useSupabaseQuery<CourseUserAccessManagement>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    finalize: React.useCallback(
      (b) => b.eq('course', course).eq('role', type).is('group', null),
      [course, type]
    ),
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof CourseUserAccessManagement,
    pageNumber: tableState.page,
    table: 'course_users_access_management',
  })

  const filterFields: FilterField[] = React.useMemo(() => {
    let table: FilterField[] = [
      {
        label: 'ID użytkownika',
        name: 'user',
        variant: 'text',
      },
      {
        label: 'Email',
        name: 'email',
        variant: 'text',
      },
      {
        label: 'Imię i nazwisko',
        name: 'full_name',
        variant: 'text',
      },
    ]
    if (type === 'user') {
      table = table.concat([
        {
          label: 'Pozostałe spotkania',
          name: 'meetings_left',
          variant: 'number',
        },
        {
          label: 'Posiada trenera',
          name: 'has_coach',
          variant: 'boolean',
        },
        {
          label: 'Email trenera',
          name: 'coach_email',
          variant: 'text',
        },
        {
          label: 'Imię trenera',
          name: 'coach_name',
          variant: 'text',
        },
      ])
    }
    return table
  }, [type])

  const columns: Column<CourseUserAccessManagement>[] = React.useMemo(() => {
    let columns: Column<CourseUserAccessManagement>[] = [
      {
        Header: 'ID użytkownika',
        id: 'id',
        sortable: true,
      },
      {
        Header: 'Email',
        id: 'email',
        sortable: false,
      },
      {
        Header: 'Imię i nazwisko',
        id: 'full_name',
        sortable: false,
      },
    ]
    if (type === 'user') {
      columns = columns.concat([
        {
          Header: 'Pozostałe spotkania',
          id: 'meetings_left',
        },
        {
          Header: 'Posiada trenera',
          id: 'has_coach',
          renderAs: 'booleanIcons',
        },
        {
          Header: 'Email trenera',
          id: 'coach_email',
          sortable: true,
        },
        {
          Header: 'Imię trenera',
          id: 'coach_name',
          sortable: true,
        },
      ])
    }
    return columns
  }, [type])

  const {
    value: paymentModalValue,
    onOpen: onPaymentModalOpen,
    onClose: onPaymentModalClose,
  } = useValueDisclosure<string>()

  const {
    value: createCourseUserModalValue,
    onOpen: onCreateCourseUserModalOpen,
    onClose: onCreateCourseUserModalClose,
  } = useValueDisclosure<CourseRole>()

  const {
    value: deleteCourseUserDialogValue,
    onOpen: onDeleteCourseUserDialogOpen,
    onClose: onDeleteCourseUserDialogClose,
  } = useValueDisclosure<CourseUserAccessManagement>()

  const openPaymentModal = React.useCallback(
    (cuj: CourseUserAccessManagement) => {
      if (!cuj.payment) {
        toast({
          isClosable: true,
          status: 'info',
          title: 'Nie znaleziono płatności',
        })
        return
      }
      onPaymentModalOpen(cuj.payment)
    },
    [onPaymentModalOpen, toast]
  )

  const [editedUser, setEditedUser] = React.useState<CourseUserAccessManagement>()

  const userActions = React.useMemo(
    () => [
      {
        icon: <DollarSignIcon size="1.2rem" />,
        id: 'payment',
        onClick: openPaymentModal,
        tooltip: 'Zobacz płatność',
      },
      {
        icon: <EditIcon />,
        id: 'edit',
        onClick: (user) => {
          setEditedUser(user)
          onCreateCourseUserModalOpen(type)
        },
        tooltip: 'Edytuj',
      },
    ],
    [onCreateCourseUserModalOpen, openPaymentModal, type]
  )

  const actions = React.useMemo(
    () => [...(type === 'user' ? userActions : []), deleteAction(onDeleteCourseUserDialogOpen)],
    [onDeleteCourseUserDialogOpen, type, userActions]
  )

  const handleCourseUserCreate = React.useCallback(() => {
    onCreateCourseUserModalOpen(type)
  }, [onCreateCourseUserModalOpen, type])

  const handleDeleteCourseUserDialogClose = React.useCallback(() => {
    onCreateCourseUserModalClose()
    setEditedUser(undefined)
  }, [onCreateCourseUserModalClose])

  return (
    <Stack w="100%" mt="4" spacing="4">
      <DataTable<CourseUserAccessManagement>
        columns={columns}
        data={data ?? []}
        totalCount={rows ?? 0}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleCourseUserCreate}
        filterFields={filterFields}
        loading={loading}
      />
      <PaymentModal open={!!paymentModalValue} onClose={onPaymentModalClose} payment={paymentModalValue} />
      <CreateCourseUserModal
        open={!!createCourseUserModalValue}
        onClose={handleDeleteCourseUserDialogClose}
        onComplete={fetch}
        course={course}
        type={createCourseUserModalValue}
        initValue={editedUser}
      />
      <DeleteCourseUserDialog
        open={!!deleteCourseUserDialogValue}
        onClose={onDeleteCourseUserDialogClose}
        onComplete={fetch}
        courseUser={deleteCourseUserDialogValue}
      />
    </Stack>
  )
}

export default CourseAccessManagementTable
