import * as React from 'react'

import {Box, Button, Collapse, Flex, Stack, useDisclosure} from '@chakra-ui/react'
import {TNode} from '@udecode/plate'
import {Element, scroller} from 'react-scroll'

import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'

type Props = {
  id: string
  shortDescription: TNode[] | null
  fullDescription?: TNode[] | null
}

const ShowcaseDescription = ({id, shortDescription, fullDescription}: Props) => {
  const {isOpen, onToggle} = useDisclosure()

  const onDescriptionToggle = React.useCallback(() => {
    onToggle()

    const top = document.getElementById(`${id}-short-description-scroll`)?.getBoundingClientRect().top ?? 0
    if (isOpen && top < window.scrollY)
      scroller.scrollTo(`${id}-short-description`, {
        duration: 250,
        offset: -200,
        smooth: true,
      })
  }, [id, isOpen, onToggle])

  return (
    <Stack>
      <Box>
        <Element name={`${id}-short-description`} id={`${id}-short-description-scroll`} />
        <ContentEditor
          id={`${id}-short-description`}
          variant="basic"
          value={shortDescription ?? initialEditorValue}
        />
      </Box>
      {fullDescription && (
        <>
          <Collapse in={isOpen} animateOpacity={true}>
            <ContentEditor
              id={`${id}-full-description`}
              variant="extended"
              value={fullDescription ?? initialEditorValue}
            />
          </Collapse>
          <Flex justify="flex-end">
            <Flex
              as="button"
              onClick={onDescriptionToggle}
              p={4}
              py={2}
              position="relative"
              justify="center"
              _after={{
                content: '""',
                w: 'calc(100% - 24px)',
                h: '2px',
                bg: 'black',
                position: 'absolute',
                bottom: '0',
                transition: 'all 100ms ease',
              }}
              _hover={{_after: {w: '100%'}}}
            >
              {isOpen ? 'Zwiń opis' : 'Pełny opis'}
            </Flex>
          </Flex>
        </>
      )}
    </Stack>
  )
}

export default ShowcaseDescription
