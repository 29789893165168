import * as React from 'react'

import {Button, Stack, Text, useDisclosure} from '@chakra-ui/react'

import {useBillingAddress} from '@/user/billing/hooks'
import UserBillingModal from '@/user/billing/modal'

type Props = {
  disabled: boolean
}

const AddressInput = ({disabled}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const address = useBillingAddress()

  return (
    <>
      <UserBillingModal open={isOpen} onClose={onClose} />

      <Stack align="flex-start">
        {address ? (
          <Stack spacing={1}>
            <Text>{address.company || `${address.first_name} ${address.last_name}`}</Text>
            {!!address.nip && <Text>NIP: {address.nip}</Text>}
            <Text>{address.address}</Text>
            <Text>
              {address.postal_code} {address.city}
            </Text>
          </Stack>
        ) : (
          <Text fontWeight={500} color="red">
            Brak
          </Text>
        )}
        <Button variant="brandLink" onClick={onOpen} isDisabled={disabled} fontWeight={500}>
          {address ? 'Zmień' : 'Dodaj'}
        </Button>
      </Stack>
    </>
  )
}

export default AddressInput
