import * as React from 'react'

import {Box, Container, Grid, GridItem, Heading, Stack, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import Section from '@/common/components/section'
import {CONTACT_US} from '@/router/paths'

import section1 from './section1.jpg'
import section2 from './section2.jpg'
import section3 from './section3.jpg'

const JoinUs = () => {
  return (
    <>
      <Container maxW="container.xl">
        <Stack spacing={16} py={16} alignItems="center">
          <Grid templateColumns="repeat(12, 1fr)" gap={8} mb={8}>
            <GridItem colSpan={[12, null, 4]} w="100%">
              <Box display="flex" flexDirection="row" justifyContent={['center', null, 'flex-end']}>
                <Heading as="h1" fontSize={28} fontWeight={500}>
                  Dołącz do nas
                </Heading>
              </Box>
            </GridItem>
            <GridItem colSpan={[12, null, 6]} w="100%">
              <Text fontWeight={300} fontSize={16} lineHeight="21px">
                Jesteś psychologiem, psychoterapeutą lub terapeutą uzależnień? Zapraszamy Cię do zespołu!
                Docieraj łatwo do osób, które potrzebują Twoich kompetencji, aby podjąć wyzwanie zmiany.
                Chcesz z nami pracować?
              </Text>
            </GridItem>
          </Grid>
          {/* First section */}
          <Section
            title="Pracuj dla nas zdalnie – z całej Polski"
            img={section1}
            barColor="#EFDEBD"
            size="sm"
          >
            <Text fontWeight={300}>
              Weź udział w projekcie, który - jako pierwszy - wspiera rozwój osobisty na różnych poziomach! U
              nas wygląda to tak: Ty skupiasz się na terapii, my dbamy o Twój komfort pracy. Szukamy
              doświadczonych psychoterapeutów z całej Polski. Skontaktuj się i zacznijmy działać!
            </Text>
          </Section>

          {/* Second section */}
          <Section
            title="Trzy kroki i... jesteś w naszym zespole"
            img={section2}
            barColor="#F9F2E6"
            size="sm"
            flip={true}
          >
            <Text fontWeight={300}>
              Zgłaszasz się do nas, omawiamy warunki współpracy i po weryfikacji dokumentów, możesz rozpocząć
              pracę. Od Ciebie zależy, ile i kiedy pracujesz - udostępniasz swój kalendarz. Pokazujemy Ci, jak
              korzystać z platformy, tworzymy Twoją wizytówkę z opisem doświadczenia i kompetencji, aby
              użytkownicy mogli wyszukać Cię po specjalizacji.
            </Text>
          </Section>

          {/* Third section */}
          <Section title="Co zyskujesz dzięki współpracy?" img={section3} barColor="#EFDEBD" size="sm">
            <Text fontWeight={300}>
              Masz szansę tworzyć coś przełomowego na polskim rynku i pracować tak, jak lubisz. Znamy
              specyfikę Twojej pracy i chcemy Ci maksymalnie ułatwić codzienne zadania. Dzięki nam skorzystasz
              z narzędzi, które odciążą Cię w zbędnych czynnościach, takich jak: potwierdzanie wizyt czy
              zarządzanie płatnościami.
            </Text>
          </Section>
        </Stack>
      </Container>
      <Box backgroundColor="brand.green.800" py={20} color="#fff" mt={[0, null, 8]}>
        <Container maxW="container.xl">
          <Stack direction="column" spacing={12} alignItems="center">
            <Heading size="lg" fontWeight={500}>
              Czekamy na Ciebie!
            </Heading>
            <ArrowButton
              as={Link}
              to={CONTACT_US}
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={55}
              fontSize="lg"
              fontWeight={500}
            >
              Zacznijmy współpracę
            </ArrowButton>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

export default JoinUs
