import React from 'react'

import {supabase} from '@/api'
import {DeleteTherapistCertificateResponse, UpdateTherapistCertificateVisibilityResponse} from '@/api/models'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useValueDisclosure from '@/common/use-value-disclosure'

import {TherapistCertificate} from '../types'
import TherapistCertificateItem from './certificate-item'

type Props = {
  certificates: TherapistCertificate[]
  therapist: number | null
  fetch: () => Promise<void>
  isDisabled?: boolean
}

const TherapistCertificates = ({therapist, certificates, fetch, isDisabled}: Props) => {
  const {onClose: onDeleteClose, onOpen: onDeleteOpen, value: deleteValue} = useValueDisclosure<string>()

  const [isChangingVisibility, setIsChangingVisibility] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)

  const handleCertificateVisibilityChange = React.useCallback(
    async (id: string, visible: boolean) => {
      setIsChangingVisibility(true)
      try {
        const {data, error} = await supabase.rpc('update_therapist_certificate_visibility', {
          certificate_id: id,
          public: visible,
          therapist_id: therapist,
        })
        if (error) {
          throw error
        }
        const response = data as any as UpdateTherapistCertificateVisibilityResponse
        if ('error' in response) {
          throw new Error(response.error)
        }
        fetch()
      } catch (e) {
        console.error(`Failed to change ${id} certificate visibility`, e)
      }
      setIsChangingVisibility(false)
    },
    [fetch, therapist]
  )

  const handleCertificateDelete = React.useCallback(async () => {
    if (!deleteValue) {
      return
    }
    setIsDeleting(true)
    try {
      const {data, error} = await supabase.rpc('delete_therapist_certificate', {
        certificate_id: deleteValue,
        therapist_id: therapist,
      })
      if (error) {
        throw error
      }
      const response = data as any as DeleteTherapistCertificateResponse
      if ('error' in response) {
        throw new Error(response.error)
      }
    } catch (e) {
      console.error(`Failed to delete ${deleteValue} certificate`, e)
    }
    setIsDeleting(false)
  }, [deleteValue, therapist])

  return (
    <>
      {certificates.map((c, i) => (
        <TherapistCertificateItem
          key={i}
          certificate={c}
          onVisibilityChange={handleCertificateVisibilityChange}
          onDelete={onDeleteOpen}
          isDisabled={isDisabled || isChangingVisibility || isDeleting}
        />
      ))}
      <DeleteResourceModal
        onDelete={handleCertificateDelete}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default TherapistCertificates
