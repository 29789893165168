import * as React from 'react'

import {Tag, Wrap, WrapItem} from '@chakra-ui/react'

import {SupportGroupJoined} from '@/api/models'
import {emptyPGTimeRange} from '@/api/utils'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptySupportGroup: SupportGroupJoined = {
  id: 0,
  name: '',
  slug: '',
  price: 0,
  rating: 0,
  max_members: 0,
  min_members: 0,
  meetings_left: 0,
  occupied_slots: 0,
  hosts: [],
  categories: [],
  published: false,
  is_finished: false,
  has_access: false,
  thumbnail: null,
  featured_index: null,
  created_at: new Date(),
  updated_at: new Date(),
  full_description: initialEditorValue,
  short_description: initialEditorValue,
  meta_description: '',
  first_meeting_duration: emptyPGTimeRange,
}

export const defaultSelectedColumns = [
  'thumbnail',
  'name',
  'hosts',
  'price',
  'duration',
  'meetings_left',
  'occupied_slots',
  'max_members',
  'min_members',
  'published',
  'categories',
  'featured_index',
]

export const columns: Column<SupportGroupJoined>[] = [
  {
    Header: 'Miniaturka',
    id: 'thumbnail',
    sortable: false,
    renderAs: 'avatar',
  },
  {
    Header: 'ID',
    id: 'id',
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Cena (PLN)',
    id: 'price',
    renderAs: 'monetary',
  },
  {
    Header: 'Pozostałe spotkania',
    id: 'meetings_left',
  },
  {
    Header: 'Indeks wyróżnienia',
    id: 'featured_index',
  },
  {
    Header: 'Zajęte miejsca',
    id: 'occupied_slots',
  },
  {
    Header: 'Max członków',
    id: 'max_members',
  },
  {
    Header: 'Min członków',
    id: 'min_members',
  },
  {
    Header: 'Prowadzący',
    id: 'hosts',
    renderer: ({hosts}) => (
      <Wrap w="200px">
        {hosts?.map((h, i) => (
          <WrapItem key={i}>
            <Tag>{h.email}</Tag>
          </WrapItem>
        ))}
      </Wrap>
    ),
  },
  {
    Header: 'Kategorie',
    id: 'categories',
    renderAs: 'tags',
  },
  {
    Header: 'Opublikowana?',
    id: 'published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Zakończona?',
    id: 'is_finished',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Czas pierwszego spotkania',
    id: 'first_meeting_duration',
    renderAs: 'timeSlot',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Zaktualizowano',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'number',
  },
  {
    label: 'Miniaturka',
    name: 'thumbnail',
    variant: 'exists',
  },
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Prowadzący',
    name: 'hosts',
    variant: 'list',
  },
  {
    label: 'Min członków',
    name: 'min_members',
    variant: 'number',
  },
  {
    label: 'Max członków',
    name: 'max_members',
    variant: 'number',
  },
  {
    label: 'Liczba spotkań',
    name: 'meetings_left',
    variant: 'number',
  },
  {
    label: 'Cena (PLN)',
    name: 'price',
    variant: 'monetary',
  },
  {
    label: 'Indeks wyróżnienia',
    name: 'featured_index',
    variant: 'number',
  },
  {
    label: 'Opublikowana?',
    name: 'published',
    variant: 'boolean',
  },
  {
    label: 'Zakończona?',
    name: 'is_finished',
    variant: 'boolean',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Zaktualizowano',
    name: 'updated_at',
    variant: 'datetime',
  },
]
