import * as React from 'react'

import {Box, Center, Flex, Grid, GridItem, HStack, Stack, Text, useBreakpointValue} from '@chakra-ui/react'
import {CarouselProvider, Dot, Slide, Slider} from 'pure-react-carousel'
import {Link as RouterLink} from 'react-router-dom'

import {SupportGroupJoined} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import LoadingListItem from '@/common/components/loading-list-item'
import {SUPPORT_GROUPS_LIST} from '@/router/paths'

import {SupportGroupItem} from './list/item'

type Props = {
  items: SupportGroupJoined[]
  count: number
  loading?: boolean
}

const RecommendedSupportGroups = ({items, count, loading}: Props) => {
  const variant = useBreakpointValue({
    base: 'slider',
    lg: 'stack',
  })
  const visibleSlides = useBreakpointValue({
    base: 1,
    md: 2,
  })

  return (
    <Stack spacing={8}>
      {variant === 'slider' ? (
        <CarouselProvider
          naturalSlideWidth={500}
          naturalSlideHeight={400}
          totalSlides={count}
          visibleSlides={visibleSlides}
          infinite={true}
          isIntrinsicHeight={true}
        >
          <Slider>
            {items.map((sg, i) => (
              <Slide index={i} key={i}>
                <Box px={4}>
                  <SupportGroupItem item={sg} direction="column" />
                </Box>
              </Slide>
            ))}
          </Slider>
          <Center pt={8}>
            <HStack spacing={6}>
              {items.map((_, i) => (
                <Box
                  as={Dot}
                  key={i}
                  slide={i}
                  borderRadius="full"
                  boxSize="14px"
                  bg="brand.gray.200"
                  transition="all 200ms ease"
                  sx={{
                    '&.carousel__dot--selected': {
                      bg: 'brand.green.800',
                    },
                  }}
                />
              ))}
            </HStack>
          </Center>
        </CarouselProvider>
      ) : (
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)', 'repeat(4, 1fr)']}
          gap={[8, null, null, 16]}
          mb={8}
          width="100%"
        >
          {loading
            ? Array.from(Array(count), (_, i) => (
                <GridItem key={i}>
                  <LoadingListItem direction="column" />
                </GridItem>
              ))
            : items?.map((sg, i) => (
                <GridItem key={i}>
                  <SupportGroupItem item={sg} direction="column" />
                </GridItem>
              ))}
        </Grid>
      )}
      <Flex justify="center">
        <ArrowButton as={RouterLink} to={SUPPORT_GROUPS_LIST} height={48}>
          <Text px={8} fontSize="lg">
            Zobacz wszystkie
          </Text>
        </ArrowButton>
      </Flex>
    </Stack>
  )
}

export default RecommendedSupportGroups
