import React from 'react'

import {Text, Heading, Flex, Box, Grid, GridItem, Container, Stack} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {CONTACT_US} from '@/router/paths'

import QuestionsAndAnswers from './questions-and-answers/viewer'

const FAQViewer = () => {
  return (
    <>
      <Box backgroundColor="brand.yellow.300" py={16}>
        <Container maxW="container.xl">
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem colSpan={[12, null, 4]} w="100%">
              <Box display="flex" flexDirection="row" justifyContent="flex-start">
                <Box flexGrow={1} display={['none', null, 'block']} />
                <Heading as="h1" fontSize={28} fontWeight={500}>
                  Najczęstsze pytania
                </Heading>
              </Box>
            </GridItem>
            <GridItem colSpan={[12, null, 6]} w="100%">
              <Text fontWeight={300} fontSize={16} lineHeight="21px">
                Tutaj znajdziesz odpowiedzi na pytania, które najczęściej zadają nam użytkownicy. Mamy
                nadzieję, że dzięki nim lepiej poznasz naszą platformę.
              </Text>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <Container maxW="container.xl" my={16}>
        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={10} colStart={2}>
            <QuestionsAndAnswers id="faq" />
          </GridItem>
        </Grid>
      </Container>
      <Box backgroundColor="brand.green.800" py={20} color="white">
        <Container maxW="container.xl">
          <Grid templateColumns="repeat(12, 1fr)">
            <GridItem
              colSpan={[12, null, 5]}
              colStart={[0, null, 2]}
              as={Stack}
              direction="column"
              spacing={8}
            >
              <Heading size="lg" fontWeight={500}>
                Chcesz wiedzieć więcej?
              </Heading>
              <Text fontWeight={300} pb={[8, null, 0]}>
                Masz pytanie, związane z platformą? Potrzebujesz pomocy w doborze specjalisty? A może chcesz
                się podzielić wskazówką na temat obsługi platformy?
              </Text>
            </GridItem>
            <GridItem
              colSpan={[12, null, 4]}
              colStart={[0, null, 8]}
              as={Flex}
              direction="column"
              justifyContent="center"
              alignItems={['center', null, 'stretch']}
            >
              <ArrowButton
                as={Link}
                to={CONTACT_US}
                bg="brand.yellow.600"
                hoverBg="brand.yellow.700"
                color="white"
                height={55}
                fontSize="lg"
                fontWeight={500}
              >
                Daj nam znać
              </ArrowButton>
            </GridItem>
          </Grid>
        </Container>
      </Box>
    </>
  )
}

export default FAQViewer
