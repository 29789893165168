import * as React from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {Payment, SupabaseRPCResult} from '@/api/models'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

const PaymentRefundModal = ({onClose, open, item, onComplete}: CommonModalProps & {item: Payment | null}) => {
  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      if (!item?.id) return

      const {error, data} = await supabase.rpc('refund_payment', {
        payment_id: item.id,
      })
      if (error) throw error
      const res = data as any as SupabaseRPCResult
      if ('error' in res) throw new Error(res.error)

      onComplete && onComplete()
      onClose()
    }, [item, onClose, onComplete]),
    {
      onSuccessToast: 'Zwrot przyjęty do realizacji',
      onErrorToast: 'Nie udało się zwrócić płatności',
    }
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Zwróć płatność</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {item?.status === 'refunded'
            ? 'Płatność została już zwrócona.'
            : item?.status !== 'completed'
            ? 'Płatność nie podlega zwrotowi, ponieważ nie została zrealizowana.'
            : 'Czy na pewno chcesz dokonać zwrotu tej płatności?'}
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit} isDisabled={item?.status !== 'completed'}>
              Zwróć
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default PaymentRefundModal
