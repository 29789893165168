import * as React from 'react'

import {Redirect, Route, Switch} from 'react-router-dom'

import {ADMIN_PREFIX} from '@/router/paths'
import GoogleCalendarAuthCallback from '@/therapist-panel/calendar/callback'

export const ADMIN_USERS = `${ADMIN_PREFIX}/users`
export const ADMIN_COURSES = `${ADMIN_PREFIX}/courses`
export const ADMIN_COURSE_CATEGORIES = `${ADMIN_PREFIX}/course-categories`
export const ADMIN_THERAPISTS = `${ADMIN_PREFIX}/therapists`
export const ADMIN_THERAPIST_SPECIALTIES = `${ADMIN_PREFIX}/therapist-specialties`
export const ADMIN_THERAPIST_SERVICES = `${ADMIN_PREFIX}/therapist-services`
export const ADMIN_THERAPIST_REVIEWS = `${ADMIN_PREFIX}/therapist-reviews`
export const ADMIN_INVOICES = `${ADMIN_PREFIX}/invoices`
export const ADMIN_BLOG_POSTS = `${ADMIN_PREFIX}/blog-posts`
export const ADMIN_WEBINARS = `${ADMIN_PREFIX}/webinars`
export const ADMIN_WEBINAR_REVIEWS = `${ADMIN_PREFIX}/webinar-reviews`
export const ADMIN_SUPPORT_GROUPS = `${ADMIN_PREFIX}/support-groups`
export const ADMIN_SUPPORT_GROUP_CATEGORIES = `${ADMIN_PREFIX}/support-group-categories`
export const ADMIN_SUPPORT_GROUP_REVIEWS = `${ADMIN_PREFIX}/support-group-reviews`
export const ADMIN_CALENDAR = `${ADMIN_PREFIX}/google`
export const ADMIN_CALENDAR_GOOGLE_CALLBACK = `${ADMIN_PREFIX}/google/callback`
export const ADMIN_TSHIRT_SIZES = `${ADMIN_PREFIX}/tshirt-sizes`
export const ADMIN_FAQ = `${ADMIN_PREFIX}/faq`
export const ADMIN_COURSE_REVIEWS = `${ADMIN_PREFIX}/course-reviews`
export const ADMIN_FRONT_PAGE_LOGOS = `${ADMIN_PREFIX}/front-page-logos`
export const ADMIN_DISCOUNT_CODES = `${ADMIN_PREFIX}/discount-codes`
export const ADMIN_ORDERS = `${ADMIN_PREFIX}/orders`
export const ADMIN_ORDER_ITEMS = `${ADMIN_PREFIX}/order-items`
export const ADMIN_MEETINGS = `${ADMIN_PREFIX}/meetings`
export const ADMIN_PAYMENTS = `${ADMIN_PREFIX}/payments`
export const ADMIN_PAYMENT_REFUNDS = `${ADMIN_PREFIX}/payment-refunds`

export default () => {
  return (
    <Switch>
      <Route path={ADMIN_USERS} exact={true} component={require('@/admin/users').default} />
      <Route path={ADMIN_COURSES} exact={true} component={require('@/admin/courses').default} />
      <Route
        path={ADMIN_COURSE_CATEGORIES}
        exact={true}
        component={require('@/admin/course-categories').default}
      />
      <Route path={ADMIN_THERAPISTS} exact={true} component={require('@/admin/therapists').default} />
      <Route
        path={ADMIN_THERAPIST_SPECIALTIES}
        exact={true}
        component={require('@/admin/therapist-specialties').default}
      />
      <Route
        path={ADMIN_THERAPIST_SERVICES}
        exact={true}
        component={require('@/admin/therapist-services').default}
      />
      <Route
        path={ADMIN_THERAPIST_REVIEWS}
        exact={true}
        component={require('@/admin/therapist-reviews').default}
      />
      <Route path={ADMIN_MEETINGS} exact={true} component={require('@/admin/meetings').default} />
      <Route path={ADMIN_INVOICES} exact={true} component={require('@/admin/invoices').default} />
      <Route path={ADMIN_BLOG_POSTS} exact={true} component={require('@/admin/blog-posts').default} />
      <Route path={ADMIN_WEBINARS} exact={true} component={require('@/admin/webinars').default} />
      <Route
        path={ADMIN_WEBINAR_REVIEWS}
        exact={true}
        component={require('@/admin/webinar-reviews').default}
      />
      <Route path={ADMIN_SUPPORT_GROUPS} exact={true} component={require('@/admin/support-groups').default} />
      <Route
        path={ADMIN_SUPPORT_GROUP_CATEGORIES}
        exact={true}
        component={require('@/admin/support-group-categories').default}
      />
      <Route
        path={ADMIN_SUPPORT_GROUP_REVIEWS}
        exact={true}
        component={require('@/admin/support-group-reviews').default}
      />
      <Route path={ADMIN_CALENDAR} exact={true} component={require('@/admin/google').default} />
      <Route
        path={ADMIN_CALENDAR_GOOGLE_CALLBACK}
        exact={true}
        render={(props) => <GoogleCalendarAuthCallback calendarType="admin" {...props} />}
      />
      <Route path={ADMIN_TSHIRT_SIZES} exact={true} component={require('@/admin/tshirt-sizes').default} />
      <Route
        path={ADMIN_FAQ}
        exact={true}
        component={require('@/admin/questions-and-answers').FrequentlyAskedQuestions}
      />
      <Route path={ADMIN_COURSE_REVIEWS} exact={true} component={require('@/admin/course-reviews').default} />
      <Route
        path={ADMIN_FRONT_PAGE_LOGOS}
        exact={true}
        component={require('@/admin/front-page-logos').default}
      />
      <Route path={ADMIN_DISCOUNT_CODES} exact={true} component={require('@/admin/discount-codes').default} />
      <Route path={ADMIN_ORDERS} exact={true} component={require('@/admin/orders').default} />
      <Route path={ADMIN_ORDER_ITEMS} exact={true} component={require('@/admin/order-items').default} />
      <Route path={ADMIN_PAYMENTS} exact={true} component={require('@/admin/payments').default} />
      <Route
        path={ADMIN_PAYMENT_REFUNDS}
        exact={true}
        component={require('@/admin/payment-refunds').default}
      />
      <Redirect to={ADMIN_COURSES} />
    </Switch>
  )
}
