import * as React from 'react'

import {Box, Container, Text, useToken} from '@chakra-ui/react'
import {Parallax} from 'react-scroll-parallax'

import {SupportGroupJoined, parseSupportGroupJoined} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'
import RecommendedSupportGroups from '@/psychotherapy/support-groups/recommended'

const count = 4

const SupportGroups = () => {
  const green600 = useToken('colors', ['brand.green.600'])

  const {loading, data} = useSupabaseQuery<SupportGroupJoined>({
    table: 'support_groups_view',
    fields: '*',
    itemsPerPage: count,
    order: 'featured_index', // TODO: fetch random rows
    parsingFunction: parseSupportGroupJoined,
  })

  return !data?.length && !loading ? null : (
    <>
      <Container maxW="container.xl" px={[8, null, 4]}>
        <Text fontSize="3xl" fontWeight={500} mt={24} mb={12}>
          Grupy wsparcia
        </Text>
      </Container>
      <Box position="relative">
        <Box left="0" top="5%" width="100%" height="100%" position="absolute" zIndex={-1} overflow="hidden">
          <Parallax
            style={{
              backgroundColor: green600,
              height: '25%',
            }}
            translateY={[0, 20]}
          />
        </Box>

        <Container maxW="container.xl">
          <RecommendedSupportGroups items={data ?? []} count={count} loading={loading} />
        </Container>
      </Box>
    </>
  )
}

export default SupportGroups
