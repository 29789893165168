import {AdminMeeting} from '@/api/models'

export const inputToUpsertArgs = (i: AdminMeeting) => ({
  meeting_id: i.id || undefined,
  host_id: i.host_id,
  service: i.service_id,
  starts_at: i.starts_at,
  guests: i.guests_ids,
  name: i.name,
  description: i.description,
})
