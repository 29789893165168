import React from 'react'

import {AdminInvoice} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import useSupabaseQuery from '@/common/use-custom-query'

import {columns, filterFields} from './constants'

const Invoices = () => {
  const tableState = useTableState({sortBy: 'created_at', descending: true})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminInvoice>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminInvoice,
    pageNumber: tableState.page,
    table: 'admin_invoices',
  })

  return (
    <DataTable<AdminInvoice>
      table="admin_invoices"
      columns={columns}
      data={data ?? []}
      totalCount={rows ?? 0}
      tableState={tableState}
      onRefresh={fetch}
      filterFields={filterFields}
      loading={loading}
    />
  )
}

export default Invoices
