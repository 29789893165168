import {SupportGroupJoined} from '@/api/models'

export const inputToUpsertArgs = (i: SupportGroupJoined) => ({
  support_group_id: i.id || undefined,
  name: i.name,
  price: i.price || 0,
  max_members: i.max_members,
  min_members: i.min_members,
  published: i.published,
  is_finished: i.is_finished,
  categories: i.categories || [],
  full_description: i.full_description,
  short_description: i.short_description,
  meta_description: i.meta_description,
  featured_index: i.featured_index || undefined,
  // thumbnail upload is handled by `useUpsert` hook
})
