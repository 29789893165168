import {AdminTherapist} from '@/api/models'

export const inputToUpsertArgs = (input: AdminTherapist) => ({
  id: input.id,
  full_name: input.full_name,
  user: input.user,
  avatar: input.avatar,
  featured_index: input.featured_index,
  full_description: input.full_description,
  gender: input.gender,
  meta_description: input.meta_description,
  public: input.public,
  short_description: input.short_description,
  years_of_exp: input.years_of_exp ?? 0,
})
