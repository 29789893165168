import * as React from 'react'

import {
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import {ModalState, selectModalState, setModalState} from '@/auth/state'
import Logo from '@/common/logo'
import {useAppDispatch, useAppSelector} from '@/store'

import ConfirmEmail from '../confirm-email'
import ResetPassword from '../reset-password/form'
import SignUpForm from '../signup/form'
import SignInForm from './form'

const SignInModal = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(selectModalState)
  const setState = React.useCallback(
    (state?: ModalState) => {
      dispatch(
        setModalState({
          redirectPath: window.location.pathname,
          state,
        })
      )
    },
    [dispatch]
  )

  const [opacity, setOpacity] = React.useState<number>(1)
  const handleSignIn = React.useCallback(() => {
    setOpacity(0)
    setTimeout(() => setState('signin'), 200)
    setTimeout(() => setOpacity(1), 400)
  }, [setOpacity, setState])
  const handleSignUp = React.useCallback(() => {
    setOpacity(0)
    setTimeout(() => setState('signup'), 200)
    setTimeout(() => setOpacity(1), 400)
  }, [setOpacity, setState])
  const handleResetPassword = React.useCallback(() => {
    setOpacity(0)
    setTimeout(() => setState('reset-password'), 200)
    setTimeout(() => setOpacity(1), 400)
  }, [setOpacity, setState])
  const handleConfirmEmail = React.useCallback(() => {
    setOpacity(0)
    setTimeout(() => setState('confirm-email'), 200)
    setTimeout(() => setOpacity(1), 400)
  }, [setOpacity, setState])
  const handleClose = React.useCallback(() => {
    setState(undefined)
  }, [setState])

  const containerWidth = React.useMemo(() => (state === 'signup' ? 'container.lg' : 'container.sm'), [state])

  return (
    <Modal isOpen={!!state} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent px={[2, null, 8]} py={6}>
        <ModalHeader>
          <Logo />
        </ModalHeader>
        <ModalCloseButton top={12} right={[8, null, 12]} boxSize={12} fontSize="30px" rounded="full" />
        <ModalBody mb={8}>
          <Container maxW={containerWidth} px="0">
            {state === 'signin' && (
              <SignInForm
                opacity={opacity}
                transitionDuration="var(--chakra-transition-duration-normal)"
                transitionProperty="opacity"
                switchToSignUp={handleSignUp}
                switchToResetPassword={handleResetPassword}
                onComplete={handleClose}
              />
            )}
            {state === 'signup' && (
              <SignUpForm
                opacity={opacity}
                transitionDuration="var(--chakra-transition-duration-normal)"
                transitionProperty="opacity"
                switchToSignIn={handleSignIn}
                switchToConfirmEmail={handleConfirmEmail}
                onClose={handleClose}
              />
            )}
            {state === 'reset-password' && (
              <ResetPassword
                opacity={opacity}
                transitionDuration="var(--chakra-transition-duration-normal)"
                transitionProperty="opacity"
              />
            )}
            {state === 'confirm-email' && (
              <ConfirmEmail
                opacity={opacity}
                transitionDuration="var(--chakra-transition-duration-normal)"
                transitionProperty="opacity"
                onClose={handleClose}
              />
            )}
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SignInModal
