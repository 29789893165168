import React from 'react'

import {EditIcon, DeleteIcon, CloseIcon, CheckIcon} from '@chakra-ui/icons'
import {Box, Flex, HStack, IconButton, Input, InputProps, Text, useColorMode} from '@chakra-ui/react'
import {pl} from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import useLoadingState from '@/common/use-loading-state'
import {formatTimestamp, polishTimestampFormat} from '@/utils/string'

const DefaultDatePickerInput = React.forwardRef(
  (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <Input size="sm" ref={ref} {...(props as InputProps)} />
  )
)

const defaultInput = <DefaultDatePickerInput />

const EditableDatetime = ({
  initialValue,
  onSave,
  placeholder,
  editable,
  customInput,
  textColor: textColorFunction,
  showTimeSelect,
}: {
  initialValue: Date | null
  onSave: (value: Date | null) => Promise<void>
  editable?: boolean
  customInput?: React.ReactNode
  placeholder?: string
  textColor?: (value: Date | null, colorMode: 'light' | 'dark') => string
  showTimeSelect?: boolean
}) => {
  const {colorMode} = useColorMode()

  const [isEditing, setIsEditing] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(initialValue)

  const displayValue = React.useMemo(
    () => (selectedDate ? formatTimestamp(selectedDate) : placeholder || 'Nie wybrano daty...'),
    [selectedDate, placeholder]
  )

  const cancelEditing = React.useCallback(() => {
    setSelectedDate(initialValue)
    setIsEditing(false)
  }, [initialValue])

  React.useEffect(() => {
    if (editable) {
      return
    }
    cancelEditing()
  }, [cancelEditing, editable])

  const handleDateChange = React.useCallback(
    (date: Date | null) => {
      setSelectedDate(date)
    },
    [setSelectedDate]
  )

  const handleEdit = React.useCallback(() => {
    setIsEditing(true)
  }, [])

  const handleSave = React.useCallback(
    async (valueOverride?: Date | null) => {
      const savingValue = valueOverride === undefined ? selectedDate : valueOverride
      await onSave(savingValue)
      setIsEditing(false)
    },
    [onSave, selectedDate]
  )

  const handleSaveDefault = React.useCallback(async () => await handleSave(), [handleSave])

  const {handleSubmit, loading: isSaving} = useLoadingState(handleSaveDefault)

  const removeValue = React.useCallback(async () => {
    setSelectedDate(null)
    await handleSave(null)
  }, [handleSave])

  const {handleSubmit: handleRemoveValue, loading: isRemoving} = useLoadingState(removeValue)

  const textColor = React.useMemo(
    () => textColorFunction && textColorFunction(selectedDate, colorMode),
    [colorMode, selectedDate, textColorFunction]
  )

  return isEditing ? (
    <Flex>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat={polishTimestampFormat}
        customInput={customInput || defaultInput}
        showTimeSelect={showTimeSelect}
        disabled={isRemoving || isSaving}
        locale={pl}
        timeCaption="Czas"
      />
      <IconButton
        size="sm"
        aria-label=""
        onClick={handleSubmit}
        isLoading={isSaving}
        isDisabled={isRemoving || isSaving}
        w="45px"
        ml="1"
        colorScheme="green"
        icon={<CheckIcon />}
      />
      <IconButton
        size="sm"
        aria-label=""
        onClick={handleRemoveValue}
        isLoading={isRemoving}
        isDisabled={isRemoving || isSaving}
        w="45px"
        ml="1"
        colorScheme="red"
        icon={<DeleteIcon />}
      />
      <IconButton
        size="sm"
        aria-label=""
        onClick={cancelEditing}
        isDisabled={isRemoving || isSaving}
        w="45px"
        ml="1"
        icon={<CloseIcon />}
      />
    </Flex>
  ) : (
    <HStack>
      <Text fontSize="sm" fontWeight="semibold" color={textColor}>
        {displayValue}
      </Text>
      {editable && (
        <Box cursor="pointer" onClick={handleEdit}>
          <EditIcon size="1rem" />
        </Box>
      )}
    </HStack>
  )
}

export default EditableDatetime
