// groups meetings into joinable and planned
export const groupMeetings = <T extends {joinable_from: Date; joinable_to: Date}>(data?: T[] | null) =>
  data?.reduce(
    (acc, v) => {
      const now = new Date()
      return new Date(v.joinable_from) < now && now < new Date(v.joinable_to)
        ? {...acc, joinable: [...acc.joinable, v]}
        : {...acc, planned: [...acc.planned, v]}
    },
    {joinable: [] as T[], planned: [] as T[]}
  ) ?? {joinable: [], planned: []}
