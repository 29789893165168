import {PayloadAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {User} from '@supabase/supabase-js'

import {supabase} from '@/api'
import {UserBillingAddress, UserProfile} from '@/api/models'
import {RootState} from '@/store'

export type ModalState = 'reset-password' | 'signin' | 'signup' | 'confirm-email'

export interface authState {
  user?: User
  profile?: UserProfile
  profileLoading: boolean

  billingInfoLoading?: boolean
  billingInfo?: UserBillingAddress[]

  modalState?: ModalState
  redirectPath?: string
}

const initialState: authState = {
  billingInfoLoading: true,
  profileLoading: true,
  user: undefined,
}

export const fetchUserProfile = createAsyncThunk('auth/fetchUserProfile', async (_, {dispatch}) => {
  const {data, error} = await supabase.from('user_profile').select('*').single()
  if (error) throw error
  if (!data) throw new Error("Failed to retrieve the currently logged in user's profile")

  dispatch(fetchBillingInfo())

  return data
})

export const fetchBillingInfo = createAsyncThunk('auth/fetchBillingInfo', async () => {
  const {data, error} = await supabase.from('my_billing_address').select('*')
  if (error) throw error

  return data
})

export const updateUser = createAsyncThunk('auth/updateUser', async (user: User | undefined, {dispatch}) => {
  if (!user) throw new Error('no user')

  dispatch(setUser(user))
  dispatch(fetchUserProfile())
})

type SetModalStateArgs = {
  state?: ModalState
  redirectPath?: string
}

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    setBillingInfoLoading: (state, action: PayloadAction<boolean>) => {
      state.billingInfoLoading = action.payload
    },
    setModalState: (state, action: PayloadAction<SetModalStateArgs>) => {
      state.modalState = action.payload.state
      state.redirectPath = action.payload.redirectPath
    },
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.rejected, (state) => {
        state.user = undefined
        state.profile = undefined
        state.profileLoading = false
      })
      // User profile
      .addCase(fetchUserProfile.pending, (state) => {
        state.profileLoading = true
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.profile = action.payload ?? undefined
        state.profileLoading = false
      })
      .addCase(fetchUserProfile.rejected, (state, error) => {
        state.profile = undefined
        state.profileLoading = false
      })
      // Billing info
      .addCase(fetchBillingInfo.pending, (state) => {
        state.billingInfoLoading = true
      })
      .addCase(fetchBillingInfo.fulfilled, (state, action) => {
        state.billingInfoLoading = false
        state.billingInfo = action.payload ?? undefined
      })
      .addCase(fetchBillingInfo.rejected, (state) => {
        state.billingInfoLoading = false
        state.billingInfo = undefined
      })
  },
})

export const {setModalState, setUser, setBillingInfoLoading} = authSlice.actions

export const selectModalState = (state: RootState) => state.auth.modalState
export const selectRedirectPath = (state: RootState) => state.auth.redirectPath
export const selectUser = (state: RootState) => state.auth.user
export const selectProfileLoading = (state: RootState) => state.auth.profileLoading
export const selectProfile = (state: RootState) => state.auth.profile
export const selectRole = (state: RootState) => state.auth.profile?.role ?? 'user'
export const selectIsAdmin = (state: RootState) => state.auth.profile?.role === 'admin'
export const selectBillingInfo = ({auth}: RootState) => auth.billingInfo

export default authSlice.reducer
