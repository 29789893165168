import * as React from 'react'

import {AspectRatio, Box, Button, Center, Flex, SkeletonText, Spinner, Stack, Text} from '@chakra-ui/react'

const LoadingListItem = ({direction = 'row'}: {direction?: 'row' | 'column'}) => (
  <Stack direction={['column', null, direction]} spacing={8} width="100%">
    <AspectRatio ratio={1} flex={1} roundedTopRight="60px" roundedBottomLeft="60px" overflow="hidden">
      <Center objectFit="cover" bg="gray.300">
        <Spinner size="xl" thickness="4px" />
      </Center>
    </AspectRatio>
    <Flex direction="column" flex={1}>
      <Text fontWeight={500} fontSize="xl" mb={2} noOfLines={2} height="60px">
        <SkeletonText noOfLines={2} />
      </Text>
      <Box fontWeight={300} fontSize="md" flex={1} mb={4}>
        <SkeletonText noOfLines={4} w="100%" h="100%" />
      </Box>

      <Flex direction="row" justifyContent="flex-end">
        <Button
          disabled={true}
          variant="link"
          color="black"
          pb={2}
          borderBottomWidth="1px"
          borderBottomColor="black"
          rounded="none"
          marginBottom="1px"
          _hover={{
            borderBottomWidth: '2px',
            fontWeight: 500,
            marginBottom: '0',
          }}
        >
          Zobacz więcej
        </Button>
      </Flex>
    </Flex>
  </Stack>
)

export default LoadingListItem
