import * as React from 'react'

import {TShirtSize} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, emptyTshirtSize, filterFields} from './constants'
import TshirtSizeEditorModal from './editor-modal'

const TshirtSizesAvailability = () => {
  const tableState = useTableState({sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<TShirtSize>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof TShirtSize,
    pageNumber: tableState.page,
    table: 'tshirt_sizes',
  })

  const {onClose: onDeleteClose, onOpen: onDeleteOpen, value: deleteValue} = useValueDisclosure<TShirtSize>()
  const {onClose: onEditorClose, onOpen: onEditorOpen, value: editorValue} = useValueDisclosure<TShirtSize>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyTshirtSize)
  }, [onEditorOpen])

  const actions = React.useMemo(
    () => [editAction(onEditorOpen), deleteAction(onDeleteOpen)],
    [onEditorOpen, onDeleteOpen]
  )

  return (
    <>
      <DataTable<TShirtSize>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        loading={loading}
        filterFields={filterFields}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <TshirtSizeEditorModal
        item={editorValue}
        onClose={onEditorClose}
        open={!!editorValue}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="tshirt_sizes"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default TshirtSizesAvailability
