import {createStandaloneToast} from '@chakra-ui/react'
import {Action, ThunkAction, combineReducers, configureStore} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import {supabase} from '@/api'
import authReducer, {updateUser} from '@/auth/state'
import reviewsReducer from '@/common/reviews/state'
import coursesReducer from '@/courses/state'
import privacyPolicyReducer from '@/privacy-policy/state'
import supportGroupsReducer from '@/psychotherapy/support-groups/state'
import psychotherapyReducer from '@/psychotherapy/therapists/state'

const rootReducer = combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      whitelist: ['profile', 'user'],
    },
    authReducer
  ),
  courses: coursesReducer,
  privacyPolicy: persistReducer(
    {
      key: 'privacyPolicy',
      storage,
    },
    privacyPolicyReducer
  ),
  psychotherapy: psychotherapyReducer,
  reviews: reviewsReducer,
  supportGroups: supportGroupsReducer,
})

export const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  reducer: rootReducer,
})

let rehydrationComplete: ((value?: any) => void) | undefined
const rehydrationPromise = new Promise((resolve) => {
  rehydrationComplete = resolve
})

export const rehydration = () => rehydrationPromise

export const persistor = persistStore(store, null, () => rehydrationComplete && rehydrationComplete())

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

const toast = createStandaloneToast()

// It's placed here in order to avoid a circular dependency between 'auth/state.tsx' and 'store/index.tsx'
supabase.auth.onAuthStateChange(async (event, session) => {
  const state = store.getState()
  if (event === 'SIGNED_OUT') store.dispatch(updateUser(undefined))

  if (event === 'SIGNED_IN' && !state.auth.user) {
    store.dispatch(updateUser(session?.user ?? undefined))
    toast({
      isClosable: true,
      status: 'success',
      title: 'Zalogowano pomyślnie',
    })
  }
})
;(async () => {
  await rehydration()
  const {
    data: {user: initialUser},
  } = await supabase.auth.getUser()
  store.dispatch(updateUser(initialUser ?? undefined))
})()
