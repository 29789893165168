import * as React from 'react'

import {useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {MeetingInvitation} from '@/api/models'

import {MeetingTokenResponse} from './types'

export const useMeetingToken = (id: string) => {
  const toast = useToast()

  const [data, setData] = React.useState<MeetingTokenResponse | undefined>()
  const [loading, setLoading] = React.useState(true)

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const {data: tokenData, error: tokenErr} = await supabase.rpc('create_meeting_token', {
        meeting_id: id,
      })
      if (tokenErr) throw tokenErr
      if (!tokenData) throw new Error('Failed to fetch token')

      setData(tokenData as any)
    } catch (e) {
      console.error('Failed to fetch token', e)
      toast({
        isClosable: true,
        title: 'Nie udało się uzyskać dostępu do spotkania',
        status: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [id, toast])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  return {data, fetch, loading}
}

export const useMeetingInvitations = (id: string) => {
  const [data, setData] = React.useState<MeetingInvitation[]>([])
  const [loading, setLoading] = React.useState(true)

  const fetch = React.useCallback(async () => {
    setLoading(true)

    try {
      const {data, error} = await supabase.rpc('get_meeting_invitations', {
        meeting_id: id,
      })
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('Failed to fetch invitations')
      }
      setData(data as any)
    } catch (e) {
      console.error('Failed to fetch invitations', e)
    } finally {
      setLoading(false)
    }
  }, [id])

  React.useEffect(() => {
    fetch()
  }, [fetch])

  return {data, fetch, loading}
}
