import * as React from 'react'

import {Box, BoxProps, Stack, Text} from '@chakra-ui/react'

import {MySupportGroupMeeting} from '@/api/models'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import {formatMeetingDuration} from '@/utils/string'

type Props = BoxProps & {item: MySupportGroupMeeting; onReviewComplete?: () => void}

const PlannedSupportGroupMeeting = ({item, onReviewComplete, ...rest}: Props) => (
  <Box bg="brand.green.300" p={8} {...rest}>
    <Stack spacing={2} flex={1} justify="center" align="flex-start">
      <Text fontWeight={500}>{item.name}</Text>
      <Text fontWeight={300}>{formatMeetingDuration(item.starts_at, item.ends_at)}</Text>
      {onReviewComplete && (
        <ReviewModalButton
          type="support_group"
          withText={true}
          itemID={item.support_group}
          reviewed={item.reviewed}
          onComplete={onReviewComplete}
        />
      )}
    </Stack>
  </Box>
)

export default PlannedSupportGroupMeeting
