import * as React from 'react'

import {Box, Button, SkeletonCircle, Stack, Text} from '@chakra-ui/react'

import {ArrowIcon, ArrowIconButton} from '@/common/arrow-button'

export type PaginationOptions = {
  page: number
  countPerPage: number
  maxShownPages: number
  totalCount: number
}

export const usePagination = ({page, countPerPage, maxShownPages, totalCount}: PaginationOptions) => {
  const totalPages = React.useMemo(() => {
    return Math.ceil(totalCount / countPerPage)
  }, [totalCount, countPerPage])

  // items to render
  // eg. 6 items in total
  // first item is always 1
  // last item is always the last page
  // middle items either stick to the left or the right
  // after 1 and before last, we do an empty slot if it's needed
  const items = React.useMemo<Array<number | null>>(() => {
    if (totalPages <= maxShownPages) {
      return Array.from(Array(totalPages), (_, i) => i + 1)
    }

    // if the currently selected one is within the first pages, we cut off the end
    // eg. 1 2 3 4 5 . 10
    if (page <= maxShownPages - 3) {
      return [...Array.from(Array(maxShownPages - 3), (_, i) => i + 1), null, totalPages]
    }

    // if the currently selected one is within the last pages, we cut off the start
    // eg. 1 . 8 9 10 11 12
    if (page >= totalPages - maxShownPages + 3) {
      return [1, null, ...Array.from(Array(maxShownPages - 2), (_, i) => totalPages - maxShownPages + 3 + i)]
    }

    // otherwise we do first, gap, a range centered at the current page, last
    const itemsToGenerate = maxShownPages - 4 // minus first, dots, ..., dots, last
    const itemsAroundMiddle = itemsToGenerate - 1 // minus the middle one
    const itemsOnTheLeft = Math.floor(itemsAroundMiddle / 2) // prefer right, so floor
    const itemsStartPoint = page - itemsOnTheLeft
    return [1, null, ...Array.from(Array(itemsToGenerate), (_, i) => itemsStartPoint + i), null, totalPages]
  }, [totalPages, maxShownPages, page])

  return {
    items,
    totalPages,
  }
}

export const calculateRange = ({countPerPage, page}: {countPerPage: number; page: number}) => {
  return [(page - 1) * countPerPage, page * countPerPage - 1]
}

export type PaginationProps = {
  countPerPage: number
  loading?: boolean
  page: number
  setPage: (page: number) => void
  items: Array<number | null>
}

export const Pagination = ({countPerPage, loading, page, setPage, items}: PaginationProps) => {
  const handlePrevious = React.useCallback(() => {
    setPage(page - 1)
  }, [setPage, page])
  const handleNext = React.useCallback(() => {
    setPage(page + 1)
  }, [setPage, page])

  return !items.length ? null : (
    <Stack direction="row" spacing={[4, null, 8]} alignItems="center">
      <Button
        leftIcon={<ArrowIcon transform="rotate(180deg)" boxSize={8} color="brand.yellow.600" />}
        variant="link"
        color="black"
        onClick={handlePrevious}
        disabled={!!loading || page === items[0]}
        display={['none', null, 'flex']}
      >
        Poprzednia
      </Button>
      <ArrowIconButton
        aria-label="Poprzednia"
        display={['block', null, 'none']}
        style={{transform: 'rotate(180deg)'}}
        width="32px"
        color="brand.yellow.600"
        _hover={{color: 'brand.yellow.700'}}
        onClick={handlePrevious}
        disabled={!!loading || page === items[0]}
      />
      <Stack direction="row" spacing={4} alignItems="center">
        {loading
          ? Array.from(Array(countPerPage), (_, i) => (
              <Box key={i}>
                <SkeletonCircle size="2" />
              </Box>
            ))
          : items.map((item, i) =>
              item === null ? (
                <Text fontSize="xl" fontWeight={300} key={i}>
                  ...
                </Text>
              ) : (
                <Button
                  fontSize="xl"
                  fontWeight={page === item ? 500 : 300}
                  variant="link"
                  color="black"
                  minW="auto"
                  key={i}
                  onClick={() => setPage(item)}
                >
                  {item}
                </Button>
              )
            )}
      </Stack>
      <Button
        rightIcon={<ArrowIcon boxSize={8} color="brand.yellow.600" />}
        variant="link"
        color="black"
        onClick={handleNext}
        disabled={!!loading || page === items[items.length - 1]}
        display={['none', null, 'flex']}
      >
        Następna
      </Button>
      <ArrowIconButton
        aria-label="Następna"
        display={['block', null, 'none']}
        width="32px"
        color="brand.yellow.600"
        _hover={{color: 'brand.yellow.700'}}
        onClick={handleNext}
        disabled={!!loading || page === items[items.length - 1]}
      />
    </Stack>
  )
}
