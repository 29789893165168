import {AdminCourse} from '@/api/models'

export const inputToUpsertArgs = (i: AdminCourse, isNextLvlCourse: boolean, withMeetings: boolean) => ({
  id: i.id,
  name: i.name,
  is_new: i.is_new,
  benefits: i.benefits,
  published: i.published,
  booklet_path: i.booklet_path,
  covered_topics: i.covered_topics,
  short_description: i.short_description,
  full_description: i.full_description,
  meta_description: i.meta_description,
  sections_number_override: i.sections_number_override,
  logo: i.logo || null,
  order: i.order || null,
  base_price: i.base_price || null,
  price_with_meetings: withMeetings ? i.price_with_meetings : null,
  meetings_count: withMeetings ? i.meetings_count : null,
  release_at: i.release_at || null,
  promotional_video_url: i.promotional_video_url || null,
  nextlvl_id: isNextLvlCourse ? i.nextlvl_id ?? '' : null,
  nextlvl_url: isNextLvlCourse ? i.nextlvl_url ?? '' : null,
})
