import * as React from 'react'

import {Spinner, Stack, Text} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'

import {useRetryPayment} from './hooks'

type Props = {
  id?: string
}

const Pending = ({id}: Props) => {
  const retryPayment = useRetryPayment(id)

  return (
    <Stack align="center" justify="center" spacing={12}>
      <Spinner color="brand.green.600" thickness="16px" boxSize="240px" size="xl" />
      <Stack
        align="center"
        justify="center"
        spacing={0}
        color="brand.gray.600"
        fontSize={['20px', null, '28px']}
        fontWeight={700}
        textAlign="center"
      >
        <Text>Przetwarzamy Twoje zamówienie</Text>
        <Text>Czekamy na potwierdzenie Twojej płatności</Text>
      </Stack>
      {id && (
        <ArrowButton
          onClick={retryPayment}
          bg="brand.green.600"
          hoverBg="brand.green.700"
          color="white"
          height={40}
          fontSize="lg"
          fontWeight={500}
          width="320px"
        >
          Ponów płatność
        </ArrowButton>
      )}
    </Stack>
  )
}

export default Pending
