import * as React from 'react'

import {Stack, Text, Image} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {HOME} from '@/router/paths'
import {USER_WEBINARS} from '@/user/router'

import picture from './picture.svg'

const WebinarOrderFulfilled = () => (
  <Stack align="center" justify="center" spacing={12}>
    <Image src={picture} />
    <Stack
      align="center"
      justify="center"
      spacing={0}
      color="brand.gray.600"
      fontSize={['20px', null, '28px']}
      lineHeight="30px"
      fontWeight={700}
      textAlign="center"
    >
      <Text>Dziękujemy za dokonanie płatności</Text>
      <Text>Webinarium zostało wykupione</Text>
    </Stack>
    <Stack direction={['column', null, 'row']} spacing={8} align="center" justify="center">
      <ArrowButton
        as={Link}
        to={HOME}
        bg="brand.green.600"
        hoverBg="brand.green.700"
        color="white"
        height={40}
        fontSize="lg"
        fontWeight={500}
        width="320px"
        flipped={true}
      >
        Powrót na stronę główną
      </ArrowButton>
      <ArrowButton
        as={Link}
        to={USER_WEBINARS}
        bg="brand.yellow.600"
        hoverBg="brand.yellow.700"
        color="white"
        height={40}
        fontSize="lg"
        fontWeight={500}
        width="320px"
      >
        Przejdź do webinarów
      </ArrowButton>
    </Stack>
  </Stack>
)

export default WebinarOrderFulfilled
