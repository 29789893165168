import React from 'react'

import {
  Box,
  Button,
  AspectRatio,
  Grid,
  GridItem,
  Flex,
  Image,
  Text,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react'
import {Link as RouterLink, generatePath, useHistory} from 'react-router-dom'

import {PublicTherapist} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import TimeSlotSelector from '@/psychotherapy/therapists/selector'
import MobileSelector from '@/psychotherapy/therapists/selector/mobile'
import {PSYCHOTHERAPY_THERAPIST} from '@/router/paths'

type Props = {
  therapist: PublicTherapist
  service: string
}

const TherapistItem = ({therapist, service}: Props) => {
  const history = useHistory()

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'full',
  })

  const path = React.useMemo(
    () =>
      generatePath(PSYCHOTHERAPY_THERAPIST, {
        id: therapist.id,
        slug: therapist.full_name_slug,
      }),
    [therapist]
  )

  const handleClick = React.useCallback(
    (date: Date) => {
      const params = new URLSearchParams()
      params.set('service', service)
      date && params.set('date', date.getTime().toString())

      history.push(`${path}?${params.toString()}`)
    },
    [history, path, service]
  )

  if (!therapist.id) return null

  return (
    <Grid templateColumns={['repeat(1, 1fr)', null, 'repeat(12, 1fr)']} columnGap={8}>
      <GridItem colSpan={[1, null, 3]}>
        <Stack
          direction={['row', null, 'column']}
          spacing={4}
          alignItems={['center', null, 'flex-start']}
          mb={[8, null, 0]}
        >
          <Box width={['34%', null, '100%']}>
            <AspectRatio
              ratio={1}
              roundedTopLeft={['50px', null, '70px']}
              roundedBottomRight={['50px', null, '70px']}
              overflow="hidden"
            >
              {therapist.signed_avatar ? (
                <Image src={therapist.signed_avatar} w="100%" h="100%" objectFit="cover" />
              ) : (
                <Box h="100%" w="100%" objectFit="cover" bg="gray.600" />
              )}
            </AspectRatio>
          </Box>
          <Text as="h2" width={['66%', null, 'auto']} fontSize="xl" fontWeight={500}>
            {therapist.full_name}
          </Text>
          {!!therapist.specialties?.length && (
            <Text fontSize="sm" fontWeight={300} display={['none', null, 'flex']}>
              {therapist.specialties.join(' | ')}
            </Text>
          )}
          <Text noOfLines={3} display={['none', null, 'block']}>
            <ContentEditor
              id={`therapist-short-description-${therapist.id}`}
              variant="basic"
              value={therapist.short_description ?? initialEditorValue}
              readOnly={true}
            />
          </Text>
          <Flex direction="column" alignItems="flex-end" display={['none', null, 'flex']}>
            <Button
              as={RouterLink}
              to={path}
              variant="link"
              color="black"
              pb={2}
              borderBottomWidth="1px"
              borderBottomColor="black"
              rounded="none"
              marginBottom="1px"
              _hover={{
                borderBottomWidth: '2px',
                fontWeight: 500,
                marginBottom: '0',
              }}
            >
              Zobacz więcej
            </Button>
          </Flex>
        </Stack>
      </GridItem>
      {variant === 'full' && (
        <GridItem colSpan={9}>
          <TimeSlotSelector
            therapist={therapist.id}
            service={service}
            onClick={handleClick}
            expandable={true}
          />
        </GridItem>
      )}
      {variant === 'mobile' && (
        <GridItem colSpan={1} as={Stack} direction="column" spacing={8}>
          {!!therapist.specialties?.length && (
            <Text fontSize="sm" fontWeight={300}>
              {therapist.specialties.join(' | ')}
            </Text>
          )}
          <Flex>
            <Button
              as={RouterLink}
              to={path}
              variant="link"
              color="black"
              pb={2}
              borderBottomWidth="1px"
              borderBottomColor="black"
              rounded="none"
              marginBottom="1px"
              _hover={{
                borderBottomWidth: '2px',
                fontWeight: 500,
                marginBottom: '0',
              }}
            >
              Zobacz więcej
            </Button>
          </Flex>
          <MobileSelector therapist={therapist.id} service={service} onClick={handleClick} />
          <Flex direction="row" justifyContent="center">
            <ArrowButton
              as={RouterLink}
              to={path}
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={44}
              fontSize="md"
              fontWeight={500}
            >
              Więcej terminów
            </ArrowButton>
          </Flex>
        </GridItem>
      )}
    </Grid>
  )
}

export default TherapistItem
