import * as React from 'react'

import {Stack} from '@chakra-ui/react'

import FinishedCourses from './finished'
import UnfinishedCourses from './unfinished'

const Courses = () => {
  return (
    <Stack spacing={16} mb={4} px={8}>
      <UnfinishedCourses />
      <FinishedCourses />
    </Stack>
  )
}

export default Courses
