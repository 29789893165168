import * as React from 'react'

import {AspectRatio, Box, Button, Center, Image, Progress, Spinner, Stack, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {MyCourse} from '@/api/models'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import useSupabaseQuery from '@/common/use-custom-query'
import {PSYCHOTHERAPY_THERAPIST} from '@/router/paths'

import OpenCourseButton from './open-course-button'

const UnfinishedCourses = () => {
  const {data, loading, fetch} = useSupabaseQuery<MyCourse>(
    React.useMemo(
      () => ({
        table: 'my_courses',
        fields: '*',
        order: 'progress',
        descending: true,
        errSnackbarTitle: 'Nie udało się pobrać trwających warsztatów',
        filter: (b) => b.lt('progress', 100),
      }),
      []
    )
  )

  return (
    <Stack spacing={12}>
      <Text fontSize="lg" fontWeight={500}>
        Trwające warsztaty
      </Text>
      {loading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : data?.length ? (
        data?.map((course) => <UnfinishedCourse key={course.id} course={course} fetch={fetch} />)
      ) : (
        <Text color="brand.gray.500" fontWeight={300} textAlign="center">
          Brak trwających warsztatów
        </Text>
      )}
    </Stack>
  )
}

export const UnfinishedCourse = ({
  course,
  basic,
  fetch,
}: {
  course: MyCourse
  fetch?: () => Promise<void>
  basic?: boolean
}) => (
  <Stack
    direction={['column', null, 'row']}
    justifyContent="stretch"
    spacing={[4, null, 8]}
    alignItems="center"
  >
    {!basic && (
      <AspectRatio flexShrink={0} rounded="full" width={160} ratio={1} overflow="hidden">
        {course?.signed_logo ? (
          <Image rounded="full" objectFit="cover" src={course.signed_logo} />
        ) : (
          <Box w="100%" h="100%" bg="brand.gray.500" />
        )}
      </AspectRatio>
    )}
    <Stack flex={1} align="flex-start">
      <ReviewModalButton
        type="course"
        withText={true}
        itemID={course.id}
        reviewed={course.reviewed}
        onComplete={fetch}
      />
      <Text fontSize="lg" fontWeight={500} pb={[2, null, 0]}>
        {course.name}
      </Text>
      {!course.nextlvl_url && (
        <>
          <Text fontSize="md">
            Autorzy:{' '}
            {!course.nextlvl_url && course.coach ? (
              <Button
                variant="brandLink"
                as={Link}
                to={generatePath(PSYCHOTHERAPY_THERAPIST, {
                  id: course.coach.therapist_id,
                  slug: course.coach.full_name_slug,
                })}
              >
                {course.coach.full_name}
              </Button>
            ) : (
              'Brak'
            )}
          </Text>
          <Stack direction={['column-reverse', null, 'row']} align="center" spacing={[4, null, 6]}>
            <Text>Ukończono: {course.progress}%</Text>
            <Progress
              rounded="full"
              value={course.progress}
              width="270px"
              bg="brand.green.100"
              sx={{
                '& > div': {
                  bg: 'brand.green.600',
                },
              }}
            />
          </Stack>
        </>
      )}
    </Stack>
    <OpenCourseButton id={course.id} nextlvlURL={course.nextlvl_url} />
  </Stack>
)

export default UnfinishedCourses
