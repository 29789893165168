import React from 'react'

import {useToast} from '@chakra-ui/toast'
import {TNode} from '@udecode/plate-core'
import randomstring from 'randomstring'

import {supabase} from '@/api'
import LabeledSpinner from '@/common/components/labeled-spinner'
import ContentEditor from '@/common/content-editor'
import {EDITOR_BLOCK_ID_LENGTH, initialValue} from '@/common/content-editor/constants'
import {UpdateResult} from '@/common/content-editor/types'
import {toPayload} from '@/common/content-editor/utils'
import {SUPABASE_COURSE_CONTENT_BUCKET} from '@/constants'

type Props = {
  courseID: number
  pageID: string
  readOnly: boolean
}

const CourseEditor = ({courseID, pageID, readOnly}: Props) => {
  const toast = useToast()

  const [value, setValue] = React.useState<TNode[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const {data, error} = await supabase.rpc('get_course_blocks', {
          course_id: courseID,
          page_id: pageID,
        })
        if (error) {
          throw error?.message || error
        }
        const result = data as TNode[]
        let value
        if (!result || result.length === 0) {
          value = initialValue
        } else {
          value = result
        }
        setValue(value.map((v) => ({...v, id: v.id || randomstring.generate(EDITOR_BLOCK_ID_LENGTH)})))
      } catch (e) {
        console.error('Failed to fetch course contents', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać zawartości strony.',
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [courseID, pageID, setIsLoading, setValue, toast])

  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const onSave = React.useCallback(async () => {
    try {
      setIsSaving(true)

      const {data, error} = await supabase.rpc('update_course_blocks', {
        course_id: courseID,
        page_id: pageID,
        payload: toPayload(value),
      })
      if (error) {
        throw error?.message || error
      }
      if (!data) {
        throw new Error(`Invalid saving result: ${data}`)
      }
      const result = data as any as UpdateResult
      if (result.error) {
        throw result.error
      }

      toast({
        isClosable: true,
        status: 'success',
        title: 'Zapisano zawartość strony.',
      })
    } catch (e) {
      console.error('Failed to save course page contents', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się zapisać zawartości strony.',
      })
    } finally {
      setIsSaving(false)
    }
  }, [courseID, pageID, value, setIsSaving, toast])

  if (isLoading) {
    return <LabeledSpinner label="Ładowanie strony..." />
  }

  return (
    <ContentEditor
      id="course-viewer-content-editor"
      bucketName={SUPABASE_COURSE_CONTENT_BUCKET}
      bucketScope={courseID}
      value={value}
      onChange={setValue}
      readOnly={readOnly}
      isSaving={isSaving}
      onSave={onSave}
      surveys={true}
      variant="full"
      toolbarPosition="navbar"
    />
  )
}

export default CourseEditor
