import React from 'react'

import {Stack, Text, useDisclosure} from '@chakra-ui/react'
import {generatePath, useHistory} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {OrderModal} from '@/common/order'
import {COURSE_PAGE} from '@/router/paths'

type Props = {
  id: number
  nextlvlURL?: string | null
}

const OpenCourseButton = ({id, nextlvlURL}: Props) => {
  const {push} = useHistory()
  const [seenNextlvlHelp, setSeenNextlvlHelp] = React.useState(true)

  const {isOpen, onOpen, onClose} = useDisclosure() // nextlvl help modal

  React.useEffect(() => {
    setSeenNextlvlHelp(localStorage.getItem('seen_nextlvl_help') === 'true')
  }, [])

  const handleOpenCourse = React.useCallback(() => {
    if (isOpen) {
      setSeenNextlvlHelp(true)
      localStorage.setItem('seen_nextlvl_help', 'true')
    }

    nextlvlURL
      ? isOpen || seenNextlvlHelp
        ? window.open(nextlvlURL, '_blank')
        : onOpen()
      : push(generatePath(COURSE_PAGE, {course: id}))
  }, [id, nextlvlURL, seenNextlvlHelp, isOpen, onOpen, push])

  return (
    <>
      <ArrowButton onClick={handleOpenCourse} height={40}>
        Przejdź do warsztatu
      </ArrowButton>

      <OrderModal open={isOpen} onClose={onClose}>
        <Stack spacing={8} align="center">
          <Stack align="center" spacing={4} textAlign="center" fontSize="xl">
            <Text fontSize="2xl" fontWeight={500}>
              Zapisałeś się na szkolenie YouDynamics
            </Text>
            <Text>
              Masz już dostęp do szkolenia YouDynamics, teraz przez 12 miesięcy możesz rozwijać się razem z
              nami.
            </Text>
            <Text>
              <b>Na Twoje konto email została wysłana wiadomość z platformy szkoleniowej nextlvl.se.</b>
              <br />
              Otwórz tę wiadomość i postępuj zgodnie z zawartymi tam informacjami na temat logowania.
            </Text>
            <Text>
              W zależności od opcji jaką wybrałeś przy zakupie szkolenie możesz odbyć samodzielnie lub ze
              wsparciem trenera. Pamiętaj o wyborze psychoterapeuty lub trenera, który będzie Cię wspierał w
              czasie trwania warsztatu jeśli wykupiłeś opcję szkolenia z trenerem.
            </Text>
          </Stack>
          <ArrowButton onClick={handleOpenCourse} height={40} fontSize="lg" fontWeight={500} w="30%">
            Kontynuuj
          </ArrowButton>
        </Stack>
      </OrderModal>
    </>
  )
}

export default OpenCourseButton
