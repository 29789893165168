import React from 'react'

import {Box, Spinner, SpinnerProps, Text} from '@chakra-ui/react'

const LabeledSpinner = ({label, ...rest}: SpinnerProps & {label?: string}) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Spinner size="xl" {...rest} />
      <Text fontSize="xl" mt="5">
        {label ?? 'Ładowanie...'}
      </Text>
    </Box>
  )
}

export default LabeledSpinner
