import * as React from 'react'

import {parseISO} from 'date-fns'

import {OrderDiscountCode} from '@/api/models'
import {parsePGInterval} from '@/api/types'
import {OrderAgreement, OrderSummary, SummaryItemProps, DiscountCodeInputProps} from '@/common/order'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatDurationShort} from '@/utils/duration'
import {formatEventTime} from '@/utils/string'

import {
  selectIsSummary,
  selectOrderAgreement,
  selectOrderCourse,
  selectOrderDate,
  selectOrderService,
  selectOrderSubmitting,
  selectTherapist,
  selectOrderDiscountCode,
  setIsSummary,
  setOrderAgreement,
  setOrderDiscountCode,
} from '../state'
import {doesDiscountCodeApply} from './utils'

const PsychotherapyOrderSummary = () => {
  const dispatch = useAppDispatch()

  const date = useAppSelector(selectOrderDate)
  const course = useAppSelector(selectOrderCourse)
  const service = useAppSelector(selectOrderService)
  const isSummary = useAppSelector(selectIsSummary)
  const therapist = useAppSelector(selectTherapist)
  const submitting = useAppSelector(selectOrderSubmitting)

  const agreement = useAppSelector(selectOrderAgreement)
  const handleAgreementChange = React.useCallback((v: boolean) => dispatch(setOrderAgreement(v)), [dispatch])

  const handleEdit = React.useCallback(() => dispatch(setIsSummary(false)), [dispatch])
  const summaryItems: SummaryItemProps[] = React.useMemo(
    () => [
      {
        title: 'Terapeuta',
        children: therapist?.full_name,
      },
      {
        title: 'Typ wizyty',
        children: course ? 'Spotkanie w ramach warsztatu' : 'Psychoterapia online',
      },
      {
        title: 'Rodzaj',
        children: service
          ? `${service.name}${course ? ` "${course.name}"` : ''} (${(service.price ?? 0) / 100}zł, ${
              !!service.meeting_duration &&
              formatDurationShort(parsePGInterval(service.meeting_duration as any))
            })`
          : 'Nie wybrano',
        onEdit: isSummary ? handleEdit : undefined,
      },
      {
        title: 'Data',
        children: date ? formatEventTime(parseISO(date)) : 'Nie wybrano',
        onEdit: isSummary ? handleEdit : undefined,
      },
    ],
    [therapist, course, service, date, isSummary, handleEdit]
  )

  const discountCode = useAppSelector(selectOrderDiscountCode)
  const discountCodeProps: DiscountCodeInputProps | undefined = React.useMemo(
    () =>
      isSummary && !course
        ? {
            value: discountCode,
            onChange: (v?: OrderDiscountCode) => dispatch(setOrderDiscountCode(v)),
            doesApply: (c: OrderDiscountCode) => doesDiscountCodeApply(c, service?.id, therapist?.id),
          }
        : undefined,
    [discountCode, service, therapist, course, isSummary, dispatch]
  )

  return (
    <>
      <OrderSummary
        image={therapist?.signed_avatar}
        summaryItems={summaryItems}
        disabled={submitting}
        discountCodeProps={discountCodeProps}
      />
      {isSummary && <OrderAgreement isChecked={agreement} onChange={handleAgreementChange} />}
    </>
  )
}

export default PsychotherapyOrderSummary
