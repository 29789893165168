import React from 'react'

import {useToast} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'

import {supabase} from '@/api'

import useRunOnReconnect from '../use-run-on-reconnect'

export type useSupabaseQueryArgs<T extends {}> = {
  fields: string
  table: string
  autoRefetch?: boolean
  itemsPerPage?: number
  pageNumber?: number
  order?: keyof T
  descending?: boolean
  match?: Partial<Record<keyof T, any>>
  finalize?: (builder: PostgrestFilterBuilder<any, T, T[]>) => PostgrestFilterBuilder<any, T, T[]>
  filter?: (builder: PostgrestFilterBuilder<any, T, T[]>) => PostgrestFilterBuilder<any, T, T[]>
  parsingFunction?: (item: any) => T
  errSnackbarTitle?: string
}

// TODO: rename it to `useSupabaseListQuery`

const useSupabaseQuery = <T extends {}>({
  fields,
  table,
  autoRefetch = true,
  itemsPerPage,
  pageNumber,
  order,
  descending,
  match,
  finalize,
  filter,
  parsingFunction,
  errSnackbarTitle,
}: useSupabaseQueryArgs<T>) => {
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const ac = React.useRef<AbortController>()
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<T[] | null>([])
  const [rows, setRows] = React.useState<number | null>(0)

  const fetch = React.useCallback(async () => {
    loading && ac.current?.abort()

    setLoading(true)
    ac.current = new AbortController()
    try {
      let query: any = supabase.from(table).select(fields, {count: 'exact'}).abortSignal(ac.current.signal)

      if (match) {
        query = query.match(match)
      }

      if (itemsPerPage) {
        query = query.limit(itemsPerPage)
        if (pageNumber) {
          const offset = itemsPerPage * pageNumber
          query = query.range(offset, offset + itemsPerPage - 1) // Both start and end indices are inclusive
        }
      }

      if (order) {
        query = query.order(order as any, {ascending: !descending})
      }

      if (filter) {
        query = filter(query)
      }
      if (finalize) {
        query = finalize(query)
      }

      const {data, count, error} = await query
      if (error) throw error
      if (!data) throw new Error('No data')

      setData(parsingFunction ? data.map((item: any) => parsingFunction(item)) : data)
      setRows(count)
    } catch (e) {
      if ((e as Error).message.includes('aborted')) return
      console.error(e)
      setError(e as Error)
      toast({
        isClosable: true,
        title: errSnackbarTitle || 'Nie udało się wczytać zasobów',
        status: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [
    descending,
    filter,
    finalize,
    itemsPerPage,
    order,
    pageNumber,
    table,
    fields,
    match,
    errSnackbarTitle,
    parsingFunction,
    toast,
    loading,
  ])

  useRunOnReconnect(fetch, !autoRefetch)

  React.useEffect(() => {
    autoRefetch && fetch()
  }, [descending, filter, finalize, itemsPerPage, order, pageNumber, match]) // eslint-disable-line

  return {data, error, fetch, loading, rows}
}

export default useSupabaseQuery
