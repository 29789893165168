import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import _ from 'lodash'

import {supabase} from '@/api'
import {AdminCourse, BuyResult, PublicCourseWithDetails, OrderDiscountCode} from '@/api/models'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {RootState} from '@/store'

export interface coursesState {
  upsert: {
    model: AdminCourse
  }
  order: {
    active: boolean
    submitting: boolean
    withMeetings: boolean
    error?: string
    model?: PublicCourseWithDetails
    coach?: number
    discountCode?: OrderDiscountCode
    tshirt?: string
    agreements: boolean[]
  }
}

const initialState: coursesState = {
  order: {
    active: false,
    submitting: false,
    agreements: Array(3).fill(false),
    withMeetings: false,
  },
  // TODO: fix bug - removing upsert.model from state breaks the application
  upsert: {
    model: {
      full_description: initialEditorValue,
      short_description: initialEditorValue,
    } as AdminCourse,
  },
}

export const submitOrder = createAsyncThunk('courses/submitOrder', async (_, {getState}) => {
  const order = (getState() as RootState).courses.order

  const {data, error} = await supabase.rpc('create_course_access_order', {
    coach_id: order.withMeetings ? order.coach : null,
    course_id: order.model?.id,
    discount_code: order.discountCode?.code ?? null,
    tshirt: order.tshirt ?? null,
    with_meetings: order.withMeetings,
  })
  if (error) throw error
  if (!data) throw new Error('No data')

  const res = data as any as BuyResult
  if ('error' in res) throw new Error(res.error)
  if (!res.redirect_url) throw new Error('Invalid redirect URL')

  window.location.href = res.redirect_url
})

export const coursesSlice = createSlice({
  initialState,
  name: 'courses',
  reducers: {
    setOrderActive: (state) => {
      state.order.active = true
      state.order.model = undefined
    },
    setOrderDone: (state) => {
      state.order = initialState.order
    },
    setOrderModel: (state, {payload}: PayloadAction<PublicCourseWithDetails>) => {
      state.order.active = true
      state.order.model = payload
    },
    setOrderCoach: (state, {payload}: PayloadAction<number>) => {
      state.order.coach = payload
    },
    setOrderDiscountCode: (state, {payload}: PayloadAction<OrderDiscountCode | undefined>) => {
      state.order.discountCode = payload
    },
    setOrderTshirt: (state, {payload}: PayloadAction<string | undefined>) => {
      state.order.tshirt = payload
    },
    setOrderAgreements: (state, {payload: {i, checked}}: PayloadAction<{i: number; checked: boolean}>) => {
      state.order.agreements[i] = checked
    },
    setOrderWithMeetings: (state, {payload}: PayloadAction<boolean>) => {
      if (!payload) state.order.coach = undefined
      state.order.withMeetings = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitOrder.pending, (state) => {
        state.order.error = undefined
        state.order.submitting = true
      })
      .addCase(submitOrder.fulfilled, (state) => {
        state.order = initialState.order
      })
      .addCase(submitOrder.rejected, (state, action) => {
        console.error('Failed to buy course', action.error)
        state.order.error = 'Zakup warsztatu zakończony niepowodzeniem'
        state.order.submitting = false
      })
  },
})

export const {
  setOrderActive,
  setOrderDone,
  setOrderModel,
  setOrderCoach,
  setOrderDiscountCode,
  setOrderTshirt,
  setOrderAgreements,
  setOrderWithMeetings,
} = coursesSlice.actions

export const selectOrderState = (state: RootState) => state.courses.order
export const selectOrderActive = (state: RootState) => state.courses.order.active
export const selectOrderModel = (state: RootState) => state.courses.order.model
export const selectOrderCoach = (state: RootState) => state.courses.order.coach
export const selectOrderWithMeetings = (state: RootState) => state.courses.order.withMeetings
export const selectOrderDiscountCode = (state: RootState) => state.courses.order.discountCode
export const selectOrderTshirt = (state: RootState) => state.courses.order.tshirt
export const selectOrderAgreements = (state: RootState) => state.courses.order.agreements
export const selectOrderSubmitting = (state: RootState) => state.courses.order.submitting
export const selectOrderError = (state: RootState) => state.courses.order.error

export default coursesSlice.reducer
