import * as React from 'react'

import {Box, Container, Flex, Grid, GridItem, Heading, Image} from '@chakra-ui/react'

type Props = {
  img?: string
  title?: string
  children?: React.ReactNode
}

const Header = ({img, title, children}: Props) => {
  return (
    <Flex
      minHeight={[96, null, 64]}
      py={[8, null, 0]}
      position="relative"
      direction="column"
      justifyContent={children ? 'flex-end' : 'center'}
      w="100%"
    >
      <Box position="absolute" left="0" top="0" width="100%" height="100%" zIndex={-2} overflow="hidden">
        {img ? (
          <>
            <Box w="100%" h="100%" position="absolute" bg="brand.green.800" opacity="0.55" />
            <Image w="100%" h="100%" src={img} objectFit="cover" objectPosition="center" />
          </>
        ) : (
          <Box w="100%" h="100%" bg="brand.green.600" />
        )}
      </Box>
      <Container maxW="container.xl" zIndex={0} mb={[0, null, children ? 8 : 0]}>
        <Grid templateColumns="repeat(12, 1fr)" gap={children ? 8 : 0} color="white">
          {title && (
            <GridItem colStart={[children ? 0 : 2, null, null, null, 2]} colSpan={[13, null, null, null, 3]}>
              <Heading as="h1" textAlign={children ? 'center' : 'left'} size={children ? 'xl' : '2xl'}>
                {title}
              </Heading>
            </GridItem>
          )}
          {children && (
            <GridItem colStart={[0, 2, null]} colSpan={[13, 11, null, null, 6]}>
              {children}
            </GridItem>
          )}
        </Grid>
      </Container>
    </Flex>
  )
}

export default Header
