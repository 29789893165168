import {createContext} from 'react'

import {AbilityBuilder, AbilityTuple, MatchConditions, PureAbility} from '@casl/ability'
import {createContextualCan} from '@casl/react'

import {UserProfile} from '@/api/models'

type AppAbility = PureAbility<AbilityTuple, MatchConditions>

export const defineAbilitiesFor = (user?: UserProfile) => {
  const {can, build} = new AbilityBuilder<AppAbility>(PureAbility)

  if (!user?.id) {
    // logged out user
    can('access', 'LoggedOutRoute')
    return build()
  }

  // logged in user
  can('access', 'LoggedInRoute')

  // TODO: add to "user_profile" view products the user has access to
  // or handle the redirects in the respective components

  // if (user.courses.length) {
  //   for (const c of user.courses) {
  //     can('access', `warsztat/${c.id}`)
  //   }
  // }

  // therapist
  if (user.therapist_id) {
    can('access', 'TherapistPanel')
  }

  // admin
  if (user.role === 'admin') {
    can('access', 'AdminPanel')
  }

  return build()
}

export const AbilityContext = createContext({} as AppAbility)
export const Can = createContextualCan(AbilityContext.Consumer)
