import * as React from 'react'

import {chakra, Button, Spacer, Stack, Text, useDisclosure} from '@chakra-ui/react'

import {UserBillingAddress} from '@/api/models'
import UserBillingModal from '@/user/billing/modal'

type Props = {
  address?: UserBillingAddress
  title?: string
  notAvailableText: string
  disabled: boolean
}

const AddressInput = ({address, title, notAvailableText, disabled}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return (
    <>
      <UserBillingModal open={isOpen} onClose={onClose} />

      <Stack direction="column" spacing={1}>
        <Stack direction="row" spacing={4}>
          {title && (
            <chakra.span fontWeight={600} whiteSpace="nowrap">
              {title}:
            </chakra.span>
          )}
          <Spacer />
          <Button onClick={onOpen} variant="brandLink" fontWeight={700} isDisabled={disabled}>
            Zmień
          </Button>
        </Stack>
        {address ? (
          <Stack spacing={1}>
            <Text>{address.company || `${address.first_name} ${address.last_name}`}</Text>
            {!!address.nip && <Text>NIP: {address.nip}</Text>}
            <Text>{address.address}</Text>
            <Text>
              {address.postal_code} {address.city}
            </Text>
          </Stack>
        ) : (
          <chakra.span>{notAvailableText}</chakra.span>
        )}
      </Stack>
    </>
  )
}

export default AddressInput
