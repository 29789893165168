import React from 'react'

import {Stack, Text, useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {GetCourseUsersResult} from '@/api/models'
import {UserLoginDetails} from '@/api/utils'
import AsyncSelect from '@/common/async-select'

import CourseJournal from './journal'

const JournalExplorer = ({course}: {course: string}) => {
  const toast = useToast()

  const [selectedValue, setSelectedValue] = React.useState<UserLoginDetails | null>(null)

  const asyncSelectLoadOptions = React.useCallback(
    async (searchValue) => {
      try {
        const {data, error} = await supabase.rpc('get_course_users', {
          course_id: course,
          email_filter: searchValue,
        })
        if (error) {
          throw error
        }
        if (!data) {
          throw new Error('No response data received.')
        }
        const result = data as any as GetCourseUsersResult
        if ('error' in result) {
          throw result.error
        }
        return result.users
      } catch (e) {
        console.error('Failed to fetch course users')
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać użytkowników kursu.',
        })
      }
    },
    [course, toast]
  )

  const optionLabel = React.useCallback(
    (u: UserLoginDetails) => (!u.id ? '' : u.email + ' - ' + u.provider),
    []
  )
  const optionValue = React.useCallback((u: UserLoginDetails) => u.email, [])

  return (
    <Stack spacing="5">
      <Text textAlign="center" fontSize="xl">
        Przeglądaj dzienniki
      </Text>
      <AsyncSelect
        value={selectedValue}
        placeholder="Wybierz użytkownika, aby zobaczyć jego dziennik..."
        defaultOptions={true}
        loadOptions={asyncSelectLoadOptions}
        getOptionLabel={optionLabel}
        getOptionValue={optionValue}
        onChange={setSelectedValue}
      />
      {selectedValue && <CourseJournal course={course} user={selectedValue.id} />}
    </Stack>
  )
}

export default JournalExplorer
