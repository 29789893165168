import React, {useCallback, useMemo} from 'react'

import {useToast} from '@chakra-ui/react'
import _ from 'lodash'
import {TreeNodeProps} from 'rc-tree'
import {NodeDragEventParams} from 'rc-tree/lib/contextTypes'
import {DataNode, EventDataNode, Key} from 'rc-tree/lib/interface'

import Tree from '@/common/rc-tree'

import './index.css'
import {findTreeNode, OnDropReturnType} from './utils'

const allowDrop = ({dropNode, dropPosition}: {dropNode: DataNode; dropPosition: number}) =>
  !dropNode.isLeaf || !!dropPosition

const DraggableTree = ({
  value,
  onChange,
  draggable,
  titleRender,
  onDrop,
  onSelect,
  selectedKeys,
  defaultSelectedKeys,
  icon,
  expandedKeys,
  onExpand,
}: {
  value: DataNode[]
  onChange: (treeData: DataNode[]) => void
  draggable?: boolean
  titleRender?: (node: DataNode) => React.ReactNode
  onDrop?: (
    info: NodeDragEventParams & {
      dragNode: EventDataNode
      dragNodesKeys: Key[]
      dropPosition: number
      dropToGap: boolean
    }
  ) => Promise<OnDropReturnType>
  onSelect?: (
    selectedKeys: Key[],
    info: {
      event: 'select'
      selected: boolean
      node: EventDataNode
      selectedNodes: DataNode[]
      nativeEvent: MouseEvent
    }
  ) => void
  selectedKeys?: Key[]
  defaultSelectedKeys?: Key[]
  icon: React.ReactNode | ((props: TreeNodeProps) => React.ReactNode)
  expandedKeys?: Key[]
  onExpand?: (
    expandedKeys: Key[],
    info: {
      node: EventDataNode
      expanded: boolean
      nativeEvent: MouseEvent
    }
  ) => void
}) => {
  const toast = useToast()

  const onDropWrapper = useCallback(
    async (
      info: NodeDragEventParams & {
        dragNode: EventDataNode
        dragNodesKeys: Key[]
        dropPosition: number
        dropToGap: boolean
      }
    ) => {
      let treeValue = value

      if (onDrop) {
        const {success, tree} = await onDrop(info)
        if (!success) {
          return
        }
        if (tree) {
          treeValue = tree
        }
      }

      const dropKey = info.node.key
      const dragKey = info.dragNode.key
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

      // The received treeData prop is a component's state,
      // so we need to deep copy it to not break immutability.
      const copiedValue = _.cloneDeep(treeValue)

      // find drag and drop items
      const [dragItem, dragIndex, dragArr] = findTreeNode(copiedValue, dragKey)
      if (!dragItem) {
        toast({
          description: 'Failed to find source node in the tree',
          status: 'error',
          title: 'Drag operation failed to complete',
        })
        return
      }
      const [dropItem, dropIndex, dropArr] = findTreeNode(copiedValue, dropKey)
      if (!dropItem) {
        toast({
          description: 'Failed to find destination node in the tree',
          status: 'error',
          title: 'Drag operation failed to complete',
        })
        return
      }
      dragArr.splice(dragIndex, 1)

      // `dropPosition === 0` means the node was dropped directly into the node with children.
      if (!dropPosition) {
        dropItem.children = dropItem.children || []
        dropItem.children.unshift(dragItem)
      } else {
        // Drop on the gap (insert before or insert after)
        if (dropPosition === -1) {
          dropArr.splice(dropIndex, 0, dragItem)
        } else {
          dropArr.splice(dropIndex + 1, 0, dragItem)
        }
      }
      onChange(copiedValue)
    },
    [onChange, onDrop, toast, value]
  )

  return (
    <Tree
      expandedKeys={expandedKeys}
      onExpand={onExpand}
      icon={icon}
      allowDrop={allowDrop}
      defaultExpandAll={true}
      draggable={draggable}
      onDrop={onDropWrapper}
      treeData={value}
      titleRender={titleRender}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      defaultSelectedKeys={defaultSelectedKeys}
    />
  )
}

export default DraggableTree
