import React from 'react'

import {AdminWebinarReview} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useReviewModal from '@/common/reviews/review-modal/use-review-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, filterFields} from './constants'

const WebinarReviews = () => {
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminWebinarReview>({
    table: 'admin_webinar_reviews',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminWebinarReview,
    filter: tableState.filter,
  })

  const {
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
    value: deleteValue,
  } = useValueDisclosure<AdminWebinarReview>()

  const {startReview} = useReviewModal({onComplete: fetch})
  const handleEdit = React.useCallback(
    (review: AdminWebinarReview) =>
      startReview({admin: true, open: true, type: 'webinar', itemID: review.webinar, review}),
    [startReview]
  )

  const actions = React.useMemo(
    () => [deleteAction(onDeleteOpen), editAction(handleEdit)],
    [onDeleteOpen, handleEdit]
  )

  return (
    <>
      <DataTable<AdminWebinarReview>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
        filterFields={filterFields}
      />
      <DeleteResourceModal
        table="webinar_reviews"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default WebinarReviews
