import * as React from 'react'

import {Tag} from '@chakra-ui/react'

import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

import {TherapistMeeting} from '../types'
import {ReviewsModalButton} from './reviews-modal'

export const defaultSelectedColumns = ['name', 'starts_at', 'participants', 'avg_rating']

export const columns: Column<TherapistMeeting>[] = [
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
    sortable: false,
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'ID usługi',
    id: 'service_id',
    renderAs: 'code',
    sortable: false,
  },
  {
    Header: 'Nazwa usługi',
    id: 'service_name',
  },
  {
    Header: 'Czas rozpoczęcia',
    id: 'starts_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Czas zakończenia',
    id: 'ends_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Uczestnicy',
    id: 'participants',
    sortable: false,
    renderer: ({participants}) => participants.map((p, i) => <Tag key={i}>{p.email}</Tag>),
  },
  {
    Header: 'Ocena',
    id: 'avg_rating',
    renderer: ({avg_rating, reviews}) => <ReviewsModalButton avg={avg_rating} reviews={reviews} />,
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'text',
  },
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'ID usługi',
    name: 'service_id',
    variant: 'text',
  },
  {
    label: 'Nazwa usługi',
    name: 'service_name',
    variant: 'text',
  },
  {
    label: 'Czas rozpoczęcia',
    name: 'starts_at',
    variant: 'datetime',
  },
  {
    label: 'Czas zakończenia',
    name: 'ends_at',
    variant: 'datetime',
  },
  {
    label: 'Ocena',
    name: 'avg_rating',
    variant: 'number',
  },
]
