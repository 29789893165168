import React from 'react'

import {IconButton, Tooltip} from '@chakra-ui/react'
import {usePlateEditorState} from '@udecode/plate'
import {toggleMark} from '@udecode/plate-core'
import {
  MdCode,
  MdFormatBold,
  MdFormatItalic,
  MdFormatQuote,
  MdFormatUnderlined,
  MdLooksOne,
  MdLooksTwo,
} from 'react-icons/md'

import {EditorContext} from '../editor-context'
import {InsertAttachmentButton} from '../plugins/attachment'
import {InsertCanvaButton} from '../plugins/canva'
import {InsertImageButton} from '../plugins/image'
import {InsertLinkButton} from '../plugins/link'
import {OrderedListButton, UnorderedListButton} from '../plugins/list'
import {InsertSurveyButton} from '../plugins/survey'
import {InsertTypeformButton} from '../plugins/typeform'
import {InsertVimeoButton} from '../plugins/vimeo'
import {InsertYouTubeButton} from '../plugins/youtube'
import {isBlockActive, isMarkActive, toggleBlock} from '../utils'

export const MarkButton = ({
  format,
  icon,
  label,
}: {
  format: string
  icon: React.ReactElement
  label?: string
}) => {
  const editor = usePlateEditorState()!
  return (
    <Tooltip label={label}>
      <IconButton
        variant="outline"
        isActive={isMarkActive(editor, format)}
        onMouseDown={(event) => {
          event.preventDefault()
          toggleMark(editor, {key: format})
        }}
        aria-label={format}
        icon={icon}
        borderWidth={0}
        fontSize="20px"
      />
    </Tooltip>
  )
}

export const BlockButton = ({
  format,
  label,
  icon,
  onClick,
}: {
  format: string
  label?: string
  icon: React.ReactElement
  onClick?: React.MouseEventHandler
}) => {
  const editor = usePlateEditorState()!

  const handleDefaultBlockToggle = React.useCallback(
    (e) => {
      e.preventDefault()
      toggleBlock(editor, format)
    },
    [editor, format]
  )

  return (
    <Tooltip label={label}>
      <IconButton
        variant="outline"
        isActive={isBlockActive(editor, format)}
        onClick={onClick || handleDefaultBlockToggle}
        aria-label={format}
        icon={icon}
        borderWidth={0}
        fontSize="20px"
      />
    </Tooltip>
  )
}

export const ToolbarButtons = () => {
  const {variant, surveys, bucketName, bucketScope} = React.useContext(EditorContext)

  return (
    <>
      <MarkButton format="bold" label="Pogrubienie (Ctrl+B)" icon={<MdFormatBold />} />
      <MarkButton format="italic" label="Kursywa (Ctrl+I)" icon={<MdFormatItalic />} />
      <MarkButton format="underline" label="Podkreślenie (Ctrl+U)" icon={<MdFormatUnderlined />} />
      <MarkButton format="code" label="Kod" icon={<MdCode />} />
      <InsertLinkButton />
      {variant !== 'basic' && (
        <>
          <BlockButton format="heading-one" label="Nagłówek 1" icon={<MdLooksOne />} />
          <BlockButton format="heading-two" label="Nagłówek 2" icon={<MdLooksTwo />} />
          <BlockButton format="blockquote" label="Cytat" icon={<MdFormatQuote />} />
          <UnorderedListButton />
          <OrderedListButton />
          <InsertVimeoButton />
          <InsertYouTubeButton />
          <InsertCanvaButton />
          <InsertTypeformButton />
          {surveys && <InsertSurveyButton />}
          {variant === 'full' && bucketName && bucketScope && (
            <>
              <InsertAttachmentButton bucketName={bucketName} bucketScope={bucketScope} />
              <InsertImageButton bucketName={bucketName} bucketScope={bucketScope} />
            </>
          )}
        </>
      )}
    </>
  )
}
