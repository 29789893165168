import React from 'react'

import {Box, Container, Flex, IconButton, Image, Link, Stack} from '@chakra-ui/react'
import {FaFacebookF, FaInstagram} from 'react-icons/fa'
import {Link as RouterLink} from 'react-router-dom'

import {CONTACT_US, FAQ, HOME, JOIN_US, PRIVACY_POLICY, TERMS_AND_CONDITIONS} from '@/router/paths'

import logo from './logo.svg'

const Footer = () => {
  return (
    <Box py={8} bg="brand.green.600" color="white">
      <Container maxW="container.xl">
        <Flex
          flexDirection={['column', null, 'row']}
          alignItems="center"
          justifyContent="space-between"
          px={[4, null, 0]}
        >
          <Stack
            direction="row"
            spacing={8}
            alignItems="center"
            width={['100%', null, 'auto']}
            mb={[6, null, 0]}
          >
            <Image src={logo} height={['68px', null, 'auto']} />
            <Flex grow={1} />
            <Stack direction="row" spacing={4}>
              <IconButton
                as={Link}
                href="https://www.facebook.com/psychoformapl"
                target="_blank"
                icon={<FaFacebookF />}
                variant="unstyled"
                aria-label="Strona na Facebooku"
                border="2px solid"
                borderColor="brand.yellow.600"
                display="flex"
                color="brand.yellow.600"
                _hover={{borderColor: 'brand.yellow.700', color: 'brand.yellow.700'}}
                rounded="full"
              />
              <IconButton
                as={Link}
                href="https://www.instagram.com/psychoforma.pl"
                target="_blank"
                icon={<FaInstagram />}
                variant="unstyled"
                aria-label="Profil na Instagramie"
                border="2px solid"
                borderColor="brand.yellow.600"
                display="flex"
                color="brand.yellow.600"
                _hover={{borderColor: 'brand.yellow.700', color: 'brand.yellow.700'}}
                rounded="full"
              />
            </Stack>
          </Stack>

          <Stack
            direction="column"
            fontWeight={300}
            fontSize="sm"
            spacing={4}
            display={['none', null, 'block']}
          >
            <Stack direction="row" justifyContent="flex-end" spacing={10}>
              <Link as={RouterLink} to={CONTACT_US} fontWeight={500}>
                Kontakt
              </Link>
              <Link as={RouterLink} to={JOIN_US} fontWeight={500}>
                Dołącz do nas
              </Link>
              <Link as={RouterLink} to={FAQ} fontWeight={500}>
                FAQ
              </Link>
              <Link as={RouterLink} to={TERMS_AND_CONDITIONS}>
                Regulamin
              </Link>
              <Link as={RouterLink} to={PRIVACY_POLICY}>
                Polityka prywatności
              </Link>
            </Stack>
            <Box textAlign="right">
              © {new Date().getFullYear()}
              <Link ml="1" as={RouterLink} to={HOME}>
                Psychoforma
              </Link>
            </Box>
          </Stack>

          <Stack
            direction="column"
            fontWeight={300}
            fontSize="sm"
            spacing={4}
            display={['block', null, 'none']}
            width="100%"
          >
            <Stack direction="row" justifyContent="space-between" spacing={10} fontSize="lg">
              <Link as={RouterLink} to={CONTACT_US} fontWeight={500}>
                Kontakt
              </Link>
              <Link as={RouterLink} to={JOIN_US} fontWeight={500}>
                Dołącz do nas
              </Link>
              <Link as={RouterLink} to={FAQ} fontWeight={500}>
                FAQ
              </Link>
            </Stack>
            <Flex direction="row" alignItems="flex-end" justifyContent="space-between" width="100%">
              <Stack direction="column" spacing={4}>
                <Link as={RouterLink} to={TERMS_AND_CONDITIONS}>
                  Regulamin
                </Link>
                <Link as={RouterLink} to={PRIVACY_POLICY}>
                  Polityka prywatności
                </Link>
              </Stack>
              <Box textAlign="right">
                © 2022
                <Link ml="1" as={RouterLink} to={HOME}>
                  Psychoforma
                </Link>
              </Box>
            </Flex>
          </Stack>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
