import * as React from 'react'

import {DownloadIcon} from '@chakra-ui/icons'
import {IconButton, Link} from '@chakra-ui/react'

import {AdminInvoice} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const columns: Column<AdminInvoice>[] = [
  {
    Header: 'Zamówienie',
    id: 'order',
  },
  {
    Header: 'Numer faktury',
    id: 'id',
  },
  {
    Header: 'Email użytkownika',
    id: 'user_email',
  },
  {
    Header: 'Klient',
    id: 'name',
  },
  {
    Header: 'Firma',
    id: 'company',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'NIP',
    id: 'nip',
    sortable: false,
  },
  {
    Header: 'Pobierz',
    id: 'url',
    renderer: ({url}) => (
      <IconButton
        as={Link}
        target="_blank"
        href={url}
        aria-label="pdf"
        icon={<DownloadIcon />}
        variant="ghost"
      />
    ),
    sortable: false,
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Zamówienie',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'Numer faktury',
    name: 'id',
    variant: 'text',
  },
  {
    label: 'Klient',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Firma',
    name: 'company',
    variant: 'text',
  },
  {
    label: 'NIP',
    name: 'nip',
    variant: 'text',
  },
  {
    label: 'Email użytkownika',
    name: 'user_email',
    variant: 'text',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
]
