import * as React from 'react'

import {FormControl, FormLabel} from '@chakra-ui/react'
import Select, {SingleValue} from 'react-select'

import useSupabaseQuery from '@/common/use-custom-query'
import {useAppDispatch, useAppSelector} from '@/store'
import {SelectOption} from '@/utils/types'

import {selectReviewState, setHostID} from '../state'

type SupportGroupReviewHost = {
  support_group: number
  id: number
  full_name: string
}

// support group reviews are assigned to the host
const ReviewHostInput = () => {
  const dispatch = useAppDispatch()
  const {submitting, type, itemID, review, hostID} = useAppSelector(selectReviewState)

  const {data, loading, fetch} = useSupabaseQuery<SupportGroupReviewHost>(
    React.useMemo(
      () => ({
        table: 'support_group_review_hosts',
        fields: '*',
        autoRefetch: false,
        match: {support_group: itemID},
      }),
      [itemID]
    )
  )
  const options = React.useMemo(() => data?.map((h) => ({value: h.id, label: h.full_name})) || [], [data])

  React.useEffect(() => {
    if (type !== 'support_group' || !itemID) return
    fetch()
  }, [type, itemID]) // eslint-disable-line

  React.useEffect(() => {
    if (hostID || !data?.length || !('host' in review)) return

    dispatch(setHostID((review.host as number) || data[0].id))
  }, [data]) //eslint-disable-line

  const handleChange = React.useCallback(
    (v: SingleValue<SelectOption<number>>) => {
      dispatch(setHostID(v?.value))
    },
    [dispatch]
  )

  return (
    <FormControl>
      <FormLabel>Prowadzący</FormLabel>
      <Select
        value={options?.find((v) => v.value === hostID)}
        options={options}
        onChange={handleChange}
        isLoading={loading}
        isDisabled={submitting}
      />
    </FormControl>
  )
}

export default ReviewHostInput
