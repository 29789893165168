import React, {useCallback, useMemo, useState} from 'react'

import {
  PlusSquareIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  DeleteIcon,
  DragHandleIcon,
  EditIcon,
  HamburgerIcon,
  ViewIcon,
  ViewOffIcon,
} from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  useColorMode,
  useToast,
} from '@chakra-ui/react'
import _ from 'lodash'
import {TreeNodeProps} from 'rc-tree'
import {NodeDragEventParams} from 'rc-tree/lib/contextTypes'
import {EventDataNode, Key} from 'rc-tree/lib/interface'
import {MdFolder as FolderIcon} from 'react-icons/md'
import {useParams} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'
import {CoursePage, Section} from '@/api/models'
import DraggableTree from '@/common/draggable-tree'
import {findTreeNode, findTreeParent, OnDropReturnType} from '@/common/draggable-tree/utils'
import useValueDisclosure from '@/common/use-value-disclosure'
import {CourseNode, CoursePagePerUser, EventCourseNode, MenuNodeType, SectionPerUser} from '@/courses/types'

import CourseNodeTitleEditor from './course-node-title-editor'
import DeleteMenuNodeDialog from './delete-menu-node-dialog'
import {CourseParams, moveCoursePage, moveCourseSection, newCourseNode, refreshSectionsState} from './utils'

const TreeMenu = ({
  adminView,
  course,
  editing,
  value,
  onChange,
  isLoading,
  expandedKeys,
  onExpand,
}: {
  adminView?: boolean
  course: number
  editing: boolean
  value: CourseNode[]
  onChange: (treeData: CourseNode[]) => void
  isLoading: boolean
  expandedKeys?: Key[]
  onExpand?: (
    expandedKeys: Key[],
    info: {
      node: EventDataNode
      expanded: boolean
      nativeEvent: MouseEvent
    }
  ) => void
}) => {
  const [nodeTitleEditorModalType, setNodeTitleEditorModalType] = useState<MenuNodeType>()
  const [nodeTitleEditorModalValue, setNodeTitleEditorModalValue] = useState<string>('')
  const [nodeTitleEditorModalNodeID, setNodeTitleEditorModalNodeID] = useState<string>()
  const [nodeTitleEditorModalParent, setNodeTitleEditorModalParent] = useState<CourseNode>()

  const [navbarHeight, setNavbarHeight] = React.useState<number>()
  React.useEffect(() => {
    setNavbarHeight(document.getElementById('navbar')?.getBoundingClientRect().height ?? 0)
  }, [])

  const toast = useToast()

  const {page} = useParams<CourseParams>()

  const {
    onClose: onCreateMenuNodeClose,
    onOpen: onCreateMenuNodeOpen,
    value: createMenuNodeValue,
  } = useValueDisclosure<string>()

  const {
    onClose: onDeleteMenuNodeClose,
    onOpen: onDeleteMenuNodeOpen,
    value: deleteMenuNodeValue,
  } = useValueDisclosure<CourseNode>()

  const onDeleteMenuNodeComplete = useCallback(() => {
    if (!deleteMenuNodeValue) {
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się zaktualizować układu menu.',
      })
      return
    }

    // The received treeData prop is a component's state,
    // so we need to deep copy it to not break immutability.
    const treeDataCopy = _.cloneDeep(value)
    const [toBeRemoved, ix, parentArray] = findTreeNode(treeDataCopy, deleteMenuNodeValue.key)
    if (!toBeRemoved) {
      throw new Error('The node to be deleted is not found in a menu tree')
    }

    // We need to find parent node before removing the node itself.
    const parentNode = findTreeParent(treeDataCopy, toBeRemoved.key)
    parentArray.splice(ix, 1)
    if (parentNode) {
      refreshSectionsState(treeDataCopy, parentNode)
    }

    onChange(treeDataCopy)
  }, [deleteMenuNodeValue, onChange, toast, value])

  const togglePublished = useCallback(
    async ({key, type, published}: CourseNode) => {
      try {
        if (!type) {
          throw new Error('Could not determine the node type')
        }

        const {error} = await supabase
          .from(type === 'section' ? 'course_sections' : 'course_pages')
          .update({published: !published})
          .eq('id', key.toString())
        if (error) {
          throw error
        }

        // The received treeData prop is a component's state,
        // so we need to deep copy it to not break immutability.
        const treeDataCopy = _.cloneDeep(value)
        const [node] = findTreeNode(treeDataCopy, key)
        if (!node) {
          throw new Error('Published state updated, but the change is not visible locally')
        }
        ;(node as CourseNode).published = !published
        onChange(treeDataCopy)

        toast({
          description: (node as CourseNode).title,
          isClosable: true,
          status: 'success',
          title: (node as CourseNode).published
            ? (node as CourseNode).type === 'page'
              ? 'Opublikowano stronę.'
              : 'Opublikowano sekcję.'
            : (node as CourseNode).type === 'page'
            ? 'Ukryto stronę.'
            : 'Ukryto sekcję.',
        })
      } catch (e) {
        console.error('Failed to update node published status', e)
        toast({
          isClosable: true,
          status: 'error',
          title:
            type === 'page'
              ? 'Nie udało się zaktualizować statusu opublikowania strony.'
              : 'Nie udało się zaktualizować statusu opublikowania sekcji.',
        })
      }
    },
    [onChange, toast, value]
  )

  const renameCourseNode = useCallback(
    ({key, title, type}: CourseNode) => {
      type === 'section' ? setNodeTitleEditorModalType('section') : setNodeTitleEditorModalType('page')
      setNodeTitleEditorModalValue(title as string)
      setNodeTitleEditorModalNodeID(key.toString())
      onCreateMenuNodeOpen(title as string)
    },
    [onCreateMenuNodeOpen]
  )

  // handleStopPropagation is used to prevent propageting onclick event from menu node
  // action buttons to menu nodes itself, and as a result to prevent changing selected page.
  // Also, is used to prevent propageting drag events of menu node dropdown menu.
  const handleStopPropagation = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const handleSectionModalOpen = useCallback(
    (parent?: CourseNode) => {
      setNodeTitleEditorModalType('section')
      setNodeTitleEditorModalValue('')
      setNodeTitleEditorModalNodeID(undefined)
      setNodeTitleEditorModalParent(parent)
      onCreateMenuNodeOpen('')
    },
    [onCreateMenuNodeOpen]
  )

  const handleSectionModalOpenRoot = useCallback(() => handleSectionModalOpen(), [handleSectionModalOpen])

  const handlePageModalOpen = useCallback(
    (parent?: CourseNode) => {
      setNodeTitleEditorModalType('page')
      setNodeTitleEditorModalValue('')
      setNodeTitleEditorModalNodeID(undefined)
      setNodeTitleEditorModalParent(parent)
      onCreateMenuNodeOpen('')
    },
    [onCreateMenuNodeOpen]
  )

  const titleRender = useCallback(
    (a: CourseNode) => (
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Box mr="2" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {a.title}
        </Box>
        {editing && (
          <HStack spacing="1">
            <IconButton
              aria-label=""
              size="sm"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                togglePublished(a)
              }}
              boxShadow="none !important"
              icon={a.published ? <ViewIcon color="#008800" /> : <ViewOffIcon color="#cc0000" />}
            />
            <Box onClick={handleStopPropagation}>
              <Menu placement="bottom-end">
                {({isOpen}) => (
                  <>
                    <MenuButton
                      isActive={isOpen}
                      as={IconButton}
                      size="sm"
                      aria-label="Options"
                      boxShadow="none !important"
                      icon={<HamburgerIcon />}
                    />
                    {/* when closed, set display to none to prevent dragging invisible element with all elements beneath */}
                    <Portal>
                      <MenuList
                        display={!isOpen ? 'none' : undefined}
                        draggable={true}
                        onDragStart={handleStopPropagation}
                      >
                        {!a.isLeaf && (
                          <>
                            <MenuItem onClick={() => handleSectionModalOpen(a)}>
                              <PlusSquareIcon mr="2" />
                              Dodaj sekcję
                            </MenuItem>
                            <MenuItem onClick={() => handlePageModalOpen(a)}>
                              <PlusSquareIcon mr="2" />
                              Dodaj stronę
                            </MenuItem>
                            <MenuDivider />
                          </>
                        )}

                        <MenuItem
                          onClick={(e) => {
                            handleStopPropagation(e)
                            renameCourseNode(a)
                          }}
                        >
                          <EditIcon mr="2" />
                          Zmień nazwę
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleStopPropagation(e)
                            onDeleteMenuNodeOpen(a)
                          }}
                        >
                          <DeleteIcon mr="2" />
                          Usuń
                        </MenuItem>
                      </MenuList>
                    </Portal>
                  </>
                )}
              </Menu>
            </Box>
          </HStack>
        )}
      </Flex>
    ),
    [
      handlePageModalOpen,
      handleSectionModalOpen,
      handleStopPropagation,
      onDeleteMenuNodeOpen,
      editing,
      renameCourseNode,
      togglePublished,
    ]
  )

  const onDrop = useCallback(
    async (
      info: NodeDragEventParams & {
        dragNode: EventDataNode
        dragNodesKeys: Key[]
        dropPosition: number
        dropToGap: boolean
      }
      // TODO: add generics on the tree
    ): Promise<OnDropReturnType> => {
      const node: EventCourseNode = info.node
      const dragNode: EventCourseNode = info.dragNode

      try {
        // prevent course page to be dropped in the menu root
        if (dragNode.isLeaf && node.pos.split('-').length === 2 && info.dropToGap) {
          throw new Error('Course page has to be inside course section')
        }

        if (!node.type || !dragNode.type) {
          throw new Error('Could not retrieve the type of a dragged element')
        }

        let destinationSection: CourseNode | null
        let destinationIndex: number

        if (node.type === 'section') {
          if (info.dropToGap) {
            const destinationSectionParent = findTreeParent(value, info.node.key)
            destinationSection = destinationSectionParent || null
            destinationIndex = info.dropPosition === -1 ? 0 : info.dropPosition
          } else {
            destinationSection = info.node
            destinationIndex = 0
          }
        } else {
          const destinationPageParent = findTreeParent(value, info.node.key)
          destinationSection = destinationPageParent || null
          destinationIndex = info.dropPosition
        }

        await (dragNode.type === 'section'
          ? moveCourseSection({
              course,
              destinationIndex,
              destinationSection: destinationSection?.key.toString() || null,
              section: info.dragNode.key.toString(),
            })
          : moveCoursePage({
              course,
              destinationIndex,
              destinationSection: destinationSection?.key.toString() || null,
              page: info.dragNode.key.toString(),
            }))

        let updatedTree = value
        if (dragNode.type === 'section') {
          const alterSectionPaths = (root: CourseNode, path: string) => {
            root.path = (path ? `${path}.` : '') + `${root.key.toString().replaceAll('-', '_')}`
            for (const ch of root.children || []) {
              if (!ch.isLeaf) {
                alterSectionPaths(ch, root.path)
              }
            }
          }

          const copiedTreeData = _.cloneDeep(value)
          const [updatedNode] = findTreeNode(copiedTreeData, dragNode.key)
          if (!updatedNode) {
            throw new Error('Menu arrangement updated, but the change is not visible locally')
          }
          alterSectionPaths(updatedNode, destinationSection?.path || '')
          updatedTree = copiedTreeData
        }

        toast({
          isClosable: true,
          status: 'success',
          title: 'Zaktualizowano układ menu.',
        })
        return {success: true, tree: updatedTree}
      } catch (e) {
        console.error('Failed to update menu arrangement', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zaktualizować układu menu.',
        })
        return {success: false}
      }
    },
    [course, toast, value]
  )

  const onTitleEditorModalComplete = useCallback(async () => {
    const now = new Date()

    // if true then update existing element
    if (nodeTitleEditorModalNodeID) {
      try {
        const {error} = await supabase
          .from(nodeTitleEditorModalType === 'section' ? 'course_sections' : 'course_pages')
          .update({title: nodeTitleEditorModalValue, updated_at: now})
          .eq('id', nodeTitleEditorModalNodeID)
        if (error) {
          throw error
        }

        // The treeData is a component's state, so we need to deep copy it to not break immutability.
        const copiedTreeData = _.cloneDeep(value)
        const [updatedNode] = findTreeNode(copiedTreeData, nodeTitleEditorModalNodeID)
        if (!updatedNode) {
          throw new Error('Menu node name updated, but the change is not visible locally')
        }
        updatedNode.title = nodeTitleEditorModalValue
        onChange(copiedTreeData)

        toast({
          isClosable: true,
          status: 'success',
          title: 'Zmieniono nazwę elementu menu.',
        })
      } catch (e) {
        console.error('Failed to update menu node title', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zmienić nazwy elementu menu.',
        })
      } finally {
        onCreateMenuNodeClose()
      }
      // exit the function
      return
    }

    try {
      if (!nodeTitleEditorModalType) {
        throw new Error('Failed to retrieve created node type')
      }

      const id = uuidv4()

      // pages are inserted into the last section of the course
      if (nodeTitleEditorModalType === 'page' && !value.length) {
        throw new Error('Cannot insert a new page, because there is no section in the course')
      }

      const newElement: CoursePage | Section =
        nodeTitleEditorModalType === 'section'
          ? {
              course,
              created_at: now,
              id,
              order: nodeTitleEditorModalParent
                ? nodeTitleEditorModalParent.children?.length || 0
                : value.length,
              path:
                (nodeTitleEditorModalParent?.path ? `${nodeTitleEditorModalParent.path}.` : '') +
                id.replaceAll('-', '_'),
              published: false,
              title: nodeTitleEditorModalValue,
              updated_at: now,
            }
          : {
              course,
              created_at: now,
              id,
              order:
                (nodeTitleEditorModalParent
                  ? nodeTitleEditorModalParent.children?.length
                  : value[value.length - 1].children?.length) || 0,
              published: false,
              section: nodeTitleEditorModalParent
                ? nodeTitleEditorModalParent.key.toString()
                : value[value.length - 1].key.toString(),
              title: nodeTitleEditorModalValue,
              updated_at: now,
            }

      const {error} = await supabase
        .from(nodeTitleEditorModalType === 'section' ? 'course_sections' : 'course_pages')
        .insert(newElement)
      if (error) {
        throw error
      }

      const newNode =
        nodeTitleEditorModalType === 'page'
          ? newCourseNode({
              completed: false,
              id,
              link: `${adminView ? '/admin' : ''}/courses/${course}/view/${id}`,
              published: false,
              title: nodeTitleEditorModalValue,
              touched: false,
              type: nodeTitleEditorModalType,
            })
          : newCourseNode({
              completed: false,
              id,
              path: (newElement as Section).path,
              published: false,
              title: nodeTitleEditorModalValue,
              touched: false,
              type: nodeTitleEditorModalType,
            })

      if (nodeTitleEditorModalParent) {
        const copiedTreeData = _.cloneDeep(value)
        const [destination] = findTreeNode(copiedTreeData, nodeTitleEditorModalParent.key)
        destination?.children?.push(newNode)
        onChange(copiedTreeData)
      } else {
        onChange([...value, newNode])
      }

      toast({
        isClosable: true,
        status: 'success',
        title: 'Utworzono nowy element menu.',
      })
    } catch (e) {
      console.error('Failed to create new menu node', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się utworzyć nowego elementu menu.',
      })
    } finally {
      onCreateMenuNodeClose()
    }
  }, [
    adminView,
    course,
    nodeTitleEditorModalNodeID,
    nodeTitleEditorModalParent,
    nodeTitleEditorModalType,
    nodeTitleEditorModalValue,
    onCreateMenuNodeClose,
    onChange,

    toast,
    value,
  ])

  const selectedKeys = useMemo(() => [page], [page])

  const cssVariables = useMemo(
    () => ({
      '&::-webkit-scrollbar': {
        width: '0.5em',
      },
      '&::-webkit-scrollbar-thumb': {
        '&:hover': {
          bg: 'brand.yellow.400',
        },
        bg: 'brand.yellow.300',
        borderRadius: 'full',
      },
      '&::-webkit-scrollbar-track': {
        display: 'none',
      },
      '--menu-hover-color': 'var(--chakra-colors-gray-300)',
      '--menu-selected-node': 'var(--chakra-colors-messenger-50)',
      maxH: `calc(100vh - ${(navbarHeight ?? 0) + 32}px)`,
      overflowY: 'scroll',
      pos: navbarHeight ? 'sticky' : undefined,
      pr: 2,
      top: navbarHeight ? `${navbarHeight + 16}px` : undefined,
    }),
    [navbarHeight]
  )

  const getIcon = useCallback(
    (nodeProps: TreeNodeProps & (CoursePagePerUser | SectionPerUser)) => {
      return (
        <HStack>
          {editing && <DragHandleIcon />}
          {nodeProps.isLeaf ? (
            <Box
              height=".75rem"
              borderRadius="full"
              width=".75rem"
              bg={nodeProps.completed ? 'brand.green.600' : nodeProps.touched ? 'brand.yellow.600' : 'gray'}
            />
          ) : (
            <HStack>
              {nodeProps.expanded ? <ChevronDownIcon /> : <ChevronRightIcon />}
              <Icon
                as={FolderIcon}
                color={
                  nodeProps.completed ? 'brand.green.600' : nodeProps.touched ? 'brand.yellow.600' : 'gray'
                }
              />
            </HStack>
          )}
        </HStack>
      )
    },
    [editing]
  )

  return (
    <Box w="100%" sx={cssVariables}>
      <DeleteMenuNodeDialog
        course={course}
        node={deleteMenuNodeValue}
        onClose={onDeleteMenuNodeClose}
        open={!!deleteMenuNodeValue}
        onComplete={onDeleteMenuNodeComplete}
      />
      <CourseNodeTitleEditor
        open={createMenuNodeValue !== null} // empty string should open the modal
        onClose={onCreateMenuNodeClose}
        initialValue={createMenuNodeValue ?? undefined}
        type={nodeTitleEditorModalType}
        onApply={setNodeTitleEditorModalValue}
        onSubmit={onTitleEditorModalComplete}
      />
      <Box>
        {editing && !isLoading && (
          <Button
            onClick={handleSectionModalOpenRoot}
            w="100%"
            size="sm"
            variant="outline"
            leftIcon={<PlusSquareIcon />}
          >
            Dodaj sekcję
          </Button>
        )}
        {value.length ? (
          <>
            <DraggableTree
              value={value}
              icon={getIcon}
              onChange={onChange}
              draggable={editing}
              titleRender={titleRender}
              onDrop={onDrop}
              selectedKeys={selectedKeys}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
            />
          </>
        ) : (
          !isLoading && <Box>Kurs nie ma żadnej zawartości...</Box>
        )}
      </Box>
    </Box>
  )
}

export default TreeMenu
