import * as React from 'react'

import {useToast} from '@chakra-ui/react'
import {useHistory, useLocation} from 'react-router'

import {ADMIN_CALENDAR} from '@/admin/router'
import {supabase} from '@/api'
import LoadingView from '@/common/loading-view'
import {THERAPIST_CALENDAR} from '@/therapist-panel/router'

type CalendarAuthCallbackProps = {
  calendarType: 'admin' | 'user'
}

const CalendarAuthCallback = ({calendarType}: CalendarAuthCallbackProps) => {
  const location = useLocation()
  const history = useHistory()

  const toast = useToast()

  React.useEffect(() => {
    ;(async () => {
      try {
        const params = new URLSearchParams(location.search)
        const code = params.get('code')
        if (!code) throw Error('Brak kodu autoryzacyjnego')

        const {error} = await supabase.rpc('finalize_google_auth', {
          calendar_type: calendarType,
          code: code,
        })
        if (error) throw error

        toast({isClosable: true, status: 'success', title: 'Pomyślnie połączono z Kalendarzem Google'})
      } catch (e) {
        console.error('Failed to authenticate', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Wystąpił błąd podczas łączenia z kalendarzem Google',
        })
      } finally {
        history.replace(calendarType === 'admin' ? ADMIN_CALENDAR : THERAPIST_CALENDAR)
      }
    })()
  }, []) // eslint-disable-line

  return <LoadingView />
}

export default CalendarAuthCallback
