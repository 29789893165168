import * as React from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import Select, {MultiValue, SingleValue} from 'react-select'
import AsyncSelect from 'react-select/async'

import {supabase} from '@/api'
import {AdminMeeting, TherapistAssignedService, parseTherapistService} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'
import useEditorModalState from '@/common/use-editor-modal'
import TimeSlotSelector from '@/psychotherapy/therapists/selector'
import {formatDurationShort} from '@/utils/duration'
import {CommonModalProps, SelectOption} from '@/utils/types'

import {emptyAdminMeeting} from '../constants'
import {inputToUpsertArgs} from './utils'

const AdminMeetingEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: AdminMeeting | null}) => {
  const {input, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<AdminMeeting>({
      table: 'meetings',
      item,
      emptyInput: emptyAdminMeeting,
      customRPC: 'upsert_therapy_meeting',
      inputToUpsertArgs,
      open,
      onComplete,
      onClose,
    })

  const handleDateChange = React.useCallback(
    (date: Date) => {
      handleCustomInputChange({starts_at: date.toISOString()})
    },
    [handleCustomInputChange]
  )

  // Host
  const [selectedHost, setSelectedHost] = React.useState<SelectOption | null>(null)

  React.useEffect(() => {
    setSelectedHost(
      item?.host_id
        ? {value: item.host_id.toString(), label: `${item.host_email} - ${item.host_full_name}`}
        : null
    )
  }, [open, item])

  const handlHostChange = React.useCallback(
    (v: SingleValue<SelectOption>) => {
      handleCustomInputChange({host_id: v?.value ? +v?.value : null})
      setSelectedHost(v)
    },
    [handleCustomInputChange]
  )

  const asyncLoadHosts = React.useCallback(async (searchValue) => {
    try {
      const query = supabase.from('available_meeting_hosts').select('*')
      if (searchValue) query.like('email', `%${searchValue}%`)

      const {data, error} = await query
      if (error) throw error

      return data?.map((h) => ({value: h.therapist_id, label: `${h.email} - ${h.full_name}`}))
    } catch (e) {
      console.log(e)
    }
  }, [])

  // Service
  const {
    data: services,
    loading: servicesLoading,
    fetch: fetchServices,
  } = useSupabaseQuery<TherapistAssignedService>(
    React.useMemo(
      () => ({
        table: 'therapist_assigned_services',
        fields: '*',
        match: {therapist: input.host_id},
        order: 'order',
        autoRefetch: false,
        parsingFunction: parseTherapistService,
      }),
      [input.host_id]
    )
  )

  React.useEffect(() => {
    handleCustomInputChange({
      service_id: null,
      starts_at: input.host_id === item?.host_id ? item.starts_at : null,
    })
    input.host_id && fetchServices()
  }, [input.host_id]) // eslint-disable-line

  React.useEffect(() => {
    !input.service_id && services?.length && handleCustomInputChange({service_id: services[0].id})
  }, [services]) // eslint-disable-line

  const servicesOptions = React.useMemo(
    () =>
      services?.map((s) => ({
        label: `${s.name} (${s.price / 100}zł / ${formatDurationShort(s.meeting_duration)})`,
        value: s.id,
      })) ?? [],
    [services]
  )

  const handleServiceChange = React.useCallback(
    (v: SingleValue<SelectOption>) =>
      handleCustomInputChange({
        service_id: v?.value,
        starts_at: input.host_id === item?.host_id && v?.value === item?.service_id ? item.starts_at : null,
      }),
    [input, item, handleCustomInputChange]
  )

  // Participants
  const [selectedParticipants, setSelectedParticipants] = React.useState<SelectOption[]>([])
  React.useEffect(() => {
    item?.guests && setSelectedParticipants(item.guests.map((g) => ({value: g.id, label: g.email})))
  }, [item, open])

  const handleParticipantsChange = React.useCallback(
    (value: MultiValue<SelectOption>) => {
      setSelectedParticipants([...value])
      handleCustomInputChange({guests_ids: value.map((v) => v.value)})
    },
    [handleCustomInputChange]
  )

  const asyncLoadParticipants = React.useCallback(async (searchValue) => {
    try {
      const query = supabase.from('users').select('id, email')
      if (searchValue) query.like('email', `%${searchValue}%`)

      const {data, error} = await query
      if (error) throw error

      return data?.map((u) => ({value: u.id, label: u.email}))
    } catch (e) {
      console.log(e)
    }
  }, [])

  const isSubmitDisabled = React.useMemo(
    () => !input.host_id || !input.service_id || !input.name || !input.starts_at,
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj spotkanie' : 'Zaplanuj spotkanie'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading}>
              <FormLabel>Terapeuta</FormLabel>
              <AsyncSelect
                value={selectedHost}
                onChange={handlHostChange}
                loadOptions={asyncLoadHosts}
                defaultOptions={true}
                isDisabled={loading}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Rodzaj usługi</FormLabel>
              <Select
                placeholder="Brak"
                value={servicesOptions.find((o) => o.value === input.service_id) || null}
                options={servicesOptions}
                onChange={handleServiceChange}
                isLoading={servicesLoading}
                isDisabled={!input.host_id}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Uczestnicy</FormLabel>
              <AsyncSelect
                value={selectedParticipants}
                onChange={handleParticipantsChange}
                loadOptions={asyncLoadParticipants}
                isMulti={true}
                defaultOptions={true}
                isClearable={true}
                isDisabled={loading}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa</FormLabel>
              <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opis w kalendarzu</FormLabel>
              <Textarea name="description" value={input.description ?? ''} onChange={handleInputChange} />
            </FormControl>
            {input.host_id && input.service_id && (
              <TimeSlotSelector
                therapist={input.host_id}
                service={input.service_id}
                onClick={handleDateChange}
                selectedDate={input.starts_at ? new Date(input.starts_at) : undefined}
              />
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={isSubmitDisabled}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AdminMeetingEditorModal
