import React from 'react'

import {Box, Container, Heading, ListItem, OrderedList, Stack, Text} from '@chakra-ui/react'

const TermsAndConditions = () => {
  return (
    <Container maxW="container.xl" py={8}>
      <Heading as="h1" mb={4}>
        Regulamin świadczenia usług drogą elektroniczną przez Serwis Internetowy Psychoforma.pl
      </Heading>
      <Stack spacing={2} textAlign="justify">
        <Heading size="sm" pt={8}>
          I. Definicje
        </Heading>
        <Text>Użyte w Regulaminie pojęcia mają następujące znaczenie:</Text>
        <OrderedList pl={6}>
          <ListItem>
            Cena – wartość brutto wyrażona w złotych polskich, uwzględniająca podatki, w tym VAT,
            nieuwzględniająca kosztów dodatkowych, stanowiąca wynagrodzenie za Usługi wskazane w Serwisie
            Internetowym. Dokładna oferta Usług oraz ich koszt zostały wskazane pod adresem psychoforma.pl;
          </ListItem>
          <ListItem>
            Dni robocze – dni od poniedziałku do piątku z wyłączeniem dni ustawowo wolnych od pracy;
          </ListItem>
          <ListItem>
            Usługodawca – Izabela Olszewska prowadząca działalność gospodarczą pod nazwą Meblissima Izabela
            Olszewska z siedzibą przy ul. Skowrończej 36, 91–510 Łódź, NIP 7261682249. Usługodawca jest stroną
            Umowy sprzedaży Usług prezentowanych w Serwisie Internetowym, zawieranej z Klientem, zobowiązany
            do świadczenia Usług na rzecz Klienta wraz z rachunkiem.
          </ListItem>
          <ListItem>
            Klient – osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości
            prawnej, ale posiadająca zdolność prawną, korzystająca z Serwisu Internetowego;
          </ListItem>
          <ListItem>
            Konsument – osoba fizyczna dokonująca czynności prawnej z przedsiębiorcą, niezwiązanej
            bezpośrednio z jej działalnością gospodarczą lub zawodową; postanowienia dotyczące konsumentów
            stosuje się także do osoby fizycznej będącej przedsiębiorcą w rozumieniu art. 43[1] Kodeksu
            cywilnego, zawierającej umowę bezpośrednio związaną z jej działalnością gospodarczą, o ile umowa
            ta nie posiada dla tej osoby charakteru zawodowego, wynikającego w szczególności z przedmiotu
            wykonywanej przez nią działalności gospodarczej, udostępnionego na podstawie przepisów o
            Centralnej Ewidencji i Informacji o Działalności Gospodarczej;
          </ListItem>
          <ListItem>
            Konto – przydzielona danemu Klientowi część Serwisu Internetowego, za pomocą której Klient może
            dokonywać określonych działań w ramach Serwisu Internetowego;
          </ListItem>
          <ListItem>
            Łączny koszt zamówienia – podane do wiadomości Klienta wynagrodzenie, na które składają się: Cena
            oraz inne koszty, jeśli występują i zostały podane do wiadomości Konsumenta, do którego zapłaty
            zobowiązany jest Klient dokonujący Zamówienia Usług w Serwisie internetowym;
          </ListItem>
          <ListItem>
            Materiały szkoleniowe – chronione autorskimi prawami majątkowymi dokumenty i informacje,
            udostępnione Klientowi w ramach świadczenia określonej Usługi.
          </ListItem>
          <ListItem>
            Serwis Internetowy - działająca pod adresem psychoforma.pl platforma internetowa, której
            właścicielem jest Usługodawca, umożliwiająca składanie Zamówień Usług za pośrednictwem sieci
            Internet;
          </ListItem>
          <ListItem>
            Regulamin – niniejszy regulamin świadczenia usług drogą elektroniczną przez Usługodawcę;
          </ListItem>
          <ListItem>
            Usługa – usługa oferowana w Serwisie Internetowym, której opis jest dostępny przy każdej z
            prezentowanych usług, m.in. konsultacja z psychoterapeutą, warsztaty, organizacja grup wsparcia
            oraz webinary, organizowanie szkoleń na zewnętrznej platformie nextlvl.se. W ramach świadczonych
            usług Usługodawca może udostępnić Klientowi określoną dokumentację, np. w Materiały szkoleniowe.
            Świadczenie usług może wiązać się z koniecznością wykupienia przez Usługodawcę licencji;
          </ListItem>
          <ListItem>
            Zamówienie – oświadczenie woli Klienta, zmierzające do zawarcia Umowy o świadczenie usług,
            określające w szczególności rodzaj i liczbę Usług;
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          II. Postanowienia ogólne
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Regulamin określa ogólne warunki i sposób świadczenia Usług drogą elektroniczną za pomocą Serwisu
            Internetowego znajdującego się pod adresem psychoforma.pl, w tym m.in. warunki składania Zamówień
            na Usługi dostępne w Serwisie Internetowym, zasady świadczenia zamówionych Usług na rzecz Klienta,
            sposób i termin uiszczania ceny, uprawnienia związane z odstąpieniem od umowy oraz zasady
            składania reklamacji.
          </ListItem>
          <ListItem>Niniejszy Regulamin jest dostępny w witrynie internetowej psychoforma.pl</ListItem>
          <ListItem>
            Serwis Internetowy psychoforma.pl prowadzony jest przez Izabelę Olszewską prowadzącą działalność
            gospodarczą pod nazwą Meblissima Izabela Olszewska z siedzibą przy ul. Skowrończej 36, 91–510
            Łódź, NIP 7261682249.
          </ListItem>
          <ListItem>
            Kontakt z Usługodawcą odbywa się poprzez e-mail kontakt@psychoforma.pl oraz numer telefonu
            +48600701465
          </ListItem>
          <ListItem>
            Do przeglądania stron internetowych Serwisu Internetowego, w tym przeglądania usług świadczonych
            za pośrednictwem Serwisu Internetowego, niezbędne jest urządzenie końcowe (np. komputer, tablet,
            smartfon lub inne urządzenie elektroniczne) z dostępem do sieci Internet i przeglądarką
            internetową typu Internet Explorer, Microsoft Edge, Google Chrome, Mozilla Firefox, Opera lub
            Safari w aktualnej wersji. Do korzystania z niektórych funkcjonalności Serwisu Internetowego, w
            tym w szczególności składania zamówień na produkty, niezbędne jest ponadto aktywne konto poczty
            elektronicznej (e-mail) oraz włączenie w przeglądarce internetowej Cookies oraz JavaScript.
          </ListItem>
          <ListItem>
            W ramach korzystania z Serwisu Internetowego zabronione jest dostarczanie przez Klientów treści
            bezprawnych, naruszających prawo lub dobre obyczaje. Klient zobowiązany jest ponadto do
            powstrzymywania się od jakiejkolwiek aktywności, która mogłaby negatywnie wpłynąć na prawidłowe
            funkcjonowanie Serwisu Internetowego, w tym w szczególności od jakiegokolwiek ingerowania w
            zawartość Serwisu Internetowego lub jego elementy techniczne. Zabronione jest ponadto
            wykorzystywanie Serwisu Internetowego do celów sprzecznych z jego przeznaczeniem, w tym rozsyłanie
            spamu, prowadzenie na stronach internetowych Serwisu Internetowego jakiejkolwiek działalności
            komercyjnej, reklamowej lub promocyjnej.
          </ListItem>
          <ListItem>
            Klient zobowiązany jest z chwilą podjęcia czynności zmierzających do złożenia Zamówienia Usług
            oferowanych w Serwisie Internetowym do zapoznania się i przestrzegania wszystkich postanowień
            Regulaminu.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          III. Zasady korzystania z Serwisu Internetowego
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Serwis Internetowy oferuje świadczenie Usług. Oferta dotyczy wyłącznie Usług, dla których podana
            jest w Serwisie Internetowym informacja o dostępności oraz cena.
          </ListItem>
          <ListItem>Z Serwisu Internetowego mogą korzystać wyłącznie osoby pełnoletnie.</ListItem>
          <ListItem>
            Rejestracja w ramach Serwisu Internetowego jest dobrowolna, aczkolwiek konieczna do złożenia
            zamówienia na wykonanie konkretnej usługi.
          </ListItem>
          <ListItem>
            <Box mb={1}>Warunkiem do złożenia zamówienia jest:</Box>
            <OrderedList pl={6}>
              <ListItem>
                rejestracja w portalu działającym pod adresem psychoforma.pl, poprzez prawidłowe wypełnienie
                formularza rejestracyjnego i podanie niezbędnych danych. Umowa o świadczenie Usługi
                polegającej na prowadzeniu Konta w Serwisie Internetowym zawierana jest na czas nieoznaczony i
                ulega rozwiązaniu z chwilą przesłania przez Klienta żądania usunięcia Konta;
              </ListItem>
              <ListItem>
                posiadanie aktywnego Konta i aktywnego adresu e-mail, za pomocą którego dokonano rejestracji
                Konta, niezbędnego również do kontaktu m.in. w celu realizacji Zamówienia, jak np. wysłania
                potwierdzenia złożenia Zamówienia, oraz aktywnego numeru telefonu niezbędnego do
                bezpośredniego kontaktu w związku wykonaniem zamówionej Usługi;
              </ListItem>
              <ListItem>
                podanie wszystkich niezbędnych do realizacji Zamówienia danych: imienia i nazwiska, adresu
                e-mail oraz numeru telefonu kontaktowego, w przypadku wystawienia faktury VAT na rzecz
                przedsiębiorcy będącego osobą fizyczną - firmy, numeru NIP, REGON, adresu działalności
                gospodarczej;
              </ListItem>
            </OrderedList>
          </ListItem>
          <ListItem>
            <Box mb={1}>
              Usługodawca może pozbawić Klienta prawa do korzystania z Serwisu Internetowego, jak również może
              ograniczyć jego dostęp do części lub całości zasobów Serwisu Internetowego, ze skutkiem
              natychmiastowym, w przypadku naruszenia przez Klienta Regulaminu, a w szczególności, gdy Klient:
            </Box>
            <OrderedList pl={6}>
              <ListItem>
                w trakcie rejestracji w serwisie internetowym podał dane niezgodne z prawdą, wprowadzające w
                błąd lub naruszające prawa osób trzecich,
              </ListItem>
              <ListItem>
                dopuści się innych zachowań, niezgodnych z obowiązującymi przepisami prawa lub ogólnymi
                zasadami korzystania z sieci Internet lub godzące w dobre imię Usługodawcy. Osoba, która
                została pozbawiona prawa do korzystania z Serwisu Internetowego, nie może dokonać powtórnej
                rejestracji bez uprzedniej zgody Usługodawcy.
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          IV. Składanie Zamówienia
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Realizacja zamówionej Usługi inicjowana jest przez Klienta za pośrednictwem złożenia w Serwisie
            Internetowym Zamówienia. Klient może złożyć Zamówienie, jeśli zalogował się do swojego Konta w
            Serwisie Internetowym, zgodnie z zasadami opisanymi w Regulaminie.
          </ListItem>
          <ListItem>
            <Box mb={1}>Klient dokonuje wyboru Usługi w Serwisie Internetowym. Dodatkowo w przypadku:</Box>
            <OrderedList pl={6}>
              <ListItem>Warsztaty – Klient dokonuje wybory trenera;</ListItem>
              <ListItem>
                Psychoterapii – Klient dokonuje wyboru psychoterapeuty, typu i rodzaju wizyty oraz terminu.
              </ListItem>
              <ListItem>Grupy wsparcia – Klient wypełnienia formularza.</ListItem>
            </OrderedList>
          </ListItem>
          <ListItem>
            Podanie w formularzu danych dodatkowych nie jest obowiązkowe, ale wpływa na efektywność
            świadczonej usługi.
          </ListItem>
          <ListItem>
            Po dokonaniu w/w czynności, Klientowi udostępniane jest w Serwisie internetowym podsumowanie
            złożonego Zamówienia.
          </ListItem>
          <ListItem>
            Informacje o Usługach podane na stronach internetowych Serwisu internetowego, w szczególności ich
            opisy, ceny oraz informacje o osobie specjalisty wykonującego Usługę, stanowią zaproszenie do
            zawarcia Umowy, w rozumieniu art. 71 Kodeksu Cywilnego.
          </ListItem>
          <ListItem>
            Zamówienie zostaje złożone przez Klienta w formie elektronicznej i stanowi ofertę zawarcia Umowy o
            świadczenie Usług będących przedmiotem Zamówienia.
          </ListItem>
          <ListItem>
            Zawarcie Umowy o świadczenie Usług i skuteczne dokonanie Zamówienia możliwe jest jedynie w
            przypadku, gdy wybrana przez Klienta Usługa jest dostępna. Umowa zostaje zawarta wyłącznie w
            momencie, gdy Usługodawca wyśle do Klienta na podany przez niego adres e-mail, wiadomość
            zawierającą informację o potwierdzeniu przyjęcia Zamówienia. Do chwili otrzymania przez Klienta
            wiadomości, o której mowa powyżej, jego Zamówienie stanowi dla Usługodawcy ofertę zawarcia Umowy o
            świadczenie Usług i nie jest on zobowiązany do ich świadczenia na rzecz Klienta. Do czasu wysłania
            wiadomości, o której mowa powyżej, Klient może złożyć oświadczenie o jego cofnięciu.
          </ListItem>
          <ListItem>
            Składając Zamówienie dotyczące Usługi, która dodatkowo obejmuje udostępnienie Klientowi Materiałów
            szkoleniowych online, Klient składa oświadczenie, iż żąda świadczenia Usługi w zakresie
            udostępnienia mu Materiałów szkoleniowych online przed upływem terminu na złożenie oświadczenia o
            odstąpieniu od umowy zawartej na odległość oraz jest świadomy, iż utraci prawo do odstąpienia od
            umowy w tym zakresie z chwilą uzyskania dostępu do Materiałów szkoleniowych.
          </ListItem>
          <ListItem>
            Składając Zamówienie dotyczące Usługi szkoleniowej, która wymaga wykupienia licencji do
            korzystania z utworów szkoleniowych, Klient składa oświadczenie, iż żąda świadczenia mu Usługi
            wykupienia licencji do korzystania z utworów szkoleniowych przed upływem terminu na złożenie
            oświadczenia o odstąpieniu od umowy zawartej na odległość oraz jest świadomy, iż utraci prawo do
            odstąpienia od umowy w tym zakresie z chwilą wykupienia dla niego licencji do korzystania z tych
            utworów.
          </ListItem>
          <ListItem>
            Składając Zamówienie obejmujące świadczenie Usług przed upływem terminu na odstąpienie od Umowy,
            Klient składa oświadczenie, iż żąda wykonania Usługi przed upływem terminu na złożenie
            oświadczenia o odstąpieniu od umowy zawartej na odległość oraz przyjmuje do wiadomości, że z
            chwilą wykonania Usługi utraci prawo do odstąpienia od umowy.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          V. Realizacja zamówienia
        </Heading>

        <OrderedList pl={6}>
          <ListItem>
            Złożenie Zamówienia stanowi złożenie Usługodawcy przez Klienta oferty zawarcia Umowy o świadczenie
            Usług i nie stanowi zapewnienia Usługodawcy w zakresie dostępności określonych Usług.
          </ListItem>
          <ListItem>
            Dostępność Usług zależy od także od czynników losowych związanych z osobami osób świadczących
            Usługi, wobec czego Klient nie ma gwarancji stałej dostępności Usług prezentowanych w Sklepie
            internetowym. W przypadku Usługi w postaci grupy wsparcia, realizacja Usługi zależy od opinii
            specjalisty prowadzącego zajęcia grupowe.
          </ListItem>
          <ListItem>
            Na etapie podsumowania Zamówienia, Klient jest informowany o Cenie Usług, wybranych przez Klienta.
            Ceny na dalszym etapie mogą ulec zmianie.
          </ListItem>
          <ListItem>
            Po otrzymaniu Zamówienia, Usługodawca, niezwłocznie weryfikuje możliwość świadczenia określonej
            Usługi i zawiadamia o tym Klienta za pośrednictwem wiadomości email. Jeżeli realizacja Zamówienia
            jest możliwa, Usługodawca oprócz potwierdzenia wykupienia Usługi przesyła Klientowi także dane
            dostępowe do Materiałów szkoleniowych online, gdy są one objęte zamówioną Usługą.
          </ListItem>
          <ListItem>
            Jeżeli z przyczyn wskazanych w punkcie 2 realizacja całości Zamówienia nie jest możliwa,
            Usługodawca zawiadamia o tym Klienta poprzez wiadomość email. Klient może zrezygnować z całości
            Zamówienia lub zdecydować się na realizację niepełnego Zamówienia, w pozostałym zakresie. Jeżeli
            Klient nie zdecyduje się na żadną z w/w możliwości, w ciągu 24 godzin od momentu otrzymania
            wiadomości, przyjmuje się, że zrezygnował z Zamówienia. Jeżeli żadna z zamówionych Usług nie
            będzie dostępna, wówczas Zamówienie zostanie anulowane.
          </ListItem>
          <ListItem>
            Jeżeli wykonanie Usługi w określonym terminie nie jest możliwe z przyczyn niezależnych od
            Usługodawcy, w szczególności w razie choroby prowadzącego zajęcia, w takiej sytuacji Usługodawca
            proponuje Klientowi wykonanie Usługi przez inną osobę posiadającą odpowiednie kwalifikację lub
            zmianę terminu wykonania Usługi, na którą Klient może nie wyrazić zgody.
          </ListItem>
          <ListItem>
            Dokonana przez Klienta zapłata za zamówioną Usługę, która nie mogła zostać świadczona, bądź z
            której świadczenia Klient zrezygnował z przyczyn opisanych powyżej, zostanie przez Usługodawcę
            zwrócona w całości bądź w części niezwłocznie po skutecznym poinformowaniu Klienta o niemożliwości
            zrealizowania Zamówienia. Jeśli Klient dokonał płatności za Zamówienie z góry, a następnie wyraził
            zgodę na realizację Zamówienia niepełnego, bądź w innej cenie, łączny koszt zamówienia zostanie
            skorygowany.
          </ListItem>
          <ListItem>
            Umowa o świadczenie Usług zostaje zawarta z chwilą otrzymania przez Klienta wiadomości e-mail,
            zawierającej informację o wykupieniu Usługi na wskazany przez Klienta adres. Wyłącznie powyższa
            wiadomość stanowi oświadczenie o przyjęciu oferty złożonej przez Klienta (tj. Zamówienia).
          </ListItem>
          <ListItem>
            W przypadku płatności z góry za zamówiony Produkt, przyjęcie Zamówienia do realizacji nastąpi po
            uiszczeniu przez Klienta Łącznego kosztu zamówienia.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          VI. Warunki świadczenia Usług
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Usługodawca ponosi odpowiedzialność za świadczenie Usług z należytą starannością.
          </ListItem>
          <ListItem>
            Usługi są świadczone w miejscu wskazanym w opisie Usługi wskazanym na stronach internetowych
            Serwisu Internetowego lub zdalnie, za pośrednictwem sieci Internet.
          </ListItem>
          <ListItem>
            W treści opisu Usługi w Serwisie Internetowym znajduje się informacja o wysokości opłat za
            świadczenie Usługi.
          </ListItem>
          <ListItem>
            Informacja o możliwym czasie świadczenia Usługi znajduje się w treści jej opisu w Serwisie
            Internetowym, a w przypadku Usług polegających na szkoleniach lub warsztatach czas świadczenia
            Usługi zależy od indywidualnych uzgodnień Klienta z Usługodawcą.
          </ListItem>
          <ListItem>
            Z chwilą otrzymania przez Klienta wiadomości email zawierającej dane dostępowe do Materiałów
            szkoleniowych online, Klient uzyskuje dostęp do tych Materiałów i rozpoczyna się świadczenie
            Usługi w tym zakresie.
          </ListItem>
          <ListItem>
            Klient uzyskuje dostęp do Materiałów szkoleniowych przez okres jednego (1) roku licząc od daty
            dokonania zakupu określonej Usługi. Klient nie może udostępniać osobom trzecim Materiałów
            szkoleniowych, a po upływie wskazanego powyżej okresu, jest zobowiązany do trwałego usunięcia
            otrzymanych Materiałów szkoleniowych utrwalonych na wszelkich nośnikach.
          </ListItem>
          <ListItem>
            Wzięcie udziału w spotkaniu z innymi uczestnikami nie wiąże się z koniecznością udostępniania
            swoich danych takich jak imię, nazwisko lub wizerunek. Przez czas trwania spotkania Klient może
            pozostawać anonimowy. W przypadku udostępnienia swojego wizerunku, Klient wyraża zgodę na jego
            rozpowszechnianie jedynie w ramach należytego wykonania określonej Usługi przez Usługodawcę.
          </ListItem>
          <ListItem>
            Usługodawca nie ponosi odpowiedzialności za treści poruszane podczas zajęć grupowych przez ich
            uczestników, jednakże dołoży starań, aby treści te nie naruszały praw pozostałych uczestników lub
            osób trzecich.
          </ListItem>
          <ListItem>
            Usługodawca zobowiązuje się do zachowania w poufności treści przekazywanych mu przez Klientów
            podczas wykonywania Usług. Jednakże Usługodawca nie ponosi odpowiedzialności za rozpowszechnianie
            lub udostępnianie osobom trzecim treści ujawnionych podczas zajęć grupowych przez uczestników tych
            zajęć.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          VII. Sposób i termin zapłaty
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Ceny Usług zamieszczone w Serwisie internetowym podane są w złotych polskich i zawierają wszystkie
            składniki, w tym podatek VAT, cła oraz inne opłaty.
          </ListItem>
          <ListItem>
            <Box mb={1}>
              Zamówienia składane w Serwisie internetowym, mogą zostać opłacone w poniższy sposób:
            </Box>
            <OrderedList pl={6}>
              <ListItem>płatność elektroniczna za pośrednictwem serwisu internetowego Przelewy24.</ListItem>
            </OrderedList>
          </ListItem>
          <ListItem>
            W przypadku braku opłaty w ciągu 3 dni od dnia złożenia Zamówienia, Zamówienie zostaje anulowane i
            jest traktowane jako niezłożone i nieistniejące.
          </ListItem>
          <ListItem>
            Usługodawca na podany przez Klienta adres email przesyła dowód sprzedaży Usług w postaci paragonu
            fiskalnego lub faktury VAT.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          VIII. Reklamacje
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Usługodawca odpowiada względem Klienta będącego Konsumentem z tytułu rękojmi za wady, jeżeli
            Usługa została wykonana wadliwie lub z nienależytą starannością. Do postępowania w zakresie
            zgłoszonych przez Konsumenta reklamacji znajdują zastosowanie postanowienia art. 556 i nast.
            Kodeksu cywilnego.
          </ListItem>
          <ListItem>
            Klient ma prawo zgłosić reklamację do Usługodawcy w drodze elektronicznej na adres poczty
            elektronicznej kontakt@psychoforma.pl lub pisemnie na adres Meblissima Izabela Olszewska ul.
            Rewolucji 9 lok.34, 90-273 Łódź
          </ListItem>
          <ListItem>
            Reklamacja winna zawierać w szczególności opis problemu, określenie zamówienia, którego zgłoszenie
            dotyczy, a także sposób kontaktu z Klientem (adres poczty elektronicznej, numer telefonu,
            ewentualnie adres do korespondencji). Usługodawca zastrzega sobie możliwość kontaktu z Klientem
            przy wykorzystaniu podanych przez niego danych kontaktowych.
          </ListItem>
          <ListItem>Reklamacja zostanie rozpatrzona w terminie 14 dni od dnia dokonania zgłoszenia.</ListItem>
          <ListItem>
            Usługodawca podejmuje działania w celu zapewnienia poprawnego działania każdej Oferty
            internetowej, w takim zakresie, jaki wynika z aktualnej wiedzy technicznej i zobowiązuje się
            usunąć w rozsądnym terminie wszelkie nieprawidłowości zgłoszone przez Klientów. Proces zgłaszania
            i rozpatrywania reklamacji dotyczących wszelkich nieprawidłowości lub przerw w funkcjonowaniu
            danej Oferty internetowej, będzie przebiegał w sposób opisany w niniejszym dziale.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          IX. Odstąpienie od umowy
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Klient będący Konsumentem ma prawo odstąpić od umowy w terminie 14 dni bez podania jakiejkolwiek
            przyczyny. Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia zawarcia Umowy z
            zastrzeżeniem ust. IV oraz pozostałych punktów ustępu niniejszego.
          </ListItem>
          <ListItem>
            Odstąpienie od umowy nie przysługuje w przypadku umowy o świadczenie usług, jeśli Usługodawca
            wykonał w pełni usługę za wyraźną zgodą Klienta, który został poinformowany przed rozpoczęciem
            świadczenia, że po spełnieniu świadczenia utraci prawo odstąpienia od umowy. W przypadku
            rozpoczęcia wykonywania usługi przez Usługodawcę, Klient ma obowiązek zapłaty za świadczenie
            otrzymane do chwili odstąpienia. Kwotę do zapłaty oblicza się proporcjonalnie do zakresu
            spełnionego świadczenia z uwzględnieniem wynagrodzenia.
          </ListItem>
          <ListItem>
            Aby skorzystać z prawa odstąpienia od umowy, Klient musi poinformować Usługodawcę o swojej decyzji
            o odstąpieniu od niniejszej umowy w drodze jednoznacznego oświadczenia (pismo wysłane pocztą na
            adres Meblissima Izabela Olszewska ul. Rewolucji 9 lok.34, 90-273 Łódź lub pocztą elektroniczną na
            adres kontakt@psychofroma.pl).
          </ListItem>
          <ListItem>
            W przypadku odstąpienia od umowy Klientowi zostaną zwrócone wszystkie otrzymane płatności
            niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym Usługodawca został
            poinformowany o decyzji o wykonaniu prawa odstąpienia od niniejszej umowy. Zwrot płatności
            zostanie dokonany przy użyciu takich samych sposobów płatności, jakie zostały przez Klienta użyte
            w pierwotnej transakcji, chyba że Klient wyraźnie zgodził się na inne rozwiązanie.
          </ListItem>
          <ListItem>
            W przypadku wygaśnięcia uprawnienia do odstąpienia od części Umowy, Klientowi nie zwraca się ceny
            w części odpowiadającej Usłudze lub jej części, która została już wykonana.
          </ListItem>
          <ListItem>
            <Box mb={1}>
              Prawo do odstąpienia od umowy nie przysługuje Klientowi m.in. w przypadkach określonych w ust.
              IV oraz w odniesieniu do umów w przypadkach wskazanych w art. 38 Ustawy Prawa Konsumentów, w tym
              w szczególności w odniesieniu do umów o:
            </Box>
            <OrderedList pl={6}>
              <ListItem>
                świadczenia usług, jeżeli Dostawca wykonał w pełni usługę za wyraźną zgodą Konsumenta, który
                został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez
                Dostawcę utraci prawo odstąpienia od Umowy;
              </ListItem>
              <ListItem>
                w której cena lub wynagrodzenie zależy od wahań na rynku finansowym, nad którymi Dostawca nie
                sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od Umowy;
              </ListItem>
              <ListItem>
                w której przedmiotem świadczenia jest Towar nieprefabrykowany, wyprodukowany według
                specyfikacji Konsumenta lub służący zaspokojeniu jego zindywidualizowanych potrzeb;
              </ListItem>
              <ListItem>
                w której przedmiotem świadczenia jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki
                termin przydatności do użycia;
              </ListItem>
              <ListItem>
                w której przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której
                po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów
                higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;
              </ListItem>
              <ListItem>
                w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój
                charakter, zostają nierozłącznie połączone z innymi rzeczami;
              </ListItem>
              <ListItem>
                w której przedmiotem są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane
                w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;
              </ListItem>
              <ListItem>
                o dostarczanie treści cyfrowych, które nie są zapisane na nośniku materialnym, jeżeli
                spełnienie świadczenia rozpoczęło się za wyraźną zgodą Klienta przed upływem terminu do
                odstąpienia od umowy;
              </ListItem>
            </OrderedList>
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          X. Spory
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Rozstrzyganie ewentualnych sporów powstałych pomiędzy Usługodawcą a Klientem będącym Konsumentem,
            zostaje poddane sądom właściwym miejscowo zgodnie z przepisami kodeksu postępowania cywilnego.
          </ListItem>
          <ListItem>
            Klient może skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń, w
            szczególności z: Pomocy miejskich (powiatowych) rzeczników konsumentów (ich lista dostępna jest na
            stronie http://www.uokik.gov.pl/rzecznicy_konsumentow.php, Mediacji pozasądowej – gdzie strona
            trzecia, którą jest mediator lub arbiter, pomaga w osiągnięciu porozumienia bez konieczności
            angażowania wymiaru sprawiedliwości, Pomocy organizacji społecznych, do których zadań statutowych
            należy ochrona konsumentów (m.in. Federacja Konsumentów, Stowarzyszenie Konsumentów Polskich).
            Porady udzielane są pod przez Stowarzyszenie Konsumentów Polskich i Federację Konsumentów pod
            numerem infolinii konsumenckiej 801 440 220 oraz 22 290 89 16 oraz przez Stowarzyszenie
            Konsumentów Polskich pod adresem e-mail porady@dlakonsumentow.pl., Platformy internetowego systemu
            rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR).
            Pod adresem internetowym: http://ec.europa.eu/consumers/odr. Platforma ODR stanowi interaktywną i
            wielojęzyczną stronę internetową z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców
            dążących do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z
            internetowej umowy sprzedaży lub umowy o świadczenie usług.
          </ListItem>
          <ListItem>
            Zasady dostępu do procedur pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń
            są dostępne w siedzibach oraz na stronach internetowych powiatowych (miejskich) rzeczników
            konsumentów, organizacji społecznych, do których zadań statutowych należy ochrona konsumentów, pod
            adresem internetowym: http://ec.europa.eu/consumers/odr, pod następującymi adresami internetowymi
            Urzędu Ochrony Konkurencji i Konsumentów: http://www.uokik.gov.pl/spory_konsumenckie.php,
            http://www.uokik.gov.pl/sprawy_indywidualne.php oraz http://www.uokik.gov.pl/wazne_adresy.php.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          XI. Ochrona prywatności
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Usługodawca zbiera i przetwarza dane osobowe Klientów zgodnie z obowiązującymi przepisami prawa
            oraz zgodnie z Polityką Prywatności.
          </ListItem>
          <ListItem>
            Usługodawca wykorzystuje pliki cookies, zgodnie z zasadami opisanymi w Polityce Prywatności.
          </ListItem>
        </OrderedList>

        <Heading size="sm" pt={8}>
          XII. Postanowienia końcowe.
        </Heading>
        <OrderedList pl={6}>
          <ListItem>
            Ewentualne zmiany Regulaminu będą publikowane niezwłocznie w postaci ujednoliconego tekstu
            Regulaminu na stronach internetowych Serwisu Internetowego i wchodzą w życie po upływie 14 dni od
            dnia ich opublikowania.
          </ListItem>
          <ListItem>
            Klient posiadający Konto, w przypadku braku akceptacji nowej treści Regulaminu obowiązany jest
            zawiadomić o tym fakcie Usługodawcę w ciągu 14 dni od daty poinformowania o zmianie Regulaminu.
            Brak akceptacji nowej treści Regulaminu skutkuje rozwiązaniem Umowy.
          </ListItem>
          <ListItem>
            W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie mają przepisy kodeksu cywilnego
            oraz aktów prawnych regulujących prawo konsumenckie, a także inne postanowienia prawa powszechnie
            obowiązującego.
          </ListItem>
          <ListItem>Regulamin wchodzi w życie z dniem 14.11.2022.</ListItem>
        </OrderedList>
      </Stack>
    </Container>
  )
}

export default TermsAndConditions
