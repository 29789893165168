import React from 'react'

import {TNode, createPlugins} from '@udecode/plate'
import {Plate} from '@udecode/plate-core'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'

import {getComponents} from './constants'
import {EditorContext} from './editor-context'
import Toolbar from './toolbar'
import {getPlugins} from './utils'

type Props = {
  id?: string // needs to be specified especially if component renders more than one instance of ContentEditor
  value: TNode[]
  onChange?: (value: TNode[]) => void
  readOnly?: boolean
  isSaving?: boolean
  onSave?: () => void
  onCancel?: () => void
  toolbarPosition?: 'top' | 'navbar'
  surveys?: boolean
} & (
  | {
      bucketName: string
      bucketScope: number
      variant: 'full'
    }
  | {
      bucketName?: never
      bucketScope?: never
      variant: 'basic' | 'extended'
    }
)

const ContentEditor = ({
  id,
  bucketName,
  bucketScope,
  isSaving,
  onChange,
  onSave,
  value,
  surveys = false,
  readOnly = true,
  variant = 'full',
  toolbarPosition = 'top',
}: Props) => {
  const editorContext = React.useMemo(
    () => ({variant, surveys, readOnly, bucketName, bucketScope}),
    [variant, surveys, readOnly, bucketName, bucketScope]
  )

  const components = React.useMemo(() => getComponents(variant, surveys), [variant, surveys])

  const plugins = React.useMemo(
    () => createPlugins(getPlugins(variant, editorContext, surveys), {components}),
    [components, editorContext, variant, surveys]
  )

  const editableProps = React.useMemo(
    () => ({
      placeholder: readOnly ? '' : 'Wpisz jakiś tekst...',
      readOnly,
      spellCheck: true,
    }),
    [readOnly]
  )

  return (
    <EditorContext.Provider value={editorContext}>
      <DndProvider backend={HTML5Backend}>
        <Plate editableProps={editableProps} value={value} onChange={onChange} plugins={plugins} id={id}>
          {!readOnly && <Toolbar isSaving={isSaving} onSave={onSave} position={toolbarPosition} />}
        </Plate>
      </DndProvider>
    </EditorContext.Provider>
  )
}

export default ContentEditor
