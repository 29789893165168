import React from 'react'

import {Box, Container, Grid, GridItem} from '@chakra-ui/react'

import Menu from '@/admin/menu'
import Router from '@/admin/router'

const Admin = () => {
  return (
    <Container maxW="container.xl" height="100%">
      <Grid templateColumns="repeat(12, 1fr)" height="100%" minHeight="calc(100vh - 314px)">
        <GridItem colSpan={2} borderRightWidth="2px" height="100%">
          <Box mt={16} pb={12}>
            <Menu />
          </Box>
        </GridItem>
        <GridItem colSpan={10} mt={8} pb={12}>
          <Container maxW="container.xl" py={4}>
            <Router />
          </Container>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default Admin
