import * as React from 'react'

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {Link as RouterLink} from 'react-router-dom'
import {Element, scroller} from 'react-scroll'

import {SupportGroupJoined, parseSupportGroupJoined} from '@/api/models'
import CategoriesFilter from '@/common/components/categories-filter'
import Header from '@/common/components/header'
import LoadingListItem from '@/common/components/loading-list-item'
import useTableState from '@/common/data-table/use-table-state'
import NotForMe from '@/common/not-for-me'
import {Pagination, usePagination} from '@/common/pagination'
import useSupabaseQuery from '@/common/use-custom-query'
import {SUPPORT_GROUPS, SUPPORT_GROUPS_LIST} from '@/router/paths'

import header from './header.jpg'
import {SupportGroupItem} from './item'

const itemsPerPage = 6

const SupportGroups = () => {
  const tableState = useTableState({sortBy: 'created_at'})
  const {loading, data, rows} = useSupabaseQuery<SupportGroupJoined>({
    table: 'support_groups_view',
    fields: '*',
    order: 'featured_index',
    itemsPerPage,
    pageNumber: tableState.page,
    filter: tableState.filter,
    parsingFunction: parseSupportGroupJoined,
  })

  const {items} = usePagination({
    countPerPage: itemsPerPage,
    maxShownPages: 7,
    page: tableState.page + 1,
    totalCount: rows ?? 0,
  })

  const setPage = React.useCallback(
    (page: number) => {
      tableState.setPage(page - 1)
      scroller.scrollTo('support-groups-list' as any, {
        duration: 500,
        offset: -150,
        smooth: true,
      })
    },
    [tableState]
  )

  React.useEffect(() => {
    if (!data) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          item_list_id: 'support-groups-list',
          item_list_name: 'Support groups list',
          items: data.map((item) => ({
            currency: 'PLN',
            item_id: `support_group:${item.id}`,
            item_name: `Grupa wsparcia - ${item.name}`,
            value: (item.price || 0) / 100,
          })),
        },
        event: 'view_item_list',
      },
    })
  }, [data])

  return (
    <>
      <Header img={header} title="Lista grup wsparcia">
        <Text fontSize="md" fontWeight={400}>
          Chcesz zrozumieć siebie i swoje funkcjonowanie w danej sytuacji? Szukasz osób, które zmagają się z
          tym, co Ty? Kiedy dzielisz się swoimi doświadczeniami i inni robią to samo, możecie się uczyć od
          siebie i wspierać się nawzajem.
        </Text>
      </Header>

      <Element name="support-groups-list" />
      <Container maxW="container.xl" mt={[8, null, 16]}>
        <Breadcrumb mb={8} fontWeight={300}>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={SUPPORT_GROUPS}>
              Grupy Wsparcia
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
            <BreadcrumbLink as={RouterLink} to={SUPPORT_GROUPS_LIST}>
              Lista grup wsparcia
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Box mb={[8, null, 16]}>
          <CategoriesFilter tableState={tableState} table="support_group_categories" />
        </Box>

        <Flex mb={[8, null, 16]} px={[8, null, 0]}>
          <Grid
            templateColumns={['repeat(1, 1fr)', null, 'repeat(2, 1fr)']}
            columnGap={8}
            rowGap={16}
            mb={8}
            width="100%"
          >
            {loading ? (
              Array.from(Array(itemsPerPage), (_, i) => (
                <GridItem key={i}>
                  <LoadingListItem />
                </GridItem>
              ))
            ) : data?.length ? (
              data?.map((sg, i) => (
                <GridItem key={i}>
                  <SupportGroupItem item={sg} index={i} />
                </GridItem>
              ))
            ) : (
              <GridItem>
                <Text>Nie znaleziono żadnych grup wsparcia</Text>
              </GridItem>
            )}
          </Grid>
        </Flex>
        <Flex direction="row" justifyContent="center" mb={16}>
          <Pagination
            countPerPage={itemsPerPage}
            loading={loading}
            items={items}
            page={tableState.page + 1}
            setPage={setPage}
          />
        </Flex>
      </Container>
      <NotForMe title="Szukasz czegoś innego?" selected="support_groups" />
    </>
  )
}

export default SupportGroups
