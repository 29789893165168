import * as React from 'react'

import {Center} from '@chakra-ui/layout'

import LabeledSpinner from '@/common/components/labeled-spinner'

const LoadingView = ({label}: {label?: string}) => {
  return (
    <Center height="85vh">
      <LabeledSpinner label={label} />
    </Center>
  )
}

export default LoadingView
