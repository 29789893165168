import * as React from 'react'

import {useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {PGTimeRangeSlotJoined} from '@/api/models'
import {parsePgTimestampRange} from '@/api/utils'

export const useUpcomingTimeSlots = (therapist: number, service: string) => {
  const toast = useToast()

  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<PGTimeRangeSlotJoined[]>([])
  const fetch = React.useCallback(
    async (markAsLoading: boolean) => {
      try {
        markAsLoading && setLoading(true)
        const {data: result, error} = await supabase.rpc('upcoming_therapist_slots', {
          service_id: service,
          therapist_id: therapist,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        if (error) {
          throw error
        }
        if (!result) {
          throw new Error('Failed to fetch time slots')
        }
        setData(
          result
            .map((v) => ({
              ...v,
              ...(parsePgTimestampRange(v.slot_range) as PGTimeRangeSlotJoined),
            }))
            .filter((s) => !!s)
        )
      } catch (e) {
        console.error('Failed to fetch therapist time slots', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać informacji o usługach psychoterapeuty',
        })
      } finally {
        markAsLoading && setLoading(false)
      }
    },
    [therapist, service, setData, toast]
  )
  React.useEffect(() => {
    fetch(true)
  }, [service]) // eslint-disable-line

  return {
    data,
    fetch,
    loading,
  }
}
