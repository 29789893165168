import {TherapistSpecialty} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptyTherapistSpecialty = {
  name: '',
  show_as_filter: false,
} as TherapistSpecialty

export const columns: Column<TherapistSpecialty>[] = [
  {
    Header: 'Nazwa specjalności',
    id: 'name',
  },
  {
    Header: 'Pokazuj jako filtr',
    id: 'show_as_filter',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Nazwa specjalności',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Pokazuj jako filtr',
    name: 'show_as_filter',
    variant: 'boolean',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
