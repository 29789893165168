import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const ClockIcon = (props: IconProps) => (
  <Icon viewBox="0 0 60 60" {...props} fill="none">
    <path
      d="M30 13.125C30 12.6277 29.8025 12.1508 29.4508 11.7992C29.0992 11.4475 28.6223 11.25 28.125 11.25C27.6277 11.25 27.1508 11.4475 26.7992 11.7992C26.4475 12.1508 26.25 12.6277 26.25 13.125V33.75C26.2501 34.0805 26.3376 34.4051 26.5035 34.6909C26.6695 34.9767 26.908 35.2136 27.195 35.3775L40.32 42.8775C40.7507 43.1103 41.2554 43.1654 41.7262 43.031C42.197 42.8966 42.5965 42.5833 42.8394 42.1582C43.0823 41.7332 43.1493 41.2299 43.0261 40.7561C42.9029 40.2822 42.5992 39.8754 42.18 39.6225L30 32.6625V13.125Z"
      fill="currentColor"
    />
    <path
      d="M30 60C37.9565 60 45.5871 56.8393 51.2132 51.2132C56.8393 45.5871 60 37.9565 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.16071 37.9565 0 30 0C22.0435 0 14.4129 3.16071 8.7868 8.7868C3.16071 14.4129 0 22.0435 0 30C0 37.9565 3.16071 45.5871 8.7868 51.2132C14.4129 56.8393 22.0435 60 30 60ZM56.25 30C56.25 36.9619 53.4844 43.6387 48.5616 48.5616C43.6387 53.4844 36.9619 56.25 30 56.25C23.0381 56.25 16.3613 53.4844 11.4384 48.5616C6.51562 43.6387 3.75 36.9619 3.75 30C3.75 23.0381 6.51562 16.3613 11.4384 11.4384C16.3613 6.51562 23.0381 3.75 30 3.75C36.9619 3.75 43.6387 6.51562 48.5616 11.4384C53.4844 16.3613 56.25 23.0381 56.25 30Z"
      fill="currentColor"
    />
  </Icon>
)

export default ClockIcon
