import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {supabase} from '@/api'
import {SupabaseRPCResult} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import useLoadingState from '@/common/use-loading-state'
import {HOME} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'

import {resetOrderState, selectSupportGroup} from '../state'

const GroupFull = () => {
  const dispatch = useAppDispatch()
  const group = useAppSelector(selectSupportGroup)

  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      if (!group?.id) return
      const {data, error} = await supabase.rpc('add_to_support_group_queue', {
        support_group_id: group.id,
      })
      if (error) throw error
      const res = data as any as SupabaseRPCResult
      if ('error' in res) throw new Error(res.error)

      dispatch(resetOrderState())
    }, [dispatch, group]),
    {
      onErrorToast: 'Nie udało się zapisać na listę chętnych',
      onSuccessToast: 'Zapisano na listę chętnych',
    }
  )

  return (
    <Stack align="center" justify="center" spacing={16}>
      <Stack
        spacing={2}
        color="brand.gray.600"
        fontSize={['20px', null, '28px']}
        lineHeight="30px"
        textAlign="center"
      >
        <Text fontWeight={700}>Niestety, grupa jest już pełna.</Text>
        <Text>Zapisz się na listę chętnych,</Text>
        <Text>wtedy damy Ci znać, jeśli utworzy się nowa grupa o tej samej tematyce.</Text>
      </Stack>
      <Stack direction={['column', null, 'row']} spacing={8} align="center" justify="center">
        <ArrowButton
          as={Link}
          to={HOME}
          bg="brand.green.600"
          hoverBg="brand.green.700"
          height={40}
          fontSize="lg"
          fontWeight={500}
          width="320px"
          flipped={true}
          isDisabled={loading}
        >
          Wróć na stronę główną
        </ArrowButton>
        <ArrowButton
          height={40}
          fontSize="lg"
          fontWeight={500}
          width="320px"
          onClick={handleSubmit}
          isLoading={loading}
        >
          Zapisz się
        </ArrowButton>
      </Stack>
    </Stack>
  )
}

export default GroupFull
