import React from 'react'

import {Box} from '@chakra-ui/react'
import useMergedRef from '@react-hook/merged-ref'
import {
  createNodesWithHOC,
  DraggableProps,
  DragHandleProps,
  DragItemBlock,
  getDraggableStyles,
  getHoverDirection,
  getNewDirection,
  PlateRenderElementProps,
  useEditorRef,
} from '@udecode/plate'
import {createPluginFactory, findNode, isExpanded, TEditor} from '@udecode/plate-core'
import {DropTargetMonitor, useDrag, useDrop} from 'react-dnd'
import {getEmptyImage} from 'react-dnd-html5-backend'
import {Path, Transforms} from 'slate'
import {ReactEditor, useReadOnly} from 'slate-react'

export const KEY_DND = 'dnd'

const DefaultDragHandle = ({styles, ...props}: DragHandleProps) => (
  <button type="button" {...props} style={styles} />
)

const dragHandleButton = {
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  border: 'none',
  cursor: 'pointer',
  height: '18px',
  minWidth: '18px',
  outline: 'none',
  overflow: 'hidden',
  padding: 0,
}

const Draggable = (props: DraggableProps & {gutterLeftStyles?: any}) => {
  const {children, element, componentRef, gutterLeftStyles, onRenderDragHandle} = props

  const DragHandle = onRenderDragHandle ?? DefaultDragHandle

  const blockRef = React.useRef<HTMLDivElement>(null)
  const rootRef = React.useRef<HTMLDivElement>(null)
  const dragWrapperRef = React.useRef(null)
  const multiRootRef = useMergedRef(componentRef, rootRef)

  const {dropLine, dragRef, isDragging} = useDndBlock({
    blockRef: rootRef,
    id: element.id,
  })

  const multiDragRef = useMergedRef(dragRef, dragWrapperRef)

  const styles = getDraggableStyles({
    direction: dropLine,
    isDragging,
    ...props,
  })

  const stopMouseEventPropagation = React.useCallback((e: React.MouseEvent) => e.stopPropagation(), [])

  const hoverStyle = React.useMemo(() => ({'& div.plate-editor-drag-handle': {opacity: '1'}}), [])

  return (
    <Box>
      <Box position="relative" opacity={isDragging ? '.5' : '1'} ref={multiRootRef} _hover={hoverStyle}>
        <Box overflow="auto" ref={blockRef}>
          {children}
          {!!dropLine && (
            <Box
              position="absolute"
              left="0"
              right="0"
              opacity="1"
              top={dropLine === 'top' ? '0' : 'unset'}
              bottom={dropLine === 'bottom' ? '0' : 'unset'}
              backgroundColor="#b4d5ff"
              h="0.5"
              contentEditable={false}
            />
          )}
        </Box>
        <Box
          position="absolute"
          top="0"
          display="flex"
          h="full"
          opacity="0"
          className="plate-editor-drag-handle"
          transform="translateX(-100%)"
          contentEditable={false}
          style={gutterLeftStyles}
        >
          <Box display="flex" h="1.5em">
            <Box display="flex" alignItems="center" mr="1" pointerEvents="auto" ref={multiDragRef}>
              <DragHandle
                style={dragHandleButton}
                element={element}
                onMouseDown={stopMouseEventPropagation}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

interface WithDraggableOptions extends Pick<DraggableProps, 'onRenderDragHandle' | 'styles'> {
  level?: number
  filter?: (editor: TEditor, path: Path) => boolean
  allowReadOnly?: boolean
  gutterLeftStyles?: any
}

const withDraggable = (
  Component: any,
  {
    styles,
    level,
    filter,
    allowReadOnly = false,
    onRenderDragHandle,
    gutterLeftStyles,
  }: WithDraggableOptions = {}
) => {
  return React.forwardRef((props: PlateRenderElementProps, ref) => {
    const {attributes, element, editor} = props
    const readOnly = useReadOnly()
    const path = React.useMemo(() => ReactEditor.findPath(editor, element), [editor, element])

    const filteredOut = React.useMemo(
      () => (Number.isInteger(level) && level !== path.length - 1) || (filter && filter(editor, path)),
      [path, editor]
    )

    if (filteredOut || (!allowReadOnly && readOnly)) {
      return <Component {...props} />
    }

    return (
      <Draggable
        editor={editor}
        attributes={attributes}
        element={element}
        componentRef={ref}
        styles={styles}
        gutterLeftStyles={gutterLeftStyles}
        onRenderDragHandle={onRenderDragHandle}
      >
        <Component {...props} />
      </Draggable>
    )
  })
}

export const withDraggables = createNodesWithHOC(withDraggable)

const useDropBlockOnEditor = (
  editor: ReactEditor,
  {
    blockRef,
    id,
    dropLine,
    setDropLine,
  }: {
    blockRef: any
    id: string
    dropLine: string
    setDropLine: Function
  }
) => {
  return useDrop({
    accept: 'block',
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (dragItem: DragItemBlock, monitor: DropTargetMonitor) => {
      const direction = getHoverDirection(dragItem, monitor, blockRef, id)
      if (!direction) return

      const dragEntry = findNode(editor, {
        at: [],
        match: {id: dragItem.id},
      })
      if (!dragEntry) return
      const [, dragPath] = dragEntry

      ReactEditor.focus(editor)

      let dropPath: Path | undefined
      if (direction === 'bottom') {
        dropPath = findNode(editor, {at: [], match: {id}})?.[1]
        if (!dropPath) return

        if (Path.equals(dragPath, Path.next(dropPath))) return
      }

      if (direction === 'top') {
        const nodePath = findNode(editor, {at: [], match: {id}})?.[1]

        if (!nodePath) return
        dropPath = [...nodePath.slice(0, -1), nodePath[nodePath.length - 1] - 1]

        if (Path.equals(dragPath, dropPath)) return
      }

      if (direction) {
        const _dropPath = dropPath as Path

        const before = Path.isBefore(dragPath, _dropPath) && Path.isSibling(dragPath, _dropPath)
        const to = before ? _dropPath : Path.next(_dropPath)

        Transforms.moveNodes(editor, {
          at: dragPath,
          to,
        })
      }
    },
    hover(item: DragItemBlock, monitor: DropTargetMonitor) {
      const direction = getHoverDirection(item, monitor, blockRef, id)
      const dropLineDir = getNewDirection(dropLine, direction)
      if (dropLineDir) setDropLine(dropLineDir)

      if (direction && isExpanded(editor.selection)) {
        ReactEditor.focus(editor)
        Transforms.collapse(editor)
      }
    },
  })
}

const useDndBlock = ({id, blockRef, removePreview}: {id: string; blockRef: any; removePreview?: boolean}) => {
  const editor = useEditorRef()

  const [dropLine, setDropLine] = React.useState<'' | 'top' | 'bottom'>('')

  const [{isDragging}, dragRef, preview] = useDragBlock(editor, id)
  const [{isOver}, drop] = useDropBlockOnEditor(editor, {
    blockRef,
    dropLine,
    id,
    setDropLine,
  })

  // TODO: previewElement option <- comment from forked plate dnd plugin
  if (removePreview) {
    drop(blockRef)
    preview(getEmptyImage(), {captureDraggingState: true})
  } else {
    preview(drop(blockRef))
  }

  if (!isOver && dropLine) {
    setDropLine('')
  }

  return {
    dragRef,
    dropLine,
    isDragging,
  }
}

const useDragBlock = (editor: TEditor, id: string) => {
  return useDrag(
    () => ({
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: () => {
        editor.isDragging = false
        document.body.classList.remove('dragging')
      },
      item() {
        editor.isDragging = true
        document.body.classList.add('dragging')
        return {id}
      },
      type: 'block',
    }),
    []
  )
}

export const createDndPlugin = createPluginFactory({
  handlers: {
    onDrop: (editor) => () => editor.isDragging,
  },
  key: KEY_DND,
})
