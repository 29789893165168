import React from 'react'

import {Box, BoxProps} from '@chakra-ui/react'

const FormBox = ({flip, ...rest}: BoxProps & {flip?: boolean}) => (
  <Box bg="brand.green.300" rounded={flip ? '50px 0' : '0 50px'} px={[8, null, 16]} py={8} {...rest}>
    {rest.children}
  </Box>
)

export default FormBox
