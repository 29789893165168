import * as React from 'react'

import {useToast} from '@chakra-ui/react'
import {useHistory} from 'react-router-dom'

import {ADMIN_COURSES} from '@/admin/router'
import {supabase} from '@/api'
import {selectIsAdmin, selectProfile} from '@/auth/state'
import SideMenu, {SideMenuItem} from '@/common/components/side-menu'
import {SUPPORT_GROUPS_FEATURE} from '@/constants'
import {HOME} from '@/router/paths'
import {useAppSelector} from '@/store'
import {THERAPIST_PROFILE} from '@/therapist-panel/router'
import {
  USER_COURSES,
  USER_MEETINGS,
  USER_ORDERS,
  USER_PROFILE,
  USER_SETTINGS,
  USER_SUPPORT_GROUPS,
  USER_WEBINARS,
} from '@/user/router'

const Menu = () => {
  const {push} = useHistory()
  const toast = useToast()
  const isAdmin = useAppSelector(selectIsAdmin)
  const profile = useAppSelector(selectProfile)

  const handleSignOut = React.useCallback(async () => {
    try {
      const {error} = await supabase.auth.signOut()
      if (error) throw error

      toast({isClosable: true, status: 'success', title: 'Wylogowano pomyślnie'})
      push(HOME)
    } catch (e) {
      console.error('Failed to sign out', e)
      toast({isClosable: true, status: 'error', title: 'Wylogowywanie nie powiodło się'})
    }
  }, [push, toast])

  const items = React.useMemo<SideMenuItem[]>(() => {
    return [
      {
        label: 'Twój profil',
        link: USER_PROFILE,
      },
      {
        label: 'Warsztaty',
        link: USER_COURSES,
      },
      {
        label: 'Terapie - Wizyty',
        link: USER_MEETINGS,
      },
      {
        label: 'Webinary',
        link: USER_WEBINARS,
      },
      ...(SUPPORT_GROUPS_FEATURE === 'true'
        ? [
            {
              label: 'Grupy wsparcia',
              link: USER_SUPPORT_GROUPS,
            },
          ]
        : []),
      {
        label: 'Płatności',
        link: USER_ORDERS,
      },
      {
        label: 'Ustawienia',
        link: USER_SETTINGS,
      },
      ...(isAdmin
        ? [
            {
              label: 'Panel administratora',
              link: ADMIN_COURSES,
            },
          ]
        : []),
      ...(profile?.therapist_id
        ? [
            {
              label: 'Panel terapeuty',
              link: THERAPIST_PROFILE,
            },
          ]
        : []),
      {
        color: 'brand.yellow.600',
        fontWeight: 500,
        label: 'Wyloguj się',
        onClick: handleSignOut,
      },
    ]
  }, [isAdmin, profile, handleSignOut])

  return <SideMenu items={items} />
}

export default Menu
