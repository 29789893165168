import * as React from 'react'

import {Tag} from '@chakra-ui/react'

import {AdminOrderItem, OrderItemType} from '@/api/models'
import CopyToClipboardButton from '@/common/components/copy-to-clipboard-button'
import DownloadInvoicesButton from '@/common/components/download-invoices-button'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

import {mapStatusToColor, mapStatusToTranslationLabel} from '../orders/constants'
import {stringifyShippingAddress} from '../orders/utils'

const mapItemTypeToLabel: Record<OrderItemType, string> = {
  meeting: 'Spotkanie',
  course: 'Warsztat',
  tshirt: 'Koszulka',
  webinar: 'Webinar',
  support_group: 'Grupa wsparcia',
  course_meeting: 'Spotkanie',
}

export const defaultSelectedColumns = [
  'order',
  'user_email',
  'reference_type',
  'name',
  'therapists',
  'total_gross',
  'status',
]

export const columns: Column<AdminOrderItem>[] = [
  {
    Header: 'ID zamówienia',
    id: 'order',
    renderAs: 'code',
  },
  {
    Header: 'ID produktu',
    id: 'product_id',
    renderAs: 'code',
  },
  {
    Header: 'Email zamawiającego',
    id: 'user_email',
  },
  {
    Header: 'Ilość',
    id: 'amount',
  },
  {
    Header: 'Cena za szt.',
    id: 'unit_net_price',
    renderAs: 'monetary',
  },
  {
    Header: 'Kwota netto',
    id: 'total_net',
    renderAs: 'monetary',
  },
  {
    Header: 'Kwota brutto',
    id: 'total_gross',
    renderAs: 'monetary',
  },
  {
    Header: 'Kwota podatku',
    id: 'total_tax',
    renderAs: 'monetary',
  },
  {
    Header: 'Status',
    id: 'status',
    renderer: ({status}) => (
      <Tag size="md" variant="solid" colorScheme={mapStatusToColor[status]}>
        {mapStatusToTranslationLabel[status]}
      </Tag>
    ),
    toCSV: ({status}) => mapStatusToTranslationLabel[status],
  },
  {
    Header: 'Typ',
    id: 'reference_type',
    renderer: ({reference_type}) => (
      <Tag size="md" variant="solid">
        {mapItemTypeToLabel[reference_type]}
      </Tag>
    ),
    toCSV: ({reference_type}) => mapItemTypeToLabel[reference_type],
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'ID Terapeutów',
    id: 'therapists_ids',
    renderAs: 'tags',
  },
  {
    Header: 'Terapeuci',
    id: 'therapists',
    renderAs: 'tags',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Kod zniżkowy',
    id: 'discount_code',
    renderAs: 'code',
  },
  {
    Header: 'Faktura',
    id: 'invoices',
    renderer: ({invoices}) => (invoices?.length ? <DownloadInvoicesButton invoices={invoices} /> : '-'),
    sortable: false,
  },
  {
    Header: 'Adres wysyłki',
    id: 'shipping_address',
    sortable: false,
    renderer: ({shipping_address}) =>
      shipping_address ? (
        <CopyToClipboardButton value={stringifyShippingAddress(shipping_address)} />
      ) : (
        'Brak'
      ),
    toCSV: ({shipping_address}) => stringifyShippingAddress(shipping_address),
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID zamówienia',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'ID produktu',
    name: 'product_id',
    variant: 'text',
  },
  {
    label: 'Email zamawiającego',
    name: 'user_email',
    variant: 'text',
  },
  {
    label: 'Status',
    name: 'status',
    variant: 'select',
    values: Object.entries(mapStatusToTranslationLabel).map(([k, v]) => ({
      name: k,
      label: v,
    })),
  },
  {
    label: 'Typ',
    name: 'reference_type',
    variant: 'select',
    values: Object.entries(mapItemTypeToLabel).map(([k, v]) => ({
      name: k,
      label: v,
    })),
  },
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Terapeuci',
    name: 'therapists',
    variant: 'list',
  },
  {
    label: 'ID Terapeutów',
    name: 'therapists_ids',
    variant: 'list',
  },
  {
    label: 'Ilość',
    name: 'amount',
    variant: 'number',
  },
  {
    label: 'Cena za szt.',
    name: 'unit_net_price',
    variant: 'monetary',
  },
  {
    label: 'Kwota netto',
    name: 'total_net',
    variant: 'monetary',
  },
  {
    label: 'Kwota brutto',
    name: 'total_gross',
    variant: 'monetary',
  },
  {
    label: 'Kwota podatku',
    name: 'total_tax',
    variant: 'monetary',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Kod zniżkowy',
    name: 'discount_code',
    variant: 'text',
  },
  {
    label: 'Adres wysyłki',
    name: 'shipping_address',
    variant: 'exists',
  },
]
