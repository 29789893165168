import * as React from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
  InputGroup,
  InputRightAddon,
  NumberInput,
  NumberInputField,
  Switch,
} from '@chakra-ui/react'
import Select, {MultiValue, SingleValue} from 'react-select'
import AsyncSelect from 'react-select/async'

import {supabase} from '@/api'
import {DiscountCode, DiscountCodeProductType} from '@/api/models'
import useEditorModalState from '@/common/use-editor-modal'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps, SelectOption} from '@/utils/types'

import {emptyDiscountCode, productTypeOptions, productTypeToFormLabel} from '../constants'

const DiscountCodeEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: DiscountCode | null}) => {
  const {input, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<DiscountCode>({
      table: 'discount_codes',
      item,
      emptyInput: emptyDiscountCode,
      open,
      onComplete,
      onClose,
    })

  const [discountPercentageString, setDiscountPercentageString] = React.useState('')
  React.useEffect(() => setDiscountPercentageString(item?.discount_percentage.toFixed(2) ?? ''), [open, item])
  const handleDiscountPercentageChange = React.useCallback(
    (s: string) => {
      const iOfDot = s.indexOf('.')
      const v = isNaN(+s)
        ? ''
        : +s > 100
        ? '100'
        : +s < 0
        ? '0'
        : s.slice(0, iOfDot > 0 ? iOfDot + 3 : undefined)
      setDiscountPercentageString(v)
      handleCustomInputChange({discount_percentage: +v})
    },
    [handleCustomInputChange]
  )

  const handleUsesLeftChange = React.useCallback(
    (_, n: number) => {
      handleCustomInputChange({uses_left: isNaN(n) ? null : n})
    },
    [handleCustomInputChange]
  )

  // Constraining discount codes to certain products

  const handleProductTypeChange = React.useCallback(
    (v: SingleValue<SelectOption<DiscountCodeProductType>>) => {
      handleCustomInputChange({product_type: v?.value})
      setSelectedProducts([])
      handleCustomInputChange({products_ids: []})
    },
    [handleCustomInputChange]
  )

  const [selectedProducts, setSelectedProducts] = React.useState<SelectOption[]>([])
  const handleProductsChange = React.useCallback(
    (value: MultiValue<SelectOption>) => {
      setSelectedProducts([...value])
      handleCustomInputChange({products_ids: [...value.map((v) => v.value)]})
    },
    [handleCustomInputChange]
  )

  const {handleSubmit: fetchInitialProducts, loading: productsLoading} = useLoadingState(
    React.useCallback(async () => {
      if (!item?.product_type || !item?.products_ids.length) {
        setSelectedProducts([])
        return
      }

      try {
        const query = supabase
          .from('discount_code_available_products')
          .select('*')
          .eq('type', item.product_type)
          .in('id', item.products_ids)

        const {data, error} = await query
        if (error) throw error

        setSelectedProducts(data.map((v) => ({label: v.name, value: v.id})))
      } catch (e) {
        console.error('Failed to fetch initial products', e)
      }
    }, [item])
  )
  React.useEffect(() => {
    fetchInitialProducts()
  }, [fetchInitialProducts]) // eslint-disable-line

  const asyncLoadAvailableProducts = React.useCallback(
    async (searchValue) => {
      try {
        const query = supabase
          .from('discount_code_available_products')
          .select('*')
          .match({type: input.product_type})
        if (searchValue) query.like('name', `%${searchValue}%`)

        const {data, error} = await query
        if (error) throw error

        return data.map((v) => ({label: v.name, value: v.id}))
      } catch (e) {
        console.error('Failed to load products options', e)
      }
    },
    [input.product_type]
  )
  // cant use defaultOptions={true} because it won't refresh on product_type change
  const [defaultOptions, setDefaultOptions] = React.useState<SelectOption[]>([])
  React.useEffect(() => {
    ;(async () => {
      const opts = await asyncLoadAvailableProducts('')
      setDefaultOptions(opts ?? [])
    })()
  }, [asyncLoadAvailableProducts])

  const isSubmitDisabled = React.useMemo(() => !input.code || !input.discount_percentage, [input])

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj kod zniżkowy' : 'Utwórz kod zniżkowy'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading}>
              <FormLabel>Kod zniżkowy</FormLabel>
              <Input name="code" value={input.code ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="is_active"
                  name="is_active"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={input.is_active}
                  onChange={handleInputChange}
                />
                <FormLabel htmlFor="is_active">Aktywny?</FormLabel>
              </HStack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Zniżka</FormLabel>
              <InputGroup>
                <NumberInput
                  w="100px"
                  min={0}
                  max={100}
                  precision={2}
                  onChange={handleDiscountPercentageChange}
                  value={discountPercentageString}
                >
                  <NumberInputField borderRightRadius={0} />
                </NumberInput>
                <InputRightAddon children="%" />
              </InputGroup>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Pozostała liczba użyć</FormLabel>
              <NumberInput
                min={0}
                precision={0}
                onChange={handleUsesLeftChange}
                value={input.uses_left ?? ''}
              >
                <NumberInputField placeholder="Brak limitu" />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opis</FormLabel>
              <Textarea name="description" value={input.description ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl>
              <FormLabel>Typ produktów, na które działa kod</FormLabel>
              <Select
                placeholder="Wszystkie"
                value={productTypeOptions?.filter((s) => s.value === input.product_type)}
                options={productTypeOptions}
                onChange={handleProductTypeChange}
                isClearable={true}
              />
            </FormControl>
            {input.product_type && (
              <FormControl>
                <FormLabel>{productTypeToFormLabel[input.product_type]}</FormLabel>
                <AsyncSelect
                  placeholder="Wszystkie"
                  isMulti={true}
                  isClearable={true}
                  defaultOptions={defaultOptions}
                  isDisabled={loading}
                  isLoading={productsLoading}
                  value={selectedProducts}
                  onChange={handleProductsChange}
                  loadOptions={asyncLoadAvailableProducts}
                />
              </FormControl>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={isSubmitDisabled}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DiscountCodeEditorModal
