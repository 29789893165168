import React from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Switch,
  Textarea,
} from '@chakra-ui/react'

import {BlogPost} from '@/api/models'
import useEditorModalState from '@/common/use-editor-modal'
import {CommonModalProps} from '@/utils/types'

import {emptyBlogPost, MIN_LENGHT_NAME} from '../constants'

const UpsertBlogPostModal = ({
  open,
  onClose,
  onComplete,
  item,
}: CommonModalProps & {
  item: BlogPost | null
}) => {
  const {input, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<BlogPost>({
      table: 'blog_posts',
      item,
      emptyInput: emptyBlogPost,
      open,
      onComplete,
      onClose,
    })

  const handlePublishedChange = React.useCallback(
    ({target: {checked}}) => {
      handleCustomInputChange({published_at: checked ? new Date() : null})
    },
    [handleCustomInputChange]
  )

  const handleFeaturedIndexChange = React.useCallback(
    (v: string) => {
      handleCustomInputChange({featured_index: +v})
    },
    [handleCustomInputChange]
  )

  const nameError = React.useMemo(() => !!input.name && input.name.length < MIN_LENGHT_NAME, [input.name])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.language || nameError,
    [input, nameError]
  )

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj post' : 'Utwórz nowy post'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl isInvalid={nameError} isDisabled={loading} isRequired={true}>
              <FormLabel mb={1} fontWeight={600}>
                Nazwa postu
              </FormLabel>
              <Input name="name" value={input.name} onChange={handleInputChange} placeholder="Nazwa" />
              <FormErrorMessage>{`Nazwa musi mieć co najmniej ${MIN_LENGHT_NAME} znaków.`}</FormErrorMessage>
            </FormControl>
            <FormControl isDisabled={loading} isRequired={true}>
              <FormLabel mb={1} fontWeight={600}>
                Język postu
              </FormLabel>
              <Select
                name="language"
                placeholder="Wybierz język"
                value={input.language}
                onChange={handleInputChange}
              >
                <option value="pl">Polski</option>
                <option value="en">Angielski</option>
              </Select>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel mb={1} fontWeight={600}>
                Opublikowany
              </FormLabel>
              <Switch
                pl="6"
                colorScheme="whatsapp"
                checked={!!input?.published_at}
                onChange={handlePublishedChange}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel mb={1}>Indeks wyróżnienia</FormLabel>
              <NumberInput
                onChange={handleFeaturedIndexChange}
                precision={0}
                value={input?.featured_index ?? undefined}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel mb={1} fontWeight={600}>
                Opis meta
              </FormLabel>
              <Textarea
                name="meta_description"
                value={input?.meta_description ?? ''}
                onChange={handleInputChange}
              />
            </FormControl>
          </Stack>
        </ModalBody>

        <ModalFooter>
          <HStack>
            <Button onClick={handleUpsert} disabled={isSubmitDisabled} isLoading={loading}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UpsertBlogPostModal
