import * as React from 'react'

import {Redirect, Route, Switch, useLocation} from 'react-router-dom'

import useMetaTags from '@/common/use-meta-tags'
import GoogleCalendarAuthCallback from '@/therapist-panel/calendar/callback'

import {
  ABOUT_US,
  ADMIN_COURSE_PAGE,
  ADMIN_PREFIX,
  BLOG,
  BLOG_POST,
  CONTACT_US,
  COURSES,
  COURSE_PAGE,
  COURSE_SHOWCASE,
  FAQ,
  HOME,
  JOIN_US,
  MEETING,
  MEETING_INVITE,
  ORDER_PROCESSING,
  PRIVACY_POLICY,
  PSYCHOTHERAPISTS,
  PSYCHOTHERAPY,
  PSYCHOTHERAPY_THERAPIST,
  RESET_PASSWORD,
  SUPPORT_GROUPS,
  SUPPORT_GROUPS_LIST,
  SUPPORT_GROUP_SHOWCASE,
  TERMS_AND_CONDITIONS,
  THERAPIST_GOOGLE_CALLBACK,
  THERAPIST_PANEL,
  USER_PREFIX,
  WEBINAR,
  WEBINAR_VIEW,
} from './paths'
import ProtectedRoute from './protected-route'

export default () => {
  // injects appropriate meta tags depending on the current path
  // empty object indicates that values are obtained from @/common/use-meta-tags/constants
  useMetaTags({})

  // scroll to top on page change
  const {pathname} = useLocation()
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Switch>
      <ProtectedRoute path={USER_PREFIX} component={require('@/user').default} />
      <ProtectedRoute
        route="TherapistPanel"
        path={THERAPIST_PANEL}
        component={require('@/therapist-panel').default}
      />

      <Route path={HOME} exact={true} component={require('@/home').default} />
      <Route path={RESET_PASSWORD} exact={true} component={require('@/auth/reset-password').default} />
      <Route path={PRIVACY_POLICY} exact={true} component={require('@/privacy-policy').default} />
      <Route path={TERMS_AND_CONDITIONS} exact={true} component={require('@/terms-and-conditions').default} />
      <Route path={ABOUT_US} exact={true} component={require('@/about-us').default} />
      <Route path={FAQ} exact={true} component={require('@/faq/viewer').default} />
      <Route path={JOIN_US} exact={true} component={require('@/join-us').default} />
      <Route path={CONTACT_US} exact={true} component={require('@/contact-us').default} />

      {/* Blog */}
      <Route path={BLOG} exact={true} component={require('@/blog/list').default} />
      <Route path={BLOG_POST} exact={true} component={require('@/blog/viewer').default} />

      {/* Courses */}
      <Route path={COURSES} exact={true} component={require('@/courses/list').default} />
      <ProtectedRoute path={COURSE_PAGE} exact={true} component={require('@/courses/viewer').UserCourse} />
      <Route path={COURSE_SHOWCASE} exact={true} component={require('@/courses/showcase').default} />
      <ProtectedRoute
        route="AdminPanel"
        path={ADMIN_COURSE_PAGE}
        exact={true}
        component={require('@/courses/viewer').AdminCourse}
      />

      {/* Psychotherapy */}
      <ProtectedRoute
        path={THERAPIST_GOOGLE_CALLBACK}
        render={(props) => <GoogleCalendarAuthCallback calendarType="user" {...props} />}
      />
      <Route
        path={PSYCHOTHERAPY_THERAPIST}
        exact={true}
        component={require('@/psychotherapy/therapists/showcase').default}
      />
      <Route path={WEBINAR} component={require('@/psychotherapy/webinars/showcase').default} />
      <Route path={WEBINAR_VIEW} component={require('@/psychotherapy/webinars/view').default} />

      <ProtectedRoute path={MEETING} component={require('@/psychotherapy/meetings/view').default} />
      <Route path={MEETING_INVITE} component={require('@/psychotherapy/meetings/invitation').default} />
      <Route path={PSYCHOTHERAPISTS} component={require('@/psychotherapy/therapists').default} />
      <Route path={PSYCHOTHERAPY} component={require('@/psychotherapy/landing').default} />

      <Route path={SUPPORT_GROUPS} component={require('@/psychotherapy/support-groups/landing').default} />
      <Route path={SUPPORT_GROUPS_LIST} component={require('@/psychotherapy/support-groups/list').default} />
      <Route
        path={SUPPORT_GROUP_SHOWCASE}
        component={require('@/psychotherapy/support-groups/showcase').default}
      />

      <ProtectedRoute route="AdminPanel" path={ADMIN_PREFIX} component={require('@/admin').default} />

      <ProtectedRoute path={ORDER_PROCESSING} component={require('@/order-processing').default} />

      <Route component={() => <Redirect to={{pathname: HOME}} />} />
    </Switch>
  )
}
