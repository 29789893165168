import * as React from 'react'

import {useToast} from '@chakra-ui/toast'
import {TNode} from '@udecode/plate-core'
import randomstring from 'randomstring'

import {supabase} from '@/api'
import LabeledSpinner from '@/common/components/labeled-spinner'
import ContentEditor from '@/common/content-editor'
import {EDITOR_BLOCK_ID_LENGTH, initialValue} from '@/common/content-editor/constants'
import {UpdateResult} from '@/common/content-editor/types'
import {toPayload} from '@/common/content-editor/utils'
import {SUPABASE_BLOG_POST_CONTENT_BUCKET} from '@/constants'

type Props = {
  blogPostID: number
  readOnly: boolean
}

const BlogPostEditor = ({blogPostID, readOnly}: Props) => {
  const toast = useToast()

  const [value, setValue] = React.useState<TNode[]>([])
  const [isLoading, setIsLoading] = React.useState<boolean>(true)

  React.useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const {data, error} = await supabase.rpc('get_blog_post_contents', {
          blog_post_id: blogPostID,
        })
        if (error) {
          throw error?.message || error
        }
        const result = data as TNode[]
        let value
        if (!result || result.length === 0) {
          value = initialValue
        } else {
          value = result
        }
        setValue(value.map((v) => ({...v, id: v.id || randomstring.generate(EDITOR_BLOCK_ID_LENGTH)})))
      } catch (e) {
        console.error('Failed to fetch blog post contents', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać zawartości postu.',
        })
      } finally {
        setIsLoading(false)
      }
    })()
  }, [blogPostID, setIsLoading, setValue, toast])

  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const onSave = React.useCallback(async () => {
    try {
      setIsSaving(true)

      const {data, error} = await supabase.rpc('update_blog_post_content', {
        blog_post_id: blogPostID,
        payload: toPayload(value),
      })
      if (error) {
        throw error?.message || error
      }
      if (!data) {
        throw new Error(`Invalid saving result: ${data}`)
      }
      const result = data as any as UpdateResult
      if (result.error) {
        throw result.error
      }

      toast({
        isClosable: true,
        status: 'success',
        title: 'Zapisano zawartość postu.',
      })
    } catch (e) {
      console.error('Failed to save blog post contents', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się zapisać zawartości postu.',
      })
    } finally {
      setIsSaving(false)
    }
  }, [blogPostID, value, setIsSaving, toast])

  if (isLoading) {
    return <LabeledSpinner label="Ładowanie postu..." />
  }

  return (
    <ContentEditor
      id={`blog-viewer-content-${blogPostID}`}
      bucketName={SUPABASE_BLOG_POST_CONTENT_BUCKET}
      bucketScope={blogPostID}
      variant="full"
      value={value}
      onChange={setValue}
      readOnly={readOnly}
      isSaving={isSaving}
      onSave={onSave}
      toolbarPosition="navbar"
    />
  )
}

export default BlogPostEditor
