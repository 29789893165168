import {AdminMeeting, MeetingRole} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'
import {SelectOption} from '@/utils/types'

export const meetingRoleToLabel: Record<MeetingRole, string> = {
  guest: 'Gość',
  host: 'Prowadzący',
}

export const meetingRolesOptions: SelectOption<MeetingRole>[] = Object.entries(meetingRoleToLabel).map(
  ([k, v]) => ({
    value: k as MeetingRole,
    label: v,
  })
)

export const emptyAdminMeeting: AdminMeeting = {
  id: '',
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  starts_at: null,
  ends_at: new Date().toISOString(),
  name: '',
  description: '',
  google_calendar_id: null,
  service_id: null,
  service_name: null,
  host_id: null,
  host_email: null,
  host_full_name: null,
  guests: [],
  guests_ids: [],
  guests_emails: [],
}

export const defaultSelectedColumns = [
  'name',
  'starts_at',
  'ends_at',
  'opis',
  'description',
  'service_name',
  'host_full_name',
  'guests_emails',
]

export const columns: Column<AdminMeeting>[] = [
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
    sortable: false,
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Prowadzący',
    id: 'host_full_name',
  },
  {
    Header: 'ID prowadzącego',
    id: 'host_therapist_id',
    renderAs: 'code',
  },
  {
    Header: 'E-mail prowadzącego',
    id: 'host_email',
  },
  {
    Header: 'Początek',
    id: 'starts_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Koniec',
    id: 'ends_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Uczestnicy',
    id: 'guests_emails',
    renderAs: 'tags',
  },
  {
    Header: 'Opis',
    id: 'description',
    renderAs: 'longString',
  },
  {
    Header: 'Nazwa usługi',
    id: 'service_name',
  },
  {
    Header: 'ID usługi',
    id: 'service_id',
    renderAs: 'code',
    sortable: false,
  },

  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'text',
  },
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Opis',
    name: 'description',
    variant: 'text',
  },
  {
    label: 'ID usługi',
    name: 'service_id',
    variant: 'text',
  },
  {
    label: 'Nazwa usługi',
    name: 'service_name',
    variant: 'text',
  },
  {
    label: 'Początek',
    name: 'starts_at',
    variant: 'datetime',
  },
  {
    label: 'Koniec',
    name: 'ends_at',
    variant: 'datetime',
  },
  {
    label: 'Prowadzący',
    name: 'host_full_name',
    variant: 'text',
  },
  {
    label: 'ID prowadzącego',
    name: 'host_id',
    variant: 'number',
  },
  {
    label: 'E-mail prowadzącego',
    name: 'host_email',
    variant: 'text',
  },
  {
    label: 'ID uczestników',
    name: 'guests_ids',
    variant: 'list',
  },
  {
    label: 'E-maile uczestników',
    name: 'guests_emails',
    variant: 'list',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
