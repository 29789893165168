const colors = {
  brand: {
    apple: {
      500: '#494949',
      600: '#646464',
      700: '#646464',
    },
    gray: {
      100: '#EEEEEE',
      200: '#DCE2E1',
      300: '#BEBEBE',
      400: '#A9A9A9',
      500: '#646464',
      600: '#0B132A',
    },
    green: {
      100: '#E7F0EE',
      200: '#E0ECEA',
      300: '#D6E6E4',
      350: '#B2CCC7',
      400: '#8EB2AA',
      500: '#0F6C5C', // 500 is used by chakra in colorScheme props
      600: '#0F6C5C',
      700: '#084036',
      800: '#07332C',
    },
    silver: {
      500: '#858995',
    },
    yellow: {
      300: '#F9F2E6',
      400: '#EFDEBD',
      500: '#E3C58C',
      600: '#D9B166',
      700: '#CB932B',
    },
  },
}

export default colors
