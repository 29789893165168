import React from 'react'

import {TimeIcon} from '@chakra-ui/icons'
import {FaCalendarAlt, FaMoneyBillWave, FaRegCheckSquare, FaTag} from 'react-icons/fa'

import {ADMIN_MEETINGS, ADMIN_ORDER_ITEMS} from '@/admin/router'
import {AdminTherapist} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'

import {generateFilterURL} from '../utils'

export const specialtiesAction = (
  onClick: (item: AdminTherapist) => void
): DataTableAction<AdminTherapist> => ({
  icon: <FaTag />,
  id: 'specialties',
  onClick,
  tooltip: 'Edytuj specjalności',
})

export const certificatesAction = (
  onClick: (item: AdminTherapist) => void
): DataTableAction<AdminTherapist> => ({
  icon: <FaRegCheckSquare />,
  id: 'certificates',
  onClick,
  tooltip: 'Edytuj certyfikaty',
})

export const showCalendarAction = (
  onClick: (item: AdminTherapist) => void
): DataTableAction<AdminTherapist> => ({
  icon: <FaCalendarAlt />,
  id: 'calendar',
  onClick,
  tooltip: 'Kalendarz',
})

export const showSoldProductsAction: DataTableAction<AdminTherapist> = {
  icon: <FaMoneyBillWave />,
  id: 'showSoldProducts',
  to: (item: AdminTherapist) =>
    generateFilterURL(ADMIN_ORDER_ITEMS, {
      combinator: 'and',
      rules: [{field: 'therapists_ids', operator: 'ov', value: item.id}],
    }),
  tooltip: 'Zobacz sprzedane usługi',
}

export const showMeetingsAction: DataTableAction<AdminTherapist> = {
  icon: <TimeIcon />,
  id: 'showMeetings',
  to: (item: AdminTherapist) =>
    generateFilterURL(ADMIN_MEETINGS, {
      combinator: 'and',
      rules: [{field: 'host_id', operator: 'eq', value: item.id}],
    }),
  tooltip: 'Zobacz spotkania',
}
