import React from 'react'

import {DragHandleIcon} from '@chakra-ui/icons'

import {withDraggables} from './plugins/dnd'

const withStyledDraggables = (components: any) => {
  return withDraggables(components, [
    {
      keys: ['ol', 'p', 'ul'],
      level: 0,
    },
    {
      keys: [
        'attachment',
        'blockquote',
        'heading-one',
        'heading-two',
        'image',
        'ol',
        'p',
        'survey',
        'ul',
        'vimeo',
        'youtube',
        'canva',
        'typeform',
      ],
      onRenderDragHandle: ({styles, ...props}) => (
        <button {...props} style={styles}>
          <DragHandleIcon />
        </button>
      ),
    },
    {
      gutterLeftStyles: {
        marginTop: '6px',
      },
      keys: ['heading-one', 'heading-two', 'survey'],
    },
    {
      gutterLeftStyles: {
        marginTop: '-2px',
      },
      key: 'p',
    },
    {
      gutterLeftStyles: {
        marginTop: '20px',
      },
      key: 'blockquote',
    },
    {
      gutterLeftStyles: {
        marginTop: '-4px',
      },
      keys: ['vimeo', 'image', 'youtube'],
    },
    {
      gutterLeftStyles: {
        marginTop: '6px',
      },
      key: 'attachment',
    },
  ])
}

export default withStyledDraggables
