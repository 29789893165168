import * as React from 'react'

import {Button, HStack, Stack, Text, useDisclosure} from '@chakra-ui/react'

import {OrderDiscountCode} from '@/api/models'

import DiscountCodeModal from './modal'

export type DiscountCodeInputProps = {
  value?: OrderDiscountCode
  disabled?: boolean
  onChange: (v?: OrderDiscountCode) => void
  doesApply?: (code: OrderDiscountCode) => boolean
}

const DiscountCodeInput = ({value, disabled, onChange, doesApply}: DiscountCodeInputProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const handleRemove = React.useCallback(() => onChange(), [onChange])

  return (
    <>
      <DiscountCodeModal
        value={value?.code}
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={onChange}
        doesApply={doesApply}
      />

      <Stack align="flex-start">
        {value && (
          <Stack spacing={0}>
            <Text>
              {value.code} -{value.discount_percentage}%
            </Text>
            {value?.description && <Text fontSize="md">{value.description}</Text>}
          </Stack>
        )}
        <HStack>
          <Button variant="brandLink" onClick={onOpen} isDisabled={disabled} fontWeight={500}>
            {value ? 'Zmień' : 'Dodaj'}
          </Button>
          {value && (
            <Button variant="brandLink" onClick={handleRemove} isDisabled={disabled} fontWeight={500}>
              Usuń
            </Button>
          )}
        </HStack>
      </Stack>
    </>
  )
}

export default DiscountCodeInput
