import React, {useCallback, useRef} from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {CourseUser, CourseUserAccessManagement} from '@/api/models'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

const DeleteCourseUserDialog = ({
  open,
  onClose,
  onComplete,
  courseUser,
}: CommonModalProps & {
  courseUser: CourseUserAccessManagement | null
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const handleDelete = useCallback(async () => {
    try {
      if (!courseUser) {
        throw new Error()
      }

      const query = supabase
        .from('course_users')
        .delete()
        .eq('course', courseUser.course)
        .eq('role', courseUser.role)

      // one of 'group' and 'role' always is null
      if (courseUser.user) {
        query.eq('user', courseUser.user)
        query.is('group', null)
      } else {
        query.is('user', null)
        query.eq('group', courseUser.group)
      }

      if (courseUser.payment) {
        query.eq('payment', courseUser.payment)
      } else {
        query.is('payment', null)
      }

      const {error} = await query
      if (error) {
        throw error
      }
      toast({
        isClosable: true,
        status: 'success',
        title: 'Usunięto użytkownika z kursu.',
      })
      onComplete && onComplete()
    } catch (e) {
      console.error(`Failed to remove ${courseUser?.role || 'user'} from the course`, e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się usunąć użytkownika z kursu',
      })
    } finally {
      onClose()
    }
  }, [courseUser, onClose, onComplete, toast])
  const {loading, handleSubmit} = useLoadingState(handleDelete)

  return (
    <AlertDialog isOpen={open} leastDestructiveRef={cancelButtonRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {courseUser?.role === 'course_author'
              ? 'Usuń autora'
              : courseUser?.role === 'coach'
              ? 'Usuń prowadzącego szkolenie'
              : 'Usuń użytkownika'}
          </AlertDialogHeader>

          <AlertDialogBody>
            Chcesz usunąć <b>{courseUser?.email}</b>?
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelButtonRef} isDisabled={loading} onClick={onClose}>
              Anuluj
            </Button>
            <Button colorScheme="red" isLoading={loading} onClick={handleSubmit} ml={3}>
              Usuń
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DeleteCourseUserDialog
