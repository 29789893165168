import React from 'react'

import {Container, Flex, Stack, Text, Spinner} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import {PublicTherapist} from '@/api/models'
import useTableState from '@/common/data-table/use-table-state'
import useSupabaseQuery from '@/common/use-custom-query'

import Filter, {TherapistsFilter} from './filter'
import TherapistItem from './item'

const TherapistsList = () => {
  const tableState = useTableState()
  const {data: therapists, loading} = useSupabaseQuery<PublicTherapist>({
    table: 'public_therapists',
    fields: '*',
    order: 'featured_index',
    filter: tableState.filter,
  })

  const [filter, setFilter] = React.useState<TherapistsFilter>({specialties: []})
  const handleFilterChange = React.useCallback(
    (value: TherapistsFilter) => {
      setFilter((prev) => ({...prev, ...value}))
      tableState.setFilter((b) => {
        if (value.service?.id) b = b.overlaps('services', [value.service?.id])
        if (value.specialties.length) b = b.overlaps('specialties', value.specialties)
        return b
      })
    },
    [tableState]
  )

  React.useEffect(() => {
    if (loading || !therapists || !filter.service) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          item_list_id: 'psychotherapists-list',
          item_list_name: 'Psychotherapists list',
          items: therapists.map((item) => ({
            currency: 'PLN',
            item_id: `meeting:${item.id}:${filter.service?.id}`,
            item_name: `Psychoterapeuta - ${item.full_name} - ${filter.service?.name}`,
            value: (filter.service?.price || 0) / 100,
          })),
        },
        event: 'view_item_list',
      },
    })
  }, [loading, therapists, filter])

  return (
    <>
      <Container maxW="container.xl">
        <Filter value={filter} onChange={handleFilterChange} />
        {loading || !filter.service ? (
          <Flex alignItems="center" justifyContent="center" pb={16}>
            <Spinner size="xl" />
          </Flex>
        ) : (
          !!filter.service && (
            <Stack direction="column" spacing={16} mb={16}>
              {therapists?.length ? (
                therapists?.map((therapist, index) => (
                  <TherapistItem key={index} therapist={therapist} service={filter.service!.id} />
                ))
              ) : (
                <Text textAlign="center">Nie znaleziono terapeutów spełniających określone wymagania</Text>
              )}
            </Stack>
          )
        )}
      </Container>
    </>
  )
}

export default TherapistsList
