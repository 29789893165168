import * as React from 'react'

import {CloseIcon} from '@chakra-ui/icons'
import {Center, HStack, Image, Radio, RadioGroup, Stack, Text} from '@chakra-ui/react'

import {useAppSelector, useAppDispatch} from '@/store'

import {
  selectOrderCoach,
  selectOrderModel,
  selectOrderWithMeetings,
  setOrderCoach,
  setOrderWithMeetings,
} from '../state'
import FormBox from './form-box'

const CoachInput = () => {
  const dispatch = useAppDispatch()
  const course = useAppSelector(selectOrderModel)
  const coach = useAppSelector(selectOrderCoach)
  const withMeetings = useAppSelector(selectOrderWithMeetings)

  const handleCoachChange = React.useCallback(
    (v: string) => {
      if (v === 'without') {
        dispatch(setOrderWithMeetings(false))
        return
      }

      dispatch(setOrderWithMeetings(true))
      dispatch(setOrderCoach(+v))
    },
    [dispatch]
  )

  const radioValue = React.useMemo(() => (withMeetings ? coach : 'without'), [coach, withMeetings])

  const priceForMeetings = React.useMemo(
    () => ((course?.price_with_meetings || 0) - (course?.base_price || 0)) / 100,
    [course]
  )

  if (!course || !course.meetings_count || course?.nextlvl_id || !course?.coaches?.length) return null

  return (
    <FormBox>
      <Stack direction="column" spacing={2}>
        <RadioGroup value={radioValue} onChange={handleCoachChange}>
          <Stack>
            <HStack spacing={1} justify="space-between">
              <Radio borderColor="brand.green.600" colorScheme="brand.green" value="without">
                <HStack spacing={4} pl={4}>
                  <Center boxSize="60px">
                    <CloseIcon />
                  </Center>
                  <Text fontWeight={600}>Bez spotkań</Text>
                </HStack>
              </Radio>
              {!!priceForMeetings && withMeetings && (
                <Text color="brand.green.600">-{priceForMeetings}zł</Text>
              )}
            </HStack>
            {course?.coaches?.map((coach, i) => (
              <HStack key={i} justify="space-between">
                <Radio borderColor="brand.green.600" colorScheme="brand.green" value={coach.therapist_id}>
                  <HStack spacing={4} pl={4}>
                    <Image src={coach.signed_avatar ?? ''} w="60px" h="60px" rounded="full" />
                    <Stack spacing={0}>
                      <Text fontWeight={600}>{coach.full_name}</Text>
                      <Text fontWeight={300} color="brand.gray.600">{`${course.meetings_count} ${
                        course.meetings_count === 1
                          ? 'spotkanie'
                          : course.meetings_count! < 5
                          ? 'spotkania'
                          : 'spotkań'
                      }`}</Text>
                    </Stack>
                  </HStack>
                </Radio>
                {!!priceForMeetings && !withMeetings && (
                  <Text color="brand.green.600">+{priceForMeetings}zł</Text>
                )}
              </HStack>
            ))}
          </Stack>
        </RadioGroup>
      </Stack>
    </FormBox>
  )
}

export default CoachInput
