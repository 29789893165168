import * as React from 'react'

import {IconButton, Tooltip} from '@chakra-ui/react'
import {Link, useHistory} from 'react-router-dom'

import {DataTableAction} from './types'

type Props<T extends {}> = {
  action: DataTableAction<T>
  item: T
}

const ActionButton = <T extends {}>({action, item}: Props<T>) => {
  const {push} = useHistory()
  const handleClick = React.useCallback(
    () => ('onClick' in action ? action.onClick(item) : push(action.to(item))),
    [push, item, action]
  )

  return (
    <Tooltip label={action.tooltip}>
      <IconButton size="sm" aria-label="edit-button" onClick={handleClick}>
        {action.icon}
      </IconButton>
    </Tooltip>
  )
}

export default ActionButton
