import * as React from 'react'

import {Text, Stack, AspectRatio, Image} from '@chakra-ui/react'

import AddressInput from './address-input'
import DiscountCodeInput, {DiscountCodeInputProps} from './discount-code-input'
import SummaryItem, {SummaryItemProps} from './item'

type Props = {
  price?: number
  image?: string | null
  title?: string
  summaryItems: SummaryItemProps[]
  disabled?: boolean
  discountCodeProps?: DiscountCodeInputProps
}

const OrderSummary = ({price, image, title, summaryItems, discountCodeProps, disabled = false}: Props) => (
  <Stack spacing={8}>
    <Stack direction={['column', null, null, 'row']} align={['center', null, null, 'stretch']} spacing={8}>
      <Stack w="100%" maxW="352px" flex="1" spacing={8} justify={title ? 'space-between' : 'flex-end'}>
        {title && <Text fontSize="lg">{title}</Text>}
        {image && (
          <AspectRatio ratio={1}>
            <Image src={image} objectFit="cover" borderRadius="0 50px" />
          </AspectRatio>
        )}
      </Stack>
      <Stack
        py={[8, null, 16]}
        px={[8, null, 20]}
        w="100%"
        flex="1"
        spacing={8}
        borderRadius="50px 0"
        bg="brand.green.200"
      >
        {summaryItems.map((s, i) => (
          <SummaryItem key={i} {...s} />
        ))}
        {!!price && <SummaryItem title="Cena">{(price / 100).toFixed(2)} zł</SummaryItem>}
        <SummaryItem title="Adres rozliczeniowy">
          <AddressInput disabled={disabled} />
        </SummaryItem>
        {discountCodeProps && (
          <SummaryItem title="Kod zniżkowy">
            <DiscountCodeInput disabled={disabled} {...discountCodeProps} />
          </SummaryItem>
        )}
      </Stack>
    </Stack>
  </Stack>
)

export default OrderSummary
