import React from 'react'

import {EditIcon} from '@chakra-ui/icons'
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  IconButton,
  SkeletonText,
  Spinner,
  Stack,
  Text,
  useBoolean,
  useToast,
} from '@chakra-ui/react'
import {TNode} from '@udecode/plate-core'
import {FaFacebook, FaTwitter} from 'react-icons/fa'
import {ImageType} from 'react-images-uploading'
import {Link, generatePath, useParams, useHistory} from 'react-router-dom'
import {FacebookShareButton, TwitterShareButton} from 'react-share'

import {supabase} from '@/api'
import {ViewBlogPost, GenerateBlogPostImageURLResult, BlogPost} from '@/api/models'
import {selectIsAdmin} from '@/auth/state'
import {calculateReadTime} from '@/blog/utils'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import EditableDatetime from '@/common/editable-datetime'
import EditableText from '@/common/editable-text'
import ImageUpload from '@/common/image-upload'
import useFetchSingle from '@/common/use-fetch-single'
import useMetaTags from '@/common/use-meta-tags'
import {SUPABASE_BLOG_POST_IMAGES_BUCKET} from '@/constants'
import Newsletter from '@/home/newsletter'
import {BLOG, BLOG_POST} from '@/router/paths'
import {useAppSelector} from '@/store'
import {formatDate} from '@/utils/string'

import BlogPostEditor from './editor'
import OtherBlogPosts from './other-blog-posts'
import RelatedBlogPostCourses from './related-courses'
import RelatedBlogPostTherapists from './related-therapists'

type BlogPostParams = {id: string; slug: string}

const updateImage = async (id: string, image: ImageType | undefined) => {
  if (!image?.file) {
    const {data, error} = await supabase.rpc('update_blog_post_image', {blog_post_id: id, path: null})
    const result = data as any as GenerateBlogPostImageURLResult
    if (error) {
      throw error
    }
    if ('error' in result) {
      throw result.error
    }
    return undefined
  }

  const filename = `${Date.now()}.${image.file.name.slice(
    (Math.max(0, image.file.name.lastIndexOf('.')) || Infinity) + 1
  )}`
  const path = `/${id}/${filename}`
  const {error: uploadError} = await supabase.storage
    .from(SUPABASE_BLOG_POST_IMAGES_BUCKET)
    .upload(path.substr(1), image.file, {upsert: true})
  if (uploadError) {
    throw uploadError
  }

  const {data, error} = await supabase.rpc('update_blog_post_image', {blog_post_id: id, path})
  const result = data as any as GenerateBlogPostImageURLResult
  if (error) {
    throw error
  }
  if ('error' in result) {
    throw result.error
  }

  return result.url
}

const BlogPostView = () => {
  const toast = useToast()
  const editable = useAppSelector(selectIsAdmin)
  const {id, slug} = useParams<BlogPostParams>()
  const history = useHistory()

  const [image, setImage] = React.useState<ImageType>()
  const [editing, setEditing] = React.useState(false)
  const [isEditingDescription, setIsEditingDescription] = useBoolean(false)
  const [editDescriptionValue, setEditDescriptionValue] = React.useState(initialEditorValue)

  const {
    data: post,
    loading,
    fetch,
  } = useFetchSingle<ViewBlogPost>(
    React.useMemo(
      () => ({
        table: 'blog_posts_view',
        fields: '*',
        match: {id: +id},
        errSnackbarTitle: 'Nie udało się pobrać zawartości postu.',
      }),
      [id]
    )
  )

  useMetaTags({
    title: post?.name,
    description: post?.meta_description,
    'og:image': post?.signed_image,
  })

  React.useEffect(() => {
    if (post && post.slug !== slug) {
      history.replace(generatePath(BLOG_POST, {id, slug: post.slug}))
    }
  }, [post, slug]) // eslint-disable-line

  React.useEffect(() => {
    setImage(post?.signed_image ? {dataURL: post?.signed_image} : undefined)
  }, [post])

  const toggleEditing = React.useCallback(() => {
    setEditing(!editing)
  }, [setEditing, editing])

  const handleUploadImage = React.useCallback(
    async (image: ImageType | undefined) => {
      try {
        const url = await updateImage(id, image)
        if (url) {
          setImage({dataURL: url})
        } else {
          setImage(undefined)
        }

        toast({
          isClosable: true,
          status: 'success',
          title: 'Zaktualizowano zdjęcie postu.',
        })
      } catch (e) {
        console.error('Failed to upload blog post image', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zaktualizować zdjęcia postu.',
        })
      }
    },
    [id, toast]
  )
  const updatePost = React.useCallback(
    async (blogPostPartial: Partial<BlogPost>) => {
      const {error} = await supabase
        .from('blog_posts')
        .update({...blogPostPartial, updated_at: new Date()})
        .eq('id', id)
      if (error) {
        throw error
      }
    },
    [id]
  )
  const handleSaveName = React.useCallback(
    async (name: string) => {
      try {
        await updatePost({name})
        toast({
          isClosable: true,
          status: 'success',
          title: 'Zaktualizowano nazwę posta.',
        })
        fetch()
      } catch (e) {
        console.error('Failed to update blog post name.', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zaktualizować nazwy posta.',
        })
      }
    },
    [toast, updatePost, fetch]
  )
  const handleSaveAuthor = React.useCallback(
    async (author: string) => {
      try {
        await updatePost({author})
        toast({
          isClosable: true,
          status: 'success',
          title: 'Zaktualizowano autora kursu.',
        })
        fetch()
      } catch (e) {
        console.error("Failed to update post's author.", e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zaktualizować autora kursu.',
        })
      }
    },
    [toast, updatePost, fetch]
  )

  const handleDescriptionEditorOpen = React.useCallback(() => {
    setIsEditingDescription.on()
    setEditDescriptionValue(post?.description ?? initialEditorValue)
  }, [post?.description, setIsEditingDescription])

  const handleDescriptionChange = React.useCallback((value: TNode[]) => {
    setEditDescriptionValue(value)
  }, [])

  const handleEditDescriptionSave = React.useCallback(async () => {
    try {
      await updatePost({description: editDescriptionValue})
      fetch()
      setIsEditingDescription.off()
      toast({
        isClosable: true,
        status: 'success',
        title: 'Zaktualizowano opis postu.',
      })
    } catch (e) {
      console.error("Failed to update post's description.", e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się zaktualizować opisu postu.',
      })
    }
  }, [editDescriptionValue, fetch, setIsEditingDescription, toast, updatePost])

  const handleEditDescriptionCancel = React.useCallback(() => {
    setIsEditingDescription.off()
    fetch()
  }, [fetch, setIsEditingDescription])

  const handleSavePublishedAt = React.useCallback(
    async (publishedAt?: Date | null) => {
      try {
        await updatePost({published_at: publishedAt})
        toast({
          isClosable: true,
          status: 'success',
          title: publishedAt ? 'Zaktualizowano datę opublikowania' : 'Usunięto datę opublikowania',
        })
        fetch()
      } catch (e) {
        console.error("Failed to update post's published date.", e)
        toast({
          isClosable: true,
          status: 'error',
          title: publishedAt
            ? 'Nie udało się zaktualizować daty opublikowania postu'
            : 'Nie udało się usunąć daty opublikowania postu',
        })
      }
    },
    [toast, updatePost, fetch]
  )

  const publishedAt = React.useMemo(() => {
    return post?.published_at ? new Date(post.published_at) : null
  }, [post?.published_at])

  const readTime = React.useMemo(() => calculateReadTime(post?.words_count ?? 0), [post?.words_count])

  if (loading) {
    return (
      <Container maxW="container.xl" textAlign="center">
        <Spinner my={8} size="xl" thickness="4px" speed="0.8s" />
      </Container>
    )
  }

  return (
    <>
      <Container maxW="container.xl">
        <Flex
          direction={['column', null, 'row']}
          my={[8, null, 16]}
          justifyContent="space-between"
          alignItems="center"
        >
          <Breadcrumb fontWeight={300}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={BLOG}>
                Blog
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
              {post?.slug ? (
                <BreadcrumbLink
                  as={Link}
                  to={post.id ? generatePath(BLOG_POST, {id: post.id, slug: post.slug}) : BLOG}
                >
                  {post.name}
                </BreadcrumbLink>
              ) : (
                <BreadcrumbLink>
                  <SkeletonText w="100px" noOfLines={1} />
                </BreadcrumbLink>
              )}
            </BreadcrumbItem>
          </Breadcrumb>
          {editable && (
            <Stack spacing="5" alignItems="center" pt={[8, null, 0]}>
              <Button onClick={toggleEditing} size="sm">
                {editing ? 'Wyjdź z edytora' : 'Edytuj post'}
              </Button>
            </Stack>
          )}
        </Flex>
      </Container>

      <Box mb={[8, null, 16]}>
        <ImageUpload
          value={image}
          onChange={handleUploadImage}
          height="400px"
          editing={editing}
          overlay={
            editing ? undefined : (
              <Flex
                direction="column"
                alignItems="flex-start"
                justifyContent="flex-end"
                width="100%"
                height="100%"
                backgroundColor="rgba(7, 51, 44, 0.4)"
                color="white"
              >
                <Container maxW="container.xl" mb={6} px={[4, null, 12]}>
                  <Heading as="h1" fontSize="4xl">
                    {post?.name}
                  </Heading>
                </Container>
              </Flex>
            )
          }
        />
      </Box>

      <Container maxW="container.xl">
        <Stack
          spacing={2}
          mx={[0, null, 8]}
          mb={16}
          direction={['column', null, 'row']}
          alignItems={['flex-start', null, 'center']}
          fontWeight={300}
        >
          {editing ? (
            <>
              <EditableText
                fontSize="md"
                initialValue={post?.name || ''}
                onSave={handleSaveName}
                editable={editing}
              />
              <Text display={['none', null, 'block']}>|</Text>
            </>
          ) : null}
          {editing ? (
            <>
              <Text fontSize="md">Opublikowano:</Text>
              <EditableDatetime
                initialValue={publishedAt}
                onSave={handleSavePublishedAt}
                editable={editing}
                placeholder="Publikacja nie jest jeszcze zaplanowana"
                showTimeSelect={true}
              />
            </>
          ) : (
            <Text fontSize="md">
              {publishedAt ? `Opublikowano: ${formatDate(publishedAt)}` : 'Nie opublikowano'}
            </Text>
          )}
          <Text display={['none', null, 'block']}>|</Text>
          <Flex direction="row" alignItems="center">
            {(post?.author || editing) && <Text pr={1}>Autor:</Text>}
            <EditableText initialValue={post?.author || ''} onSave={handleSaveAuthor} editable={editing} />
          </Flex>
          <Text display={['none', null, 'block']}>|</Text>
          <Text flex={1}>
            {readTime} {readTime === 1 ? 'minuta' : 'minut'} czytania
          </Text>
          <Stack direction="row" spacing={2} alignItems="center">
            <Text pr={[2, null, 0]}>Udostępnij</Text>
            <IconButton
              aria-label="Udostępnij na Twitterze"
              as={TwitterShareButton}
              url={window.location.href}
              title={post?.name}
              icon={<FaTwitter />}
            />
            <IconButton
              aria-label="Udostępnij na Facebooku"
              as={FacebookShareButton}
              url={window.location.href}
              quote={post?.name}
              icon={<FaFacebook />}
            />
          </Stack>
        </Stack>
      </Container>
      {editing && (
        <Box bg="brand.yellow.300" py={8} mb={16}>
          <Container maxW="container.xl">
            <Stack direction="column" px={8}>
              <HStack>
                <Text>Opis:</Text>
                {!isEditingDescription && (
                  <Box ml="1" cursor="pointer" onClick={handleDescriptionEditorOpen}>
                    <EditIcon size="1rem" />
                  </Box>
                )}
              </HStack>
              {!loading && (
                <ContentEditor
                  id={`blog-viewer-description-${id}`}
                  readOnly={!isEditingDescription}
                  onChange={handleDescriptionChange}
                  value={post?.description ?? initialEditorValue}
                  variant="basic"
                  onSave={handleEditDescriptionSave}
                  onCancel={handleEditDescriptionCancel}
                  toolbarPosition="navbar"
                />
              )}
            </Stack>
          </Container>
        </Box>
      )}
      <Container maxW="container.xl">
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(11, 1fr)']}
          mx={[0, null, 7]}
          columnGap={8}
          mb={[0, null, 16]}
        >
          <GridItem colSpan={[1, null, 8]} textAlign="justify">
            <BlogPostEditor blogPostID={+id} readOnly={!editing} />
          </GridItem>
          <GridItem colSpan={[1, null, 3]}>
            <RelatedBlogPostCourses editing={editing} forBlogPost={+id} />
            <RelatedBlogPostTherapists editing={editing} forBlogPost={+id} />
          </GridItem>
        </Grid>
        <Box mx={[0, null, 7]} mt={[4, null, 0]}>
          <OtherBlogPosts editing={editing} forBlogPost={+id} />
        </Box>
      </Container>
      <Newsletter />
    </>
  )
}

export default BlogPostView
