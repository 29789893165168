import * as React from 'react'

import {RiRefund2Line} from 'react-icons/ri'

import {Payment} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'

export const refundAction = (onClick: (item: Payment) => void): DataTableAction<Payment> => ({
  icon: <RiRefund2Line />,
  id: 'refund',
  onClick,
  tooltip: 'Zwróć',
})
