import * as React from 'react'

import {Stack} from '@chakra-ui/react'

import SupportGroupMeetings from './meetings'

const SupportGroups = () => (
  <Stack spacing={24} pr={[4, null, 0]} pl={[4, null, 24]} mt={6} position="relative">
    <SupportGroupMeetings />
  </Stack>
)

export default SupportGroups
