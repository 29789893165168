import React from 'react'

import {
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {MdClose} from 'react-icons/md'
import {Link as RouterLink, generatePath, useHistory, useParams} from 'react-router-dom'

import {PublicWebinar} from '@/api/models'
import ShowcaseDescription from '@/common/components/product-showcase/description'
import ShowcaseDetailsBanner, {DetailItemProps} from '@/common/components/product-showcase/details-banner'
import ScreenWideBar from '@/common/components/screen-wide-bar'
import CalendarIcon from '@/common/icons/calendar'
import ClockIcon from '@/common/icons/clock'
import UserIcon from '@/common/icons/user'
import LoadingView from '@/common/loading-view'
import NotFoundView from '@/common/not-found-view'
import useFetchSingle from '@/common/use-fetch-single'
import useMetaTags from '@/common/use-meta-tags'
import {HOME, PSYCHOTHERAPISTS, PSYCHOTHERAPY_THERAPIST, WEBINAR} from '@/router/paths'
import {formatInterval} from '@/utils/duration'
import {formatTimestamp} from '@/utils/string'

import WebinarOrderModalButton from '../order-modal/button'

type Params = {id: string; slug?: string}

const WebinarShowcase = () => {
  const {id, slug} = useParams<Params>()
  const {replace} = useHistory()

  const {data: webinar, loading} = useFetchSingle<PublicWebinar>(
    React.useMemo(
      () => ({
        table: 'public_webinars',
        fields: '*',
        match: {id: +id},
        errSnackbarTitle: 'Nie udało się wczytać webinaru',
      }),
      [id]
    )
  )

  useMetaTags({
    title: webinar?.name,
    description: webinar?.description,
    'og:image': webinar?.thumbnail,
  })

  React.useEffect(() => {
    if (webinar?.slug && webinar.slug !== slug) {
      replace(generatePath(WEBINAR, {id, slug: webinar.slug}))
    }
  }, [webinar, slug]) // eslint-disable-line

  React.useEffect(() => {
    if (!webinar) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `webinar:${webinar.id}`, // meeting or id??
              item_name: `Uczestnictwo w webinarze - ${webinar.name}`,
              value: (webinar.price || 0) / 100,
            },
          ],
          value: (webinar.price || 0) / 100,
        },
        event: 'view_item',
      },
    })
  }, [webinar])

  const detailsItems: DetailItemProps[] = React.useMemo(
    () =>
      webinar
        ? [
            {
              icon: CalendarIcon,
              title: 'Termin webinaru',
              children: <Text>{formatTimestamp(webinar.start_at)}</Text>,
            },
            {
              icon: ClockIcon,
              title: 'Czas trwania',
              children: <Text>{formatInterval(webinar.start_at, webinar.end_at)}</Text>,
            },
            {
              icon: UserIcon,
              title: 'Prowadzący',
              children: (
                <Stack>
                  {webinar.hosts.map((h, i) => (
                    <Button
                      key={i}
                      variant="brandLink"
                      as={RouterLink}
                      to={
                        h.therapist_id
                          ? generatePath(PSYCHOTHERAPY_THERAPIST, {
                              id: h.therapist_id,
                            })
                          : PSYCHOTHERAPISTS
                      }
                    >
                      {h.full_name}
                    </Button>
                  ))}
                </Stack>
              ),
            },
          ]
        : [],
    [webinar]
  )

  if (loading) return <LoadingView />

  if (!webinar) return <NotFoundView />

  // TODO: make some reusable product showcase components
  return (
    <Container
      maxW="container.xl"
      as={Stack}
      spacing={[8, null, 24]}
      mt={[8, null, 16]}
      mb={[8, null, 24]}
      px={[8, null, 4]}
    >
      <Stack spacing={[8, null, 16]}>
        <Breadcrumb fontWeight={300}>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={HOME}>
              Strona Główna
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
            <BreadcrumbLink as={RouterLink} to={generatePath(WEBINAR, {id, slug})}>
              {webinar.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={[12, null, 5]} px={[4, null, 0]}>
            <Box roundedTopRight="60px" roundedBottomLeft="60px" overflow="hidden">
              <AspectRatio ratio={1}>
                {webinar.thumbnail ? (
                  <Image src={webinar.thumbnail} objectFit="cover" />
                ) : (
                  <Flex objectFit="cover" bg="gray.600" alignItems="center" justifyContent="center">
                    <MdClose size={64} />
                  </Flex>
                )}
              </AspectRatio>
            </Box>
          </GridItem>
          <GridItem
            as={Stack}
            direction="column"
            colStart={[0, null, 7]}
            colSpan={[12, null, 6]}
            py={[8, null, 4]}
            spacing={8}
          >
            <ScreenWideBar py={[8, null, 12]} bg="brand.yellow.300">
              <Heading size="lg">{webinar.name}</Heading>
            </ScreenWideBar>
            <Stack
              mb={4}
              spacing={4}
              direction={['column', 'row', 'column', 'row']}
              justify="space-between"
              align={['flex-start', 'center', 'flex-start', 'center']}
            >
              <HStack fontSize="xl" spacing={1}>
                <Text>Cena:</Text>
                {webinar.price ? <Text>{`${webinar.price / 100} zł`}</Text> : <Text>Za darmo</Text>}
              </HStack>
              <WebinarOrderModalButton webinar={webinar} />
            </Stack>
            <ShowcaseDescription
              id="webinar-showcase"
              shortDescription={webinar.short_description}
              fullDescription={webinar.full_description}
            />
          </GridItem>
        </Grid>
      </Stack>

      <ShowcaseDetailsBanner items={detailsItems} bg="brand.yellow.300" />

      <ScreenWideBar py={10} bg="brand.green.800" justify="center">
        <Stack direction={['column', null, 'row']} spacing={[4, null, 16]} align="center">
          <Text color="white" fontSize="2xl" textAlign={['center', null, 'left']}>
            {webinar.has_access ? 'Już masz dostęp do tego webinaru!' : 'Jesteś zainteresowany webinarem?'}
          </Text>
          <WebinarOrderModalButton webinar={webinar} height={58} width={80} />
        </Stack>
      </ScreenWideBar>

      {/* TODO: Related support groups */}
    </Container>
  )
}

export default WebinarShowcase
