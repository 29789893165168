import * as React from 'react'

import {
  Box,
  Button,
  ButtonProps,
  ComponentWithAs,
  Flex,
  Icon,
  IconButton,
  IconButtonProps,
  IconProps,
  Spinner,
  useToken,
} from '@chakra-ui/react'
import {Link, LinkProps} from 'react-router-dom'

const ButtonRight = ({
  color,
  height,
  marginLeft,
  marginRight,
  transform,
}: {
  color: string
  height?: number
  marginLeft?: string
  marginRight?: string
  transform?: string
}) => {
  const [chakraColor] = useToken('colors', [color])
  const computedHeight = height ?? 37
  const computedWidth = (30.36 / 37) * computedHeight
  return (
    <svg
      width={computedWidth}
      height={computedHeight}
      viewBox="0 0 30.36 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        flexShrink: 0,
        marginLeft,
        marginRight,
        transform,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-133.5 0C-143.717 0-152 8.283-152 18.5s8.283 18.5 18.5 18.5H-.074c3.316 0 6.427-.872 9.118-2.4a22.249 22.249 0 0 0 3.005-1.751v.05L30.367 18.5 12.049 4.101v.05A22.251 22.251 0 0 0 9.044 2.4 18.415 18.415 0 0 0-.074 0h-1.582z"
        fill={chakraColor}
        style={{
          transitionDuration: 'var(--chakra-transition-duration-normal)',
          transitionProperty: 'var(--chakra-transition-property-common)',
        }}
      />
    </svg>
  )
}

export type Props = Omit<ButtonProps, 'bg' | 'height' | 'h' | 'size'> & {
  height?: number
  bg?: string
  hoverBg?: string
  flipped?: boolean
}

const activeStyle = {
  backgroundColor: 'transparent',
}

const focusStyle = {
  border: 0,
}

const hoverStyle = {
  backgroundColor: 'transparent',
}

export const ArrowButton: ComponentWithAs<'button', Props> = ({
  children,
  height,
  isLoading,
  flipped,
  bg = 'brand.yellow.600',
  hoverBg = 'brand.yellow.700',
  ...rest
}: Props) => {
  const computedHeight = height ?? 37
  const computedWidth = (computedHeight * 30.36) / 37

  const [hover, setHover] = React.useState(false)
  const handleMouseEnter = React.useCallback(() => setHover(true), [setHover])
  const handleMouseLeave = React.useCallback(() => setHover(false), [setHover])

  const buttonColor = React.useMemo(() => (hoverBg && hover ? hoverBg : bg), [bg, hoverBg, hover])

  return (
    <Button
      color="white"
      size="md"
      height={`${computedHeight}px`}
      backgroundColor="transparent"
      p={0}
      _active={activeStyle}
      _focus={focusStyle}
      _hover={hoverStyle}
      borderRadius="full"
      display="flex"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {flipped && (
        <ButtonRight color={buttonColor} height={computedHeight} transform="scaleX(-1)" marginRight="-1px" />
      )}
      <Flex
        direction="row"
        roundedStart={flipped ? undefined : 'full'}
        roundedEnd={flipped ? 'full' : undefined}
        backgroundColor={buttonColor}
        height={`${computedHeight}px`}
        transitionDuration="var(--chakra-transition-duration-normal)"
        transitionProperty="var(--chakra-transition-property-common)"
        width="100%"
      >
        {!flipped && <Box width={`${computedWidth - 1}px`} />}
        <Flex alignItems="center" justifyContent="center" px={2} position="relative" width="100%">
          <Flex
            direction="row"
            alignItems="center"
            justifyContent="center"
            visibility={isLoading ? 'hidden' : 'visible'}
            flex={1}
          >
            {children}
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            position="absolute"
            visibility={isLoading ? 'visible' : 'hidden'}
            left={0}
            top={0}
            width="100%"
            height="100%"
          >
            <Spinner />
          </Flex>
        </Flex>
        {flipped && <Box width={`${computedWidth - 1}px`} />}
      </Flex>
      {!flipped && <ButtonRight color={buttonColor} height={computedHeight} marginLeft="-1px" />}
    </Button>
  )
}

export const ArrowIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 46 29" {...props} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.7273 25.5044C21.2233 27.5192 18.0273 28.7273 14.5455 28.7273C6.51222 28.7273 0 22.2965 0 14.3636C0 6.43082 6.51222 0 14.5455 0C18.0273 0 21.2233 1.20807 23.7273 3.22291V3.18403L36 14.3636L23.7273 25.5432V25.5044Z"
        fill="currentColor"
      />
      <path d="M34 4L44 14.5L34 25" stroke="currentColor" strokeWidth="2" />
    </Icon>
  )
}

export const ArrowIconButton = (
  props: Omit<IconButtonProps, 'as'> & (Pick<IconButtonProps, 'as'> | ({as: Link} & LinkProps))
) => {
  return (
    <IconButton
      {...props}
      icon={<ArrowIcon width={props.width ?? '100%'} height={props.height ?? '100%'} />}
      variant="unstyled"
      _active={Object.assign(
        {
          borderWidth: 0,
        },
        props._active
      )}
      _focus={Object.assign(
        {
          borderWidth: 0,
        },
        props._focus
      )}
    />
  )
}

export const ChevronIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 18 10" {...props} fill="none">
      <path d="M16.5601 1L8.77999 8.22435L0.999928 0.999999" stroke="currentColor" strokeWidth="2" />
    </Icon>
  )
}

/*
  export const ArrowIcon = ({color, style, width}: {color: string; style?: any; width?: any}) => {
    const computedWidth = width ?? 46
    const computedHeight = computedWidth * (29 / 46)

    return (
      <svg
        width={computedWidth}
        height={computedHeight}
        viewBox="0 0 46 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={style}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7273 25.5044C21.2233 27.5192 18.0273 28.7273 14.5455 28.7273C6.51222 28.7273 0 22.2965 0 14.3636C0 6.43082 6.51222 0 14.5455 0C18.0273 0 21.2233 1.20807 23.7273 3.22291V3.18403L36 14.3636L23.7273 25.5432V25.5044Z"
          fill={color}
        />
        <path d="M34 4L44 14.5L34 25" stroke={color} strokeWidth="2" />
      </svg>
    )
  }
*/
