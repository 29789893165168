import * as React from 'react'

import {Stack} from '@chakra-ui/react'

import MeetingHistory from './history'
import UpcomingMeetings from './upcoming'

const TherapistMeetings = () => {
  return (
    <Stack spacing={16} px={[4, null, 8]}>
      <UpcomingMeetings />
      <MeetingHistory />
    </Stack>
  )
}

export default TherapistMeetings
