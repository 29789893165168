import * as React from 'react'

import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link as ChakraLink,
  Stack,
  Text,
} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'
import Section from '@/common/components/section'
import {hotlinePhoneNumber} from '@/constants'

import bg from './bg.svg'
import image from './image.png'

const Contact = () => {
  return (
    <>
      {/* <Box py={16} position="relative" overflow="hidden">
        <Box
          backgroundColor="brand.yellow.300"
          zIndex={-1}
          position="absolute"
          left="0"
          top="0"
          width="100%"
          height="100%"
        />
        <Box position="absolute" left="0" top="0" width="100%" height="100%" zIndex={-1} display="block">
          <Flex marginTop="0%" height="100%" position="relative" alignItems="center" justifyContent="center">
            <Image src={bg} width="100%" />
          </Flex>
        </Box>
        <Container maxW="container.xl" zIndex={1}>
          <Grid templateColumns="repeat(12, 1fr)" gap={8}>
            <GridItem
              colSpan={[12, null, 5]}
              colStart={[0, null, 2]}
              as={Stack}
              direction="column"
              spacing={8}
            >
              <Heading
                as="h1"
                size="lg"
                fontWeight={500}
                lineHeight="40px"
                textAlign={['center', null, 'left']}
                width={['100%', null, 'auto']}
              >
                Nie wiesz jak zacząć?
                <br />
                Jesteśmy tu, żeby Ci pomóc.
              </Heading>
              <Text fontWeight={300}>
                Mamy dla Ciebie mały poradnik z pytaniami, które warto sobie zadać już na początku. Dzięki nim
                ustalisz, co w Twoim życiu wymaga zmiany, a my skierujemy Cię na właściwą drogę.
              </Text>
            </GridItem>
            <GridItem
              colSpan={[12, null, 4]}
              colStart={[0, null, 8]}
              as={Flex}
              direction="column"
              justifyContent="center"
            >
              <ArrowButton
                bg="brand.yellow.600"
                hoverBg="brand.yellow.700"
                color="white"
                height={55}
                fontSize="lg"
                fontWeight={500}
                isDisabled={true}
              >
                Pobierz poradnik
              </ArrowButton>
            </GridItem>
          </Grid>
        </Container>
      </Box> */}
      <Container maxW="container.xl" my={20}>
        {/* First section */}
        <Section img={image} fontWeight={300}>
          <Stack direction="column" spacing={2}>
            <Heading size="md" fontWeight={500}>
              Kontakt
            </Heading>
            <Text>kontakt@psychoforma.pl</Text>
            <ChakraLink href={`tel:${hotlinePhoneNumber.raw}`}>{hotlinePhoneNumber.displayed}</ChakraLink>
          </Stack>
          <Stack direction="row" spacing={4}>
            <Stack direction="column" spacing={2} flex={1}>
              <Heading size="md" fontWeight={500}>
                Adres
              </Heading>
              <Text>ul. Rewolucji 1905 r. 9/34</Text>
              <Text>90-273 Łódź</Text>
            </Stack>
            {/*
                <Stack direction="column" spacing={2} flex={1}>
                  <Heading size="md" fontWeight={500}>
                    Dział sprzedaży
                  </Heading>
                  <Text>kontakt@psychoforma.pl</Text>
                  <Text>+22 565 55 55</Text>
                </Stack>
              */}
          </Stack>
          <Stack direction="column" spacing={2}>
            <Heading size="md" fontWeight={500}>
              Godziny kontaktu
            </Heading>
            <Grid templateColumns="repeat(2, 1fr)" columnGap={4} rowGap={2}>
              <GridItem>poniedziałek - piątek</GridItem>
              <GridItem>8:00 - 20:00</GridItem>
              <GridItem>sobota</GridItem>
              <GridItem>9:00 - 17:00</GridItem>
              <GridItem>niedziela</GridItem>
              <GridItem>dzień wolny</GridItem>
            </Grid>
          </Stack>
        </Section>
      </Container>
      <Box backgroundColor="brand.green.800" py={20} color="white" mt={8}>
        <Container maxW="container.xl">
          <Stack direction="column" spacing={8} alignItems="center">
            <Heading size="lg" fontWeight={500} color="brand.yellow.600">
              Masz jakieś pytania? Zadzwoń do nas
            </Heading>
            <ChakraLink href={`tel:${hotlinePhoneNumber.raw}`} fontSize="3xl" fontWeight={500}>
              {hotlinePhoneNumber.displayed}
            </ChakraLink>
          </Stack>
        </Container>
      </Box>
    </>
  )
}

export default Contact
