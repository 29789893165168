import * as React from 'react'

import {Text, useToast, Stack, Center, Button} from '@chakra-ui/react'

import {supabase} from '@/api'
import {emptyPGInterval, formatPGInterval, parsePGInterval, PGInterval} from '@/api/types'
import DurationPicker from '@/common/duration-picker'

const BreakDuration = () => {
  const toast = useToast()
  const [breakDuration, setBreakDuration] = React.useState<PGInterval>(emptyPGInterval)
  const [isBreakLoading, setIsBreakLoading] = React.useState(false)

  const fetchBreakDuration = React.useCallback(async () => {
    setIsBreakLoading(true)
    try {
      const {data, error} = await supabase
        .from('therapist_services_settings')
        .select('value')
        .eq('name', 'break_duration')
      if (error) throw error
      if (!data) throw new Error('Could not fetch break duration')

      setBreakDuration(parsePGInterval(data[0].value))
    } catch (e) {
      console.error('Failed to fetch break duration', e)
      toast({
        isClosable: true,
        title: 'Nie udało się wczytać długości przerwy',
        variant: 'error',
      })
    } finally {
      setIsBreakLoading(false)
    }
  }, [toast])

  const saveBreakDuration = React.useCallback(async () => {
    try {
      setIsBreakLoading(true)
      const {error} = await supabase.from('therapist_services_settings').update([
        {
          value: formatPGInterval(breakDuration),
        },
      ])
      if (error) throw error

      toast({
        isClosable: true,
        status: 'success',
        title: 'Długość przerwy zapisana',
      })
    } catch (e) {
      console.error('Failed to fetch break duration', e)
      toast({
        isClosable: true,
        title: 'Nie udało się zapisać długości przerwy',
        variant: 'error',
      })
    } finally {
      setIsBreakLoading(false)
    }
  }, [toast, breakDuration])

  React.useEffect(() => {
    fetchBreakDuration()
  }, [fetchBreakDuration])

  return (
    <Stack align="center" py={4} spacing={2}>
      <Text>Długość przerwy</Text>
      <DurationPicker
        value={breakDuration ?? emptyPGInterval}
        onChange={setBreakDuration}
        isDisabled={isBreakLoading}
      />
      <Button isLoading={isBreakLoading} onClick={saveBreakDuration} marginTop={4}>
        Zapisz długość przerwy
      </Button>
    </Stack>
  )
}

export default BreakDuration
