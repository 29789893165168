import React from 'react'

import {
  Box,
  Container,
  Flex,
  Stack,
  Text,
  Image,
  useBreakpointValue,
  useToken,
  Tooltip,
  Link,
} from '@chakra-ui/react'
import {CarouselProvider, Dot, Slider, Slide} from 'pure-react-carousel'
import {Parallax} from 'react-scroll-parallax'

import {PublicFrontPageLogo} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'

import './index.css'

const RecommendUsLogo = ({logo}: {logo: PublicFrontPageLogo}) => {
  return (
    <Tooltip label={logo.alt_text}>
      <Link href={logo.link_url} target="_blank">
        <Image src={logo.image_path} alt={logo.alt_text} flexShrink={1} maxWidth="233px" maxHeight="70px" />
      </Link>
    </Tooltip>
  )
}

const RecommendUs = () => {
  const [yellow300] = useToken('colors', ['brand.yellow.300'])
  const variant = useBreakpointValue({base: 'slider', md: 'stack'})

  const {data: logos} = useSupabaseQuery<PublicFrontPageLogo>({
    fields: '*',
    table: 'public_front_page_logos',
  })

  return !logos?.length ? null : (
    <Box py={[12, null, '40px']} position="relative" my={[0, null, 20]}>
      <Box width="100%" height="100%" position="absolute" left="0" top="0" zIndex={-1}>
        <Parallax
          style={{
            backgroundColor: yellow300,
            height: '50%',
          }}
          translateY={[0, 100]}
        />
      </Box>
      <Container maxW="container.xl">
        <Stack
          direction={['column', null, 'row']}
          spacing={12}
          alignItems={['flex-start', null, 'center']}
          overflow="hidden"
        >
          <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} flexShrink={0} ml={[4, null, 0]}>
            Polecają nas
          </Text>
          {variant === 'slider' ? (
            <CarouselProvider
              naturalSlideWidth={500}
              naturalSlideHeight={400}
              totalSlides={logos?.length ?? 0}
              visibleSlides={2}
              infinite={true}
              className="recommend-us-carousel"
              isIntrinsicHeight={true}
              isPlaying={true}
              interval={3000}
            >
              <Slider>
                {logos?.map((logo, i) => (
                  <Slide index={i} key={i}>
                    <Flex mx={4} alignItems="center" justifyContent="center" height="100%">
                      <RecommendUsLogo key={i} logo={logo} />
                    </Flex>
                  </Slide>
                ))}
              </Slider>
              <Stack direction="row" spacing={8}>
                {Array.from(Array(3).keys()).map((n) => (
                  <Dot key={n} slide={n}>
                    <Box borderRadius="full" width="20px" height="20px" />
                  </Dot>
                ))}
              </Stack>
            </CarouselProvider>
          ) : (
            <Stack
              direction="row"
              spacing={12}
              justifyContent="space-evenly"
              alignItems="center"
              flexShrink={1}
              width="100%"
            >
              {logos?.map((logo, i) => (
                <RecommendUsLogo key={i} logo={logo} />
              ))}
            </Stack>
          )}
        </Stack>
      </Container>
    </Box>
  )
}

export default RecommendUs
