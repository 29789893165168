import {TherapistService, TherapistServiceRaw} from '@/api/models'
import {formatPGInterval, parsePGInterval} from '@/api/types'

export const inputToUpsertArgs = (input: TherapistService) => ({
  id: input.id,
  meeting_duration: formatPGInterval(input.meeting_duration),
  name: input.name,
  order: input.order,
  price: input.price * 100,
})

export const parseTherapistServiceType = (s: TherapistServiceRaw) => ({
  ...s,
  meeting_duration: parsePGInterval(s.meeting_duration),
  price: s.price / 100,
})
