import * as React from 'react'

import {Box, Container, Heading, Stack, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import Header from '@/common/components/header'
import Section from '@/common/components/section'
import NotForMe from '@/common/not-for-me'
import OurTherapists from '@/home/our-therapists'
import {PSYCHOTHERAPISTS} from '@/router/paths'

import headerImage from './header.jpg'
import section1Image from './section1.jpg'
import section2Image from './section2.jpg'
import section3Image from './section3.jpg'

const PsychotherapyLandingPage = () => {
  return (
    <>
      <Header img={headerImage} title="Psychoterapia">
        <Text fontSize="md" fontWeight={400}>
          Chcesz żyć lepiej i pełniej? Szukasz wewnętrznej równowagi? A może czujesz smutek, lęk, zmagasz się
          z depresją? Psychoterapia pomoże Ci odzyskać radość życia. Znajdź terapeutę z doświadczeniem i
          kompetencjami w obszarze, który Cię interesuje i rozpocznij terapię online.
        </Text>
      </Header>

      <Container as={Stack} maxW="container.xl" align="center" my={[16, null, 20]} spacing={[8, null, 24]}>
        {/* First section */}
        <Section title="Czym jest psychoterapia?" img={section1Image}>
          <Text fontSize="md" fontWeight={400}>
            Psychoterapia to nie tylko metoda leczenia problemów emocjonalnych i zaburzeń psychicznych, ale
            również sposób na zwiększenie potencjału osobowościowego. To proces poznawania siebie,
            analizowania trudności, z jakimi się zmagamy i uczenia się, jak możemy na nie wpłynąć. Polega na
            regularnych rozmowach z psychoterapeutą, które służą zrozumieniu emocji, spojrzeniu na nie z innej
            perspektywy i nauce radzenia sobie z nimi.
          </Text>
        </Section>

        {/* Second section */}
        <Section title="W czym pomaga psychoterapia?" img={section2Image} flip={true}>
          <Text fontSize="md" fontWeight={400}>
            Celem psychoterapii jest lepsze życie. Psychoterapia uczy radzenia sobie z emocjami, trudnościami
            oraz trwale wpływa na zmianę naszych zachowań. Dzięki tej metodzie można pokonać lęki, zrozumieć
            swoje emocje, myśli, nawyki, najgłębsze tęsknoty i pragnienia i nauczyć się regulować ich wzajemne
            oddziaływanie. To proces, w którym towarzyszy Ci terapeuta – jednak pracę wykonujesz samodzielnie.
            To sposób wymagający czasu oraz zbudowania zaufanej relacji z terapeutą.
          </Text>
        </Section>

        {/* Third section */}
        <Section
          title="Psychoterapia online jest wygodna i równie skuteczna jak spotkania w gabinecie"
          img={section3Image}
          barColor="brand.yellow.400"
        >
          <Text fontSize="md" fontWeight={400}>
            Badania kliniczne dowodzą, że terapia stacjonarna przynosi takie same efekty jak terapia online.
            Skuteczność w obu przypadkach wynika z otrzymanego wsparcia, relacji z terapeutą oraz komfortowych
            warunków spotkania. Nie ma żadnych przeszkód, aby terapię prowadzić zdalnie, z dowolnego miejsca.
          </Text>
        </Section>
      </Container>
      <Box bg="brand.green.800" color="white">
        <Container
          maxW="container.xl"
          as={Stack}
          direction="column"
          alignItems="center"
          py={24}
          spacing={[8, null, 24]}
        >
          <Heading fontWeight={500} textAlign="center">
            Chcesz sobie pomóc?
            <br />
            Umów się na pierwszą wizytę!
          </Heading>
          <ArrowButton
            as={Link}
            to={PSYCHOTHERAPISTS}
            bg="brand.yellow.600"
            hoverBg="brand.yellow.700"
            color="white"
            height={55}
            fontSize="lg"
            fontWeight={500}
          >
            <Text px={8} fontSize="lg">
              Wybierz terapeutę
            </Text>
          </ArrowButton>
        </Container>
      </Box>
      <OurTherapists />
      <NotForMe title="Psychoterapia nie jest dla Ciebie?" selected="psychotherapy" />
    </>
  )
}

export default PsychotherapyLandingPage
