import * as React from 'react'

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import Logo from '@/common/logo'

type Props = {
  children: React.ReactNode
  open: boolean
  onClose: () => void
}

const OrderModal = ({children, open, onClose}: Props) => (
  <Modal isOpen={open} onClose={onClose} size="6xl">
    <ModalOverlay />
    <ModalContent px={[2, null, 8]} py={6} minH="60vh">
      <ModalHeader>
        <Logo />
      </ModalHeader>
      <ModalCloseButton top={12} right={[8, null, 12]} boxSize={12} fontSize="30px" rounded="full" />
      <ModalBody my={[2, null, 8]} as={Flex} align="center" justify="center">
        {children}
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default OrderModal
