import * as React from 'react'

import {CloseIcon} from '@chakra-ui/icons'
import {Button, Flex, Skeleton, Stack, Tag, TagLabel, Wrap} from '@chakra-ui/react'

import {Category} from '@/api/models'
import {TableState} from '@/common/data-table/use-table-state/types'
import useSupabaseQuery from '@/common/use-custom-query'

type Props = {
  tableState: TableState
  table: string
}

const CategoriesFilter = ({tableState, table}: Props) => {
  const [selected, setSelected] = React.useState<string[]>([])
  const {loading, data} = useSupabaseQuery<Category>(
    React.useMemo(
      () => ({
        table,
        fields: '*',
        match: {show_as_filter: true},
        order: 'name',
      }),
      [table]
    )
  )

  React.useEffect(() => {
    tableState.setPage(0)
    tableState.setFilter((b) => (selected.length ? b.overlaps('categories', selected) : b))
  }, [selected]) // eslint-disable-line

  const processedData = React.useMemo(
    () =>
      data
        ?.map((c) => ({...c, selected: selected.includes(c.name)}))
        .sort((a, b) => +b.selected - +a.selected) ?? [],
    [data, selected]
  )

  const handleToggle = React.useCallback(
    (name: string) => {
      if (selected.includes(name)) {
        setSelected(selected.filter((v) => v !== name))
      } else {
        setSelected([...selected, name])
      }
    },
    [selected]
  )

  const clearFilters = React.useCallback(() => setSelected([]), [])

  if (loading) {
    return (
      <Flex align="center">
        <Skeleton width="100%" height="44px" />
      </Flex>
    )
  }

  return (
    <Stack align="flex-start" spacing={4}>
      <Wrap spacing={[2, null, 4]}>
        {processedData?.map((c, i) => (
          <Tag
            key={i}
            size="lg"
            rounded="full"
            variant="solid"
            background={c.selected ? 'brand.green.600' : 'brand.gray.100'}
            color={c.selected ? 'white' : 'black'}
            _hover={
              c.selected
                ? {
                    background: 'brand.green.700',
                  }
                : {
                    background: 'brand.gray.200',
                  }
            }
            fontWeight={300}
            px={5}
            py={3}
            cursor="pointer"
            userSelect="none"
            onClick={() => handleToggle(c.name)}
            transitionDuration="normal"
            transitionProperty="common"
          >
            <TagLabel>{c.name}</TagLabel>
          </Tag>
        ))}
      </Wrap>
      {!!selected.length && (
        <Button variant="link" size="sm" color="black" onClick={clearFilters}>
          <CloseIcon boxSize={3} mr={2} />
          Wyczyść wszystkie filtry
        </Button>
      )}
    </Stack>
  )
}

export default CategoriesFilter
