import React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  ModalFooter,
  HStack,
  Button,
} from '@chakra-ui/react'

import {SupportGroupCategory} from '@/api/models'
import useEditorModalState from '@/common/use-editor-modal'
import {CommonModalProps} from '@/utils/types'

import {emptySupportGroupCaterogy} from '../constants'

const SupportGroupCategoryEditorModal = ({
  onClose,
  open,
  onComplete,
  item,
}: CommonModalProps & {item: SupportGroupCategory | null}) => {
  const {input, handleInputChange, handleUpsert, loading} = useEditorModalState<SupportGroupCategory>({
    table: 'support_group_categories',
    item,
    emptyInput: emptySupportGroupCaterogy,
    open,
    onComplete,
    onClose,
  })

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj kategorię' : 'Utwórz kategorię'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="4">
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa kategorii</FormLabel>
              <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <Checkbox
                name="show_as_filter"
                isChecked={input.show_as_filter ?? false}
                onChange={handleInputChange}
              >
                Pokazuj jako filtr
              </Checkbox>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={!input.name}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SupportGroupCategoryEditorModal
