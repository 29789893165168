import * as React from 'react'

import {Stack} from '@chakra-ui/react'
import Select, {MultiValue, SingleValue} from 'react-select'

import {TherapistSpecialty, parseTherapistService, TherapistService} from '@/api/models'
import {therapistsListStyles, therapistsListComponents} from '@/common/theme/components/select'
import useSupabaseQuery from '@/common/use-custom-query'
import {formatDurationShort} from '@/utils/duration'
import {SelectOption} from '@/utils/types'

export type TherapistsFilter = {
  service?: TherapistService // service id
  specialties: string[] // specialties names
}

type Props = {
  value: TherapistsFilter
  onChange: (v: TherapistsFilter) => void
}

const Filters = ({value, onChange}: Props) => {
  // Specialties
  const {data: specialties, loading: specialtiesLoading} = useSupabaseQuery<TherapistSpecialty>(
    React.useMemo(
      () => ({
        table: 'therapist_specialties',
        fields: '*',
        match: {show_as_filter: true},
      }),
      []
    )
  )
  const specialtiesOptions = React.useMemo(
    () => specialties?.map((s) => ({label: s.name, value: s.name})),
    [specialties]
  )
  const handleSpecialtiesChange = React.useCallback(
    (v: MultiValue<SelectOption>) => {
      onChange({...value, specialties: Array.isArray(v) ? v.map((s) => s.value) : []})
    },
    [value, onChange]
  )

  // Services
  const {data: services, loading: servicesLoading} = useSupabaseQuery<TherapistService>({
    table: 'available_therapist_service_types',
    fields: '*',
    order: 'order',
    parsingFunction: parseTherapistService,
  })
  const servicesOptions = React.useMemo(
    () =>
      services?.map((s) => ({
        label: `${s.name} (${s.price / 100}zł / ${formatDurationShort(s.meeting_duration)})`,
        value: s.id,
      })) ?? [],
    [services]
  )
  const handleServiceChange = React.useCallback(
    (v: SingleValue<SelectOption>) => {
      onChange({...value, service: services?.find((s) => s.id === v?.value)})
    },
    [value, onChange, services]
  )

  React.useEffect(() => {
    if (value.service || !services?.length) return

    onChange({...value, service: services[0]})
  }, [value, onChange, services])

  return (
    <Stack
      w="100%"
      direction={['column', null, 'row']}
      justifyContent="center"
      mb={16}
      spacing={2}
      px={[0, null, 16]}
    >
      <Select
        placeholder="Wybierz specjalizację"
        value={specialtiesOptions?.filter((s) => value.specialties.includes(s.value))}
        options={specialtiesOptions}
        onChange={handleSpecialtiesChange}
        isLoading={specialtiesLoading}
        isMulti={true}
        isClearable={true}
        components={therapistsListComponents}
        styles={therapistsListStyles}
      />
      <Select
        placeholder="Wybierz rodzaj sesji"
        value={servicesOptions.find((o) => o.value === value.service?.id)}
        options={servicesOptions}
        onChange={handleServiceChange}
        isLoading={servicesLoading}
        isSearchable={false}
        components={therapistsListComponents}
        styles={therapistsListStyles}
      />
    </Stack>
  )
}

export default Filters
