import {add, parse} from 'date-fns'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '.'
import {Gender, PGTimeRangeSlot, User} from './models'

export const emptyPGTimeRange = {from: new Date(), to: add(new Date(), {hours: 1})}

// This function parses postgres 'tstzrange' type into object containing 'from' and 'to' timestamps.
// Inclusive and exclusive bounds do not matter in our use-case, so we do not distinguish between them
export const parsePgTimestampRange = (t: string | null) => {
  // The date string is sliced, removing first and last index, so it must be at least 3 chars long
  if (!t || t.length < 3) {
    return undefined
  }
  const parts = t.slice(1, -1).split(',')
  if (parts.length !== 2 || parts[0].length < 3 || parts[1].length < 3) {
    return undefined
  }
  const s: PGTimeRangeSlot = {
    from: parse(parts[0].replace(/^"(.*)"$/, '$1'), 'yyyy-MM-dd HH:mm:ssx', new Date()),
    to: parse(parts[1].replace(/^"(.*)"$/, '$1'), 'yyyy-MM-dd HH:mm:ssx', new Date()),
  }
  if (isNaN(s.from.getTime()) || isNaN(s.to.getTime())) {
    return undefined
  }
  return s
}

export const comparePgTimestampRange = (s: PGTimeRangeSlot, t: PGTimeRangeSlot) => {
  return s.from.getTime() === t.from.getTime() && s.to.getTime() === t.to.getTime()
}

export const stringifyPgTimestampRange = (s: PGTimeRangeSlot) => {
  return `["${s.from.toUTCString()}", "${s.to.toUTCString()}")`
}

export type UserLoginDetails = Pick<User, 'id' | 'email' | 'provider'>

export const genders: Record<Gender, string> = {
  female: 'Kobieta',
  male: 'Mężczyzna',
}

export const uploadFile = async (file: File, bucket: string, scope?: string) => {
  const filename = `${uuidv4()}/${file.name}`
  const filepath = scope ? `/${scope}/${filename}` : `/${filename}`

  const {error} = await supabase.storage.from(bucket).upload(filepath.substr(1), file, {upsert: true})
  if (error) {
    throw error
  }
  const {data, error: signedURLError} = await supabase.storage
    .from(bucket)
    .createSignedUrl(filepath.substr(1), 300)
  if (signedURLError) {
    throw signedURLError
  }
  if (!data || !data.signedUrl) {
    throw new Error('Empty signed url response')
  }

  return [filepath, data.signedUrl]
}
