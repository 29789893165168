import * as React from 'react'

import {Box, Container, Grid, GridItem, Flex, Heading, Skeleton, Text, useToast} from '@chakra-ui/react'
import {Element, scroller} from 'react-scroll'

import {ViewBlogPost} from '@/api/models'
import {Pagination, usePagination} from '@/common/pagination'
import useSupabaseQuery from '@/common/use-custom-query'
import Newsletter from '@/home/newsletter'

import BlogItem from './blog-item'
import LatestPost from './latest-post'

const countPerPage = 6

const Blog = () => {
  const [page, _setPage] = React.useState<number>(1)
  const {loading, data, rows} = useSupabaseQuery<ViewBlogPost>({
    fields: '*',
    itemsPerPage: countPerPage,
    pageNumber: page - 1,
    table: 'blog_posts_view',
  })

  const {items} = usePagination({
    countPerPage,
    maxShownPages: 7,
    page,
    totalCount: rows ?? 0,
  })

  const setPage = React.useCallback(
    (page: number) => {
      _setPage(page)
      scroller.scrollTo('blog-posts-list' as any, {
        duration: 500,
        offset: -150,
        smooth: true,
      })
    },
    [_setPage]
  )

  return (
    <>
      <Container maxW="container.xl" px={[0, null, 4]}>
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(12, 1fr)']}
          mt={[8, null, 16]}
          mb={[8, null, 16]}
          px={[4, null, 0]}
        >
          <GridItem colStart={[0, null, 3]} colSpan={[1, null, 2]}>
            <Heading as="h1" fontSize="5xl" lineHeight={1} mb={[4, null, 0]}>
              Blog
            </Heading>
          </GridItem>
          <GridItem colSpan={6}>
            <Text fontSize="md" fontWeight={300}>
              Masz pytania na temat psychologii, psychoterapii lub rozwoju osobistego? Tutaj znajdziesz
              materiały i inspiracje, które pomogą Ci zacząć działać. Czytaj porady naszych ekspertów i
              dojrzewaj do świadomej zmiany.
            </Text>
          </GridItem>
          <Element name="blog-posts-list" />
        </Grid>
        <Box width="100%">
          <LatestPost />
        </Box>
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(12, 1fr)']}
          mb={16}
          rowGap={16}
          columnGap={8}
          px={[8, null, 0]}
        >
          {loading
            ? Array.from(Array(countPerPage), (_, i) => (
                <GridItem key={i} colSpan={[1, null, i === 0 || i === 5 ? 6 : 3]}>
                  <Skeleton width="100%" height="586px" />
                </GridItem>
              ))
            : data?.map((post, i) => (
                <GridItem key={i} colSpan={[1, null, i === 0 || i === 5 ? 6 : 3]}>
                  <BlogItem
                    key={i}
                    imageBorderRadius={i === 2 || i === 4 ? '0px 90px' : '90px 0px'}
                    isWide={i === 0 || i === 5}
                    item={post}
                  />
                </GridItem>
              ))}
        </Grid>
        <Flex direction="row" justifyContent="center" mb={[0, null, 16]}>
          <Pagination
            countPerPage={countPerPage}
            loading={page === undefined}
            items={items}
            page={page}
            setPage={setPage}
          />
        </Flex>
      </Container>
      <Newsletter />
    </>
  )
}

export default Blog
