import * as React from 'react'

import {AspectRatio, Box, Flex, Image, Stack, Text} from '@chakra-ui/react'
import {format} from 'date-fns'
import {Link, generatePath} from 'react-router-dom'

import {MyMeeting} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import {MEETING} from '@/router/paths'

const JoinableMeeting = ({meeting}: {meeting: MyMeeting}) => {
  return (
    <Stack direction={['column', null, 'row']} spacing={4} alignItems="center">
      <Box rounded="full" w="160px" overflow="hidden">
        <AspectRatio ratio={1} w="100%">
          {meeting.therapist_avatar ? (
            <Image src={meeting.therapist_avatar} w="100%" h="100%" objectFit="cover" />
          ) : (
            <Box bg="brand.gray.500" />
          )}
        </AspectRatio>
      </Box>
      <Stack direction="column" spacing={4} flex={1} justifyContent="center">
        <Text fontSize="xl" fontWeight={500} textAlign={['center', null, 'left']}>
          {meeting.therapist_full_name}
        </Text>
        <Stack direction="row" spacing={2} textAlign={['center', null, 'left']}>
          <Text fontWeight={500}>{meeting.name}</Text>
          <Text fontWeight={300}>{format(new Date(meeting.starts_at), "dd.MM.yyy 'godz.' HH:mm")}</Text>
        </Stack>
        <Flex direction="row" justifyContent={['center', null, 'flex-start']}>
          <ArrowButton as={Link} to={generatePath(MEETING, {id: meeting.id})}>
            Rozpocznij rozmowę
          </ArrowButton>
        </Flex>
      </Stack>
    </Stack>
  )
}

export default JoinableMeeting
