export const REACT_APP_SUPABASE_URL = process.env.REACT_APP_SUPABASE_URL || 'http://localhost:8000'
export const REACT_APP_SUPABASE_KEY =
  process.env.REACT_APP_SUPABASE_KEY ||
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICAgInJvbGUiOiAiYW5vbiIsCiAgICAiaXNzIjogInN1cGFiYXNlIiwKICAgICJpYXQiOiAxNjc0MTY5MjAwLAogICAgImV4cCI6IDE4MzE5MzU2MDAKfQ.s3Pn7QOWifViPacFji2CFPfEgJbMLTaCMQ6z7o9mS6M'

// todo: no reason to generate the urls in the client
export const REACT_APP_GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID ||
  '518020672859-m18eiqtg6vcl5nsdgramlhbhvp13u182.apps.googleusercontent.com'

// todo: no reason to not derive this from web base uri - which is unused??? also probably shouldnt be generated in client at all
export const REACT_APP_GOOGLE_AUTH_REDIRECT_URI =
  process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI ||
  'http://localhost:3000/psychotherapy/therapists/google/callback'
export const REACT_APP_GOOGLE_ADMIN_AUTH_REDIRECT_URI =
  process.env.REACT_APP_GOOGLE_ADMIN_AUTH_REDIRECT_URI || 'http://localhost:3000/admin/google/callback'

// todo: no reason for this to be here, move to jwt signing
export const REACT_APP_JITSI_APP_ID =
  process.env.REACT_APP_JITSI_APP_ID || 'vpaas-magic-cookie-9e2e4c9d81cc42cca737263a20c46086'
