import React, {useCallback, useEffect, useState} from 'react'

import {EditIcon, CloseIcon, CheckIcon} from '@chakra-ui/icons'
import {Box, Flex, IconButton, Input, Text, Textarea, TypographyProps} from '@chakra-ui/react'

const EditableText = ({
  initialValue,
  onSave,
  onComplete,
  editable,
  fontSize,
  placeholder,
  multiline,
}: {
  initialValue: string
  onSave: (value: string) => Promise<void>
  onComplete?: () => void
  editable?: boolean
  fontSize?: TypographyProps['fontSize']
  placeholder?: string
  multiline?: boolean
}) => {
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState('')

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const cancelEditing = useCallback(() => {
    setValue(initialValue)
    setIsEditing(false)
  }, [initialValue])

  useEffect(() => {
    if (editable) {
      return
    }
    cancelEditing()
  }, [cancelEditing, editable])

  const handleInputChange = useCallback(({target: {value: v}}) => {
    setValue(v)
  }, [])

  const handleSave = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault()

      setIsSaving(true)
      await onSave(value)
      onComplete && onComplete()
      setIsEditing(false)
      setIsSaving(false)
    },
    [onComplete, onSave, value]
  )

  const handleEdit = useCallback(() => {
    setIsEditing(true)
  }, [])

  const textColor = React.useMemo(() => (editable && !value ? 'gray' : 'unset'), [editable, value])
  const textValue = React.useMemo(
    () => (editable && !value ? placeholder : value),
    [editable, placeholder, value]
  )

  const InputComponent = React.useMemo(() => (multiline ? Textarea : Input), [multiline])

  return isEditing ? (
    <form onSubmit={handleSave}>
      <Flex>
        <InputComponent
          fontSize={fontSize}
          value={value}
          onChange={handleInputChange}
          isDisabled={isSaving}
          placeholder={placeholder}
          resize="none"
        />
        <IconButton
          aria-label=""
          type="submit"
          isLoading={isSaving}
          w="45px"
          ml="1"
          colorScheme="green"
          icon={<CheckIcon />}
        />
        <IconButton aria-label="" onClick={cancelEditing} w="45px" ml="1" icon={<CloseIcon />} />
      </Flex>
    </form>
  ) : (
    <Flex alignItems="center">
      <Text fontSize={fontSize} color={textColor}>
        {textValue}
      </Text>
      {editable && (
        <Box ml="1" cursor="pointer" onClick={handleEdit}>
          <EditIcon />
        </Box>
      )}
    </Flex>
  )
}

export default EditableText
