import * as React from 'react'

import {Box, Container, Flex, Text, useBreakpointValue} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {CONTACT_US} from '@/router/paths'

const ContactSection = () => {
  const buttonHeight = useBreakpointValue({
    base: 38,
    md: 58,
  })
  const buttonFontSize = useBreakpointValue({
    base: 'md',
    md: 'xl',
  })
  const buttonFontWeight = useBreakpointValue({
    base: 500,
    md: 600,
  })
  return (
    <Box backgroundColor="brand.green.800" color="white">
      <Container maxW="container.lg" py={[14, null, 20]} px={[8, null, 4]}>
        <Flex direction={['column', null, 'row']}>
          <Flex px={[4, null, 0]} maxWidth={['none', null, '50%']} flex={1}>
            <Flex direction="column" justifyContent="center" pr={[0, null, 14]}>
              <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} mb={9} lineHeight={1.1}>
                Nie wiesz, co wybrać? Napisz do nas
              </Text>
              <Text fontSize="md" fontWeight={300} mb={[14, null, 8]}>
                Zastanawiasz się, która opcja jest najlepsza dla Ciebie? Napisz do nas! Wspólnie dopasujemy
                najlepsze rozwiązania do tego, co chcesz osiągnąć oraz do etapu życia, na jakim jesteś.
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent={['center', null, 'flex-end']} alignItems="center" flex={1}>
            <ArrowButton
              as={Link}
              to={CONTACT_US}
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={buttonHeight}
              fontSize={buttonFontSize}
              fontWeight={buttonFontWeight}
            >
              <Text px={8}>Skontaktuj się</Text>
            </ArrowButton>
          </Flex>
        </Flex>
      </Container>
    </Box>
  )
}

export default ContactSection
