import React from 'react'

import {
  Box,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react'
import validator from 'validator'

import {UserBillingAddress} from '@/api/models'
import {selectProfile} from '@/auth/state'
import {useAppSelector} from '@/store'

import {countryToPostalCodeLocale, supportedCountries} from './constants'
import {
  INVALID_CITY_INPUT,
  INVALID_COMPANY_INPUT,
  INVALID_EMAIL_INPUT,
  INVALID_FIRST_NAME_INPUT,
  INVALID_LAST_NAME_INPUT,
  INVALID_NIP_INPUT,
  INVALID_PHONE_NUMBER_INPUT,
  INVALID_POSTAL_CODE_INPUT,
  INVALID_STREET_INPUT,
} from './errors'
import {UpdateResponse} from './types'
import {isNIPValid} from './utils'

type Props = {
  index: number
  value: UserBillingAddress
  basicValue?: UserBillingAddress
  response?: UpdateResponse
  type: string
  disabled?: boolean
  onChange: (value: UserBillingAddress, index: number) => void
}

const AddressItem = ({value, basicValue, type, onChange, disabled, response, index}: Props) => {
  const profile = useAppSelector(selectProfile)

  const isEmailValid = React.useMemo(() => !value.email || validator.isEmail(value.email), [value.email])
  const isPhoneNumberValid = React.useMemo(
    // someone can use UK phone number and select Poland address and vice versa
    () => !value.phone_number || validator.isMobilePhone(value.phone_number, ['pl-PL', 'en-GB']),
    [value.phone_number]
  )

  React.useEffect(() => {
    if (value.email || !profile?.email) return
    onChange({...value, email: profile.email}, index)
  }, [onChange, profile]) // eslint-disable-line

  const isNipValid = React.useMemo(
    () => isNIPValid((value.use_basic_address ? basicValue?.country : value.country) || 'pl', value.nip),
    [value.use_basic_address, value.country, value.nip, basicValue]
  )

  const isPostalCodeValid = React.useMemo(
    () =>
      !value.postal_code ||
      validator.isPostalCode(value?.postal_code, countryToPostalCodeLocale[value.country] || 'any'),
    [value.postal_code, value.country]
  )

  const handleCompanyChange = React.useCallback(
    ({target: {value: company}}) => {
      onChange({...value, company}, index)
    },
    [index, onChange, value]
  )

  const handleFirstNameChange = React.useCallback(
    ({target: {value: first_name}}) => {
      onChange({...value, first_name}, index)
    },
    [index, onChange, value]
  )

  const handleLastNameChange = React.useCallback(
    ({target: {value: last_name}}) => {
      onChange({...value, last_name}, index)
    },
    [index, onChange, value]
  )

  const handlePhoneNumberChange = React.useCallback(
    ({target: {value: phone_number}}) => {
      onChange({...value, phone_number}, index)
    },
    [index, onChange, value]
  )

  const handleNIPChange = React.useCallback(
    ({target: {value: nip}}) => {
      onChange({...value, nip}, index)
    },
    [index, onChange, value]
  )

  const handleCityChange = React.useCallback(
    ({target: {value: city}}) => {
      onChange({...value, city}, index)
    },
    [index, onChange, value]
  )

  const handleCountryChange = React.useCallback(
    ({target: {value: country}}) => {
      onChange({...value, country}, index)
    },
    [index, onChange, value]
  )

  const handleAddressChange = React.useCallback(
    ({target: {value: address}}) => {
      onChange({...value, address}, index)
    },
    [index, onChange, value]
  )

  const handlePostalCodeChange = React.useCallback(
    ({target: {value: postal_code}}) => {
      onChange({...value, postal_code}, index)
    },
    [index, onChange, value]
  )

  const handleEmailChange = React.useCallback(
    ({target: {value: email}}) => {
      onChange({...value, email}, index)
    },
    [index, onChange, value]
  )

  const handleWantReceiveInvoiceChange = React.useCallback(
    ({target: {checked: requests_company_invoice}}) => onChange({...value, requests_company_invoice}, index),
    [index, onChange, value]
  )

  const handleInvoiceUseBasicAddressChange = React.useCallback(
    ({target: {checked: use_basic_address}}) => onChange({...value, use_basic_address}, index),
    [index, onChange, value]
  )

  const handleShippingUseBasicAddressChange = React.useCallback(
    ({target: {checked: same_shipping_address}}) => onChange({...value, same_shipping_address}, index),
    [index, onChange, value]
  )

  return (
    <Stack spacing="5" py="5">
      {type === 'invoice' && (
        <>
          <FormControl id="want-receive-invoice" isDisabled={disabled}>
            <HStack>
              <Checkbox
                isDisabled={disabled}
                isChecked={value.requests_company_invoice ?? false}
                onChange={handleWantReceiveInvoiceChange}
              />
              <Text>Chcę otrzymać fakturę na firmę</Text>
            </HStack>
          </FormControl>
          <Stack direction={['column', null, 'row']} spacing="5">
            <FormControl
              id="company"
              isDisabled={disabled || (type === 'invoice' && !value.requests_company_invoice)}
              isInvalid={response?.index === index && response?.error_code === INVALID_COMPANY_INPUT}
              isRequired={true}
            >
              <FormLabel>Firma</FormLabel>
              <Input value={value?.company ?? ''} onChange={handleCompanyChange} />
              <FormErrorMessage>Nieprawidłowa nazwa firmy</FormErrorMessage>
            </FormControl>
            <FormControl
              id="nip"
              isDisabled={disabled || (type === 'invoice' && !value.requests_company_invoice)}
              isInvalid={
                !isNipValid || (response?.index === index && response?.error_code === INVALID_NIP_INPUT)
              }
              isRequired={true}
            >
              <FormLabel>NIP</FormLabel>
              <Input value={value?.nip ?? ''} onChange={handleNIPChange} />
              <FormErrorMessage>Nieprawidłowy NIP</FormErrorMessage>
            </FormControl>
          </Stack>
        </>
      )}
      {(type === 'basic' || type === 'shipping') && (
        <>
          <Stack direction={['column', null, 'row']} spacing="5">
            <FormControl
              id="firstName"
              isDisabled={disabled || (type === 'shipping' && value.use_basic_address)}
              isInvalid={response?.index === index && response?.error_code === INVALID_FIRST_NAME_INPUT}
              isRequired={true}
            >
              <FormLabel>Imię</FormLabel>
              <Input value={value?.first_name ?? ''} onChange={handleFirstNameChange} />
              <FormErrorMessage>Nieprawidłowe znaki w imieniu</FormErrorMessage>
            </FormControl>
            <FormControl
              id="lastName"
              isDisabled={disabled || (type === 'shipping' && value.use_basic_address)}
              isInvalid={response?.index === index && response?.error_code === INVALID_LAST_NAME_INPUT}
              isRequired={true}
            >
              <FormLabel>Nazwisko</FormLabel>
              <Input value={value?.last_name ?? ''} onChange={handleLastNameChange} />
              <FormErrorMessage>Nieprawidłowe znaki w nazwisku</FormErrorMessage>
            </FormControl>
          </Stack>
          <Stack direction={['column', null, 'row']} spacing="5">
            <FormControl
              id="phoneNumber"
              isInvalid={
                !isPhoneNumberValid ||
                (response?.index === index && response?.error_code === INVALID_PHONE_NUMBER_INPUT)
              }
              isDisabled={disabled || (type === 'shipping' && value.use_basic_address)}
              isRequired={true}
            >
              <FormLabel>Numer telefonu</FormLabel>
              <Input value={value?.phone_number ?? ''} onChange={handlePhoneNumberChange} />
              <FormErrorMessage>Nieprawidłowy numer telefonu</FormErrorMessage>
            </FormControl>
            <Box w="100%">
              {type === 'basic' && (
                <FormControl
                  id="email"
                  isInvalid={
                    !isEmailValid ||
                    (response?.index === index && response?.error_code === INVALID_EMAIL_INPUT)
                  }
                  isDisabled={disabled}
                  isRequired={true}
                >
                  <FormLabel>Email</FormLabel>
                  <Input value={value?.email ?? ''} type="email" onChange={handleEmailChange} />
                  <FormErrorMessage>Nieprawidłowy email</FormErrorMessage>
                </FormControl>
              )}
            </Box>
          </Stack>
        </>
      )}
      {type === 'invoice' && (
        <FormControl id="invoice-use-basic-address" isDisabled={disabled}>
          <HStack>
            <Checkbox
              isDisabled={disabled || !value.requests_company_invoice}
              isChecked={value.use_basic_address ?? false}
              onChange={handleInvoiceUseBasicAddressChange}
            />
            <Text>Adres jest taki sam jak dane rozliczeniowe</Text>
          </HStack>
        </FormControl>
      )}
      <Stack direction={['column', null, 'row']} spacing="5">
        <FormControl
          id="address"
          isDisabled={
            disabled ||
            ((type === 'invoice' || type === 'shipping') &&
              (value.use_basic_address || (type === 'invoice' && !value.requests_company_invoice)))
          }
          isInvalid={response?.index === index && response?.error_code === INVALID_STREET_INPUT}
          isRequired={true}
        >
          <FormLabel>Adres, numer domu, numer mieszkania</FormLabel>
          <Input value={value?.address ?? ''} onChange={handleAddressChange} />
          <FormErrorMessage>Nieprawidłowy adres</FormErrorMessage>
        </FormControl>
        <FormControl
          id="postal_code"
          isInvalid={
            !isPostalCodeValid ||
            (response?.index === index && response?.error_code === INVALID_POSTAL_CODE_INPUT)
          }
          isDisabled={
            disabled || (type === 'invoice' && (value.use_basic_address || !value.requests_company_invoice))
          }
          isRequired={true}
        >
          <FormLabel>Kod pocztowy</FormLabel>
          <Input value={value?.postal_code ?? ''} onChange={handlePostalCodeChange} />
          <FormErrorMessage>Nieprawidłowy kod pocztowy</FormErrorMessage>
        </FormControl>
      </Stack>
      <Stack direction={['column', null, 'row']} spacing="5" w="100%">
        <FormControl
          id="city"
          isDisabled={
            disabled || (type === 'invoice' && (value.use_basic_address || !value.requests_company_invoice))
          }
          isInvalid={response?.index === index && response?.error_code === INVALID_CITY_INPUT}
          isRequired={true}
        >
          <FormLabel>Miasto</FormLabel>
          <Input value={value?.city ?? ''} onChange={handleCityChange} />
          <FormErrorMessage>Nieprawidłowa nazwa miasta</FormErrorMessage>
        </FormControl>
        <FormControl
          id="country"
          isDisabled={
            disabled || (type === 'invoice' && (value.use_basic_address || !value.requests_company_invoice))
          }
          isRequired={true}
        >
          <FormLabel>Kraj</FormLabel>
          <Select value={value?.country || 'pl'} onChange={handleCountryChange}>
            {Object.entries(supportedCountries).map(([k, v]) => (
              <option key={k} value={k}>
                {v}
              </option>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {type === 'basic' && (
        <FormControl id="shipping-use-basic-address" isDisabled={disabled}>
          <HStack>
            <Checkbox
              isDisabled={disabled}
              isChecked={value.same_shipping_address ?? false}
              onChange={handleShippingUseBasicAddressChange}
            />
            <Text>Dane do wysyłki takie same jak adres rozliczeniowy</Text>
          </HStack>
        </FormControl>
      )}
    </Stack>
  )
}

export default AddressItem
