import React from 'react'

import {AddIcon} from '@chakra-ui/icons'
import {Flex, IconButton, SkeletonText, Stack, Text, useDisclosure} from '@chakra-ui/react'
import {generatePath} from 'react-router-dom'

import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'
import {COURSES, COURSE_SHOWCASE} from '@/router/paths'

import CreateRelatedBlogPostCourseModal from './create-related-course-modal'
import RelatedBlogPostItem, {RelatedItem} from './related-item'

const RelatedBlogPostCourses = ({editing, forBlogPost}: {editing: boolean; forBlogPost: number}) => {
  const {loading, data, fetch} = useSupabaseQuery<RelatedItem>(
    React.useMemo(
      () => ({
        fields: '*',
        table: 'blog_post_suggested_courses_view',
        match: {blog_post_id: forBlogPost},
        errSnackbarTitle: 'Nie udało się pobrać powiązanych szkoleń',
      }),
      [forBlogPost]
    )
  )
  const {
    isOpen: isAddSuggestionOpen,
    onOpen: onAddSuggestionOpen,
    onClose: onAddSuggestionClose,
  } = useDisclosure()

  const {
    onClose: onDeleteDialogClose,
    onOpen: onDeleteDialogOpen,
    value: deleteDialogValue,
  } = useValueDisclosure<string>()

  if (!loading && !data?.length && !editing) {
    return null
  }

  return (
    <>
      <DeleteResourceModal
        table="blog_post_suggested_courses"
        id={deleteDialogValue ?? ''}
        onClose={onDeleteDialogClose}
        open={!!deleteDialogValue}
        onComplete={fetch}
      />
      <CreateRelatedBlogPostCourseModal
        blogPost={forBlogPost}
        open={isAddSuggestionOpen}
        onClose={onAddSuggestionClose}
        onComplete={fetch}
      />
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Text fontSize="xl">Znajdź warsztat dla siebie</Text>
        {editing && (
          <IconButton
            size="xs"
            onClick={onAddSuggestionOpen}
            icon={<AddIcon />}
            aria-label="Add suggestion"
            colorScheme="green"
          />
        )}
      </Flex>
      <SkeletonText noOfLines={10} isLoaded={!loading} mb={8}>
        <Stack direction="column" spacing={4}>
          {data?.map((c, i) => (
            <RelatedBlogPostItem
              key={i}
              item={c}
              editing={editing}
              to={c.slug ? generatePath(COURSE_SHOWCASE, {id: c.id, slug: c.slug}) : COURSES}
              onDelete={onDeleteDialogOpen}
            />
          ))}
        </Stack>
      </SkeletonText>
    </>
  )
}

export default RelatedBlogPostCourses
