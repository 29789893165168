import React from 'react'

import {
  Button,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import {
  PlateEditor,
  PlateRenderElementProps,
  createPluginFactory,
  deleteFragment,
  getParent,
  getSelectionText,
  insertNodes,
  isElement,
  unwrapNodes,
  usePlateEditorState,
} from '@udecode/plate'
import randomstring from 'randomstring'
import {MdLink} from 'react-icons/md'
import {Path, Range, Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import isURL from 'validator/lib/isURL'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {LinkElement} from '../custom-types'
import {EditorContext} from '../editor-context'
import {isBlockActive} from '../utils'

export const LinkRenderer = ({attributes, element, children}: PlateRenderElementProps) => {
  const {readOnly} = React.useContext(EditorContext)

  // TODO: floating link editor would be nicer than modal
  return (
    <Link
      {...attributes}
      href={element.href}
      target="_blank"
      color="brand.green.600"
      textDecoration="underline"
      cursor={readOnly ? 'pointer' : 'text'}
    >
      {children}
    </Link>
  )
}

const removeLink = (editor: PlateEditor) => {
  unwrapNodes(editor, {
    match: (n) => isElement(n) && n.type === 'link',
  })
}

const insertLink = (editor: PlateEditor, href: string, text?: string) => {
  if (!href) return

  const {selection} = editor
  const link: LinkElement = {
    type: 'link',
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    href,
    children: [{text: text || href}],
  }

  ReactEditor.focus(editor)

  if (selection) {
    const parent = getParent(editor, selection.focus?.path)

    // Update the Link node if we're inserting a new link node inside of another
    // link.
    if (parent && parent[0].type === 'link') {
      deleteFragment(editor, {at: selection.focus?.path, unit: 'block'})

      insertNodes<LinkElement>(editor, link, {
        at: parent[1],
        select: true,
      })

      return
    }

    if (parent && editor.isVoid(parent[0])) {
      // Insert the new link after the void node
      insertNodes<LinkElement>(editor, link, {
        at: Path.next(parent[1]),
        select: true,
      })
    } else if (Range.isCollapsed(selection)) {
      // Insert the new link in our last known location
      insertNodes(editor, link, {select: true})
    } else {
      Transforms.delete(editor)
      insertNodes(editor, link, {select: true})
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    insertNodes(editor, link)
  }
}

export const InsertLinkButton = () => {
  const editor = usePlateEditorState()!
  const {isOpen, onOpen, onClose} = useDisclosure()

  const [href, setHref] = React.useState('')
  const [text, setText] = React.useState('')
  const [editing, setEditing] = React.useState(false)

  const handleSubmit = React.useCallback(() => {
    if (!href || !isURL(href)) return
    insertLink(editor, href, text)
    onClose()
  }, [href, text, editor, onClose])

  const handleHrefChange = React.useCallback((e) => setHref(e.target.value.trim()), [])
  const handleTextChange = React.useCallback((e) => setText(e.target.value), [])

  const handleClick = React.useCallback(() => {
    setEditing(false)
    setHref('')
    setText(getSelectionText(editor))

    if (isBlockActive(editor, 'link') && editor.selection) {
      const parent = getParent(editor, editor.selection.focus?.path)
      if (parent && parent[0].type === 'link') {
        setEditing(true)
        setHref(parent[0].href)
        parent[0].children.length && setText(parent[0].children[0].text ?? '')
      }
    }

    onOpen()
  }, [editor, onOpen])

  const handleRemove = React.useCallback(() => {
    if (!isBlockActive(editor, 'link')) return
    removeLink(editor)
    onClose()
  }, [editor, onClose])

  return (
    <>
      <Tooltip label="Hiperłącze">
        <IconButton
          variant="outline"
          isActive={isBlockActive(editor, 'link')}
          onClick={handleClick}
          aria-label="insert-link"
          icon={<MdLink />}
          borderWidth={0}
          fontSize="20px"
        />
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editing ? 'Edytuj' : 'Dodaj'} hiperłącze</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              <Input placeholder="Link" variant="filled" value={href} onChange={handleHrefChange} />
              <Input
                placeholder="Tekst do wyświetlenia"
                variant="filled"
                value={text}
                onChange={handleTextChange}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            {editing && (
              <Button colorScheme="red" variant="outline" mr={3} onClick={handleRemove}>
                Usuń
              </Button>
            )}
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Anuluj
            </Button>
            <Button colorScheme="green" onClick={handleSubmit} disabled={!href || !isURL(href)}>
              {editing ? 'Aktualizuj' : 'Wstaw'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const createLinkPlugin = createPluginFactory({
  key: 'link',
  type: 'link',
  component: LinkRenderer,
  isElement: true,
  isInline: true,
  editor: {
    insertData: {
      format: 'text/plain',
      getFragment: ({data}) => [
        {
          children: [{text: data}],
          id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
          type: 'link',
          href: data,
        },
      ],
      query: ({data}) => isURL(data.trim()),
    },
  },
})
