import * as React from 'react'

import {Center, Stack, Heading} from '@chakra-ui/layout'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {HOME} from '@/router/paths'

const NotFoundView = () => {
  return (
    <Center height="82vh">
      <Stack spacing={4} align="center">
        <Heading>Coś poszło nie tak...</Heading>
        <ArrowButton
          w="100%"
          maxW="370px"
          height={55}
          fontWeight={500}
          flipped={true}
          bg="brand.yellow.600"
          hoverBg="brand.yellow.700"
          color="white"
          fontSize="2xl"
          as={Link}
          to={HOME}
        >
          Wróć na stronę główną
        </ArrowButton>
      </Stack>
    </Center>
  )
}

export default NotFoundView
