import React from 'react'

import {ViewIcon, DeleteIcon} from '@chakra-ui/icons'
import {Button, Flex, HStack, IconButton, Link, Text} from '@chakra-ui/react'

import {TherapistCertificate} from '../types'

type Props = {
  certificate: TherapistCertificate
  onVisibilityChange: (id: string, visible: boolean) => Promise<void>
  onDelete: (id: string) => void
  isDisabled?: boolean
}

const TherapistCertificateItem = ({certificate, onVisibilityChange, onDelete, isDisabled}: Props) => {
  const handleVisibilityChange = React.useCallback(() => {
    onVisibilityChange(certificate.id, !certificate.public)
  }, [certificate, onVisibilityChange])

  const handleDelete = React.useCallback(() => onDelete(certificate.id), [certificate, onDelete])

  const previewButtonAs = React.useMemo(() => (isDisabled ? undefined : Link), [isDisabled])

  const previewTextColor = React.useMemo(() => (isDisabled ? 'whiteAlpha.600' : undefined), [isDisabled])

  return (
    <Flex justifyContent="space-between">
      <HStack minW="0">
        <Button
          isDisabled={isDisabled}
          as={previewButtonAs}
          target="_blank"
          href={certificate.signed_path}
          aria-label="pdf"
          leftIcon={<ViewIcon />}
        >
          Podgląd
        </Button>
        <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" color={previewTextColor}>
          {certificate.name}
        </Text>
      </HStack>
      <HStack>
        <IconButton
          isDisabled={isDisabled}
          aria-label="delete"
          icon={<ViewIcon />}
          onClick={handleVisibilityChange}
          colorScheme={certificate.public ? 'green' : 'red'}
          variant="ghost"
        />
        <IconButton
          isDisabled={isDisabled}
          aria-label="delete"
          icon={<DeleteIcon />}
          onClick={handleDelete}
          colorScheme="red"
          variant="ghost"
        />
      </HStack>
    </Flex>
  )
}

export default TherapistCertificateItem
