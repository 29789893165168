import * as React from 'react'

import {Grid, Heading, Stack, Text} from '@chakra-ui/react'

import {MySupportGroupMeeting} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'
import {LoadingSpotlightItem} from '@/user/components/spotlight-item'
import {groupMeetings} from '@/user/meetings/utils'
import PlannedSupportGroupMeeting from '@/user/support-groups/meetings/planned'

import JoinableSupportGroupMeeting from './joinable'

const SupportGroupMeetings = () => {
  const {data, loading} = useSupabaseQuery<MySupportGroupMeeting>(
    React.useMemo(
      () => ({
        table: 'my_support_group_meetings',
        fields: '*',
        order: 'joinable_from',
        errSnackbarTitle: 'Nie udało się pobrać nadchodzących spotkań grup wsparcia',
        filter: (b) =>
          b.gte('joinable_to', new Date().toUTCString()).or('role.eq.host,role.eq.substitute_host'),
      }),
      []
    )
  )

  const {joinable, planned} = React.useMemo(() => groupMeetings(data), [data])

  return (
    <Stack spacing={12}>
      <Heading size="md">Grupy wsparcia</Heading>
      {loading ? (
        <LoadingSpotlightItem />
      ) : !data?.length ? (
        <Text>Brak zaplanowanych spotkań</Text>
      ) : (
        <Stack spacing={6}>
          {!!joinable.length && (
            <>
              <Text fontSize="lg" fontWeight={500}>
                Trwające spotkania
              </Text>
              {joinable.map((m, i) => (
                <JoinableSupportGroupMeeting key={i} item={m} />
              ))}
            </>
          )}
          {!!planned.length && (
            <>
              <Text fontSize="lg" fontWeight={500}>
                Zaplanowane spotkania
              </Text>
              <Grid templateColumns="repeat(3, 1fr)" gap={8}>
                {planned.map((m, i) => (
                  <PlannedSupportGroupMeeting
                    key={i}
                    item={m}
                    borderRadius={(i + 2) % 3 === 0 ? '0px 50px' : '50px 0px'}
                  />
                ))}
              </Grid>
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}

export default SupportGroupMeetings
