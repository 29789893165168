import * as React from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'
import Select, {SingleValue} from 'react-select'

import {supabase} from '@/api'
import {AdminSupportGroupUser, SupabaseRPCResult, SupportGroupRole} from '@/api/models'
import AsyncSelect from '@/common/async-select'
import useEditorModalState from '@/common/use-editor-modal'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps, SelectOption} from '@/utils/types'

import {RoleOption, emptySupportGroupUser, roleOptions} from './constants'

const SupportGroupUserEditorModal = ({
  item,
  role,
  supportGroupID,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {
  item: AdminSupportGroupUser | null
  role?: SupportGroupRole
  supportGroupID: number
}) => {
  const toast = useToast()
  const {input, handleCustomInputChange} = useEditorModalState<AdminSupportGroupUser>({
    item,
    emptyInput: emptySupportGroupUser,
    open,
  })

  React.useEffect(() => {
    handleCustomInputChange({role})
  }, [open, role]) // eslint-disable-line

  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      try {
        const {data, error} = await supabase.rpc('upsert_support_group_user', {
          support_group_id: supportGroupID,
          user_id: input.user,
          role: input.role,
        })
        if (error) throw error
        const res = data as any as SupabaseRPCResult
        if ('error' in res) throw new Error(res.error)

        onComplete && onComplete()
        onClose && onClose()
        toast({
          isClosable: true,
          status: 'success',
          title: `${input.user ? 'Zaktualizowano' : 'Dodano'} członka grupy`,
        })
      } catch (e) {
        console.log((e as Error).message)
        toast({
          isClosable: true,
          status: 'error',
          title: `Nie udało się ${input.user ? 'zaktualizować' : 'dodać'} uczestnika`,
        })
      }
    }, [supportGroupID, input, toast, onComplete, onClose])
  )

  const handleRoleChange = React.useCallback(
    (v: SingleValue<RoleOption>) => handleCustomInputChange({role: v?.value}),
    [handleCustomInputChange]
  )
  const handleUserChange = React.useCallback(
    (v: SingleValue<SelectOption>) => handleCustomInputChange({user: v?.value, email: v?.label}),
    [handleCustomInputChange]
  )

  const asyncSelectLoadUsers = React.useCallback(async (searchValue) => {
    try {
      const query = supabase.from('users').select('id, email')
      if (searchValue) {
        query.like('email', `%${searchValue}%`)
      }
      const {data, error} = await query
      if (error) throw error

      return data?.map((u) => ({label: u.email, value: u.id})) ?? []
    } catch (e) {
      console.error(e)
    }
  }, [])
  const selectedUser = React.useMemo(
    () => ({value: input.user, label: input.email}),
    [input.user, input.email]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {item?.user ? 'Edytuj członka grupy wsparcia' : 'Dodaj członka do grupy wsparcia'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={2}>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading}>
              <FormLabel>Użytkownik</FormLabel>
              <AsyncSelect
                isDisabled={loading}
                value={selectedUser}
                defaultOptions={true}
                loadOptions={asyncSelectLoadUsers}
                onChange={handleUserChange}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Rola</FormLabel>
              <Select
                value={roleOptions.find((o) => o.value === input.role)}
                options={roleOptions}
                onChange={handleRoleChange}
                isSearchable={false}
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleSubmit}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SupportGroupUserEditorModal
