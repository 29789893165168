import React from 'react'

import {
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {MdClose} from 'react-icons/md'
import {Link as RouterLink, generatePath, useHistory, useLocation, useParams} from 'react-router-dom'

import {parseSupportGroupJoined} from '@/api/models'
import ShowcaseDescription from '@/common/components/product-showcase/description'
import ShowcaseDetailsBanner, {DetailItemProps} from '@/common/components/product-showcase/details-banner'
import ScreenWideBar from '@/common/components/screen-wide-bar'
import {CalendarIcon, ClockIcon, ListIcon, UserIcon, UsersIcon} from '@/common/icons'
import LoadingView from '@/common/loading-view'
import NotFoundView from '@/common/not-found-view'
import StarRating from '@/common/reviews/star-rating'
import useMetaTags from '@/common/use-meta-tags'
import useRunOnReconnect from '@/common/use-run-on-reconnect'
import {
  PSYCHOTHERAPISTS,
  PSYCHOTHERAPY_THERAPIST,
  SUPPORT_GROUPS,
  SUPPORT_GROUPS_LIST,
  SUPPORT_GROUP_SHOWCASE,
} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatInterval} from '@/utils/duration'
import {formatDate, formatWeeklyDuration} from '@/utils/string'

import {
  fetchSupportGroup,
  initSupportGroupOrder,
  resetSupportGroupsState,
  selectSupportGroup,
  selectSupportGroupLoading,
} from '../state'
import {SupportGroupOrderModalButton} from './order-button'
import OtherSupportGroups from './other-support-groups'
import SupportGroupReviews from './reviews'

type Params = {id: string; slug?: string}

const SupportGroupShowcase = () => {
  const {id, slug} = useParams<Params>()
  const {replace} = useHistory()
  const {search} = useLocation()

  const dispatch = useAppDispatch()
  const loading = useAppSelector(selectSupportGroupLoading)
  const groupRaw = useAppSelector(selectSupportGroup)
  const group = React.useMemo(() => groupRaw && parseSupportGroupJoined(groupRaw), [groupRaw])

  useMetaTags({
    title: group?.name,
    description: group?.meta_description,
    'og:image': group?.thumbnail,
  })

  const fetch = React.useCallback(() => {
    const searchParams = new URLSearchParams(search)
    const order = searchParams.get('order')
    if (order === 'true') {
      dispatch(
        initSupportGroupOrder({
          groupID: +id,
          fetch: true,
        })
      )
      return
    }
    replace('?') // clear search params

    dispatch(fetchSupportGroup(+id))
  }, [id, search, replace, dispatch])

  React.useEffect(() => {
    fetch()

    return () => {
      dispatch(resetSupportGroupsState())
    }
  }, [fetch]) // eslint-disable-line

  useRunOnReconnect(fetch)

  React.useEffect(() => {
    if (group?.slug && group.slug !== slug) {
      replace(generatePath(SUPPORT_GROUP_SHOWCASE, {id, slug: group.slug}) + search)
    }
  }, [group, slug]) // eslint-disable-line

  React.useEffect(() => {
    if (!group) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `support_group:${group.id}`,
              item_name: `Grupa wsparcia - ${group.name}`,
              value: (group.price || 0) / 100,
            },
          ],
          value: (group.price || 0) / 100,
        },
        event: 'view_item',
      },
    })
  }, [group])

  const detailsItems: DetailItemProps[] = React.useMemo(
    () =>
      !group
        ? []
        : [
            ...(group.first_meeting_duration
              ? [
                  {
                    icon: CalendarIcon,
                    title: 'Termin spotkań',
                    children: (
                      <Stack spacing={0}>
                        <Text>{formatWeeklyDuration(group.first_meeting_duration)}</Text>
                        <Text>Początek: {formatDate(group.first_meeting_duration.from)}</Text>
                      </Stack>
                    ),
                  },
                  {
                    icon: ClockIcon,
                    title: 'Czas trwania',
                    children: (
                      <Text>
                        {formatInterval(group.first_meeting_duration.from, group.first_meeting_duration.to)}
                      </Text>
                    ),
                  },
                ]
              : []),
            {
              icon: UsersIcon,
              title: 'Ilość uczestników',
              children: (
                <Text>
                  {group.min_members} - {group.max_members}
                </Text>
              ),
            },
            {
              icon: ListIcon,
              title: 'Liczba spotkań',
              children: <Text>{group.meetings_left}</Text>,
            },
            {
              icon: UserIcon,
              title: 'Opiekunowie szkolenia',
              children: (
                <Stack>
                  {group.hosts.map((h, i) => (
                    <Button
                      key={i}
                      variant="brandLink"
                      as={RouterLink}
                      to={
                        h.therapist_id
                          ? generatePath(PSYCHOTHERAPY_THERAPIST, {
                              id: h.therapist_id,
                              slug: h.slug,
                            })
                          : PSYCHOTHERAPISTS
                      }
                    >
                      {h.full_name}
                    </Button>
                  ))}
                </Stack>
              ),
            },
          ],

    [group]
  )

  if (loading) return <LoadingView />

  if (!group?.id) return <NotFoundView />

  return (
    <Container as={Stack} maxW="container.xl" my={[8, null, 16]} px={[8, null, 4]} spacing={[16, null, 24]}>
      <Stack spacing={[8, null, 16]}>
        <Breadcrumb fontWeight={300}>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={SUPPORT_GROUPS}>
              Grupy Wsparcia
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={SUPPORT_GROUPS_LIST}>
              Lista grup wsparcia
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
            <BreadcrumbLink as={RouterLink} to={generatePath(SUPPORT_GROUP_SHOWCASE, {id, slug})}>
              {group.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={[12, null, 5]} px={[4, null, 0]}>
            <Box roundedTopRight="60px" roundedBottomLeft="60px" overflow="hidden">
              <AspectRatio ratio={1}>
                {group.thumbnail ? (
                  <Image src={group.thumbnail} objectFit="cover" />
                ) : (
                  <Flex objectFit="cover" bg="gray.600" alignItems="center" justifyContent="center">
                    <MdClose size={64} />
                  </Flex>
                )}
              </AspectRatio>
            </Box>
          </GridItem>
          <GridItem
            as={Stack}
            justifyContent="space-between"
            spacing={4}
            colStart={[0, null, 7]}
            colSpan={[12, null, 6]}
            py={[8, null, 4]}
          >
            <ScreenWideBar py={[8, null, 12]} bg="brand.yellow.300">
              <Heading size="lg">{group.name}</Heading>
            </ScreenWideBar>
            <Stack
              mb={4}
              spacing={4}
              direction={['column', 'row', 'column', 'row']}
              justify="space-between"
              align={['flex-start', 'center', 'flex-start', 'center']}
            >
              {!!group.rating && (
                <HStack>
                  <Text>ocena: {group.rating.toFixed(2)}</Text>
                  <StarRating value={group.rating} />
                </HStack>
              )}
              <HStack spacing={1} fontSize="xl">
                <Text>Cena:</Text>
                {group.price ? <Text>{`${group.price / 100} zł`}</Text> : <Text>Za darmo</Text>}
              </HStack>
              <SupportGroupOrderModalButton group={group} />
            </Stack>
            <Spacer />
            <ShowcaseDescription
              id="support-group-showcase"
              shortDescription={group.short_description}
              fullDescription={group.full_description}
            />
          </GridItem>
        </Grid>
      </Stack>

      <ShowcaseDetailsBanner items={detailsItems} bg="brand.yellow.300" />

      <ScreenWideBar py={[8, null, 12]} bg="brand.green.800">
        <Stack
          w="100%"
          direction={['column', null, 'row']}
          spacing={[4, null, 16]}
          align="center"
          justify="center"
        >
          <Text color="white" fontSize="2xl" textAlign={['center', null, 'left']}>
            {group.has_access ? 'Już masz dostęp do tej grupy!' : 'Jesteś zainteresowany tą grupą wsparcia?'}
          </Text>
          <SupportGroupOrderModalButton group={group} height={58} width={80} />
        </Stack>
      </ScreenWideBar>

      <SupportGroupReviews id={group.id} />

      <OtherSupportGroups currentID={group.id} />
    </Container>
  )
}

export default SupportGroupShowcase
