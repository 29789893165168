import * as React from 'react'

import {ArrowRightIcon} from '@chakra-ui/icons'
import {FaTag, FaUsers, FaDollarSign} from 'react-icons/fa'

import {ADMIN_ORDER_ITEMS} from '@/admin/router'
import {AdminCourse} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'

import {generateFilterURL} from '../utils'

export const openEditorAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <ArrowRightIcon />,
  id: 'editor',
  onClick,
  tooltip: 'Otwórz edytor',
})

export const categoriesAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <FaTag size="1rem" />,
  id: 'categories',
  onClick,
  tooltip: 'Edytuj kategorie',
})

export const accessAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <FaUsers size="1rem" />,
  id: 'access',
  onClick,
  tooltip: 'Zarządzanie dostępem',
})

export const showSellsAction: DataTableAction<AdminCourse> = {
  icon: <FaDollarSign size="1rem" />,
  id: 'showSells',
  to: (item: AdminCourse) =>
    generateFilterURL(ADMIN_ORDER_ITEMS, {
      combinator: 'and',
      rules: [
        {field: 'product_id', operator: 'eq', value: item.id},
        {field: 'reference_type', operator: 'eq', value: 'course'},
      ],
    }),
  tooltip: 'Zobacz sprzedaże',
}
