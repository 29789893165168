import * as React from 'react'

import {Box, Container, Flex, SkeletonText, Stack, Text} from '@chakra-ui/react'
import {CarouselProvider, Dot, Slide, Slider} from 'pure-react-carousel'
import {ImQuotesLeft} from 'react-icons/im'
import {Link, generatePath} from 'react-router-dom'

import {FeaturedReview} from '@/api/models'
import {COURSE_SHOWCASE, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'

import ScreenWideBar from '../components/screen-wide-bar'
import './index.css'

type Props = {
  title?: React.ReactNode
  reviews: FeaturedReview[]
  loading: boolean
  px?: number
}

const Reviews = ({reviews, loading, title, px}: Props) => {
  return (
    <ScreenWideBar bg="brand.yellow.300" mt={14} direction={['column', null, 'row']}>
      <Container maxW="container.xl" zIndex={1} px={px ?? 0} pt={4}>
        <Stack direction={['column', null, 'row']} spacing={6} align="flex-start">
          <Text
            as="h2"
            fontSize={['2xl', null, '3xl']}
            fontWeight={[700, null, 500]}
            flex={0}
            flexGrow={0}
            flexShrink={0}
            mr={[0, null, 16]}
            px={[4, null, 0]}
          >
            {title || 'Opinie'}
          </Text>
          {reviews.map((review, key) => (
            <Box key={key} display={['none', null, 'block']} flex={1} transform="translateY(-56px)">
              <Review key={key} review={review} loading={loading} />
            </Box>
          ))}
        </Stack>
        <Box display={['block', null, 'none']}>
          <CarouselProvider
            naturalSlideWidth={500}
            naturalSlideHeight={300}
            totalSlides={reviews.length}
            infinite={true}
            className="home-reviews-carousel"
            isIntrinsicHeight={true}
          >
            <Slider>
              {reviews.map((review, i) => (
                <Slide index={i} key={i}>
                  <Box px={4}>
                    <Review review={review} loading={loading} />
                  </Box>
                </Slide>
              ))}
            </Slider>
            <Flex align="center" justify="center" mt={8}>
              <Stack direction="row" spacing={6}>
                {reviews.map((_, i) => (
                  <Dot key={i} slide={i}>
                    <Box borderRadius="full" width={['14px', null, '20px']} height={['14px', null, '20px']} />
                  </Dot>
                ))}
              </Stack>
            </Flex>
          </CarouselProvider>
        </Box>
      </Container>
    </ScreenWideBar>
  )
}

const Review = ({review, loading}: {review: FeaturedReview; loading: boolean}) => {
  return (
    <Box position="relative" pt={14} pl={8}>
      <Box
        position="absolute"
        bg="brand.yellow.500"
        rounded="full"
        width="130px"
        height="130px"
        zIndex={0}
        mt={-14}
        ml={-8}
        color="brand.green.600"
      >
        <ImQuotesLeft fontSize={50} />
      </Box>
      <Flex direction="column" zIndex={1} position="relative">
        {review.reference_id ? (
          <Box
            mb={6}
            fontWeight={300}
            as={Link}
            to={
              review.reference_type === 'course'
                ? generatePath(COURSE_SHOWCASE, {id: review.reference_id, slug: review.reference_slug})
                : generatePath(PSYCHOTHERAPY_THERAPIST, {
                    id: review.reference_id,
                    slug: review.reference_slug,
                  })
            }
            _hover={{textDecoration: 'underline'}}
          >
            <SkeletonText noOfLines={4} spacing="4" isLoaded={!loading}>
              {review.content}
            </SkeletonText>
          </Box>
        ) : (
          <Box mb={6} fontWeight={300}>
            <SkeletonText noOfLines={4} spacing="4" isLoaded={!loading}>
              {review.content}
            </SkeletonText>
          </Box>
        )}
        <Flex direction="row" alignItems="center" justifyContent="flex-end">
          ~{' '}
          <SkeletonText ml="1" noOfLines={1} isLoaded={!loading}>
            {review.author}
            {!!review.rating && ` (${review.rating}/5)`}
          </SkeletonText>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Reviews
