import * as React from 'react'

import {Button, Image, Stack, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {UserOrderItem} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import useFetchSingle from '@/common/use-fetch-single'
import {startCourseMeetingOrder} from '@/psychotherapy/therapists/state'
import {HOME} from '@/router/paths'
import {useAppDispatch} from '@/store'
import OpenCourseButton from '@/user/courses/open-course-button'

import Pending from '../pending'
import picture from './picture.svg'

type CourseOrderComplete = {
  id: number
  name: string
  coach: number | null
  meetings_left: number
  nextlvl_url: string | null
}

const CourseOrderFulfilled = ({item}: {item: UserOrderItem}) => {
  const dispatch = useAppDispatch()

  const {data, loading} = useFetchSingle<CourseOrderComplete>(
    React.useMemo(
      () => ({
        table: 'course_order_complete',
        fields: '*',
        match: {id: item.id},
      }),
      [item]
    )
  )

  const handlePlanMeeting = React.useCallback(() => {
    data?.id && dispatch(startCourseMeetingOrder(data.id))
  }, [data, dispatch])

  return !data || loading ? (
    <Pending />
  ) : (
    <Stack align="center" justify="center" spacing={12}>
      <Image src={picture} />
      <Stack
        alignItems="center"
        justifyContent="center"
        spacing={0}
        color="brand.gray.600"
        fontSize={['20px', null, '28px']}
        lineHeight="30px"
        fontWeight={700}
        textAlign="center"
      >
        <Text>Dziękujemy za dokonanie płatności</Text>
        <Text>Szkolenie zostało wykupione</Text>
      </Stack>
      <Stack direction={['column', null, null, 'row']} spacing={8} align="center" justify="center">
        <ArrowButton
          as={Link}
          to={HOME}
          bg="brand.yellow.600"
          hoverBg="brand.yellow.700"
          color="white"
          height={40}
          fontSize="lg"
          fontWeight={500}
          flex={1}
          flipped={true}
        >
          Powrót na stronę główną
        </ArrowButton>
        {!!data?.meetings_left && !!data?.coach && (
          <Button
            onClick={handlePlanMeeting}
            bg="brand.green.600"
            _hover={{
              bg: 'brand.green.700',
            }}
            color="white"
            flex={1}
            minH="40px"
            fontSize="lg"
            fontWeight={500}
            px={8}
          >
            Umów się na spotkanie z trenerem
          </Button>
        )}
        <OpenCourseButton id={data.id} nextlvlURL={data.nextlvl_url} />
      </Stack>
    </Stack>
  )
}

export default CourseOrderFulfilled
