import * as React from 'react'

import {Icon, IconButton, Link, Tooltip, useToast} from '@chakra-ui/react'
import {GrDocumentCsv as CSVIcon} from 'react-icons/gr'

import {supabase} from '@/api'
import useLoadingState from '@/common/use-loading-state'

import {Column} from '../types'
import {TableState} from '../use-table-state/types'
import {exportCSV} from './utils'

type Props<T extends {}> = {
  table: string
  tableState: TableState
  selectedColumns: string[]
  columnByID: Record<string, Column<T>>
}

const ExportToCSVButton = <T extends {}>({table, tableState, selectedColumns, columnByID}: Props<T>) => {
  const toast = useToast()

  const linkRef = React.useRef<HTMLAnchorElement>(null)

  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      if (!linkRef.current) return

      const {descending, sortBy, filter} = tableState
      try {
        let query: any = supabase.from(table).select(selectedColumns.join(', '))

        if (sortBy.column) {
          query = query.order(sortBy.column as any, {ascending: !descending})
        }
        if (filter) {
          query = filter(query)
        }

        const {data, error} = await query
        if (error) throw error
        if (!data) throw new Error('No data')

        exportCSV<T>(table, data, columnByID)

        toast({
          title: 'Pobrano stan tabeli',
          status: 'success',
          isClosable: true,
        })
      } catch (e) {
        console.error('Failed to generate CSV', e)
        toast({
          title: 'Nie udało się wygenerować pliku CSV',
          status: 'error',
          isClosable: true,
        })
      }
    }, [table, tableState, selectedColumns, columnByID, toast])
  )

  return (
    <>
      <Tooltip label="Eksportuj do pliku CSV">
        <IconButton
          ml={2}
          aria-label="columns"
          variant="outline"
          onClick={handleSubmit}
          icon={<Icon as={CSVIcon} h="16" />}
          isLoading={loading}
        />
      </Tooltip>
      <Link display="none" ref={linkRef} target="_blank" />
    </>
  )
}

export default ExportToCSVButton
