import React from 'react'

import {AdminCourseReview} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useReviewModal from '@/common/reviews/review-modal/use-review-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, filterFields} from './constants'

const CourseReviews = () => {
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminCourseReview>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminCourseReview,
    pageNumber: tableState.page,
    table: 'admin_course_reviews',
  })

  const {
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
    value: deleteValue,
  } = useValueDisclosure<AdminCourseReview>()

  const {startReview} = useReviewModal({onComplete: fetch})
  const handleEdit = React.useCallback(
    (review: AdminCourseReview) =>
      startReview({admin: true, open: true, type: 'course', itemID: review.course, review}),
    [startReview]
  )

  const actions = React.useMemo(
    () => [deleteAction(onDeleteOpen), editAction(handleEdit)],
    [onDeleteOpen, handleEdit]
  )

  return (
    <>
      <DataTable<AdminCourseReview>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
        filterFields={filterFields}
      />
      <DeleteResourceModal
        table="course_reviews"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default CourseReviews
