import * as React from 'react'

import {useAppDispatch, useAppSelector} from '@/store'

import {ReviewsState, selectReviewSubmitted, setReviewState, setReviewSubmitted} from '../state'

type Props = {
  onComplete?: () => void
}

// this hook exists, because redux can't store a callback function (or at least shouldn't)
const useReviewModal = ({onComplete}: Props) => {
  const dispatch = useAppDispatch()
  const submitted = useAppSelector(selectReviewSubmitted)

  // used to track if the review was initiated by this instance of the hook
  const [reviewing, setReviewing] = React.useState(false)

  // execute callback after successfully submitting a review
  React.useEffect(() => {
    if (!submitted || !reviewing) return

    dispatch(setReviewSubmitted(false))
    setReviewing(false)
    onComplete && onComplete()
  }, [dispatch, submitted]) // eslint-disable-line

  const startReview = React.useCallback(
    (state: Partial<ReviewsState>) => {
      setReviewing(true)
      dispatch(setReviewState(state))
    },
    [dispatch]
  )

  return {startReview}
}

export default useReviewModal
