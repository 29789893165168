import * as React from 'react'

import {CloseIcon} from '@chakra-ui/icons'
import {Avatar, Link} from '@chakra-ui/react'

import {AdminCourse, Course} from '@/api/models'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const COURSE_COVERED_TOPICS_COUNT = 4
export const COURSE_BENEFITS_COUNT = 4

export const emptyCourse: Course = {
  name: '',
  created_at: new Date(),
  updated_at: new Date(),
  release_at: null,
  is_new: false,
  published: false,
  logo: null,
  base_price: null,
  meetings_count: null,
  price_with_meetings: null,
  order: null,
  rating: null,
  nextlvl_id: null,
  nextlvl_url: null,
  booklet_path: null,
  meta_description: null,
  promotional_video_url: null,
  sections_number_override: null,
  full_description: initialEditorValue,
  short_description: initialEditorValue,
  benefits: Array(COURSE_BENEFITS_COUNT).fill(''),
  covered_topics: Array(COURSE_COVERED_TOPICS_COUNT).fill(initialEditorValue),
}

export const emptyAdminCourse: AdminCourse = {
  ...emptyCourse,
  categories: [],
  signed_booklet_url: null,
}

export const defaultSelectedColumns = [
  'name',
  'categories',
  'base_price',
  'price_with_meetings',
  'order',
  'published',
]

export const columns: Column<AdminCourse>[] = [
  {
    Header: 'Logo',
    id: 'signed_logo',
    renderer: ({signed_logo}) =>
      signed_logo ? <Avatar src={signed_logo} /> : <Avatar bg="lightgray" icon={<CloseIcon />} />,
    sortable: false,
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Numer porządkowy',
    id: 'order',
  },
  {
    Header: 'Cena podstawowa (PLN)',
    id: 'base_price',
    renderAs: 'monetary',
  },
  {
    Header: 'Cena ze spotkaniami (PLN)',
    id: 'price_with_meetings',
    renderAs: 'monetary',
  },
  {
    Header: 'Spotkania w pakiecie',
    id: 'meetings_count',
  },
  {
    Header: 'Kategorie',
    id: 'categories',
    renderAs: 'tags',
  },
  {
    Header: 'Opublikowany?',
    id: 'published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Nowość?',
    id: 'is_new',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Opis meta',
    id: 'meta_description',
    renderAs: 'longString',
  },
  {
    Header: 'Film promocyjny',
    id: 'promotional_video_url',
    renderer: ({promotional_video_url}) =>
      promotional_video_url ? (
        <Link href={promotional_video_url} target="_blank">
          Zobacz
        </Link>
      ) : (
        '-'
      ),
  },
  {
    Header: 'Nadpisanie liczby sekcji',
    id: 'sections_number_override',
  },
  {
    Header: 'NextLvl ID',
    id: 'nextlvl_id',
  },
  {
    Header: 'Utworzony',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Dostępny od',
    id: 'release_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Kategorie',
    name: 'categories',
    variant: 'list',
  },
  {
    label: 'Numer porządkowy',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'Cena podstawowa',
    name: 'base_price',
    variant: 'monetary',
  },
  {
    label: 'Cena ze spotkaniami',
    name: 'price_with_meetings',
    variant: 'monetary',
  },
  {
    label: 'Spotkania w pakiecie',
    name: 'meetings_count',
    variant: 'number',
  },
  {
    label: 'Opublikowany?',
    name: 'published',
    variant: 'boolean',
  },
  {
    label: 'Nowość?',
    name: 'is_new',
    variant: 'boolean',
  },
  {
    label: 'Nadpisanie liczby sekcji',
    name: 'sections_number_override',
    variant: 'number',
  },
  {
    label: 'URL filmu promocyjnego',
    name: 'promotional_video_url',
    variant: 'text',
  },
  {
    label: 'Utworzony',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
  {
    label: 'Dostępny od',
    name: 'release_at',
    variant: 'datetime',
  },
]
