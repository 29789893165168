import * as React from 'react'

import {
  Box,
  Button,
  Flex,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  usePrevious,
} from '@chakra-ui/react'
import {addDays, endOfWeek, format, isAfter, isEqual, startOfWeek, subDays} from 'date-fns'
import {pl} from 'date-fns/locale'
import {FaLock} from 'react-icons/fa'

import {PGTimeRangeSlotJoined} from '@/api/models'
import {ArrowIconButton, ChevronIcon} from '@/common/arrow-button'

import {useTherapistTimeSlots} from './hooks'

const headerProps = {
  bg: 'brand.green.600',
  border: '2px solid white',
  color: 'white',
  fontSize: 'sm',
  fontWeight: 500,
  py: 6,
  textAlign: 'center',
  textTransform: 'none',
}

const cellProps = {
  bg: 'brand.green.100',
  borderLeft: '2px solid white',
  borderRight: '2px solid white',
  color: 'brand.green.800',
  textAlign: 'center',
}

const activeCellProps = {
  bg: 'brand.green.600',
  color: 'white',
}

export type Props = {
  therapist: number
  service: string
  onClick?: (date: Date) => void
  selectedDate?: Date
  expandable?: boolean
}

const dateAsNumber = (input: Date) => input.getMonth() * 100 + input.getDay()

const minimumTimeSlotSelectorCount = 6

const TimeSlotSelector = ({therapist, service, onClick, selectedDate, expandable}: Props) => {
  const today = React.useMemo(() => new Date(), [])

  const daysToShow = useBreakpointValue({
    base: 2,
    md: 7,
    sm: 3,
  })
  const [startOfRange, setStartOfRange] = React.useState<Date | undefined>()
  const previousDaysToShow = usePrevious(daysToShow)
  React.useEffect(() => {
    if (!daysToShow) {
      return
    }

    if (!previousDaysToShow || previousDaysToShow !== daysToShow) {
      // initialize
      if (daysToShow === 7) {
        // default to start of the week
        setStartOfRange(startOfWeek(selectedDate ?? new Date(), {weekStartsOn: 1}))
      } else if (selectedDate) {
        setStartOfRange(selectedDate)
      } else {
        setStartOfRange(new Date())
      }
    }
  }, [previousDaysToShow, daysToShow]) // eslint-disable-line
  const endOfWeekDate = React.useMemo(
    () => (startOfRange ? addDays(startOfRange, (daysToShow ?? 1) - 1) : undefined),
    [startOfRange, daysToShow]
  )
  const daysInWeek = React.useMemo(
    () =>
      startOfRange
        ? Array(daysToShow)
            .fill(1)
            .map((_, i) => addDays(startOfRange, i))
        : undefined,
    [startOfRange, daysToShow]
  )

  const previousWeek = React.useMemo(
    () => (startOfRange ? subDays(startOfRange, daysToShow ?? 1) : undefined),
    [startOfRange, daysToShow]
  )
  const lastDayOfPreviousWeek = React.useMemo(
    () => (previousWeek ? endOfWeek(previousWeek, {weekStartsOn: 1}) : undefined),
    [previousWeek]
  )
  const handlePreviousWeek = React.useCallback(
    () => setStartOfRange(previousWeek),
    [previousWeek, setStartOfRange]
  )
  const canGoPrevious = React.useMemo(
    () => (lastDayOfPreviousWeek ? isAfter(lastDayOfPreviousWeek, today) : undefined),
    [lastDayOfPreviousWeek, today]
  )

  const nextWeek = React.useMemo(
    () => (startOfRange ? addDays(startOfRange, daysToShow ?? 1) : undefined),
    [startOfRange, daysToShow]
  )
  const handleNextWeek = React.useCallback(() => setStartOfRange(nextWeek), [nextWeek, setStartOfRange])

  const {data, fetch, loading} = useTherapistTimeSlots(
    therapist,
    service,
    startOfRange ?? today,
    endOfWeekDate ?? today,
    startOfRange === undefined
  )
  const groupedSlots = React.useMemo(() => {
    const result: Record<number, PGTimeRangeSlotJoined[]> = {}
    for (const slot of data) {
      const key = slot.from.getMonth() * 100 + slot.from.getDay()
      result[key] = [...(result[key] || []), slot]
    }
    for (const k of Object.keys(result)) {
      result[k as any] = result[k as any].sort((a, b) => (a.from as any) - (b.from as any))
    }
    return result
  }, [data])
  const maxSlotCount = React.useMemo(() => {
    let maxCount = minimumTimeSlotSelectorCount // minimum count
    for (const v of Object.values(groupedSlots)) {
      if (v.length > maxCount) {
        maxCount = v.length
      }
    }
    return maxCount
  }, [groupedSlots])
  const canBeExpanded = React.useMemo(() => {
    return expandable && maxSlotCount > minimumTimeSlotSelectorCount
  }, [expandable, maxSlotCount])
  const [_expanded, setExpanded] = React.useState(false)
  const toggleExpanded = React.useCallback(() => setExpanded(!_expanded), [_expanded, setExpanded])
  const expanded = React.useMemo(() => (canBeExpanded ? _expanded : true), [canBeExpanded, _expanded])
  const maxSlotLength = React.useMemo(() => {
    return expanded
      ? maxSlotCount < minimumTimeSlotSelectorCount
        ? minimumTimeSlotSelectorCount
        : maxSlotCount
      : minimumTimeSlotSelectorCount
  }, [expanded, maxSlotCount])

  return (
    <Stack
      direction="column"
      spacing={8}
      width="100%"
      opacity={loading ? 0.6 : 1}
      pointerEvents={loading ? 'none' : undefined}
    >
      <Stack direction="row" spacing={[4, null, 16]} alignItems="center">
        <ArrowIconButton
          aria-label="Poprzedni tydzień"
          width={['40px', null, '64px']}
          color="brand.yellow.600"
          _hover={{color: 'brand.yellow.700'}}
          style={{transform: 'rotate(180deg)'}}
          onClick={handlePreviousWeek}
          isDisabled={!canGoPrevious}
        />
        <Text
          color="brand.green.800"
          fontSize="md"
          fontWeight={500}
          flex={1}
          textAlign={['center', null, 'left']}
        >
          {startOfRange && endOfWeekDate
            ? startOfRange.getMonth() === endOfWeekDate.getMonth()
              ? `${startOfRange.getDate()}-${format(endOfWeekDate, 'd LLLL y', {locale: pl})}`
              : `${format(startOfRange, 'd LLLL', {locale: pl})} - ${format(endOfWeekDate, 'd LLLL y', {
                  locale: pl,
                })}`
            : '...'}
        </Text>
        <ArrowIconButton
          aria-label="Następny tydzień"
          width={['40px', null, '64px']}
          color="brand.yellow.600"
          _hover={{color: 'brand.yellow.700'}}
          onClick={handleNextWeek}
        />
      </Stack>
      <Stack spacing={4}>
        <Table width="100%" overflow="hidden" borderRadius="0 0 50px 0">
          <Thead>
            <Tr>
              {daysInWeek?.map((day) => (
                <Th {...(headerProps as any)} width={`${100 / (daysToShow ?? 7)}%`} key={day}>
                  <Text as="span" fontWeight={700}>
                    {day.getDate()}
                  </Text>{' '}
                  {format(day, 'cccccc', {locale: pl})}
                  {today.getDate() === day.getDate() &&
                    today.getMonth() === day.getMonth() &&
                    today.getFullYear() === day.getFullYear() &&
                    ' (dziś)'}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array(maxSlotLength)
              .fill(1)
              .map((_, i) => (
                <Tr key={i}>
                  {daysInWeek?.map((day, j) => {
                    const daySlots = groupedSlots[dateAsNumber(day)]
                    const slot = daySlots ? daySlots[i] : undefined
                    const isSelected = selectedDate && slot && isEqual(slot.from, selectedDate)
                    return (
                      <Td
                        key={j}
                        {...(cellProps as any)}
                        fontWeight={slot ? 700 : 300}
                        textDecoration={slot && slot.is_occupied ? 'strike-through' : undefined}
                        {...((isSelected ? activeCellProps : {}) as any)}
                        transitionDuration="var(--chakra-transition-duration-normal)"
                        transitionProperty="var(--chakra-transition-property-common)"
                      >
                        {slot ? (
                          <Tooltip isDisabled={!slot.is_locked} label="Zarezerwowane">
                            <Box>
                              <Button
                                variant="brandLink"
                                fontWeight={slot ? 700 : 300}
                                color={isSelected ? 'white' : 'brand.green.800'}
                                onClick={() => onClick && onClick(slot.from)}
                                isDisabled={slot.is_occupied || slot.is_locked}
                                textDecoration={slot.is_occupied ? 'line-through' : undefined}
                                _hover={
                                  slot.is_occupied
                                    ? {
                                        textDecoration: 'line-through',
                                      }
                                    : undefined
                                }
                                rightIcon={slot.is_locked ? <FaLock width={16} /> : undefined}
                              >
                                {format(slot.from, 'HH:mm')}
                              </Button>
                            </Box>
                          </Tooltip>
                        ) : (
                          '-'
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              ))}
          </Tbody>
        </Table>
        {canBeExpanded && !expanded && (
          <Flex alignItems="center" justifyContent="center">
            <Button
              rightIcon={<ChevronIcon boxSize={6} color="brand.yellow.600" />}
              variant="link"
              color="black"
              onClick={toggleExpanded}
            >
              Więcej godzin
            </Button>
          </Flex>
        )}
      </Stack>
    </Stack>
  )
}

export default TimeSlotSelector
