import * as React from 'react'

import {Heading, Stack, Text} from '@chakra-ui/react'
import {isWithinInterval} from 'date-fns'
import {generatePath, useHistory} from 'react-router-dom'

import {MyWebinar} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import useSupabaseQuery from '@/common/use-custom-query'
import {WEBINAR_VIEW} from '@/router/paths'
import {SpotlightItem} from '@/user/components/spotlight-item'
import {formatEventTime} from '@/utils/string'

const UpcomingWebinarItem = ({
  webinar,
  onReviewComplete,
}: {
  webinar: MyWebinar
  onReviewComplete: () => void
}) => {
  const {push} = useHistory()

  const handleClick = React.useCallback(() => {
    push(generatePath(WEBINAR_VIEW, {meeting: webinar.meeting}))
  }, [webinar, push])

  const canJoin = React.useMemo(
    () =>
      isWithinInterval(new Date(), {
        start: new Date(webinar.start_at).getTime() - 30 * 60 * 1000, // 30min
        end: new Date(webinar.end_at),
      }),
    [webinar]
  )

  return (
    <SpotlightItem
      image={webinar.thumbnail}
      title={webinar.name}
      button={
        <ArrowButton onClick={handleClick} isDisabled={!canJoin}>
          Dołącz do webinaru
        </ArrowButton>
      }
      reviewButton={
        <ReviewModalButton
          type="webinar"
          withText={true}
          itemID={webinar.meeting}
          reviewed={webinar.reviewed}
          onComplete={onReviewComplete}
        />
      }
    >
      <Stack direction={['column', null, 'row']} align="center" spacing={[0, null, 2]}>
        <Text fontWeight={700}>Webinar</Text>
        <Text>{formatEventTime(webinar.start_at)}</Text>
      </Stack>
    </SpotlightItem>
  )
}

const UpcomingWebinars = () => {
  const {data, fetch} = useSupabaseQuery<MyWebinar>(
    React.useMemo(
      () => ({
        table: 'my_webinars',
        fields: '*',
        order: 'start_at',
        errSnackbarTitle: 'Nie udało się pobrać informacji o nadchodzących webinarach',
        finalize: (b) => b.gte('end_at', new Date().toUTCString()),
      }),
      []
    )
  )

  return (
    <Stack spacing={12}>
      <Heading size="md">Nadchodzące webinary</Heading>
      {data?.length ? (
        <>
          {data?.map((w, i) => (
            <UpcomingWebinarItem key={i} webinar={w} onReviewComplete={fetch} />
          ))}
          <Text maxW={['unset', null, '60%']}>
            <strong>Uwaga!</strong> Dostęp do webinaru zostanie aktywowany 30 minut przed jego zaplanowanym
            rozpoczęciem
          </Text>
        </>
      ) : (
        <Text>Brak nadchodzących webinarów</Text>
      )}
    </Stack>
  )
}

export default UpcomingWebinars
