import * as React from 'react'

import SideMenu, {SideMenuItem} from '@/common/components/side-menu'
import {SUPPORT_GROUPS_FEATURE} from '@/constants'

import {THERAPIST_CALENDAR, THERAPIST_MEETINGS, THERAPIST_PROFILE, THERAPIST_SUPPORT_GROUPS} from './router'

const items: SideMenuItem[] = [
  {
    link: THERAPIST_PROFILE,
    label: 'Profil terapeuty',
  },
  {
    link: THERAPIST_MEETINGS,
    label: 'Spotkania',
  },
  ...(SUPPORT_GROUPS_FEATURE === 'true'
    ? [
        {
          label: 'Grupy wsparcia',
          link: THERAPIST_SUPPORT_GROUPS,
        },
      ]
    : []),
  {
    link: THERAPIST_CALENDAR,
    label: 'Synchronizcja kalendarza',
  },
]

export default () => <SideMenu items={items} />
