import * as React from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react'

import {TShirtSize} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import useSupabaseQuery from '@/common/use-custom-query'
import {selectOrderSubmitting, selectOrderTshirt, setOrderTshirt} from '@/courses/state'
import {useAppDispatch, useAppSelector} from '@/store'

import TShirtSizesTable from './sizes-table'
import tshirtImg from './tshirt.png'

type ModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TShirtModal = ({isOpen, onClose}: ModalProps) => {
  const dispatch = useAppDispatch()
  const tshirt = useAppSelector(selectOrderTshirt)
  const submitting = useAppSelector(selectOrderSubmitting)

  const buttonDisabled = React.useMemo(() => !tshirt || submitting, [tshirt, submitting])
  const {data: sizes, loading} = useSupabaseQuery<TShirtSize>({
    table: 'tshirt_sizes',
    fields: '*',
    order: 'order',
  })

  const handleChange = React.useCallback((v: string) => dispatch(setOrderTshirt(v)), [dispatch])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent px={[0, null, 8]} py={6}>
        <ModalHeader>Odbierz darmową koszulkę</ModalHeader>
        <ModalCloseButton
          top={[4, null, 8]}
          right={[4, null, 12]}
          boxSize={[4, null, 10]}
          fontSize={[12, null, '20px']}
          rounded="full"
        />
        <ModalBody my={8}>
          <Box display={['block', null, 'none']} mb={8}>
            <AspectRatio ratio={2.4} overflow="hidden" borderRadius="50px 0px">
              <Image src={tshirtImg} objectFit="cover" objectPosition="center" />
            </AspectRatio>
          </Box>
          <Grid
            templateColumns={['repeat(1, 1fr)', null, 'repeat(2, minmax(0, 1fr))']}
            columnGap={[0, null, 12]}
          >
            <GridItem>
              <Stack direction="column" spacing={8}>
                <Stack direction="row" spacing={20}>
                  <Text fontWeight={700}>Rodzaj</Text>
                  <Text>unisex</Text>
                </Stack>
                <Stack direction="column" spacing={4}>
                  <Text fontWeight={700}>Rozmiar</Text>
                  <Stack direction="row" spacing={4}>
                    {loading ? (
                      <Skeleton width="100%" h="30px" />
                    ) : (
                      sizes?.map((size) => (
                        <Button
                          key={size.id}
                          variant="brandLink"
                          color={size.id === tshirt ? 'brand.yellow.600' : 'black'}
                          _hover={
                            size.id === tshirt
                              ? {
                                  color: 'brand.yellow.700',
                                  textDecoration: 'underline',
                                }
                              : {
                                  textDecoration: 'underline',
                                }
                          }
                          disabled={!size.available}
                          onClick={() => handleChange(size.id)}
                        >
                          {size.id}
                        </Button>
                      ))
                    )}
                  </Stack>
                </Stack>
                <Stack direction="column" spacing={4}>
                  <Text fontWeight={700}>Tabela rozmiarów</Text>
                  <TShirtSizesTable width="100%" />
                </Stack>
              </Stack>
            </GridItem>
            <GridItem display={['none', null, 'block']}>
              <AspectRatio ratio={1} overflow="hidden" borderRadius="50px 0px">
                <Image src={tshirt} objectFit="cover" objectPosition="center" />
              </AspectRatio>
            </GridItem>
          </Grid>
        </ModalBody>
        <ModalFooter>
          <HStack justify="space-evenly" spacing={8}>
            <Button
              color="brand.yellow.600"
              _hover={{
                color: 'brand.yellow.700',
              }}
              variant="brandLink"
              onClick={onClose}
              isDisabled={submitting}
            >
              Anuluj
            </Button>
            <ArrowButton bg="brand.yellow.600" color="white" onClick={onClose} isDisabled={buttonDisabled}>
              Dodaj
            </ArrowButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TShirtModal
