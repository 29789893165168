import * as React from 'react'

import {
  Text,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Tooltip,
  useDisclosure,
  ModalHeader,
  StackDivider,
} from '@chakra-ui/react'

import StarRating from '@/common/reviews/star-rating'

import {MeetingReview} from '../types'

type ModalButtonProps = {
  avg: number
  reviews: MeetingReview[]
}

export const ReviewsModalButton = (props: ModalButtonProps) => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  return props.avg ? (
    <>
      <Tooltip label="Zobacz opinie">
        <Button variant="brandLink" onClick={onOpen}>
          {props.avg} / 5
        </Button>
      </Tooltip>
      <ReviewsModal {...props} open={isOpen} onClose={onClose} />
    </>
  ) : (
    <Text>Brak</Text>
  )
}

type ModalProps = ModalButtonProps & {open: boolean; onClose: () => void}

const ReviewsModal = ({avg, reviews, open, onClose}: ModalProps) => {
  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Opinie spotkania</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <Stack spacing={4} width="100%" divider={<StackDivider />}>
            {reviews.length > 1 && (
              <HStack>
                <Text>Średnia ocena:</Text>
                <Text>{avg} / 5</Text>
              </HStack>
            )}
            {reviews.map((r, i) => (
              <Stack key={i}>
                <HStack>
                  <Text fontWeight="bold">Użytkownik:</Text>
                  <Text>{r.user}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Ocena:</Text>
                  <StarRating value={r.rating} />
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Opublikowana:</Text>
                  <Text>{r.published ? 'Tak' : 'Nie'}</Text>
                </HStack>
                <HStack>
                  <Text fontWeight="bold">Komentarz:</Text>
                  <Text>{r.content}</Text>
                </HStack>
              </Stack>
            ))}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ReviewsModal
