import * as React from 'react'

import {
  Avatar,
  Box,
  Button,
  Center,
  HStack,
  Image,
  Spinner,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'

import {MyCourse, MyMeeting} from '@/api/models'
import {selectProfile} from '@/auth/state'
import defaultAvatar from '@/common/icons/default-avatar.svg'
import useSupabaseQuery from '@/common/use-custom-query'
import {useAppSelector} from '@/store'

import AvatarUploadModal from '../avatar-upload-modal'
import {UnfinishedCourse} from '../courses/unfinished'
import UpcomingMeeting from './upcoming-meeting'

const UserProfile = () => {
  const profile = useAppSelector(selectProfile)
  const {
    isOpen: isAvatarUploadOpen,
    onOpen: onAvatarUploadOpen,
    onClose: onAvatarUploadClose,
  } = useDisclosure()

  const {
    data: coursesData,
    loading: coursesLoading,
    fetch: fetchCourses,
  } = useSupabaseQuery<MyCourse>(
    React.useMemo(
      () => ({
        table: 'my_courses',
        fields: '*',
        order: 'progress',
        descending: true,
        errSnackbarTitle: 'Nie udało się pobrać trwających warsztatów',
        filter: (b) => b.lt('progress', 100),
      }),
      []
    )
  )

  const {data: meetingsData, loading: meetingsLoading} = useSupabaseQuery<MyMeeting>(
    React.useMemo(
      () => ({
        table: 'my_meetings',
        fields: '*',
        order: 'starts_at',
        match: {type: 'therapy'},
        errSnackbarTitle: 'Nie udało się pobrać informacji o spotkaniach',
        filter: (b) => b.gte('joinable_to', new Date().toUTCString()),
      }),
      []
    )
  )

  return (
    <Stack spacing={16} mb={4}>
      <Box bg="brand.green.600">
        <HStack py={8} px={[4, null, 8]} spacing={8}>
          <Avatar
            bg="brand.green.600"
            icon={<Image src={defaultAvatar} />}
            src={profile?.avatar || undefined}
            size="xl"
          />
          <Stack
            flex={1}
            direction={['column', null, 'row']}
            align={['flex-start', null, 'center']}
            justify="space-between"
          >
            <Stack color="white">
              <Text fontSize="3xl">{profile?.full_name_override ?? profile?.full_name}</Text>
              <Text fontSize="lg" fontWeight={300} pb={[2, null, 0]}>
                {profile?.email}
              </Text>
            </Stack>
            <Button onClick={onAvatarUploadOpen} size="sm" variant="link" color="white">
              Zmień zdjęcie profilowe
            </Button>
          </Stack>
        </HStack>
      </Box>
      <Stack px={8} spacing={12}>
        <Text fontSize="lg" fontWeight={500}>
          Trwające warsztaty
        </Text>
        {coursesLoading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : coursesData?.length ? (
          coursesData?.map((course) => (
            <UnfinishedCourse key={course.id} course={course} basic={true} fetch={fetchCourses} />
          ))
        ) : (
          <Center>
            <Text color="brand.gray.500" fontWeight={300} textAlign="center">
              Brak trwających warsztatów
            </Text>
          </Center>
        )}
      </Stack>
      <Stack direction="column" px={8} spacing={8}>
        <Text fontSize="lg" fontWeight={500}>
          Nadchodzące terapie
        </Text>
        {meetingsLoading ? (
          <Center>
            <Spinner size="xl" />
          </Center>
        ) : meetingsData?.length ? (
          meetingsData?.map((m, i) => <UpcomingMeeting key={i} meeting={m} />)
        ) : (
          <Center>
            <Text color="brand.gray.500" fontWeight={300} textAlign="center">
              Brak nadchodzących terapii
            </Text>
          </Center>
        )}
      </Stack>
      {profile && (
        <AvatarUploadModal user={profile} open={isAvatarUploadOpen} onClose={onAvatarUploadClose} />
      )}
    </Stack>
  )
}

export default UserProfile
