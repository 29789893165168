import * as React from 'react'

import {AdminTherapistReview} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useReviewModal from '@/common/reviews/review-modal/use-review-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, filterFields} from './constants'

const TherapistReviews = () => {
  const tableState = useTableState({sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminTherapistReview>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminTherapistReview,
    pageNumber: tableState.page,
    table: 'admin_therapist_reviews',
  })

  const {
    value: deleteValue,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useValueDisclosure<AdminTherapistReview>()

  const {startReview} = useReviewModal({onComplete: fetch})
  const handleEdit = React.useCallback(
    (review: AdminTherapistReview) =>
      startReview({admin: true, open: true, type: 'meeting', itemID: review.meeting, review}),
    [startReview]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(onDeleteOpen)],
    [handleEdit, onDeleteOpen]
  )

  return (
    <>
      <DataTable<AdminTherapistReview>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
        filterFields={filterFields}
      />
      <DeleteResourceModal
        table="therapist_reviews"
        id={deleteValue?.id ?? ''}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default TherapistReviews
