import * as React from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  HStack,
  useDisclosure,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import useLoadingState from '@/common/use-loading-state'

const ResetCalendar = ({onComplete}: {onComplete: () => void}) => {
  const cancelButtonRef = React.useRef<HTMLButtonElement>(null)
  const {isOpen, onOpen, onClose} = useDisclosure()

  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      const {error} = await supabase.rpc('reset_therapist_calendar')
      if (error) throw error
      onComplete()
      onClose()
    }, [onComplete, onClose]),
    {onErrorToast: 'Nie udało się zresetować kalendarza', onSuccessToast: 'Kalendarz został zresetowany'}
  )

  return (
    <>
      <Button size="sm" variant="ghost" colorScheme="red" onClick={onOpen}>
        Resetuj kalendarz
      </Button>

      <AlertDialog isOpen={isOpen} onClose={onClose} size="xl" leastDestructiveRef={cancelButtonRef}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Czy na pewno chcesz zresetować swój kalendarz?</AlertDialogHeader>
          <AlertDialogBody>
            Twoje aktualnie wolne terminy zostaną usunięte, nie wpłynie to jednak na już zaplanowane
            spotkania. Pamiętaj, że zmiany są nieodwracalne.
          </AlertDialogBody>
          <AlertDialogFooter>
            <HStack justifyContent="space-evenly">
              <Button colorScheme="red" isLoading={loading} onClick={handleSubmit}>
                Resetuj
              </Button>
              <Button ref={cancelButtonRef} variant="ghost" onClick={onClose} isDisabled={loading}>
                Anuluj
              </Button>
            </HStack>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  )
}

export default ResetCalendar
