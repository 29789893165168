import * as React from 'react'

const useValueDisclosure = <T extends any>(initState?: T) => {
  const [value, setValue] = React.useState<T | null>(initState ?? null)
  const onClose = React.useCallback(() => setValue(null), [setValue])
  const onOpen = React.useCallback((value: T) => setValue(value), [setValue])

  return {onClose, onOpen, value}
}

export default useValueDisclosure
