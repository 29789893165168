import * as React from 'react'

import {useToken} from '@chakra-ui/react'
import {StylesConfig, components, DropdownIndicatorProps} from 'react-select'

import {ArrowIcon} from '@/common/arrow-button'

export const therapistsListStyles: StylesConfig<any> = {
  container: (styles) => ({
    ...styles,
    width: '100%',
    // selects "control" element, it will look better when there are more than 2 selects
    // ':first-child > div:first-of-type': {
    //   borderRadius: 'var(--chakra-radii-full) 0 0 var(--chakra-radii-full)',
    // },
    // ':last-child > div:first-of-type': {
    //   borderRadius: '0 var(--chakra-radii-full) var(--chakra-radii-full) 0',
    // },
  }),
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--chakra-colors-brand-green-300)',
    cursor: 'pointer',
    border: '1px solid rgba(255, 255, 255, 0.16)',
    height: '100%',
    padding: '8px 12px',
    borderRadius: 'var(--chakra-radii-full)',
    fontWeight: 700,
  }),
  menu: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    overflowX: 'hidden',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
  }),
  menuList: (styles) => ({
    ...styles,
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 'var(--chakra-radii-full)',
      backgroundColor: 'var(--chakra-colors-brand-green-400)',
      ':hover': {
        backgroundColor: 'var(--chakra-colors-brand-green-600)',
      },
    },
    msOverflowStyle: 'none',
    overflowX: 'hidden',
    overflowY: 'visible',
    scrollbarWidth: 'none',
    padding: '0',
  }),
  option: (styles, {isFocused, isSelected}) => ({
    ...styles,
    backgroundColor: isFocused
      ? 'var(--chakra-colors-brand-green-400)'
      : 'var(--chakra-colors-brand-green-200)',
    color: 'black',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: 'var(--chakra-colors-brand-green-350)',
    paddingLeft: '8px',
    alignItems: 'center',
    borderRadius: 'var(--chakra-radii-full)',
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#000',
    padding: '6px',
    borderRadius: 'var(--chakra-radii-full)',
    ':hover': {
      backgroundColor: 'var(--chakra-colors-brand-green-400)',
    },
  }),

  placeholder: (styles) => ({...styles, color: 'var(--chakra-colors-brand-green-400)'}),
  singleValue: (styles) => ({...styles, color: 'var(--chakra-colors-brand-gray-600)'}),
  indicatorSeparator: (styles) => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    color: 'var(--chakra-colors-brand-green-600)',
    ':hover': {
      color: 'var(--chakra-colors-brand-green-700)',
    },
  }),
  clearIndicator: (styles) => ({
    ...styles,
    '& > svg': {
      width: 24,
      height: 24,
    },
    color: 'var(--chakra-colors-brand-green-600)',
    padding: 2,
    ':hover': {
      backgroundColor: 'var(--chakra-colors-brand-green-350)',
      borderRadius: '50%',
      color: 'var(--chakra-colors-brand-green-700)',
    },
  }),
}

export const therapistsListComponents = {
  DropdownIndicator: (props: DropdownIndicatorProps) => {
    const [green600] = useToken('colors', ['brand.green.600'])

    return (
      <components.DropdownIndicator {...props}>
        <ArrowIcon transform="rotate(90deg)" boxSize="24px" color={green600} />
      </components.DropdownIndicator>
    )
  },
}
