import React from 'react'

import {DeleteIcon} from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Switch,
  Textarea,
} from '@chakra-ui/react'
import {TNode} from '@udecode/plate'

import {COURSE_COVERED_TOPICS_COUNT, COURSE_BENEFITS_COUNT, emptyAdminCourse} from '@/admin/courses/constants'
import {uploadFile} from '@/admin/utils'
import {AdminCourse} from '@/api/models'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import DatetimeInput from '@/common/datetime-input'
import Dropzone from '@/common/dropzone'
import ImageUpload from '@/common/image-upload'
import useEditorModalState from '@/common/use-editor-modal'
import {SUPABASE_COURSE_BOOKLETS_BUCKET, SUPABASE_COURSE_LOGOS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {inputToUpsertArgs} from './utils'

const CourseEditorModal = ({
  open,
  onClose,
  onComplete,
  item,
}: CommonModalProps & {
  item: AdminCourse | null
}) => {
  const [isNextLvlCourse, setIsNextLvlCourse] = React.useState(false)
  const [withMeetings, setWithMeetings] = React.useState(false)
  const [isBookletUploading, setIsBookletUploading] = React.useState(false)
  const {input, image, setImage, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<AdminCourse>({
      table: 'courses',
      item,
      emptyInput: emptyAdminCourse,
      inputToUpsertArgs: React.useCallback(
        (i: AdminCourse) => inputToUpsertArgs(i, isNextLvlCourse, withMeetings),
        [isNextLvlCourse, withMeetings]
      ),
      open,
      onComplete,
      onClose,
      imageParams: {
        bucket: SUPABASE_COURSE_LOGOS_BUCKET,
        key: 'logo',
        signed_key: 'signed_logo',
      },
    })

  React.useEffect(() => {
    setIsNextLvlCourse(!!item?.nextlvl_id)
    setWithMeetings(!!item?.meetings_count)
  }, [item])

  // input handlers

  const handleFullDescriptionChange = React.useCallback(
    (value) => handleCustomInputChange({full_description: value}),
    [handleCustomInputChange]
  )

  const handleShortDescriptionChange = React.useCallback(
    (value) => handleCustomInputChange({short_description: value}),
    [handleCustomInputChange]
  )

  const handleBenefitsChange = React.useCallback(
    (value: string, index: number) =>
      handleCustomInputChange({benefits: input.benefits.map((v, i) => (i === index ? value : v))}),
    [input, handleCustomInputChange]
  )

  const handleCoveredTopicChange = React.useCallback(
    (value: TNode[], index: number) =>
      handleCustomInputChange({
        covered_topics: input.covered_topics.map((v, i) => (i === index ? value : v)),
      }),
    [input, handleCustomInputChange]
  )

  const handleBasePriceChange = React.useCallback(
    (v) => handleCustomInputChange({base_price: v ? +v * 100 : null}),
    [handleCustomInputChange]
  )

  const handlePriceWithMeetingsChange = React.useCallback(
    (v) => handleCustomInputChange({price_with_meetings: v ? +v * 100 : null}),
    [handleCustomInputChange]
  )

  const handleMeetingsCountChange = React.useCallback(
    (v) => handleCustomInputChange({meetings_count: v ? +v : null}),
    [handleCustomInputChange]
  )

  const handleBookletUpload = React.useCallback(
    async (files: File[]) => {
      setIsBookletUploading(true)
      try {
        const [filepath, signedURL] = await uploadFile(files[0], SUPABASE_COURSE_BOOKLETS_BUCKET)
        handleCustomInputChange({booklet_path: filepath, signed_booklet_url: signedURL})
      } catch (e) {
        console.error('Failed to upload course booklet', e)
      }
      setIsBookletUploading(false)
    },
    [handleCustomInputChange]
  )

  const handleBookletRemove = React.useCallback(
    () => handleCustomInputChange({booklet_path: null, signed_booklet_url: null}),
    [handleCustomInputChange]
  )

  const handleOrderChange = React.useCallback(
    (v: string) => handleCustomInputChange({order: v ? +v : null}),
    [handleCustomInputChange]
  )

  const handleSectionsNumberOverrideChange = React.useCallback(
    (v: string) => handleCustomInputChange({sections_number_override: v ? +v : null}),
    [handleCustomInputChange]
  )

  const handleReleaseDateChange = React.useCallback(
    (v: Date | null) => handleCustomInputChange({release_at: v}),
    [handleCustomInputChange]
  )

  const handleIsNextLvlCourseChange = React.useCallback(
    ({target: {checked}}) => setIsNextLvlCourse(checked),
    []
  )

  const handleWithMeetingsChange = React.useCallback(({target: {checked}}) => setWithMeetings(checked), [])

  const isSubmitDisabled = React.useMemo(
    () =>
      !input.name ||
      !input.full_description ||
      !input.short_description ||
      loading ||
      isBookletUploading ||
      (withMeetings && !input.meetings_count),
    [isBookletUploading, withMeetings, loading, input]
  )

  const benefits = React.useMemo(
    () => (input.benefits.length ? input.benefits : new Array(COURSE_BENEFITS_COUNT).fill('')),
    [input.benefits]
  )
  const coveredTopics = React.useMemo(
    () =>
      input.covered_topics.length
        ? input.covered_topics
        : new Array(COURSE_COVERED_TOPICS_COUNT).fill(initialEditorValue),
    [input.covered_topics]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Aktualizuj warsztat' : 'Dodaj warsztat'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <FormControl isDisabled={loading} as={Stack} align="center">
              <FormLabel>Miniaturka</FormLabel>
              <ImageUpload width={200} height={200} editing={true} value={image} onChange={setImage} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa warsztatu</FormLabel>
              <Input name="name" value={input.name} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Cena podstawowa (PLN)</FormLabel>
              <NumberInput min={0} value={(input.base_price || 0) / 100} onChange={handleBasePriceChange}>
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="withMeetings"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={withMeetings}
                  onChange={handleWithMeetingsChange}
                />
                <FormLabel htmlFor="withMeetings">Warsztat ze spotkaniami?</FormLabel>
              </HStack>
            </FormControl>
            <HStack>
              <FormControl isDisabled={loading || !withMeetings}>
                <FormLabel>Cena ze spotkaniami (PLN)</FormLabel>
                <NumberInput
                  min={0}
                  value={(input.price_with_meetings || 0) / 100}
                  onChange={handlePriceWithMeetingsChange}
                >
                  <NumberInputField />
                </NumberInput>
              </FormControl>

              <FormControl
                isDisabled={loading || !withMeetings}
                isInvalid={withMeetings && !input.meetings_count}
              >
                <FormLabel>Spotkania w pakiecie</FormLabel>
                <NumberInput min={0} value={input.meetings_count || 0} onChange={handleMeetingsCountChange}>
                  <NumberInputField />
                </NumberInput>
              </FormControl>
            </HStack>
            <FormControl id="order" isDisabled={loading}>
              <FormLabel>Numer porządkowy</FormLabel>
              <NumberInput onChange={handleOrderChange} value={input.order ?? ''}>
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="published"
                  name="published"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={input.published}
                  onChange={handleInputChange}
                />
                <FormLabel htmlFor="published">Opublikowany</FormLabel>
              </HStack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="is_new"
                  name="is_new"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={input.is_new}
                  onChange={handleInputChange}
                />
                <FormLabel htmlFor="is_new">Nowość</FormLabel>
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Dostępny od</FormLabel>
              <DatetimeInput
                isDisabled={loading}
                value={input.release_at}
                onChange={handleReleaseDateChange}
                showTimeSelect={true}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Krótki opis</FormLabel>
              <ContentEditor
                id="courses-upsert-modal-short-description"
                variant="basic"
                value={input.short_description ?? initialEditorValue}
                onChange={handleShortDescriptionChange}
                readOnly={false}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Pełny opis</FormLabel>
              <ContentEditor
                id="courses-upsert-modal-full-description"
                variant="extended"
                value={input.full_description ?? initialEditorValue}
                onChange={handleFullDescriptionChange}
                readOnly={false}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opis meta</FormLabel>
              <Textarea
                name="meta_description"
                value={input.meta_description ?? ''}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Co daje szkolenie?</FormLabel>
              <Stack>
                {benefits.map((value, i) => (
                  <BenefitsInput key={i} index={i} value={value} onChange={handleBenefitsChange} />
                ))}
              </Stack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Poruszane zagadnienia</FormLabel>
              <Stack>
                {coveredTopics.map((value, i) => (
                  <CoveredTopicsContentEditor
                    key={i}
                    index={i}
                    value={value}
                    onChange={handleCoveredTopicChange}
                  />
                ))}
              </Stack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Broszura</FormLabel>
              {input.signed_booklet_url ? (
                <HStack spacing="5">
                  <Button as={Link} href={input.signed_booklet_url} target="_blank">
                    Podgląd
                  </Button>
                  <IconButton
                    aria-label="remove-booklet"
                    onClick={handleBookletRemove}
                    colorScheme="red"
                    icon={<DeleteIcon />}
                  />
                </HStack>
              ) : (
                <Dropzone
                  disabled={isBookletUploading || loading}
                  loading={isBookletUploading}
                  multiple={false}
                  onDrop={handleBookletUpload}
                />
              )}
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Nadpisanie liczby sekcji</FormLabel>
              <NumberInput
                min={0}
                onChange={handleSectionsNumberOverrideChange}
                value={input?.sections_number_override ?? ''}
              >
                <NumberInputField placeholder="Liczba sekcji" />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>URL Filmu promocyjnego</FormLabel>
              <Input
                name="promotional_video_url"
                value={input.promotional_video_url ?? ''}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="isNextLvlCourse"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={isNextLvlCourse}
                  onChange={handleIsNextLvlCourseChange}
                />
                <FormLabel htmlFor="isNextLvlCourse">Warsztat NextLvl</FormLabel>
              </HStack>
            </FormControl>
            <FormControl isDisabled={!isNextLvlCourse || loading}>
              <FormLabel>ID warsztatu NextLvl</FormLabel>
              <Input
                name="nextlvl_id"
                value={isNextLvlCourse && input.nextlvl_id ? input.nextlvl_id : ''}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl id="nextlvlURL" isDisabled={!isNextLvlCourse || loading}>
              <FormLabel>URL warsztatu NextLvl</FormLabel>
              <Input
                name="nextlvl_url"
                value={isNextLvlCourse && input.nextlvl_url ? input.nextlvl_url : ''}
                onChange={handleInputChange}
                pr="2.75rem"
              />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter bg="white" position="sticky" bottom={0}>
          <Button w="100%" onClick={handleUpsert} disabled={isSubmitDisabled} isLoading={loading}>
            {item?.id ? 'Aktualizuj warsztat' : 'Utwórz warsztat'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const CoveredTopicsContentEditor = ({
  value,
  index,
  onChange,
}: {
  value: TNode[]
  index: number
  onChange: (value: TNode[], index: number) => void
}) => {
  const handleChange = React.useCallback(
    (value) => {
      onChange(value, index)
    },
    [onChange, index]
  )

  return (
    <ContentEditor
      id={`courses-upsert-modal-covered-topics-${index + 1}`}
      variant="basic"
      value={value}
      onChange={handleChange}
      readOnly={false}
    />
  )
}

const BenefitsInput = ({
  value,
  index,
  onChange,
}: {
  value: string
  index: number
  onChange: (value: string, index: number) => void
}) => {
  const handleChange = React.useCallback(
    ({target: {value}}) => {
      onChange(value, index)
    },
    [onChange, index]
  )

  return (
    <Input
      id={`courses-upsert-modal-what-it-gives-${index + 1}`}
      value={value}
      onChange={handleChange}
      placeholder={`Odpowiedź ${index + 1}...`}
    />
  )
}

export default CourseEditorModal
