// can't use '@' imports here because server.js imports this file
import {
  ABOUT_US,
  ADMIN_PREFIX,
  BLOG,
  CONTACT_US,
  COURSES,
  COURSE_PREFIX,
  FAQ,
  HOME,
  JOIN_US,
  PRIVACY_POLICY,
  PSYCHOTHERAPISTS,
  PSYCHOTHERAPY,
  SUPPORT_GROUPS,
  SUPPORT_GROUP_PREFIX,
  TERMS_AND_CONDITIONS,
  THERAPIST_PREFIX,
  USER_PREFIX,
  WEBINAR_PREFIX,
} from '../../router/paths'
import {DynamicContentType, MetaTags} from './types'

export const associatedTags: Record<string, string[]> = {
  title: ['og:title'],
  description: ['og:description'],
}

export const titleSuffix = ' | Psychoforma'

// TODO: Ask the SEO people which tags we should include
export const defaultTags = {
  title: 'Psychoforma',
  description:
    'Psychoterapia, warsztaty oraz szkolenia z wykwalifikowana kadrą Psychoformy. Kliknij i zapoznaj się z naszą ofertą! Zapraszamy! ►',
}

export const staticPathMetaTags: Record<string, MetaTags> = {
  [ABOUT_US]: {
    title: 'O Psychoformie' + titleSuffix,
    description:
      'Psychoforma to platforma wsparcia psychologicznego online. Chcesz dowiedzieć się więcej, kim jesteśmy i jak działamy? Kliknij i sprawdź! ►',
  },
  [BLOG]: {
    title: 'Blog Psychoforma | Psychologia, psychoterapia, rozwój osobisty',
    description:
      'Na blogu Psychoformy piszemy na tematy psychologii, psychoterapii oraz rozwoju osobistego. Kliknij i dojrzewaj do świadomej zmiany razem z nami!',
  },
  [CONTACT_US]: {
    title: 'Kontakt' + titleSuffix,
    description:
      'Nie wiesz jak zacząć? Kliknij i skontaktuj się z nami. Odbieramy od poniedziałku do soboty ►',
  },
  [COURSES]: {
    title: 'Warsztaty i szkolenia rozwojowe oraz psychologiczne' + titleSuffix,
    description:
      'Warsztaty oraz szkolenia rozwojowe z wykwalifikowana kadrą Psychoformy. Jesteśmy tu dla Ciebie! ► Zapraszamy!',
  },
  [FAQ]: {
    title: 'Często zadawane pytania' + titleSuffix,
    description:
      'Najczęściej zadawane pytania dotyczące platformy Psychoforma. Kliknij i poznaj nasz serwis! ►',
  },
  [HOME]: {
    title: 'Psychoforma | Psychoterapia oraz warsztaty ze specjalistami',
    description:
      'Psychoterapia, warsztaty oraz szkolenia z wykwalifikowana kadrą Psychoformy. Kliknij i zapoznaj się z naszą ofertą! Zapraszamy! ►',
  },
  [JOIN_US]: {
    title: 'Dołącz do nas' + titleSuffix,
    description:
      'Jesteś psychologiem, psychoterapeutą lub terapeutą uzależnień? Kogoś takiego właśnie szukamy! ► Kliknij i dołącz do naszego zespołu!',
  },
  [PSYCHOTHERAPISTS]: {
    title: 'Psychoterapeuci, psycholodzy, doradcy, trenerzy' + titleSuffix,
    description:
      'Poznaj naszą wykwalifikowana kadrę psychoterapeutów, trenerów oraz doradców. Jesteśmy tu dla Ciebie! Kliknij i umów się na wizytę! ►',
  },
  [PSYCHOTHERAPY]: {
    title: 'Psychoterapia - terapia online' + titleSuffix,
    description:
      'Psychoterapia online z wykwalifikowanymi specjalistami. Rozwijaj siebie i naucz się radzić z problemami. Jesteśmy tu dla Ciebie, zapraszamy! ►',
  },
  [PRIVACY_POLICY]: {
    title: 'Polityka prywatności' + titleSuffix,
    description: 'Kliknij i zapoznaj się z polityką prywatności serwisu Internetowego Psychoforma.pl ►',
  },
  [SUPPORT_GROUPS]: {
    title: 'Grupy wsparcia' + titleSuffix,
    description: 'Grupy wsparcia',
  },
  [TERMS_AND_CONDITIONS]: {
    title: 'Regulamin' + titleSuffix,
    description: 'Kliknij i zapoznaj się z regulaminem serwisu Internetowego Psychoforma.pl ►',
  },
}

export const matchPrefixMetaTags: Record<string, MetaTags> = {
  [ADMIN_PREFIX]: {
    title: 'Panel Administratora' + titleSuffix,
    description: 'Panel Administratora serwisu Psychoforma.pl',
  },
  [USER_PREFIX]: {
    title: 'Twoje Konto' + titleSuffix,
    description: 'Konto użytkownika serwisu Psychoforma.pl',
  },
  [COURSE_PREFIX]: staticPathMetaTags[COURSES],
  [BLOG]: staticPathMetaTags[BLOG],
  [THERAPIST_PREFIX]: staticPathMetaTags[PSYCHOTHERAPISTS],
  [SUPPORT_GROUP_PREFIX]: staticPathMetaTags[SUPPORT_GROUPS],
  [WEBINAR_PREFIX]: {
    title: 'Webinar' + titleSuffix,
    description: 'Webinarium prowadzone przez specjalistów z Psychoforma.pl',
  },
}

export const prefixToType: Record<string, DynamicContentType> = {
  [COURSE_PREFIX]: 'course',
  [BLOG]: 'blog',
  [THERAPIST_PREFIX]: 'therapist',
  [WEBINAR_PREFIX]: 'webinar',
  [SUPPORT_GROUP_PREFIX]: 'support_group',
}
