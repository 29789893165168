import React from 'react'

import {Button, Flex, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {USER_COURSES} from '@/user/router'

const CourseCompletionSummary = ({course}: {course: string}) => {
  return (
    <>
      <Flex flexDirection="column" alignItems="center" py="10">
        <Text fontSize="x-large" fontWeight="semibold">
          Kurs ukończony, gratulacje!
        </Text>
        <Button mt="5" as={Link} to={USER_COURSES}>
          Wróc do wykupionych warsztatów
        </Button>
      </Flex>
    </>
  )
}

export default CourseCompletionSummary
