import React from 'react'

import {supabase} from '@/api'
import {SupportGroupJoined, parseSupportGroupJoined} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import AccessManagementModal from './access-management-modal'
import {columns, defaultSelectedColumns, emptySupportGroup, filterFields} from './constants'
import SupportGroupEditorModal from './editor-modal'
import MeetingsManagementModal from './meetings-management-modal'
import {accessAction, meetingsAction} from './utils'

const SupportGroups = () => {
  const tableState = useTableState({sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<SupportGroupJoined>({
    table: 'support_groups_view',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof SupportGroupJoined,
    filter: tableState.filter,
    parsingFunction: parseSupportGroupJoined,
  })

  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<SupportGroupJoined>()
  const {
    value: deleteValue,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useValueDisclosure<SupportGroupJoined>()
  const {
    value: accessManagementValue,
    onOpen: onAccessManagementOpen,
    onClose: onAccessManagementClose,
  } = useValueDisclosure<number>()
  const {
    value: meetingsManagementValue,
    onOpen: onMeetingsManagementOpen,
    onClose: onMeetingsManagementClose,
  } = useValueDisclosure<SupportGroupJoined>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptySupportGroup)
  }, [onEditorOpen])

  const handleDelete = React.useCallback(async () => {
    const {error} = await supabase.rpc('delete_support_group', {
      support_group_id: deleteValue?.id,
    })
    if (error) throw error
  }, [deleteValue])

  const actions = React.useMemo(
    () => [
      deleteAction(onDeleteOpen),
      editAction(onEditorOpen),
      meetingsAction(onMeetingsManagementOpen),
      accessAction((i) => onAccessManagementOpen(i.id ?? 0)),
    ],
    [onEditorOpen, onDeleteOpen, onAccessManagementOpen, onMeetingsManagementOpen]
  )

  return (
    <>
      <DataTable<SupportGroupJoined>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        onRefresh={fetch}
        loading={loading}
        actions={actions}
        onAdd={handleAdd}
        filterFields={filterFields}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <SupportGroupEditorModal
        item={editorValue}
        onClose={onEditorClose}
        open={!!editorValue}
        onComplete={fetch}
      />
      <AccessManagementModal
        supportGroupID={accessManagementValue ?? 0}
        open={!!accessManagementValue}
        onClose={onAccessManagementClose}
        onComplete={fetch}
      />
      <MeetingsManagementModal
        supportGroup={meetingsManagementValue}
        open={!!meetingsManagementValue}
        onClose={onMeetingsManagementClose}
        onComplete={fetch}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        open={!!deleteValue}
        onComplete={fetch}
        onClose={onDeleteClose}
      />
    </>
  )
}

export default SupportGroups
