import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'
import {format} from 'date-fns'

import {supabase} from '@/api'
import {BuyResult, OrderDiscountCode, PublicWebinar} from '@/api/models'
import {
  DiscountCodeInputProps,
  OrderAgreement,
  OrderButtons,
  OrderModal,
  OrderSummary,
  SummaryItemProps,
} from '@/common/order'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

const WebinarOrderModal = ({webinar, open, onClose}: CommonModalProps & {webinar: PublicWebinar}) => {
  const [agreed, setAgreed] = React.useState(false)
  const [discountCode, setDiscountCode] = React.useState<OrderDiscountCode>()

  const {handleSubmit, loading} = useLoadingState(
    React.useCallback(async () => {
      if (!webinar.meeting) return

      const {data, error} = await supabase.rpc('create_webinar_access_order', {
        meeting: webinar.meeting,
        discount_code: discountCode?.code || null,
      })
      if (error) throw error
      if (!data) throw new Error('No data')

      const res = data as any as BuyResult
      if ('error' in res) throw new Error(res.error)
      if (!res.redirect_url) throw new Error('Invalid redirect URL')

      window.location.href = res.redirect_url
    }, [discountCode, webinar]),
    {onErrorToast: 'Zakup webinaru zakończony niepowodzeniem'}
  )

  const orderSummaryItems: SummaryItemProps[] = React.useMemo(
    () =>
      webinar
        ? [
            {
              title: 'Webinar',
              children: webinar.name,
            },
            {
              title: 'Data',
              children: format(new Date(webinar.start_at), "dd.MM.yy 'godz.' HH:mm"),
            },
            {
              title: 'Prowadzący',
              children: (
                <Stack>
                  {webinar.hosts.map((h, i) => (
                    <Text key={i}>{h.full_name}</Text>
                  ))}
                </Stack>
              ),
            },
          ]
        : [],
    [webinar]
  )

  const doesCodeApply = React.useCallback(
    ({product_type, products_ids}: OrderDiscountCode) =>
      !product_type ||
      (product_type === 'webinar' &&
        (!products_ids.length || products_ids.some((id) => id === webinar.meeting))),
    [webinar]
  )

  const discountCodeProps: DiscountCodeInputProps = React.useMemo(
    () => ({
      value: discountCode,
      onChange: setDiscountCode,
      doesApply: doesCodeApply,
    }),
    [discountCode, doesCodeApply]
  )

  const discountedPrice = React.useMemo(() => {
    if (!discountCode || !webinar.meeting) return null

    if (!doesCodeApply(discountCode)) return null

    return (webinar.price * (1 - discountCode.discount_percentage / 100)) / 100
  }, [webinar, discountCode, doesCodeApply])

  return (
    <OrderModal open={open} onClose={onClose}>
      <Stack spacing={8}>
        <OrderSummary
          title="Zapisz się na webinar"
          image={webinar.thumbnail}
          price={webinar.price}
          summaryItems={orderSummaryItems}
          disabled={loading}
          discountCodeProps={discountCodeProps}
        />
        <OrderAgreement isChecked={agreed} onChange={setAgreed} />
        <OrderButtons
          price={webinar.price}
          discountedPrice={discountedPrice}
          isDisabled={!agreed}
          isLoading={loading}
          onPrevStep={onClose}
          onNextStep={handleSubmit}
        />
      </Stack>
    </OrderModal>
  )
}

export default WebinarOrderModal
