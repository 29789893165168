import React, {useCallback} from 'react'

import {Box, Button, Container, Flex, Link as ChakraLink, Slide, Stack, useMediaQuery} from '@chakra-ui/react'
import {Link as RouterLink} from 'react-router-dom'

import {selectCookieConsent, setCookieConsent} from '@/privacy-policy/state'
import {PRIVACY_POLICY} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'

const CookieConsentBar = () => {
  const dispatch = useAppDispatch()
  const cookieConsent = useAppSelector(selectCookieConsent)

  const [isLargerThan800] = useMediaQuery('(min-width: 800px)')

  const onAgree = useCallback(() => {
    dispatch(setCookieConsent(true))
  }, [dispatch])

  return (
    <Slide direction="bottom" in={!cookieConsent} style={{background: '#444', color: '#ddd'}}>
      <Container maxW="container.xl">
        <Flex py={2} justifyContent="space-between" direction={isLargerThan800 ? 'row' : 'column'}>
          <Box mr={3} alignSelf="center">
            Używając naszej strony internetowej, zgadzasz się na korzystanie z plików cookie zgodnie z naszą
            <ChakraLink as={RouterLink} to={PRIVACY_POLICY} color="#eec">
              polityką prywatności
            </ChakraLink>
            .
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="center" mt={isLargerThan800 ? 0 : 3}>
            <Button colorScheme="green" onClick={onAgree}>
              Rozumiem
            </Button>
          </Stack>
        </Flex>
      </Container>
    </Slide>
  )
}

export default CookieConsentBar
