import React from 'react'

import {ChevronRightIcon, MinusIcon} from '@chakra-ui/icons'
import {HStack, IconButton, Text} from '@chakra-ui/react'

import {TherapistAssignedSpecialtyViewItem} from '@/api/models'
import useLoadingState from '@/common/use-loading-state'

const TherapistSpecialtyItem = ({
  item: {id, name},
  onDelete,
  isDisabled,
}: {
  item: TherapistAssignedSpecialtyViewItem
  onDelete: (id: string) => Promise<void>
  isDisabled: boolean
}) => {
  const _handleDelete = React.useCallback(async () => await onDelete(id), [id, onDelete])
  const {handleSubmit: handleDelete, loading: loadingDelete} = useLoadingState(_handleDelete)

  return (
    <HStack width="100%">
      <HStack width="100%">
        <ChevronRightIcon />
        <Text color={isDisabled ? 'gray' : undefined}>{name}</Text>
      </HStack>
      <IconButton
        isLoading={loadingDelete}
        isDisabled={isDisabled}
        aria-label="delete"
        variant="ghost"
        colorScheme="red"
        onClick={handleDelete}
        icon={<MinusIcon />}
      />
    </HStack>
  )
}

export default TherapistSpecialtyItem
