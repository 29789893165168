import * as React from 'react'

import {Box, Container, Flex, Heading, Stack, Text, useBreakpointValue} from '@chakra-ui/react'
import {addDays, format, intervalToDuration, isToday, startOfWeek, subDays} from 'date-fns'
import {pl} from 'date-fns/locale'

import {PGTimeRangeSlot} from '@/api/models'
import {selectProfile} from '@/auth/state'
import {ArrowIconButton} from '@/common/arrow-button'
import {useAppSelector} from '@/store'
import {formatDate, formatEventDuration} from '@/utils/string'

import {useTherapistCalendarEvents} from './hooks'

const TherapistCalendarState = ({therapistID}: {therapistID?: number}) => {
  const user = useAppSelector(selectProfile)

  const scrollRef = React.useRef<HTMLDivElement>(null)

  const daysToShow = useBreakpointValue({
    base: 2,
    md: 7,
    sm: 3,
  })
  const [range, setRange] = React.useState<PGTimeRangeSlot>()
  React.useEffect(() => {
    if (!daysToShow) return

    const from = daysToShow === 7 ? startOfWeek(new Date(), {weekStartsOn: 1}) : new Date()
    setRange({
      from,
      to: addDays(from, daysToShow ?? 1 - 1),
    })
  }, [daysToShow]) // eslint-disable-line

  const daysInWeek = React.useMemo(
    () =>
      range
        ? Array(daysToShow)
            .fill(1)
            .map((_, i) => addDays(range.from, i))
        : undefined,
    [range, daysToShow]
  )

  const handlePreviousWeek = React.useCallback(
    () =>
      setRange(
        (prev) =>
          prev && {
            from: subDays(prev.from, daysToShow ?? 1),
            to: subDays(prev.to, daysToShow ?? 1),
          }
      ),
    [daysToShow]
  )
  const handleNextWeek = React.useCallback(
    () =>
      setRange(
        (prev) =>
          prev && {
            from: addDays(prev.from, daysToShow ?? 1),
            to: addDays(prev.to, daysToShow ?? 1),
          }
      ),
    [daysToShow]
  )

  const {events, loading} = useTherapistCalendarEvents({
    therapist: therapistID || user?.therapist_id,
    range,
  })

  React.useEffect(() => {
    // reset calendar view to 8:00am on range change
    scrollRef.current && (scrollRef.current.scrollTop = 480) // 8 * 60 pixels = 8:00am
  }, [range])

  const groupedByDay = React.useMemo(
    () =>
      events?.reduce((res, e) => {
        const key = formatDate(e.from)
        return {
          ...res,
          [key]: [...(res[key] || []), e].sort((a, b) => a.from.getTime() - b.from.getTime()),
        }
      }, {} as Record<string, PGTimeRangeSlot[]>) || {},
    [events]
  )

  return (
    <>
      <Heading size="md">Stan kalendarza</Heading>
      <Container maxW="container.lg" p={0}>
        <Stack
          spacing={8}
          width="100%"
          opacity={loading ? 0.6 : 1}
          pointerEvents={loading ? 'none' : undefined}
        >
          <Stack direction="row" spacing={[4, null, 16]} alignItems="center">
            <ArrowIconButton
              aria-label="Poprzedni tydzień"
              width={['40px', null, '64px']}
              color="brand.yellow.600"
              _hover={{color: 'brand.yellow.700'}}
              style={{transform: 'rotate(180deg)'}}
              onClick={handlePreviousWeek}
            />
            <Text
              color="brand.green.800"
              fontSize="md"
              fontWeight={500}
              flex={1}
              textAlign={['center', null, 'left']}
            >
              {range
                ? range.from.getMonth() === range.to.getMonth()
                  ? `${range.from.getDate()}-${format(range.to, 'd LLLL y', {locale: pl})}`
                  : `${format(range.from, 'd LLLL', {locale: pl})} - ${format(range.to, 'd LLLL y', {
                      locale: pl,
                    })}`
                : '...'}
            </Text>
            <ArrowIconButton
              aria-label="Następny tydzień"
              width={['40px', null, '64px']}
              color="brand.yellow.600"
              _hover={{color: 'brand.yellow.700'}}
              onClick={handleNextWeek}
            />
          </Stack>

          <Flex direction="column" h="70vh" overflowY="scroll" ref={scrollRef}>
            <Flex position="sticky" top={0} zIndex="sticky">
              <Box w="48px" bg="white" />
              {daysInWeek?.map((day, i) => (
                <Flex
                  key={i}
                  flex={1}
                  w="100%"
                  h="82px"
                  justify="center"
                  align="center"
                  bg={isToday(day) ? 'brand.yellow.600' : 'brand.green.600'}
                  color="white"
                  border="1px solid"
                  borderColor="brand.green.300"
                >
                  <Text fontWeight={700}>{format(day, 'd cccccc', {locale: pl})}</Text>
                </Flex>
              ))}
            </Flex>
            <Flex align="stretch" position="relative" bg="brand.green.100">
              <Flex
                h="1440px" // 24 * 50 -> 1 px == 1 minute
                w="48px"
                direction="column"
                justify="space-between"
                align="flex-end"
                bg="white"
                pr={2}
              >
                {Array(24)
                  .fill(1)
                  .map((_, i) => (
                    <Flex
                      key={i}
                      position="absolute"
                      top={`${i * 60}px`}
                      w="100%"
                      left="0"
                      direction="column"
                    >
                      <Box bg="brand.green.300" h="2px" w="100%" />
                      <Text>{i}:00</Text>
                    </Flex>
                  ))}
              </Flex>
              {daysInWeek?.map((day, i) => (
                <Flex
                  key={i}
                  flex={1}
                  px={1}
                  border="1px solid"
                  borderColor="brand.green.300"
                  position="relative"
                >
                  {groupedByDay[formatDate(day)]?.map((e, i) => (
                    <EventTile key={i} event={e} />
                  ))}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Stack>
      </Container>
    </>
  )
}

const EventTile = ({event}: {event: PGTimeRangeSlot}) => {
  const top = React.useMemo(() => event.from.getHours() * 60 + event.from.getMinutes(), [event])

  const heigth = React.useMemo(() => {
    const duration = intervalToDuration({
      start: event.from,
      end: event.to,
    })
    return (duration.hours || 0) * 60 + (duration.minutes || 0)
  }, [event])

  return (
    <Box
      w="calc(100% - 8px)"
      position="absolute"
      top={top}
      h={heigth}
      bg="brand.green.600"
      rounded="lg"
      color="white"
      p={2}
      opacity={0.8}
    >
      <Text zIndex={2}>{formatEventDuration(event)}</Text>
    </Box>
  )
}

export default TherapistCalendarState
