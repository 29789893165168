import * as React from 'react'

import {CheckIcon, CloseIcon, TimeIcon} from '@chakra-ui/icons'
import {Tooltip} from '@chakra-ui/react'

import {BlogPost} from '@/api/models'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const MIN_LENGHT_NAME = 3

export const emptyBlogPost: BlogPost = {
  created_at: new Date(),
  updated_at: new Date(),
  published_at: null,
  name: '',
  author: null,
  language: '',
  description: initialEditorValue,
  meta_description: null,
  image: null,
  featured_index: null,
}

export const defaultSelectedColumns = ['name', 'featured_index', 'created_at', 'published']

export const columns: Column<BlogPost>[] = [
  {
    Header: 'ID',
    id: 'id',
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Indeks wyróżnienia',
    id: 'featured_index',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Język',
    id: 'language',
    renderer: ({language}) => (language === 'pl' ? 'Polski' : 'Angielski'),
  },
  {
    Header: 'Czy opublikowany',
    id: 'published',
    renderer: ({published_at}) => (
      <div>
        {published_at ? (
          new Date(published_at) > new Date() ? (
            <Tooltip label="Zaplanowany">
              <TimeIcon color="gray" />
            </Tooltip>
          ) : (
            <Tooltip label="Widoczny publicznie">
              <CheckIcon color="#3eb03e" />
            </Tooltip>
          )
        ) : (
          <Tooltip label="Nieopublikowany">
            <CloseIcon color="#e53e3e" />
          </Tooltip>
        )}
      </div>
    ),
    sortable: false,
  },
  {
    Header: 'Opis meta',
    id: 'meta_description',
    renderAs: 'longString',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Indeks wyróżnienia',
    name: 'featured_index',
    variant: 'number',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
  {
    label: 'Język',
    name: 'language',
    values: [
      {label: 'Angielski', name: 'en'},
      {label: 'Polski', name: 'pl'},
    ],
    variant: 'select',
  },
]
