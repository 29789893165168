import React from 'react'

import {chakra} from '@chakra-ui/react'
import {createPluginFactory, PlateRenderElementProps} from '@udecode/plate'

const style: React.CSSProperties = {
  margin: '1.5em 10px',
  padding: '0.5em 10px',
}

export const BlockquoteRenderer = ({attributes, children}: PlateRenderElementProps) => {
  return (
    <chakra.blockquote style={style} borderLeftWidth="10px" borderLeftColor="gray.200" {...attributes}>
      {children}
    </chakra.blockquote>
  )
}

export const createBlockquotePlugin = createPluginFactory({
  component: BlockquoteRenderer,
  isElement: true,
  key: 'blockquote',
  type: 'blockquote',
})
