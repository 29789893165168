import React from 'react'

import {Text} from '@chakra-ui/react'
import {createPluginFactory, HotkeyPlugin, onKeyDownToggleMark, PlateRenderElementProps} from '@udecode/plate'

const ItalicRenderer = ({children}: PlateRenderElementProps) => (
  <Text as="em" textStyle="italic">
    {children}
  </Text>
)

export const createItalicPlugin = createPluginFactory<HotkeyPlugin>({
  component: ItalicRenderer,
  handlers: {
    onKeyDown: onKeyDownToggleMark,
  },
  isLeaf: true,
  key: 'italic',
  options: {
    hotkey: 'mod+i',
  },
  type: 'italic',
})
