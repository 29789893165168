import * as React from 'react'

import {Tag} from '@chakra-ui/react'

import {Payment, PaymentStatus} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const mapStatusToColor: Record<PaymentStatus, string> = {
  completed: 'green',
  pending: 'yellow',
  refunded: 'blue',
  cancelled: 'red',
}

export const mapStatusToTranslationLabel: Record<PaymentStatus, string> = {
  completed: 'Zrealizowane',
  pending: 'Oczekiwanie na płatność',
  refunded: 'Zwrócono',
  cancelled: 'Anulowano',
}

export const defaultSelectedColumns = ['order', 'user_email', 'amount', 'status', 'updated_at']

export const columns: Column<Payment>[] = [
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
  },
  {
    Header: 'ID zamówienia przelewy24',
    id: 'p24_order_id',
    renderAs: 'code',
  },
  {
    Header: 'Zamówienie',
    id: 'order',
    renderAs: 'code',
  },
  {
    Header: 'ID użytkownika',
    id: 'user',
    renderAs: 'code',
  },
  {
    Header: 'E-mail użytkownika',
    id: 'user_email',
  },
  {
    Header: 'Kwota',
    id: 'amount',
    renderAs: 'monetary',
  },
  {
    Header: 'Status',
    id: 'status',
    renderer: ({status}) => (
      <Tag size="md" variant="solid" colorScheme={mapStatusToColor[status]}>
        {mapStatusToTranslationLabel[status]}
      </Tag>
    ),
    toCSV: ({status}) => mapStatusToTranslationLabel[status],
  },
  {
    Header: 'Waluta',
    id: 'currency',
  },
  {
    Header: 'Kraj',
    id: 'country',
  },
  {
    Header: 'Język',
    id: 'language',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'text',
  },
  {
    label: 'ID zamówienia przelewy24',
    name: 'p24_order_id',
    variant: 'text',
  },
  {
    label: 'Zamówienie',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'ID użytkownika',
    name: 'user',
    variant: 'text',
  },
  {
    label: 'E-mail użytkownika',
    name: 'user_email',
    variant: 'text',
  },
  {
    label: 'Kwota',
    name: 'amount',
    variant: 'monetary',
  },
  {
    label: 'Status',
    name: 'status',
    variant: 'select',
    values: Object.entries(mapStatusToTranslationLabel).map(([k, v]) => ({
      label: v,
      name: k,
    })),
  },
  {
    label: 'Waluta',
    name: 'currency',
    variant: 'text',
  },
  {
    label: 'Kraj',
    name: 'country',
    variant: 'text',
  },
  {
    label: 'Język',
    name: 'language',
    variant: 'text',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
