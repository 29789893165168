import * as React from 'react'

import {Button, Center, Divider, Stack, Text, useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {generateGoogleAuthURL} from '@/api/google'
import {GOOGLE_SERVICE_CALENDAR} from '@/constants'
import {formatTimestamp} from '@/utils/string'

const GoogleAdminCalendarAuth = () => {
  const toast = useToast()
  const [lastSyncDate, setLastSyncDate] = React.useState<Date>()
  const [calendarID, setCalendarID] = React.useState('')

  React.useEffect(() => {
    ;(async () => {
      try {
        const {data, error} = await supabase
          .from('google_calendar_settings')
          .select('updated_at, calendar')
          .eq('id', GOOGLE_SERVICE_CALENDAR)
          .single()
        if (error) throw error

        // Ensures Date type even if it arrived in a string form
        const syncDate = new Date(data.updated_at)
        if (isNaN(syncDate.getTime())) throw new Error('invalid date')

        setLastSyncDate(syncDate)
        setCalendarID(data.calendar)
      } catch (e) {
        console.error('Failed to fetch last sync timestamp', e)
        toast({
          isClosable: true,
          title: 'Błąd pobierania daty ostatniej synchronizacji',
          variant: 'error',
        })
      }
    })()
  }, [toast])

  const handleClick = React.useCallback(() => {
    try {
      const url = generateGoogleAuthURL(true)
      window.location.href = url
    } catch (e) {
      console.error('Failed to generate auth URL', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Niepoprawny URL',
      })
    }
  }, [toast])

  return (
    <Center flexDirection="column" py={8}>
      <Text py={4} fontWeight="bold">
        Kalendarz Google
      </Text>
      <Button onClick={handleClick}>Połącz z Kalendarzem Google</Button>
      <Stack py={3} align="center">
        <Divider />
        <Text>Ostatnia synchronizacja:</Text>
        <Text fontWeight={500}>{lastSyncDate ? formatTimestamp(lastSyncDate) : 'Brak'}</Text>
        {calendarID && (
          <>
            <Divider />
            <Text>ID połączonego kalendarza:</Text>
            <Text fontWeight={500}>{calendarID}</Text>
          </>
        )}
      </Stack>
    </Center>
  )
}

export default GoogleAdminCalendarAuth
