import * as React from 'react'

import {AspectRatio, Button, Container, Flex, Image, Spinner, Stack, Text, useToast} from '@chakra-ui/react'
import {Link, generatePath, useHistory, useParams} from 'react-router-dom'

import {supabase} from '@/api'
import {MEETING_ACCESS_GRANTED, MEETING_INVITE_PENDING, MEETING_LOGIN_REQUIRED} from '@/api/status'
import {selectUser, setModalState} from '@/auth/state'
import {ArrowButton} from '@/common/arrow-button'
import NotFoundView from '@/common/not-found-view'
import useLoadingState from '@/common/use-loading-state'
import {HOME, MEETING} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatEventTime} from '@/utils/string'

import {MEETING_ACCESS_DENIED} from '../errors'
import {MeetingAccessResponse} from '../view/types'

type InvitationParams = {id: string}

const InvitationView = () => {
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const {id} = useParams<InvitationParams>()

  const toast = useToast()
  const history = useHistory()

  const signIn = React.useCallback(() => {
    dispatch(
      setModalState({
        redirectPath: window.location.pathname,
        state: 'signin',
      })
    )
  }, [dispatch])

  const [accessResponse, setAccessResponse] = React.useState<MeetingAccessResponse>()
  const [loading, setLoading] = React.useState<boolean>(true)
  const loadMeetingAccess = React.useCallback(async () => {
    if (!id) {
      return
    }
    setLoading(true)
    try {
      const {data: accessData, error: accessErr} = await supabase.rpc('get_meeting_invitation_details', {
        invitation_id: id,
      })
      console.log(accessData)
      if (accessErr) {
        throw accessErr
      }
      if (!accessData) {
        throw new Error('Failed to fetch access data')
      }
      setAccessResponse(accessData as any)
    } catch (e) {
      console.error('Failed to fetch meeting access response', e)
      toast({
        isClosable: true,
        title: 'Nie udało się wczytać informacji o spotkaniu',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [id, toast])
  React.useEffect(() => {
    loadMeetingAccess()
  }, [loadMeetingAccess, user])

  const _handleJoinMeeting = React.useCallback(async () => {
    if (!id) {
      return
    }
    try {
      const {data, error} = await supabase.rpc('accept_meeting_invitation', {
        invitation_id: id,
      })
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('Failed to accept the invitation')
      }
      history.replace(generatePath(MEETING, {id: (data as any).meeting_id}))
    } catch (e) {
      console.error('Failed to join meeting', e)
      toast({
        isClosable: true,
        title: 'Nie udało się dołączyć do spotkania',
        variant: 'error',
      })
    }
  }, [id, toast, history])
  const {loading: joinLoading, handleSubmit: handleJoinMeeting} = useLoadingState(_handleJoinMeeting)

  if (loading) {
    return (
      <Stack direction="column" my={16} alignItems="center" justifyContent="center">
        <Spinner size="xl" />
      </Stack>
    )
  }

  if (!accessResponse) return <NotFoundView />

  if (accessResponse?.status_code === MEETING_ACCESS_DENIED) {
    return (
      <Container maxW="container.xl" my={8}>
        <Flex alignItems="center" justifyContent="center">
          <Text fontSize="2xl">Nie masz dostępu do tego zaproszenia</Text>
        </Flex>
      </Container>
    )
  }

  return (
    <Container maxW="container.xl" my={8}>
      <Stack direction="column" spacing={8} alignItems="center">
        <Text fontSize="2xl" fontWeight={700}>
          Zaproszenie do spotkania
        </Text>
        <Stack direction="row" spacing={8} alignItems="center">
          <AspectRatio ratio={1} w="160px">
            <Image
              src={accessResponse?.therapist_avatar ?? ''}
              rounded="full"
              w="100%"
              h="100%"
              objectFit="cover"
            />
          </AspectRatio>
          <Stack direction="column" spacing={4} py={2}>
            <Text fontSize="xl">{accessResponse?.therapist_name}</Text>
            <Stack direction="row" spacing={2}>
              <Text fontWeight={500}>{accessResponse?.service_name}</Text>
              <Text>{formatEventTime(accessResponse?.starts_at)}</Text>
            </Stack>
          </Stack>
        </Stack>
        {accessResponse?.status_code === MEETING_ACCESS_GRANTED && (
          <Flex alignItems="center" justifyContent="center">
            <ArrowButton
              as={Link}
              to={generatePath(MEETING, {
                id: accessResponse?.meeting_id || '#',
              })}
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={37}
              fontSize="lg"
              fontWeight={500}
            >
              Jesteś już członkiem tego spotkania, dołącz do niego
            </ArrowButton>
          </Flex>
        )}
        {accessResponse?.status_code === MEETING_LOGIN_REQUIRED && (
          <Flex alignItems="center" justifyContent="center">
            <ArrowButton
              onClick={signIn}
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={37}
              fontSize="lg"
              fontWeight={500}
            >
              Zaloguj się, aby zaakceptować zaproszenie
            </ArrowButton>
          </Flex>
        )}
        {accessResponse?.status_code === MEETING_INVITE_PENDING && (
          <Stack direction="row" spacing={8} alignItems="center">
            <Button as={Link} to={HOME} variant="brandLink">
              Przejdź do strony głównej
            </Button>
            <ArrowButton
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={37}
              fontSize="lg"
              fontWeight={500}
              isDisabled={joinLoading}
              isLoading={joinLoading}
              onClick={handleJoinMeeting}
            >
              Dołącz do spotkania
            </ArrowButton>
          </Stack>
        )}
      </Stack>
    </Container>
  )

  /*
    return (
      <Box p={2}>
        {accessResponse?.error_code && accessResponse.error_code === MEETING_ACCESS_DENIED ? (
          <Center py={8}>
            <Text fontSize="xl">Nie masz dostępu do tego spotkania</Text>
          </Center>
        ) : (
          <Center direction="row" justify="space-between" mb={6}>
            <VStack>
              <Heading fontWeight={600}>{accessResponse?.title}</Heading>
              <Heading fontWeight={600}>{`$Spotkanie z ${accessResponse?.names.join(', ')}`}</Heading>
            </VStack>
          </Center>
        )}
        <Center flexDirection="column">
          {accessResponse?.status_code === MEETING_INVITE_PENDING ? (
            <Stack py={4}>
              <VStack>
                <Button isDisabled={joinLoading} onClick={handleMeetingJoin}>
                  Dołącz do spotkania
                </Button>
                <Button isDisabled={ignoreLoading} onClick={handleMeetingIgnore}>
                  Zignoruj zaproszenie
                </Button>
              </VStack>
            </Stack>
          ) : accessResponse?.status_code === MEETING_LOGIN_REQUIRED ? (
            <Stack py={8}>
              <Text>Musisz być zalogowany żeby dołączyć do spotkania</Text>
              <Flex justify="center" py={2}>
                <SignInForm redirectURL={redirectToInvitationURL} />
              </Flex>
            </Stack>
          ) : accessResponse?.status_code === MEETING_ACCESS_GRANTED ? (
            <Stack>
              <Text>Już jesteś uczestnikiem tego spotkania</Text>
            </Stack>
          ) : (
            <Stack>
              <Text>Nieznany błąd</Text>
            </Stack>
          )}
        </Center>
      </Box>
    )
  */
}

export default InvitationView
