import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const CalendarIcon = (props: IconProps) => (
  <Icon viewBox="0 0 60 60" {...props} fill="none">
    <path
      d="M57.6 5.4H45V0.6C45 0.27 44.73 0 44.4 0H40.2C39.87 0 39.6 0.27 39.6 0.6V5.4H20.4V0.6C20.4 0.27 20.13 0 19.8 0H15.6C15.27 0 15 0.27 15 0.6V5.4H2.4C1.0725 5.4 0 6.4725 0 7.8V57.6C0 58.9275 1.0725 60 2.4 60H57.6C58.9275 60 60 58.9275 60 57.6V7.8C60 6.4725 58.9275 5.4 57.6 5.4ZM54.6 54.6H5.4V26.1H54.6V54.6ZM5.4 21V10.8H15V14.4C15 14.73 15.27 15 15.6 15H19.8C20.13 15 20.4 14.73 20.4 14.4V10.8H39.6V14.4C39.6 14.73 39.87 15 40.2 15H44.4C44.73 15 45 14.73 45 14.4V10.8H54.6V21H5.4Z"
      fill="#151515"
    />
  </Icon>
)

export default CalendarIcon
