import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const BookletIcon = (props: IconProps) => (
  <Icon viewBox="0 0 60 60" {...props} fill="none">
    <path
      d="M48.75 3.75H11.25C10.2129 3.75 9.375 4.58789 9.375 5.625V54.375C9.375 55.4121 10.2129 56.25 11.25 56.25H48.75C49.7871 56.25 50.625 55.4121 50.625 54.375V5.625C50.625 4.58789 49.7871 3.75 48.75 3.75ZM33.5156 7.96875H39.1406V20.2676L36.416 18.2812L33.5156 20.3555V7.96875ZM46.4062 52.0312H13.5938V7.96875H30V25.3652C30 25.5586 30.0586 25.752 30.1758 25.9102C30.2469 26.0107 30.3373 26.0962 30.4417 26.1616C30.546 26.227 30.6623 26.271 30.7839 26.2912C30.9054 26.3114 31.0297 26.3072 31.1496 26.279C31.2695 26.2508 31.3826 26.1992 31.4824 26.127L36.3926 22.6172L41.1621 26.0977C41.3203 26.2148 41.5137 26.2793 41.7129 26.2793C42.2285 26.2793 42.6504 25.8574 42.6504 25.3418V7.96875H46.4004V52.0312H46.4062Z"
      fill="currentColor"
    />
  </Icon>
)

export default BookletIcon
