import * as React from 'react'

import {Button, HStack, Stack, Text} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'

type Props = {
  price?: number
  discountedPrice?: number | null
  isDisabled?: boolean
  isLoading?: boolean
  onNextStep: () => void
  nextStepLabel?: string
  onPrevStep?: () => void
  prevStepLabel?: string
}

const OrderModalButtons = ({
  price,
  discountedPrice,
  isDisabled,
  isLoading,
  onNextStep,
  nextStepLabel,
  onPrevStep,
  prevStepLabel = 'Anuluj',
}: Props) => (
  <Stack
    direction={['column-reverse', null, 'row']}
    justify="flex-end"
    align={['center', null, 'flex-end']}
    spacing={[4, null, 8]}
  >
    {onPrevStep && (
      <Button
        variant="link"
        color="brand.yellow.600"
        _hover={{
          color: 'brand.yellow.700',
        }}
        fontSize="lg"
        p={2}
        height={55}
        fontWeight={500}
        onClick={onPrevStep}
        isDisabled={isLoading}
        borderBottom="2px solid"
        borderColor="brand.yellow.600"
        borderRadius={0}
      >
        {prevStepLabel}
      </Button>
    )}
    <Stack align="center" spacing={4}>
      {!!price && discountedPrice !== 0 && (
        <HStack spacing={1} fontWeight={500}>
          <Text>Do zapłaty:</Text>
          <Text textDecoration={discountedPrice ? 'line-through' : undefined}>
            {(price / 100).toFixed(2)}zł
          </Text>
          {discountedPrice && <Text>{discountedPrice.toFixed(2)}zł</Text>}
        </HStack>
      )}
      <ArrowButton
        height={55}
        fontWeight={500}
        onClick={onNextStep}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        <Text px={[4, null, 8]} fontSize="lg">
          {nextStepLabel || (price && discountedPrice !== 0 ? 'Przejdź do płatności' : 'Zapisz się')}
        </Text>
      </ArrowButton>
    </Stack>
  </Stack>
)

export default OrderModalButtons
