import * as React from 'react'

// executes the given function after getting back online unless `unless` is true
const useRunOnReconnect = (fn: () => void, unless?: boolean) => {
  React.useEffect(() => {
    !unless && window.addEventListener('online', fn)
    return () => {
      window.removeEventListener('online', fn)
    }
  }, [fn, unless])
}

export default useRunOnReconnect
