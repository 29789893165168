import React from 'react'

import {
  AspectRatio,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Image,
  Link,
  Spacer,
  Stack,
  Text,
} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {MdClose} from 'react-icons/md'
import {Link as RouterLink, generatePath, useHistory, useLocation, useParams} from 'react-router-dom'

import {PublicCourseWithDetails} from '@/api/models'
import {selectProfile} from '@/auth/state'
import {ArrowButton} from '@/common/arrow-button'
import ShowcaseDescription from '@/common/components/product-showcase/description'
import ShowcaseDetailsBanner, {DetailItemProps} from '@/common/components/product-showcase/details-banner'
import ScreenWideBar from '@/common/components/screen-wide-bar'
import ContentEditor from '@/common/content-editor'
import {BookletIcon, CheckmarkIcon, ClockIcon, DownloadIcon, ListIcon, UserIcon} from '@/common/icons'
import LoadingView from '@/common/loading-view'
import NotFoundView from '@/common/not-found-view'
import StarRating from '@/common/reviews/star-rating'
import useFetchSingle from '@/common/use-fetch-single'
import useMetaTags from '@/common/use-meta-tags'
import {setOrderModel} from '@/courses/state'
import {startCourseMeetingOrder} from '@/psychotherapy/therapists/state'
import {COURSES, COURSE_SHOWCASE, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatTimestamp} from '@/utils/string'

import CoursesCarousel from './carousel'
import OrderButton from './order-button'
import CourseReviews from './reviews'

type CourseShowcaseParams = {id: string; slug?: string}

const CourseShowcase = () => {
  const profile = useAppSelector(selectProfile)
  const dispatch = useAppDispatch()

  const {id, slug} = useParams<CourseShowcaseParams>()
  const {search} = useLocation()
  const history = useHistory()

  const {data: course, loading} = useFetchSingle<PublicCourseWithDetails>(
    React.useMemo(
      () => ({
        table: 'public_courses_with_details',
        fields: '*',
        match: {id: +id},
        errSnackbarTitle: 'Nie udało się wczytać warsztatu',
      }),
      [id]
    )
  )

  useMetaTags({
    title: course?.name,
    description: course?.meta_description,
    'og:image': course?.signed_logo,
  })

  React.useEffect(() => {
    if (course?.name_slug && course?.name_slug !== slug) {
      history.replace(generatePath(COURSE_SHOWCASE, {id, slug: course?.name_slug}) + search)
    }
  }, [course, slug]) // eslint-disable-line

  React.useEffect(() => {
    if (!course) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `course:${course.id}`,
              item_name: `Warsztat - ${course.name}`,
              value: (course.base_price || 0) / 100,
            },
          ],
          value: (course.base_price || 0) / 100,
        },
        event: 'view_item',
      },
    })
  }, [course])

  const openCourseOrderModal = React.useCallback(() => {
    course && dispatch(setOrderModel(course))
  }, [dispatch, course])
  React.useEffect(() => {
    if (!profile || !course || !!course.roles?.length) {
      return
    }
    const query = new URLSearchParams(search)
    if (query.get('order') === 'true') {
      console.log('open modal')
      openCourseOrderModal()
    }
  }, [profile, search, course, openCourseOrderModal])

  const handlePlanMeeting = React.useCallback(() => {
    if (!course?.id || !course?.coach) return
    dispatch(startCourseMeetingOrder(course.id))
  }, [course, dispatch])

  const bookletLabel = React.useMemo(() => {
    if (!course?.booklet_path?.length) return ''

    const pathParts = course?.booklet_path.split('/')
    return pathParts[pathParts.length - 1].replace('.pdf', '')
  }, [course?.booklet_path])

  const detailsItems: DetailItemProps[] = React.useMemo(
    () =>
      course
        ? [
            {
              icon: ClockIcon,
              title: 'Czas trwania',
              children: (
                <Text>
                  Trenerzy rekomendują tempo wykonywania warsztatu, jednak Ty decydujesz, ile poświęcisz mu
                  czasu i zaangażowania.
                </Text>
              ),
            },
            {
              icon: ListIcon,
              title: 'Liczba segmentów',
              children: <Text>{course.segments ?? 0}</Text>,
            },
            ...(!course.signed_booklet_url
              ? []
              : [
                  {
                    icon: BookletIcon,
                    title: 'Broszura',
                    children: (
                      <Button
                        as={Link}
                        target="_blank"
                        href={course.signed_booklet_url}
                        fontWeight={300}
                        variant="link"
                        color="brand.yellow.600"
                        _hover={{color: 'brand.yellow.700', textDecoration: 'underline'}}
                      >
                        <Text whiteSpace="break-spaces" textAlign="center">
                          {bookletLabel}
                          <DownloadIcon color="brand.yellow.600" ml={2} />
                        </Text>
                      </Button>
                    ),
                  },
                ]),
            ...(!course.coaches?.length
              ? []
              : [
                  {
                    icon: UserIcon,
                    title: 'Certyfikowani trenerzy',
                    children: (
                      <Stack>
                        {course.coaches?.map((c, i) => (
                          <Button
                            key={i}
                            as={RouterLink}
                            to={generatePath(PSYCHOTHERAPY_THERAPIST, {id: c.therapist_id})}
                            fontWeight={300}
                            variant="link"
                            color="brand.yellow.600"
                            _hover={{color: 'brand.yellow.700', textDecoration: 'underline'}}
                          >
                            {c.full_name}
                          </Button>
                        ))}
                      </Stack>
                    ),
                  },
                ]),
          ]
        : [],
    [course, bookletLabel]
  )

  if (loading) return <LoadingView />

  if (!course?.id) return <NotFoundView />

  return (
    <Container
      maxW="container.xl"
      as={Stack}
      spacing={[8, null, 24]}
      mt={[8, null, 16]}
      mb={[8, null, 24]}
      px={[8, null, 4]}
    >
      <Stack spacing={[8, null, 16]}>
        <Breadcrumb fontWeight={300}>
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to={COURSES}>
              Warsztaty
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
            <BreadcrumbLink as={RouterLink} to={generatePath(COURSE_SHOWCASE, {id, slug})}>
              {course.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>

        <Grid templateColumns="repeat(12, 1fr)">
          <GridItem colSpan={[12, null, 5]} px={[4, null, 0]}>
            <AspectRatio ratio={1} rounded="0 60px" overflow="hidden">
              {course.signed_logo ? (
                <Image src={course.signed_logo} objectFit="cover" />
              ) : (
                <Flex objectFit="cover" bg="gray.600" alignItems="center" justifyContent="center">
                  <MdClose size={64} />
                </Flex>
              )}
            </AspectRatio>
          </GridItem>
          <GridItem
            as={Stack}
            direction="column"
            colStart={[0, null, 7]}
            colSpan={[12, null, 6]}
            py={[8, null, 4]}
            spacing={8}
          >
            <ScreenWideBar py={[8, null, 12]} bg="brand.yellow.300">
              <Stack spacing={1}>
                {course.release_at && (
                  <Text size="lg" mb={[4, null, 0]}>
                    {`Dostępne od ${formatTimestamp(course.release_at)}`}
                  </Text>
                )}
                <Heading as="h1" size="lg" mb={[4, null, 0]}>
                  {course.name}
                </Heading>
              </Stack>
            </ScreenWideBar>
            {/* price + rating */}
            <Stack
              mb={4}
              spacing={4}
              direction={['column', 'row', 'column', 'row']}
              justify="space-between"
              align={['flex-start', 'center', 'flex-start', 'center']}
            >
              <Stack spacing={1}>
                {!!course.rating && (
                  <HStack>
                    <Text>ocena: {course.rating.toFixed(2)}</Text>
                    <StarRating value={course.rating} />
                  </HStack>
                )}
                <HStack spacing={1} fontSize="xl">
                  <Text>Cena:</Text>
                  {course.base_price ? <Text>{`${course.base_price / 100} zł`}</Text> : <Text>Za darmo</Text>}
                </HStack>
                {!!course.meetings_count && (
                  <Text>
                    {`z ${course.meetings_count} ${
                      course.meetings_count === 1 ? 'spotkaniem' : 'spotkaniami'
                    } z trenerem: ${
                      course.price_with_meetings ? `${course.price_with_meetings / 100} zł` : 'Za darmo'
                    }`}
                  </Text>
                )}
              </Stack>
              <OrderButton course={course} />
            </Stack>
            <Spacer />
            <ShowcaseDescription
              id="course-showcase"
              shortDescription={course.short_description}
              fullDescription={course.full_description}
            />
          </GridItem>
        </Grid>
      </Stack>

      <ScreenWideBar py={10} bg="brand.yellow.300" justify="center">
        <Stack direction={['column', null, 'row']} spacing={[4, null, 16]} align="center">
          <Text as="h2" fontSize="2xl" textAlign={['center', null, 'left']}>
            {course.roles?.length ? 'Już masz dostęp do tego warsztatu!' : 'Odważ się żyć lepiej!'}
          </Text>
          {!!course.meetings_left && (
            <ArrowButton
              bg="brand.green.600"
              hoverBg="brand.green.700"
              color="white"
              height={40}
              fontSize="lg"
              fontWeight={500}
              onClick={handlePlanMeeting}
            >
              Umów się na spotkanie
            </ArrowButton>
          )}
          <OrderButton course={course} height={58} width={80} />
        </Stack>
      </ScreenWideBar>

      <ShowcaseDetailsBanner items={detailsItems} bg="brand.green.800" color="white" />

      <Stack spacing={[8, null, 16]}>
        <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} color="brand.green.800">
          Co zyskasz dzięki warsztatom?
        </Text>
        <Grid
          gap={[8, null, 4]}
          templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', null, 'repeat(4, 1fr)']}
        >
          {course.benefits.map((item, i) => (
            <GridItem key={i} as={Flex} px={[0, null, 5]} justify={['flex-start', null, 'center']}>
              <Stack direction="row" spacing={4} align="center">
                <CheckmarkIcon boxSize="57px" color="brand.yellow.500" />
                <Text fontWeight={700}>{item}</Text>
              </Stack>
            </GridItem>
          ))}
        </Grid>
      </Stack>

      <Stack spacing={[8, null, 16]}>
        <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} color="brand.green.800">
          Tego się nauczysz
        </Text>
        <Grid
          templateColumns={['repeat(1, 1fr)', null, 'repeat(4, 1fr)']}
          gap={[8, null, 4]}
          textAlign="center"
        >
          {course.covered_topics?.map((item, i) => (
            <Box key={i} w="100%" px="5">
              <ContentEditor
                id={`course-showcase-covered-topic-${i}`}
                variant="basic"
                readOnly={true}
                value={item}
              />
            </Box>
          ))}
        </Grid>
      </Stack>

      <CourseReviews course={+id} />

      <ScreenWideBar py={10} bg="brand.green.600" justify="center">
        <Stack direction={['column', null, 'row']} align="center" spacing={[8, null, 16]}>
          <Text as="h2" fontSize="2xl" color="white">
            {course.roles?.length ? 'Już masz dostęp do tego warsztatu!' : 'Sięgnij po lepsze życie!'}
          </Text>
          <OrderButton course={course} height={58} width={80} />
        </Stack>
      </ScreenWideBar>

      <Stack spacing={[8, null, 16]}>
        <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} color="brand.green.800">
          Zobacz także
        </Text>
        <CoursesCarousel course={+id} />
      </Stack>
    </Container>
  )
}

export default CourseShowcase
