import React from 'react'

import {
  Button,
  Checkbox,
  Flex,
  FlexProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import isEmail from 'validator/lib/isEmail'

import {supabase} from '@/api'
import {ArrowButton} from '@/common/arrow-button'
import useLoadingState from '@/common/use-loading-state'
import {TERMS_AND_CONDITIONS} from '@/router/paths'

import {minPasswordLength} from '../constants'
import Social from '../social'

type Props = {
  switchToSignIn?: () => void
  switchToConfirmEmail: () => void
  onClose: () => void
} & FlexProps

const SignUpForm = ({switchToSignIn, switchToConfirmEmail, onClose, ...rest}: Props) => {
  const toast = useToast()

  const [fullName, setFullName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [repeatPassword, setRepeatPassword] = React.useState('')
  const [agreement, setAgreement] = React.useState(false)
  const handleFullNameChange = React.useCallback(({target: {value}}) => setFullName(value), [setFullName])
  const handleEmailChange = React.useCallback(({target: {value}}) => setEmail(value), [setEmail])
  const handlePasswordChange = React.useCallback(({target: {value}}) => setPassword(value), [setPassword])
  const handleRepeatPasswordChange = React.useCallback(
    ({target: {value}}) => setRepeatPassword(value),
    [setRepeatPassword]
  )
  const handleAgreementChange = React.useCallback(
    ({target: {checked}}) => setAgreement(checked),
    [setAgreement]
  )

  const _handleSubmit = React.useCallback(async () => {
    if (!email || !password) return

    try {
      const session = await supabase.auth.signUp({
        email,
        options: {
          data: {
            full_name: fullName,
          },
        },
        password,
      })
      if (session.error) throw new Error(session.error.message)

      toast({isClosable: true, status: 'success', title: 'Konto utworzone'})
      switchToConfirmEmail()
    } catch (e) {
      console.error('Failed to sign up', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nastąpił błąd przy tworzeniu konta',
      })
    }
  }, [fullName, email, password, switchToConfirmEmail, toast])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)
  const handleFormSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      handleSubmit()
    },
    [handleSubmit]
  )

  const isEmailValid = React.useMemo(() => !email || isEmail(email), [email])
  const isPasswordValid = React.useMemo(() => !password || password.length >= minPasswordLength, [password])
  const isRepeatPasswordValid = React.useMemo(
    () => !repeatPassword || password === repeatPassword,
    [password, repeatPassword]
  )

  const isSubmitDisabled = React.useMemo(
    () =>
      !fullName ||
      !email ||
      !isEmailValid ||
      !password ||
      !isPasswordValid ||
      !repeatPassword ||
      !isRepeatPasswordValid ||
      !agreement,
    [
      fullName,
      email,
      isEmailValid,
      password,
      isPasswordValid,
      repeatPassword,
      isRepeatPasswordValid,
      agreement,
    ]
  )

  return (
    <Flex
      as="form"
      direction="column"
      alignItems="center"
      justifyContent="center"
      onSubmit={handleFormSubmit}
      {...rest}
    >
      <Text fontSize="lg" fontWeight={700} mb={8}>
        Zarejestruj się
      </Text>
      <Grid
        templateColumns={['repeat(1, 1fr)', null, 'repeat(11, 1fr)']}
        columnGap={[0, null, 16]}
        width="100%"
        mb={10}
      >
        <GridItem colSpan={[1, null, 6]}>
          <FormControl id="fullName" isDisabled={loading} mb={8}>
            <FormLabel fontSize="sm" fontWeight={300} mb={2}>
              Imię i nazwisko
            </FormLabel>
            <Input
              placeholder="Anna Kowalska"
              size="lg"
              autoFocus={true}
              onChange={handleFullNameChange}
              value={fullName}
            />
          </FormControl>
          <FormControl
            id="email"
            isDisabled={loading}
            mb={!!email && !isEmailValid ? 0 : '32px'}
            isInvalid={!!email && !isEmailValid}
          >
            <FormLabel fontSize="sm" fontWeight={300} mb={2}>
              Adres e-mail
            </FormLabel>
            <Input
              type="email"
              placeholder="przyklad@email.com"
              size="lg"
              onChange={handleEmailChange}
              value={email}
            />
            <FormErrorMessage height="24px">Nieprawidłowy adres e-mail</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={[1, null, 5]}>
          <FormControl
            id="password"
            isDisabled={loading}
            mb={!!password && !isPasswordValid ? 0 : '32px'}
            isInvalid={!!password && !isPasswordValid}
          >
            <FormLabel fontSize="sm" fontWeight={300} mb={2}>
              Hasło
            </FormLabel>
            <Input
              type="password"
              placeholder="Hasło"
              size="lg"
              onChange={handlePasswordChange}
              value={password}
            />
            <FormErrorMessage height="24px">
              Hasło musi mieć przynajmniej {minPasswordLength} znaków.
            </FormErrorMessage>
          </FormControl>
          <FormControl
            id="repeatPassword"
            isDisabled={loading}
            mb={!isRepeatPasswordValid ? 0 : '32px'}
            isInvalid={!isRepeatPasswordValid}
          >
            <FormLabel fontSize="sm" fontWeight={300} mb={2}>
              Powtórz hasło
            </FormLabel>
            <Input
              type="password"
              placeholder="Hasło"
              size="lg"
              onChange={handleRepeatPasswordChange}
              value={repeatPassword}
              isInvalid={!isRepeatPasswordValid}
            />
            <FormErrorMessage height="24px">Hasła różnią się od siebie.</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem as={Stack} spacing={2} colSpan={[1, null, 11]}>
          <Stack spacing={4} direction="row">
            <Checkbox
              size="lg"
              spacing={4}
              isRequired={true}
              onChange={handleAgreementChange}
              isChecked={agreement}
            >
              <Text
                direction="row"
                spacing={0}
                alignItems="center"
                fontSize="sm"
                fontWeight={300}
                flexWrap="wrap"
              >
                <Text as="span" lineHeight={8} pr={1}>
                  Oświadczam, że znam i akceptuję postanowienia
                </Text>
                <Button
                  as={Link}
                  to={TERMS_AND_CONDITIONS}
                  onClick={onClose}
                  height={8}
                  variant="brandLink"
                  size="sm"
                >
                  Regulaminu Psychoformy
                </Button>
                <Text as="span" lineHeight={8}>
                  .
                </Text>
              </Text>
            </Checkbox>
          </Stack>
          {/*
            <Stack spacing={4} direction="row">
              <Checkbox as="div" size="lg" spacing={4}>
                <Text fontSize="sm" fontWeight={300}>
                  Chcę otrzymywać: kody zniżkowe, oferty specjalne lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i towarach dostępnych w serwisie, za pośrednictwem komunikacji elektronicznej. W każdej chwili możesz wycofać udzieloną zgodę.
                </Text>
              </Checkbox>
            </Stack>
          */}
        </GridItem>
      </Grid>
      <Stack direction="row" alignItems="center" justifyContent="center" width="100%" mb={8}>
        <ArrowButton
          type="submit"
          bg="brand.yellow.600"
          hoverBg="brand.yellow.700"
          color="white"
          height={40}
          fontSize="lg"
          fontWeight={500}
          isLoading={loading}
          isDisabled={isSubmitDisabled}
        >
          <Text px={8} fontSize="lg">
            Zarejestruj się
          </Text>
        </ArrowButton>
      </Stack>
      <Social mb={switchToSignIn ? 16 : 0} maxW="container.sm" />
      {switchToSignIn && (
        <Stack direction="row" spacing={2} justifyContent="center" width="100%">
          <Text fontSize="lg">Posiadasz konto?</Text>
          <Button variant="brandLink" fontSize="lg" fontWeight={700} onClick={switchToSignIn}>
            Zaloguj się
          </Button>
        </Stack>
      )}
    </Flex>
  )
}

export default SignUpForm
