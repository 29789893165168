import * as React from 'react'

import {Container} from '@chakra-ui/react'

import {PerTherapistReview} from '@/api/models'
import Reviews from '@/common/reviews'
import useSupabaseQuery from '@/common/use-custom-query'

const MAX_REVIEWS_COUNT = 4

type Props = {
  therapist: number
}

const TherapistReviews = ({therapist}: Props) => {
  const {loading, data} = useSupabaseQuery<PerTherapistReview>(
    React.useMemo(
      () => ({
        fields: '*',
        itemsPerPage: MAX_REVIEWS_COUNT,
        match: {therapist},
        order: 'featured_index',
        table: 'therapist_reviews_view',
        errSnackbarTitle: 'Nie udało się wczytać recenzji terapeuty',
      }),
      [therapist]
    )
  )

  const reviews = React.useMemo(
    () =>
      data?.map((v) => ({
        author: v.author,
        content: v.content,
        rating: v.rating,
      })) || [],
    [data]
  )

  return !reviews.length && !loading ? null : (
    <Container mb={16}>
      <Reviews reviews={reviews} loading={loading} />
    </Container>
  )
}

export default TherapistReviews
