import * as React from 'react'

import {FlexProps, Box, Flex, useDimensions} from '@chakra-ui/react'

// Actually as wide as the nearest position="relative" parent
const ScreenWideBar = ({children, ...rest}: FlexProps & {children: React.ReactNode}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const dimensions = useDimensions(ref, true)

  return (
    <Flex ref={ref} align="center" {...rest}>
      <Box
        position="absolute"
        left="0"
        width="100%"
        top="auto"
        height={dimensions?.borderBox.height}
        bg={rest.bg}
        zIndex={-5}
      />
      {children}
    </Flex>
  )
}

export default ScreenWideBar
