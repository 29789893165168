import * as React from 'react'

import {Box, Container, Flex, Link, Stack, Text} from '@chakra-ui/react'
import {Link as RouterLink} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import Header from '@/common/components/header'
import Section from '@/common/components/section'
import Newsletter from '@/home/newsletter'
import {COURSES, PSYCHOTHERAPISTS, PSYCHOTHERAPY} from '@/router/paths'

import headerImage from './header.jpg'
import section1Image from './section1.jpg'
import section2Image from './section2.jpg'
import section3Image from './section3.jpg'
import section4Image from './section4.jpg'

const AboutUs = () => {
  return (
    <>
      <Stack w="100%" align="center" spacing={20} mb={[0, null, 20]}>
        <Header img={headerImage} title="O nas" />
        <Container as={Stack} maxW="container.xl" px={[8, null, 0]} spacing={[8, null, 24]}>
          {/* First section */}
          <Section title="Kim jesteśmy?" img={section1Image}>
            <Text fontSize="md" fontWeight={300}>
              Psychoforma to{' '}
              <Text as="span" fontWeight={500}>
                platforma wsparcia psychologicznego online
              </Text>
              . Miejsce, w którym każdy kto potrzebuje rozmowy, wsparcia lub motywacji – bez względu na czas i
              miejsce – znajdzie komfortową przestrzeń do rozwoju osobistego oraz doskonalenia w różnych
              obszarach życia.
            </Text>
            <Text fontSize="md" fontWeight={300}>
              W ramach platformy online dostępne są dwie metody samorozwoju:{' '}
              <Link as={RouterLink} to={COURSES} fontWeight={500} color="brand.green.600">
                warsztaty
              </Link>{' '}
              i{' '}
              <Link as={RouterLink} to={PSYCHOTHERAPY} fontWeight={500} color="brand.green.600">
                psychoterapia
              </Link>
              . Możesz wybrać jedną z nich lub łączyć je, rozwijając się i poprawiając jakość swojego życia na
              kilku płaszczyznach równocześnie. Psychoterapia pozwalaja sięgnąć do źródeł lepszego życia,
              czyli naszego własnego wnętrza, które tylko czeka, by ktoś pomógł mu rozkwitnąć.
            </Text>
          </Section>

          {/* Second section */}
          <Section img={section2Image} flip={true}>
            <Text fontSize="md" fontWeight={300}>
              Platforma Psychoforma jest{' '}
              <Text as="span" fontWeight={500}>
                efektem współpracy doświadczonych psychoterapeutów
              </Text>
              , których celem jest zapewnienie możliwości rozwoju osobistego, wsparcia i pomocy
              psychoterapeutycznej osobom, które tego potrzebują, tu i teraz. Aby sięgnąć po pomoc, wystarczy
              dostęp do internetu.{' '}
              <Text as="span" fontWeight={500}>
                Odważ się żyć lepiej!
              </Text>
            </Text>
          </Section>
        </Container>

        <Box bg="brand.green.600" w="100%" py={[16, null, 10]}>
          <Container maxW="container.xl" as={Flex} direction="row" justifyContent="center">
            <Stack direction={['column', null, 'row']} spacing={[8, null, 16]} alignItems="center">
              <Text as="h2" fontSize="2xl" color="white">
                Dowiedz się więcej.
              </Text>
              <ArrowButton
                as={RouterLink}
                to={PSYCHOTHERAPISTS}
                bg="brand.yellow.600"
                hoverBg="brand.yellow.700"
                color="white"
                height={58}
                fontSize="lg"
                fontWeight={500}
              >
                Poznaj nasz zespół
              </ArrowButton>
            </Stack>
          </Container>
        </Box>

        <Container maxW="container.xl">
          <Stack spacing={[8, null, 24]}>
            {/* Third section */}
            <Section title="Nasza wizja" img={section3Image}>
              <Text fontSize="md" fontWeight={300}>
                Psychoforma to platforma dla osób, które gotowe są wziąć część odpowiedzialności za własny
                rozwój, poprawę samopoczucia i samooceny oraz rozwiązanie swoich problemów.{' '}
                <Text fontWeight={500}>My zapewniamy narzędzia</Text> online oraz wsparcie doświadczonych
                psychoterapeutów, jednak{' '}
                <Text fontWeight={500}>od Ciebie zależy, jak często i kiedy będziesz korzystać</Text> z
                oferowanych Ci możliwości. Terapia online, jaką oferujemy, to najbardziej efektywny rodzaj
                pomocy, ponieważ <Text fontWeight={500}>pod opieką specjalistów poznasz swoją moc</Text>,
                zrozumiesz, że Ty jesteś drogą do zmiany na lepsze. Odważ się żyć pełnią życia!
              </Text>
            </Section>

            {/* Fourth section */}
            <Section title="Nasza misja" img={section4Image} flip={true}>
              <Text fontSize="md" fontWeight={300}>
                Platforma wsparcia psychologicznego online Psychoforma powstała jako{' '}
                <Text as="span" fontWeight={500}>
                  odpowiedź na potrzeby i wyzwania życia we współczesnym, szybko zmieniającym się świecie
                </Text>
                . Epidemia, wojny, pogorszenie się warunków życia w wyniku zmian polityczno-gospodarczych,
                niestabilna sytuacja zawodowa, wypalenie, brak czasu na satysfakcjonujące relacje
                międzyludzkie, samotność – to główne problemy współczesności i powody obniżonego samopoczucia
                wielu osób. Brak czasu nie pozwala nam sięgnąć w odpowiednim momencie po profesjonalną pomoc
                psychologiczną.
              </Text>
              <Text fontSize="md" fontWeight={300}>
                Platforma online ma tę zaletę, że{' '}
                <Text as="span" fontWeight={500}>
                  łatwo, bez wysiłku i bez bariery
                </Text>
                , którą często odczuwasz przed zgłoszeniem się do poradni terapeutycznej, możesz liczyć na
                serdeczną rozmowę oraz profesjonalną pomoc. Terapia online to naturalny rezultat procesu
                przeniesienia usług do internetu, jaki nastąpił w ostatnim czasie w każdej dziedzinie życia.
                <br />
                <Text as="span" fontWeight={500}>
                  Terapia online jest wygodna, dostępna w każdej chwili i z każdego miejsca na świecie
                </Text>
                . To właśnie chcemy Państwu zapewnić.
              </Text>
            </Section>
          </Stack>
        </Container>
      </Stack>
      <Newsletter />
    </>
  )
}

export default AboutUs
