import * as React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  HStack,
  Button,
  Checkbox,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'

import {FrontPageLogo} from '@/api/models'
import ImageUpload from '@/common/image-upload'
import useEditorModalState from '@/common/use-editor-modal'
import {SUPABASE_FRONT_PAGE_LOGOS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptyFrontPageLogo} from '../constants'

const FrontPageLogosEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: FrontPageLogo | null}) => {
  const {input, image, setImage, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<FrontPageLogo>({
      table: 'front_page_logos',
      item,
      emptyInput: emptyFrontPageLogo,
      open,
      onComplete,
      onClose,
      imageParams: {
        bucket: SUPABASE_FRONT_PAGE_LOGOS_BUCKET,
        key: 'image_path',
      },
    })

  const handleOrderChange = React.useCallback(
    (v: string) => {
      handleCustomInputChange({order: +v})
    },
    [handleCustomInputChange]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {item?.id ? 'Edytuj logo na stronie domowej' : 'Dodaj logo na stronie domowej'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading}>
              <FormLabel mb={1} fontWeight={600}>
                Logo
              </FormLabel>
              <ImageUpload width={200} height={200} editing={true} value={image} onChange={setImage} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa</FormLabel>
              <Input name="alt_text" value={input.alt_text ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>URL</FormLabel>
              <Input name="link_url" value={input.link_url ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <Checkbox name="published" isChecked={input.published ?? false} onChange={handleInputChange}>
                Opublikowano
              </Checkbox>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Numer porządkowy</FormLabel>
              <NumberInput onChange={handleOrderChange} precision={0} value={input.order}>
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={false}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default FrontPageLogosEditorModal
