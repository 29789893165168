import * as React from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Image,
  useToast,
} from '@chakra-ui/react'
import ImageUploading, {ImageListType, ImageType} from 'react-images-uploading'
import {useDispatch} from 'react-redux'

import {supabase} from '@/api'
import {UserProfile} from '@/api/models'
import {fetchUserProfile} from '@/auth/state'
import useLoadingState from '@/common/use-loading-state'
import {SUPABASE_AVATARS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

const uploadImagesCount = 1

const AvatarUploadModal = ({onClose, open, user}: CommonModalProps & {user: UserProfile}) => {
  const toast = useToast()

  const [image, setImage] = React.useState<ImageType>()
  const dispatch = useDispatch()

  const onImageChange = React.useCallback(
    (imageList: ImageListType) => {
      imageList.length && setImage(imageList[0])
    },
    [setImage]
  )

  const onImageRemove = React.useCallback(() => {
    setImage(undefined)
  }, [setImage])

  const handleUpload = React.useCallback(async () => {
    if (!image?.file) {
      return
    }
    try {
      const filename = `${Date.now()}.${image.file.name.slice(
        (Math.max(0, image.file.name.lastIndexOf('.')) || Infinity) + 1
      )}`
      const path = `/users/${user.id}/${filename}`
      const {error: uploadError} = await supabase.storage
        .from(SUPABASE_AVATARS_BUCKET)
        .upload(path, image.file, {upsert: true})
      if (uploadError) {
        throw uploadError
      }
      const {error: insertError} = await supabase.rpc('update_user_avatar', {
        path,
      })
      if (insertError) {
        throw insertError
      }
      toast({isClosable: true, status: 'success', title: 'Zdjęcie profilowe przesłane'})
      onClose()
      dispatch(fetchUserProfile())
    } catch (e) {
      console.error('Failed to upload avatar', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Błąd podczas przesyłania zdjęcia profilowego',
      })
    }
  }, [dispatch, user, image, toast, onClose])
  const {loading, handleSubmit} = useLoadingState(handleUpload)

  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Prześlij swoje zdjęcie profilowe</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align="center">
            <ImageUploading
              multiple={false}
              value={React.useMemo(() => (image ? [image] : []), [image])}
              onChange={onImageChange}
              maxNumber={uploadImagesCount}
            >
              {({onImageUpload, isDragging, dragProps}) => (
                <Stack>
                  {image?.dataURL ? (
                    <Stack align="center">
                      <Image src={image.dataURL} boxSize="200" />
                      <HStack>
                        <Button
                          isLoading={loading}
                          colorScheme="red"
                          variant="outline"
                          onClick={onImageRemove}
                        >
                          Cofnij
                        </Button>
                        <Button isLoading={loading} colorScheme="blue" onClick={handleSubmit}>
                          Prześlij
                        </Button>
                      </HStack>
                    </Stack>
                  ) : (
                    <Button
                      colorScheme={isDragging ? 'teal' : 'gray'}
                      onClick={onImageUpload}
                      {...dragProps}
                      height="15vh"
                    >
                      Kliknij, albo upuść obraz
                    </Button>
                  )}
                </Stack>
              )}
            </ImageUploading>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AvatarUploadModal
