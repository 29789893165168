import * as React from 'react'

import {Grid, GridItem, AspectRatio, Stack, Heading, Image, StackProps} from '@chakra-ui/react'

import ScreenWideBar from '../screen-wide-bar'

type Props = StackProps & {
  children?: React.ReactNode
  img?: string
  title?: string
  flip?: boolean
  barColor?: string
  size?: 'sm' | 'lg'
}

const Section = ({children, img, title, flip = false, barColor, size = 'lg', ...rest}: Props) => {
  return (
    <Grid templateColumns="repeat(12, 1fr)" rowGap={[8, null, 24]}>
      <GridItem
        colStart={flip ? [0, null, size === 'sm' ? 9 : 8] : [0, null, 2]}
        colSpan={[12, null, size === 'sm' ? 3 : 4]}
        order={flip ? [0, null, 1] : 0}
        as={Stack}
        justifyContent={barColor ? 'flex-start' : 'center'}
      >
        <AspectRatio
          ratio={1}
          overflow="hidden"
          width="100%"
          borderRadius={flip && size === 'sm' ? '0 75px' : '75px 0'}
        >
          <Image src={img} objectFit="cover" width="100%" height="100%" />
        </AspectRatio>
      </GridItem>
      <GridItem
        mt={barColor ? [0, null, 6] : 0}
        colSpan={[12, null, size === 'sm' ? 6 : 5]}
        colStart={flip ? [0, null, 2] : [0, null, size === 'sm' ? 6 : 7]}
        as={Stack}
        spacing={barColor ? [4, null, 8] : [8, null, 16]}
        justifyContent={barColor ? 'flex-start' : 'center'}
      >
        {title && (
          <ScreenWideBar bg={barColor} py={barColor ? [4, null, 8] : 0}>
            <Heading size="lg" fontWeight={size === 'sm' ? 500 : 700}>
              {title}
            </Heading>
          </ScreenWideBar>
        )}
        <Stack spacing={[6, null, 8]} {...rest}>
          {children}
        </Stack>
      </GridItem>
    </Grid>
  )
}

export default Section
