import * as React from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'
import {CommonModalProps} from '@/utils/types'

import UserBilling from '.'
import useBillingEditorState from './use-billing-editor-state'

type Props = CommonModalProps

const UserBillingModal = ({onClose, open, onComplete}: Props) => {
  const state = useBillingEditorState(
    React.useCallback(() => {
      onClose()
      onComplete && onComplete()
    }, [onComplete, onClose])
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edycja adresów</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <UserBilling mode="edit" controlledState={state} />
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent="space-evenly">
            <ArrowButton
              bg="brand.yellow.600"
              color="white"
              isLoading={state.saving}
              onClick={state.handleSubmit}
              isDisabled={state.isSubmitDisabled}
            >
              Zapisz
            </ArrowButton>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={state.saving}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserBillingModal
