import React from 'react'

import {AddIcon} from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {TherapistAssignedSpecialtyViewItem, TherapistSpecialty} from '@/api/models'
import AsyncSelect from '@/common/async-select'
import LabeledSpinner from '@/common/components/labeled-spinner'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

import TherapistSpecialtyItem from './specialty-item'

const TherapistSpecialtiesModal = ({
  open,
  onClose,
  therapist,
  onComplete,
}: Omit<CommonModalProps, 'onComplete'> & {
  therapist: number | null
  onComplete: () => void
}) => {
  const toast = useToast()

  const [assignedSpecialties, setAssignedSpecialties] = React.useState<TherapistAssignedSpecialtyViewItem[]>(
    []
  )
  const [isAdding, setIsAdding] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState<TherapistSpecialty | null>(null)

  const _fetchAssignedTherapistSpecialties = React.useCallback(async () => {
    try {
      if (!therapist) {
        return
      }
      const {data, error} = await supabase
        .from('therapist_assigned_specialties_view')
        .select()
        .match({therapist})
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('Lack of data')
      }
      setAssignedSpecialties(data)
    } catch (e) {
      console.error('Failed to fetch assigned therapist specialties', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się pobrać przypisanych specjalności do terapeuty',
      })
    }
  }, [therapist, toast])
  const {handleSubmit: fetchAssignedTherapistSpecialties, loading: isFetching} = useLoadingState(
    _fetchAssignedTherapistSpecialties
  )

  React.useEffect(() => {
    fetchAssignedTherapistSpecialties()
  }, [fetchAssignedTherapistSpecialties])

  const handleSpecialtyAssign = React.useCallback(async () => {
    setIsAdding(true)
    try {
      const {error} = await supabase.from('therapist_assigned_specialties').upsert({
        therapist,
        therapist_specialty: selectedValue?.id,
        updated_at: new Date(),
      })
      if (error) {
        throw error
      }
      setSelectedValue(null)
      fetchAssignedTherapistSpecialties()
    } catch (e) {
      console.error(e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się dodać specjalności do terapeuty',
      })
    }
    setIsAdding(false)
  }, [fetchAssignedTherapistSpecialties, therapist, toast, selectedValue, setSelectedValue])

  const handleSpecialtyAssignmentDelete = React.useCallback(
    async (specialty: string) => {
      setIsDeleting(true)
      try {
        const {count, error} = await supabase
          .from('therapist_assigned_specialties')
          .delete({count: 'exact'})
          .eq('therapist', therapist)
          .eq('therapist_specialty', specialty)
        if (error) {
          throw error
        }
        if (!count) {
          throw new Error('No rows deleted')
        }
        setAssignedSpecialties((as) => as.filter((c) => c.id !== specialty))
      } catch (e) {
        console.error('Failed to delete therapist specialty', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się usunąć specjalności terapeuty',
        })
      }
      setIsDeleting(false)
    },
    [therapist, toast]
  )

  const handleClose = React.useCallback(() => {
    onComplete && onComplete()
    onClose()
  }, [onClose, onComplete])

  const asyncSelectLoadOptions = React.useCallback(
    async (searchValue) => {
      try {
        const {data, error} = await supabase
          .from('therapist_specialties')
          .select()
          .like('name', `%${searchValue}%`)
        if (error) throw error
        if (!data) throw new Error('No response data received.')

        return data?.filter((s) => !assignedSpecialties.some((as) => s.id === as.id))
      } catch (e) {
        console.error('Failed to fetch therapist specialties')
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać specjalności terapeuty',
        })
      }
    },
    [assignedSpecialties, toast]
  )

  const optionLabel = React.useCallback((c: Pick<TherapistSpecialty, 'id' | 'name'>) => c.name, [])

  const isLoading = React.useMemo(
    () => isAdding || isFetching || isDeleting,
    [isAdding, isFetching, isDeleting]
  )

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Specjalizacje terapeutów</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack width="100%">
            <FormControl id="req" isDisabled={isLoading}>
              <Stack>
                <FormLabel>Specjalizacje</FormLabel>
                <HStack>
                  <Box w="100%">
                    <AsyncSelect
                      value={selectedValue}
                      defaultOptions={true}
                      loadOptions={asyncSelectLoadOptions}
                      getOptionLabel={optionLabel}
                      onChange={setSelectedValue}
                    />
                  </Box>
                  <IconButton
                    aria-label="add"
                    variant="ghost"
                    colorScheme="green"
                    icon={<AddIcon />}
                    onClick={handleSpecialtyAssign}
                    isDisabled={!selectedValue || isLoading}
                  />
                </HStack>
              </Stack>
            </FormControl>
            {isFetching ? (
              <Box py="5">
                <LabeledSpinner />
              </Box>
            ) : (
              <Stack>
                {assignedSpecialties.map((specialty, index) => (
                  <TherapistSpecialtyItem
                    key={index}
                    item={specialty}
                    onDelete={handleSpecialtyAssignmentDelete}
                    isDisabled={isLoading}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={handleClose}>
            Zamknij
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TherapistSpecialtiesModal
