import * as React from 'react'

import {ChevronLeftIcon} from '@chakra-ui/icons'
import {
  AspectRatio,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import {Jutsu} from 'react-jutsu'
import {Link, generatePath, useParams} from 'react-router-dom'

import {supabase} from '@/api'
import {REACT_APP_JITSI_APP_ID} from '@/api/constants'
import {ArrowButton} from '@/common/arrow-button'
import LoadingView from '@/common/loading-view'
import {MEETING_INVITE, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {USER_MEETINGS} from '@/user/router'
import {formatEventTime} from '@/utils/string'

import {MEETING_ACCESS_DENIED, MEETING_FINISHED, MEETING_TOO_EARLY} from '../errors'
import {useMeetingInvitations, useMeetingToken} from './hooks'
import InviteModal from './invite-modal'

const configOverwrite = {
  doNotStoreRoom: true,
  enableFeaturesBasedOnToken: true,
  hideConferenceSubject: true,
  toolbarButtons: [
    'camera',
    'chat',
    'closedcaptions',
    'desktop',
    'download',
    'embedmeeting',
    'etherpad',
    'feedback',
    'filmstrip',
    'fullscreen',
    'hangup',
    'help',
    'livestreaming',
    'microphone',
    'mute-everyone',
    'mute-video-everyone',
    'participants-pane',
    'profile',
    'raisehand',
    'recording',
    'security',
    'select-background',
    'settings',
    'shareaudio',
    'sharedvideo',
    'shortcuts',
    'stats',
    'tileview',
    'toggle-camera',
    'videoquality',
    '__end',
  ],
}

const containerStyles = {
  flex: 1,
  height: 'unset',
  width: '100%',
  display: 'flex',
  alignItems: 'stretch',
}

const jitsiContainerStyles = {
  height: 'unset',
}

type MeetingParams = {id: string}

const generateInviteLink = (id: string) => {
  const parsed = new URL(window.location.origin)
  parsed.pathname = generatePath(MEETING_INVITE, {id})
  return parsed.toString()
}
const linkCopiedMessage = 'Skopiowano link do spotkania'

const MeetingView = () => {
  const toast = useToast()

  const [meetingEnded, setMeetingEnded] = React.useState(false)

  const {
    isOpen: isInvitationModalOpen,
    onOpen: onInvitationModalOpen,
    onClose: onInvitationModalClose,
  } = useDisclosure()

  const {id} = useParams<MeetingParams>()
  const {data: tokenData, fetch: tokenFetch, loading: tokenLoading} = useMeetingToken(id)
  const {
    data: invitationsData,
    fetch: invitationsFetch,
    loading: invitationsLoading,
  } = useMeetingInvitations(id)

  const handleMeetingEnd = React.useCallback(() => setMeetingEnded(true), [])

  const copyInviteLink = React.useCallback(async () => {
    const existingInvite = invitationsData.find((invitation) => !invitation.to && !invitation.name)
    if (existingInvite) {
      navigator.clipboard.writeText(generateInviteLink(existingInvite.id))
      toast({isClosable: true, status: 'success', title: linkCopiedMessage})
      return
    }

    // otherwise create a new one
    try {
      const {data, error} = await supabase.rpc('create_meeting_invitation', {
        meeting_id: id,
      })
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('Error while creating invitation link')
      }

      // spin up another fetch
      invitationsFetch()

      navigator.clipboard.writeText(generateInviteLink((data as any).invitation_id))
      toast({isClosable: true, status: 'success', title: linkCopiedMessage})
      return
    } catch (e) {
      console.error('Failed to create an invitation link', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się wygenerować linka do spotkania',
      })
    }
  }, [id, toast, invitationsData, invitationsFetch])

  React.useEffect(() => {
    console.log(invitationsData)
  }, [invitationsData])

  if (tokenLoading) return <LoadingView />

  if (!tokenData || tokenData?.error_code === MEETING_ACCESS_DENIED) {
    return (
      <Stack my={16} align="center" justify="center">
        <Text textAlign="center">Nie udało się uzyskać dostępu do spotkania.</Text>
      </Stack>
    )
  }

  if (tokenData.error_code === MEETING_TOO_EARLY) {
    return (
      <Stack my={16} align="center" justify="center">
        <Heading size="lg" textAlign="center">
          Jest za wcześnie, aby dołączyć do tego spotkania.
        </Heading>
        <Button isLoading={tokenLoading} onClick={tokenFetch}>
          Odśwież
        </Button>
      </Stack>
    )
  }

  if (tokenData.error_code === MEETING_FINISHED) {
    return (
      <Stack my={16} spacing={8} align="center" justify="center">
        <Heading size="lg" textAlign="center">
          Spotkanie już się skończyło
        </Heading>
        <ArrowButton as={Link} to={USER_MEETINGS} height={37} fontSize="lg" fontWeight={500}>
          Powrót do konta
        </ArrowButton>
      </Stack>
    )
  }

  if (meetingEnded) {
    return (
      <Container maxW="container.xl" my={8}>
        <Stack direction="column" spacing={8} align="center">
          <Stack textAlign="center">
            <Text fontSize="2xl" fontWeight={700}>
              Dziękujemy za udział w spotkaniu
            </Text>
            <Text fontWeight={500}>{tokenData?.name}</Text>
            {tokenData?.starts_at && <Text>{formatEventTime(tokenData?.starts_at)}</Text>}
          </Stack>
          <HStack spacing={4}>
            {tokenData.hosts.map((h, i) => (
              <Stack key={i} align="center" as={Link} to={generatePath(PSYCHOTHERAPY_THERAPIST, {id: h.id})}>
                <AspectRatio ratio={1} w="160px">
                  <Image src={h.avatar ?? ''} rounded="full" w="100%" h="100%" objectFit="cover" />
                </AspectRatio>
                <Button variant="brandLink" fontSize="xl">
                  {h.name}
                </Button>
              </Stack>
            ))}
          </HStack>
          <ArrowButton
            as={Link}
            to={USER_MEETINGS}
            bg="brand.yellow.600"
            hoverBg="brand.yellow.700"
            color="white"
            height={37}
            fontSize="lg"
            fontWeight={500}
          >
            Powrót do konta
          </ArrowButton>
        </Stack>
      </Container>
    )
  }

  return (
    <>
      <InviteModal
        isOpen={isInvitationModalOpen}
        onClose={onInvitationModalClose}
        meeting={id}
        invitations={invitationsData}
        fetchInvitations={invitationsFetch}
      />
      <Container as={Stack} maxW="container.xl" h="calc(100vh - 68px)" py={2} px={[0, null, 2]}>
        <Flex spacing={[4, null, 12]} align="center" px={[2, null, 0]} flex={0}>
          <IconButton
            as={Link}
            to={USER_MEETINGS}
            variant="ghost"
            aria-label="Cofnij się"
            minWidth="48px"
            height="48px"
            rounded="full"
            color="brand.yellow.600"
            _hover={{borderColor: 'brand.yellow.700', color: 'brand.yellow.700'}}
            icon={<ChevronLeftIcon boxSize="24px" />}
            borderColor="brand.yellow.600"
            borderWidth={2}
          />
          <Stack direction={['column', null, 'row']} flex={1} spacing={2} mx={4}>
            <Text fontWeight={500}>{tokenData.name}</Text>
            <Spacer display={['none', null, 'block']} />
            {tokenData.starts_at && <Text>{formatEventTime(tokenData.starts_at)}</Text>}
          </Stack>
          {!tokenData.support_group && (
            <Menu>
              <MenuButton as={Button} isDisabled={invitationsLoading}>
                Zaproś
              </MenuButton>
              <MenuList>
                <MenuItem onClick={copyInviteLink}>Skopiuj link do spotkania</MenuItem>
                <MenuItem onClick={onInvitationModalOpen}>Zaproś do spotkania</MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Jutsu
          containerStyles={containerStyles}
          jitsiContainerStyles={jitsiContainerStyles}
          roomName={`${REACT_APP_JITSI_APP_ID}/${id}`}
          jwt={tokenData.token}
          domain="8x8.vc"
          onMeetingEnd={handleMeetingEnd}
          configOverwrite={configOverwrite}
        />
        {/* <MeetingInviter meeting={id} /> */}
      </Container>
    </>
  )
}

export default MeetingView
