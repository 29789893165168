import {createContext} from 'react'

import {ContentEditorVariant} from './types'

export type EditorContextType = {
  variant: ContentEditorVariant
  readOnly: boolean
  surveys?: boolean
  bucketScope?: number
  bucketName?: string
}

const emptyEditorContext: EditorContextType = {
  variant: 'basic',
  readOnly: true,
  surveys: false,
}

export const EditorContext = createContext<EditorContextType>(emptyEditorContext)
