import {SupportedCountry, UserBillingAddress} from '@/api/models'

export const emptyBillingAddress = (type?: string): UserBillingAddress => ({
  address: '',
  city: '',
  country: 'pl',
  email: '',
  first_name: '',
  id: '',
  last_name: '',
  nip: undefined,
  phone_number: '',
  postal_code: '',
  type: type ?? '',
  updated_at: new Date(),
  use_basic_address: false,
  user: '',
})

export const emptyAddressSet = [
  emptyBillingAddress('basic'),
  emptyBillingAddress('shipping'),
  emptyBillingAddress('invoice'),
]

export const addressTypeToLabel: Record<string, string> = {
  basic: 'Adres rozliczeniowy',
  invoice: 'Faktura',
  shipping: 'Adres do wysyłki',
}

export const supportedCountries: Record<SupportedCountry, string> = {
  pl: 'Polska',
  gb: 'Wielka Brytania',
}

type SupportedPostalCodeLocale = 'PL' | 'GB'

export const countryToPostalCodeLocale: Record<SupportedCountry, SupportedPostalCodeLocale> = {
  gb: 'GB',
  pl: 'PL',
}
