import * as React from 'react'

import {Box, Button, Flex, Image, Stack, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {ViewBlogPost} from '@/api/models'
import {calculateReadTime} from '@/blog/utils'
import ContentEditor from '@/common/content-editor'
import {BLOG, BLOG_POST} from '@/router/paths'
import {formatDate} from '@/utils/string'

type Props = {
  item: ViewBlogPost
  isWide: boolean
  imageBorderRadius: string
}

const BlogItem = ({item, isWide, imageBorderRadius}: Props) => {
  const readTime = React.useMemo(() => calculateReadTime(item?.words_count ?? 0), [item.words_count])

  return (
    <Box width="100%">
      <Box width="100%" mb={10}>
        <Box height="288px" width="100%" overflow="hidden" borderRadius={imageBorderRadius}>
          {item.signed_image ? (
            <Image src={item.signed_image} width="100%" height="100%" objectFit="cover" />
          ) : (
            <Box width="100%" height="100%" bg="brand.gray.200" />
          )}
        </Box>
      </Box>
      <Stack direction="column" spacing={4}>
        <Text as="h2" fontSize="xl" fontWeight={500} minH="60px">
          {item.name}
        </Text>
        <Text fontSize="sm" fontWeight={300} minH="50px">
          {item.published_at && formatDate(new Date(item.published_at)) + ' | '}
          {item.author && item.author + ' | '}
          {readTime} {readTime === 1 ? 'minuta' : 'minut'} czytania
        </Text>
        <Box fontSize="md" fontWeight="300" minH="80px">
          <ContentEditor
            id={`blog-list-item-description-${item.id}`}
            variant="basic"
            value={item.description}
            readOnly={true}
          />
        </Box>
        <Flex direction="row" justifyContent="flex-end">
          <Button
            as={Link}
            to={item.id ? generatePath(BLOG_POST, {id: item.id, slug: item.slug}) : BLOG}
            variant="link"
            color="black"
          >
            Czytaj dalej
          </Button>
        </Flex>
      </Stack>
    </Box>
  )
}

export default BlogItem
