import React from 'react'

import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react'
import {format} from 'date-fns'
import {pl} from 'date-fns/locale'

import {supabase} from '@/api'
import {SupportGroupJoined, SupportGroupMeeting} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'
import {formatWeeklyDuration} from '@/utils/string'
import {CommonModalProps} from '@/utils/types'

import {columns, defaultSelectedColumns, emptySupportGroupMeeting, filterFields} from './constants'
import SupportGroupMeetingEditorModal from './editor-modal'

const SupportGroupAccessManagementTable = ({
  supportGroup,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {
  supportGroup: SupportGroupJoined | null
}) => {
  const tableState = useTableState({sortBy: 'starts_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<SupportGroupMeeting>({
    descending: tableState.descending,
    table: 'admin_support_group_meetings',
    fields: '*',
    pageNumber: tableState.page,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof SupportGroupMeeting,
    filter: tableState.filter,
    finalize: React.useCallback(
      (b) => (supportGroup?.id ? b.eq('support_group', supportGroup.id) : b),
      [supportGroup]
    ),
  })

  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<SupportGroupMeeting>()
  const {
    value: deleteValue,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useValueDisclosure<SupportGroupMeeting>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptySupportGroupMeeting)
  }, [onEditorOpen])

  const handleDelete = React.useCallback(async () => {
    const {error} = await supabase.rpc('delete_support_group_meeting', {
      meeting_id: deleteValue?.id,
    })
    if (error) throw error
  }, [deleteValue])

  const actions = React.useMemo(
    () => [deleteAction(onDeleteOpen), editAction(onEditorOpen)],
    [onEditorOpen, onDeleteOpen]
  )

  const handleClose = React.useCallback(() => {
    onComplete && onComplete()
    onClose()
  }, [onClose, onComplete])

  return (
    <>
      <Modal isOpen={open} onClose={handleClose} size="6xl">
        <ModalOverlay />
        <ModalContent minW="500">
          <ModalHeader>Zarządzanie spotkaniami {supportGroup?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack>
              {supportGroup?.first_meeting_duration && (
                <HStack fontSize="xl">
                  <Text>Domyślny czas spotkań:</Text>
                  <Text fontWeight={500}>{formatWeeklyDuration(supportGroup.first_meeting_duration)}</Text>
                </HStack>
              )}
              <DataTable<SupportGroupMeeting>
                columns={columns}
                data={data ?? []}
                totalCount={rows ?? 0}
                tableState={tableState}
                actions={actions}
                onRefresh={fetch}
                filterFields={filterFields}
                loading={loading}
                defaultSelectedColumns={defaultSelectedColumns}
                onAdd={handleAdd}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SupportGroupMeetingEditorModal
        supportGroup={supportGroup}
        item={editorValue}
        open={!!editorValue}
        onComplete={fetch}
        onClose={onEditorClose}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default SupportGroupAccessManagementTable
