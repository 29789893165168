import React from 'react'

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import YouTube from '@u-wave/react-youtube'
import {
  PlateEditor,
  PlateRenderElementProps,
  createPluginFactory,
  insertNodes,
  usePlateEditorState,
} from '@udecode/plate'
import randomstring from 'randomstring'
import {ImYoutube} from 'react-icons/im'
import {MdClose} from 'react-icons/md'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'

import useDebouncedEffect from '@/utils/use-debounced-effect'
import {getYouTubeVideoID, youtubeRegex} from '@/utils/youtube'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {YouTubeElement} from '../custom-types'
import {EditorContext} from '../editor-context'
import {BlockButton} from '../toolbar/buttons'

export const YouTubeRenderer = ({attributes, children, element}: PlateRenderElementProps) => {
  const {readOnly} = React.useContext(EditorContext)
  const editor = usePlateEditorState()!

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: ReactEditor.findPath(editor, element)})
  }, [editor, element])

  return (
    <Flex position="relative" mb={4} {...attributes} contentEditable={false} userSelect="none" width="100%">
      {children}
      <Box contentEditable={false} width="100%">
        {!!element.videoID && (
          <AspectRatio ratio={16 / 9} width="100%">
            <YouTube
              width="100%"
              height="100%"
              video={element.videoID}
              onError={(e) => console.log(e)}
              showRelatedVideos={false}
            />
          </AspectRatio>
        )}
        {!readOnly && (
          <IconButton
            variant="outline"
            onClick={handleRemove}
            icon={<MdClose />}
            aria-label="Usuń film"
            position="absolute"
            top="5px"
            right="5px"
            zIndex={2}
          />
        )}
      </Box>
    </Flex>
  )
}

export const InsertYouTubeButton = () => {
  const toast = useToast()

  const editor = usePlateEditorState()!
  const {isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose} = useDisclosure()
  const modalFocusedRef = React.useRef<HTMLInputElement>(null)

  const [videoURL, setVideoURL] = React.useState('')
  const [videoID, setVideoID] = React.useState<string | null>(null)

  useDebouncedEffect(() => setVideoID(getYouTubeVideoID(videoURL)), [videoURL], 250)

  const handleVideoURLChange = React.useCallback(({target: {value}}) => {
    setVideoURL(value.trim())
  }, [])

  const handleToolbarButtonClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      setVideoURL('')
      setVideoID(null)
      onModalOpen()
    },
    [onModalOpen]
  )

  const handleSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()

      try {
        if (!videoID) throw new Error('Niepoprawne URL')

        insertYouTube(editor, videoID)
      } catch (e) {
        toast({
          description: (e as Error).message,
          isClosable: true,
          status: 'error',
          title: 'Nie udało się dodać filmu z YouTube',
        })
      } finally {
        onModalClose()
      }
    },
    [editor, onModalClose, toast, videoID]
  )

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={onModalClose} initialFocusRef={modalFocusedRef} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Wstaw film z YouTube</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="5">
              <FormControl isInvalid={!!videoURL && !videoID}>
                <Input
                  ref={modalFocusedRef}
                  placeholder="Adres URL filmu"
                  variant="filled"
                  value={videoURL || ''}
                  onChange={handleVideoURLChange}
                />
                <FormErrorMessage>Niepoprawne URL</FormErrorMessage>
              </FormControl>
              {videoID && (
                <AspectRatio ratio={16 / 9} width="100%">
                  <YouTube width="100%" height="100%" video={videoID} onError={(e) => console.log(e)} />
                </AspectRatio>
              )}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onModalClose}>
              Anuluj
            </Button>
            <Button colorScheme="green" onClick={handleSubmit} disabled={!videoID}>
              Wstaw
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <BlockButton label="YouTube" format="youtube" icon={<ImYoutube />} onClick={handleToolbarButtonClick} />
    </>
  )
}

const insertYouTube = (editor: PlateEditor, videoID: string) => {
  const video: YouTubeElement = {
    children: [{text: ''}],
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    type: 'youtube',
    videoID,
  }
  insertNodes<YouTubeElement>(editor, video)
}

export const createYouTubePlugin = createPluginFactory({
  component: YouTubeRenderer,
  editor: {
    insertData: {
      format: 'text/plain',
      getFragment: ({data}) => [
        {
          children: [{text: ''}],
          id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
          type: 'youtube',
          videoID: getYouTubeVideoID(data),
        },
      ],
      query: ({data}) => !!data.match(youtubeRegex)?.length,
    },
  },
  isElement: true,
  isVoid: true,
  key: 'youtube',
  type: 'youtube',
})
