import * as React from 'react'

import {useToast} from '@chakra-ui/react'
import {Key} from 'rc-tree/lib/interface'
import {generatePath, useHistory} from 'react-router-dom'

import {supabase} from '@/api'
import {
  GetCourseDetailsResult,
  GetCourseMenuResult,
  GetPrevAndNextCoursePageResult,
  UpdateCoursePageProgressResult,
} from '@/api/models'
import useRunOnReconnect from '@/common/use-run-on-reconnect'
import {CourseDetails, CourseNode} from '@/courses/types'
import {ADMIN_COURSE_PAGE, COURSE_PAGE} from '@/router/paths'

import {buildTreeData} from './utils'

export const useCourseDetails = (id: string) => {
  const toast = useToast()

  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<CourseDetails | undefined>()
  const fetch = React.useCallback(
    async (markAsLoading?: boolean) => {
      try {
        markAsLoading && setLoading(true)
        const {data, error} = await supabase.rpc('get_course_details', {
          course_id: id,
        })
        if (error) {
          throw error
        }
        const result = data as any as GetCourseDetailsResult
        if ('error' in result) {
          throw new Error(result.error)
        }
        if (!result.details) {
          throw new Error('No response data received.')
        }
        setData(result.details)
      } catch (e) {
        console.error('Failed to fetch course details', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać informacji o warsztacie',
        })
      } finally {
        markAsLoading && setLoading(false)
      }
    },
    [id, setData, toast]
  )

  useRunOnReconnect(fetch, !!data)

  React.useEffect(() => {
    fetch(true)
  }, []) // eslint-disable-line

  return {
    data,
    fetch,
    loading,
  }
}

type CourseMenuHookOptions = {
  id: string
  adminView: boolean
  setExpandedKeys: (keys: Key[]) => void
}

export const useCourseMenu = ({id, adminView, setExpandedKeys}: CourseMenuHookOptions) => {
  const toast = useToast()

  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<CourseNode[] | undefined>()
  const fetch = React.useCallback(
    async (markAsLoading?: boolean) => {
      try {
        markAsLoading && setLoading(true)
        const {data, error} = await supabase.rpc('get_course_menu', {
          course_id: id,
        })
        if (error) {
          throw error
        }
        const result = data as any as GetCourseMenuResult
        if ('error' in result) {
          throw new Error(result.error)
        }
        setData(buildTreeData(result.sections, result.pages, adminView))
        markAsLoading && setExpandedKeys(result.sections.map((s) => s.id))
      } catch (e) {
        console.error('Failed to fetch course sections', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać treści warsztatu',
        })
      } finally {
        markAsLoading && setLoading(false)
      }
    },
    [adminView, id, setData, setLoading, setExpandedKeys, toast]
  )

  useRunOnReconnect(fetch, !!data)

  React.useEffect(() => {
    fetch(true)
  }, []) // eslint-disable-line

  return {
    data,
    fetch,
    loading,
  }
}

type CoursePrevNextOptions = {
  adminView: boolean
  id: string
  page: string
}

export const useCoursePrevNext = ({adminView, id, page}: CoursePrevNextOptions) => {
  const history = useHistory()
  const toast = useToast()

  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<Array<string | null>>([null, null])
  const fetch = React.useCallback(async () => {
    if (!page || page === 'summary' || page === 'journal' || page === 'journal-explorer') return

    setLoading(true)

    try {
      const {data, error} = await supabase.rpc('get_prev_and_next_course_page', {
        course_id: id,
        page_id: page,
      })
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('No response data received.')
      }
      const result = data as any as GetPrevAndNextCoursePageResult
      if ('error' in result) {
        throw result.error
      }
      setData([result.prev_page, result.next_page])
    } catch (e) {
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się pobrać kolejnej strony warsztatu',
      })
    } finally {
      setLoading(false)
    }
  }, [id, page, setLoading, setData, toast])
  React.useEffect(() => {
    fetch()
  }, [id, page]) // eslint-disable-line
  const goToNextPage = React.useCallback(async () => {
    setLoading(true)
    try {
      const {data: rawData, error} = await supabase.rpc('update_course_page_progress', {
        action_type: 'complete',
        course_id: id,
        page_id: page,
      })
      if (error) {
        throw error
      }
      if (!rawData) {
        throw new Error('No response data received.')
      }
      const result = rawData as any as UpdateCoursePageProgressResult
      if ('error' in result) {
        throw result.error
      }

      history.push(
        generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
          course: id,
          page: data[1] || 'summary',
        })
      )
    } catch (e) {
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się oznaczyć strony warsztatu jako przeczytanej.',
      })
    } finally {
      setLoading(false)
    }
  }, [adminView, history, id, toast, page, data])

  return {
    data,
    fetch,
    goToNextPage,
    loading,
  }
}

export const useTouchCoursePage = (id: string, page: string) => {
  const touch = React.useCallback(async () => {
    if (!page || page === 'summary' || page === 'journal' || page === 'journal-explorer') return

    try {
      const {data: rawData, error} = await supabase.rpc('update_course_page_progress', {
        action_type: 'touch',
        course_id: id,
        page_id: page,
      })
      if (error) {
        throw error
      }
      if (!rawData) {
        throw new Error('No response data received.')
      }
      const result = rawData as any as UpdateCoursePageProgressResult
      if ('error' in result) {
        throw result.error
      }
    } catch (e) {
      console.error('Failed to touch the course page progress', e)
    }
  }, [id, page])

  return touch
}
