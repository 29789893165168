import * as React from 'react'

import {Stack} from '@chakra-ui/layout'
import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay} from '@chakra-ui/modal'

import TherapistCalendarState from '@/therapist-panel/calendar/calendar-state'
import TherapistTimeSlots from '@/therapist-panel/calendar/time-slots'
import {CommonModalProps} from '@/utils/types'

type Props = CommonModalProps & {
  therapist: number | null
}

const TherapistTimeSlotsModal = ({onClose, open, therapist}: Props) => (
  <Modal isOpen={open} onClose={onClose} size="6xl">
    <ModalOverlay />
    <ModalContent>
      <ModalCloseButton />
      <ModalBody my={8}>
        {therapist && (
          <Stack width="100%" align="center" spacing={6}>
            <TherapistTimeSlots therapistID={therapist} />
            <TherapistCalendarState therapistID={therapist} />
          </Stack>
        )}
      </ModalBody>
    </ModalContent>
  </Modal>
)

export default TherapistTimeSlotsModal
