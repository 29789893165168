import * as React from 'react'

import {
  AspectRatio,
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'

import {useMailchimpSubscribe} from './hooks'
import newsletter from './newsletter.png'

const url =
  'https://psychoforma.us21.list-manage.com/subscribe/post?u=fed8316b1f8027e81694b034c&amp;id=0d4cdc9757&amp;f_id=00ead7e1f0'

const Newsletter = () => {
  const toast = useToast()

  const [email, setEmail] = React.useState('')
  const {loading, subscribe} = useMailchimpSubscribe(url)
  const handleSubscribe = React.useCallback(async () => {
    try {
      await subscribe(email)

      toast({
        isClosable: true,
        status: 'success',
        title: 'Zapisałeś/aś się do newslettera, dziękujemy!',
      })
      setEmail('')
    } catch (e) {
      console.error('Failed to subscribe to the newsletter', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się zapisać do newslettera',
      })
    }
  }, [email, subscribe, toast])

  return (
    <Box position="relative" overflow="hidden">
      <Box left="0" top={['24%', null, '38%']} width="100%" height="100%" position="absolute" zIndex={-1}>
        <Box backgroundColor="brand.yellow.300" height={['76%', null, '62%']} />
      </Box>
      <Container maxW="container.xl" py={16}>
        <Stack direction={['column', null, 'row']} spacing={8} alignItems="center">
          <Box width={['100%', null, '256px']} flexShrink={0}>
            <Stack direction="row" spacing={4} alignItems="flex-end">
              <Box flex={1} width={['auto', null, '100%']} maxWidth="260px">
                <AspectRatio ratio={1}>
                  <Image src={newsletter} rounded="full" />
                </AspectRatio>
              </Box>
              <Text
                as="h2"
                flex={1}
                fontSize={['xl', '2xl', '3xl']}
                marginBottom={4}
                fontWeight={500}
                display={['block', null, 'none']}
              >
                Odważ się żyć lepiej!
              </Text>
            </Stack>
          </Box>
          <Flex direction="column" fontWeight={300}>
            <Text as="h2" fontSize="3xl" marginBottom={8} fontWeight={500} display={['none', null, 'block']}>
              Odważ się żyć lepiej!
            </Text>

            <Grid templateColumns={['repeat(5, 1fr)', null, 'repeat(9, 1fr)']} gap={12}>
              <GridItem colSpan={5} pr={16}>
                <Text fontWeight={500} maxWidth="400px">
                  Zapisz się do newslettera, aby otrzymywać inspirujące treści. Zyskuj wsparcie i wskazówki w
                  drodze do swojego celu.
                </Text>
              </GridItem>
              <GridItem colSpan={[5, null, 4]}>
                <Stack direction="column" spacing={8} alignItems={['center', null, 'flex-start']}>
                  <Input
                    variant="flush"
                    placeholder="E-mail"
                    background="transparent"
                    borderBottom="2px solid"
                    borderColor='brand.yellow.600"'
                    borderRadius="none"
                    fontWeight={300}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <ArrowButton
                    bg="brand.yellow.600"
                    hoverBg="brand.yellow.700"
                    color="white"
                    height={42}
                    fontSize="lg"
                    onClick={handleSubscribe}
                    isLoading={loading}
                    disabled={loading}
                  >
                    <Text px={16}>Zapisz się</Text>
                  </ArrowButton>
                </Stack>
              </GridItem>
            </Grid>
          </Flex>
        </Stack>
      </Container>
    </Box>
  )
}

export default Newsletter
