import * as React from 'react'

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  HStack,
  Button,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react'

import {TherapistService} from '@/api/models'
import {PGInterval} from '@/api/types'
import DurationPicker from '@/common/duration-picker'
import useEditorModalState from '@/common/use-editor-modal'
import {CommonModalProps} from '@/utils/types'

import {emptyTherapistService} from '../constants'
import {inputToUpsertArgs} from '../utils'

const TherapistServiceEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: TherapistService | null}) => {
  const {input, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<TherapistService>({
      table: 'therapist_service_types',
      item,
      emptyInput: emptyTherapistService,
      open,
      inputToUpsertArgs,
      onComplete,
      onClose,
    })

  const handlePriceChange = React.useCallback(
    (v: string) => handleCustomInputChange({price: +v}),
    [handleCustomInputChange]
  )
  const handleMeetingDurationChange = React.useCallback(
    (d: PGInterval) => handleCustomInputChange({meeting_duration: d}),
    [handleCustomInputChange]
  )

  const isSubmitDisabled = React.useMemo(() => !input.name || !input.order, [input])

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj usługę' : 'Dodaj usługę'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl isDisabled={loading}>
                <FormLabel>Nazwa</FormLabel>
                <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Numer porządkowy</FormLabel>
                <Input name="order" type="number" value={input.order ?? 0} onChange={handleInputChange} />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Cena (PLN)</FormLabel>
                <NumberInput min={0} value={input.price.toString()} onChange={handlePriceChange}>
                  <NumberInputField placeholder="PLN" />
                </NumberInput>
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Długość spotkania</FormLabel>
                <DurationPicker value={input.meeting_duration} onChange={handleMeetingDurationChange} />
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={isSubmitDisabled}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TherapistServiceEditorModal
