import * as React from 'react'

import {Box, Center, Container, Spinner, Text, useToken} from '@chakra-ui/react'
import {Parallax} from 'react-scroll-parallax'

import {PublicCourse} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'
import Carousel from '@/courses/carousel'

const FEATURED_COURSES_LIMIT = 5

const Courses = () => {
  const [green800] = useToken('colors', ['brand.green.800'])
  const {data, loading} = useSupabaseQuery<PublicCourse>({
    table: 'public_courses',
    fields: '*',
    order: 'order',
    itemsPerPage: FEATURED_COURSES_LIMIT,
    errSnackbarTitle: 'Nie udało się wczytać wyróżnionych szkoleń',
  })

  return (
    <>
      <Container maxW="container.xl" px={[8, null, 4]}>
        <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} mt={10} mb={12}>
          Warsztaty
        </Text>
      </Container>
      <Box position="relative">
        <Box left="0" top="-5%" width="100%" height="100%" position="absolute" zIndex={-1} overflow="hidden">
          <Parallax
            style={{
              backgroundColor: green800,
              height: '25%',
            }}
            translateY={[0, 50]}
          />
        </Box>
        <Box mt={24} mb={12}>
          {!data || loading ? (
            <Center m={4}>
              <Spinner size="xl" />
            </Center>
          ) : (
            <Carousel courses={data} />
          )}
        </Box>
      </Box>
    </>
  )
}

export default Courses
