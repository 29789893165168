export const baseStyle: React.CSSProperties = {
  alignItems: 'center',
  backgroundColor: '#fafafa',
  borderColor: '#eeeeee',
  borderRadius: 2,
  borderStyle: 'dashed',
  borderWidth: 2,
  color: '#bdbdbd',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  outline: 'none',
  padding: '20px',
  transition: 'border .24s ease-in-out',
}

export const focusedStyle: React.CSSProperties = {
  borderColor: '#2196f3',
}

export const acceptStyle: React.CSSProperties = {
  borderColor: '#00e676',
}

export const rejectStyle: React.CSSProperties = {
  borderColor: '#ff1744',
}
