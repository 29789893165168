import * as React from 'react'

import {HStack, Icon, Stack} from '@chakra-ui/react'
import {BsStar, BsStarFill} from 'react-icons/bs'

type Props = {
  value: number
  onClick?: (index: number) => void
}

const StarRating = ({value, onClick}: Props) => {
  return (
    <HStack>
      {Array.from(new Array(5)).map((_, i) => (
        <Stack
          key={i}
          onClick={onClick ? () => onClick(i + 1) : undefined}
          cursor={onClick ? 'pointer' : undefined}
        >
          {i + 1 <= value ? (
            <Icon as={BsStarFill} color="brand.yellow.600" />
          ) : (
            <Icon as={BsStarFill} color="brand.yellow.300" />
          )}
        </Stack>
      ))}
    </HStack>
  )
}

export default StarRating
