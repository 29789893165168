import * as React from 'react'

import {Box, Stack, Text, Image, Avatar, HStack, Heading, Wrap, WrapItem, Tag} from '@chakra-ui/react'

import {AdminTherapist} from '@/api/models'
import {selectProfile} from '@/auth/state'
import defaultAvatar from '@/common/icons/default-avatar.svg'
import LoadingView from '@/common/loading-view'
import useFetchSingle from '@/common/use-fetch-single'
import TherapistCertificates from '@/psychotherapy/therapists/showcase/certificates'
import {useAppSelector} from '@/store'

import TherapistsServices from './services'

const TherapistProfile = () => {
  const userProfile = useAppSelector(selectProfile)

  const {data, loading} = useFetchSingle<AdminTherapist>(
    React.useMemo(
      () => ({
        table: 'admin_therapists',
        fields: '*',
        match: {user: userProfile?.id},
        errSnackbarTitle: 'Nie udało się wczytać profilu terapeuty',
      }),
      [userProfile]
    )
  )

  if (loading) return <LoadingView />

  return (
    <Stack spacing={16}>
      <Box bg="brand.green.600">
        <HStack py={8} px={[4, null, 8]} align="center" spacing={8}>
          <Avatar
            bg="brand.green.600"
            icon={<Image src={defaultAvatar} />}
            src={data?.signed_avatar || undefined}
            size="xl"
            overflow="hidden"
          />
          <Stack color="white">
            <Text fontSize="3xl">{data?.full_name}</Text>
            <Text fontSize="lg" fontWeight={300} pb={[2, null, 0]}>
              {data?.email}
            </Text>
          </Stack>
        </HStack>
      </Box>
      <Stack px={[4, null, 8]} spacing={12}>
        <TherapistsServices />

        <Stack spacing={6}>
          <Heading size="md" fontWeight={500}>
            Twoje specjalizacje
          </Heading>
          <Wrap>
            {data?.specialties.map((s, i) => (
              <WrapItem key={i}>
                <Tag rounded="full" size="lg" bg="brand.yellow.600" color="white">
                  {s}
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Stack>

        <TherapistCertificates therapist={data?.id ?? 0} mine={true} />
      </Stack>
    </Stack>
  )
}

export default TherapistProfile
