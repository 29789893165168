import {
  PlateEditor,
  TNode,
  createNodeIdPlugin,
  createSoftBreakPlugin,
  createTrailingBlockPlugin,
  isMarkActive as isMarkActivePlate,
  someNode,
  toggleNodeType,
} from '@udecode/plate'
import _ from 'lodash'

import {nodeIdPluginConfig, trailingBlockPluginConfig} from './constants'
import {createAttachmentPlugin} from './plugins/attachment'
import {createBlockquotePlugin} from './plugins/blockquote'
import {createBoldPlugin} from './plugins/bold'
import {createCanvaPlugin} from './plugins/canva'
import {createCodePlugin} from './plugins/code'
import {createDndPlugin} from './plugins/dnd'
import {createHeadingOnePlugin} from './plugins/heading-one'
import {createHeadingTwoPlugin} from './plugins/heading-two'
import {createImagePlugin} from './plugins/image'
import {createItalicPlugin} from './plugins/italic'
import {createLinkPlugin} from './plugins/link'
import {createListPlugin} from './plugins/list'
import {createParagraphPlugin} from './plugins/paragraph'
import {createSurveyPlugin} from './plugins/survey'
import {createTypeformPlugin} from './plugins/typeform'
import {createUnderlinePlugin} from './plugins/underline'
import {createVimeoPlugin} from './plugins/vimeo'
import {createYouTubePlugin} from './plugins/youtube'
import {ContentEditorVariant} from './types'

export const isBlockActive = (editor: PlateEditor, format: string) => {
  return !!editor?.selection && someNode(editor, {match: {type: format}})
}

export const isMarkActive = (editor: PlateEditor, format: string) => {
  return !!editor?.selection && isMarkActivePlate(editor, format)
}

export const toggleBlock = (editor: PlateEditor, format: string) => {
  toggleNodeType(editor, {activeType: format, inactiveType: 'p'})
}

export const flattenEditorBlockData = (tree: TNode[]) => {
  const flattenedTree: TNode[] = []
  // The received tree prop is a component's state,
  // so we need to deep copy it to not break immutability.
  tree = _.cloneDeep(tree)

  const flattenSubTree = (node: TNode) => {
    for (const child of node.children) {
      if (child.type === 'attachment' || child.type === 'image') {
        // Put attachments and images to the root level of result array.
        flattenedTree.push(child)
      }
      if (!child.children) {
        continue
      }
      // First, search a child node for deeper nested attachments and images and extract them to the result array.
      flattenSubTree(child)
      // Then, get rid of attachments and images from their nested location.
      child.children = child.children.filter((y: any) => y.type !== 'attachment' && y.type !== 'image')
    }
  }

  for (const x of tree) {
    if (x.children) {
      // First, search the subtree for nested attachments and images and extract them to the result array.
      flattenSubTree(x)
      // Then, get rid of attachments and images from their nested location.
      x.children = x.children.filter((y: any) => y.type !== 'attachment' && y.type !== 'image')
    }
    flattenedTree.push(x)
  }
  return flattenedTree
}

export const toPayload = (data: TNode[]) => {
  const flattenedValue = flattenEditorBlockData(data)

  const payload = flattenedValue.map((p) => {
    if ((p.type === 'attachment' || p.type === 'image') && 'url' in p) {
      // Needs to make a shallow copy of p. Otherwise we cannot delete 'url' key from the node.
      p = {...p}
      // do not store supabase signed urls in the database
      delete p.url
    }
    return p
  })
  return payload
}

export const getBasicPlugins = () => [
  createParagraphPlugin(),

  createBoldPlugin(),
  createCodePlugin(),
  createItalicPlugin(),
  createUnderlinePlugin(),
  createLinkPlugin(),

  // append empty paragraph at the end of the editor
  createTrailingBlockPlugin(trailingBlockPluginConfig),
  createSoftBreakPlugin(),
  createDndPlugin(),
  createNodeIdPlugin(nodeIdPluginConfig),
]

export const getExtendedPlugins = () => [
  ...getBasicPlugins(),
  createBlockquotePlugin(),
  createHeadingOnePlugin(),
  createHeadingTwoPlugin(),
  createListPlugin(),
  createVimeoPlugin(),
  createYouTubePlugin(),
  createCanvaPlugin(),
  createTypeformPlugin(),
]

export const getAllPlugins = (editorContext: any, surveys?: boolean) => [
  // `editor.insertData` in `withOverrides` plugin prop is waterfalled from the last plugin to first one.
  // It is important to leave attachment as the first file-handling plugin and filter out files in further ones.
  ...getExtendedPlugins(),
  createAttachmentPlugin({options: editorContext}),
  createImagePlugin({options: editorContext}),
  ...(surveys ? [createSurveyPlugin()] : []),
]

export const getPlugins = (variant: ContentEditorVariant, editorContext: any, surveys?: boolean) => {
  switch (variant) {
    case 'basic':
      return getBasicPlugins()
    case 'extended':
      return getExtendedPlugins()
  }
  return getAllPlugins(editorContext, surveys)
}
