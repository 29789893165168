import React from 'react'

import {ChevronLeftIcon} from '@chakra-ui/icons'
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Stack,
  Text,
  chakra,
  useDisclosure,
  useToast,
} from '@chakra-ui/react'
import YouTube from '@u-wave/react-youtube'
import {format} from 'date-fns'
import {isValidMotionProp, motion} from 'framer-motion'
import {Link, useParams} from 'react-router-dom'

import {supabase} from '@/api'
import {MyWebinar} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import LoadingView from '@/common/loading-view'
import NotFoundView from '@/common/not-found-view'
import useReviewModal from '@/common/reviews/review-modal/use-review-modal'
import useFetchSingle from '@/common/use-fetch-single'
import {USER_WEBINARS} from '@/user/router'
import {sleep} from '@/utils/duration'
import {formatEventTime} from '@/utils/string'
import {getYouTubeVideoID} from '@/utils/youtube'

const MotionBox = chakra(motion.div, {
  shouldForwardProp: (prop) => isValidMotionProp(prop) || prop === 'children',
})

type Params = {meeting: string}

const WebinarView = () => {
  const {meeting} = useParams<Params>()
  const [youTubeVideoID, setYouTubeVideoID] = React.useState<string | null>(null)

  const toast = useToast()
  const {getButtonProps, getDisclosureProps, isOpen: isChatOpen} = useDisclosure()

  const {
    data: webinar,
    loading,
    fetch,
  } = useFetchSingle<MyWebinar>(
    React.useMemo(
      () => ({
        table: 'my_webinars',
        fields: '*',
        match: {meeting},
        errSnackbarTitle: 'Nie udało się wczytać webinaru',
      }),
      [meeting]
    )
  )

  const {startReview} = useReviewModal({onComplete: fetch})
  const handleStartReview = React.useCallback(() => {
    startReview({open: true, type: 'webinar', itemID: webinar?.meeting})
  }, [startReview, webinar])

  const fetchYouTubeURL = React.useCallback(async () => {
    if (!webinar) return

    // continuously fetch the url until the admins insert it
    while (true) {
      try {
        console.log('Fetching YouTube URL...')
        const {data, error} = await supabase
          .from('my_webinars')
          .select('youtube_url')
          .match({id: webinar.id})
          .maybeSingle()
        if (error) throw error

        if (data?.youtube_url) {
          setYouTubeVideoID(getYouTubeVideoID(data.youtube_url))
          break
        }
      } catch (e) {
        console.log((e as Error).message)
        toast({
          isClosable: true,
          title: 'Nie udało się pozyskać linku do webinaru',
          status: 'error',
        })
      }

      await sleep(5000)
    }
  }, [webinar, toast])

  React.useEffect(() => {
    webinar && fetchYouTubeURL()
  }, [webinar]) // eslint-disable-line

  if (loading) return <LoadingView label="Weryfikowanie dostępu do webinarium" />

  if (!webinar) return <NotFoundView />

  return (
    <Container as={Stack} maxW="container.xl" py={2} px={[0, null, 2]} h="calc(100vh - 68px)">
      {/* Header */}
      <HStack justify="space-between">
        <HStack spacing={4}>
          <IconButton
            as={Link}
            to={USER_WEBINARS}
            variant="ghost"
            aria-label="Cofnij się"
            minW="48px"
            h="48px"
            rounded="full"
            color="brand.yellow.600"
            _hover={{borderColor: 'brand.yellow.700', color: 'brand.yellow.700'}}
            icon={<ChevronLeftIcon boxSize="24px" />}
            borderColor="brand.yellow.600"
            borderWidth={2}
          />
          <Heading size="md">{webinar?.name} - Webinar</Heading>
        </HStack>
        <Text>
          {formatEventTime(webinar.start_at)} - {format(new Date(webinar.end_at), 'HH:mm')}
        </Text>
      </HStack>

      {new Date(webinar.end_at).getTime() < Date.now() ? (
        <Stack align="center" py={8} spacing={12}>
          <Stack align="center">
            {webinar.thumbnail && (
              <Image src={webinar.thumbnail} boxSize="162px" borderRadius="full" objectFit="cover" />
            )}
            <Heading fontSize="lg">Dziękujemy za udział w webinarze</Heading>
            <Text fontSize="2xl" fontWeight={300}>
              "{webinar.name}"
            </Text>
          </Stack>
          <Stack direction={['column', null, 'row']} spacing={8} align="center" justify="center">
            <ArrowButton
              as={Link}
              to={USER_WEBINARS}
              bg="brand.green.600"
              hoverBg="brand.green.700"
              color="white"
              height={40}
              fontSize="lg"
              fontWeight={500}
              width="320px"
              flipped={true}
            >
              Powrót do konta
            </ArrowButton>
            <ArrowButton
              onClick={handleStartReview}
              height={40}
              fontSize="lg"
              fontWeight={500}
              width="320px"
              isDisabled={webinar.reviewed}
            >
              {webinar.reviewed ? 'Oceniono' : 'Zostaw ocenę'}
            </ArrowButton>
          </Stack>
        </Stack>
      ) : (
        <Center h="100%" bg="brand.gray.300" borderRadius="60px 0">
          {youTubeVideoID ? (
            <Flex h="100%" w="100%" align="stretch">
              <Box flex={1}>
                <YouTube width="100%" height="100%" video={youTubeVideoID} onError={(e) => console.log(e)} />
              </Box>
              {webinar.enable_chat && (
                <Flex>
                  <MotionBox
                    {...getDisclosureProps()}
                    hidden={!isChatOpen}
                    initial={false}
                    animate={{width: isChatOpen ? 350 : 0}}
                    transition={{duration: 0.5}}
                    overflow="hidden"
                    position="relative"
                  >
                    <Box
                      as="iframe"
                      position="absolute"
                      right="0"
                      w="350px"
                      h="100%"
                      src={`https://www.youtube.com/live_chat?v=${youTubeVideoID}&embed_domain=${window.location.hostname}`}
                    />
                  </MotionBox>
                  <Button {...getButtonProps()} h="100%" rounded="0" p={2} colorScheme="brand.green" minW={0}>
                    <HStack
                      sx={{textOrientation: 'mixed', writingMode: 'vertical-rl'}}
                      align="center"
                      spacing={0}
                    >
                      <ChevronLeftIcon
                        transform={`rotate(${isChatOpen ? 180 : 0}deg)`}
                        transition="all 500ms ease"
                        boxSize="20px"
                      />
                      <Text fontSize="sm">{isChatOpen ? 'Schowaj' : 'Pokaż'} czat</Text>
                    </HStack>
                  </Button>
                </Flex>
              )}
            </Flex>
          ) : (
            <Heading size="md">Webinar jeszcze się nie rozpoczął</Heading>
          )}
        </Center>
      )}
    </Container>
  )
}

export default WebinarView
