import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const UserIcon = (props: IconProps) => (
  <Icon viewBox="0 0 60 60" {...props} fill="none">
    <path
      d="M50.3029 44.7422C49.1976 42.1241 47.5936 39.7459 45.5802 37.7402C43.573 35.7288 41.1952 34.125 38.5783 33.0176C38.5548 33.0059 38.5314 33 38.508 32.9883C42.1583 30.3516 44.5314 26.0566 44.5314 21.2109C44.5314 13.1836 38.0275 6.67969 30.0001 6.67969C21.9728 6.67969 15.4689 13.1836 15.4689 21.2109C15.4689 26.0566 17.8419 30.3516 21.4923 32.9941C21.4689 33.0059 21.4455 33.0117 21.422 33.0234C18.797 34.1309 16.4416 35.7188 14.4201 37.7461C12.4086 39.7533 10.8049 42.1311 9.69741 44.748C8.60946 47.3101 8.0227 50.0569 7.9689 52.8398C7.96733 52.9024 7.9783 52.9646 8.00116 53.0228C8.02401 53.0811 8.05829 53.1341 8.10198 53.1789C8.14566 53.2237 8.19787 53.2593 8.25551 53.2835C8.31316 53.3078 8.37509 53.3203 8.43765 53.3203H11.9533C12.2111 53.3203 12.4162 53.1152 12.422 52.8633C12.5392 48.3398 14.3556 44.1035 17.5666 40.8926C20.8888 37.5703 25.3009 35.7422 30.0001 35.7422C34.6994 35.7422 39.1115 37.5703 42.4337 40.8926C45.6447 44.1035 47.4611 48.3398 47.5783 52.8633C47.5841 53.1211 47.7892 53.3203 48.047 53.3203H51.5626C51.6252 53.3203 51.6871 53.3078 51.7448 53.2835C51.8024 53.2593 51.8546 53.2237 51.8983 53.1789C51.942 53.1341 51.9763 53.0811 51.9991 53.0228C52.022 52.9646 52.033 52.9024 52.0314 52.8398C51.9728 50.0391 51.3927 47.3145 50.3029 44.7422ZM30.0001 31.2891C27.3107 31.2891 24.7794 30.2402 22.8751 28.3359C20.9708 26.4316 19.922 23.9004 19.922 21.2109C19.922 18.5215 20.9708 15.9902 22.8751 14.0859C24.7794 12.1816 27.3107 11.1328 30.0001 11.1328C32.6896 11.1328 35.2208 12.1816 37.1251 14.0859C39.0294 15.9902 40.0783 18.5215 40.0783 21.2109C40.0783 23.9004 39.0294 26.4316 37.1251 28.3359C35.2208 30.2402 32.6896 31.2891 30.0001 31.2891Z"
      fill="currentColor"
    />
  </Icon>
)

export default UserIcon
