import * as React from 'react'

import {Heading, Stack} from '@chakra-ui/react'

import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import useSupabaseQuery from '@/common/use-custom-query'

import {TherapistMeeting} from '../types'
import {columns, defaultSelectedColumns, filterFields} from './constants'

const MeetingHistory = () => {
  const tableState = useTableState({sortBy: 'ends_at', descending: true})
  const {loading, data, fetch, rows} = useSupabaseQuery<TherapistMeeting>({
    table: 'therapist_meetings',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof TherapistMeeting,
    filter: tableState.filter,
    finalize: React.useCallback(
      (b) => b.lt('joinable_to', new Date().toUTCString()).eq('type', 'therapy'),
      []
    ),
  })

  return (
    <Stack py={4} spacing={4}>
      <Heading size="md">Historia spotkań</Heading>
      <DataTable<TherapistMeeting>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        onRefresh={fetch}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
        filterFields={filterFields}
      />
    </Stack>
  )
}

export default MeetingHistory
