import * as React from 'react'

import {Tag} from '@chakra-ui/react'

import {AdminPaymentRefund, RefundStatus} from '@/api/models'
import DownloadInvoicesButton from '@/common/components/download-invoices-button'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const mapStatusToColor: Record<RefundStatus, string> = {
  completed: 'green',
  pending: 'yellow',
  cancelled: 'red',
}

export const mapStatusToTranslationLabel: Record<RefundStatus, string> = {
  completed: 'Zrealizowane',
  pending: 'Oczekiwanie',
  cancelled: 'Anulowane',
}

export const defaultSelectedColumns = ['id', 'payment', 'status', 'invoices', 'created_at', 'updated_at']

export const columns: Column<AdminPaymentRefund>[] = [
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
  },
  {
    Header: 'Płatność',
    id: 'payment',
    renderAs: 'code',
  },
  {
    Header: 'Status',
    id: 'status',
    renderer: ({status}) => (
      <Tag size="md" variant="solid" colorScheme={mapStatusToColor[status]}>
        {mapStatusToTranslationLabel[status]}
      </Tag>
    ),
    toCSV: ({status}) => mapStatusToTranslationLabel[status],
  },
  {
    Header: 'Faktura',
    id: 'invoices',
    renderer: ({invoices}) => (invoices?.length ? <DownloadInvoicesButton invoices={invoices} /> : '-'),
    sortable: false,
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'text',
  },
  {
    label: 'Płatność',
    name: 'payment',
    variant: 'text',
  },
  {
    label: 'Status',
    name: 'status',
    variant: 'select',
    values: Object.entries(mapStatusToTranslationLabel).map(([k, v]) => ({
      label: v,
      name: k,
    })),
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
