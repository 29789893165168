import * as React from 'react'

import {Box, Button, Heading, Spinner, Stack, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {MySupportGroup} from '@/api/models'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import useSupabaseQuery from '@/common/use-custom-query'
import {PSYCHOTHERAPY_THERAPIST} from '@/router/paths'

const FinishedSupportGroups = () => {
  const {data, loading, fetch} = useSupabaseQuery<MySupportGroup>(
    React.useMemo(
      () => ({
        table: 'my_support_groups',
        fields: '*',
        errSnackbarTitle: 'Nie udało się pobrać zakończonych grup wsparcia',
        finalize: (b) => b.eq('is_finished', true).eq('role', 'member'),
      }),
      []
    )
  )

  return !data?.length && !loading ? null : (
    <Stack spacing={12}>
      <Heading size="md">Zakończone grupy</Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table>
          <Thead display={['none', null, 'table-header-group']}>
            <Tr>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Nazwa
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500} textAlign="center">
                Prowadzący
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((sg, i) => (
              <Row key={i} item={sg} fetch={fetch} />
            ))}
          </Tbody>
        </Table>
      )}
    </Stack>
  )
}

const Row = ({item, fetch}: {item: MySupportGroup; fetch: () => void}) => {
  return !item.id ? null : (
    <Tr fontWeight={300} fontSize="md">
      <Td display={['none', null, 'table-cell']}>{item.name}</Td>
      <Td px={[0, null, 6]}>
        <Box display={['block', null, 'none']} mb={2}>
          {item.name}
        </Box>
        <Stack>
          {item.hosts.map((h, i) => (
            <Button
              key={i}
              variant="brandLink"
              as={Link}
              to={generatePath(PSYCHOTHERAPY_THERAPIST, {id: h.therapist_id, slug: h.slug})}
            >
              {h.full_name}
            </Button>
          ))}
        </Stack>
      </Td>
      <Td textAlign="center" px={[0, null, 6]}>
        <ReviewModalButton
          type="support_group"
          itemID={item.id}
          reviewed={item.reviewed}
          onComplete={fetch}
        />
      </Td>
    </Tr>
  )
}

export default FinishedSupportGroups
