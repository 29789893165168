import React from 'react'

import {DeleteIcon, CloseIcon} from '@chakra-ui/icons'
import {Box, Flex, IconButton, Image, Stack, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

export type RelatedItem = {
  suggestion: string
  blog_post_id: number
  id: number
  name: string
  slug: string
  user_visible: boolean
  signed_img?: string
}

type Props = {
  item: RelatedItem
  to: string
  editing: boolean
  onDelete: (id: string) => void
}
const RelatedBlogPostItem = ({item, to, editing, onDelete}: Props) => {
  // Border is red for not published posts. Only admins can see non published posts.
  const borderColor = React.useMemo(() => (item.user_visible ? undefined : 'red.600'), [item.user_visible])

  const handleDelete = React.useCallback(
    (e) => {
      // Delete button is over clickable component so we need to prevent propagating the click event.
      e.preventDefault()
      e.stopPropagation()
      onDelete(item.suggestion)
    },
    [item.suggestion, onDelete]
  )

  return (
    <Stack
      direction="row"
      spacing={4}
      as={Link}
      to={to}
      alignItems="center"
      p={2}
      rounded={8}
      _hover={{
        background: 'brand.gray.100',
      }}
    >
      <Box w="64px" h="64px" rounded="full" overflow="hidden" flexShrink={0}>
        {item.signed_img ? (
          <Image w="100%" h="100%" src={item.signed_img} objectFit="cover" />
        ) : (
          <Flex w="100%" h="100%" objectFit="cover" bg="gray.600" alignItems="center" justifyContent="center">
            <CloseIcon />
          </Flex>
        )}
      </Box>
      <Text flex={1} fontSize="md" noOfLines={2} fontWeight={300}>
        {item.name}
      </Text>
      {editing && (
        <IconButton
          size="sm"
          colorScheme="red"
          aria-label="Remove item"
          icon={<DeleteIcon />}
          onClick={handleDelete}
          borderRadius="full"
        />
      )}
    </Stack>
  )
}

export default RelatedBlogPostItem
