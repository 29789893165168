import React from 'react'

import {useDisclosure} from '@chakra-ui/react'

import {AdminTherapist} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import TherapistsCertificatesModal from './certificates-modal'
import {columns, defaultSelectedColumns, emptyAdminTherapist, filterFields} from './constants'
import TherapistEditorModal from './editor-modal'
import TherapistSpecialtiesModal from './specialties-modal'
import TherapistTimeSlotsModal from './time-slots-modal'
import {
  certificatesAction,
  showCalendarAction,
  showMeetingsAction,
  showSoldProductsAction,
  specialtiesAction,
} from './utils'

const Therapists = () => {
  const tableState = useTableState({sortBy: 'featured_index'})
  const {isOpen: isEditorOpen, onOpen: onEditorOpen, onClose: onEditorClose} = useDisclosure()
  const {isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose} = useDisclosure()
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminTherapist>({
    table: 'admin_therapists',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminTherapist,
    filter: tableState.filter,
  })

  const {
    value: specialtiesModalValue,
    onOpen: onSpecialtiesModalOpen,
    onClose: onSpecialtiesModalClose,
  } = useValueDisclosure<number>()

  const {
    value: certificatesModalValue,
    onOpen: onCertificatesModalOpen,
    onClose: onCertificatesModalClose,
  } = useValueDisclosure<number>()
  const {
    value: calendarModalValue,
    onOpen: onCalendarModalOpen,
    onClose: onCalendarModalClose,
  } = useValueDisclosure<number>()

  const [currentTherapist, setCurrentTherapist] = React.useState<AdminTherapist>(emptyAdminTherapist)

  const handleDelete = React.useCallback(
    (item: AdminTherapist) => {
      setCurrentTherapist(item)
      onDeleteOpen()
    },
    [setCurrentTherapist, onDeleteOpen]
  )

  const handleEdit = React.useCallback(
    (item: AdminTherapist) => {
      setCurrentTherapist(item)
      onEditorOpen()
    },
    [setCurrentTherapist, onEditorOpen]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentTherapist(emptyAdminTherapist)
    onEditorOpen()
  }, [onEditorOpen])

  const actions = React.useMemo(
    () => [
      editAction(handleEdit),
      specialtiesAction(({id}: AdminTherapist) => id && onSpecialtiesModalOpen(id)),
      certificatesAction(({id}: AdminTherapist) => id && onCertificatesModalOpen(id)),
      showCalendarAction(({id}: AdminTherapist) => id && onCalendarModalOpen(id)),
      showSoldProductsAction,
      showMeetingsAction,
      deleteAction(handleDelete),
    ],
    [handleEdit, handleDelete, onCalendarModalOpen, onCertificatesModalOpen, onSpecialtiesModalOpen]
  )

  return (
    <>
      <DataTable<AdminTherapist>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        filterFields={filterFields}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <TherapistEditorModal
        item={currentTherapist}
        onClose={onEditorClose}
        open={isEditorOpen}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="therapists"
        id={currentTherapist.id}
        onClose={onDeleteClose}
        open={isDeleteOpen}
        onComplete={fetch}
        headerText="Usunąć terapeutę?"
        name={currentTherapist?.full_name}
        onSuccessTitle="Terapeuta został usunięty"
        onFailTitle="Nie udało się usunąć terapeuty"
      />
      <TherapistSpecialtiesModal
        open={!!specialtiesModalValue}
        onClose={onSpecialtiesModalClose}
        therapist={specialtiesModalValue}
        onComplete={fetch}
      />
      <TherapistsCertificatesModal
        open={!!certificatesModalValue}
        onClose={onCertificatesModalClose}
        therapist={certificatesModalValue}
        onComplete={fetch}
      />
      <TherapistTimeSlotsModal
        therapist={calendarModalValue}
        onClose={onCalendarModalClose}
        open={!!calendarModalValue}
      />
    </>
  )
}

export default Therapists
