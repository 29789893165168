import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'

import {OrderDiscountCode, parseSupportGroupJoined} from '@/api/models'
import {DiscountCodeInputProps, OrderAgreement, OrderSummary, SummaryItemProps} from '@/common/order'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatEventTime} from '@/utils/string'

import {
  selectOrderAgreement,
  selectOrderDiscountCode,
  selectOrderSubmitting,
  selectSupportGroup,
  setOrderAgreement,
  setOrderDiscountCode,
} from '../state'
import {doesDiscountCodeApply} from './utils'

const SupportGroupOrderSummary = () => {
  const dispatch = useAppDispatch()

  const groupRaw = useAppSelector(selectSupportGroup)
  const group = React.useMemo(() => groupRaw && parseSupportGroupJoined(groupRaw), [groupRaw])
  const submitting = useAppSelector(selectOrderSubmitting)

  const agreement = useAppSelector(selectOrderAgreement)
  const handleAgreementChange = React.useCallback((v: boolean) => dispatch(setOrderAgreement(v)), [dispatch])

  const summaryItems: SummaryItemProps[] = React.useMemo(
    () =>
      !group
        ? []
        : [
            {
              title: 'Grupa',
              children: group.name,
            },
            ...(group.next_meeting_duration?.from
              ? [
                  {
                    title: 'Najbliższe spotkanie',
                    children: formatEventTime(group.next_meeting_duration.from),
                  },
                ]
              : []),
            {
              title: 'Opiekunowie',
              children: (
                <Stack>
                  {group.hosts.map((h, i) => (
                    <Text key={i}>{h.full_name}</Text>
                  ))}
                </Stack>
              ),
            },
          ],
    [group]
  )

  const discountCode = useAppSelector(selectOrderDiscountCode)
  const discountCodeProps: DiscountCodeInputProps | undefined = React.useMemo(
    () => ({
      value: discountCode,
      onChange: (v?: OrderDiscountCode) => dispatch(setOrderDiscountCode(v)),
      doesApply: (c: OrderDiscountCode) => doesDiscountCodeApply(c, group?.id),
    }),
    [discountCode, group, dispatch]
  )

  return (
    <>
      <OrderSummary
        title="Zapisz się do grupy wsparcia"
        image={group?.thumbnail}
        summaryItems={summaryItems}
        disabled={submitting}
        discountCodeProps={discountCodeProps}
      />
      <OrderAgreement isChecked={agreement} onChange={handleAgreementChange} />
    </>
  )
}

export default SupportGroupOrderSummary
