import React from 'react'

import {ChevronRightIcon} from '@chakra-ui/icons'
import {Button, Collapse, Heading, Stack, useDisclosure} from '@chakra-ui/react'

import JournalPageItem from './page'
import {JournalNode} from './types'

const JournalNodeItem = ({node}: {node: JournalNode}) => {
  const {isOpen, onToggle} = useDisclosure()

  return 'children' in node ? (
    <Stack>
      <Button
        onClick={onToggle}
        variant="ghost"
        rounded="lg"
        justifyContent="flex-start"
        leftIcon={
          <ChevronRightIcon
            boxSize={6}
            transform={isOpen ? 'rotate(90deg)' : undefined}
            transition="all ease 150ms"
          />
        }
      >
        <Heading size="md">{node.title}</Heading>
      </Button>
      <Collapse in={isOpen} animateOpacity={true}>
        <Stack pl={4}>
          {node.children.map((c, i) => (
            <JournalNodeItem key={i} node={c} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  ) : (
    <JournalPageItem page={node} />
  )
}

export default JournalNodeItem
