import React, {useCallback, useRef} from 'react'

import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from '@chakra-ui/react'

import useLoadingState from '@/common/use-loading-state'
import {CourseNode} from '@/courses/types'
import {CommonModalProps} from '@/utils/types'

import {deleteCoursePage, deleteCourseSection} from './utils'

const DeleteMenuNodeDialog = ({
  open,
  onClose,
  onComplete,
  course,
  node,
}: CommonModalProps & {
  course: number
  node: CourseNode | null
}) => {
  const cancelButtonRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const handleDelete = useCallback(async () => {
    try {
      if (!node) {
        throw new Error()
      }

      await (node.type === 'section'
        ? deleteCourseSection(course, node.key.toString())
        : deleteCoursePage(course, node.key.toString()))

      toast({
        description: node.title,
        isClosable: true,
        status: 'success',
        title: 'Usunięto element menu.',
      })
      onComplete && onComplete()
    } catch (e) {
      console.error(`Failed to remove ${node?.title || ''} ${node?.type || 'node'}.`, e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się usunąć elementu menu.',
      })
    } finally {
      onClose()
    }
  }, [course, node, onClose, onComplete, toast])

  const {loading, handleSubmit} = useLoadingState(handleDelete)

  return (
    <AlertDialog isOpen={open} leastDestructiveRef={cancelButtonRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {node?.type === 'page'
              ? 'Usunąć stronę?'
              : node?.type === 'section'
              ? 'Usunąć sekcję?'
              : 'Usunąć element?'}
          </AlertDialogHeader>

          <AlertDialogBody>
            {!!node?.title && (
              <Text fontWeight="bold" mb="2">
                {node.title}
              </Text>
            )}
            Ta operacja jest nieodwracalna.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelButtonRef} onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
            <Button colorScheme="red" onClick={handleSubmit} ml={3} isLoading={loading}>
              Usuń
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}

export default DeleteMenuNodeDialog
