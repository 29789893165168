import * as React from 'react'

import {Heading, Image, Skeleton, SkeletonCircle, SkeletonText, Stack} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'
import ScreenWideBar from '@/common/components/screen-wide-bar'

type Props = {
  image?: string | null
  title?: string
  reviewButton?: React.ReactNode
  button: React.ReactNode
  children?: React.ReactNode
}

export const SpotlightItem = ({image, title, reviewButton, button, children}: Props) => (
  <Stack
    direction={['column', null, 'row']}
    align={['center', null, 'stretch']}
    spacing={[4, null, 8]}
    minH="176px"
  >
    {image && <Image src={image} boxSize="162px" borderRadius="full" objectFit="cover" />}
    <Stack w="100%" align={['center', null, 'flex-start']} justify="flex-end">
      {reviewButton}
      {title && (
        <Heading size="md" textAlign={['center', null, 'left']}>
          {title}
        </Heading>
      )}
      <Stack align="center">{children}</Stack>
      <ScreenWideBar bg="brand.yellow.300" py={6} pr={[0, null, 24]} alignSelf={['center', null, 'flex-end']}>
        {button}
      </ScreenWideBar>
    </Stack>
  </Stack>
)

export const LoadingSpotlightItem = () => (
  <Stack direction={['column', null, 'row']} align={['center', null, 'flex-start']} spacing={[4, null, 8]}>
    <SkeletonCircle boxSize="162px" flexShrink={0} />
    <Stack w="100%" spacing={4} align={['center', null, 'flex-start']}>
      <Skeleton w="400px" h={4} />
      <SkeletonText w="300px" noOfLines={3} spacing="2" />
      <ScreenWideBar bg="brand.yellow.300" py={8} pr={[0, null, 24]} alignSelf={['center', null, 'flex-end']}>
        <ArrowButton isDisabled={true}>Dołącz do spotkania</ArrowButton>
      </ScreenWideBar>
    </Stack>
  </Stack>
)
