import * as React from 'react'

import {ArrowRightIcon} from '@chakra-ui/icons'

import {DataTableAction} from '@/common/data-table/types'

export const openEditorAction = <T extends {}>(onClick: (item: T) => void): DataTableAction<T> => ({
  icon: <ArrowRightIcon />,
  id: 'editor',
  onClick,
  tooltip: 'Otwórz edytor',
})
