import React from 'react'

import {Box, Container, Flex, Grid, GridItem, Text, useToken} from '@chakra-ui/react'
import {Link as RouterLink} from 'react-router-dom'
import {Parallax} from 'react-scroll-parallax'

import {ViewBlogPost} from '@/api/models'
import {ArrowButton} from '@/common/arrow-button'
import useSupabaseQuery from '@/common/use-custom-query'
import {BLOG} from '@/router/paths'

import {BlogPostTile} from './tile'

const Blog = () => {
  const [yellow400] = useToken('colors', ['brand.yellow.400'])
  const {data: blogPosts} = useSupabaseQuery<ViewBlogPost>({
    fields: '*',
    itemsPerPage: 3,
    table: 'blog_posts_view',
    errSnackbarTitle: 'Nie udało się wczytać wyróżnionych postów na blogu',
  })

  return (
    <Box pt={16}>
      <Box position="relative">
        <Box width="100%" height="100%" position="absolute" left="0" top="-5" zIndex={-1}>
          <Parallax
            style={{
              backgroundColor: yellow400,
              height: '25%',
            }}
            translateY={[0, 35]}
          />
        </Box>

        <Container maxW="container.xl" px={[8, null, 4]}>
          <Grid templateColumns="repeat(12, 1fr)" mb={20}>
            <GridItem colSpan={[12, null, 2]}>
              <Text as="h2" fontSize={['xl', null, '3xl']} fontWeight={500} py={10} color="brand.green.800">
                Blog
              </Text>
            </GridItem>

            <GridItem colSpan={[12, null, 10]}>
              <Grid
                templateAreas={['"r" "tl" "bl"', null, '"tl r r r r" "bl r r r r"']}
                templateColumns={['repeat(1, 1fr)', null, 'repeat(5, minmax(0, 1fr))']}
                width="100%"
                gap={8}
              >
                {blogPosts?.map((f, i) => (
                  <GridItem key={i} gridArea={i === 0 ? 'r' : i === 1 ? 'tl' : 'bl'}>
                    <BlogPostTile
                      post={f}
                      featured={i === 0}
                      roundedTopLeft="50px"
                      roundedBottomRight="50px"
                    />
                  </GridItem>
                ))}
              </Grid>
            </GridItem>
          </Grid>

          <Flex direction="row" justifyContent="center" alignItems="center" mb={20}>
            <ArrowButton
              as={RouterLink}
              to={BLOG}
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={48}
            >
              <Text px={8} fontSize="lg">
                Więcej artykułów
              </Text>
            </ArrowButton>
          </Flex>
        </Container>
      </Box>
    </Box>
  )
}

export default Blog
