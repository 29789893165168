import * as React from 'react'

import {Stack} from '@chakra-ui/react'

import WebinarsHistory from './history'
import UpcomingWebinars from './upcoming'

const Webinars = () => (
  <Stack spacing={24} pr={[4, null, 0]} pl={[4, null, 24]} mt={6} position="relative">
    <UpcomingWebinars />
    <WebinarsHistory />
  </Stack>
)

export default Webinars
