import * as React from 'react'

import {
  Box,
  Button,
  Link as ChakraLink,
  Heading,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {MyCourse} from '@/api/models'
import ReviewModalButton from '@/common/reviews/review-modal/button'
import useSupabaseQuery from '@/common/use-custom-query'
import {COURSE_PAGE, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {formatDate} from '@/utils/string'

const FinishedCourses = () => {
  const {data, loading, fetch} = useSupabaseQuery<MyCourse>(
    React.useMemo(
      () => ({
        table: 'my_courses',
        fields: '*',
        order: 'completed_at',
        descending: true,
        errSnackbarTitle: 'Nie udało się pobrać ukończonych warsztatów',
        filter: (b) => b.gte('progress', 100),
      }),
      []
    )
  )

  return (
    <Stack spacing={12}>
      <Heading size="md">Zakończone</Heading>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table>
          <Thead display={['none', null, 'table-header-group']}>
            <Tr>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500} pl={0}>
                Data
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Warsztat
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Psychoterapeuta
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {data?.length ? (
              data?.map((c, i) => <Row key={i} course={c} fetch={fetch} />)
            ) : (
              <Tr>
                <Td colSpan={4}>
                  <Text color="brand.gray.500" fontWeight={300} textAlign="center">
                    Brak zakończonych warsztatów
                  </Text>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      )}
    </Stack>
  )
}

const Row = ({course, fetch}: {course: MyCourse; fetch: () => void}) => (
  <Tr fontWeight={300} fontSize="md">
    <Td pl={0} whiteSpace="nowrap" display={['none', null, 'table-cell']}>
      {formatDate(course.completed_at ?? course.created_at)}
    </Td>
    <Td px={[0, null, 6]}>
      <Text display={['block', null, 'none']} mb={2}>
        {formatDate(course.completed_at ?? course.created_at)}
      </Text>
      {course.nextlvl_url ? (
        <Button
          as={ChakraLink}
          href={course.nextlvl_url}
          target="_blank"
          variant="brandLink"
          whiteSpace="normal"
          mb={[2, null, 0]}
        >
          {course.name}
        </Button>
      ) : (
        <Button
          as={Link}
          to={generatePath(COURSE_PAGE, {
            course: course.id,
          })}
          variant="brandLink"
          whiteSpace="normal"
          mb={[2, null, 0]}
        >
          {course.name}
        </Button>
      )}
      <Box display={['block', null, 'none']}>
        {course.coach ? (
          <Button
            variant="brandLink"
            as={Link}
            to={generatePath(PSYCHOTHERAPY_THERAPIST, {
              id: course.coach.therapist_id,
              slug: course.coach.full_name_slug,
            })}
          >
            {course.coach.full_name}
          </Button>
        ) : (
          'Brak prowadzącego'
        )}
      </Box>
    </Td>
    <Td display={['none', null, 'table-cell']}>
      {course.coach ? (
        <Button
          variant="brandLink"
          as={Link}
          to={generatePath(PSYCHOTHERAPY_THERAPIST, {
            id: course.coach.therapist_id,
            slug: course.coach.full_name_slug,
          })}
        >
          {course.coach.full_name}
        </Button>
      ) : (
        'Brak'
      )}
    </Td>
    <Td textAlign="center">
      <ReviewModalButton reviewed={course.reviewed} type="course" itemID={course.id} onComplete={fetch} />
    </Td>
  </Tr>
)

export default FinishedCourses
