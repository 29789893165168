import * as React from 'react'

import {useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {PGTimeRangeSlotJoined} from '@/api/models'
import {parsePgTimestampRange} from '@/api/utils'

export const useTherapistTimeSlots = (
  therapist: number,
  service: string,
  startDate: Date,
  endDate: Date,
  skip: boolean
) => {
  const toast = useToast()

  const [loading, setLoading] = React.useState(true)
  const [data, setData] = React.useState<PGTimeRangeSlotJoined[]>([])
  const fetch = React.useCallback(
    async (markAsLoading: boolean) => {
      try {
        markAsLoading && setLoading(true)
        console.log(
          `Looking up time slots for therapist ${therapist} between ${startDate.toDateString()} and ${endDate.toDateString()}`
        )

        const {data: result, error} = await supabase.rpc('therapist_available_time_slots', {
          end_date: endDate.toDateString(),
          service_id: service,
          start_date: startDate.toDateString(),
          therapist_id: therapist,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        })
        if (error) throw error
        if (!result) throw new Error('Failed to fetch time slots')

        setData(
          result
            .map((v) => ({
              ...v,
              ...(parsePgTimestampRange(v.slot) as PGTimeRangeSlotJoined),
            }))
            .filter((s) => !!s)
        )
      } catch (e) {
        console.error('Failed to fetch therapist time slots', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać informacji o usługach psychoterapeuty',
        })
      } finally {
        markAsLoading && setLoading(false)
      }
    },
    [therapist, service, startDate, endDate, setData, toast]
  )
  React.useEffect(() => {
    if (skip) {
      return
    }
    fetch(true)
  }, [service, startDate, endDate, skip]) // eslint-disable-line

  return {
    data,
    fetch,
    loading,
  }
}
