import * as React from 'react'

import {Box, Button, Image, Flex, Skeleton, SkeletonText, Stack, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {ViewBlogPost} from '@/api/models'
import {calculateReadTime} from '@/blog/utils'
import {ArrowIcon} from '@/common/arrow-button'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import useFetchSingle from '@/common/use-fetch-single'
import {BLOG, BLOG_POST} from '@/router/paths'

const LatestPost = () => {
  const {data, loading} = useFetchSingle<ViewBlogPost>({
    table: 'blog_posts_view',
    fields: '*',
    order: ['published_at'],
    descending: true,
    errSnackbarTitle: 'Nie udało się wczytać ostatniego posta na blogu.',
  })

  const readTime = React.useMemo(() => calculateReadTime(data?.words_count ?? 0), [data])

  return (
    <Box
      mb={16}
      px={[8, null, 20]}
      pt={16}
      pb={14}
      borderRadius={[null, null, '90px 0px']}
      color="white"
      overflow="hidden"
      position="relative"
    >
      <Box position="absolute" left={0} top={0} width="100%" height="100%" zIndex={-1}>
        <Image src={data?.signed_image ?? ''} objectFit="cover" width="100%" height="100%" />
      </Box>
      <Box
        position="absolute"
        left={0}
        top={0}
        width="100%"
        height="100%"
        bg="brand.green.800"
        opacity={0.67}
        zIndex={-1}
      />

      <Stack>
        <Text fontWeight={300} fontSize="md">
          Najnowszy artykuł
        </Text>
        <Text as="h2" fontWeight={400} fontSize="3xl" pb={8}>
          {loading ? <Skeleton w="100%" height="45px" maxW="300px" /> : data?.name}
        </Text>
        <Stack direction="row" spacing={1} pb={4} fontWeight={300} alignItems="center">
          {loading ? (
            <SkeletonText as="span" width="250px" noOfLines={1} />
          ) : (
            <Text>
              {data?.author} | {readTime} {readTime === 1 ? 'minuta' : 'minut'} czytania
            </Text>
          )}
        </Stack>
        <Box fontWeight={300} maxW="450px" minH="40px">
          {loading ? (
            <SkeletonText as="span" width="100%" noOfLines={3} />
          ) : (
            <ContentEditor
              id={`blog-list-latest-post-description-${data?.id}`}
              variant="basic"
              value={data?.description ?? initialEditorValue}
              readOnly={true}
            />
          )}
        </Box>
        <Flex direction="row" justifyContent="flex-end">
          <Button
            as={Link}
            to={data?.id ? generatePath(BLOG_POST, {id: data?.id, slug: data?.slug}) : BLOG}
            variant="brandLink"
            rightIcon={<ArrowIcon fontSize="3xl" color="brand.yellow.600" />}
            fontWeight={400}
            color="white"
          >
            <Text pr={4}>Czytaj</Text>
          </Button>
        </Flex>
      </Stack>
    </Box>
  )
}

export default LatestPost
