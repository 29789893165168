import React from 'react'

import {supabase} from '@/api'
import {AdminMeeting} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, emptyAdminMeeting, filterFields} from './constants'
import AdminMeetingEditorModal from './editor-modal'

const Meetings = () => {
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminMeeting>({
    table: 'admin_therapy_meetings',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminMeeting,
    filter: tableState.filter,
  })

  const {
    value: deleteValue,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useValueDisclosure<AdminMeeting>()
  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<AdminMeeting>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyAdminMeeting)
  }, [onEditorOpen])

  const handleDelete = React.useCallback(async () => {
    const {error} = await supabase.rpc('delete_meeting', {
      meeting_id: deleteValue?.id,
    })
    if (error) throw error
  }, [deleteValue])

  const actions = React.useMemo(
    () => [editAction(onEditorOpen), deleteAction(onDeleteOpen)],
    [onDeleteOpen, onEditorOpen]
  )

  return (
    <>
      <DataTable<AdminMeeting>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        loading={loading}
        filterFields={filterFields}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <AdminMeetingEditorModal
        item={editorValue}
        onClose={onEditorClose}
        onComplete={fetch}
        open={!!editorValue}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default Meetings
