import {Journal, JournalNode} from './types'

// sort by order and remove nodes with no answers
const cleanupJournalTree = (tree: JournalNode[]) => {
  const cleanTree = tree.filter((b) => !('children' in b))

  for (const b of tree) {
    if (!('children' in b)) continue
    const cleanChildren = cleanupJournalTree(b.children)

    cleanChildren.length && cleanTree.push({...b, children: cleanChildren.sort((a, b) => a.order - b.order)})
  }

  return cleanTree
}

export const buildJournalTree = ({sections, pages}: Journal): JournalNode[] => {
  const tree: JournalNode[] = []
  const sectionsWithSplitPath = sections.map((s) => ({
    ...s,
    splitPath: s.path.split('.'),
  }))

  const fillTreeChildren = (branches: JournalNode[]) => {
    for (const b of branches) {
      if (!('children' in b)) continue

      const branchSubPages = pages.filter(
        (p) => p.section === b.id && p.surveys.some((s) => Object.values(s.answers).some((a) => !!a))
      ) // filter out pages with empty answers

      const branchSubSections = sectionsWithSplitPath
        .filter((s) => s.splitPath[s.splitPath.length - 2]?.replaceAll('_', '-') === b.id)
        .map((s) => ({...s, children: []}))

      b.children = [...branchSubPages, ...branchSubSections]

      fillTreeChildren(b.children)
    }
  }

  // setup root branches
  tree.push(
    ...sectionsWithSplitPath
      .filter((s) => s.splitPath.length === 1)
      .sort((a, b) => a.order - b.order)
      .map((s) => ({
        ...s,
        children: [],
      }))
  )

  fillTreeChildren(tree)

  return cleanupJournalTree(tree)
}
