import React from 'react'

import {DeleteIcon, CloseIcon} from '@chakra-ui/icons'
import {AspectRatio, Box, Flex, IconButton, Image, Text} from '@chakra-ui/react'
import {Link, generatePath} from 'react-router-dom'

import {BLOG_POST} from '@/router/paths'

import {RelatedItem} from './related-item'

const imageHeight = {base: '200', sm: '250', xl: '200'}

const OtherBlogPostItem = ({
  editing,
  item: {id, signed_img, name, user_visible, suggestion, slug},
  onDelete,
}: {
  editing: boolean
  item: RelatedItem
  onDelete: (id: string) => void
}) => {
  const handleDelete = React.useCallback(
    (e) => {
      // Delete button is over clickable component so we need to prevent propagating the click event.
      e.preventDefault()
      e.stopPropagation()
      onDelete(suggestion)
    },
    [onDelete, suggestion]
  )

  return (
    <Flex
      as={Link}
      to={generatePath(BLOG_POST, {id, slug})}
      justifyContent="space-between"
      flexDirection="column"
      overflow="visible"
      bg="base.d100"
      rounded={5}
      flex={1}
      h="100%"
      position="relative"
    >
      {editing && (
        <IconButton
          colorScheme="red"
          aria-label="Remove item"
          icon={<DeleteIcon />}
          position="absolute"
          right="0"
          onClick={handleDelete}
          borderRadius="full"
          transform="translate(35%, -35%)"
          borderStyle="solid"
          borderWidth="2px"
          borderColor="red.600"
          zIndex={1}
        />
      )}
      <AspectRatio
        ratio={1}
        roundedTopStart={['50px', null, '70px']}
        roundedBottomEnd={['50px', null, '70px']}
        overflow="hidden"
      >
        {signed_img ? (
          <Image src={signed_img} h={imageHeight} w="100%" objectFit="cover" />
        ) : (
          <Flex
            h={imageHeight}
            w="100%"
            objectFit="cover"
            bg="gray.600"
            alignItems="center"
            justifyContent="center"
          >
            <CloseIcon size={64} />
          </Flex>
        )}
      </AspectRatio>

      <Box w="100%" py={5} px={3}>
        <Text as="h3" fontWeight="semibold" fontSize="md" textAlign="center" w="full" mb={2} noOfLines={4}>
          {name}
        </Text>
        {!user_visible && (
          <Text textAlign="center" fontSize="sm" color="gray.400">
            (nieopublikowany)
          </Text>
        )}
      </Box>
    </Flex>
  )
}

export default OtherBlogPostItem
