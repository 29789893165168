import React from 'react'

import {Box, HStack, Image, Link, Stack, Text} from '@chakra-ui/react'
import {Link as RouterLink, generatePath} from 'react-router-dom'

import {selectUser} from '@/auth/state'
import LabeledSpinner from '@/common/components/labeled-spinner'
import useFetchSingle from '@/common/use-fetch-single'
import {PSYCHOTHERAPY_THERAPIST} from '@/router/paths'
import {useAppSelector} from '@/store'

import JournalNodeItem from './node'
import {Journal} from './types'
import {buildJournalTree} from './utils'

// CourseJournal renders journal either for the provided or currently authenticated user.
// When no user is provided, then it returns the journal for a current user.
const CourseJournal = ({course, user}: {course: string; user?: string}) => {
  const me = useAppSelector(selectUser)

  const {data, loading} = useFetchSingle<Journal>(
    React.useMemo(
      () => ({
        table: 'course_journal',
        fields: '*',
        match: {course, user: user || me?.id},
      }),
      [course, user, me]
    )
  )

  const nodes = React.useMemo(() => (data ? buildJournalTree(data) : []), [data])

  if (loading) {
    return <LabeledSpinner />
  }

  if (!nodes.length) {
    return <Box>Brak wpisów do dziennika</Box>
  }

  return (
    <Stack spacing={4}>
      {!user && data?.coach && (
        <HStack justify="flex-end">
          {/* TODO: add "plan a meeting" button */}
          <Stack spacing={0} align="flex-end">
            <Text>Twój trener</Text>
            <Link
              as={RouterLink}
              to={generatePath(PSYCHOTHERAPY_THERAPIST, {id: data.coach.therapist_id})}
              color="brand.green.600"
            >
              {data.coach.full_name}
            </Link>
          </Stack>
          {data.coach.signed_avatar && (
            <Image src={data.coach.signed_avatar} rounded="full" boxSize="50px" objectFit="cover" />
          )}
        </HStack>
      )}
      {nodes.map((node, i) => (
        <JournalNodeItem key={i} node={node} />
      ))}
    </Stack>
  )
}

export default CourseJournal
