import {TherapistService} from '@/api/models'
import {emptyPGInterval} from '@/api/types'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'
import {formatDurationShort} from '@/utils/duration'

export const emptyTherapistService: TherapistService = {
  created_at: '',
  id: '',
  meeting_duration: emptyPGInterval,
  name: '',
  order: 0,
  price: 0,
  updated_at: '',
}

export const defaultSelectedColumns = ['name', 'order', 'price', 'meeting_duration']

export const columns: Column<TherapistService>[] = [
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Numer porządkowy',
    id: 'order',
  },
  {
    Header: 'Cena (PLN)',
    id: 'price',
  },
  {
    Header: 'Długość spotkania',
    id: 'meeting_duration',
    renderer: ({meeting_duration}) => formatDurationShort(meeting_duration),
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Numer porządkowy',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
