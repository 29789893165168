import React, {useMemo} from 'react'

import {CloseIcon, HamburgerIcon} from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Button,
  Container,
  Flex,
  HStack,
  IconButton,
  Image,
  Spinner,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {FaSignInAlt} from 'react-icons/fa'
import {matchPath} from 'react-router'
import {Link as RouterLink, useLocation} from 'react-router-dom'

import {selectProfile, selectProfileLoading, setModalState} from '@/auth/state'
import {ArrowButton} from '@/common/arrow-button'
import defaultAvatar from '@/common/icons/default-avatar.svg'
import logoImage from '@/common/logo/logo.svg'
import textImage from '@/common/logo/text.svg'
import {SUPPORT_GROUPS_FEATURE} from '@/constants'
import HotlineBar from '@/layout/navbar/hotline-bar'
import {
  ABOUT_US,
  BLOG,
  CONTACT_US,
  COURSES,
  FAQ,
  HOME,
  JOIN_US,
  MEETING,
  PSYCHOTHERAPY,
  SUPPORT_GROUPS,
  WEBINAR_VIEW,
} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'
import {USER_PROFILE} from '@/user/router'

import NavLink, {NavLinkProps} from './nav-link'

const buttonDisplay = {md: 'none'}
const navbarLinksDisplay = {base: 'none', md: 'flex'}
const dropdownMenuDisplay = {md: 'none'}

const smallNavbarPaths = [MEETING, WEBINAR_VIEW]

const Navbar = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectProfile)
  const profileLoading = useAppSelector(selectProfileLoading)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {pathname} = useLocation()

  const [full, setFull] = React.useState(true)

  React.useEffect(() => {
    setFull(
      !smallNavbarPaths.some(
        (path) =>
          !!matchPath(pathname, {
            path,
            exact: true,
            strict: true,
          })?.isExact
      )
    )
  }, [pathname])

  const signIn = React.useCallback(() => {
    dispatch(
      setModalState({
        redirectPath: pathname,
        state: 'signin',
      })
    )
  }, [pathname, dispatch])

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'desktop',
  })

  const menuButtonHover = {
    bg: 'gray.200',
    textDecoration: 'none',
  }

  const links: NavLinkProps[] = useMemo(
    () => [
      {
        label: 'O nas',
        menuChildren: [
          {
            absolutePath: ABOUT_US,
            label: 'O Psychoformie',
          },
          {
            absolutePath: FAQ,
            label: 'Najczęstsze pytania',
          },
          {
            absolutePath: JOIN_US,
            label: 'Dołącz do nas',
          },
          {
            absolutePath: CONTACT_US,
            label: 'Kontakt',
          },
        ],
      },
      {
        absolutePath: COURSES,
        label: 'Warsztaty',
      },
      {
        absolutePath: PSYCHOTHERAPY,
        label: 'Psychoterapia',
      },
      ...(SUPPORT_GROUPS_FEATURE === 'true'
        ? [
            {
              absolutePath: SUPPORT_GROUPS,
              label: 'Grupy wsparcia',
            },
          ]
        : []),
      {
        absolutePath: BLOG,
        label: 'Blog',
      },
    ],
    []
  )

  React.useEffect(() => {
    onClose()
  }, [pathname, onClose])

  return (
    <>
      {full && <HotlineBar />}
      <Box
        bg="white"
        position="sticky"
        top={0}
        w="100%"
        id="navbar"
        zIndex="banner"
        boxShadow="0px 4px 20px rgba(0, 0, 0, 0.1)"
      >
        <Container maxW="container.xl">
          <Flex h={full ? '100px' : '64px'} alignItems="center">
            <HStack spacing={8} pr={4} flexGrow={1}>
              <Box as={RouterLink} to={HOME}>
                <Flex wrap="nowrap">
                  <Image src={logoImage} mr={4} h={full ? '68px' : '48px'} />
                  {full && <Image src={textImage} alt="Psychoforma" />}
                </Flex>
              </Box>
              <HStack
                as="nav"
                spacing={4}
                display={navbarLinksDisplay}
                justifyContent="flex-start"
                flexGrow={1}
              >
                {links.map((props, i) => (
                  <NavLink key={i} {...props} />
                ))}
              </HStack>
            </HStack>
            <HStack spacing={[2, null, 6]} alignItems="center">
              {variant === 'desktop' ? (
                profile ? (
                  <ArrowButton
                    as={RouterLink}
                    to={USER_PROFILE}
                    bg="brand.yellow.600"
                    hoverBg="brand.yellow.700"
                    color="white"
                  >
                    Twoje konto
                  </ArrowButton>
                ) : (
                  <ArrowButton
                    bg="brand.yellow.600"
                    hoverBg="brand.yellow.700"
                    color="white"
                    onClick={signIn}
                  >
                    Zaloguj się
                  </ArrowButton>
                )
              ) : (
                !profile && (
                  <IconButton
                    isRound={true}
                    size="md"
                    variant="brandLink"
                    cursor="pointer"
                    p={2}
                    color="white"
                    _hover={menuButtonHover}
                    icon={<FaSignInAlt />}
                    aria-label="Zaloguj się"
                    onClick={signIn}
                    bg="brand.yellow.600"
                    boxSize={10}
                  />
                )
              )}
              {!!profile && (
                <Button
                  as={RouterLink}
                  to={USER_PROFILE}
                  aria-label="Profil użytkownika"
                  borderRadius="full"
                  size="md"
                  variant="brandLink"
                  cursor="pointer"
                  p={2}
                  color="none"
                  _hover={menuButtonHover}
                >
                  <Avatar
                    bg="brand.green.600"
                    icon={!profileLoading ? <Image src={defaultAvatar} /> : <Spinner color="white" />}
                    src={!profileLoading ? profile.avatar || undefined : undefined}
                    boxSize={['40px', null, '48px']}
                    overflow="hidden"
                  />
                </Button>
              )}
              <IconButton
                size="md"
                icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                aria-label="Otwórz menu"
                display={buttonDisplay}
                onClick={isOpen ? onClose : onOpen}
              />
            </HStack>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={dropdownMenuDisplay}>
              <Stack as="nav" spacing={4}>
                {links.map((props, i) => (
                  <NavLink key={i} {...props} />
                ))}
              </Stack>
            </Box>
          ) : null}
        </Container>
      </Box>
    </>
  )
}

export default Navbar
