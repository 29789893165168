import React from 'react'

import {Input, InputProps} from '@chakra-ui/react'
import {pl} from 'date-fns/locale'
import DatePicker from 'react-datepicker'

import {polishTimestampFormat} from '@/utils/string'

import './styles.css'

const DefaultDatePickerInput = React.forwardRef((props: InputProps, ref: React.Ref<HTMLInputElement>) => (
  <Input ref={ref} {...props} />
))

const DatetimeInput = ({
  defaultValue,
  onChange,
  value,
  isClearable,
  showTimeSelect = true,
  ...props
}: Omit<InputProps, 'onChange' | 'defaultValue' | 'value'> & {
  value?: Date | null
  defaultValue?: Date | null
  showTimeSelect?: boolean
  isClearable?: boolean
  onChange?: (datetime: Date | null) => void
}) => {
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(defaultValue || null)

  const handleDateChange = React.useCallback(
    (date: Date | null) => {
      setSelectedDate(date)
      onChange && onChange(date)
    },
    [onChange]
  )

  const inputComponent = React.useMemo(() => <DefaultDatePickerInput {...props} />, [props])

  const valueDate = React.useMemo(() => (value ? new Date(value) : null), [value])

  return (
    <DatePicker
      selected={valueDate || selectedDate}
      onChange={handleDateChange}
      dateFormat={polishTimestampFormat}
      customInput={inputComponent}
      showTimeSelect={showTimeSelect}
      locale={pl}
      isClearable={isClearable}
      timeCaption="Czas"
      popperClassName="date-picker-popper"
      disabled={props.isDisabled}
    />
  )
}

export default DatetimeInput
