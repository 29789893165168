import {add} from 'date-fns'

import {SupportGroupMeeting} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptySupportGroupMeeting: SupportGroupMeeting = {
  id: '',
  support_group: 0,
  name: '',
  description: '',
  starts_at: new Date(),
  ends_at: add(new Date(), {hours: 2}),
}

export const defaultSelectedColumns = ['name', 'description', 'starts_at', 'ends_at']

export const columns: Column<SupportGroupMeeting>[] = [
  {
    Header: 'ID',
    id: 'meeting',
    renderAs: 'code',
  },
  {
    Header: 'Nazwa',
    id: 'name',
  },
  {
    Header: 'Opis',
    id: 'description',
    renderAs: 'longString',
  },
  {
    Header: 'Start',
    id: 'starts_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Koniec',
    id: 'ends_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'meeting',
    variant: 'text',
  },
  {
    label: 'Nazwa',
    name: 'name',
    variant: 'text',
  },
  {
    label: 'Opis',
    name: 'description',
    variant: 'text',
  },
  {
    label: 'Start',
    name: 'starts_at',
    variant: 'datetime',
  },
  {
    label: 'Koniec',
    name: 'ends_at',
    variant: 'datetime',
  },
]
