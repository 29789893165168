import React from 'react'

import {Box, Flex, IconButton, Image, Spinner, Tooltip, useToast} from '@chakra-ui/react'
import {createPluginFactory, PlateRenderElementProps, setNodes} from '@udecode/plate'
import {insertNodes, PlateEditor, usePlateEditorRef} from '@udecode/plate-core'
import randomstring from 'randomstring'
import {Resizable} from 're-resizable'
import {MdClose, MdImage} from 'react-icons/md'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {CommonScope, ImageElement} from '../custom-types'
import {EditorContext} from '../editor-context'
import selectFile, {SelectionCancelledError} from '../select-file'
import {BlockButton} from '../toolbar/buttons'

const handleAfter = {
  backgroundColor: 'gray.400',
  borderRadius: '6px',
  content: '" "',
  height: '64px',
  width: '3px',
}

const handleHover = {
  _after: {
    backgroundColor: 'blue.500',
  },
}

const handleFocus = {
  _after: {
    backgroundColor: 'blue.500',
  },
}

const handleActive = {
  _after: {
    backgroundColor: 'blue.500',
  },
}

const ImageHandle = ({position}: {position: 'left' | 'right'}) => {
  const right = React.useMemo(() => (position === 'right' ? '-1' : undefined), [position])
  const marginRight = React.useMemo(() => (position === 'right' ? '-1' : undefined), [position])
  const paddingRight = React.useMemo(() => (position === 'right' ? '1' : undefined), [position])
  const alignItems = React.useMemo(() => (position === 'right' ? 'flex-end' : undefined), [position])

  const left = React.useMemo(() => (position === 'left' ? '-1' : undefined), [position])
  const marginLeft = React.useMemo(() => (position === 'left' ? '-1' : undefined), [position])
  const paddingLeft = React.useMemo(() => (position === 'left' ? '1' : undefined), [position])

  return (
    <Flex
      right={right}
      marginRight={marginRight}
      paddingRight={paddingRight}
      alignItems={alignItems}
      left={left}
      marginLeft={marginLeft}
      paddingLeft={paddingLeft}
      flexDir="column"
      justifyContent="center"
      position="absolute"
      userSelect="none"
      w="6"
      h="full"
      top="0"
      zIndex="10"
      _after={handleAfter}
      _hover={handleHover}
      _focus={handleFocus}
      _active={handleActive}
    />
  )
}

const resizeHandleComponent = {
  left: <ImageHandle position="left" />,
  right: <ImageHandle position="right" />,
}

export const ImageRenderer = ({attributes, children, element}: PlateRenderElementProps) => {
  const {bucketName, readOnly} = React.useContext(EditorContext)

  const toast = useToast()
  const editor = usePlateEditorRef()!
  const path = ReactEditor.findPath(editor, element)

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: path})
  }, [editor, path])

  const el = element as ImageElement

  const resizeEnabled = React.useMemo(() => (readOnly ? {} : {left: true, right: true}), [readOnly])

  const setNodeWidth = React.useCallback(
    (w: number) => {
      const path = ReactEditor.findPath(editor, element)
      setNodes(editor, {width: w}, {at: path})
    },
    [editor, element]
  )

  const handleResize = React.useCallback(
    (e, direction, {offsetWidth}) => setNodeWidth(offsetWidth),
    [setNodeWidth]
  )

  return (
    <Flex position="relative" mb={4} {...attributes} contentEditable={false} userSelect="none">
      {children}
      <Box w="100%" margin="auto">
        {el.url ? (
          <Flex maxW="100%" justifyContent="center">
            <Resizable
              maxWidth="100%"
              minWidth="200px"
              enable={resizeEnabled}
              resizeRatio={2}
              onResize={handleResize}
              handleComponent={resizeHandleComponent}
            >
              <Image src={el.url} w={el.width} maxW="100%" zIndex={1} />
            </Resizable>
          </Flex>
        ) : (
          <Spinner size="xl" />
        )}
        {!readOnly && (
          <IconButton
            variant="outline"
            onClick={handleRemove}
            icon={<MdClose />}
            aria-label="Usuń obraz"
            position="absolute"
            top="5px"
            right="5px"
            zIndex={2}
          />
        )}
      </Box>
    </Flex>
  )
}

export const InsertImageButton = ({
  bucketName,
  bucketScope,
}: {
  bucketName: string
  bucketScope: string | number
}) => {
  const toast = useToast()

  const editor = usePlateEditorRef()!

  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()

      try {
        const files = await selectFile({accept: 'image/*'})
        if (files.length !== 1) {
          throw new Error('Invalid files count')
        }

        const file = files[0]
        const [path, signedURL] = await uploadImage(bucketName, bucketScope, file)

        insertImage(editor, path, signedURL)
      } catch (e) {
        console.error('Failed to insert image', e)
        if (!(e instanceof SelectionCancelledError)) {
          toast({
            isClosable: true,
            status: 'error',
            title: 'Nie udało się wstawić obrazu.',
          })
        }
      }
    },
    [bucketName, bucketScope, editor, toast]
  )

  return <BlockButton format="image" label="Dodaj obraz" icon={<MdImage />} onClick={handleClick} />
}

const uploadImage = async (bucket: string, scope: string | number, file: File) => {
  const filename = `${uuidv4()}.${file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1)}`
  const filepath = `/${scope}/${filename}`

  const {error} = await supabase.storage.from(bucket).upload(filepath.substr(1), file, {upsert: true})
  if (error) {
    throw error
  }

  if (!filepath) {
    throw new Error('Path is empty')
  }

  const {data, error: signedURLError} = await supabase.storage
    .from(bucket)
    .createSignedUrl(filepath.substr(1), 86400) // 24 hours
  if (signedURLError) {
    throw signedURLError
  }
  if (!data || !data.signedUrl) {
    throw new Error('Empty response')
  }

  return [filepath, data.signedUrl]
}

const insertImage = (editor: PlateEditor, path: string, url: string) => {
  const text = {text: ''}
  const image: ImageElement = {
    children: [text],
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    path,
    type: 'image',
    url,
  }
  insertNodes<ImageElement>(editor, image)
}

export const createImagePlugin = createPluginFactory({
  component: ImageRenderer,
  isElement: true,
  isVoid: true,
  key: 'image',
  type: 'image',
  withOverrides: (editor, plugin) => {
    const {bucketName, bucketScope} = plugin.options as CommonScope

    const {insertData} = editor
    editor.insertData = (dataTransfer: DataTransfer) => {
      const {files} = dataTransfer
      if (files && files.length > 0 && [...files].every((f) => f.type.startsWith('image/'))) {
        Promise.all([...files].map((file) => uploadImage(bucketName, bucketScope, file))).then((response) => {
          response.forEach(([path, signedURL]) => insertImage(editor, path, signedURL))
        })
      } else {
        insertData(dataTransfer)
      }
    }

    return editor
  },
})
