import {createClient} from '@supabase/supabase-js'

import {REACT_APP_SUPABASE_KEY, REACT_APP_SUPABASE_URL} from './constants'
import {Database} from './database.types'

const maxRetries = 5
const fetchTimeout = 3000
const nonMutatingMethods = ['GET', 'HEAD', 'OPTIONS', 'TRACE']

// improves ux in case of poor internet connection
const customFetch = async (input: RequestInfo | URL, init?: RequestInit): Promise<Response> => {
  let retries = 0

  while (retries < maxRetries) {
    try {
      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), fetchTimeout)
      // ensure abort signals passed in supabase.abortSignal are not overwritten
      if (init?.signal) {
        init.signal.aborted
          ? controller.abort()
          : init.signal.addEventListener('abort', () => controller.abort())
      }

      const response = await fetch(input, {...init, signal: controller.signal})
      clearTimeout(timeoutId)

      return response
    } catch (err) {
      const error = err as any as Error
      const isNonMutating = nonMutatingMethods.includes(init?.method?.toUpperCase() || '')
      // only retry if err was caused by timeout
      if (isNonMutating && error.name === 'AbortError' && !init?.signal?.aborted) {
        retries++
        continue
      }

      throw err
    }
  }

  throw new Error('Max retries exceeded')
}

export const supabase = createClient<Database>(REACT_APP_SUPABASE_URL, REACT_APP_SUPABASE_KEY, {
  global: {
    fetch: customFetch,
  },
})
