import React from 'react'

import {
  Box,
  Container,
  Heading,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react'

const TermsAndConditions = () => {
  return (
    <Container maxW="container.xl" py={8}>
      <Heading as="h1" mb={4}>
        Polityka prywatności
      </Heading>
      <Stack spacing={2} pt={8} textAlign="justify">
        <Text>
          Niniejsza polityka prywatności określa zasady przetwarzania i ochrony danych osobowych pozyskanych
          za pośrednictwem serwisu internetowego funkcjonującego pod adresem psychoforma.pl, zwanego dalej
          „Serwisem internetowym”.
        </Text>
        <Text>
          Dokładamy wszelkich starań, aby przetwarzanie danych było zgodne z obowiązującymi przepisami prawa,
          w szczególności z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z 27 kwietnia 2016
          r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
          swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o
          ochronie danych), zwanym dalej: „RODO”.
        </Text>
        <Text>
          Oficjalny tekst Rozporządzenia jest dostępny pod adresem:{' '}
          <Link href="https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679" target="_blank">
            https://eur-lex.europa.eu/legal-content/PL/TXT/?uri=CELEX%3A32016R0679
          </Link>
          .
        </Text>

        <Text fontWeight={600} pt={8}>
          Administrator Danych Osobowych
        </Text>
        <Text>
          Administratorem danych osobowych zbieranych za pośrednictwem Serwisu internetowego jest Izabela
          Olszewska prowadząca działalność gospodarczą pod firmą Meblissima Izabela Olszewska z siedzibą w
          Łodzi 91–510 przy ul. Skowrończej 36, posiadająca NIP 7261682249, dalej: „Administrator”.
        </Text>
        <Text>
          W sprawach związanych z ochroną danych osobowych, można się skontaktować z Administratorem pisemnie
          pod adresem Meblissima Izabela Olszewska ul. Rewolucji 9 lok.34, 90-273 Łódź lub wysyłając e-mail na
          adres: kontakt@psychoforma.pl
        </Text>

        <Text fontWeight={600} pt={8}>
          Cel i podstawa przetwarzania danych osobowych
        </Text>
        <Text>
          Dane osobowe pozyskane za pośrednictwem Serwisu internetowego przetwarzamy w następujących celach:
        </Text>
        <UnorderedList pl={6}>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                rejestracji konta użytkownika.
              </Text>{' '}
              W trakcie rejestracji konta użytkownik proszony jest o podanie danych niezbędnych do utworzenia
              i obsługi konta, tj. imię, nazwisko oraz adres e-mail.
            </Box>
            <Box>
              W momencie utworzenia konta użytkownika, dochodzi do zawarcia umowy o świadczenie usług drogą
              elektroniczną. Umowa ta stanowi podstawę prawną dla przetwarzania danych osobowych zgodnie z
              art. 6 ust. 1 lit. b RODO;
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                rejestracja konta przez Specjalistę (psychoterapeutę).
              </Text>{' '}
              W trakcie rejestracji konta Specjalista proszony jest o podanie danych niezbędnych do utworzenia
              i obsługi konta, tj. imię, nazwisko oraz adres e-mail.
            </Box>
            <Box>
              W momencie utworzenia konta użytkownika, dochodzi do zawarcia umowy o świadczenie usług drogą
              elektroniczną. Umowa ta stanowi podstawę prawną dla przetwarzania danych osobowych zgodnie z
              art. 6 ust. 1 lit. b RODO;
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                umówienia konsultacji ze Specjalistą (zawarcie i wykonanie umowy o świadczenie usług drogą
                elektroniczną).
              </Text>{' '}
              W procesie umawiania konsultacji ze Specjalistą prosimy o wypełnienie ankiety, na podstawie
              której użytkownik zostanie przydzielony do określonej Grupy Wsparcia.
            </Box>
            <Box mb={2}>
              Zakres przekazanych danych osobowych może obejmować: imię i nazwisko, adres mailowy, telefon
              kontaktowy.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. b RODO, tj. zawarcie i wykonanie
              Umowy oraz na art. 9 ust. 1 lit. a) RODO, tj. na zgody osoby, której dane dotyczą.
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                obsługi kontaktu telefonicznego.
              </Text>{' '}
              W przypadku kontaktowania się z Administratorem drogą telefoniczną, w sprawach niezwiązanych z
              zawartą umową lub świadczonymi usługami, możemy żądać podania danych osobowych tylko wówczas,
              gdy będzie to niezbędne do obsługi sprawy, której dotyczy kontakt.
            </Box>
            <Box>
              Podstawą prawną jest w takim wypadku uzasadniony interes Administratora (art. 6 ust. 1 lit f
              RODO), polegający na konieczności załatwienia zgłoszonej sprawy związanej z prowadzoną
              działalnością gospodarczą.
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                wykonania umowy o przeprowadzenie warsztatu.
              </Text>{' '}
              Zakres przetwarzanych danych obejmuje: imię i nazwisko, adres rozliczeniowy, adres do wysyłki.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. b) RODO, który pozwala przetwarzać
              dane osobowe, jeżeli są one konieczne do wykonania umowy lub podjęcia czynności zmierzających do
              zawarcia umowy. W zakresie danych podanych fakultatywnie podstawą prawną przetwarzania art. 6
              ust. 1 lit a) RODO, który pozwala przetwarzać dane osobowe, na podstawie dobrowolnie udzielonej
              zgody;
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                wykonania umowy sprzedaży,
              </Text>{' '}
              w tym dostarczenia zakupionego produktu lub podjęcia czynności zmierzających do zawarcia umowy.
              Zakres przetwarzanych danych obejmuje: imię i nazwisko, adres rozliczeniowy, adres do wysyłki.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. b) RODO, który pozwala przetwarzać
              dane osobowe, jeżeli są one konieczne do wykonania umowy lub podjęcia czynności zmierzających do
              zawarcia umowy. W zakresie danych podanych fakultatywnie podstawą prawną przetwarzania art. 6
              ust. 1 lit a) RODO, który pozwala przetwarzać dane osobowe, na podstawie dobrowolnie udzielonej
              zgody.
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                realizacji obowiązków wynikających z przepisów prawa podatkowego,
              </Text>{' '}
              w tym wystawiania faktur VAT i dokonywania rozliczeń podatkowych. Zakres przetwarzanych danych
              obejmuje: imię i nazwisko, adres zamieszkania, w odniesieniu do przedsiębiorcy - firmę, adres
              prowadzenia działalności/siedziby, NIP.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit c) RODO, (który pozwala przetwarzać
              dane osobowe, jeżeli takie przetwarzanie jest konieczne do wywiązania się przez Administratora z
              obowiązków wynikających z przepisów prawa), oraz art. 32 ust. 1, art. 86 ust. 1, art. 88 ust. 1
              Ordynacji podatkowej, art. 74 ustawy o rachunkowości;
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                umożliwienia przeglądania strony internetowej i zapewnienia prawidłowego funkcjonowania
                Serwisu.
              </Text>{' '}
              Użytkownik może przeglądać stronę internetową bez konieczności podawania swoich danych
              osobowych, jednakże podczas przeglądania strony automatycznie zbierane są dane takie jak: adres
              IP urządzenia, z którego użytkownik korzysta, nazwa domeny, dane dotyczące sesji urządzenia, typ
              przeglądarki, typ systemu operacyjnego. Dane te mogą być zbierane przez pliki cookie
              („ciasteczka”) oraz system Google Analytics.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. f) RODO, który pozwala przetwarzać
              dane osobowe, gdy przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
              interesów Administratora, polegających w tym wypadku na prowadzeniu i utrzymaniu Serwisu
              internetowego oraz zagwarantowaniu niezakłóconego połączenia i korzystania z Serwisu;
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                obsługi procesu reklamacji.
              </Text>{' '}
              Użytkownik ma możliwość złożenia reklamacji związanej z funkcjonowaniem Serwisu internetowego
              lub oferowanych usług, za pośrednictwem elektronicznego formularza. Zakres przetwarzanych danych
              obejmuje dane zawarte w reklamacji, w tym w szczególności: imię i nazwisko, adres e-mail, adres
              do korespondencji, dane wskazujące na przyczynę reklamacji oraz dane zawarte w dokumentach
              załączonych do formularza reklamacji.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit c) RODO, (który pozwala przetwarzać
              dane osobowe, jeżeli takie przetwarzanie jest konieczne do wywiązania się przez Administratora z
              obowiązków wynikających z przepisów prawa) oraz art. 560 k.c.; oraz realizacja uzasadnionego
              interesu Administratora polegającego na ustaleniu, dochodzeniu i egzekucji roszczeń oraz obrony
              przed roszczeniami, wynikającymi z prowadzonej przez Administratora działalności gospodarczej, w
              trybie art. 6 ust. 1 lit. f) RODO.
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                realizowania działań marketingowych.
              </Text>{' '}
              Działania marketingowe mogą polegać w szczególności na wyświetlaniu użytkownikowi treści
              marketingowych, w tym reklam dostosowanych do jego preferencji lub potencjalnych zainteresowań.
              Zakres przetwarzanych danych obejmuje dane osobowe podane przy utworzeniu konta użytkownika i
              jego aktualizacji, dane dotyczące aktywności użytkownika w Serwisie internetowym, które są
              rejestrowane i przechowywane za pośrednictwem plików cookie, a w szczególności historia
              wyszukiwań, daty logowania i rejestracji, historia i aktywność użytkownika związana z kontaktem
              z Administratorem.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. a) RODO, tj. zgoda wyrażona przez
              użytkownika na przechowywanie i dostęp do plików cookie na jego urządzeniu końcowym (komputerze,
              telefonie lub innym urządzeniu, z którego dokonano połączenia);
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                wysyłania newslettera.
              </Text>{' '}
              W przypadku wyrażenia przez użytkownika zgody na otrzymywanie informacji handlowych, będzie on
              otrzymywał drogą elektroniczną bezpłatny newsletter. Zakres przetwarzanych danych obejmuje adres
              e-mail.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych w ramach wysyłania newslettera jest art. 6 ust. 1 lit. a)
              RODO, który pozwala przetwarzać dane osobowe, gdy osoba, której dane dotyczą wyraziła zgodę na
              przetwarzanie danych w określonym celu. W przypadku woli zrezygnowania z otrzymywania
              newslettera, użytkownik może w dowolnym momencie wycofać wyrażoną zgodę ze skutkiem na
              przyszłość.
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                prowadzenia statystyk i monitorowania aktywności w Serwisie internetowym,
              </Text>{' '}
              tj. podejmowania działań zmierzających do poprawy funkcjonowania Serwisu internetowego oraz
              jakości usług oferowanych przez Administratora. Zakres przetwarzanych danych obejmuje: adres IP
              urządzenia, z którego użytkownik korzysta, nazwę domeny, dane o lokalizacji, dane dotyczące
              sesji urządzenia, typu przeglądarki, typu systemu operacyjnego, a także dane osobowe dotyczące
              aktywności użytkownika w Serwisie internetowym, tzn. informacja czy użytkownik jest nowy czy
              powracający, rozdzielczość natywna urządzenia, dane o źródle, z którego użytkownik pozyskał
              informację o Serwisie internetowym, czas spędzony przez użytkownika na stronie. Dane te mogą być
              zbierane przez pliki cookie („ciasteczka”) oraz system Google Analytics.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. f) RODO, który pozwala przetwarzać
              dane osobowe, gdy przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
              interesów Administratora, polegających w tym wypadku na prowadzeniu analiz zachowań oraz
              aktywności użytkowników, a także ich preferencji, w celu poprawy jakości funkcjonowania Serwisu
              internetowego i świadczonych usług;
            </Box>
          </ListItem>
          <ListItem>
            <Box mb={2}>
              <Text as="span" fontWeight={600}>
                ochrony prawnie uzasadnionego interesu Administratora,
              </Text>{' '}
              w tym ustalenia, dochodzenia i egzekucji roszczeń oraz obrony przed roszczeniami w postępowaniu
              sądowym i przed innymi organami. Zakres przetwarzanych danych obejmuje dane osobowe niezbędne do
              udowodnienia istnienia roszczenia lub obrony przed roszczeniami, które wynikają z przepisów
              prawa.
            </Box>
            <Box>
              Podstawą prawną przetwarzania danych jest art. 6 ust. 1 lit. f) RODO, który pozwala przetwarzać
              dane osobowe, gdy przetwarzanie jest niezbędne do celów wynikających z prawnie uzasadnionych
              interesów Administratora, polegających w tym wypadku na dochodzeniu roszczeń przez
              Administratora lub obronie roszczeniami kierowanymi wobec niego.
            </Box>
          </ListItem>
        </UnorderedList>

        <Text fontWeight={600} pt={8}>
          Dane dotyczące zdrowia
        </Text>
        <Text>
          W przypadku umawiania konsultacji ze Specjalistą (psychoterapeutą) za pośrednictwem Serwisu
          internetowego, możemy przetwarzać (w tym zbierać, przechowywać lub udostępniać Specjaliście) dane
          osobowe, które mogą zostać uznane za dane dotyczące zdrowia. W szczególności informacje o przyczynie
          konsultacji, historii umawianych konsultacji lub dane przekazywane Specjaliście w ankiecie
          wypełnianej przed konsultacją.
        </Text>
        <Text>
          Z powyższych względów, użytkownicy będą proszeni o wyrażenie wyraźnej zgody na przetwarzanie danych
          dotyczących zdrowia (stosownie do treści art. 9 ust. 2 lit. a RODO). Zgoda, o której mowa powyżej
          będzie stanowiła podstawę przetwarzania tych danych.
        </Text>

        <Text fontWeight={600} pt={8}>
          Odbiorcy/kategorie odbiorców danych osobowych
        </Text>
        <Text>Dane osobowe przetwarzane przez Administratora mogą zostać udostępnione:</Text>
        <OrderedList pl={6}>
          <ListItem>
            <Box mb={1}>
              dostawcom usług, z którymi Administrator współpracuje przy prowadzeniu Serwisu internetowego, w
              tym w celu realizacji i obsługi zamówień, tj.:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>
                podmiotowi obsługującemu płatność elektroniczną lub kartą płatniczą – dane osobowe
                przekazywane są w zakresie niezbędnym do zrealizowania płatności,
              </ListItem>
              <ListItem>
                firmom kurierskim – dane osobowe przekazywane są w zakresie niezbędnym do zrealizowania
                dostawy zamówionego produktu,
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            EuroAcademy – imię, nazwisko oraz adres e-mail użytkownika przekazywane po wykupieniu warsztatu
            YouDynamics,
          </ListItem>
          <ListItem>
            podmiotom zajmującym się hostingiem (przechowywaniem) strony oraz danych osobowych dla nas,
            podmiotom zaopatrującym Administratora w rozwiązania techniczne oraz świadczącym usługi
            informatyczne, dostarczającym oprogramowanie komputerowe do prowadzenia Serwisu internetowego,
            obsługującym systemy teleinformatyczne, dostawcom narzędzi służących do analityki działania strony
            lub udostępniającym narzędzia teleinformatyczne do obsługi warsztatu, w tym platformy
            informatyczne, m.in. Google LLC, 8x8 Inc. – Administrator udostępnia tym podmiotom dane osobowe, w
            zakresie niezbędnym do zrealizowania jednego z ww. celów przetwarzania danych;
          </ListItem>
          <ListItem>
            agencjom marketingowym odpowiedzialnym za promocję produktów i akcje marketingowe Serwisu
            internetowego;
          </ListItem>
          <ListItem>
            podmiotowi świadczącemu usługi księgowe – w zakresie niezbędnym do prawidłowego wywiązania się
            przez Administratora z ciążących na nim obowiązków prawnych, w tym obowiązków podatkowych;
          </ListItem>
          <ListItem>
            podmiotom i organom (np. sądom, prokuraturze, policji), którym Administrator jest zobowiązany lub
            upoważniony udostępnić dane osobowe na podstawie powszechnie obowiązujących przepisów prawa.
          </ListItem>
        </OrderedList>

        <Text fontWeight={600} pt={8}>
          Przekazywanie danych do państwa trzeciego
        </Text>
        <Text>
          Z uwagi na współpracę dostawców niektórych usług, z których korzysta Administrator, w tym mi.in. 8x8
          Inc., Facebook Ireland Ltd oraz Google LLC z partnerami i podmiotami na całym świecie, dane mogą być
          przekazywane do Stanów Zjednoczonych i innych państw spoza Europejskiego Obszaru Gospodarczego
          (dalej EOG). Operatorzy i dostawcy narzędzi, z których korzysta Administrator deklarują, że w
          zakresie przekazywania danych do Stanów Zjednoczonych i pozostałych krajów spoza EOG wykorzystują
          standardowe klauzule umowne zatwierdzone przez Komisję Europejską, a w stosownych przypadkach
          opierają się na decyzjach Komisji Europejskiej stwierdzających odpowiedni stopień ochrony w
          odniesieniu do określonych krajów.
        </Text>

        <Text fontWeight={600} pt={8}>
          Okres przechowywania danych osobowych
        </Text>
        <OrderedList pl={6}>
          <ListItem>
            zawarcia i realizacji umowy – dane osobowe będą przetwarzane przez okres niezbędny do wykonania
            umowy, jej rozwiązania lub wygaśnięcia, maksymalnie do upływu terminów przedawnienia roszczeń
            wynikających z danej umowy, natomiast w przypadku dochodzenia lub obrony roszczeń z tytułu
            realizacji umowy – dane będą przetwarzane do prawomocnego zakończenia postępowania,
          </ListItem>
          <ListItem>
            spełnienia ciążących na Administratorze obowiązków prawnych – dane osobowe zawarte w księgach
            podatkowych lub rachunkowych będą przetwarzane przez okres 5 lat (licząc od początku roku
            następującego po roku obrotowym, którego dane dotyczą), jednak nie krócej niż do czasu upływu
            terminu przedawnienia zobowiązania podatkowego,
          </ListItem>
          <ListItem>
            <Box mb={1}>
              realizacji prawnie uzasadnionego interesu Administratora – dane zostaną usunięte:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>
                niezwłocznie po ustaniu celu ich przetwarzania, jednak nie później niż po upływie terminu
                przedawnienia roszczeń związanych z realizacją zawartej umowy,
              </ListItem>
              <ListItem>
                w razie dochodzenia lub obrony roszczeń z tytułu realizacji umowy – po prawomocnym zakończeniu
                postępowania,
              </ListItem>
              <ListItem>
                w przypadku skutecznego wniesienia przez osobę, której dane dotyczą sprzeciwu – z przyczyn
                związanych z jej szczególną sytuacją – wobec przetwarzania dotyczących jej danych osobowych.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <Box mb={1}>marketingu bezpośredniego – dane zostaną usunięte:</Box>
            <UnorderedList pl={6}>
              <ListItem>
                niezwłocznie po ustaniu celu ich przetwarzania, jednak nie później niż po upływie terminu
                przedawnienia roszczeń związanych z realizacją zawartej umowy,
              </ListItem>
              <ListItem>
                w przypadku wniesienia przez osobę, której dane dotyczą sprzeciwu wobec przetwarzania danych
                na potrzeby marketingu bezpośredniego, w tym profilowania,
              </ListItem>
              <ListItem>
                w razie dochodzenia lub obrony roszczeń z tytułu realizacji umowy – po prawomocnym zakończeniu
                postępowania,
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            dane osobowe przetwarzane na podstawie wyrażonej zgody będą przetwarzane do czasu wycofania zgody
            przez osobę, której dane dotyczą, na dalsze przetwarzanie jej danych w celu, na który zgoda
            została wyrażona.
          </ListItem>
        </OrderedList>

        <Text fontWeight={600} pt={8}>
          Prawa osób, których dane dotyczą
        </Text>
        <Text>
          W związku z przetwarzaniem przez Administratora danych osobowych, użytkownikom, których dane dotyczą
          przysługują następujące uprawnienia:
        </Text>
        <Text textDecoration="underline">Prawo dostępu do danych osobowych</Text>
        <OrderedList pl={6}>
          <ListItem>
            <Box mb={1}>
              Użytkownik, którego dane dotyczą, jest uprawniony do uzyskania potwierdzenia, czy Administrator
              przetwarza dane osobowe jego dotyczące, a w razie odpowiedzi twierdzącej,{' '}
              <Text as="span" textDecoration="underline">
                ma prawo dostępu do tych danych
              </Text>
              , a także{' '}
              <Text as="span" textDecoration="underline">
                prawo do informacji o
              </Text>
              :
            </Box>
            <UnorderedList pl={6}>
              <ListItem>celu przetwarzania jego danych osobowych,</ListItem>
              <ListItem>kategorii danych osobowych, których dotyczy przetwarzanie,</ListItem>
              <ListItem>
                odbiorcach lub kategoriach odbiorców, którym dane osobowe zostały lub zostaną ujawnione, w
                szczególności o odbiorcach w państwach trzecich lub organizacjach międzynarodowych,
              </ListItem>
              <ListItem>
                planowanym okresie przechowywania danych osobowych, a gdy nie jest to możliwe, o kryteriach
                ustalania tego okresu,
              </ListItem>
              <ListItem>
                prawie do żądania od administratora sprostowania, usunięcia lub ograniczenia przetwarzania
                danych oraz do wniesienia sprzeciwu wobec takiego przetwarzania,
              </ListItem>
              <ListItem>prawie wniesienia skargi do organu nadzorczego,</ListItem>
              <ListItem>źródle danych, jeżeli nie zostały zebrane od użytkownika, którego dotyczą,</ListItem>
              <ListItem>
                zautomatyzowanym podejmowaniu decyzji, w tym o profilowaniu oraz o zasadach ich podejmowania,
                a także o znaczeniu i przewidywanych konsekwencjach takiego przetwarzania dla użytkownika,
                którego dane dotyczą,
              </ListItem>
              <ListItem>
                Jeżeli dane osobowe są przekazywane do państwa trzeciego lub organizacji międzynarodowej,
                użytkownik, którego dane dotyczą, ma prawo zostać poinformowany o odpowiednich
                zabezpieczeniach związanych z przekazaniem.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            Użytkownik, którego dane dotyczą ma również{' '}
            <Text as="span" textDecoration="underline">
              prawo do uzyskania kopii danych
            </Text>
            .
          </ListItem>
        </OrderedList>
        <Text textDecoration="underline">Prawo dostępu do sprostowania danych</Text>
        <OrderedList pl={6}>
          <ListItem>
            Użytkownik, którego dane dotyczą, ma prawo wystąpienia do Administratora z żądaniem niezwłocznego
            sprostowania dotyczących go danych osobowych, które są nieprawidłowe.
          </ListItem>
          <ListItem>
            Ponadto użytkownik, może żądać uzupełnienia niekompletnych danych osobowych, w tym poprzez
            przedstawienie dodatkowego oświadczenia.
          </ListItem>
        </OrderedList>
        <Text textDecoration="underline">Prawo do usunięcia danych („prawo do bycia zapomnianym”)</Text>
        <OrderedList pl={6}>
          <ListItem>
            <Box mb={1}>
              Użytkownik może skierować do Administratora żądanie niezwłocznego usunięcia dotyczących go
              danych osobowych, a Administrator ma obowiązek bez zbędnej zwłoki usunąć dane osobowe oraz
              poinformować innych administratorów, którym upublicznił dane, które ma obowiązek usunąć, o
              konieczności usunięcia wszelkich łącz do tych danych, kopii tych danych osobowych lub ich
              replikacji, jeżeli zachodzi jedna z następujących okoliczności:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>
                dane osobowe nie są już niezbędne do celów, w których zostały zebrane lub w inny sposób
                przetwarzane,
              </ListItem>
              <ListItem>
                osoba, której dane dotyczą, cofnęła zgodę, na której opiera się przetwarzanie zarówno danych
                zwykłych, jak i szczególnych kategorii danych i nie ma innej podstawy prawnej ich
                przetwarzania,
              </ListItem>
              <ListItem>
                osoba, której dane dotyczą, wniosła sprzeciw wobec przetwarzania z przyczyn związanych z jej
                szczególną sytuacją jej danych osobowych i nie występują nadrzędne prawnie uzasadnione
                podstawy przetwarzania lub osoba, której dane dotyczą wniosła sprzeciw wobec przetwarzania jej
                danych osobowych na potrzeby marketingu bezpośredniego, w tym profilowania,
              </ListItem>
              <ListItem>dane osobowe były przetwarzane niezgodnie z prawem,</ListItem>
              <ListItem>
                dane osobowe muszą zostać usunięte w celu wywiązania się z obowiązku prawnego przewidzianego w
                prawie Unii lub prawie polskim.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <Text textDecoration="underline">Prawo do ograniczenia przetwarzania</Text>
        <OrderedList pl={6}>
          <ListItem>
            <Box mb={1}>
              Ograniczenie przetwarzania oznacza, że dane osobowe można jedynie przechowywać. Inne formy
              przetwarzania danych osobowych mogą mieć miejsce wyłącznie:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>za zgodą użytkownika, którego dane dotyczą lub</ListItem>
              <ListItem>w celu ustalenia, dochodzenia lub obrony roszczeń lub</ListItem>
              <ListItem>w celu ochrony praw innej osoby fizycznej lub prawnej lub</ListItem>
              <ListItem>
                z uwagi na ważne względy interesu publicznego Unii lub państwa członkowskiego.
              </ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            <Box mb={1}>Do ograniczenia przetwarzania może dojść w sytuacji gdy:</Box>
            <UnorderedList pl={6}>
              <ListItem>
                użytkownik, którego dane dotyczą kwestionuje prawidłowość danych. W takim przypadku
                przetwarzanie ogranicza się na okres pozwalający sprawdzić prawidłowość danych,
              </ListItem>
              <ListItem>
                przetwarzanie jest niezgodne z prawem, a użytkownik, którego dane dotyczą sprzeciwia się
                usunięciu danych osobowych, żądając w zamian ograniczenia ich wykorzystywania,
              </ListItem>
              <ListItem>
                Administrator nie potrzebuje już danych osobowych do celów przetwarzania, ale są one potrzebne
                użytkownikowi, którego dane dotyczą, do ustalenia, dochodzenia lub obrony roszczeń;
              </ListItem>
              <ListItem>
                użytkownik, którego dane dotyczą wniósł sprzeciw wobec przetwarzania z przyczyn związanych z
                jego szczególną sytuacją. W takim przypadku ogranicza się przetwarzanie do czasu stwierdzenia,
                czy prawnie uzasadnione podstawy po stronie Administratora są nadrzędne wobec podstaw
                sprzeciwu użytkownika, którego dane dotyczą.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <Text textDecoration="underline">Prawo do przeniesienia danych</Text>
        <OrderedList pl={6}>
          <ListItem>
            <Box mb={1}>
              W przypadku przetwarzania danych osobowych w sposób zautomatyzowany na podstawie zgody lub na
              podstawie umowy, której stroną jest użytkownik, którego dane dotyczą, użytkownik ten ma prawo:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>
                otrzymać w ustrukturyzowanym, powszechnie używanym formacie nadającym się do odczytu
                maszynowego, dane osobowe jego dotyczące (które dostarczył Administratorowi) oraz
              </ListItem>
              <ListItem>
                przesłać te dane osobowe innemu administratorowi bez przeszkód ze strony Administratora.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>
        <Text textDecoration="underline">Prawo do cofnięcia zgody w dowolnym momencie</Text>
        <Text>
          Użytkownik, którego dane przetwarzane są przez Administratora na podstawie wyrażonej przez
          użytkownika zgody, ma prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem
          przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.
        </Text>
        <Text textDecoration="underline">Prawo wniesienia skargi do organu nadzorczego</Text>
        <Text>
          W przypadku uznania, że przetwarzanie danych osobowych narusza przepisy prawa użytkownik, którego
          dane są przetwarzane przez Administratora, ma prawo wniesienia skargi do organu nadzorczego, którym
          jest Prezes Urzędu Ochrony Danych Osobowych, na adres ul. Stawki 2; 00-193 Warszawa.
        </Text>
        <Text textDecoration="underline">Prawo do sprzeciwu</Text>
        <Text>
          Użytkownik, którego dane dotyczą, ma prawo w dowolnym momencie wnieść sprzeciw – z przyczyn
          związanych z jego szczególną sytuacją – wobec przetwarzania dotyczących go danych osobowych opartego
          na art. 6 ust. 1 lit. e) (interes lub zadania publiczne) lub f) (prawnie uzasadniony interes
          administratora), w tym profilowania na podstawie tych przepisów. Administratorowi w takim przypadku
          nie wolno już przetwarzać tych danych osobowych, chyba że wykaże on istnienie ważnych prawnie
          uzasadnionych podstaw do przetwarzania, nadrzędnych wobec interesów, praw i wolności użytkownika,
          którego dane dotyczą, lub podstaw do ustalenia, dochodzenia lub obrony roszczeń.
        </Text>
        <Text textDecoration="underline">
          Prawo do sprzeciwu wobec przetwarzania danych na potrzeby marketingu bezpośredniego
        </Text>
        <Text>
          Jeżeli dane osobowe są przetwarzane na potrzeby marketingu bezpośredniego, użytkownik, którego dane
          dotyczą, ma prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania dotyczących go danych
          osobowych na potrzeby takiego marketingu, w tym profilowania, w zakresie, w jakim przetwarzanie jest
          związane z marketingiem bezpośrednim. W przypadku wniesienia przez użytkownika sprzeciwu wobec
          przetwarzania do celów marketingu bezpośredniego, Administrator nie będzie mógł dłużej przetwarzać
          danych do takich celów.
        </Text>
        <Text fontWeight={500}>
          W celu realizacji uprawnień, o których mowa w niniejszym punkcie polityki prywatności, użytkownik
          może się kontaktować się z Administratorem poprzez zgłoszenie żądania pisemnie lub pocztą
          elektroniczną na adres Administratora wskazany na wstępie Polityki prywatności.
        </Text>

        <Text fontWeight={600} pt={8}>
          Dobrowolność podania danych osobowych
        </Text>
        <OrderedList pl={6}>
          <ListItem>
            <Box mb={1}>
              Podanie Administratorowi jakichkolwiek danych osobowych jest dobrowolne. Nie jest to obowiązek
              ustawowy, może stanowić jedynie wymóg umowny. W niektórych przypadkach podanie określonych
              danych osobowych jest niezbędne, aby spełnić oczekiwania użytkownika w zakresie korzystania z
              usług świadczonych przez Administratora, w tym:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>zawarcia lub realizacji umowy sprzedaży,</ListItem>
              <ListItem>
                obsługi reklamacji/odstąpienia od umowy i wykonywania obowiązków ciążących na Administratorze
                z tego tytułu,
              </ListItem>
              <ListItem>odpowiedzi na kierowane do Administratora zapytania,</ListItem>
              <ListItem>przeglądania strony sklepu internetowego,</ListItem>
              <ListItem>wystawiania faktur VAT i wykonywania obowiązków podatkowych,</ListItem>
              <ListItem>wysyłania newslettera.</ListItem>
            </UnorderedList>
          </ListItem>
          <ListItem>
            We wskazanych wyżej przypadkach niepodanie danych osobowych będzie skutkowało niemożnością
            realizacji ww. usług przez Administratora.
          </ListItem>
        </OrderedList>

        <Text fontWeight={600} pt={8}>
          Profilowanie
        </Text>
        <OrderedList pl={6}>
          <ListItem>
            W celu realizowania działań marketingowych w niektórych wypadkach wykorzystujemy profilowanie, tj.
            automatyczne przetwarzanie danych osobowych, które polega na automatycznej analizie lub prognozie
            zachowania użytkownika w Serwisie internetowym np. na podstawie zainteresowania użytkownika
            określoną usługą, czy też na analizie aktywności użytkownika w Serwisie internetowym. Powyższe
            pozwala nam dostosować swoją ofertę do potrzeb lub zainteresowań użytkowników.
          </ListItem>
          <ListItem>
            Administrator nie stosuje jednak w stosunku do użytkowników profilowania wywołującego wobec nich
            skutki prawne lub w podobny sposób istotnie na nich wpływających. Profilowanie nie wpływa również
            na zawarcie lub odmowę zawarcia umowy o świadczenie usług.
          </ListItem>
        </OrderedList>

        <Text fontWeight={600} pt={8}>
          Informacja o plikach cookie
        </Text>
        <OrderedList pl={6}>
          <ListItem>
            Administrator korzysta z plików typu cookie, tj. niewielkich plików tekstowych, które są
            zapisywane na urządzeniu końcowym użytkownika (komputerze, telefonie lub innym urządzeniu, z
            którego dokonano połączenia).
          </ListItem>
          <ListItem>
            Stosujemy następujące pliki cookie: zaakceptowanie cookies, stan autentykacji użytkownika.
            Dodatkowo moduły Google Analytics i 8x8 SaaS tworzą i zarządzają swoimi własnymi plikami cookies.
          </ListItem>
          <ListItem>
            Stosowanie przez nas plików cookie ma na celu dostarczenie usług zgodnie z przepisami polskiego
            prawa.
          </ListItem>
          <ListItem>Pliki cookie będą stosowane przez nas bez limitu czasu ich istnienia.</ListItem>
          <ListItem>
            Informujemy, że osoby trzecie będą miały dostęp do plików cookie stworzonych przez ich moduły, tj.
            Google LLC będzie miało dostęp do plików cookies stworzonych przez Google Analytics, 8x8 Inc.
            będzie miało dostęp do plików cookies stworzonych przez JaaS, platformę do wideokonferencji.
          </ListItem>
          <ListItem>
            Użytkownik w dowolnym momencie, wykorzystując przeglądarkę internetową z której korzysta, może
            zmienić ustawienia dotyczące plików cookie, w tym może zablokować możliwość ich gromadzenia.
            Jednak w takim wypadku należy pamiętać, że całkowite wyłączenie możliwości zapisywania plików
            cookie może mieć wpływ na niektóre funkcjonalności Serwisu internetowego i utrudnić korzystanie ze
            świadczonych przez nas usług.
          </ListItem>
          <ListItem>
            <Box mb={1}>
              Szczegółowe informacje na temat zmiany ustawień dotyczących plików cookie oraz ich samodzielnego
              usuwania w najpopularniejszych przeglądarkach internetowych dostępne są w dziale pomocy
              przeglądarki internetowej oraz na poniższych stronach:
            </Box>
            <UnorderedList pl={6}>
              <ListItem>
                Mozilla Firefox:{' '}
                <Link href="https://support.mozilla.org/pl/kb/ciasteczka" target="_blank">
                  https://support.mozilla.org/pl/kb/ciasteczka
                </Link>
              </ListItem>
              <ListItem>
                Google Chrome:{' '}
                <Link
                  href="https://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647"
                  target="_blank"
                >
                  https://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647
                </Link>
              </ListItem>
              <ListItem>
                Internet Explorer:{' '}
                <Link
                  href="https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies"
                  target="_blank"
                >
                  https://support.microsoft.com/pl-pl/help/17442/windows-internet-explorer-delete-manage-cookies
                </Link>
              </ListItem>
              <ListItem>
                Opera:{' '}
                <Link href="https://help.opera.com/Windows/12.10/pl/cookies.html" target="_blank">
                  https://help.opera.com/Windows/12.10/pl/cookies.html
                </Link>
              </ListItem>
              <ListItem>
                Safari:{' '}
                <Link href="https://support.apple.com/kb/PH5042?locale=en-GB" target="_blank">
                  https://support.apple.com/kb/PH5042?locale=en-GB
                </Link>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </OrderedList>

        <Text fontWeight={600} pt={8}>
          Usługa Google Analytics
        </Text>
        <OrderedList pl={6}>
          <ListItem>
            W celu dostosowania zawartości Serwisu internetowego do preferencji użytkowników i jej ciągłej
            optymalizacji, Administrator korzysta z usługi Google Analytics – narzędzia do analizowania
            aktywności użytkowników stron internetowych udostępnianego przez Google LLC (dalej: „Google”).
          </ListItem>
          <ListItem>
            Usługa ta wykorzystuje pliki cookie (dotyczące statystyki oraz reklamowe) i służy do raportowania
            źródeł ruchu i analizowania zachowania użytkowników w Serwisie internetowym.
          </ListItem>
          <ListItem>
            Szczegółowe informacje dotyczące Google Analytics znajdują się na stronie{' '}
            <Link href="https://support.google.com/analytics/answer/6004245" target="_blank">
              https://support.google.com/analytics/answer/6004245
            </Link>
            .
          </ListItem>
          <ListItem>
            Możliwe jest zablokowanie przez użytkownika udostępniania Google Analytics informacji o jego
            aktywności w Serwisie internetowym – w tym celu można zainstalować dodatek do przeglądarki
            udostępniany przez Google, dostępny pod adresem:{' '}
            <Link href="https://tools.google.com/dlpage/gaoptout?hl=pl" target="_blank">
              https://tools.google.com/dlpage/gaoptout?hl=pl
            </Link>
            .
          </ListItem>
        </OrderedList>

        <Text fontWeight={600} pt={8}>
          Postanowienia końcowe
        </Text>

        <OrderedList pl={6}>
          <ListItem>Polityka prywatności odnosi się tylko do Serwisu internetowego Administratora.</ListItem>
          <ListItem>Niniejsza polityka prywatności obowiązuje od dnia 21.11.2022.</ListItem>
        </OrderedList>
      </Stack>
    </Container>
  )
}

export default TermsAndConditions
