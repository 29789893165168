import {AdminSupportGroupUser, SupportGroupRole} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptySupportGroupUser: AdminSupportGroupUser = {
  support_group: 0,
  user: '',
  created_at: new Date(),
  updated_at: new Date(),
  role: 'queue',
  email: '',
}

export const supportGroupRoleToLabel: Record<SupportGroupRole, string> = {
  member: 'Członek',
  host: 'Prowadzący',
  substitute_host: 'Zastępca prowadzącego',
  queue: 'Chętny',
}

export type RoleOption = {
  value: SupportGroupRole
  label: string
}

export const roleOptions: RoleOption[] = [
  {
    value: 'member',
    label: supportGroupRoleToLabel.member,
  },
  {
    value: 'host',
    label: supportGroupRoleToLabel.host,
  },
  {
    value: 'substitute_host',
    label: supportGroupRoleToLabel.substitute_host,
  },
  {
    value: 'queue',
    label: supportGroupRoleToLabel.queue,
  },
]

export const columns: Column<AdminSupportGroupUser>[] = [
  {
    Header: 'ID użytkownika',
    id: 'user',
    sortable: true,
  },
  {
    Header: 'Email',
    id: 'email',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Zaktualizowano',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID użytkownika',
    name: 'user',
    variant: 'text',
  },
  {
    label: 'Email',
    name: 'email',
    variant: 'text',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Zaktualizowano',
    name: 'updated_at',
    variant: 'datetime',
  },
]
