import * as React from 'react'

import {Heading, Stack} from '@chakra-ui/react'

import {SupportGroupJoined, parseSupportGroupJoined} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'

import RecommendedSupportGroups from '../recommended'

const count = 4

const OtherSupportGroups = ({currentID}: {currentID: number}) => {
  const {loading, data} = useSupabaseQuery<SupportGroupJoined>({
    table: 'support_groups_view',
    fields: '*',
    itemsPerPage: count,
    order: 'featured_index', // TODO: fetch random rows
    filter: React.useCallback((b) => (currentID ? b.neq('id', currentID) : b), [currentID]),
    parsingFunction: parseSupportGroupJoined,
  })

  return !data?.length && !loading ? null : (
    <Stack spacing={[4, null, 16]}>
      <Heading size="lg">Inne grupy wsparcia</Heading>
      <RecommendedSupportGroups items={data ?? []} count={count} loading={loading} />
    </Stack>
  )
}

export default OtherSupportGroups
