import * as React from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {add} from 'date-fns'

import {SupportGroupJoined, SupportGroupMeeting} from '@/api/models'
import {stringifyPgTimestampRange} from '@/api/utils'
import DatetimeInput from '@/common/datetime-input'
import useEditorModalState from '@/common/use-editor-modal'
import {CommonModalProps} from '@/utils/types'

import {emptySupportGroupMeeting} from './constants'

// TODO: Add a feature to generate some number of meetings with a given interval
const inputToUpsertArgs = (i: SupportGroupMeeting) => ({
  meeting_id: i.id || undefined,
  support_group_id: i.support_group,
  name: i.name,
  description: i.description,
  duration: stringifyPgTimestampRange({from: new Date(i.starts_at), to: new Date(i.ends_at)}),
})

const SupportGroupMeetingEditorModal = ({
  item,
  supportGroup,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {
  item: SupportGroupMeeting | null
  supportGroup: SupportGroupJoined | null
}) => {
  const {input, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<SupportGroupMeeting>({
      item,
      inputToUpsertArgs,
      customRPC: 'upsert_support_group_meeting',
      emptyInput: emptySupportGroupMeeting,
      open,
      onClose,
      onComplete,
    })

  // Generate default values
  React.useEffect(() => {
    if (item?.id || !supportGroup) return

    const {id, name, meetings_left, first_meeting_duration, meta_description} = supportGroup
    handleCustomInputChange({
      support_group: id,
      name: `Grupa wsparcia "${name}" - spotkanie #${meetings_left + 1}`,
      description: meta_description || '',
      starts_at: first_meeting_duration?.from
        ? add(first_meeting_duration?.from, {weeks: 1 * meetings_left})
        : emptySupportGroupMeeting.starts_at,
      ends_at: first_meeting_duration?.to
        ? add(first_meeting_duration?.to, {weeks: 1 * meetings_left})
        : emptySupportGroupMeeting.ends_at,
    })
  }, [open, supportGroup, item]) // eslint-disable-line

  const handleStartsAtChange = React.useCallback(
    (date: Date | null) => handleCustomInputChange({starts_at: date || new Date()}),
    [handleCustomInputChange]
  )
  const handleEndsAtChange = React.useCallback(
    (date: Date | null) => handleCustomInputChange({ends_at: date || new Date()}),
    [handleCustomInputChange]
  )

  const isSubmitDisabled = React.useMemo(
    () =>
      !input.name ||
      !input.description ||
      !input.support_group ||
      !input.starts_at ||
      !input.ends_at ||
      input.starts_at >= input.ends_at,
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{input.id ? 'Edytuj' : 'Dodaj'} spotkanie grupy wsparcia</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa</FormLabel>
              <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isInvalid={input.starts_at >= input.ends_at} isDisabled={loading}>
              <FormLabel>Czas trwania pierwszego spotkania</FormLabel>
              <HStack>
                <DatetimeInput value={input.starts_at} onChange={handleStartsAtChange} isDisabled={loading} />
                <Text>-</Text>
                <DatetimeInput value={input.ends_at} onChange={handleEndsAtChange} isDisabled={loading} />
              </HStack>
              <FormErrorMessage>Nieprawidłowy czas początku i końca spotkania</FormErrorMessage>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opis</FormLabel>
              <Textarea name="description" value={input.description ?? ''} onChange={handleInputChange} />
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={isSubmitDisabled}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SupportGroupMeetingEditorModal
