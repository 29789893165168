import * as React from 'react'

import {Box, BoxProps, Stack, Text} from '@chakra-ui/react'
import {format} from 'date-fns'

import {MyMeeting} from '@/api/models'

type Props = BoxProps & {meeting: MyMeeting}

const PlannedMeeting = ({meeting, ...rest}: Props) => {
  return (
    <Box bg="brand.green.300" p={8} {...rest}>
      <Stack spacing={2} flex={1} justify="center">
        <Text fontWeight={500}>{meeting.therapist_full_name}</Text>
        <Text fontWeight={500}>{meeting.name}</Text>
        <Text fontWeight={300}>{format(new Date(meeting.starts_at), "dd.MM.yyy | 'godz.' HH:mm")}</Text>
      </Stack>
    </Box>
  )
}

export default PlannedMeeting
