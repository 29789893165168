import React from 'react'

import {CourseCategory} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {emptyCourseCaterogy, columns, filterFields} from './constants'
import CourseCategoryEditor from './editor-modal'

const CourseCategories = () => {
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<CourseCategory>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof CourseCategory,
    pageNumber: tableState.page,
    table: 'course_categories',
  })

  const {
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
    value: deleteValue,
  } = useValueDisclosure<CourseCategory>()

  const {
    onClose: onEditorClose,
    onOpen: onEditorOpen,
    value: editorValue,
  } = useValueDisclosure<CourseCategory>()

  const actions = React.useMemo(
    () => [editAction(onEditorOpen), deleteAction(onDeleteOpen)],
    [onDeleteOpen, onEditorOpen]
  )

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyCourseCaterogy)
  }, [onEditorOpen])

  return (
    <>
      <DataTable<CourseCategory>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        loading={loading}
        filterFields={filterFields}
      />
      <CourseCategoryEditor
        item={editorValue}
        open={!!editorValue}
        onClose={onEditorClose}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="course_categories"
        id={deleteValue?.id || ''}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default CourseCategories
