// defining paths here rather than in the router to avoid circular dependencies

export const ADMIN_PREFIX = '/admin'
export const USER_PREFIX = '/konto'
export const ABOUT_US = '/o-psychoformie'
export const RESET_PASSWORD = '/resetowanie-hasla'
export const PRIVACY_POLICY = '/polityka-prywatnosci'
export const TERMS_AND_CONDITIONS = '/regulamin'
export const FAQ = '/czesto-zadawane-pytania'
export const JOIN_US = '/dolacz-do-nas'
export const CONTACT_US = '/kontakt'
export const BLOG = '/blog'
export const BLOG_POST = `${BLOG}/:id/:slug?`
export const HOME = '/'
export const COURSES = '/warsztaty'
export const COURSE_PREFIX = '/warsztat'
export const COURSE_SHOWCASE = `${COURSE_PREFIX}/:id/:slug?`
export const COURSE_PAGE = `${COURSE_PREFIX}/:course/sekcja/:page?`
export const ADMIN_COURSE_PAGE = `${ADMIN_PREFIX}/courses/:course/view/:page?`
export const MEETING = '/j/:id'
export const MEETING_INVITE = '/i/:id'
export const PSYCHOTHERAPY = '/psychoterapia'
export const THERAPIST_PREFIX = '/psychoterapeuta'
export const THERAPIST_PANEL = '/panel-terapeuty'
export const THERAPIST_GOOGLE_CALLBACK = '/psychotherapy/therapists/google/callback'
export const PSYCHOTHERAPY_THERAPIST = `${THERAPIST_PREFIX}/:id/:slug?`
export const PSYCHOTHERAPISTS = '/psychoterapeuci'
export const SUPPORT_GROUPS = '/grupy-wsparcia'
export const SUPPORT_GROUPS_LIST = '/lista-grup-wsparcia'
export const SUPPORT_GROUP_PREFIX = '/grupa-wsparcia'
export const SUPPORT_GROUP_SHOWCASE = `${SUPPORT_GROUP_PREFIX}/:id/:slug?`
export const ORDER_PROCESSING = '/zamowienie/:id'
export const WEBINAR_PREFIX = '/webinar'
export const WEBINAR = `${WEBINAR_PREFIX}/:id/:slug?`
export const WEBINAR_VIEW = '/w/:meeting'
