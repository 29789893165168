import * as React from 'react'

import {FaUsers, FaRegCalendarAlt} from 'react-icons/fa'

import {SupportGroupJoined} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'

export const accessAction = (
  onClick: (item: SupportGroupJoined) => void
): DataTableAction<SupportGroupJoined> => ({
  icon: <FaUsers />,
  id: 'access-management',
  onClick,
  tooltip: 'Zarządzanie dostępem',
})

export const meetingsAction = (
  onClick: (item: SupportGroupJoined) => void
): DataTableAction<SupportGroupJoined> => ({
  icon: <FaRegCalendarAlt />,
  id: 'meetings-management',
  onClick,
  tooltip: 'Zarządzanie spotkaniami',
})
