import React from 'react'

import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Textarea,
} from '@chakra-ui/react'

import {ArrowButton} from '@/common/arrow-button'
import StarRating from '@/common/reviews/star-rating'
import {useAppDispatch, useAppSelector} from '@/store'

import {selectReviewState, setReviewState, submitReview, updateReview} from '../state'
import ReviewHostInput from './host-input'

const ReviewModal = () => {
  const dispatch = useAppDispatch()
  const {open, submitting, review, admin, type} = useAppSelector(selectReviewState)

  // reset state to initial value
  const handleClose = React.useCallback(() => dispatch(setReviewState({})), [dispatch])

  const handleSubmit = React.useCallback(async () => dispatch(submitReview()), [dispatch])

  const handleContentChange = React.useCallback(
    ({target: {value}}) => dispatch(updateReview({content: value})),
    [dispatch]
  )

  const handleRatingChange = React.useCallback((i: number) => dispatch(updateReview({rating: i})), [dispatch])

  const handlePublishedChange = React.useCallback(
    ({target: {checked}}) => dispatch(updateReview({published: checked})),
    [dispatch]
  )

  const handleFeaturedChange = React.useCallback(
    (s: string) => dispatch(updateReview({featured_index: s ? +s : null})),
    [dispatch]
  )

  return (
    <Modal isOpen={open} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{review.id ? 'Zaktualizuj' : 'Dodaj'} recenzję</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            {type === 'support_group' && <ReviewHostInput />}
            <FormControl isDisabled={submitting}>
              <FormLabel>Ocena</FormLabel>
              <StarRating value={review.rating} onClick={handleRatingChange} />
            </FormControl>
            <FormControl isDisabled={submitting}>
              <FormLabel>Opis</FormLabel>
              <Textarea value={review.content} onChange={handleContentChange} />
            </FormControl>
            {admin && (
              <>
                <FormControl isDisabled={submitting}>
                  <Checkbox isChecked={review.published} onChange={handlePublishedChange}>
                    Czy opublikowany
                  </Checkbox>
                </FormControl>
                <FormControl isDisabled={submitting}>
                  <FormLabel>Indeks wyróżnienia</FormLabel>
                  <NumberInput value={review.featured_index ?? ''} onChange={handleFeaturedChange}>
                    <NumberInputField />
                  </NumberInput>
                </FormControl>
              </>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button color="brand.yellow.600" variant="link" onClick={handleClose} isDisabled={submitting}>
              Anuluj
            </Button>
            <ArrowButton
              onClick={handleSubmit}
              height={40}
              fontSize="lg"
              fontWeight={500}
              isLoading={submitting}
              isDisabled={review.rating === 0}
            >
              {review.id ? 'Zaktualizuj' : 'Dodaj'}
            </ArrowButton>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ReviewModal
