import {generatePath} from 'react-router-dom'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'
import {Section} from '@/api/models'
import {findTreeParent} from '@/common/draggable-tree/utils'
import {Key} from '@/common/rc-tree/interface'
import {CourseNode, CoursePagePerUser, MenuNodeType, SectionPerUser} from '@/courses/types'
import {ADMIN_COURSE_PAGE, COURSE_PAGE} from '@/router/paths'

export type CourseParams = {course: string; page: string}

type SectionWithSplitPath = Section & {
  splitPath: string[]
}

export const buildTreeData = (
  sections: SectionPerUser[],
  pages: CoursePagePerUser[],
  adminView?: boolean
): CourseNode[] => {
  const sectionsWithSplitPath = sections.map((s) => ({
    ...s,
    splitPath: s.path.split('.'),
  }))
  const tree: CourseNode[] = []

  const fillTreeChildren = (branches: CourseNode[]) => {
    for (const b of branches) {
      if (b.isLeaf) {
        continue
      }

      const branchSubSections = sectionsWithSplitPath
        .filter((s) => s.splitPath[s.splitPath.length - 2]?.replaceAll('_', '-') === b.key)
        .map((s) => ({...s, type: 'section'}))

      const branchSubPages = pages.filter((p) => p.section === b.key).map((m) => ({...m, type: 'page'}))

      b.children = [...branchSubSections, ...branchSubPages]
        .sort((a, b) => a.order - b.order)
        .map((e) =>
          newCourseNode({
            completed: e.completed,
            id: e.id,
            link: generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
              course: e.course,
              page: e.id,
            }),
            path: e.type === 'section' ? (e as SectionWithSplitPath).path : undefined,
            published: e.published,
            title: e.title,
            touched: e.touched,
            type: e.type as MenuNodeType,
          })
        )

      fillTreeChildren(b.children)
    }
  }

  // setup root branches
  tree.push(
    ...sectionsWithSplitPath
      .filter((s) => s.splitPath.length === 1)
      .sort((a, b) => a.order - b.order)
      .map((s) =>
        newCourseNode({
          completed: s.completed,
          id: s.id,
          path: s.path,
          published: s.published,
          title: s.title,
          touched: s.touched,
          type: 'section',
        })
      )
  )

  fillTreeChildren(tree)

  return tree
}

export const newCourseNode = ({
  type,
  title,
  published,
  id,
  path,
  link,
  completed,
  touched,
}: {
  type: MenuNodeType
  title: string
  published?: boolean
  id?: string
  path?: string
  link?: string
  completed: boolean
  touched: boolean
}): CourseNode => {
  // generate uuid if not passed
  const key = id || uuidv4()

  if (type === 'section') {
    return {
      children: [],
      completed,
      isLeaf: false,
      key,
      path,
      published,
      selectable: false,
      title,
      touched,
      type: 'section',
    }
  }

  return {
    completed,
    isLeaf: true,
    key,
    link,
    published,
    title,
    touched,
    type: 'page',
  }
}

export const deleteCoursePage = async (course: number, page: string) => {
  const {data, error} = await supabase.rpc('delete_course_page', {
    course_id: course,
    page_id: page,
  })
  if (error) {
    throw error
  }
  if ((data as any).error) {
    throw (data as any).error
  }
}

export const deleteCourseSection = async (course: number, section: string) => {
  const {data, error} = await supabase.rpc('delete_course_section', {
    course_id: course,
    section_id: section,
  })
  if (error) {
    throw error
  }
  if ((data as any).error) {
    throw (data as any).error
  }
}

export const moveCoursePage = async ({
  course,
  page,
  destinationIndex,
  destinationSection,
}: {
  course: number
  destinationIndex: number
  destinationSection: string | null
  page: string
}) => {
  const {data, error} = await supabase.rpc('move_course_page', {
    course_id: course,
    destination_index: destinationIndex,
    destination_section: destinationSection,
    page_id: page,
  })
  if (error) {
    throw error
  }
  if ((data as any).error) {
    throw (data as any).error
  }
}

export const moveCourseSection = async ({
  course,
  section,
  destinationIndex,
  destinationSection,
}: {
  course: number
  destinationIndex: number
  destinationSection: string | null
  section: string
}) => {
  const {data, error} = await supabase.rpc('move_course_section', {
    course_id: course,
    destination_index: destinationIndex,
    destination_section: destinationSection,
    section_id: section,
  })
  if (error) {
    throw error
  }
  if ((data as any).error) {
    throw (data as any).error
  }
}

// Refreshing from the passed node to the root of the tree.
export const refreshSectionsState = (treeData: CourseNode[], node: CourseNode) => {
  if (node.type === 'page') {
    const parentNode = findTreeParent(treeData, node.key)
    if (!parentNode) {
      throw new Error('Page always should have a parent section.')
    }
    refreshSectionsState(treeData, parentNode)
    return
  }

  node.touched = false
  node.completed = !!node.children?.length
  for (const c of node.children || []) {
    if (c.touched) {
      node.touched = true
    }
    if (!c.completed) {
      node.completed = false
    }
  }

  const parentNode = findTreeParent(treeData, node.key)
  if (!parentNode) {
    return
  }
  refreshSectionsState(treeData, parentNode)
}

export const getNodeParentSectionKeys = (treeData: CourseNode[], node: CourseNode): Key[] => {
  const keys: Key[] = []
  let currentNode = node

  while (true) {
    if (!currentNode?.key) {
      throw Error('')
    }
    const parentNode = findTreeParent(treeData, currentNode.key)
    if (!parentNode) {
      break
    }

    keys.push(parentNode.key)
    currentNode = parentNode
  }

  return keys
}
