import {OrderDiscountCode} from '@/api/models'

export const doesDiscountCodeApply = (
  {product_type, products_ids}: OrderDiscountCode,
  serviceID?: string,
  therapistID?: number
) =>
  !product_type ||
  (product_type === 'service' && (!products_ids.length || products_ids.some((id) => id === serviceID))) ||
  (product_type === 'therapist' &&
    (!products_ids.length || products_ids.some((id) => id === therapistID?.toString())))
