import * as React from 'react'

import {CloseIcon} from '@chakra-ui/icons'
import {Avatar, Image, Link} from '@chakra-ui/react'

import {FrontPageLogo} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const emptyFrontPageLogo: FrontPageLogo = {
  alt_text: '',
  created_at: new Date(),
  id: '',
  image_path: '',
  link_url: '',
  order: 0,
  published: false,
  updated_at: new Date(),
}

export const defaultSelectedColumns = ['image_path', 'alt_text', 'order', 'published']

export const columns: Column<FrontPageLogo>[] = [
  {
    Header: 'Logo',
    id: 'image_path',
    renderer: ({image_path}) =>
      image_path ? (
        <Image boxSize="75px" src={image_path} objectFit="contain" />
      ) : (
        <Avatar boxSize="75px" icon={<CloseIcon />} />
      ),
    sortable: false,
  },
  {
    Header: 'Nazwa',
    id: 'alt_text',
  },
  {
    Header: 'URL',
    id: 'link_url',
    renderer: ({link_url}) => (link_url ? <Link href={link_url}>Link</Link> : '-'),
  },
  {
    Header: 'Numer porządkowy',
    id: 'order',
  },
  {
    Header: 'Opublikowano',
    id: 'published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Numer porządkowy',
    name: 'order',
    variant: 'number',
  },
  {
    label: 'Nazwa',
    name: 'alt_text',
    variant: 'text',
  },
  {
    label: 'URL',
    name: 'link_url',
    variant: 'text',
  },
  {
    label: 'Opublikowano',
    name: 'published',
    variant: 'boolean',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
