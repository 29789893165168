import {QuestionAnswerItem} from '@/api/models'
import {Column} from '@/common/data-table/types'

export const defaultSelectedColumns = ['question', 'collapsed', 'is_published']

export const columns: Column<QuestionAnswerItem>[] = [
  {
    Header: 'ID',
    id: 'id',
  },
  {
    Header: 'Pytanie',
    id: 'question',
  },
  {
    Header: 'Zwinięte',
    id: 'collapsed',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Opublikowane',
    id: 'is_published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Numer porządkowy',
    id: 'order',
  },
]
