import * as React from 'react'

import {
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  HStack,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

import {INVALID_PWD} from './errors'

const DeleteUserConfirmationModal = ({onClose, open}: CommonModalProps) => {
  const [password, setPassword] = React.useState('')
  const toast = useToast()

  const handlePasswordChange = React.useCallback(({target: {value}}) => setPassword(value), [setPassword])

  React.useEffect(() => {
    setPassword('')
  }, [open])

  const _handleSubmit = React.useCallback(async () => {
    if (!password) {
      return
    }
    try {
      const {data, error: deleteError} = await supabase.rpc('delete_own_user_account', {
        password: password,
      })
      if (deleteError) {
        throw deleteError
      }
      if ((data as any).error && (data as any).error_code === INVALID_PWD) {
        throw new Error('Nieprawidłowe hasło')
      }
      toast({isClosable: true, status: 'success', title: 'Konto usunięte'})
      const {error} = await supabase.auth.signOut()
      // It will always error, as the account does not exist at this point
    } catch (e) {
      console.error('Failed to delete account', e)
      toast({
        description: (e as Error).message === 'Nieprawidłowe hasło' ? 'Nieprawidłowe hasło' : '',
        isClosable: true,
        status: 'error',
        title: 'Nie udało się usunąć konta',
      })
    } finally {
      onClose()
    }
  }, [password, toast, onClose])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)

  return (
    <Modal isOpen={open} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Potwierdź hasło, aby usunąć konto</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="password" isDisabled={loading}>
            <FormLabel>Hasło</FormLabel>
            <Input type="password" value={password} onChange={handlePasswordChange} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button isDisabled={loading} onClick={onClose}>
              Anuluj
            </Button>
            <Button colorScheme="red" isLoading={loading} isDisabled={!password} onClick={handleSubmit}>
              Usuń konto
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default DeleteUserConfirmationModal
