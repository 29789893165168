import React from 'react'

import {
  AspectRatio,
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'
import {TNode} from '@udecode/plate'
import {CarouselProvider, Dot, Slider, Slide, ButtonBack, ButtonNext} from 'pure-react-carousel'
import {Link as RouterLink, generatePath} from 'react-router-dom'

import {FeaturedTherapist} from '@/api/models'
import {ArrowButton, ArrowIconButton} from '@/common/arrow-button'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import useSupabaseQuery from '@/common/use-custom-query'
import {PSYCHOTHERAPISTS, PSYCHOTHERAPY_THERAPIST} from '@/router/paths'

import {FEATURED_THERAPISTS_LIMIT} from './constants'
import './index.css'

type TherapistProps = {
  id: string
  name: string
  full_name_slug: string
  image?: string
  description: TNode[] | null
  specialties: string[]
}

const Therapist = (props: TherapistProps) => {
  const columnStyle = useBreakpointValue({
    base: {
      columnCount: 'auto',
    },
    md: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  })

  return (
    <Flex
      position="relative"
      ml={[0, null, '88px']}
      mr={[0, null, '8px']}
      alignItems={['flex-start', null, 'center']}
      justifyContent={['flex-start', null, 'center']}
      height="100%"
    >
      <Flex
        bg="brand.green.600"
        roundedBottomLeft={[0, null, '100px']}
        roundedTopRight={[0, null, '100px']}
        pl={[8, null, `${(240 * 2) / 3 + 20}px`]}
        pr={[8, null, 20]}
        py={12}
        mt={[12, null, 0]}
        color="white"
        maxHeight="360px"
        direction="column"
      >
        <Text as="h3" fontSize={['md', null, '3xl']} fontWeight={500} mb={[12, null, 8]}>
          {props.name}
        </Text>
        <Box maxHeight={['none', null, '300px']} fontSize="md" fontWeight={300} style={columnStyle} mb={4}>
          <ContentEditor
            id={`homepage-featured-therapist-${props.id}`}
            value={props.description ?? initialEditorValue}
            variant="basic"
            readOnly={true}
          />
        </Box>
        <Text fontSize={['sm', null, 'md']} fontWeight={300}>
          {props.specialties?.join(' | ')}
        </Text>
        {props.full_name_slug && (
          <Box fontSize="md" display={['block', null, 'none']} mt={8} textAlign="right">
            <Link
              as={RouterLink}
              to={generatePath(PSYCHOTHERAPY_THERAPIST, {
                id: props.id,
                slug: props.full_name_slug,
              })}
              fontWeight={400}
              borderBottom="1px solid white"
              pb={2}
              _hover={{
                fontWeight: 500,
              }}
            >
              Zobacz więcej
            </Link>
          </Box>
        )}
      </Flex>
      <Box
        width={['154px', null, '240px']}
        height={['154px', null, '240px']}
        position="absolute"
        left={['auto', null, `-${240 / 3}px`]}
        right={['-20px', null, 'auto']}
        top={[0, null, '50%']}
      >
        <AspectRatio
          ratio={1}
          rounded="full"
          width={['154px', null, '240px']}
          height={['154px', null, '240px']}
          marginTop={[0, null, '-50%']}
          overflow="hidden"
        >
          <Image src={props.image} objectFit="cover" />
        </AspectRatio>
      </Box>
    </Flex>
  )
}

const OurTherapists = () => {
  const buttonHeight = useBreakpointValue({
    base: 38,
    md: 58,
  })
  const buttonFontSize = useBreakpointValue({
    base: 'md',
    md: 'xl',
  })
  const buttonFontWeight = useBreakpointValue({
    base: 500,
    md: 600,
  })

  const {data: featuredTherapists, loading} = useSupabaseQuery<FeaturedTherapist>({
    fields: '*',
    itemsPerPage: FEATURED_THERAPISTS_LIMIT,
    table: 'featured_therapists',
    errSnackbarTitle: 'Nie udało się wczytać wyróżnionych terapeutów',
  })

  return (
    <Container maxW={['100%', null, 'container.xl']} px={[0, null, 4]}>
      <Text
        as="h2"
        fontSize={['xl', null, '3xl']}
        fontWeight={500}
        mt={[5, null, 24]}
        mx={[8, null, 0]}
        mb={[-2, null, 10]}
      >
        Nasi specjaliści
      </Text>

      <Flex direction="column" alignItems="center" justifyContent="center" mb={12}>
        <CarouselProvider
          naturalSlideWidth={700}
          naturalSlideHeight={300}
          totalSlides={featuredTherapists?.length ?? 0}
          infinite={true}
          className="our-therapists-carousel"
          isIntrinsicHeight={true}
        >
          <Grid templateColumns="repeat(12, 1fr)" gap={8} alignItems="center" justifyContent="center">
            <GridItem colSpan={1} display={['none', null, 'block']}>
              <ButtonBack>
                <ArrowIconButton
                  aria-label="Poprzedni terapeuta"
                  width="64px"
                  color="brand.yellow.600"
                  _hover={{color: 'brand.yellow.700'}}
                  style={{transform: 'rotate(180deg)'}}
                />
              </ButtonBack>
            </GridItem>
            <GridItem colSpan={[12, null, 10]} position="relative" minWidth={0}>
              <Slider>
                {!loading &&
                  featuredTherapists?.map((t, i) => (
                    <Slide key={i} index={i}>
                      <Therapist
                        id={`${i}`}
                        name={t.full_name ?? ''}
                        full_name_slug={t.full_name_slug}
                        image={t.signed_avatar ?? undefined}
                        description={t.full_description}
                        specialties={t.specialties}
                      />
                    </Slide>
                  ))}
              </Slider>
            </GridItem>
            <GridItem colSpan={1} display={['none', null, 'block']}>
              <ButtonNext>
                <ArrowIconButton
                  aria-label="Następny terapeuta"
                  width="64px"
                  color="brand.yellow.600"
                  _hover={{color: 'brand.yellow.700'}}
                />
              </ButtonNext>
            </GridItem>
            <GridItem
              as={Flex}
              colStart={[0, null, 4]}
              colSpan={[12, null, 2]}
              justifyContent={['center', null, 'flex-start']}
              my={[4, null, 0]}
            >
              <Stack direction="row" spacing={6}>
                {Array.from(Array(featuredTherapists?.length).keys()).map((n) => (
                  <Dot key={n} slide={n}>
                    <Box borderRadius="full" width={['14px', null, '20px']} height={['14px', null, '20px']} />
                  </Dot>
                ))}
              </Stack>
            </GridItem>
            <GridItem
              colStart={[0, null, 8]}
              colSpan={[12, null, 4]}
              display="flex"
              direction="row"
              justifyContent={['center', null, 'flex-end']}
              pr={[0, null, 2]}
            >
              <ArrowButton
                as={RouterLink}
                to={PSYCHOTHERAPISTS}
                color="white"
                bg="brand.yellow.600"
                hoverBg="brand.yellow.700"
                height={buttonHeight}
                fontSize={buttonFontSize}
                fontWeight={buttonFontWeight}
              >
                <Text px={[4, null, 0]}>Poznaj wszystkich</Text>
              </ArrowButton>
            </GridItem>
          </Grid>
        </CarouselProvider>
      </Flex>
    </Container>
  )
}

export default OurTherapists
