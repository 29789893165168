import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'

import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

const MIN_LENGHT_TITLE = 2

const CourseNodeTitleEditor = ({
  open,
  onClose,
  initialValue,
  onApply,
  type,
  onSubmit,
}: CommonModalProps & {
  initialValue?: string
  type?: 'section' | 'page'
  onApply: (title: string) => void
  onSubmit: () => void
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')

  useEffect(() => {
    setIsEditing(!!initialValue)
    setTitle(initialValue ?? '')
  }, [initialValue])

  const handleTitleChange = useCallback(
    ({target: {value}}) => {
      setTitle(value)
      onApply(value)

      value.length < MIN_LENGHT_TITLE
        ? setTitleError('Tytuł musi składać się z co najmniej ' + MIN_LENGHT_TITLE + ' znaków.')
        : setTitleError('')
    },
    [onApply]
  )

  const {loading, handleSubmit} = useLoadingState(onSubmit)

  const isSubmitButtonDisabled = useMemo(
    () => !title || !!titleError || loading,
    [loading, title, titleError]
  )

  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {type === 'page'
            ? isEditing
              ? 'Edytuj stronę'
              : 'Utwórz stronę'
            : isEditing
            ? 'Edytuj sekcję'
            : 'Utwórz sekcję'}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormControl isInvalid={!!titleError}>
            <FormLabel htmlFor="title" mb={1} fontWeight={600}>
              {type === 'page' ? 'Nazwa strony' : 'Nazwa sekcji'}
            </FormLabel>
            <Input
              id="title"
              value={title}
              onChange={handleTitleChange}
              placeholder={type === 'page' ? 'Nazwa strony' : 'Nazwa sekcji'}
            />
            <FormErrorMessage>{titleError}</FormErrorMessage>
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} isDisabled={loading}>
            Anuluj
          </Button>
          <Button
            colorScheme="green"
            ml={3}
            disabled={isSubmitButtonDisabled}
            onClick={handleSubmit}
            isLoading={loading}
          >
            {isEditing ? 'Zapisz' : 'Utwórz'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CourseNodeTitleEditor
