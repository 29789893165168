import React from 'react'

import {useToast} from '@chakra-ui/react'
import {PostgrestFilterBuilder} from '@supabase/postgrest-js'

import {supabase} from '@/api'

import useRunOnReconnect from '../use-run-on-reconnect'

type Props<T extends {}> = {
  fields: string
  table: string
  order?: (keyof T)[]
  descending?: boolean
  autoRefetch?: boolean
  match?: Partial<Record<keyof T, any>>
  errSnackbarTitle?: string
  parsingFunction?: (item: any) => T
  filter?: (builder: PostgrestFilterBuilder<any, T, T[]>) => PostgrestFilterBuilder<any, T, T[]>
}

// TODO: rename it to `useSupabaseGetQuery`

const useFetchSingle = <T extends {}>({
  fields,
  table,
  match,
  order,
  errSnackbarTitle,
  filter,
  parsingFunction,
  descending = false,
  autoRefetch = true,
}: Props<T>) => {
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState<Error | null>(null)
  const [data, setData] = React.useState<T>()

  const fetch = React.useCallback(async () => {
    setLoading(true)
    try {
      let query: any = supabase.from(table).select(fields)

      if (order?.length) {
        order.forEach((o) => (query = query.order(o as any, {ascending: !descending})))
      }

      if (match) {
        query = query.match(match)
      }
      if (filter) {
        query = filter(query)
      }

      const {data, error} = await query.limit(1).maybeSingle()
      if (error) throw error

      setData(parsingFunction && data ? parsingFunction(data) : data)
    } catch (e) {
      setError(e as Error)
      toast({
        description: (e as Error).message,
        isClosable: true,
        title: errSnackbarTitle || 'Nie udało się wczytać zasobu',
        status: 'error',
      })
    } finally {
      setLoading(false)
    }
  }, [table, fields, match, errSnackbarTitle, order, descending, toast, filter, parsingFunction])

  useRunOnReconnect(fetch, !autoRefetch)

  React.useEffect(() => {
    autoRefetch && fetch()
  }, [match, filter]) // eslint-disable-line

  return {data, error, fetch, loading}
}

export default useFetchSingle
