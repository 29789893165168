import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const CheckmarkIcon = (props: IconProps) => (
  <Icon viewBox="0 0 57 57" {...props} fill="none">
    <path
      d="M28.5 4.75C15.4043 4.75 4.75 15.4043 4.75 28.5C4.75 41.5957 15.4043 52.25 28.5 52.25C41.5957 52.25 52.25 41.5957 52.25 28.5C52.25 15.4043 41.5957 4.75 28.5 4.75ZM28.5 47.5C18.0239 47.5 9.5 38.9761 9.5 28.5C9.5 18.0239 18.0239 9.5 28.5 9.5C38.9761 9.5 47.5 18.0239 47.5 28.5C47.5 38.9761 38.9761 47.5 28.5 47.5Z"
      fill="currentColor"
    />
    <path
      d="M23.7472 32.2691L18.2871 26.8184L14.9336 30.1814L23.752 38.9808L39.6787 23.054L36.3205 19.6958L23.7472 32.2691Z"
      fill="currentColor"
    />
  </Icon>
)

export default CheckmarkIcon
