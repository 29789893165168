import React from 'react'

import {AddIcon} from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {CourseAssignedCategory, CourseCategory, CourseCourseCategory} from '@/api/models'
import AsyncSelect from '@/common/async-select'
import LabeledSpinner from '@/common/components/labeled-spinner'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

import CourseCategoryItem from './category-item'

const CourseCategoriesModal = ({
  open,
  onClose,
  course,
  onComplete,
}: Omit<CommonModalProps, 'onComplete'> & {
  course: number
  onComplete: () => void
}) => {
  const toast = useToast()

  const [assignedCategories, setAssignedCategories] = React.useState<CourseAssignedCategory[]>([])
  const [isAdding, setIsAssigning] = React.useState(false)
  const [isDeleting, setIsDeleting] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState<CourseCategory | null>(null)

  const _fetchAssignedCourseCategories = React.useCallback(async () => {
    try {
      if (!course) {
        return
      }
      const {data, error} = await supabase.from('course_assigned_categories_view').select().match({course})
      if (error) {
        throw error
      }
      if (!data) {
        throw new Error('Failed to fetch course categories')
      }
      setAssignedCategories(data)
    } catch (e) {
      console.error('Failed to fetch course categories', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się pobrać kategorii kursu',
      })
    }
  }, [course, toast])
  const {handleSubmit: fetchAssignedCourseCategories, loading: isFetching} = useLoadingState(
    _fetchAssignedCourseCategories
  )

  React.useEffect(() => {
    fetchAssignedCourseCategories()
  }, [fetchAssignedCourseCategories])

  const handleCategoryAssign = React.useCallback(async () => {
    setIsAssigning(true)
    try {
      const {error} = await supabase.from('course_course_category').upsert({
        course,
        course_category: selectedValue?.id,
        created_at: assignedCategories.map((ac) => ac.id).includes(selectedValue?.id || '')
          ? undefined
          : new Date(),
        updated_at: new Date(),
      })
      if (error) {
        throw error
      }
      setSelectedValue(null)
      fetchAssignedCourseCategories()
    } catch (e) {
      console.error(e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się dodać kategorii kursu',
      })
    }
    setIsAssigning(false)
  }, [assignedCategories, course, fetchAssignedCourseCategories, selectedValue, setSelectedValue, toast])

  const handleCategoryAssignmentDelete = React.useCallback(
    async (category: string) => {
      setIsDeleting(true)
      try {
        const {count, error} = await supabase
          .from('course_course_category')
          .delete({count: 'exact'})
          .eq('course', course)
          .eq('course_category', category)
        if (error) {
          throw error
        }
        if (!count) {
          throw new Error('No rows deleted')
        }
        setAssignedCategories((assignedCategories) => assignedCategories.filter((c) => c.id !== category))
      } catch (e) {
        console.error('Failed to delete course category', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się usunąć kategorii kursu',
        })
      }
      setIsDeleting(false)
    },
    [course, toast]
  )

  const handleClose = React.useCallback(() => {
    onComplete && onComplete()
    onClose()
  }, [onClose, onComplete])

  const asyncSelectLoadOptions = React.useCallback(
    async (searchValue) => {
      try {
        const {data, error} = await supabase
          .from('course_categories')
          .select()
          .like('name', `%${searchValue}%`)
        if (error) {
          throw error
        }
        if (!data) {
          throw new Error('No response data received.')
        }
        return data
      } catch (e) {
        console.error('Failed to fetch assignable categores')
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać dostępnych kategorii',
        })
      }
    },
    [toast]
  )

  const optionLabel = React.useCallback((c: Pick<CourseCategory, 'id' | 'name'>) => c.name, [])

  const isLoading = React.useMemo(
    () => isAdding || isFetching || isDeleting,
    [isAdding, isFetching, isDeleting]
  )

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Kategorie kursu</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack width="100%">
            <FormControl id="req" isDisabled={isLoading}>
              <Stack>
                <HStack>
                  <Box w="100%">
                    <AsyncSelect
                      value={selectedValue}
                      defaultOptions={true}
                      loadOptions={asyncSelectLoadOptions}
                      getOptionLabel={optionLabel}
                      onChange={setSelectedValue}
                    />
                  </Box>
                  <IconButton
                    aria-label="add"
                    variant="ghost"
                    colorScheme="green"
                    icon={<AddIcon />}
                    onClick={handleCategoryAssign}
                    isDisabled={!selectedValue || isLoading}
                  />
                </HStack>
              </Stack>
            </FormControl>
            {isFetching ? (
              <Box py="5">
                <LabeledSpinner />
              </Box>
            ) : (
              <Stack>
                {assignedCategories.map((category, index) => (
                  <CourseCategoryItem
                    key={index}
                    item={category}
                    onDelete={handleCategoryAssignmentDelete}
                    isDisabled={isLoading}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={handleClose}>
            Zamknij
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CourseCategoriesModal
