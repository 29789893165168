export const youtubeRegex = /^(https?:\/\/)?(?:www\.|(?!www))youtube\.com\/watch\?.*/

export const getYouTubeVideoID = (value: string) => {
  const match = value.match(youtubeRegex)
  if (!match?.length) return null

  try {
    const url = new URL(value)
    const id = url.searchParams.get('v')
    if (id?.length !== 11) return null
    return id
  } catch {
    return null
  }
}
