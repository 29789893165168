import * as React from 'react'

import {Tag} from '@chakra-ui/react'

import {AdminUser} from '@/admin/types'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const defaultSelectedColumns = ['avatar', 'full_name', 'email', 'role']

export const columns: Column<AdminUser>[] = [
  {
    Header: 'Avatar',
    id: 'avatar',
    renderAs: 'avatar',
    sortable: false,
  },
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
    sortable: false,
  },
  {
    Header: 'Adres e-mail',
    id: 'email',
  },
  {
    Header: 'Imię i nazwisko',
    id: 'full_name',
    sortable: false,
  },
  {
    Header: 'Rola',
    id: 'role',
    renderer: ({role}) => <Tag>{role}</Tag>,
  },
  {
    Header: 'Terapeuta',
    id: 'is_therapist',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnie logowanie',
    id: 'last_sign_in_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Imię i nazwisko',
    name: 'full_name',
    variant: 'text',
  },
  {
    label: 'Adres e-mail',
    name: 'email',
    variant: 'text',
  },
  {
    label: 'Rola',
    name: 'role',
    values: [
      {label: 'admin', name: 'admin'},
      {label: 'user', name: 'user'},
    ],
    variant: 'select',
  },
  {
    label: 'Terapeuta',
    name: 'is_therapist',
    variant: 'boolean',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnie logowanie',
    name: 'last_sign_in_at',
    variant: 'datetime',
  },
]
