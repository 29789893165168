import * as React from 'react'

import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import Select, {SingleValue} from 'react-select'

import {SupportGroupRole} from '@/api/models'
import {CommonModalProps} from '@/utils/types'

import {RoleOption, roleOptions} from './constants'
import SupportGroupAccessManagementTable from './table'

// TODO: add functionality to send an invitation to a new group email to all people in queue
const AccessManagementModal = ({
  supportGroupID,
  open,
  onClose,
  onComplete,
}: CommonModalProps & {
  supportGroupID: number
}) => {
  const [role, setRole] = React.useState<SupportGroupRole>('member')

  const handleRoleChange = React.useCallback((v: SingleValue<RoleOption>) => {
    setRole(v?.value || 'member')
  }, [])

  const handleClose = React.useCallback(() => {
    onComplete && onComplete()
    onClose()
  }, [onClose, onComplete])

  return (
    <Modal isOpen={open} onClose={handleClose} size="6xl">
      <ModalOverlay />
      <ModalContent minW="500">
        <ModalHeader>Zarządzanie dostępem do grupy wsparcia</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack w="100%" px={8} mb={4}>
            <Text>Rola: </Text>
            <Box w="100%">
              <Select
                value={roleOptions.find((o) => o.value === role)}
                options={roleOptions}
                onChange={handleRoleChange}
                isSearchable={false}
              />
            </Box>
          </HStack>
          <SupportGroupAccessManagementTable supportGroupID={supportGroupID} role={role} />
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Zamknij
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AccessManagementModal
