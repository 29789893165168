import * as React from 'react'

import {Button, Stack, Text, Tooltip, useDisclosure} from '@chakra-ui/react'

import {selectProfile} from '@/auth/state'
import {useAppSelector} from '@/store'

import UserBilling from '../billing'
import ChangePassword from './change-password'
import DeleteUserConfirmationModal from './delete-user-confirmation-modal'

const Settings = () => {
  const profile = useAppSelector(selectProfile)
  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure()
  const {
    isOpen: isDeleteAccountOpen,
    onOpen: onDeleteAccountOpen,
    onClose: onDeleteAccountClose,
  } = useDisclosure()

  return (
    <Stack direction="column" spacing={8} ml={[0, null, 24]} mt={[0, null, 6]} mb={6} px={[4, null, 0]}>
      <UserBilling mode="edit" />
      <Text fontSize={20} fontWeight={500}>
        Zarządzanie kontem
      </Text>
      <Stack alignItems="flex-start" mb={6} spacing={3}>
        <Tooltip
          label={
            profile?.provider !== 'email'
              ? 'Zmiana hasła jest niedostępna dla użytkowników logujących się za pomocą Facebook/Google'
              : ''
          }
        >
          <span>
            <Button
              onClick={onChangePasswordOpen}
              variant="brandLink"
              isDisabled={profile?.provider !== 'email'}
            >
              Zmień hasło
            </Button>
          </span>
        </Tooltip>
        <Button onClick={onDeleteAccountOpen} variant="brandLink">
          Usuń konto
        </Button>
      </Stack>
      <ChangePassword open={isChangePasswordOpen} onClose={onChangePasswordClose} />
      <DeleteUserConfirmationModal onClose={onDeleteAccountClose} open={isDeleteAccountOpen} />
    </Stack>
  )
}

export default Settings
