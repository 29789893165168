import * as React from 'react'

import {useHistory} from 'react-router-dom'

import {SupportGroupJoined} from '@/api/models'
import {ArrowButton, Props as ArrowButtonProps} from '@/common/arrow-button'
import {useAppDispatch} from '@/store'
import {USER_SUPPORT_GROUPS} from '@/user/router'

import {initSupportGroupOrder} from '../state'

export const SupportGroupOrderModalButton = ({
  group,
  ...rest
}: ArrowButtonProps & {group: SupportGroupJoined}) => {
  const dispatch = useAppDispatch()
  const {push} = useHistory()

  const handleClick = React.useCallback(() => {
    group.has_access
      ? push(USER_SUPPORT_GROUPS)
      : dispatch(
          initSupportGroupOrder({
            groupID: group.id,
          })
        )
  }, [group, push, dispatch])

  return (
    <ArrowButton height={40} fontSize="lg" fontWeight={500} onClick={handleClick} {...rest}>
      {group.has_access ? 'Przejdź do grup wsparcia' : 'Kup teraz'}
    </ArrowButton>
  )
}

export default SupportGroupOrderModalButton
