import * as React from 'react'

import {useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {BuyResult} from '@/api/models'

export const useRetryPayment = (id?: string) => {
  const toast = useToast()

  const retryPayment = React.useCallback(async () => {
    if (!id) return

    try {
      const {data, error} = await supabase.rpc('retry_order_payment', {
        order_id: id,
      })
      if (error) throw error
      if (!data) throw new Error('No data')

      const res = data as any as BuyResult
      if ('error' in res) throw new Error(res.error)
      if (!res.redirect_url) throw new Error('Invalid redirect URL')

      window.location.href = res.redirect_url
    } catch (e) {
      console.error('Failed to retry payment', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się ponowić płatności',
      })
    }
  }, [toast, id])

  return retryPayment
}
