import * as React from 'react'

import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {MultiValue} from 'react-select'
import AsyncSelect from 'react-select/async'

import {supabase} from '@/api'
import {SupportGroupJoined} from '@/api/models'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import ImageUpload from '@/common/image-upload'
import useEditorModalState from '@/common/use-editor-modal'
import {SUPABASE_SUPPORT_GROUPS_BUCKET} from '@/constants'
import {CommonModalProps, SelectOption} from '@/utils/types'

import {emptySupportGroup} from '../constants'
import {inputToUpsertArgs} from './utils'

const SupportGroupEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: SupportGroupJoined | null}) => {
  const {input, handleInputChange, handleCustomInputChange, image, setImage, handleUpsert, loading} =
    useEditorModalState<SupportGroupJoined>({
      item,
      table: 'support_groups',
      customRPC: 'upsert_support_group',
      emptyInput: emptySupportGroup,
      inputToUpsertArgs,
      open,
      onComplete,
      onClose,
      imageParams: {
        bucket: SUPABASE_SUPPORT_GROUPS_BUCKET,
        key: 'thumbnail',
      },
    })

  const handleFeaturedChange = React.useCallback(
    (v: string) => handleCustomInputChange({featured_index: v ? +v : null}),
    [handleCustomInputChange]
  )
  const handleMaxMembersChange = React.useCallback(
    (v: string) => handleCustomInputChange({max_members: v ? +v : 0}),
    [handleCustomInputChange]
  )
  const handleMinMembersChange = React.useCallback(
    (v: string) => handleCustomInputChange({min_members: v ? +v : 0}),
    [handleCustomInputChange]
  )
  const handlePriceChange = React.useCallback(
    (v: string) => handleCustomInputChange({price: v ? +v * 100 : 0}),
    [handleCustomInputChange]
  )
  const handleFullDescriptionChange = React.useCallback(
    (value) => handleCustomInputChange({full_description: value}),
    [handleCustomInputChange]
  )
  const handleShortDescriptionChange = React.useCallback(
    (value) => handleCustomInputChange({short_description: value}),
    [handleCustomInputChange]
  )
  const handleCategoriesChange = React.useCallback(
    (value: MultiValue<SelectOption>) =>
      handleCustomInputChange({categories: value?.map((v) => v.value) || []}),
    [handleCustomInputChange]
  )

  // async select categories
  const asyncSelectLoadCategories = React.useCallback(async (searchValue) => {
    try {
      const query = supabase.from('support_group_categories').select('name')
      if (searchValue) {
        query.like('name', `%${searchValue}%`)
      }
      const {data, error} = await query
      if (error) throw error

      return data?.map((c) => ({label: c.name, value: c.name})) ?? []
    } catch (e) {
      console.error(e)
    }
  }, [])

  const selectedCategories = React.useMemo(
    () => input.categories?.map((c) => ({label: c, value: c})),
    [input.categories]
  )

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.max_members || (!!input.price && input.price < 0),
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj grupę wsparcia' : 'Utwórz grupę wsparcia'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading} as={Stack} align="center">
              <FormLabel mb={1} fontWeight={600}>
                Miniaturka
              </FormLabel>
              <ImageUpload width={200} height={200} editing={true} value={image} onChange={setImage} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  name="published"
                  colorScheme="whatsapp"
                  defaultChecked={input.published}
                  onChange={handleInputChange}
                />
                <FormLabel>Opublikowana?</FormLabel>
              </HStack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa</FormLabel>
              <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Cena (PLN)</FormLabel>
              <NumberInput
                value={String((input.price || 0) / 100)} // TODO: input cents
                min={0}
                precision={2}
                onChange={handlePriceChange}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Indeks wyróżnienia</FormLabel>
              <NumberInput value={input.featured_index ?? ''} min={0} onChange={handleFeaturedChange}>
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Liczba członków</FormLabel>
              <HStack w="100%">
                <NumberInput
                  flex={1}
                  value={input.min_members ?? ''}
                  min={0}
                  onChange={handleMinMembersChange}
                >
                  <NumberInputField />
                </NumberInput>
                <Text>-</Text>
                <NumberInput
                  flex={1}
                  value={input.max_members ?? ''}
                  min={0}
                  onChange={handleMaxMembersChange}
                >
                  <NumberInputField />
                </NumberInput>
              </HStack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Kategorie</FormLabel>
              <AsyncSelect
                isDisabled={loading}
                value={selectedCategories}
                isMulti={true}
                defaultOptions={true}
                loadOptions={asyncSelectLoadCategories}
                onChange={handleCategoriesChange}
                styles={{menu: (base) => ({...base, zIndex: 'var(--chakra-zIndices-popover)'})}}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Pełny opis</FormLabel>
              <ContentEditor
                id="admin-panel-support-group-full-description"
                variant="extended"
                value={input?.full_description ?? initialEditorValue}
                onChange={handleFullDescriptionChange}
                readOnly={false}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Krótki opis</FormLabel>
              <ContentEditor
                id="admin-panel-support-group-short-description"
                variant="basic"
                value={input?.short_description ?? initialEditorValue}
                onChange={handleShortDescriptionChange}
                readOnly={false}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opis meta</FormLabel>
              <Textarea
                name="meta_description"
                value={input.meta_description ?? ''}
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  name="is_finished"
                  colorScheme="whatsapp"
                  defaultChecked={input.is_finished}
                  onChange={handleInputChange}
                />
                <FormLabel>Zakończona?</FormLabel>
              </HStack>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={isSubmitDisabled}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default SupportGroupEditorModal
