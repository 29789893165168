import React from 'react'

import {emptyQuestionAnswerItem} from '@/admin/utils'
import {supabase} from '@/api'
import {DeleteQuestionAnswerItemResult, QuestionAnswerItem} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns} from './constants'
import QuestionsAndAnswersEditorModal from './editor-modal'

// It would probably make more sense to have one table for all q&a's and have `id` (type / location) as a filter
// for now there's only `faq`
export const FrequentlyAskedQuestions = () => <QuestionsAndAnswersTable id="faq" />

const QuestionsAndAnswersTable = ({id}: {id: string}) => {
  const tableState = useTableState({sortBy: 'question'})
  const {
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
    value: deleteValue,
  } = useValueDisclosure<QuestionAnswerItem>()
  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<QuestionAnswerItem>()
  const {loading, data, fetch, rows} = useSupabaseQuery<QuestionAnswerItem>({
    descending: tableState.descending,
    fields: '*',
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof QuestionAnswerItem,
    pageNumber: tableState.page,
    table: 'questions_answers_items',
    finalize: React.useCallback((b) => b.eq('question_answer', id), [id]),
  })
  const [currentMode, setCurrentMode] = React.useState<'edit' | 'create'>('create')

  const handleDeleteAction = React.useCallback(
    (item: QuestionAnswerItem) => {
      onDeleteOpen(item)
    },
    [onDeleteOpen]
  )

  const handleEdit = React.useCallback(
    (item: QuestionAnswerItem) => {
      setCurrentMode('edit')
      onEditorOpen(item)
    },
    [onEditorOpen]
  )

  const actions = React.useMemo(
    () => [editAction(handleEdit), deleteAction(handleDeleteAction)],
    [handleEdit, handleDeleteAction]
  )

  const handleAdd = React.useCallback(() => {
    setCurrentMode('create')
    onEditorOpen(emptyQuestionAnswerItem())
  }, [onEditorOpen, setCurrentMode])

  const handleCompleteEdit = React.useCallback(() => {
    fetch()
    onEditorClose()
  }, [fetch, onEditorClose])

  const handleDelete = React.useCallback(async () => {
    const {data, error} = await supabase.rpc('delete_question_answer_item', {
      item_id: deleteValue?.id ?? null,
      page_id: id,
    })
    if (error) {
      throw error?.message || error
    }
    if (!data) {
      throw new Error(`Invalid saving result: ${data}`)
    }
    const result = data as any as DeleteQuestionAnswerItemResult
    if ('error' in result) {
      throw result.error
    }
  }, [deleteValue?.id, id])

  return (
    <>
      <DataTable<QuestionAnswerItem>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <QuestionsAndAnswersEditorModal
        pageID={id}
        item={editorValue ?? emptyQuestionAnswerItem()}
        onClose={onEditorClose}
        open={!!editorValue}
        onComplete={handleCompleteEdit}
        mode={currentMode}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default QuestionsAndAnswersTable
