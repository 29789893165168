import React from 'react'

import {AdminOrder} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import useSupabaseQuery from '@/common/use-custom-query'

import {columns, defaultSelectedColumns, filterFields} from './constants'
import {showInvoicesAction, showProductsAction} from './utils'

const Orders = () => {
  const tableState = useTableState({descending: true, sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminOrder>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminOrder,
    pageNumber: tableState.page,
    table: 'admin_orders',
  })

  const actions = React.useMemo(() => [showProductsAction, showInvoicesAction], [])

  return (
    <DataTable<AdminOrder>
      table="admin_orders"
      columns={columns}
      data={data ?? []}
      totalCount={rows}
      tableState={tableState}
      actions={actions}
      onRefresh={fetch}
      loading={loading}
      filterFields={filterFields}
      defaultSelectedColumns={defaultSelectedColumns}
    />
  )
}

export default Orders
