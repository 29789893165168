import React, {useCallback, useState} from 'react'

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
} from '@chakra-ui/react'

import {CourseRole} from '@/api/models'

import CourseAccessManagementTable from './table'

const AccessManagementModal = ({
  course,
  isOpen,
  onClose,
}: {
  course: number
  isOpen: boolean
  onClose: () => void
}) => {
  const [userType, setUserType] = useState<CourseRole>('user')

  const handleUserTypeChange = useCallback(({target: {value}}) => {
    setUserType(value)
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent minW="500">
        <ModalHeader>Zarządzanie dostępem</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Select value={userType} onChange={handleUserTypeChange}>
            <option value="user">Użytkownicy</option>
            <option value="course_author">Autorzy</option>
            <option value="coach">Prowadzący szkolenie</option>
          </Select>

          <CourseAccessManagementTable course={course} type={userType} />
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            Zamknij
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default AccessManagementModal
