import React from 'react'

import {AdminOrderItem} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import useSupabaseQuery from '@/common/use-custom-query'

import {columns, defaultSelectedColumns, filterFields} from './constants'

const Orders = () => {
  const tableState = useTableState({descending: true, sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<AdminOrderItem>({
    table: 'admin_order_items',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof AdminOrderItem,
    filter: tableState.filter,
  })

  return (
    <DataTable<AdminOrderItem>
      table="admin_order_items"
      columns={columns}
      data={data ?? []}
      totalCount={rows}
      tableState={tableState}
      onRefresh={fetch}
      loading={loading}
      filterFields={filterFields}
      defaultSelectedColumns={defaultSelectedColumns}
    />
  )
}

export default Orders
