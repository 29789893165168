import React from 'react'

import {Link, Spacer} from '@chakra-ui/layout'
import {Box, Flex, IconButton, Text, useToast} from '@chakra-ui/react'
import {
  createPluginFactory,
  insertNodes,
  PlateEditor,
  PlateRenderElementProps,
  usePlateEditorState,
} from '@udecode/plate'
import randomstring from 'randomstring'
import {MdClose, MdAttachment, MdFileDownload} from 'react-icons/md'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'

import {EDITOR_BLOCK_ID_LENGTH} from '../constants'
import {AttachmentElement, CommonScope} from '../custom-types'
import {EditorContext} from '../editor-context'
import selectFile, {SelectionCancelledError} from '../select-file'
import {BlockButton} from '../toolbar/buttons'

export const AttachmentRenderer = ({attributes, children, element}: PlateRenderElementProps) => {
  const {readOnly} = React.useContext(EditorContext)

  const editor = usePlateEditorState()!
  const path = ReactEditor.findPath(editor, element)

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: path})
  }, [editor, path])

  const el = element as AttachmentElement
  const fileName = el.path?.substring(el.path?.lastIndexOf('/') + 1) || ''

  return (
    <Flex position="relative" mb={4} {...attributes} contentEditable={false} userSelect="none">
      {children}
      <Box width="100%">
        <Flex direction="row" alignItems="center" width="100%">
          <MdAttachment size={24} />
          <Text pl={4}>{fileName}</Text>
          <Spacer />
          <IconButton
            as={Link}
            variant="outline"
            href={el.url}
            target="_blank"
            download={true}
            icon={<MdFileDownload />}
            aria-label="Pobierz"
          />
          {!readOnly && (
            <IconButton
              ml={2}
              variant="outline"
              onClick={handleRemove}
              icon={<MdClose />}
              aria-label="Usuń załącznik"
            />
          )}
        </Flex>
      </Box>
    </Flex>
  )
}

export const InsertAttachmentButton = ({
  bucketName,
  bucketScope,
}: {
  bucketName: string
  bucketScope: number | string
}) => {
  const toast = useToast()

  const editor = usePlateEditorState()!

  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()

      try {
        const files = await selectFile({accept: '*'})
        if (files.length !== 1) {
          throw new Error('Invalid files count')
        }

        const file = files[0]
        const [path, signedURL] = await uploadAttachment(bucketName, bucketScope, file)
        insertAttachment(editor, path, signedURL)
      } catch (e) {
        console.error('Failed to insert attachment', e)
        if (!(e instanceof SelectionCancelledError)) {
          toast({
            isClosable: true,
            status: 'error',
            title: 'Nie udało się wstawić załącznika.',
          })
        }
      }
    },
    [bucketName, bucketScope, editor, toast]
  )

  return <BlockButton label="Załącz plik" format="attachment" icon={<MdAttachment />} onClick={handleClick} />
}

const uploadAttachment = async (bucket: string, scope: string | number, file: File) => {
  const filename = `${uuidv4()}/${file.name}`
  const filepath = `/${scope}/${filename}`

  const {error} = await supabase.storage.from(bucket).upload(filepath.substr(1), file, {upsert: true})
  if (error) {
    throw error
  }
  const {data, error: signedURLError} = await supabase.storage
    .from(bucket)
    .createSignedUrl(filepath.substr(1), 86400) // 24 hours
  if (signedURLError) {
    throw signedURLError
  }
  if (!data || !data.signedUrl) {
    throw new Error('Empty signed url response')
  }

  return [filepath, data.signedUrl]
}

const insertAttachment = (editor: PlateEditor, path: string, url: string) => {
  const text = {text: ''}
  const attachment: AttachmentElement = {
    children: [text],
    id: randomstring.generate(EDITOR_BLOCK_ID_LENGTH),
    path,
    type: 'attachment',
    url,
  }
  insertNodes<AttachmentElement>(editor, attachment)
}

export const createAttachmentPlugin = createPluginFactory({
  component: AttachmentRenderer,
  isElement: true,
  isVoid: true,
  key: 'attachment',
  type: 'attachment',
  withOverrides: (editor, plugin) => {
    const {bucketName, bucketScope} = plugin.options as CommonScope

    const {insertData} = editor
    editor.insertData = (dataTransfer: DataTransfer) => {
      const {files} = dataTransfer
      if (files && files.length > 0) {
        Promise.all([...files].map((file) => uploadAttachment(bucketName, bucketScope, file))).then(
          (response) => {
            response.forEach(([path, signedURL]) => insertAttachment(editor, path, signedURL))
          }
        )
      } else {
        insertData(dataTransfer)
      }
    }

    return editor
  },
})
