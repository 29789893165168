import {checkVAT, poland, unitedKingdom} from 'jsvat'

import {SupportedCountry, UserBillingAddress} from '@/api/models'

import {countryToPostalCodeLocale} from './constants'

export const isNIPValid = (country: SupportedCountry, n?: string) => {
  if (!n) return true

  const result = checkVAT(countryToPostalCodeLocale[country] + n, [country === 'gb' ? unitedKingdom : poland])
  return result.isValid && result.isValidFormat
}

export const toPayload = (address: UserBillingAddress[]) =>
  address.map((a) => ({
    address: a.address || null,
    city: a.city || null,
    country: a.country || null,
    company: a.company || null,
    email: a.email || null,
    first_name: a.first_name || null,
    id: a.id || null,
    last_name: a.last_name || null,
    nip: a.nip || null,
    phone_number: a.phone_number || null,
    postal_code: a.postal_code || null,
    requests_company_invoice: a.requests_company_invoice,
    same_shipping_address: a.same_shipping_address,
    type: a.type,
    use_basic_address: a.use_basic_address,
  }))
