import React from 'react'

import {Link} from 'react-router-dom'

import TreeNode from './TreeNode'

// TreeNodeWrapper adds link to leaf nodes based on 'link' prop
const TreeNodeWrapper = (props: any) =>
  props.isLeaf ? (
    <Link to={props.data.link}>
      <TreeNode {...props} />
    </Link>
  ) : (
    <TreeNode {...props} />
  )

export default TreeNodeWrapper
