import React from 'react'

import {Box, Flex, FlexProps, FormLabel, Input, Stack, Text, useToast} from '@chakra-ui/react'

import {supabase} from '@/api'
import {ArrowButton} from '@/common/arrow-button'
import useLoadingState from '@/common/use-loading-state'
import {WEB_BASE_URI} from '@/constants'
import {RESET_PASSWORD} from '@/router/paths'

export type Props = FlexProps

const SignInForm = (props: Props) => {
  const toast = useToast()

  const [opacity, setOpacity] = React.useState(0)
  const [complete, setComplete] = React.useState(false)

  const [email, setEmail] = React.useState('')
  const handleEmailChange = React.useCallback(({target: {value}}) => setEmail(value), [setEmail])

  const _handleSubmit = React.useCallback(async () => {
    if (!email) {
      return
    }
    try {
      const {error} = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: `${WEB_BASE_URI}${RESET_PASSWORD}`,
      })
      if (error) {
        throw new Error(error.message)
      }
      setOpacity(0)
      setTimeout(() => setComplete(true), 200)
      setTimeout(() => setOpacity(1), 400)
    } catch (e) {
      console.error('Failed to send email', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Wystąpił błąd podczas resetowania hasła',
      })
    }
  }, [email, setComplete, setOpacity, toast])
  const {loading, handleSubmit} = useLoadingState(_handleSubmit)
  const handleFormSubmit = React.useCallback(
    (e: React.FormEvent) => {
      e.preventDefault()
      handleSubmit()
    },
    [handleSubmit]
  )

  return (
    <Flex
      as="form"
      direction="column"
      alignItems="center"
      justifyContent="center"
      onSubmit={handleFormSubmit}
      opacity={opacity}
      transitionDuration="var(--chakra-transition-duration-normal)"
      transitionProperty="opacity"
      {...props}
    >
      {complete ? (
        <>
          <Text fontSize="lg" fontWeight={700} mb={8}>
            Gotowe
          </Text>
          <Text fontSize="md" fontWeight={300} mb={8}>
            Na podany adres e-mail została wysłana wiadomość z linkiem do strony, na której ustawisz swoje
            nowe hasło.
          </Text>
          {/*
            <Stack spacing={1} direction="row" fontSize="sm" fontWeight={300} alignItems="center">
              <Text>E-mail nie dotarł?</Text>
              <Button variant="brandLink" size="sm" fontSize="sm" onClick={handleSubmit}>
                Wyślij ponownie
              </Button>
            </Stack>
          */}
        </>
      ) : (
        <>
          <Text fontSize="lg" fontWeight={700} mb={8}>
            Nie pamiętasz hasła?
          </Text>
          <Text fontSize="md" fontWeight={300} mb={8} textAlign="center">
            Podaj swój adres e-mail, a my pomożemy Ci ustawić nowe hasło.
          </Text>
          <Box width="100%" mb={8}>
            <FormLabel htmlFor="email" fontSize="sm" fontWeight={300} mb={0}>
              Wpisz swój e-mail
            </FormLabel>
            <Input
              name="email"
              type="email"
              placeholder="przyklad@email.com"
              size="lg"
              autoFocus={true}
              onChange={handleEmailChange}
              value={email}
            />
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" mb={8}>
            <ArrowButton
              type="submit"
              bg="brand.yellow.600"
              hoverBg="brand.yellow.700"
              color="white"
              height={40}
              fontSize="lg"
              fontWeight={500}
              isDisabled={loading || email === ''}
              isLoading={loading}
            >
              <Text px={8} fontSize="lg">
                Dalej
              </Text>
            </ArrowButton>
          </Stack>
        </>
      )}
    </Flex>
  )
}

export default SignInForm
