export const getBlogPostPublishedAtColor = (publishedAt: Date | null, colorMode: 'light' | 'dark') =>
  publishedAt
    ? publishedAt > new Date()
      ? colorMode === 'light'
        ? 'green.500'
        : 'green.200'
      : colorMode === 'light'
      ? 'gray'
      : 'darkgray'
    : 'red.500'

export const calculateReadTime = (wordsCount: number) => Math.max(1, Math.ceil(wordsCount / 300))
