import * as React from 'react'

import {AddIcon, CloseIcon, EditIcon, CheckIcon} from '@chakra-ui/icons'
import {useToast, Text, Stack, HStack, Heading, IconButton, Tooltip} from '@chakra-ui/react'
import Select, {SingleValue} from 'react-select'

import {supabase} from '@/api'
import {MyTherapistService, parseTherapistService} from '@/api/models'
import useSupabaseQuery from '@/common/use-custom-query'
import useLoadingState from '@/common/use-loading-state'
import {formatDurationShort} from '@/utils/duration'
import {SelectOption} from '@/utils/types'

import ServiceItem from './service-item'

const TherapistsServices = () => {
  const toast = useToast()
  const [editing, setEditing] = React.useState(false)
  const [services, setServices] = React.useState<MyTherapistService[]>([])
  const [currentServiceID, setCurrentServiceID] = React.useState<string>()

  const {
    data,
    loading: fetching,
    fetch,
  } = useSupabaseQuery<MyTherapistService>({
    table: 'my_therapist_services',
    fields: '*',
    order: 'order',
    parsingFunction: parseTherapistService,
  })
  React.useEffect(() => setServices(data || []), [data])

  const {handleSubmit, loading: saving} = useLoadingState(
    React.useCallback(async () => {
      try {
        const {error} = await supabase.rpc('update_therapist_services', {
          services: services.filter((s) => s.assigned).map((s) => s.id),
        })
        if (error) throw error

        fetch()
        setEditing(false)
        toast({title: 'Usługi zapisane', status: 'success', isClosable: true})
      } catch (e) {
        console.error('Failed to save therapist services', e)
        toast({
          title: 'Nie udało się zapisać usług',
          status: 'error',
          isClosable: true,
        })
      }
    }, [services, toast, fetch])
  )

  const handleEdit = React.useCallback(() => setEditing(true), [])

  const handleEditCancel = React.useCallback(() => {
    setEditing(false)
    setServices(data || [])
  }, [data])

  const handleServiceAdd = React.useCallback(() => {
    setServices((prev) => prev.map((s) => (s.id === currentServiceID ? {...s, assigned: true} : s)))
    setCurrentServiceID(undefined)
  }, [currentServiceID])

  const handleServiceDelete = React.useCallback((id: string) => {
    setServices((prev) => prev.map((s) => (s.id === id ? {...s, assigned: false} : s)))
  }, [])

  const handleCurrentServiceChange = React.useCallback(
    (v: SingleValue<SelectOption>) => setCurrentServiceID(v?.value),
    []
  )
  const availableServices = React.useMemo(
    () =>
      services
        .filter((s) => !s.assigned)
        .map((s) => ({
          value: s.id,
          label: `${s.name} ( ${s.price / 100}zł / ${formatDurationShort(s.meeting_duration)} )`,
        })),
    [services]
  )

  const assignedServices = React.useMemo(() => services.filter((s) => s.assigned), [services])

  const loading = React.useMemo(() => fetching || saving, [fetching, saving])

  return (
    <Stack spacing={6}>
      <HStack spacing={4}>
        <Heading size="md" fontWeight={500}>
          Twoje usługi
        </Heading>
        {editing ? (
          <>
            <HStack>
              <Tooltip label="Anuluj">
                <IconButton
                  aria-label="cancel"
                  size="sm"
                  colorScheme="red"
                  icon={<CloseIcon />}
                  isDisabled={loading}
                  onClick={handleEditCancel}
                />
              </Tooltip>
              <Tooltip label="Zapisz">
                <IconButton
                  aria-label="save"
                  size="sm"
                  colorScheme="green"
                  icon={<CheckIcon />}
                  isDisabled={loading}
                  onClick={handleSubmit}
                />
              </Tooltip>
            </HStack>
          </>
        ) : (
          <Tooltip label="Edytuj">
            <IconButton
              aria-label="edit-services"
              size="sm"
              bg="brand.green.300"
              icon={<EditIcon />}
              isDisabled={loading}
              onClick={handleEdit}
            />
          </Tooltip>
        )}
      </HStack>
      <Stack spacing={4}>
        {assignedServices.length
          ? assignedServices.map((s, i) => (
              <ServiceItem key={i} item={s} editing={editing} onDelete={handleServiceDelete} />
            ))
          : !editing && <Text fontSize="lg">Nie masz przypisanych żadnych usług</Text>}
        {editing && (
          <HStack>
            <Tooltip label="Dodaj usługę">
              <IconButton
                aria-label="add-service"
                size="sm"
                variant="outline"
                colorScheme="green"
                icon={<AddIcon />}
                onClick={handleServiceAdd}
                isDisabled={loading || !availableServices.length || !currentServiceID}
              />
            </Tooltip>
            <Select
              placeholder={
                availableServices.length ? 'Wybierz usługę...' : 'Już wykonujesz wszystkie dostępne usługi!'
              }
              value={availableServices.find((o) => o.value === currentServiceID) || null}
              options={availableServices}
              onChange={handleCurrentServiceChange}
              isDisabled={loading}
              isSearchable={false}
            />
          </HStack>
        )}
      </Stack>
    </Stack>
  )
}

export default TherapistsServices
