import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {RootState} from '@/store'

export interface privacyPolicyState {
  cookieConsent: boolean
}

const initialState: privacyPolicyState = {
  cookieConsent: false,
}

export const privacyPolicySlice = createSlice({
  initialState,
  name: 'privacyPolicy',
  reducers: {
    setCookieConsent: (state, action: PayloadAction<boolean>) => {
      state.cookieConsent = action.payload
    },
  },
})

export const {setCookieConsent} = privacyPolicySlice.actions

export const selectCookieConsent = (state: RootState) => state.privacyPolicy.cookieConsent

export default privacyPolicySlice.reducer
