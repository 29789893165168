import * as React from 'react'

import {Text, Stack, FormControl, Checkbox, Flex, Spacer} from '@chakra-ui/react'

const OrderAgreement = ({isChecked, onChange}: {isChecked: boolean; onChange: (v: boolean) => void}) => {
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked),
    [onChange]
  )

  return (
    <Flex>
      <Spacer display={['none', null, null, 'block']} />
      <Stack px={[0, null, 20]} flex="1" spacing={4} fontWeight={300}>
        <FormControl>
          <Checkbox isChecked={isChecked} onChange={handleChange} id="agreement" size="lg">
            <Text fontSize="xs" pl={4}>
              Żądam wykonania Usługi przed upływem terminu na złożenie oświadczenia o odstąpieniu od umowy
              zawartej na odległość oraz przyjmuję do wiadomości, że z chwilą wykonania Usługi utracę prawo do
              odstąpienia od umowy.
              <Text as="span" color="red" pl={1}>
                *
              </Text>
            </Text>
          </Checkbox>
        </FormControl>
        <Text fontWeight={300}>
          <Text as="span" color="red" pr={1}>
            *
          </Text>
          Pola wymagane
        </Text>
      </Stack>
    </Flex>
  )
}

export default OrderAgreement
