import React from 'react'

import {Container, Heading, Stack, Text} from '@chakra-ui/react'
import {formatISO, parseISO} from 'date-fns'
import Select, {SingleValue} from 'react-select'

import {parseTherapistService} from '@/api/models'
import {therapistsListComponents, therapistsListStyles} from '@/common/theme/components/select'
import TimeSlotSelector from '@/psychotherapy/therapists/selector'
import {
  selectOrderActive,
  selectOrderCourse,
  selectOrderDate,
  selectOrderService,
  selectServices,
  selectServicesLoading,
  selectTherapist,
  setOrderDate,
  setOrderService,
  startPsychotherapyOrder,
} from '@/psychotherapy/therapists/state'
import {useAppDispatch, useAppSelector} from '@/store'
import {formatDurationShort} from '@/utils/duration'
import {SelectOption} from '@/utils/types'

const MeetingSelector = () => {
  const dispatch = useAppDispatch()
  const therapist = useAppSelector(selectTherapist)
  const isOrderActive = useAppSelector(selectOrderActive)
  const date = useAppSelector(selectOrderDate)

  const course = useAppSelector(selectOrderCourse)
  const service = useAppSelector(selectOrderService)
  const services = useAppSelector(selectServices)
  const servicesLoading = useAppSelector(selectServicesLoading)
  const servicesOptions = React.useMemo(
    () =>
      services
        ?.map((s) => parseTherapistService(s))
        .map((s) => ({
          label: `${s.name} (${s.price / 100}zł / ${formatDurationShort(s.meeting_duration)})`,
          value: s.id,
        })) ?? [],
    [services]
  )
  const handleServiceChange = React.useCallback(
    (v: SingleValue<SelectOption>) => dispatch(setOrderService(services.find((s) => s.id === v?.value))),
    [dispatch, services]
  )

  React.useEffect(() => {
    if (service || !services.length || course) return
    dispatch(setOrderService(services[0]))
  }, [service, services]) // eslint-disable-line

  const handleTimeSlotClick = React.useCallback(
    (date: Date) => {
      if (!therapist?.id || !service) return

      dispatch(
        isOrderActive
          ? setOrderDate(formatISO(date))
          : startPsychotherapyOrder({
              therapist: therapist?.id,
              date,
            })
      )
    },
    [therapist, isOrderActive, service, dispatch]
  )

  return (
    <Stack spacing={6} align="center">
      <Stack w="100%" maxW="container.md" textAlign="center">
        <Heading fontSize="lg">Umów wizytę</Heading>
        {course ? (
          <>
            <Text fontSize="xl">"{course.name}"</Text>
            <Text>
              Masz jeszcze <b>{course.meetings_left}</b>{' '}
              {course.meetings_left === 1 ? 'spotkanie' : course.meetings_left < 5 ? 'spotkania' : 'spotkań'}{' '}
              do wykorzystania
            </Text>
          </>
        ) : (
          <Select
            placeholder="Wybierz rodzaj sesji"
            value={servicesOptions.find((o) => o.value === service?.id)}
            options={servicesOptions}
            onChange={handleServiceChange}
            isLoading={servicesLoading}
            isSearchable={false}
            components={therapistsListComponents}
            styles={therapistsListStyles}
          />
        )}
      </Stack>
      <Container maxW="container.lg" p={0}>
        {!!therapist?.id && !!service && (
          <TimeSlotSelector
            therapist={therapist.id}
            service={service.id}
            onClick={handleTimeSlotClick}
            selectedDate={date ? parseISO(date) : undefined}
          />
        )}
      </Container>
    </Stack>
  )
}

export default MeetingSelector
