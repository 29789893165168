import * as React from 'react'

import {Container, Heading, Stack} from '@chakra-ui/react'
import Select, {SingleValue} from 'react-select'

import {TherapistAssignedService, parseTherapistService} from '@/api/models'
import {selectProfile} from '@/auth/state'
import {therapistsListComponents, therapistsListStyles} from '@/common/theme/components/select'
import useSupabaseQuery from '@/common/use-custom-query'
import TimeSlotSelector from '@/psychotherapy/therapists/selector'
import {useAppSelector} from '@/store'
import {formatDurationShort} from '@/utils/duration'
import {SelectOption} from '@/utils/types'

const TherapistTimeSlots = ({therapistID}: {therapistID?: number}) => {
  const userProfile = useAppSelector(selectProfile)

  const [service, setService] = React.useState<TherapistAssignedService>()
  const {data: services, loading: servicesLoading} = useSupabaseQuery<TherapistAssignedService>(
    React.useMemo(
      () => ({
        table: 'therapist_assigned_services',
        fields: '*',
        match: {therapist: therapistID || userProfile?.therapist_id},
        order: 'order',
        parsingFunction: parseTherapistService,
      }),
      [therapistID, userProfile]
    )
  )
  const servicesOptions = React.useMemo(
    () =>
      services?.map((s) => ({
        label: `${s.name} (${s.price / 100}zł / ${formatDurationShort(s.meeting_duration)})`,
        value: s.id,
      })) ?? [],
    [services]
  )
  const handleServiceChange = React.useCallback(
    (v: SingleValue<SelectOption>) => setService(services?.find((s) => s.id === v?.value)),
    [services]
  )

  React.useEffect(() => {
    if (service || !services?.length) return
    setService(services[0])
  }, [service, services])

  return (
    <>
      <Heading size="md">Przedziały czasowe</Heading>
      <Stack spacing={6} w="100%" align="center">
        <Container maxW="container.md" textAlign="center">
          <Select
            placeholder="Wybierz rodzaj sesji"
            value={servicesOptions.find((o) => o.value === service?.id)}
            options={servicesOptions}
            onChange={handleServiceChange}
            isLoading={servicesLoading}
            isSearchable={false}
            components={therapistsListComponents}
            styles={therapistsListStyles}
          />
        </Container>
        <Container maxW="container.lg" p={0}>
          {!!userProfile?.therapist_id && !!service && (
            <TimeSlotSelector therapist={therapistID || userProfile.therapist_id} service={service.id} />
          )}
        </Container>
      </Stack>
    </>
  )
}

export default TherapistTimeSlots
