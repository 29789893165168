import {v4 as uuidv4} from 'uuid'

export class SelectionCancelledError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'SelectionCancelledError'
  }
}

type Options = {
  accept?: string
  multiple?: boolean
}

const selectFile = ({accept, multiple}: Options): Promise<FileList> => {
  return new Promise((resolve, reject) => {
    const el = document.createElement('input')
    el.type = 'file'
    el.style.display = 'none'
    if (accept) {
      el.accept = accept
    }
    if (multiple) {
      el.multiple = true
    }
    el.id = uuidv4()
    document.body.appendChild(el)

    let lock = false
    el.addEventListener(
      'change',
      () => {
        lock = true

        if (!el.files) {
          reject(new SelectionCancelledError('No file selected'))
        } else {
          resolve(el.files)
        }
        const subel = document.getElementById(el.id)
        if (subel) {
          document.body.removeChild(subel)
        }
      },
      {once: true}
    )

    window.addEventListener(
      'focus',
      () => {
        setTimeout(() => {
          const subel = document.getElementById(el.id)
          if (!lock && subel) {
            reject(new SelectionCancelledError('File selection cancelled'))
            document.body.removeChild(subel)
          }
        }, 300)
      },
      {once: true}
    )

    el.click()
  })
}

export default selectFile
