import * as React from 'react'

import {RepeatIcon} from '@chakra-ui/icons'
import {
  Box,
  Button,
  HStack,
  Link,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react'
import {Link as RouterLink, generatePath} from 'react-router-dom'

import {mapStatusToTranslationLabel} from '@/admin/orders/constants'
import {supabase} from '@/api'
import {BuyResult, OrderItemType, UserOrder} from '@/api/models'
import DownloadIcon from '@/common/icons/download'
import useSupabaseQuery from '@/common/use-custom-query'
import useLoadingState from '@/common/use-loading-state'
import {COURSE_PAGE} from '@/router/paths'
import {USER_MEETINGS} from '@/user/router'
import {formatDate} from '@/utils/string'

import {orderItemReferenceTypeToLabel} from './constants'

const OrdersTable = ({type}: {type?: OrderItemType}) => {
  const {data: orders, loading} = useSupabaseQuery<UserOrder>(
    React.useMemo(
      () => ({
        table: 'my_orders',
        fields: '*',
        order: 'created_at',
        descending: true,
        errSnackbarTitle: 'Nie udało się pobrać historii płatności',
        filter: (b) => (type ? b.contains('item_types', [type]) : b),
      }),
      [type]
    )
  )

  const [retryingPayment, setRetryingPayment] = React.useState(false)

  return (
    <>
      {loading ? (
        <Spinner size="xl" />
      ) : (
        <Table>
          <Thead display={['none', null, 'table-header-group']}>
            <Tr>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500} pl={0}>
                Data
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Zamówienie
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Produkt
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Koszt
              </Th>
              <Th textTransform="none" fontSize="md" color="block" fontWeight={500}>
                Status
              </Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {orders?.map((o, i) => (
              <OrderRow
                key={i}
                order={o}
                otherPaymentRetrying={retryingPayment}
                setIsRetryingPayment={setRetryingPayment}
              />
            ))}
          </Tbody>
        </Table>
      )}
    </>
  )
}

const OrderRow = ({
  order,
  otherPaymentRetrying,
  setIsRetryingPayment,
}: {
  order: UserOrder
  otherPaymentRetrying: boolean
  setIsRetryingPayment: (v: boolean) => void
}) => {
  const toast = useToast()

  // for now there can be only one item in order that isn't a tshirt
  const item = React.useMemo(() => order.items?.find((v) => v.type !== 'tshirt'), [order])

  const url = React.useMemo(
    () =>
      item?.type === 'meeting'
        ? USER_MEETINGS
        : item?.type === 'course'
        ? generatePath(COURSE_PAGE, {course: item.id ?? '#'})
        : null,
    [item]
  )

  const {handleSubmit: retryPayment, loading: retryingPayment} = useLoadingState(
    React.useCallback(async () => {
      setIsRetryingPayment(true)
      try {
        const {data, error} = await supabase.rpc('retry_order_payment', {
          order_id: order.id,
        })
        if (error) throw error
        if (!data) throw new Error('No data')

        const res = data as any as BuyResult
        if ('error' in res) throw new Error(res.error)
        if (!res.redirect_url) throw new Error('Invalid redirect URL')

        window.location.href = res.redirect_url
      } catch (e) {
        console.error('Failed to retry payment', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Płatnośc nie powiodła się',
        })
      } finally {
        setIsRetryingPayment(false)
      }
    }, [toast, order, setIsRetryingPayment])
  )

  const openInvoices = React.useCallback(() => {
    for (const i of order.invoices) {
      window.open(i.url, '_blank')
    }
  }, [order])

  const cells = React.useMemo(
    () => ({
      date: <Text>{formatDate(new Date(order.created_at))}</Text>,
      id: <Text>#{order.id}</Text>,
      item: item?.type ? (
        url ? (
          <Link as={RouterLink} to={url} color="brand.green.800">
            {orderItemReferenceTypeToLabel[item?.type] ?? item?.type}
          </Link>
        ) : (
          <Text>{orderItemReferenceTypeToLabel[item?.type] ?? item?.type}</Text>
        )
      ) : (
        'NIEZNANY'
      ),
      price: <Text>{(order.total_gross / 100).toFixed(2)} zł</Text>,
      status: <Text>{mapStatusToTranslationLabel[order.status]}</Text>,
      actions: (
        <Box>
          {order.status === 'pending_payment' && (
            <Button
              variant="brandLink"
              rightIcon={<RepeatIcon />}
              color="red.400"
              onClick={retryPayment}
              isLoading={retryingPayment}
              isDisabled={otherPaymentRetrying}
            >
              Opłać
            </Button>
          )}
          {order.status === 'completed' && !!order.invoices.length && order.total_gross && (
            <Button
              onClick={openInvoices}
              variant="brandLink"
              rightIcon={<DownloadIcon />}
              isDisabled={otherPaymentRetrying}
            >
              Pobierz fakturę
            </Button>
          )}
        </Box>
      ),
    }),
    [order, item, openInvoices, retryPayment, retryingPayment, otherPaymentRetrying, url]
  )

  return !item ? null : (
    <>
      {/* DESKTOP */}
      <Tr fontWeight={300} fontSize="md" display={['none', null, 'table-row']}>
        {Object.values(cells).map((c, i) => (
          <Td key={i}>{c}</Td>
        ))}
      </Tr>

      {/* MOBILE */}
      <Tr fontWeight={300} fontSize="md" display={['table-row', null, 'none']}>
        <Td px={0}>
          <Stack>
            <HStack justify="space-between">
              {cells.date}
              {cells.id}
            </HStack>
            <HStack justify="space-between">
              <Box>{cells.item}</Box>
              {cells.price}
            </HStack>
            <HStack justify="space-between">
              {cells.status}
              {cells.actions}
            </HStack>
          </Stack>
        </Td>
      </Tr>
    </>
  )
}

export default OrdersTable
