import * as React from 'react'

import {Box, Button, Select, Stack} from '@chakra-ui/react'
import {NavLink, useHistory} from 'react-router-dom'

export type SideMenuItem = {
  label: string
  color?: string
  fontWeight?: number
} & (
  | {
      link: string
      onClick?: never
    }
  | {
      link?: never
      onClick: () => void
    }
)

const SideMenu = ({items}: {items: SideMenuItem[]}) => {
  const history = useHistory()

  const currentValue = React.useMemo(() => {
    for (const [key, item] of items.entries()) {
      if (!item.link) return

      if (history.location.pathname.startsWith(item.link)) {
        return key.toString()
      }
    }
    return '0'
  }, [history, items])
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const item = items[parseInt(e.target.value)]

      item.link ? history.push(item.link) : item.onClick && item.onClick()
    },
    [history, items]
  )

  return (
    <>
      <Box px={4} display={['block', null, 'none']}>
        <Select variant="outline" onChange={handleChange} value={currentValue}>
          {items.map((item, i) => (
            <option key={i} color={item.color ?? 'black'} value={i}>
              {item.label}
            </option>
          ))}
        </Select>
      </Box>
      <Stack
        direction="column"
        spacing={8}
        alignItems="flex-start"
        px={[4, null, 0]}
        display={['none', null, 'flex']}
      >
        {items.map((item, i) =>
          item.link ? (
            <Button
              as={NavLink}
              to={item.link}
              key={i}
              variant="brandLink"
              color={item.color ?? 'black'}
              fontWeight={item.fontWeight ?? 300}
              activeStyle={{
                fontWeight: 500,
              }}
            >
              {item.label}
            </Button>
          ) : (
            <Button
              key={i}
              variant="brandLink"
              color={item.color ?? 'black'}
              fontWeight={item.fontWeight ?? 300}
              onClick={item.onClick}
            >
              {item.label}
            </Button>
          )
        )}
      </Stack>
    </>
  )
}

export default SideMenu
