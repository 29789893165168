import * as React from 'react'

import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Switch,
  Text,
  Textarea,
} from '@chakra-ui/react'
import {pl} from 'date-fns/locale'
import DatePicker from 'react-datepicker'
import {MultiValue} from 'react-select'
import AsyncSelect from 'react-select/async'

import {supabase} from '@/api'
import {MeetingHost, WebinarJoined} from '@/api/models'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import ImageUpload from '@/common/image-upload'
import useEditorModalState from '@/common/use-editor-modal'
import {SUPABASE_WEBINARS_BUCKET} from '@/constants'
import {polishTimestampFormat} from '@/utils/string'
import {CommonModalProps} from '@/utils/types'

import {emptyWebinar} from '../constants'
import {inputToUpsertArgs} from './utils'

const CustomDatePickerInput = React.forwardRef(
  (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <Input size="sm" ref={ref} {...(props as InputProps)} />
  )
)

const customInput = <CustomDatePickerInput />

const WebinarEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: WebinarJoined | null}) => {
  const {input, handleInputChange, handleCustomInputChange, image, setImage, handleUpsert, loading} =
    useEditorModalState<WebinarJoined>({
      item,
      table: 'webinars',
      customRPC: 'upsert_webinar',
      emptyInput: emptyWebinar,
      inputToUpsertArgs,
      open,
      onComplete,
      onClose,
      imageParams: {
        bucket: SUPABASE_WEBINARS_BUCKET,
        key: 'thumbnail',
      },
    })

  // input handlers

  const handleMeetingDurationFromChange = React.useCallback(
    (date: Date) => {
      input.duration && handleCustomInputChange({duration: {...input.duration, from: date}})
    },
    [handleCustomInputChange, input]
  )
  const handleMeetingDurationToChange = React.useCallback(
    (date: Date) => {
      input.duration && handleCustomInputChange({duration: {...input.duration, to: date}})
    },
    [handleCustomInputChange, input]
  )
  const handlePriceChange = React.useCallback(
    (v: string) => {
      handleCustomInputChange({price: v ? +v * 100 : 0})
    },
    [handleCustomInputChange]
  )
  const handleFeaturedChange = React.useCallback(
    (v: string) => {
      handleCustomInputChange({featured_index: v ? +v : null})
    },
    [handleCustomInputChange]
  )
  const handleHostsChange = React.useCallback(
    (value: MultiValue<MeetingHost>) => {
      handleCustomInputChange({hosts: [...value] || []})
    },
    [handleCustomInputChange]
  )
  const handleFullDescriptionChange = React.useCallback(
    (value) => {
      handleCustomInputChange({full_description: value})
    },
    [handleCustomInputChange]
  )
  const handleShortDescriptionChange = React.useCallback(
    (value) => {
      handleCustomInputChange({short_description: value})
    },
    [handleCustomInputChange]
  )

  // async select

  const asyncSelectLoadOptions = React.useCallback(async (searchValue) => {
    try {
      const query = supabase.from('available_meeting_hosts').select('*')
      if (searchValue) {
        query.like('email', `%${searchValue}%`)
      }
      const {data, error} = await query
      if (error) throw error

      return data
    } catch (e) {
      console.error(e)
    }
  }, [])

  const optionLabel = React.useCallback((h: MeetingHost) => h.full_name + ' - ' + h.email, [])
  const optionValue = React.useCallback((h: MeetingHost) => h.id, [])

  const isSubmitDisabled = React.useMemo(
    () => !input.name || !input.description || !input.hosts.length || input.price < 0 || !input.duration,
    [input]
  )

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj webinar' : 'Utwórz webinar'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4} align="center" width="100%">
            <FormControl isDisabled={loading} as={Stack} align="center">
              <FormLabel mb={1} fontWeight={600}>
                Miniaturka
              </FormLabel>
              <ImageUpload width={200} height={200} editing={true} value={image} onChange={setImage} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="published"
                  name="published"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={input.published}
                  onChange={handleInputChange}
                />
                <FormLabel htmlFor="published">Opublikowany?</FormLabel>
              </HStack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Nazwa</FormLabel>
              <Input name="name" value={input.name ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Cena (PLN)</FormLabel>
              <NumberInput
                value={String(input.price / 100)} // TODO: input cents
                min={0}
                precision={2}
                onChange={handlePriceChange}
              >
                <NumberInputField />
              </NumberInput>
            </FormControl>
            <FormControl
              isInvalid={!input.duration || input.duration?.to < input.duration?.from}
              isDisabled={loading}
            >
              <FormLabel>Czas trwania</FormLabel>
              <HStack zIndex="popover" position="relative">
                <DatePicker
                  disabled={loading}
                  selected={input.duration?.from}
                  onChange={handleMeetingDurationFromChange}
                  dateFormat={polishTimestampFormat}
                  customInput={customInput}
                  showTimeSelect={true}
                  locale={pl}
                  timeCaption="Czas"
                />
                <Text>-</Text>
                <DatePicker
                  disabled={loading}
                  selected={input.duration?.to}
                  onChange={handleMeetingDurationToChange}
                  dateFormat={polishTimestampFormat}
                  customInput={customInput}
                  showTimeSelect={true}
                  locale={pl}
                  timeCaption="Czas"
                />
              </HStack>
              <FormErrorMessage>Nieprawidłowy czas początku i końca webinaru</FormErrorMessage>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Prowadzący</FormLabel>
              <AsyncSelect
                isDisabled={loading}
                value={input.hosts}
                isMulti={true}
                defaultOptions={true}
                loadOptions={asyncSelectLoadOptions}
                getOptionLabel={optionLabel}
                getOptionValue={optionValue}
                onChange={handleHostsChange}
                styles={{menu: (base) => ({...base, zIndex: 'var(--chakra-zIndices-popover)'})}}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Link do YouTube</FormLabel>
              <Input name="youtube_url" value={input.youtube_url ?? ''} onChange={handleInputChange} />
            </FormControl>
            <FormControl isDisabled={loading}>
              <HStack>
                <Switch
                  id="enable_chat"
                  name="enable_chat"
                  isDisabled={loading}
                  colorScheme="whatsapp"
                  isChecked={input.enable_chat}
                  onChange={handleInputChange}
                />
                <FormLabel htmlFor="enable_chat">Z czatem?</FormLabel>
              </HStack>
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Krótki opis</FormLabel>
              <ContentEditor
                id="admin-panel-edit-webinar-short-description"
                variant="basic"
                value={input?.short_description ?? initialEditorValue}
                onChange={handleShortDescriptionChange}
                readOnly={false}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Pełny opis</FormLabel>
              <ContentEditor
                id="admin-panel-edit-webinar-full-description"
                variant="extended"
                value={input?.full_description ?? initialEditorValue}
                onChange={handleFullDescriptionChange}
                readOnly={false}
              />
            </FormControl>
            <FormControl isDisabled={loading}>
              <FormLabel>Opis w kalendarzu</FormLabel>
              <Textarea name="description" value={input.description ?? ''} onChange={handleInputChange} />
            </FormControl>

            <FormControl isDisabled={loading}>
              <FormLabel>Indeks wyróżnienia</FormLabel>
              <NumberInput value={input.featured_index ?? ''} min={0} onChange={handleFeaturedChange}>
                <NumberInputField />
              </NumberInput>
            </FormControl>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert} isDisabled={isSubmitDisabled}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default WebinarEditorModal
