import * as React from 'react'

import {DeleteIcon} from '@chakra-ui/icons'
import {IconButton, Text, HStack} from '@chakra-ui/react'

import {MyTherapistService} from '@/api/models'
import {formatDurationShort} from '@/utils/duration'

type ServiceItemProps = {
  item: MyTherapistService
  editing: boolean
  onDelete: (id: string) => void
}

const ServiceItem = ({item, editing, onDelete}: ServiceItemProps) => {
  const handleDelete = React.useCallback(() => onDelete(item.id), [onDelete, item])

  return (
    <HStack fontSize="lg">
      {editing && (
        <IconButton
          aria-label="delete-service"
          icon={<DeleteIcon />}
          size="sm"
          colorScheme="red"
          variant="ghost"
          onClick={handleDelete}
        />
      )}
      <Text fontWeight="bold">{item.name}</Text>
      <Text>{`( ${item.price / 100}zł / ${formatDurationShort(item.meeting_duration)} )`}</Text>
    </HStack>
  )
}

export default ServiceItem
