import * as React from 'react'

import {AiOutlineFilePdf} from 'react-icons/ai'
import {MdFormatListNumbered} from 'react-icons/md'

import {ADMIN_INVOICES, ADMIN_ORDER_ITEMS} from '@/admin/router'
import {AdminOrder, OrderShippingAddress} from '@/api/models'
import {DataTableAction} from '@/common/data-table/types'

import {generateFilterURL} from '../utils'

export const showProductsAction: DataTableAction<AdminOrder> = {
  icon: <MdFormatListNumbered size="1rem" />,
  id: 'showProducts',
  to: (item: AdminOrder) =>
    generateFilterURL(ADMIN_ORDER_ITEMS, {
      combinator: 'and',
      rules: [{field: 'order', operator: 'eq', value: item.id}],
    }),
  tooltip: 'Zobacz produkty',
}

export const showInvoicesAction: DataTableAction<AdminOrder> = {
  icon: <AiOutlineFilePdf size="1rem" />,
  id: 'showInvoices',
  to: (item: AdminOrder) =>
    generateFilterURL(ADMIN_INVOICES, {
      combinator: 'and',
      rules: [{field: 'order', operator: 'eq', value: item.id}],
    }),
  tooltip: 'Faktury',
}

export const stringifyShippingAddress = (addr: OrderShippingAddress | null) =>
  addr
    ? `${addr.first_name} ${addr.last_name}
${addr.email}
${addr.street}
${addr.postal_code}, ${addr.city}, Polska
Tel: ${addr.phone_number}`
    : ''
