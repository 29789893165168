import * as React from 'react'

import {Avatar, Box, Link, Stack} from '@chakra-ui/react'

import {AdminTherapist} from '@/api/models'
import {genders} from '@/api/utils'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

import {TherapistCertificate} from './types'

export const emptyTherapistCertificate = {
  name: '',
  public: false,
} as TherapistCertificate

export const emptyAdminTherapist = {
  public: false,
} as AdminTherapist

export const defaultSelectedColumns = ['signed_avatar', 'id', 'full_name', 'gender', 'email', 'specialties']

export const columns: Column<AdminTherapist>[] = [
  {
    Header: 'Avatar',
    id: 'signed_avatar',
    renderAs: 'avatar',
    sortable: false,
  },
  {
    Header: 'ID',
    id: 'id',
    renderAs: 'code',
    sortable: false,
  },
  {
    Header: 'Imię i nazwisko',
    id: 'full_name',
    sortable: false,
  },
  {
    Header: 'Płeć',
    id: 'gender',
    renderer: ({gender}) => (gender ? genders[gender] : ''),
    sortable: true,
  },
  {
    Header: 'ID użytkownika',
    id: 'user',
    renderAs: 'code',
  },
  {
    Header: 'Email użytkownika',
    id: 'email',
    sortable: false,
  },
  {
    Header: 'Indeks wyróżnienia',
    id: 'featured_index',
  },
  {
    Header: 'Publiczny',
    id: 'public',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Lata doświadczenia',
    id: 'years_of_exp',
  },
  {
    Header: 'Opis',
    id: 'description',
  },
  {
    Header: 'Opis meta',
    id: 'meta_description',
    renderAs: 'longString',
  },
  {
    Header: 'Specjalizacje',
    id: 'specialties',
    renderAs: 'tags',
    sortable: false,
  },
  {
    Header: 'Certyfikaty',
    id: 'certificates',
    renderer: ({certificates}) => (
      <Stack>
        {certificates?.map((c, i) => (
          <Box key={i}>
            <Link isExternal={true} href={c.signed_path}>
              {c.name}
            </Link>
          </Box>
        ))}
      </Stack>
    ),
    sortable: true,
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Imię i nazwisko',
    name: 'full_name',
    variant: 'text',
  },
  {
    label: 'Specjalizacje',
    name: 'specialties',
    variant: 'list',
  },
  {
    label: 'Certyfikaty',
    name: 'certificates',
    variant: 'exists',
  },
  {
    label: 'Płeć',
    name: 'gender',
    values: [
      {label: 'Mężczyzna', name: 'male'},
      {label: 'Kobieta', name: 'female'},
    ],
    variant: 'select',
  },
  {
    label: 'Indeks wyróżnienia',
    name: 'featured_index',
    variant: 'number',
  },
  {
    label: 'Publiczny',
    name: 'public',
    variant: 'boolean',
  },
  {
    label: 'Lata doświadczenia',
    name: 'years_of_exp',
    variant: 'text',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
