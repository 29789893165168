import * as React from 'react'

import {Heading, Stack} from '@chakra-ui/react'

import TherapistCalendarState from './calendar-state'
import GoogleCalendarSetings from './settings'
import TherapistTimeSlots from './time-slots'

const TherapistCalendar = () => {
  return (
    <Stack spacing={8} px={[4, null, 8]}>
      <Heading size="md">Synchronizacja kalendarza</Heading>
      <GoogleCalendarSetings />
      <TherapistCalendarState />
      <TherapistTimeSlots />
    </Stack>
  )
}

export default TherapistCalendar
