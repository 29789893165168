import * as React from 'react'

import {Tag} from '@chakra-ui/react'

import {AdminOrder, OrderStatus} from '@/api/models'
import CopyToClipboardButton from '@/common/components/copy-to-clipboard-button'
import DownloadInvoicesButton from '@/common/components/download-invoices-button'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

import {stringifyShippingAddress} from './utils'

export const mapStatusToColor: Record<OrderStatus, string> = {
  cancelled: 'red',
  completed: 'green',
  pending_payment: 'yellow',
}

export const mapStatusToTranslationLabel: Record<OrderStatus, string> = {
  cancelled: 'Anulowane',
  completed: 'Zrealizowane',
  pending_payment: 'Oczekiwanie na płatność',
}

export const defaultSelectedColumns = ['id', 'user_email', 'total_gross', 'created_at', 'status', 'invoices']

export const columns: Column<AdminOrder>[] = [
  {
    Header: 'ID',
    id: 'id',
  },
  {
    Header: 'Email zamawiającego',
    id: 'user_email',
  },
  {
    Header: 'Kwota netto',
    id: 'total_net',
    renderAs: 'monetary',
  },
  {
    Header: 'Kwota brutto',
    id: 'total_gross',
    renderAs: 'monetary',
  },
  {
    Header: 'Kwota podatku',
    id: 'total_tax',
    renderAs: 'monetary',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Status',
    id: 'status',
    renderer: ({status}) => (
      <Tag size="md" variant="solid" colorScheme={mapStatusToColor[status]}>
        {mapStatusToTranslationLabel[status]}
      </Tag>
    ),
    toCSV: ({status}) => mapStatusToTranslationLabel[status],
  },
  {
    Header: 'Kod zniżkowy',
    id: 'discount_code',
    renderAs: 'code',
  },
  {
    Header: 'Zniżka (PLN)',
    id: 'discount',
    renderAs: 'monetary',
  },
  {
    Header: 'Zniżka (%)',
    id: 'discount_percentage',
    renderer: ({discount_percentage}) => `${discount_percentage || 0}%`,
  },
  {
    Header: 'Faktura',
    id: 'invoices',
    renderer: ({invoices}) => (invoices?.length ? <DownloadInvoicesButton invoices={invoices} /> : '-'),
    sortable: false,
  },
  {
    Header: 'Adres wysyłki',
    id: 'shipping_address',
    sortable: false,
    renderer: ({shipping_address}) =>
      shipping_address ? (
        <CopyToClipboardButton value={stringifyShippingAddress(shipping_address)} />
      ) : (
        'Brak'
      ),
    toCSV: ({shipping_address}) => stringifyShippingAddress(shipping_address),
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'ID',
    name: 'id',
    variant: 'number',
  },
  {
    label: 'Email zamawiającego',
    name: 'user_email',
    variant: 'text',
  },
  {
    label: 'Kwota netto',
    name: 'total_net',
    variant: 'monetary',
  },
  {
    label: 'Kwota brutto',
    name: 'total_gross',
    variant: 'monetary',
  },
  {
    label: 'Kwota podatku',
    name: 'total_tax',
    variant: 'monetary',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Status',
    name: 'status',
    variant: 'select',
    values: Object.entries(mapStatusToTranslationLabel).map(([k, v]) => ({
      name: k,
      label: v,
    })),
  },
  {
    label: 'Kod zniżkowy',
    name: 'discount_code',
    variant: 'text',
  },
  {
    label: 'Zniżka (PLN)',
    name: 'discount',
    variant: 'monetary',
  },
  {
    label: 'Zniżka (%)',
    name: 'discount_percentage',
    variant: 'number',
  },
  {
    label: 'Adres wysyłki',
    name: 'shipping_address',
    variant: 'exists',
  },
]
