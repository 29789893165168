import * as React from 'react'

import {Text} from '@chakra-ui/react'

import {AdminCourseReview} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const defaultSelectedColumns = [
  'user_email',
  'course_name',
  'rating',
  'content',
  'published',
  'featured_index',
]

export const columns: Column<AdminCourseReview>[] = [
  {
    Header: 'Email użytkownika',
    id: 'user_email',
  },
  {
    Header: 'Nazwa warsztatu',
    id: 'course_name',
  },
  {
    Header: 'Ocena',
    id: 'rating',
    renderer: ({rating}) => <Text>{`${rating} / 5`}</Text>,
  },
  {
    Header: 'Komentarz',
    id: 'content',
    renderAs: 'longString',
  },
  {
    Header: 'Czy opublikowany',
    id: 'published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Indeks wyróżnienia',
    id: 'featured_index',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Nazwa warsztatu',
    name: 'course_name',
    variant: 'text',
  },
  {
    label: 'ID Warsztatu',
    name: 'course',
    variant: 'number',
  },
  {
    label: 'ID użytkownika',
    name: 'user',
    variant: 'text',
  },
  {
    label: 'E-mail użytkownika',
    name: 'user_email',
    variant: 'text',
  },
  {
    label: 'Ocena',
    name: 'rating',
    variant: 'number',
  },
  {
    label: 'Czy opublikowany',
    name: 'published',
    variant: 'boolean',
  },
  {
    label: 'Indeks wyróżnienia',
    name: 'featured_index',
    variant: 'number',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
