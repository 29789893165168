import {extendTheme, ThemeConfig, createStandaloneToast} from '@chakra-ui/react'

import * as components from './components'
import * as foundations from './foundations'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const theme = extendTheme({
  config,
  ...foundations,
  components: {
    ...components,
  },
})

export const toast = createStandaloneToast({theme})

export default theme
