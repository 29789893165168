import * as React from 'react'

import {Redirect, Route, Switch} from 'react-router-dom'

import {USER_PREFIX} from '@/router/paths'

export const USER_COURSES = `${USER_PREFIX}/warsztaty`
export const USER_MEETINGS = `${USER_PREFIX}/spotkania`
export const USER_WEBINARS = `${USER_PREFIX}/webinary`
export const USER_ORDERS = `${USER_PREFIX}/zamowienia`
export const USER_SETTINGS = `${USER_PREFIX}/ustawienia`
export const USER_SUPPORT_GROUPS = `${USER_PREFIX}/grupy-wsparcia`
export const USER_PROFILE = `${USER_PREFIX}/profil`

export default () => {
  return (
    <Switch>
      <Route path={USER_COURSES} exact={true} component={require('./courses').default} />
      <Route path={USER_MEETINGS} exact={true} component={require('./meetings').default} />
      <Route path={USER_WEBINARS} exact={true} component={require('./webinars').default} />
      <Route path={USER_ORDERS} exact={true} component={require('./orders').default} />
      <Route path={USER_SETTINGS} exact={true} component={require('./settings').default} />
      <Route path={USER_SUPPORT_GROUPS} exact={true} component={require('./support-groups').default} />
      <Route path={USER_PROFILE} exact={true} component={require('./profile').default} />
      <Route component={() => <Redirect to={{pathname: USER_PROFILE}} />} />
    </Switch>
  )
}
