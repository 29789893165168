import * as React from 'react'

import {PerCourseReview} from '@/api/models'
import Reviews from '@/common/reviews'
import useSupabaseQuery from '@/common/use-custom-query'

const MAX_REVIEWS_COUNT = 4

const CourseReviews = ({course}: {course: number}) => {
  const {loading, data} = useSupabaseQuery<PerCourseReview>(
    React.useMemo(
      () => ({
        fields: '*',
        itemsPerPage: MAX_REVIEWS_COUNT,
        match: {course},
        order: 'featured_index',
        table: 'course_reviews_view',
        errSnackbarTitle: 'Nie udało się wczytać recenzji warsztatu',
      }),
      [course]
    )
  )

  const reviews = React.useMemo(
    () =>
      data?.map((v) => ({
        author: v.author,
        content: v.content,
        rating: v.rating,
      })) || [],
    [data]
  )

  return !reviews.length && !loading ? null : (
    <Reviews reviews={reviews} loading={loading} title="Dlaczego warto?" />
  )
}

export default CourseReviews
