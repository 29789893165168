import React from 'react'

import {Code} from '@chakra-ui/react'
import {createPluginFactory, PlateRenderElementProps} from '@udecode/plate'

const CodeRenderer = ({children}: PlateRenderElementProps) => <Code>{children}</Code>

export const createCodePlugin = createPluginFactory({
  component: CodeRenderer,
  isLeaf: true,
  key: 'code',
  type: 'code',
})
