import * as React from 'react'

import {OrderButtons} from '@/common/order'
import {useAppDispatch, useAppSelector} from '@/store'
import {useBillingAddress} from '@/user/billing/hooks'

import {selectTherapist, setIsSummary, setOrderDone, submitPsychotherapyOrder, selectOrder} from '../state'
import {doesDiscountCodeApply} from './utils'

const PsychotherapyOrderButtons = () => {
  const dispatch = useAppDispatch()

  const {agreement, date, service, submitting, summary, discountCode} = useAppSelector(selectOrder)
  const therapist = useAppSelector(selectTherapist)
  const billingAddress = useBillingAddress()

  const handlePreviousStep = React.useCallback(() => {
    dispatch(summary ? setIsSummary(false) : setOrderDone())
  }, [summary, dispatch])

  const handleNextStep = React.useCallback(() => {
    dispatch(summary ? submitPsychotherapyOrder() : setIsSummary(true))
  }, [summary, dispatch])

  const discountedPrice = React.useMemo(() => {
    if (!discountCode || !service?.id || !therapist?.id) return null

    // code can have no contraints or be constrained to service type or to specific therapist
    if (!doesDiscountCodeApply(discountCode, service.id, therapist.id)) return null

    return (service?.price * (1 - discountCode.discount_percentage / 100)) / 100
  }, [service, therapist, discountCode])

  return (
    <OrderButtons
      price={service?.price}
      discountedPrice={discountedPrice}
      isDisabled={!date || !service || !therapist || (summary && (!billingAddress || !agreement))}
      isLoading={submitting}
      onNextStep={handleNextStep}
      nextStepLabel={summary ? undefined : 'Dalej'}
      onPrevStep={handlePreviousStep}
      prevStepLabel={summary ? 'Powrót do wyboru terminu' : 'Anuluj'}
    />
  )
}

export default PsychotherapyOrderButtons
