import * as React from 'react'

import {Stack, Text} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import {HOME} from '@/router/paths'

import {useRetryPayment} from './hooks'

type Props = {
  id?: string
}

const Cancelled = ({id}: Props) => {
  const retryPayment = useRetryPayment(id)

  return (
    <Stack align="center" justify="center" spacing={12} minH="65vh">
      <Stack align="center" justify="center" spacing={0} color="brand.gray.600" textAlign="center">
        <Text fontSize={['20px', null, '28px']} fontWeight={700}>
          Chyba coś poszło nie tak...
        </Text>
        <Text fontSize={['16px', null, '20px']}>Płatność nie została pobrana.</Text>
      </Stack>
      <Stack direction={['column', null, 'row']} spacing={8} align="center" justify="center">
        <ArrowButton
          as={Link}
          to={HOME}
          bg="brand.green.600"
          hoverBg="brand.green.700"
          color="white"
          height={40}
          fontSize="lg"
          fontWeight={500}
          width="320px"
          flipped={true}
        >
          Powrót na stronę główną
        </ArrowButton>
        <ArrowButton
          bg="brand.yellow.600"
          hoverBg="brand.yellow.700"
          color="white"
          height={40}
          fontSize="lg"
          fontWeight={500}
          width="320px"
          onClick={retryPayment}
        >
          Ponów płatność
        </ArrowButton>
      </Stack>
    </Stack>
  )
}

export default Cancelled
