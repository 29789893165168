import {User} from '@/api/models'

import {UserRoleItem} from './types'

export const userRoleItems: UserRoleItem[] = [
  {
    label: 'Administrator',
    value: 'admin',
  },
  {
    label: 'Użytkownik',
    value: 'user',
  },
]

export const emptyUser: User = {
  deleted: false,
  email: '',
  full_name: '',
  id: '',
  provider: '',
  role: 'user',
}
