import * as React from 'react'

import {IoIosInfinite} from 'react-icons/io'

import {DiscountCode, DiscountCodeProductType} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'
import {SelectOption} from '@/utils/types'

export const emptyDiscountCode: DiscountCode = {
  id: '',
  created_at: new Date(),
  updated_at: new Date(),
  code: '',
  discount_percentage: 0,
  description: null,
  is_active: false,
  uses_left: null,
  product_type: null,
  products_ids: [],
}

export const productTypeToLabel: Record<DiscountCodeProductType, string> = {
  course: 'Warsztat',
  service: 'Typ terapii',
  therapist: 'Usługa terapeuty',
  webinar: 'Webinar',
  support_group: 'Grupa wsparcia',
}

export const productTypeToFormLabel: Record<DiscountCodeProductType, string> = {
  course: 'Warsztaty, na które działa kod',
  service: 'Typy terapii, na które działa kod',
  therapist: 'Terapeuci, na których usługi działa kod',
  webinar: 'Webinary, na które działa kod',
  support_group: 'Grupy wsparcia, na które działa kod',
}

export const productTypeOptions: SelectOption<DiscountCodeProductType>[] = Object.entries(
  productTypeToLabel
).map(([k, v]) => ({
  label: v,
  value: k as DiscountCodeProductType,
}))

export const defaultSelectedColumns = [
  'code',
  'discount_percentage',
  'is_active',
  'uses_left',
  'product_type',
  'products_ids',
]

export const columns: Column<DiscountCode>[] = [
  {
    Header: 'Kod zniżkowy',
    id: 'code',
  },
  {
    Header: 'Zniżka (%)',
    id: 'discount_percentage',
  },
  {
    Header: 'Aktywny?',
    id: 'is_active',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Pozostałe użycia',
    id: 'uses_left',
    renderer: ({uses_left}) => uses_left ?? <IoIosInfinite />,
  },
  {
    Header: 'Typ produktów',
    id: 'product_type',
    renderer: ({product_type}) => (product_type ? productTypeToLabel[product_type] : 'Wszystkie'),
  },
  {
    Header: 'Liczba objętych produktów',
    id: 'products_ids',
    renderer: ({products_ids}) => products_ids.length || 'Wszystkie',
  },
  {
    Header: 'Opis',
    id: 'description',
    renderAs: 'longString',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Ostatnia aktualizacja',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Kod zniżkowy',
    name: 'code',
    variant: 'text',
  },
  {
    label: 'Zniżka (%)',
    name: 'discount_percentage',
    variant: 'number',
  },
  {
    label: 'Opis',
    name: 'description',
    variant: 'text',
  },
  {
    label: 'Aktywny?',
    name: 'is_active',
    variant: 'boolean',
  },
  {
    label: 'Pozostałe użycia',
    name: 'uses_left',
    variant: 'number',
  },
  {
    label: 'Typ produktów',
    name: 'product_type',
    variant: 'select',
    values: Object.entries(productTypeToLabel).map(([k, v]) => ({
      name: k,
      label: v,
    })),
  },
  {
    label: 'ID Produktów',
    name: 'products_ids',
    variant: 'list',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Ostatnia aktualizacja',
    name: 'updated_at',
    variant: 'datetime',
  },
]
