export const fonts = {
  body: 'Lexend',
  heading: 'Lexend',
}

export const fontSizes = {
  '12xl': '140px',
  md: '16px',
  sm: '14px',
}
