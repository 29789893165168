import React, {useCallback, useState} from 'react'

import {MinusIcon} from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useToast,
} from '@chakra-ui/react'

import {emptyUser} from '@/admin/constants'
import {supabase} from '@/api'
import {
  CourseRole,
  CourseUserAccessManagement,
  CreateCourseUserResult,
  GetCourseCoachesResult,
  UpdateCourseUserCoachResult,
  User,
} from '@/api/models'
import AsyncSelect from '@/common/async-select'
import {CommonModalProps} from '@/utils/types'

const CreateCourseUserModal = ({
  open,
  onClose,
  onComplete,
  type,
  initValue,
  course,
}: CommonModalProps & {
  type: CourseRole | null
  initValue?: CourseUserAccessManagement
  course: number
}) => {
  const toast = useToast()

  const [selectedUser, setSelectedUser] = useState<User | null>(emptyUser)
  const [selectedCoach, setSelectedCoach] = useState<GetCourseCoachesResult | null>(null)

  const [isUpsertingUser, setIsUpsertingUser] = useState(false)
  const [isLoadingCoach, setIsLoadingCoach] = useState(false)

  React.useEffect(() => {
    if (!initValue?.has_coach) {
      setSelectedCoach(null)
      return
    }
    ;(async () => {
      try {
        setIsLoadingCoach(true)
        const {data, error} = await supabase.rpc('get_course_coaches', {
          course_id: course,
          email_like: initValue.coach_email,
        })
        if (error) {
          throw error
        }
        if (!data?.length) {
          throw new Error('User coach not found.')
        }
        setSelectedCoach(data[0])
      } catch (e) {
        console.error('Failed to load already loaded coach', e)
      } finally {
        setIsLoadingCoach(false)
      }
    })()
  }, [course, initValue])

  const handleClose = useCallback(() => {
    setSelectedUser(emptyUser)
    setSelectedCoach(null)
    setIsUpsertingUser(false)
    onClose()
  }, [onClose])

  const asyncSelectLoadUsers = useCallback(async (searchValue) => {
    try {
      const query = supabase.from('users').select('id, email, provider')

      if (searchValue) {
        query.like('email', `%${searchValue}%`)
      }
      const {data, error} = await query
      if (error) {
        throw error
      }
      return data
    } catch (e) {
      console.error(e)
    }
  }, [])

  const asyncSelectLoadCoaches = useCallback(
    async (searchValue) => {
      try {
        const {data, error} = await supabase.rpc('get_course_coaches', {
          course_id: course,
          email_like: searchValue,
        })
        if (error) {
          throw error
        }
        return data
      } catch (e) {
        console.error('Failed to load available coaches', e)
      }
    },
    [course]
  )

  const usersOptionLabel = useCallback((o: User) => (o === emptyUser ? '' : o.email + ' - ' + o.provider), [])
  const usersOptionValue = useCallback((o: User) => o.email, [])

  const coachesOptionLabel = useCallback(
    (c: GetCourseCoachesResult) => `${c.email} ${c.name ? ` - ${c.name}` : ''}`,
    []
  )
  const coachesOptionValue = useCallback((c: GetCourseCoachesResult) => c.email, [])

  const handleCourseUserCreate = useCallback(async () => {
    if (!selectedUser || !type || selectedUser === emptyUser) {
      return
    }

    try {
      setIsUpsertingUser(true)
      const {data, error} = await supabase.rpc('create_course_user', {
        coach_user_id: selectedCoach?.id || null,
        course_id: course,
        role_name: type,
        user_id: selectedUser.id,
      })
      if (error) {
        throw error
      }
      const result = data as any as CreateCourseUserResult
      if ('error' in result) {
        throw new Error(result.error)
      }
      toast({
        isClosable: true,
        status: 'success',
        title:
          type === 'course_author'
            ? 'Dodano autora do kursu.'
            : type === 'coach'
            ? 'Dodano prowadzącego szkolenie do kursu.'
            : 'Dodano użytkownika do kursu.',
      })
      onComplete && onComplete()
    } catch (e) {
      console.error(`Failed to add ${type} to a course`, e)
      toast({
        isClosable: true,
        status: 'error',
        title:
          type === 'course_author'
            ? 'Nie udało się dodać autora do kursu.'
            : type === 'coach'
            ? 'Nie udało się dodać prowadzącego szkolenie do kursu.'
            : 'Nie udało się dodać użytkownika do kursu.',
      })
    } finally {
      setIsUpsertingUser(false)
      handleClose()
    }
  }, [course, handleClose, onComplete, selectedCoach?.id, selectedUser, toast, type])

  const handleCourseUserUpdate = React.useCallback(async () => {
    if (type !== 'user') {
      console.error('User update possible only for users.')
      return
    }
    if (!initValue?.user) {
      console.error('Cannot update undefined user.')
      return
    }

    try {
      setIsUpsertingUser(true)
      const {data, error} = await supabase.rpc('update_course_user_coach', {
        coach_user_id: selectedCoach?.id || null,
        course_id: course,
        user_id: initValue.user,
      })
      if (error) {
        throw error
      }
      const result = data as any as UpdateCourseUserCoachResult
      if ('error' in result) {
        throw new Error(result.error)
      }
      toast({
        isClosable: true,
        status: 'success',
        title: 'Użytkownik kursu został zaktualizowany',
      })
      onComplete && onComplete()
    } catch (e) {
      console.error('Failed to update course user.', e)
    } finally {
      setIsUpsertingUser(false)
      handleClose()
    }
  }, [course, handleClose, initValue?.user, onComplete, selectedCoach?.id, toast, type])

  const handleCourseUserUpsert = React.useCallback(async () => {
    await (initValue ? handleCourseUserUpdate : handleCourseUserCreate)()
  }, [handleCourseUserCreate, handleCourseUserUpdate, initValue])

  const isSubmitDisabled = React.useMemo(
    () => (!!initValue && type === 'user' ? isLoadingCoach : selectedUser === emptyUser),
    [initValue, isLoadingCoach, selectedUser, type]
  )

  const removeSelectedCoach = React.useCallback(() => setSelectedCoach(null), [])

  return (
    <Modal isOpen={open} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent minW="500">
        <ModalHeader>
          {type === 'course_author'
            ? 'Dodaj autora'
            : type === 'coach'
            ? 'Dodaj prowadzącego szkolenie'
            : initValue
            ? 'Zaktualizuj użytkownika'
            : 'Dodaj użytkownika'}
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <Stack>
            {!initValue && (
              <FormControl id="email">
                <FormLabel>Wybierz email użytkownika</FormLabel>
                <AsyncSelect
                  value={selectedUser}
                  defaultOptions={true}
                  loadOptions={asyncSelectLoadUsers}
                  getOptionLabel={usersOptionLabel}
                  getOptionValue={usersOptionValue}
                  onChange={setSelectedUser}
                  placeholder=""
                />
              </FormControl>
            )}
            {type === 'user' && (
              <FormControl id="coach">
                <FormLabel>Wybierz email trenera</FormLabel>
                <HStack w="100%">
                  <Box w="100%">
                    <AsyncSelect
                      isDisabled={isLoadingCoach}
                      value={selectedCoach}
                      defaultOptions={true}
                      loadOptions={asyncSelectLoadCoaches}
                      getOptionLabel={coachesOptionLabel}
                      getOptionValue={coachesOptionValue}
                      onChange={setSelectedCoach}
                      placeholder=""
                    />
                  </Box>
                  <IconButton
                    aria-label="delete"
                    isDisabled={!selectedCoach}
                    variant="ghost"
                    colorScheme="red"
                    onClick={removeSelectedCoach}
                    icon={<MinusIcon />}
                  />
                </HStack>
              </FormControl>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button mr={3} onClick={handleClose}>
            Zamknij
          </Button>
          <Button
            colorScheme="green"
            onClick={handleCourseUserUpsert}
            isLoading={isUpsertingUser}
            isDisabled={isSubmitDisabled}
          >
            {type === 'course_author'
              ? 'Dodaj autora'
              : type === 'coach'
              ? 'Dodaj prowadzącego szkolenie'
              : initValue
              ? 'Zaktualizuj użytkownika'
              : 'Dodaj użytkownika'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CreateCourseUserModal
