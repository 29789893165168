import * as React from 'react'

import {DownloadIcon} from '@chakra-ui/icons'
import {IconButton} from '@chakra-ui/react'

import {InvoiceIDWithURL} from '@/api/models'

const DownloadInvoicesButton = ({invoices}: {invoices: InvoiceIDWithURL[]}) => {
  const handleClick = React.useCallback(() => {
    for (const i of invoices) {
      window.open(i.url, '_blank')
    }
  }, [invoices])

  return <IconButton onClick={handleClick} aria-label="pdf" icon={<DownloadIcon />} variant="ghost" />
}

export default DownloadInvoicesButton
