import * as React from 'react'

import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  chakra,
  Container,
  Heading,
  Stack,
  Text,
} from '@chakra-ui/react'
import {Link} from 'react-router-dom'

import {parseTherapistService, TherapistService} from '@/api/models'
import NotForMe from '@/common/not-for-me'
import useSupabaseQuery from '@/common/use-custom-query'
import {PSYCHOTHERAPISTS, PSYCHOTHERAPY} from '@/router/paths'
import {formatDurationShort} from '@/utils/duration'

import TherapistsList from './list'

const PsychotherapyTherapists = () => {
  const {data: services} = useSupabaseQuery<TherapistService>({
    table: 'available_therapist_service_types',
    fields: '*',
    order: 'order',
    parsingFunction: parseTherapistService,
  })

  return (
    <>
      <Container maxW="container.xl">
        <Box my={[8, null, 16]}>
          <Breadcrumb fontWeight={300}>
            <BreadcrumbItem>
              <BreadcrumbLink as={Link} to={PSYCHOTHERAPY}>
                Psychoterapia
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage={true} fontWeight={500}>
              <BreadcrumbLink as={Link} to={PSYCHOTHERAPISTS}>
                Psychoterapeuci
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Box>
      </Container>
      <Box bg="brand.yellow.300" mb={[8, null, 16]}>
        <Container maxW="container.xl">
          <Stack direction="column" justifyContent="center" alignItems="center" py={8} spacing={8}>
            <Heading as="h1" size="lg" fontWeight={700}>
              Psychoterapeuci
            </Heading>
            <Stack direction="row" alignItems="center" spacing={6}>
              <Text textAlign="center">
                {services?.map(({name, price, meeting_duration}, i) => (
                  <chakra.span key={i} display={['block', null, 'inline']}>
                    {name} - {formatDurationShort(meeting_duration)} /{' '}
                    {price ? <span>{price / 100} zł</span> : 'GRATIS'}
                    {i < services?.length - 1 && (
                      <chakra.span mx={3} display={['none', null, 'inline']}>
                        |
                      </chakra.span>
                    )}
                  </chakra.span>
                ))}
              </Text>
            </Stack>
          </Stack>
        </Container>
      </Box>
      <Container maxW="container.xl">
        <TherapistsList />
      </Container>
      <NotForMe title="Psychoterapia nie jest dla Ciebie?" selected="psychotherapy" />
    </>
  )
}

export default PsychotherapyTherapists
