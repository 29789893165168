import {TNode} from '@udecode/plate'

import {TherapistCertificate} from '@/admin/therapists/types'
import {CourseDetails, CoursePagePerUser, SectionPerUser} from '@/courses/types'
import {QuestionAnswerContent} from '@/faq/questions-and-answers/types'

import {PGInterval, parsePGInterval} from './types'
import {UserLoginDetails, parsePgTimestampRange} from './utils'

export type SupabaseRPCResult =
  | {
      error: string
    }
  | {
      success: boolean
    }

export type UpsertResponse =
  | {
      success: boolean
      id?: string
    }
  | {
      error: string
    }

export type UserRole = 'admin' | 'user'

export type User = {
  id: string
  email: string
  role: UserRole
  provider: string
  full_name?: string
  full_name_override?: string | null
  avatar?: string | null
  avatar_override?: string | null
  deleted: boolean
}

export type UserProfile = User & {
  therapist_id: number | null
  can_get_tshirt: boolean
}

export const extractUserFullName = (user?: User) => user?.full_name_override || user?.full_name

export type SupportedCountry = 'pl' | 'gb'

export type UserBillingAddress = {
  id: string
  user: string
  updated_at: Date

  company?: string
  first_name?: string
  last_name?: string
  phone_number?: string
  nip?: string
  city?: string
  country: SupportedCountry
  address?: string
  postal_code?: string
  email?: string
  use_basic_address?: boolean
  requests_company_invoice?: boolean
  same_shipping_address?: boolean
  type: string
}

export type AvailableMeetingHost = {
  id: string
  therapist_id: number
  email: string
  full_name: string
}

export type Course = {
  id?: number
  created_at: Date
  updated_at: Date
  release_at: Date | null
  name: string
  published: boolean
  is_new: boolean
  logo: string | null
  base_price: number | null
  price_with_meetings: number | null
  meetings_count: number | null
  order: number | null
  // TODO: remove promotional_video_url as videos can be uploaded in full_description now
  // (saving it for later so it is easy to copy/paste video urls)
  promotional_video_url: string | null
  full_description: TNode[] | null
  short_description: TNode[] | null
  meta_description: string | null
  sections_number_override: number | null
  benefits: string[]
  covered_topics: TNode[][]
  booklet_path: string | null
  rating: number | null
} & (
  | {
      nextlvl_id: string
      nextlvl_url: string
    }
  | {
      nextlvl_id: null
      nextlvl_url: null
    }
)

export type MyCourse = {
  id: number
  created_at: Date
  updated_at: Date

  name: string
  language: string
  nextlvl_url: string | null
  order: number | null
  full_description: TNode[] | null
  short_description: TNode[] | null
  signed_logo: string | null

  coaches: Omit<AvailableCourseCoach, 'course'>[] | null
  roles: CourseRole[]
  meetings_left: number
  progress: number
  reviewed: boolean
  coach: Omit<AvailableCourseCoach, 'course'> | null
  completed_at: Date | null
}

export type PublicCourse = Course &
  Pick<MyCourse, 'coach' | 'meetings_left' | 'roles' | 'signed_logo'> & {
    name_slug: string
    categories?: string[]
    signed_booklet_url: string | null
  }

export type Gender = 'male' | 'female'

export type Therapist = {
  id?: number
  full_name: string
  user: string
  created_at: string
  updated_at: string
  avatar: string | null
  years_of_exp: string // TODO: years of exp is a string? wut
  public: boolean
  gender: Gender | null
  full_description: TNode[] | null
  short_description: TNode[] | null
  meta_description: string | null
  featured_index: number | null
}

export type TherapistTimeSlotRaw = {
  id: string
  created_at: string
  google_calendar_event: string
  therapist: number
  time_range: string
}

export type TherapistTimeSlot = Omit<TherapistTimeSlotRaw, 'time_range'> & {
  time_range: PGTimeRangeSlot
}

export const parseTherapistTimeSlot = <IT extends {time_range: string}>(input: IT) => ({
  ...input,
  time_range: parsePgTimestampRange(input.time_range),
})

export type TherpistCalendarDetails = {
  therapist_id: number
  google_calendar_id: string | null
  last_calendar_sync: Date | null
}

type ParagraphContent = {
  text: string
}

type ImageContent = {
  file: {id: string}
  caption: string
  stretched: boolean
  withBorder: boolean
  withBackground: boolean
}

export type CourseBlockContent = ParagraphContent | ImageContent
export type CourseBlockType = 'p' | 'image'

export type CourseBlock = {
  id: string
  created_at: Date
  updated_at: Date

  course: number
  order: number
  page: string
} & (
  | {
      type: 'p'
      content: ParagraphContent
    }
  | {
      type: 'image'
      content: ImageContent
    }
)

export type Section = {
  id: string
  created_at: Date
  updated_at: Date

  course: number
  order: number
  path: string
  published: boolean
  title: string
}

export type CoursePage = {
  id: string
  created_at: Date
  updated_at: Date

  course: number
  order: number
  published: boolean
  section: string
  title: string
}

export type PGTimeRangeSlot = {
  from: Date
  to: Date
}

export type PGTimeRangeSlotJoined = PGTimeRangeSlot & {
  is_occupied: boolean
  is_locked: boolean
}

export type PaymentStatus = 'pending' | 'completed' | 'refunded' | 'cancelled'

export type Payment = {
  id: string
  created_at: Date
  updated_at: Date
  order: number
  user: string
  user_email: string
  amount: number
  currency: string
  country: string
  language: string
  status: PaymentStatus
  ps4_order_id: string | null
  meta: any | null
}

export type RefundStatus = 'cancelled' | 'completed' | 'pending'

export type PaymentRefund = {
  id: string
  created_at: Date
  updated_at: Date
  payment: string
  status: RefundStatus
  invoice: string | null
}

export type AdminPaymentRefund = PaymentRefund & {
  invoices: InvoiceIDWithURL[]
}

export type CourseRole = 'coach' | 'course_author' | 'user'

export type CourseUser = {
  id: string
  course: number
  role: CourseRole
  payment?: string
  meetings_left: number
} & (
  | {
      user: string
      group?: never
    }
  | {
      user?: never
      group: string
    }
)

export type BlogPost = {
  id?: number
  author: string | null
  created_at: Date
  updated_at: Date
  published_at: Date | null
  name: string
  description: TNode[]
  meta_description: string | null
  image: string | null
  language: string
  featured_index: number | null
}

export type ViewBlogPost = BlogPost & {
  slug: string
  words_count: number
  signed_image: string | null
}

export type BlogPostContentType = ParagraphContent | ImageContent
export type BlogPostContent = {
  id: string
  blog_post: BlogPost
  created_at: Date
  updated_at: Date
  type: string // TODO: make it an enum
} & (
  | {
      type: 'p'
      content: ParagraphContent
    }
  | {
      type: 'image'
      content: ImageContent
    }
)

export type BuyResult =
  | {
      redirect_url: string
    }
  | {error: string}

export type TherapistServiceRaw = {
  id: string
  created_at: string
  updated_at: string
  name: string
  order: number
  price: number
  meeting_duration: string
}

export type TherapistService = Omit<TherapistServiceRaw, 'meeting_duration'> & {
  meeting_duration: PGInterval
}

export type MyTherapistService = TherapistService & {
  assigned: boolean
}

export type TherapistAssignedServiceRaw = TherapistServiceRaw & {
  therapist?: number
}

export type TherapistAssignedService = Omit<TherapistAssignedServiceRaw, 'meeting_duration'> & {
  meeting_duration: PGInterval
}

export const parseTherapistService = <IT extends {meeting_duration: string}>(input: IT) => ({
  ...input,
  meeting_duration: parsePGInterval(input.meeting_duration),
})

export type UserInvitationRaw = {
  id: string
  meeting_name: string
  meeting_duration: string
  names: string[]
}

export type UserInvitation = Omit<UserInvitationRaw, 'meeting_duration'> & {
  meeting_duration: PGTimeRangeSlot | undefined
}

export const parseUserInvitation = (i: UserInvitationRaw) => ({
  ...i,
  meeting_duration: parsePgTimestampRange(i.meeting_duration),
})

export type MeetingInvitation = {
  id: string
  uses: number
  max_uses?: number
  status: string
  name?: string
  to?: string
}

export type MeetingHost = {
  id: string
  therapist_id?: string
  email: string
  full_name: string
  slug?: string
}

export type Category = {
  id: string
  created_at?: Date
  updated_at?: Date
  name: string
  show_as_filter: boolean
}

//
// Webinars
//

export type WebinarRaw = {
  id?: number // used just to fetch from url
  meeting: string // <-- actual primary key
  created_at: Date
  updated_at: Date
  full_description: TNode[] | null
  short_description: TNode[] | null
  youtube_url: string | null
  thumbnail: string
  price: number
  featured_index: number | null
  published: boolean
  enable_chat: boolean
}

// duration, name, description come from `meetings` table
export type WebinarRawJoined = WebinarRaw & {
  duration: string
  name: string
  description: string
  hosts: MeetingHost[]
}

export type WebinarJoined = Omit<WebinarRawJoined, 'duration'> & {
  duration?: PGTimeRangeSlot
}

export type PublicWebinar = Omit<WebinarRawJoined, 'youtube_url' | 'duration'> & {
  name: string
  slug: string
  start_at: Date
  end_at: Date
  hosts: MeetingHost[]
  has_access: boolean
}

export type MyWebinar = {
  id?: number
  price: number
  meeting: string
  name: string
  youtube_url: string
  thumbnail: string
  start_at: Date
  end_at: Date
  reviewed: boolean
  hosts: MeetingHost[]
  enable_chat: boolean
}

export const parseWebinarJoined = (w: WebinarRawJoined): WebinarJoined => ({
  ...w,
  duration: parsePgTimestampRange(w.duration),
})

//
// Support groups
//

export type SupportGroupRaw = {
  id: number
  created_at: string
  updated_at: string
  name: string
  price: number
  max_members: number
  min_members: number
  full_description: TNode[] | null
  short_description: TNode[] | null
  meta_description: string | null
  featured_index: number | null
  thumbnail: string | null
  is_finished: boolean
  published: boolean
}

// duration, name, description come from `meetings` table
export type SupportGroupJoinedRaw = SupportGroupRaw & {
  slug: string
  occupied_slots: number
  hosts: MeetingHost[]
  categories: string[] // just names
  has_access: boolean
  rating: number
  meetings_left: number
  first_meeting_duration: string | null
  next_meeting_duration: string | null
}

export type SupportGroupJoined = Omit<
  SupportGroupJoinedRaw,
  'first_meeting_duration' | 'next_meeting_duration' | 'created_at' | 'updated_at'
> & {
  created_at: Date
  updated_at: Date
  first_meeting_duration?: PGTimeRangeSlot
  next_meeting_duration?: PGTimeRangeSlot
}

export const parseSupportGroupJoined = (w: SupportGroupJoinedRaw): SupportGroupJoined => ({
  ...w,
  created_at: new Date(w.created_at),
  updated_at: new Date(w.updated_at),
  first_meeting_duration: parsePgTimestampRange(w.first_meeting_duration),
  next_meeting_duration: parsePgTimestampRange(w.next_meeting_duration),
})

export type MySupportGroup = Pick<
  SupportGroupJoined,
  'id' | 'name' | 'hosts' | 'thumbnail' | 'is_finished'
> & {
  reviewed: boolean
  role: SupportGroupRole
}

export type SupportGroupCategory = Category

export type SupportGroupRole = 'member' | 'host' | 'substitute_host' | 'queue'

export type SupportGroupUser = {
  support_group: number
  user: string
  created_at: Date
  updated_at: Date
  role: SupportGroupRole
}

export type AdminSupportGroupUser = SupportGroupUser & {
  email: string
}

export type SupportGroupMeeting = {
  id: string
  support_group: number
  name: string
  description: string
  starts_at: Date
  ends_at: Date
}

export type MySupportGroupMeeting = SupportGroupMeeting & {
  group_name: string
  hosts: MeetingHost[]
  joinable_from: Date
  joinable_to: Date
  thumbnail: string | null
  reviewed: boolean
  role: SupportGroupRole
}

export type Invoice = {
  id: string
  created_at: Date
  updated_at: Date
  address: string
  items: InvoiceItem[]
  total_net: number
  total_tax: number
  total_gross: number
}

type InvoiceAddress = {
  id: string
  created_at: Date
  updated_at: Date
  company: string | null
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  nip: string | null
  city: string
  country: SupportedCountry
  street: string
  postalCode: string
}

type InvoiceItem = {
  name: string
  quantity: number
  taxRate: number
  netPrice: number
  taxPrice: number
  grossPrice: number
}

export type AdminInvoice = Pick<Invoice, 'id' | 'created_at'> &
  Pick<InvoiceAddress, 'company' | 'nip'> & {
    email: string
    name: string
    url: string
  }

export type InvoiceIDWithURL = {
  id: string
  url: string
}

export type GenerateBlogPostImageURLResult =
  | {
      error: string
    }
  | {
      url: string
    }

export type UpdateCoursePageProgressResult =
  | {
      error: string
    }
  | {
      success: boolean
    }

export type GetCourseMenuResult =
  | {
      error: string
    }
  | {
      pages: CoursePagePerUser[]
      sections: SectionPerUser[]
    }

export type GetPrevAndNextCoursePageResult =
  | {
      error: string
    }
  | {
      next_page: string | null
      prev_page: string | null
    }

export type GetMostRecentlyReadCoursePage =
  | {
      error: string
    }
  | {
      page: string
    }

export type GetCourseUsersResult =
  | {
      error: string
    }
  | {
      users: UserLoginDetails[]
    }

export type TShirtSize = {
  id: string
  created_at: Date
  updated_at: Date
  available: boolean
  order: number
}

export type CourseUserAccessManagement = CourseUser &
  Pick<User, 'email' | 'full_name'> & {
    coach_id: string
    coach_name: string
    has_coach: string
    coach_email: string
  }

export type OrderStatus = 'pending_payment' | 'cancelled' | 'completed'

export type OrderShippingAddress = {
  first_name: string
  last_name: string
  phone_number: string
  country: SupportedCountry
  city: string
  street: string
  postal_code: string
  email: string
}

export type Order = {
  id: number
  user: string
  invoice: string
  address: InvoiceAddress
  status: OrderStatus
  total_net: number
  total_tax: number
  total_gross: number
  currency: string
  created_at: Date
  updated_at: Date
  shipping_address: OrderShippingAddress | null
}

export type OrderItemType = 'course' | 'course_meeting' | 'meeting' | 'webinar' | 'tshirt' | 'support_group'

export type OrderItem = {
  id: string
  created_at: Date
  updated_at: Date
  order: number
  amount: number
  unit_net_price: number
  tax_rate: number
  currency: string
  total_net: number
  total_tax: number
  total_gross: number
  name: string
  reference_uuid: string // meetings, webinars, t-shirts
  reference_int: number // courses
  reference_type: OrderItemType
  payload: any
}

export type AdminOrder = {
  id: string
  created_at: Date
  currency: string
  user_email: string
  total_net: number
  total_tax: number
  total_gross: number
  status: OrderStatus
  discount: number
  discount_code: string | null
  discount_percentage: number | null
  shipping_address: OrderShippingAddress | null
  invoices: InvoiceIDWithURL[]
}

export type AdminOrderItem = Pick<
  OrderItem,
  | 'order'
  | 'amount'
  | 'unit_net_price'
  | 'total_net'
  | 'total_tax'
  | 'total_gross'
  | 'reference_type'
  | 'created_at'
  | 'name'
  | 'payload'
> & {
  user_email: string
  product_id: string
  therapists: string[]
  therapists_ids: number[]
  status: OrderStatus
  invoices: InvoiceIDWithURL[]
  discount_code: string | null
  shipping_address: OrderShippingAddress | null
}

export type ReviewType = 'course' | 'meeting' | 'webinar' | 'support_group'

export type Review = {
  id: string
  created_at: string
  updated_at: string
  user: string
  content: string
  rating: number
  published: boolean
  featured_index: number | null
}

export type FeaturedReview = {
  author: string
  content: string
  featured_index?: number
  reference_id?: number
  reference_type?: ReviewType
  reference_slug?: string
  rating?: number
}

export type CourseReview = Review & {
  course: number
}

export type AdminCourseReview = CourseReview & {
  course_name: string
  user_email: string
}

export type PerCourseReview = FeaturedReview & {
  course: number
}

export type TherapistReview = Review & {
  therapist: number
  meeting: string
}

export type AdminTherapistReview = TherapistReview & {
  user_email: string
  user_full_name: string
  therapist_email: string
  therapist_full_name: string
}

export type PerTherapistReview = FeaturedReview & {
  therapist: number
}

export type SupportGroupReview = Review & {
  host: number
  support_group: number
}

export type AdminSupportGroupReview = SupportGroupReview & {
  host_email: string
  user_email: string
  support_group_name: string
}

export type PerSupportGroupReview = FeaturedReview & {
  support_group: number
  host: number
}

export type SupportGroupReviewsView = {
  support_group: number
  reviews: PerSupportGroupReview[]
}
export type WebinarReview = Review & {
  webinar: number
}

export type AdminWebinarReview = WebinarReview & {
  user_email: string
  webinar_name: string
}

export type FrontPageLogo = {
  id: string
  created_at: Date
  updated_at: Date
  order: number
  image_path: string
  link_url: string
  alt_text: string
  published: boolean
}

export type DiscountCodeProductType = 'course' | 'webinar' | 'therapist' | 'service' | 'support_group'

export type DiscountCode = {
  id: string
  created_at: Date
  updated_at: Date
  code: string
  discount_percentage: number
  is_active: boolean
  uses_left: number | null // null means infinite uses
  description: string | null
  product_type: DiscountCodeProductType | null // null = apply to all products
  products_ids: string[] // empty = apply to all products of type
}

export type OrderDiscountCode = Omit<DiscountCode, 'id' | 'created_at' | 'updated_at'>

export type JournalSharedPage = {
  created_at: Date
  updated_at: Date
  course: string
  page: string
  user: string
}

export type ToggleJournalSharedPageResult =
  | {
      error: string
    }
  | {
      shared: boolean
    }

export type CourseCoach = {
  created_at: Date
  updated_at: Date
  course: number
  user: string
  coach: string
}

export type GetCourseDetailsResult =
  | {
      error: string
    }
  | {
      details: CourseDetails | null
    }

export type GetCourseCoachesResult = {
  id: string
  name: string | null
  email: string
  avatar: string
}

export type CreateCourseUserResult =
  | {
      error: string
    }
  | {
      success: boolean
    }

export type UpdateCourseUserCoachResult =
  | {
      error: string
    }
  | {
      success: boolean
    }

export type TherapistSpecialty = {
  id: string
  created_at: Date
  updated_at: Date
  name: string
  show_as_filter: boolean
}

export type AdminTherapist = Therapist & {
  email: string
  specialties: string[]
  signed_avatar: string | null
  certificates: TherapistCertificate[] | null
}

export type TherapistTherapistSpecialty = {
  created_at: Date
  updated_at: Date
  therapist: number
  therapist_specialty: string
}

export type TherapistAssignedSpecialtyViewItem = Pick<TherapistTherapistSpecialty, 'therapist'> &
  Pick<TherapistSpecialty, 'id' | 'name'>

export type TherapistAssignedSpecialty = {
  created_at: Date
  therapist: number
  therapist_specialty: string
  updated_at: Date
}

export type PublicTherapist = Therapist & {
  full_name_slug: string
  specialties: string[]
  services: string[]
  rating: number
  signed_avatar: string | null
}

export type CourseCategory = Category

export type AdminCourse = Course & {
  signed_logo?: string
  categories: string[]
  signed_booklet_url: string | null
}

export type CourseCourseCategory = {
  created_at: Date
  updated_at: Date
  course: number
  course_category: string
}

export type CourseAssignedCategory = Pick<CourseCourseCategory, 'course'> &
  Pick<CourseCategory, 'id' | 'name'>

export type QuestionAnswer = {
  id: string
  content: QuestionAnswerContent[] | null
  created_at: Date
  updated_at: Date
}

export type QuestionAnswerItem = {
  question_answer: string
} & Omit<QuestionAnswerContent, 'answer'>

export type UpsertQuestionAnswerItemResult =
  | {
      error: string
    }
  | {
      success: boolean
    }

export type DeleteQuestionAnswerItemResult =
  | {
      error: string
    }
  | {
      success: boolean
    }

export type GetQuestionAnswerItemsResult =
  | {
      error: string
    }
  | {
      data: QuestionAnswerContent[] | null
    }

export type GetQuestionAnswerItemResult =
  | {
      error: string
    }
  | {
      data: TNode[] | null
    }
export type PublicFrontPageLogo = Pick<FrontPageLogo, 'alt_text' | 'link_url' | 'image_path'>

export type UserOrderItem = {
  id: string
  type: OrderItemType
  name: string
  payload: any
}

export type UserOrder = Order & {
  invoices: InvoiceIDWithURL[]
  items: UserOrderItem[]
  item_types: OrderItemType[]
}

export type MeetingType = 'therapy' | 'webinar' | 'support_group'

export type MeetingRole = 'host' | 'guest'

export type MeetingGuest = {
  id: string
  email: string
}

export type AdminMeeting = {
  id: string
  created_at: string
  updated_at: string
  name: string
  description: string
  google_calendar_id: string | null
  service_id: string | null
  service_name: string | null
  starts_at: string | null // nullable for the sake of edition
  ends_at: string
  host_id: number | null // tharapist id
  host_email: string | null
  host_full_name: string | null
  guests: MeetingGuest[]
  guests_ids: string[]
  guests_emails: string[]
}

export type MyMeeting = {
  id: string
  name: string
  type: MeetingType
  therapist_id: number
  therapist_avatar: string | null
  therapist_full_name: string
  service_id: string
  service_name: string | null
  participant_names: string[]
  starts_at: Date
  joinable_from: Date
  ends_at: Date
  joinable_to: Date
  reviewed: boolean
}

export type AvailableCourseCoach = {
  course: string
  therapist_id: number
  full_name: string
  full_name_slug: string
  signed_avatar: string | null
}

export type PublicCourseWithDetails = PublicCourse & {
  segments: number
  coaches: Omit<AvailableCourseCoach, 'course'>[] | null
}

export type FeaturedTherapist = Pick<Therapist, 'full_description' | 'full_name'> & {
  full_name_slug: string
  signed_avatar: string | null
  specialties: string[]
}

export type GetTherapistCertificatesResult =
  | {
      error: string
    }
  | {
      certificates: TherapistCertificate[] | null
    }

export type UpdateTherapistCertificateVisibilityResponse =
  | {
      success: boolean
    }
  | {
      error: string
    }

export type DeleteTherapistCertificateResponse =
  | {
      success: boolean
    }
  | {
      error: string
    }

export type GenerateUserAvatarURLResult =
  | {
      error?: string
      url: string
    }
  | {
      error: string
      url?: never
    }

export type GetSurveyDataResult = {
  error?: string
  answers?: Record<string, string>
}

export type SetSurveyDataResult = {
  error?: string
  success?: boolean
}
