import * as React from 'react'

import {SUPPORT_GROUPS_FEATURE} from '@/constants'
import Blog from '@/home/blog'
import ContactSection from '@/home/contact-section'
import Courses from '@/home/courses'
import Hero from '@/home/hero'
import Newsletter from '@/home/newsletter'
import OurTherapists from '@/home/our-therapists'
import Products from '@/home/products'
import RecommendUs from '@/home/recommend-us'
import Reviews from '@/home/reviews'
import Steps from '@/home/steps'
import SupportGroups from '@/home/support-groups'

const Home = () => {
  return (
    <>
      <Hero />
      <Steps />
      <Products />
      <ContactSection />
      <RecommendUs />
      <OurTherapists />
      <Reviews />
      <Courses />
      {SUPPORT_GROUPS_FEATURE === 'true' && <SupportGroups />}
      <Blog />
      <Newsletter />
    </>
  )
}

export default Home
