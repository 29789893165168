import React from 'react'

import {generatePath, useHistory} from 'react-router-dom'

import {supabase} from '@/api'
import {GetMostRecentlyReadCoursePage} from '@/api/models'
import LabeledSpinner from '@/common/components/labeled-spinner'
import {ADMIN_COURSE_PAGE, COURSE_PAGE} from '@/router/paths'

// LoadingMostRecentlyReadPage is mounted when page in the parent Course component is falsey.
// It fetches the most recently read page of the course. However, user may manually select the page,
// which make page truthy and unmounts the component, before the completion of the request.
// Hence the cleanup is handled in the function returned by useEffect.
const LoadingMostRecentlyReadPage = ({adminView, course}: {adminView?: boolean; course: string}) => {
  const history = useHistory()

  React.useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    ;(async () => {
      try {
        const {data, error} = await supabase
          .rpc('get_most_recently_read_course_page', {
            course_id: course,
          })
          .abortSignal(abortController.signal)
        if (error) {
          throw error
        }
        if (!data) {
          throw new Error('No response data received.')
        }
        const result = data as any as GetMostRecentlyReadCoursePage
        if ('error' in result) {
          throw result.error
        }
        if (!isMounted || !result.page) {
          return
        }
        history.replace(
          generatePath(adminView ? ADMIN_COURSE_PAGE : COURSE_PAGE, {
            course,
            page: result.page,
          })
        )
      } catch (e) {
        console.error('Failed to load most recently read course page', e)
      }
    })()
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, []) // eslint-disable-line

  return <LabeledSpinner label="Ładowanie..." />
}

export default LoadingMostRecentlyReadPage
