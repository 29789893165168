import * as React from 'react'

import {FrontPageLogo} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {editAction, deleteAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {emptyFrontPageLogo, columns, defaultSelectedColumns, filterFields} from './constants'
import FrontPageLogoEditorModal from './editor-modal'

const FrontPageLogos = () => {
  const tableState = useTableState({sortBy: 'order'})
  const {loading, data, fetch, rows} = useSupabaseQuery<FrontPageLogo>({
    descending: tableState.descending,
    fields: '*',
    filter: tableState.filter,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof FrontPageLogo,
    pageNumber: tableState.page,
    table: 'admin_front_page_logos',
  })

  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<FrontPageLogo>()
  const {
    value: deleteValue,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useValueDisclosure<FrontPageLogo>()

  const actions = React.useMemo(
    () => [editAction(onEditorOpen), deleteAction(onDeleteOpen)],
    [onEditorOpen, onDeleteOpen]
  )

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyFrontPageLogo)
  }, [onEditorOpen])

  return (
    <>
      <DataTable<FrontPageLogo>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        loading={loading}
        defaultSelectedColumns={defaultSelectedColumns}
        filterFields={filterFields}
      />
      <FrontPageLogoEditorModal
        item={editorValue}
        onClose={onEditorClose}
        open={!!editorValue}
        onComplete={fetch}
      />
      <DeleteResourceModal
        table="front_page_logos"
        id={deleteValue?.id}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default FrontPageLogos
