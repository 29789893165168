import React, {useMemo} from 'react'

import {useColorMode} from '@chakra-ui/react'
import Select, {AsyncProps} from 'react-select/async'
import {GroupBase, StylesConfig} from 'react-select/dist/declarations/src'

const AsyncSelect = (props: AsyncProps<any, false, GroupBase<any>>) => {
  const {colorMode} = useColorMode()

  const customStyles: StylesConfig<any, false> = useMemo(() => {
    return {
      control: (provided) => ({
        ...provided,
        backgroundColor: colorMode === 'dark' ? 'var(--chakra-colors-gray-700)' : 'white',
        color: 'red',
      }),
      input: (provided) => ({
        ...provided,
        color: colorMode === 'dark' ? 'white' : 'black',
      }),
      menuList: (provided) => ({
        ...provided,
        backgroundColor: colorMode === 'dark' ? 'var(--chakra-colors-gray-700)' : 'white',
      }),
      option: (provided, {isFocused, isSelected}) => ({
        ...provided,
        backgroundColor: isFocused
          ? colorMode === 'dark'
            ? 'var(--chakra-colors-gray-500)'
            : 'var(--chakra-colors-blue-300)'
          : isSelected
          ? 'var(--chakra-colors-blue-600)'
          : undefined,
      }),
      singleValue: (provided) => ({
        ...provided,
        color: colorMode === 'dark' ? 'white' : 'black',
      }),
    }
  }, [colorMode])

  // if styles are passed in props, they will override `customStyles`
  return <Select styles={customStyles} {...props} />
}

export default AsyncSelect
