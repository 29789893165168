import {format} from 'date-fns'
import {ExportToCsv} from 'export-to-csv'

import {renderPresetToCSV} from '../constants'
import {Column} from '../types'

export const exportCSV = <T extends {}>(table: string, data: T[], columnByID: Record<string, Column<T>>) => {
  if (!Array.isArray(data) || !data.length) throw new Error('Data should be a non empty array')

  // there is `decimalSeperator` option, but it doesn't escape the value so we can't use a comma
  const csvExporter = new ExportToCsv({
    showLabels: true,
    headers: Object.keys(data[0]).map((k) => `${columnByID[k]?.Header || k}`),
    filename: `${table}_${format(new Date(), 'yyyy-MM-dd')}`,
  })

  const convertedData = data.map((item) =>
    Object.entries(item).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]:
          v == null
            ? ''
            : columnByID[k]?.toCSV
            ? columnByID[k].toCSV!(item)
            : columnByID[k]?.renderAs && renderPresetToCSV[columnByID[k].renderAs!]
            ? renderPresetToCSV[columnByID[k].renderAs!]!(v)
            : typeof v === 'number'
            ? v.toLocaleString('pl', {minimumFractionDigits: 2})
            : v,
      }),
      {} as T
    )
  )

  csvExporter.generateCsv(convertedData)
}
