import * as React from 'react'

import {Center, Spinner, Stack, useToast} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'

import {OrderModal} from '@/common/order'
import {useAppDispatch, useAppSelector} from '@/store'

import MeetingSelector from '../showcase/meeting-selector'
import {
  selectIsSummary,
  selectOrderActive,
  selectOrderDate,
  selectOrderService,
  selectPsychotherapyError,
  selectTherapist,
  selectTherapistLoading,
  setOrderDone,
} from '../state'
import PsychotherapyOrderButtons from './buttons'
import PsychotherapyOrderSummary from './summary'

const PsychotherapyOrderModal = () => {
  const toast = useToast()
  const dispatch = useAppDispatch()

  const active = useAppSelector(selectOrderActive)
  const date = useAppSelector(selectOrderDate)
  const service = useAppSelector(selectOrderService)
  const isSummary = useAppSelector(selectIsSummary)
  const therapist = useAppSelector(selectTherapist)
  const therapistLoading = useAppSelector(selectTherapistLoading)

  const error = useAppSelector(selectPsychotherapyError)
  React.useEffect(() => {
    if (!error) return
    console.error(error)
    toast({
      title: error.title,
      description: error.description,
      isClosable: true,
      status: 'error',
    })
  }, [error]) // eslint-disable-line

  React.useEffect(() => {
    if (!therapist || !service || !date) return

    TagManager.dataLayer({
      dataLayer: {
        ecommerce: null,
      },
    })
    TagManager.dataLayer({
      dataLayer: {
        ecommerce: {
          currency: 'PLN',
          items: [
            {
              currency: 'PLN',
              item_id: `meeting:${therapist.id}:${service.id}:${date}`,
              item_name: `Psychoterapeuta - ${therapist.full_name} - ${service.name} - ${date}`,
              value: (service.price || 0) / 100,
            },
          ],
          value: (service.price || 0) / 100,
        },
        event: 'begin_checkout',
      },
    })
  }, [therapist, service, date])

  const handleClose = React.useCallback(() => dispatch(setOrderDone()), [dispatch])

  return (
    <OrderModal open={active} onClose={handleClose}>
      {therapistLoading ? (
        <Center>
          <Spinner size="xl" />
        </Center>
      ) : (
        <Stack spacing={8} w="100%">
          <PsychotherapyOrderSummary />
          {!isSummary && <MeetingSelector />}
          <PsychotherapyOrderButtons />
        </Stack>
      )}
    </OrderModal>
  )
}

export default PsychotherapyOrderModal
