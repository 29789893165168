import React from 'react'

import {chakra} from '@chakra-ui/react'
import {createPluginFactory, PlateRenderElementProps} from '@udecode/plate'

export const ParagraphRenderer = ({attributes, children}: PlateRenderElementProps) => {
  return (
    <chakra.p as="div" mb={4} {...attributes}>
      {children}
    </chakra.p>
  )
}

export const createParagraphPlugin = createPluginFactory({
  component: ParagraphRenderer,
  isElement: true,
  key: 'p',
  type: 'p',
})
