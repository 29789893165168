import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'
import {QuestionAnswerItem} from '@/api/models'
import {RuleGroupTypeExtended} from '@/common/filter-builder/types'
import {buildQueryFilter} from '@/common/filter-builder/utils'

export const emptyQuestionAnswerItem: () => QuestionAnswerItem = () => ({
  collapsed: true,
  id: uuidv4(),
  is_published: true,
  order: null,
  question: '',
  question_answer: '',
})

export const uploadFile = async (file: File, bucket: string, scope?: string) => {
  const filename = `${uuidv4()}/${file.name}`
  const filepath = scope ? `/${scope}/${filename}` : `/${filename}`

  const {error} = await supabase.storage.from(bucket).upload(filepath.substr(1), file, {upsert: true})
  if (error) {
    throw error
  }
  const {data, error: signedURLError} = await supabase.storage
    .from(bucket)
    .createSignedUrl(filepath.substr(1), 300)
  if (signedURLError) {
    throw signedURLError
  }
  if (!data || !data.signedUrl) {
    throw new Error('Empty signed url response')
  }

  return [filepath, data.signedUrl]
}

export const generateFilterURL = (to: string, rules: RuleGroupTypeExtended) =>
  `${to}?filter=${buildQueryFilter(rules)}`
