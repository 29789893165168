import * as React from 'react'

import {useLocation} from 'react-router-dom'

import {associatedTags, defaultTags, matchPrefixMetaTags, staticPathMetaTags, titleSuffix} from './constants'
import {MetaTags} from './types'

const useMetaTags = (record: Record<string, string | null | undefined>) => {
  const {pathname} = useLocation()
  const tags = React.useMemo(() => {
    // go through all paths starting with the most specific ones
    if (pathname in staticPathMetaTags) return staticPathMetaTags[pathname]

    const prefix = Object.keys(matchPrefixMetaTags).find((k) => pathname.startsWith(k))
    if (!prefix) return defaultTags

    if (!Object.keys(record).length) return matchPrefixMetaTags[prefix]
    // if any args were passed it means the component handles meta tags by itself
    // could also fetch from 'meta_tags' view instead of passing tags,
    // but it would mean fetching the same data twice,
    // on the other hand it would make the code a little bit cleaner

    // filter out falsy values
    const onlyTruthy = Object.entries(record).reduce((res, [k, v]) => {
      if (v) res[k] = v
      return res
    }, {} as Partial<MetaTags>)

    return {
      ...matchPrefixMetaTags[prefix],
      ...onlyTruthy,
      title: (onlyTruthy.title || matchPrefixMetaTags[prefix].title) + titleSuffix,
    }
  }, [record, pathname])

  React.useEffect(() => {
    document.title = tags.title

    // expand tags
    // e.g. we want the same content
    // for <meta name="description"> and <meta property="og:description">
    const allTags: Record<string, string> = {...tags}
    Object.entries(tags).forEach(([k, v]) => {
      if (!associatedTags[k]) return
      associatedTags[k].forEach((t) => {
        allTags[t] = v
      })
    })
    delete allTags.title // replaced by 'og:title'

    Object.entries(allTags).forEach(([k, v]) => {
      const definingAttribute = k.startsWith('og:') ? 'property' : 'name'

      document.querySelectorAll(`meta[${definingAttribute}="${k}"]`)?.forEach((t) => t.remove())
      if (!v) return

      const tag = document.createElement('meta')
      tag.setAttribute(definingAttribute, k)
      tag.content = v
      document.head.appendChild(tag)
    })
  }, [tags])
}

export default useMetaTags
