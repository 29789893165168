import * as React from 'react'

import {Button, Stack, Text, useDisclosure} from '@chakra-ui/react'

import {selectOrderSubmitting, selectOrderTshirt, setOrderTshirt} from '@/courses/state'
import {useAppDispatch, useAppSelector} from '@/store'

import FormBox from '../form-box'
import TShirtModal from './modal'

const TShirtInput = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()

  const dispatch = useAppDispatch()
  const tshirt = useAppSelector(selectOrderTshirt)
  const submitting = useAppSelector(selectOrderSubmitting)

  const handleDelete = React.useCallback(() => {
    dispatch(setOrderTshirt(undefined))
  }, [dispatch])

  return (
    <FormBox>
      <TShirtModal isOpen={isOpen} onClose={onClose} />
      <Stack direction={['column', null, 'row']} spacing={[6, null, 1]}>
        <Stack spacing={1} flex={1}>
          {tshirt ? (
            <>
              <Text fontWeight={700}>Odbierasz darmową koszulkę</Text>
              <Text>rozmiar: {tshirt}</Text>
              <Text>rodzaj: unisex</Text>
            </>
          ) : (
            <>
              <Text>To Twoje pierwsze szkolenie</Text>
              <Text fontWeight={700}>Odbierasz darmową koszulkę</Text>
            </>
          )}
        </Stack>
        <Stack spacing={4}>
          <Button variant="brandLink" fontWeight={700} isDisabled={submitting} onClick={onOpen}>
            {tshirt ? 'Edytuj' : 'Dodaj'}
          </Button>
          {tshirt && (
            <Button variant="brandLink" fontWeight={700} isDisabled={submitting} onClick={handleDelete}>
              Usuń
            </Button>
          )}
        </Stack>
      </Stack>
    </FormBox>
  )
}

export default TShirtInput
