import React from 'react'

import {DiscountCode} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, emptyDiscountCode, filterFields} from './constants'
import DiscountCodeEditorModal from './editor-modal'

const DiscountCodes = () => {
  const tableState = useTableState({descending: true, sortBy: 'updated_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<DiscountCode>({
    table: 'discount_codes',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof DiscountCode,
    filter: tableState.filter,
  })

  const {
    value: deleteValue,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useValueDisclosure<DiscountCode>()
  const {
    value: editorValue,
    onOpen: onEditorOpen,
    onClose: onEditorClose,
  } = useValueDisclosure<DiscountCode>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyDiscountCode)
  }, [onEditorOpen])

  const actions = React.useMemo(
    () => [deleteAction(onDeleteOpen), editAction(onEditorOpen)],
    [onDeleteOpen, onEditorOpen]
  )

  return (
    <>
      <DataTable<DiscountCode>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        actions={actions}
        onRefresh={fetch}
        onAdd={handleAdd}
        loading={loading}
        filterFields={filterFields}
      />
      <DiscountCodeEditorModal
        item={editorValue}
        onClose={onEditorClose}
        onComplete={fetch}
        open={!!editorValue}
      />
      <DeleteResourceModal
        table="discount_codes"
        id={deleteValue?.id ?? ''}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default DiscountCodes
