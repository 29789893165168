import React from 'react'

import {Center, useToast} from '@chakra-ui/react'
import TagManager from 'react-gtm-module'
import {useHistory, useParams} from 'react-router-dom'

import {supabase} from '@/api'
import {UserOrder, UserOrderItem} from '@/api/models'
import {HOME} from '@/router/paths'

import Cancelled from './cancelled'
import Fulfilled from './fulfilled'
import Pending from './pending'

type OrderParams = {
  id: string
}

const fetchEveryMs = 1000

const OrderProcessing = () => {
  const history = useHistory()
  const {id} = useParams<OrderParams>()
  const toast = useToast()
  const [cancelled, setCancelled] = React.useState(false)
  const [orderItem, setOrderItem] = React.useState<UserOrderItem>()

  React.useEffect(() => {
    const interval = setInterval(async () => {
      const {data, error} = await supabase.from('my_orders').select('*').match({id}).single()
      if (error || !data) {
        console.error('Failed to look up order status', error)
        toast({
          description: 'Nie udało się sprawdzić statusu zamówienia.',
          isClosable: true,
          status: 'error',
        })
        history.push(HOME)
        return
      }
      switch (data.status) {
        case 'completed':
          break
        case 'cancelled':
          setCancelled(true)
          clearInterval(interval)
          return
        default:
          return
      }

      // for now orders can only contain one item and possibly a tshirt
      const item = (data as UserOrder).items.find((v) => v.type !== 'tshirt')
      if (!item) return

      let productID = item.id
      let productName = item.name

      if (item.type === 'meeting') {
        try {
          const {data, error} = await supabase.from('my_meetings').select('*').eq('id', item.id).single()
          if (error) throw error
          if (!data) throw new Error('No data')
          productID = `${data?.therapist_id}:${data?.service_id}:${data?.starts_at}`
          productName = `Psychoterapia - ${data?.therapist_full_name} - ${data?.meeting_name}`
        } catch (e) {
          console.error('Failed to fetch meeting details', e)
        }
      }

      setOrderItem(item)
      clearInterval(interval)
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: null,
        },
      })
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            currency: 'PLN',
            items: [
              {
                currency: 'PLN',
                item_id: `${productID}:${id}`,
                item_name: productName,
                value: data.total_gross / 100,
              },
            ],
            tax: data.total_tax / 100,
            transaction_id: id,
            value: data.total_gross / 100,
          },
          event: 'purchase',
        },
      })
    }, fetchEveryMs)
    return () => clearInterval(interval)
  }, [history, id, toast])

  return (
    <Center py={[16, null, 20]} px={[4, null, 0]}>
      {cancelled ? <Cancelled /> : orderItem ? <Fulfilled item={orderItem} /> : <Pending id={id} />}
    </Center>
  )
}

export default OrderProcessing
