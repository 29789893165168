import * as React from 'react'

import {useToast} from '@chakra-ui/react'
import validator from 'validator'

import {supabase} from '@/api'
import {UserBillingAddress} from '@/api/models'
import {fetchBillingInfo} from '@/auth/state'
import useLoadingState from '@/common/use-loading-state'
import useRunOnReconnect from '@/common/use-run-on-reconnect'
import {useAppDispatch} from '@/store'

import {countryToPostalCodeLocale, emptyAddressSet, emptyBillingAddress} from './constants'
import {UpdateResponse} from './types'
import {isNIPValid, toPayload} from './utils'

const useBillingEditorState = (onComplete?: () => void) => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const [billingAddressLoading, setBillingAddressLoading] = React.useState(false)
  const [draftBillingAddresses, setDraftBillingAddresses] =
    React.useState<UserBillingAddress[]>(emptyAddressSet)
  const [response, setResponse] = React.useState<UpdateResponse>()

  const fetchBillingAddress = React.useCallback(async () => {
    setBillingAddressLoading(true)
    try {
      const {data, error} = await supabase.from('my_billing_address').select('*')
      if (error) throw error
      if (!data) throw new Error('Error loading user billing address')

      const basicAddress = data.find((d) => d.type === 'basic') || emptyBillingAddress('basic')
      const shippingAddress = data.find((d) => d.type === 'shipping') || emptyBillingAddress('shipping')
      const invoiceAddress = data.find((d) => d.type === 'invoice') || emptyBillingAddress('invoice')

      const combined = [basicAddress, shippingAddress, invoiceAddress]
      setDraftBillingAddresses(combined)
    } catch (e) {
      console.error('Failed to fetch billing address', e)
      toast({
        isClosable: true,
        status: 'error',
        title: 'Nie udało się wczytać informacji o adresie użytkownika',
      })
    } finally {
      setBillingAddressLoading(false)
    }
  }, [toast])

  useRunOnReconnect(fetchBillingAddress)

  React.useEffect(() => {
    fetchBillingAddress()
  }, [fetchBillingAddress])

  const {loading: saving, handleSubmit} = useLoadingState(
    React.useCallback(async () => {
      if (!draftBillingAddresses) {
        return
      }
      setResponse(undefined)
      try {
        const {data, error} = await supabase.rpc('update_my_billing_address', {
          payload: toPayload(draftBillingAddresses),
        })
        if (error) throw error

        const result = data as UpdateResponse
        if (result.error) {
          setResponse(result)
          throw new Error(result.error)
        }
        toast({isClosable: true, status: 'success', title: 'Pomyślnie zapisano nowy adres'})
        fetchBillingAddress()
        dispatch(fetchBillingInfo())
        onComplete && onComplete()
      } catch (e) {
        console.error('Failed to save billing address', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się zapisać nowego adresu',
        })
      }
    }, [toast, draftBillingAddresses, dispatch, fetchBillingAddress, onComplete])
  )

  const isSubmitDisabled = React.useMemo(() => {
    if (billingAddressLoading || saving) {
      return true
    }

    const basicAddress = draftBillingAddresses.find((d) => d.type === 'basic') || emptyBillingAddress('basic')
    const shippingAddress =
      draftBillingAddresses.find((d) => d.type === 'shipping') || emptyBillingAddress('shipping')
    const invoiceAddress =
      draftBillingAddresses.find((d) => d.type === 'invoice') || emptyBillingAddress('invoice')

    // basic address needs everything
    if (
      !basicAddress.country ||
      !basicAddress.first_name ||
      !basicAddress.last_name ||
      !validator.isMobilePhone(basicAddress.phone_number ?? '', ['pl-PL', 'en-GB']) ||
      !validator.isEmail(basicAddress.email ?? '') ||
      !basicAddress.address ||
      !validator.isPostalCode(
        basicAddress.postal_code ?? '',
        countryToPostalCodeLocale[basicAddress.country]
      ) ||
      !basicAddress.city
    ) {
      return true
    }

    // only validate shippingAddress if !basicAddress.same_shipping_address
    if (!basicAddress.same_shipping_address) {
      if (
        !basicAddress.country ||
        !shippingAddress.first_name ||
        !shippingAddress.last_name ||
        !validator.isMobilePhone(shippingAddress.phone_number ?? '', ['pl-PL', 'en-GB']) ||
        !validator.isEmail(shippingAddress.email ?? '') ||
        !shippingAddress.address ||
        !validator.isPostalCode(
          shippingAddress.postal_code ?? '',
          countryToPostalCodeLocale[basicAddress.country]
        ) ||
        !shippingAddress.city
      ) {
        return true
      }
    }

    // only validate companyInvoice if basicAddress.requests_company_invoice
    if (basicAddress.requests_company_invoice) {
      if (
        !invoiceAddress.country ||
        !invoiceAddress.company ||
        !isNIPValid(
          invoiceAddress.use_basic_address ? basicAddress.country : invoiceAddress.country,
          invoiceAddress.nip
        )
      ) {
        return true
      }

      if (!invoiceAddress.use_basic_address) {
        if (
          !invoiceAddress.country ||
          !invoiceAddress.address ||
          !validator.isPostalCode(
            invoiceAddress.postal_code ?? '',
            countryToPostalCodeLocale[invoiceAddress.country]
          ) ||
          !invoiceAddress.city
        ) {
          return true
        }
      }
    }

    return false
  }, [billingAddressLoading, draftBillingAddresses, saving])

  return {
    billingAddressLoading,
    draftBillingAddresses,
    handleSubmit,
    isSubmitDisabled,
    response,
    saving,
    setDraftBillingAddresses,
  }
}

export default useBillingEditorState
