import * as React from 'react'

import {Flex, HStack, Heading, Stack, Text} from '@chakra-ui/react'
import {format} from 'date-fns'
import {pl} from 'date-fns/locale'
import {Link, generatePath} from 'react-router-dom'

import {ArrowButton} from '@/common/arrow-button'
import LoadingView from '@/common/loading-view'
import useSupabaseQuery from '@/common/use-custom-query'
import {MEETING} from '@/router/paths'
import {groupMeetings} from '@/user/meetings/utils'

import {TherapistMeeting} from './types'

const UpcomingMeetings = () => {
  const {data, loading} = useSupabaseQuery<TherapistMeeting>(
    React.useMemo(
      () => ({
        table: 'therapist_meetings',
        fields: '*',
        order: 'starts_at',
        filter: (b) => b.gte('joinable_to', new Date().toUTCString()).eq('type', 'therapy'),
      }),
      []
    )
  )

  const {joinable, planned} = React.useMemo(() => groupMeetings(data), [data])

  const groupedByDay = React.useMemo(
    () =>
      planned?.reduce((res, m) => {
        const day = format(new Date(m.starts_at), 'eeee | dd.MM.yyyy', {locale: pl})
        res[day] = [...(res[day] ?? []), m]
        return res
      }, {} as Record<string, TherapistMeeting[]>) ?? {},
    [planned]
  )

  if (loading) return <LoadingView />

  return (
    <Stack spacing={8}>
      {joinable.length && (
        <Stack spacing={4}>
          <Heading size="md">Trwające spotkania</Heading>
          <Stack>
            {joinable.map((m, i) => (
              <JoinableMeeting key={i} meeting={m} />
            ))}
          </Stack>
        </Stack>
      )}
      <Stack spacing={4}>
        <Heading size="md">Nadchodzące spotkania</Heading>
        <Stack>
          {Object.entries(groupedByDay).map(([day, meetings], i) => (
            <Stack key={i}>
              <Text>{day}</Text>
              {meetings.map((m, i) => (
                <HStack key={i} p={2} bg="brand.green.200" rounded="0 10px">
                  <Text>{format(new Date(m.starts_at), 'HH:mm')}</Text>
                  <Text fontWeight="bold">{m.name}</Text>
                  {!!m.participants.length && (
                    <>
                      <Text>z</Text>
                      {m.participants.map((p, i) => (
                        <Text key={i}>
                          {p.full_name}
                          {i !== m.participants.length - 1 && ','}
                        </Text>
                      ))}
                    </>
                  )}
                </HStack>
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

// TODO: Replace this with `SpotlightItem` after merging webinars branch
const JoinableMeeting = ({meeting}: {meeting: TherapistMeeting}) => {
  return (
    <Stack direction={['column', null, 'row']} spacing={4} alignItems="center">
      <Stack direction="column" spacing={4} flex={1} justifyContent="center">
        {meeting.participants.map((p, i) => (
          <Text key={i} fontSize="xl" fontWeight={500} textAlign={['center', null, 'left']}>
            {p.full_name}
          </Text>
        ))}
        <Stack direction="row" spacing={2} textAlign={['center', null, 'left']}>
          <Text fontWeight={500}>{meeting.name}</Text>
          <Text fontWeight={300}>{format(new Date(meeting.starts_at), "dd.MM.yyy 'godz.' HH:mm")}</Text>
        </Stack>
        <Flex direction="row" justifyContent={['center', null, 'flex-start']}>
          <ArrowButton
            as={Link}
            to={generatePath(MEETING, {id: meeting.id})}
            bg="brand.yellow.600"
            hoverBg="brand.yellow.700"
            color="white"
          >
            Rozpocznij rozmowę
          </ArrowButton>
        </Flex>
      </Stack>
    </Stack>
  )
}

export default UpcomingMeetings
