import React from 'react'

import {Button, Container, Heading, Link, Stack, Wrap, WrapItem} from '@chakra-ui/react'

import {TherapistCertificate} from '@/admin/therapists/types'
import {supabase} from '@/api'
import {GetTherapistCertificatesResult} from '@/api/models'
import CheckmarkIcon from '@/common/icons/checkmark'

type Props = {
  therapist: number
  mine?: boolean // displayed in therapists panel
}

const TherapistCertificates = ({therapist, mine}: Props) => {
  const [certificates, setCertificates] = React.useState<TherapistCertificate[]>([])

  React.useEffect(() => {
    ;(async () => {
      if (!therapist) return

      try {
        const {data, error} = await supabase.rpc('get_therapist_certificates', {
          therapist_id: therapist,
        })
        if (error) throw error
        if (!data) throw new Error('No data received')

        const result = data as any as GetTherapistCertificatesResult
        if ('error' in result) throw result.error

        setCertificates(result.certificates ?? [])
      } catch (e) {
        console.error('Failed to load therapist certificates', e)
      }
    })()
  }, [therapist]) // eslint-disable-line

  return !certificates.length ? null : (
    <Stack spacing={6}>
      {mine ? (
        <Heading size="md" fontWeight={500}>
          Twoje certyfikaty i szkolenia
        </Heading>
      ) : (
        <Heading size="lg" fontWeight={[700, null, 500]}>
          Certyfikaty i szkolenia
        </Heading>
      )}
      <Wrap spacing={8}>
        {certificates.map((c, i) => (
          <WrapItem key={i}>
            <Button
              leftIcon={<CheckmarkIcon boxSize={8} />}
              borderRadius="full"
              pl={3}
              pr={4}
              py={6}
              as={Link}
              target="_blank"
              href={c.signed_path}
              bg="brand.green.300"
              _hover={{
                bg: 'brand.green.300',
                textDecoration: 'none',
              }}
            >
              {c.name}
            </Button>
          </WrapItem>
        ))}
      </Wrap>
    </Stack>
  )
}

export default TherapistCertificates
