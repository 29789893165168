import * as React from 'react'

import {Box, Container, Grid, GridItem} from '@chakra-ui/react'

import Menu from './menu'
import Router from './router'

const TherapistPanel = () => {
  return (
    <Container maxW="container.xl" height="100%" px={[0, null, 4]}>
      <Grid
        templateColumns={['repeat(1, 1fr)', null, 'repeat(12, 1fr)']}
        height="100%"
        minHeight="calc(100vh - 314px)"
      >
        <GridItem colSpan={[1, null, 2]} borderRightWidth="2px" height="100%">
          <Box mt={[8, null, 16]} pb={[0, null, 8]}>
            <Menu />
          </Box>
        </GridItem>
        <GridItem colSpan={[1, null, 10]} mt={8} pb={8}>
          <Router />
        </GridItem>
      </Grid>
    </Container>
  )
}

export default TherapistPanel
