import React from 'react'

import {
  Box,
  Button,
  Collapse,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import {NavLink as RouterLink} from 'react-router-dom'

type SingleItemProps = {
  absolutePath: string
  label: string
  menuChildren?: never
}

type MultiItemProps = {
  label: string
  menuChildren: SingleItemProps[]
}

export type NavLinkProps = SingleItemProps | MultiItemProps

const activeStyle = {
  color: 'brand.green.800',
  fontWeight: 600,
}

const SingleNavLink = ({absolutePath, label}: SingleItemProps) => (
  <Button
    as={RouterLink}
    p="16px 8px"
    rounded="md"
    variant="brandLink"
    to={absolutePath}
    activeStyle={activeStyle}
    _focus={activeStyle}
    color="brand.green.800"
    display="flex"
    justifyContent="flex-start"
  >
    {label}
  </Button>
)

const NavLink = (props: NavLinkProps) => {
  const {isOpen, onOpen, onClose, onToggle} = useDisclosure()
  const currentMouseEntered = React.useRef<boolean>(false)

  const handleMouseEnter = React.useCallback(() => {
    onOpen()
    currentMouseEntered.current = true
  }, [onOpen])
  const handleMouseLeave = React.useCallback(() => {
    currentMouseEntered.current = false

    setTimeout(() => {
      if (currentMouseEntered.current) {
        return
      }
      onClose()
    }, 100)
  }, [onClose])

  const variant = useBreakpointValue({
    base: 'mobile',
    md: 'desktop',
  })

  if (props.menuChildren) {
    if (variant === 'desktop') {
      // Box wrapping Menu prevents a warning from showing up in the console
      return (
        <Box>
          <Menu placement="bottom-start" isOpen={isOpen} onOpen={onOpen} onClose={onClose} gutter={-4}>
            <MenuButton
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              py={4}
              textAlign="left"
              px={2}
              color="brand.green.800"
            >
              {props.label}
            </MenuButton>
            <MenuList onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
              {props.menuChildren.map((item, i) => (
                <SingleNavLink key={i} {...item} />
              ))}
            </MenuList>
          </Menu>
        </Box>
      )
    }
    return (
      <Stack direction="column" spacing={4}>
        <Link
          px={2}
          py={4}
          rounded="md"
          _hover={{
            textDecoration: 'underline',
          }}
          color="brand.green.800"
          onClick={onToggle}
        >
          {props.label}
        </Link>
        <Collapse in={isOpen} animateOpacity={true}>
          <Stack direction="column" spacing={4} pl={4}>
            {props.menuChildren.map((item, i) => (
              <SingleNavLink key={i} {...item} />
            ))}
          </Stack>
        </Collapse>
      </Stack>
    )
  }

  return <SingleNavLink {...props} />
}

export default NavLink
