import * as React from 'react'

import {Text} from '@chakra-ui/react'
import {formatISO} from 'date-fns'

import {AdminTherapistReview} from '@/api/models'
import {Column} from '@/common/data-table/types'
import {FilterField} from '@/common/filter-builder/types'

export const defaultSelectedColumns = [
  'user_email',
  'therapist_full_name',
  'rating',
  'content',
  'published',
  'featured_index',
]

export const columns: Column<AdminTherapistReview>[] = [
  {
    Header: 'Użytkownik',
    id: 'user_email',
  },
  {
    Header: 'Terapeuta',
    id: 'therapist_full_name',
  },
  {
    Header: 'Ocena',
    id: 'rating',
    renderer: ({rating}) => <Text>{`${rating} / 5`}</Text>,
  },
  {
    Header: 'Komentarz',
    id: 'content',
    renderAs: 'longString',
  },
  {
    Header: 'Opublikowano',
    id: 'published',
    renderAs: 'booleanIcons',
  },
  {
    Header: 'Indeks wyróżnienia',
    id: 'featured_index',
  },
  {
    Header: 'Utworzono',
    id: 'created_at',
    renderAs: 'timestamp',
  },
  {
    Header: 'Zaktualizowano',
    id: 'updated_at',
    renderAs: 'timestamp',
  },
]

export const filterFields: FilterField[] = [
  {
    label: 'Ocena',
    name: 'rating',
    variant: 'number',
  },
  {
    label: 'Terapeuta',
    name: 'therapist_full_name',
    variant: 'text',
  },
  {
    label: 'ID terapeuty',
    name: 'therapist',
    variant: 'number',
  },
  {
    label: 'E-mail terapeuty',
    name: 'therapist_email',
    variant: 'text',
  },
  {
    label: 'Użytkownik',
    name: 'user_full_name',
    variant: 'text',
  },
  {
    label: 'ID użytkownika',
    name: 'user',
    variant: 'text',
  },
  {
    label: 'E-mail użytkownika',
    name: 'user_email',
    variant: 'text',
  },
  {
    label: 'Opublikowano',
    name: 'published',
    variant: 'boolean',
  },
  {
    label: 'Indeks wyróżnienia',
    name: 'featured_index',
    variant: 'number',
  },
  {
    label: 'Utworzono',
    name: 'created_at',
    variant: 'datetime',
  },
  {
    label: 'Zaktualizowano',
    name: 'updated_at',
    variant: 'datetime',
  },
]
