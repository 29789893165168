import * as React from 'react'

import {useDisclosure} from '@chakra-ui/react'
import {generatePath, useHistory} from 'react-router-dom'

import {PublicWebinar} from '@/api/models'
import {selectUser, setModalState} from '@/auth/state'
import {ArrowButton, Props as ArrowButtonProps} from '@/common/arrow-button'
import {WEBINAR} from '@/router/paths'
import {useAppDispatch, useAppSelector} from '@/store'
import {USER_WEBINARS} from '@/user/router'

import WebinarOrderModal from '.'

const WebinarOrderModalButton = ({webinar, ...rest}: ArrowButtonProps & {webinar: PublicWebinar}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {push} = useHistory()

  const handleClick = React.useCallback(() => {
    if (webinar.has_access) {
      push(generatePath(USER_WEBINARS))
      return
    }
    if (user) {
      onOpen()
      return
    }

    // TODO: add a redux slice for webinars and pass `order=true` query param
    // in redirectPath, just as it is done in `web/src/psychotherapy/support-groups/state.ts:55`
    dispatch(
      setModalState({
        redirectPath: generatePath(WEBINAR, {
          id: webinar.id,
        }),
        state: 'signin',
      })
    )
  }, [webinar, user, onOpen, push, dispatch])

  return (
    <>
      <ArrowButton height={40} fontSize="lg" fontWeight={500} onClick={handleClick} {...rest}>
        {webinar.has_access ? 'Przejdź do webinarów' : 'Kup teraz'}
      </ArrowButton>
      <WebinarOrderModal webinar={webinar} open={isOpen} onClose={onClose} />
    </>
  )
}

export default WebinarOrderModalButton
