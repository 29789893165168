import * as React from 'react'

import {
  Modal,
  Stack,
  Text,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  FormControl,
  Select,
  useToast,
  Button,
  ModalFooter,
} from '@chakra-ui/react'

import {userRoleItems} from '@/admin/constants'
import {AdminUser} from '@/admin/types'
import {supabase} from '@/api'
import useLoadingState from '@/common/use-loading-state'
import {CommonModalProps} from '@/utils/types'

const UserEditorModal = ({onClose, open, item, onComplete}: CommonModalProps & {item?: AdminUser}) => {
  const toast = useToast()
  const [role, setRole] = React.useState('')

  React.useEffect(() => {
    if (!item?.role) {
      return
    }
    setRole(item.role)
  }, [item])

  const handleRoleChange = React.useCallback(({target: {value}}) => setRole(value), [setRole])

  const handleUpdate = React.useCallback(async () => {
    if (!item) {
      return
    }
    try {
      const {error} = await supabase.from('users').update({role}).eq('id', item.id)
      if (error) {
        throw error
      }
      toast({isClosable: true, status: 'success', title: 'Zmiany zapisane'})
      onClose()
      onComplete && onComplete()
    } catch (e) {
      console.error('Failed to update user', e)
      toast({
        isClosable: true,
        title: 'Błąd podczas zapisywania',
        variant: 'error',
      })
    }
  }, [item, toast, onClose, role, onComplete])
  const {loading, handleSubmit} = useLoadingState(handleUpdate)

  return (
    <Modal isOpen={open} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edytuj role użytkownika</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align="center" spacing={8}>
            <HStack width="100%" justify="space-evenly">
              <Text>{item?.email}</Text>
              <Stack width="30%">
                <FormControl isDisabled={loading}>
                  <Select value={role} onChange={handleRoleChange}>
                    {userRoleItems.map((role, i) => (
                      <option value={role.value} key={i}>
                        {role.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </HStack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button isLoading={loading} onClick={handleSubmit}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default UserEditorModal
