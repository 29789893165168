import React from 'react'

import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Select,
  Stack,
  Textarea,
} from '@chakra-ui/react'

import {supabase} from '@/api'
import {AdminTherapist, User} from '@/api/models'
import {genders} from '@/api/utils'
import AsyncSelect from '@/common/async-select'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import ImageUpload from '@/common/image-upload'
import useEditorModalState from '@/common/use-editor-modal'
import {SUPABASE_AVATARS_BUCKET} from '@/constants'
import {CommonModalProps} from '@/utils/types'

import {emptyAdminTherapist} from '../constants'
import {inputToUpsertArgs} from './utils'

const TherapistEditorModal = ({
  onClose,
  open,
  item,
  onComplete,
}: CommonModalProps & {item: AdminTherapist}) => {
  const {input, image, setImage, handleInputChange, handleCustomInputChange, handleUpsert, loading} =
    useEditorModalState<AdminTherapist>({
      table: 'therapists',
      item,
      emptyInput: emptyAdminTherapist,
      inputToUpsertArgs,
      open,
      onComplete,
      onClose,
      imageParams: {
        bucket: SUPABASE_AVATARS_BUCKET,
        key: 'avatar',
        signed_key: 'signed_avatar',
        scope: 'therapists',
      },
    })

  const [user, setUser] = React.useState<Partial<User> | null>(null)

  React.useEffect(() => {
    ;(async () => {
      if (!input.user) return

      try {
        const {data, error} = await supabase.from('users').select('id, email, provider').eq('id', input.user)
        if (error) {
          throw error
        }
        data && setUser(data[0])
      } catch (e) {
        console.log(e)
      }
    })()
  }, [input])

  const handleYearsOfExpChange = React.useCallback(
    (v: string) => handleCustomInputChange({years_of_exp: v}),
    [handleCustomInputChange]
  )

  const handleFullDescriptionChange = React.useCallback(
    (value) => {
      handleCustomInputChange({full_description: value})
    },
    [handleCustomInputChange]
  )

  const handleShortDescriptionChange = React.useCallback(
    (value) => {
      handleCustomInputChange({short_description: value})
    },
    [handleCustomInputChange]
  )

  const handleFeaturedChange = React.useCallback(
    (v: string) => {
      handleCustomInputChange({featured_index: v ? +v : null})
    },
    [handleCustomInputChange]
  )

  const handleUserChange = React.useCallback(
    (value: User) => {
      setUser(value)
      handleCustomInputChange({user: value.id})
    },
    [handleCustomInputChange]
  )

  const asyncSelectLoadOptions = React.useCallback(async (searchValue) => {
    try {
      const query = supabase.from('users').select('id, email, provider')

      if (searchValue) {
        query.like('email', `%${searchValue}%`)
      }
      const {data, error} = await query
      if (error) throw error

      return data
    } catch (e) {
      console.log(e)
    }
  }, [])

  const optionLabel = React.useCallback((o: User) => o.email + ' - ' + o.provider, [])
  const optionValue = React.useCallback((o: User) => o.email, [])

  return (
    <Modal isOpen={open} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{item?.id ? 'Edytuj terapeutę' : 'Dodaj terapeutę'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack px={2} py={2}>
            <Stack spacing={4} align="center" width="100%">
              <FormControl isDisabled={loading} as={Stack} align="center">
                <FormLabel mb={1} fontWeight={600}>
                  Avatar
                </FormLabel>
                <ImageUpload width={200} height={200} editing={true} value={image} onChange={setImage} />
              </FormControl>
              <FormControl id="email" isDisabled={loading}>
                <FormLabel>Użytkownik</FormLabel>

                <AsyncSelect
                  value={user}
                  defaultOptions={true}
                  loadOptions={asyncSelectLoadOptions}
                  getOptionLabel={optionLabel}
                  getOptionValue={optionValue}
                  onChange={handleUserChange}
                />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Imię i nazwisko</FormLabel>
                <Input name="full_name" value={input?.full_name ?? ''} onChange={handleInputChange} />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Płeć</FormLabel>
                <Select
                  name="gender"
                  placeholder="Wybierz płeć"
                  onChange={handleInputChange}
                  value={input.gender || undefined}
                >
                  {Object.entries(genders).map(([k, v]) => (
                    <option key={k} value={k}>
                      {v}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Lata doświadczenia</FormLabel>
                <NumberInput value={input.years_of_exp ?? ''} onChange={handleYearsOfExpChange}>
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel htmlFor="short-description" mb={1} fontWeight={600}>
                  Krótki opis
                </FormLabel>
                <ContentEditor
                  id="admin-panel-edit-therapist-short-description"
                  variant="basic"
                  value={input?.short_description ?? initialEditorValue}
                  onChange={handleShortDescriptionChange}
                  readOnly={false}
                />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel htmlFor="full-description" mb={1} fontWeight={600}>
                  Pełny opis
                </FormLabel>
                <ContentEditor
                  id="admin-panel-edit-therapist-full-description"
                  variant="extended"
                  value={input?.full_description ?? initialEditorValue}
                  onChange={handleFullDescriptionChange}
                  readOnly={false}
                />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel mb={1} fontWeight={600}>
                  Opis meta
                </FormLabel>
                <Textarea
                  name="meta_description"
                  value={input?.meta_description ?? ''}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl isDisabled={loading}>
                <FormLabel>Indeks wyróżnienia</FormLabel>
                <NumberInput value={input.featured_index ?? ''} onChange={handleFeaturedChange}>
                  <NumberInputField />
                </NumberInput>
              </FormControl>
              <FormControl isDisabled={loading}>
                <Checkbox name="public" isChecked={input.public ?? false} onChange={handleInputChange}>
                  Publiczny
                </Checkbox>
              </FormControl>
            </Stack>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <HStack py={2} justifyContent="space-evenly">
            <Button isLoading={loading} onClick={handleUpsert}>
              Zapisz
            </Button>
            <Button colorScheme="blue" variant="ghost" onClick={onClose} isDisabled={loading}>
              Anuluj
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default TherapistEditorModal
