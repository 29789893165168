import * as React from 'react'

import {PhoneIcon} from '@chakra-ui/icons'
import {Button, Container, Flex, Link, Stack, Text, useBreakpointValue} from '@chakra-ui/react'

import {hotlinePhoneNumber} from '@/constants'

const activeStyle = {
  color: 'white',
}

const HotlineBar = () => (
  <Flex bg="brand.green.600" color="white" py={3.5}>
    <Container maxW="container.xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={['space-between', 'flex-end']}
        spacing={[2, null, 7]}
        fontWeight={[300, null, 600]}
      >
        <Text fontSize={['xs', null, 'sm']}>Masz jakieś pytania? Zadzwoń do nas</Text>
        <Text>
          <Button
            as={Link}
            color="white"
            variant="brandLink"
            leftIcon={<PhoneIcon />}
            href={`tel:${hotlinePhoneNumber.raw}`}
            _active={activeStyle}
            fontSize={['xs', null, 'sm']}
          >
            {hotlinePhoneNumber.displayed}
          </Button>
        </Text>
      </Stack>
    </Container>
  </Flex>
)

export default HotlineBar
