import * as React from 'react'

import {AspectRatio, Box, Button, Center, Flex, Image, Stack, Text} from '@chakra-ui/react'
import {MdClose} from 'react-icons/md'
import {Link, generatePath} from 'react-router-dom'

import {SupportGroupJoined} from '@/api/models'
import ContentEditor from '@/common/content-editor'
import {initialValue as initialEditorValue} from '@/common/content-editor/constants'
import {SUPPORT_GROUPS_LIST, SUPPORT_GROUP_SHOWCASE} from '@/router/paths'
import {formatWeeklyDuration} from '@/utils/string'

export const SupportGroupItem = ({
  item,
  direction = 'row',
  index = 0,
}: {
  item: SupportGroupJoined
  direction?: 'row' | 'column'
  index?: number
}) => {
  // TODO: add 'nowa grupa', 'zapisy trwają'
  const banner = React.useMemo(
    () =>
      item.has_access
        ? {title: 'Jesteś członkiem', bg: 'brand.yellow.500'}
        : item.occupied_slots >= item.max_members
        ? {title: 'Lista rezerwowych', bg: 'brand.yellow.500'}
        : null,
    [item]
  )

  const path = React.useMemo(
    () =>
      item.id ? generatePath(SUPPORT_GROUP_SHOWCASE, {id: item.id, slug: item.slug}) : SUPPORT_GROUPS_LIST,
    [item]
  )

  return (
    <Stack direction={['column', null, null, direction]} spacing={8} align="center">
      <Box
        as={Link}
        to={path}
        flex={1}
        w="100%"
        maxW="320px"
        position="relative"
        alignSelf={['center', null, 'flex-start']}
        borderRadius={(index - 2) % 4 && (index - 1) % 4 ? '0 60px' : '60px 0'}
        overflow="hidden"
        transition="all 250ms ease"
        _hover={{
          borderRadius: (index - 2) % 4 && (index - 1) % 4 ? '60px 0' : '0 60px',
        }}
      >
        <AspectRatio ratio={1}>
          {item.thumbnail ? (
            <Image src={item.thumbnail} objectFit="cover" />
          ) : (
            <Center bg="gray.600">
              <MdClose size={64} />
            </Center>
          )}
        </AspectRatio>
        {item.has_access && <Box position="absolute" top={0} w="100%" h="100%" bg="#3E3E3E" opacity={0.7} />}
        {banner && (
          <Box
            position="absolute"
            top={8}
            bg={banner.bg}
            color="white"
            py={2}
            px={8}
            minWidth="60%"
            maxWidth="90%"
            textAlign="center"
            boxShadow="0px 4px 4px 0px #00000040"
            borderBottomEndRadius="full"
          >
            {banner.title}
          </Box>
        )}
      </Box>

      <Stack flex={1}>
        <Text as="h2" fontWeight={500} fontSize="xl" noOfLines={[null, null, 2]}>
          {item.name}
        </Text>
        {!!item.first_meeting_duration && !!item.meetings_left && (
          <Stack spacing={0} fontWeight={300}>
            <Text>{formatWeeklyDuration(item.first_meeting_duration)}</Text>
            <Text>
              {item.meetings_left}{' '}
              {item.meetings_left === 1
                ? 'spotkanie'
                : item.meetings_left % 10 < 5 && item.meetings_left % 10 > 1
                ? 'spotkania'
                : 'spotkań'}
            </Text>
          </Stack>
        )}
        <Box flex={1} mb={[2, null, 0]}>
          <ContentEditor
            id={`support-groups-list-${item.id}`}
            variant="basic"
            value={item.short_description ?? initialEditorValue}
            readOnly={true}
          />
        </Box>
        <Flex justify="flex-end">
          <Button
            as={Link}
            to={path}
            variant="link"
            color="black"
            pb={2}
            borderBottomWidth="1px"
            borderBottomColor="black"
            rounded="none"
            marginBottom="1px"
            _hover={{
              borderBottomWidth: '2px',
              fontWeight: 500,
              marginBottom: '0',
            }}
          >
            Zobacz więcej
          </Button>
        </Flex>
      </Stack>
    </Stack>
  )
}
