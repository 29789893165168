import * as React from 'react'

import {Icon, IconProps} from '@chakra-ui/react'

const DownloadIcon = (props: IconProps) => (
  <Icon viewBox="0 0 20 17" {...props} fill="none">
    <path
      d="M0.625 11.125C0.79076 11.125 0.949732 11.1908 1.06694 11.3081C1.18415 11.4253 1.25 11.5842 1.25 11.75V14.875C1.25 15.2065 1.3817 15.5245 1.61612 15.7589C1.85054 15.9933 2.16848 16.125 2.5 16.125H17.5C17.8315 16.125 18.1495 15.9933 18.3839 15.7589C18.6183 15.5245 18.75 15.2065 18.75 14.875V11.75C18.75 11.5842 18.8158 11.4253 18.9331 11.3081C19.0503 11.1908 19.2092 11.125 19.375 11.125C19.5408 11.125 19.6997 11.1908 19.8169 11.3081C19.9342 11.4253 20 11.5842 20 11.75V14.875C20 15.538 19.7366 16.1739 19.2678 16.6428C18.7989 17.1116 18.163 17.375 17.5 17.375H2.5C1.83696 17.375 1.20107 17.1116 0.732233 16.6428C0.263392 16.1739 0 15.538 0 14.875V11.75C0 11.5842 0.065848 11.4253 0.183058 11.3081C0.300269 11.1908 0.45924 11.125 0.625 11.125Z"
      fill="currentColor"
    />
    <path
      d="M9.55731 13.5675C9.61537 13.6257 9.68434 13.6719 9.76027 13.7034C9.8362 13.7349 9.9176 13.7511 9.99981 13.7511C10.082 13.7511 10.1634 13.7349 10.2394 13.7034C10.3153 13.6719 10.3843 13.6257 10.4423 13.5675L14.1923 9.8175C14.3097 9.70014 14.3756 9.54097 14.3756 9.375C14.3756 9.20903 14.3097 9.04986 14.1923 8.9325C14.075 8.81514 13.9158 8.74921 13.7498 8.74921C13.5838 8.74921 13.4247 8.81514 13.3073 8.9325L10.6248 11.6163V0.625C10.6248 0.45924 10.559 0.300269 10.4418 0.183058C10.3245 0.065848 10.1656 0 9.99981 0C9.83405 0 9.67508 0.065848 9.55787 0.183058C9.44066 0.300269 9.37481 0.45924 9.37481 0.625V11.6163L6.69231 8.9325C6.57496 8.81514 6.41578 8.74921 6.24981 8.74921C6.08384 8.74921 5.92467 8.81514 5.80731 8.9325C5.68995 9.04986 5.62402 9.20903 5.62402 9.375C5.62402 9.54097 5.68995 9.70014 5.80731 9.8175L9.55731 13.5675Z"
      fill="currentColor"
    />
  </Icon>
)

export default DownloadIcon
