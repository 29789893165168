import React from 'react'

import {Spacer} from '@chakra-ui/layout'
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react'
import {
  createPluginFactory,
  insertNodes,
  PlateEditor,
  PlateRenderElementProps,
  usePlateEditorState,
} from '@udecode/plate'
import {MdQuestionAnswer, MdClose} from 'react-icons/md'
import {Transforms} from 'slate'
import {ReactEditor} from 'slate-react'
import {v4 as uuidv4} from 'uuid'

import {supabase} from '@/api'
import {GetSurveyDataResult, SetSurveyDataResult} from '@/api/models'
import useDebouncedEffect from '@/utils/use-debounced-effect'

import {SurveyElement, SurveyQuestion, SurveyQuestionSelect, SurveyQuestionText} from '../custom-types'
import {EditorContext} from '../editor-context'
import {BlockButton} from '../toolbar/buttons'

export const SurveyRenderer = ({attributes, children, element}: PlateRenderElementProps) => {
  const toast = useToast()

  const {bucketScope, readOnly} = React.useContext(EditorContext)

  const el = element as SurveyElement

  const editor = usePlateEditorState()!
  const path = ReactEditor.findPath(editor, element)

  const handleRemove = React.useCallback(() => {
    Transforms.removeNodes(editor, {at: path})
  }, [editor, path])

  const updateSurvey = React.useCallback(
    (survey: Partial<SurveyElement>) => {
      Transforms.setNodes(editor, survey, {at: path})
    },
    [editor, path]
  )
  const handleDeleteQuestion = React.useCallback(
    (index) => {
      const newQuestions = [...el.questions]
      newQuestions.splice(index, 1)
      updateSurvey({
        questions: newQuestions,
      })
    },
    [el, updateSurvey]
  )
  const handleCreateText = React.useCallback(() => {
    updateSurvey({
      questions: [
        ...el.questions,
        {
          id: uuidv4(),
          placeholder: '',
          question: '',
          type: 'text',
        },
      ],
    })
  }, [el.questions, updateSurvey])
  const handleCreateOptions = React.useCallback(() => {
    updateSurvey({
      questions: [
        ...el.questions,
        {
          id: uuidv4(),
          options: [],
          question: '',
          type: 'select',
        },
      ],
    })
  }, [el.questions, updateSurvey])
  const handleQuestionChange = React.useCallback(
    (index: number, question: SurveyQuestion) => {
      updateSurvey({
        questions: Object.assign([], el.questions, {[index]: question}),
      })
    },
    [el.questions, updateSurvey]
  )

  const [surveyState, setSurveyState] = React.useState<Record<string, string> | undefined>()
  const [userEdited, setUserEdited] = React.useState<boolean>(false)
  const handleChange = React.useCallback(
    (questionID: string, value: string) => {
      setSurveyState(Object.assign({}, surveyState || {}, {[questionID]: value}))
      if (!userEdited) {
        setUserEdited(true)
      }
    },
    [surveyState, setSurveyState, userEdited, setUserEdited]
  )
  React.useEffect(() => {
    ;(async () => {
      try {
        const {data, error} = await supabase.rpc('get_course_survey_data', {
          course_id: bucketScope,
          survey_id: el.id,
        })
        if (error) {
          throw error
        }
        if (!data) {
          throw new Error('Lack of response data')
        }
        const result = data as GetSurveyDataResult
        if (result.error) {
          throw result.error
        }
        if (!result.answers) {
          throw new Error('No answers')
        }
        setSurveyState(result.answers)
      } catch (e) {
        console.error('Failed to fetch survey data', e)
        toast({
          isClosable: true,
          status: 'error',
          title: 'Nie udało się pobrać danych ankiety.',
        })
      }
    })()
  }, [bucketScope, el.id, toast])

  const [saving, setSaving] = React.useState<boolean>(false)
  useDebouncedEffect(
    () => {
      ;(async () => {
        if (!userEdited) {
          return
        }

        try {
          setSaving(true)

          const {data, error} = await supabase.rpc('set_course_survey_data', {
            course_id: bucketScope,
            data: surveyState,
            survey_id: el.id,
          })
          if (error) {
            throw error
          }
          if (!data) {
            throw new Error('Lack of response data')
          }
          const result = data as SetSurveyDataResult
          if (result.error) {
            throw result.error
          }
          if (!result.success) {
            throw new Error('No success')
          }
        } catch (e) {
          console.warn('Failed to save a survey', e)
          toast({
            isClosable: true,
            status: 'error',
            title: 'Nie udało się zapisać ankiety.',
          })
        } finally {
          setSaving(false)
        }
      })()
    },
    [userEdited, surveyState, setSaving],
    250
  )

  return (
    <Flex direction="column" mb={4}>
      <Box contentEditable={false} userSelect="none">
        <Flex direction="row" alignItems="center">
          <Text size="lg" fontWeight="bold">
            Twój dziennik
            {readOnly && !surveyState && ` (${'Ładowanie...'.toLowerCase()})`}
            {saving && ` (${'Zapisywanie...'.toLowerCase()})`}
          </Text>
          <Spacer />
          {!readOnly && (
            <IconButton
              variant="outline"
              onClick={handleRemove}
              icon={<MdClose />}
              aria-label="Usuń ankietę"
            />
          )}
        </Flex>
        {el.questions.map((question, i) => (
          <SurveyQuestionRenderer
            key={i}
            question={question}
            onQuestionChange={(v) => handleQuestionChange(i, v)}
            onQuestionDelete={() => handleDeleteQuestion(i)}
            value={(surveyState || {})[question.id]}
            onChange={(v) => handleChange(question.id, v)}
            readOnly={readOnly}
          />
        ))}
        {!readOnly && (
          <Flex direction="row" alignItems="center">
            <Button size="sm" onClick={handleCreateText}>
              Tekst
            </Button>
            <Button size="sm" onClick={handleCreateOptions}>
              Opcje
            </Button>
          </Flex>
        )}
      </Box>
      <Text {...attributes}>{children}</Text>
    </Flex>
  )
}

type SurveyProps<T extends {}> = {
  question: T
  onQuestionChange: (question: T) => void
  onQuestionDelete: () => void

  value: any
  onChange: (v: any) => void

  readOnly: boolean
}
const SurveyQuestionRenderer = (props: SurveyProps<SurveyQuestion>) => {
  switch (props.question.type) {
    case 'select':
      return <SelectQuestionRenderer {...(props as SurveyProps<SurveyQuestionSelect>)} />
    case 'text':
      return <TextQuestionRenderer {...(props as SurveyProps<SurveyQuestionText>)} />
  }
}

const SelectQuestionRenderer = ({
  question,
  onQuestionChange,
  onQuestionDelete,
  value,
  onChange,
  readOnly,
}: SurveyProps<SurveyQuestionSelect>) => {
  const handleQuestionChange = React.useCallback(
    (e) => {
      onQuestionChange({
        ...question,
        question: e.target.value,
      })
    },
    [onQuestionChange, question]
  )
  const handleAddOption = React.useCallback(() => {
    onQuestionChange({
      ...question,
      options: [...question.options, ''],
    })
  }, [onQuestionChange, question])
  const handleOptionChange = React.useCallback(
    (index: number, value: string) => {
      onQuestionChange({
        ...question,
        options: Object.assign([], question.options, {[index]: value}),
      })
    },
    [onQuestionChange, question]
  )
  const handleOptionDelete = React.useCallback(
    (index: number) => {
      const newOptions = [...question.options]
      newOptions.splice(index)
      onQuestionChange({
        ...question,
        options: newOptions,
      })
    },
    [onQuestionChange, question]
  )

  return (
    <Flex mb={2} direction="column">
      {readOnly ? (
        <>
          <Text mb={2}>{question.question}</Text>
          <RadioGroup onChange={onChange} value={value}>
            <Stack direction="column" spacing={2}>
              {question.options.map((option, i) => (
                <Radio value={option} key={i}>
                  {option}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        </>
      ) : (
        <>
          <Divider mb={4} />
          <Stack direction="row" spacing={2} mb={2}>
            <Text flex={1}>Pytanie</Text>
            <Button size="sm" onClick={onQuestionDelete}>
              Usuń
            </Button>
          </Stack>
          <Input mb={2} size="sm" value={question.question} onChange={handleQuestionChange} />
          <Text mb={2}>Opcje</Text>
          {question.options.map((option, i) => (
            <Flex mb={2} direction="row" key={i} alignItems="center">
              <Input
                flexGrow={1}
                size="sm"
                value={option}
                onChange={(e) => handleOptionChange(i, e.target.value)}
              />
              <IconButton
                flexGrow={0}
                size="sm"
                ml={2}
                icon={<MdClose />}
                onClick={() => handleOptionDelete(i)}
                aria-label="Usuń opcję"
              />
            </Flex>
          ))}
          <Button size="sm" mb={2} onClick={handleAddOption}>
            Dodaj opcję
          </Button>
        </>
      )}
    </Flex>
  )
}

const TextQuestionRenderer = ({
  question,
  onQuestionChange,
  onQuestionDelete,
  value,
  onChange,
  readOnly,
}: SurveyProps<SurveyQuestionText>) => {
  const handleQuestionChange = React.useCallback(
    (e) => {
      onQuestionChange({
        ...question,
        question: e.target.value,
      })
    },
    [onQuestionChange, question]
  )
  const handlePlaceholderChange = React.useCallback(
    (e) => {
      onQuestionChange({
        ...question,
        placeholder: e.target.value,
      })
    },
    [onQuestionChange, question]
  )

  return (
    <Flex mb={2} direction="column">
      {readOnly ? (
        <>
          <Text mb={2}>{question.question}</Text>
          <Textarea
            mb={2}
            placeholder={question.placeholder}
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </>
      ) : (
        <>
          <Divider mb={4} />
          <Stack direction="row" spacing={2} mb={2}>
            <Text flex={1}>Pytanie</Text>
            <Button size="sm" onClick={onQuestionDelete}>
              Usuń
            </Button>
          </Stack>
          <Input mb={2} size="sm" value={question.question} onChange={handleQuestionChange} />
          <Text mb={2}>Podpowiedź pola</Text>
          <Input mb={2} size="sm" value={question.placeholder} onChange={handlePlaceholderChange} />
        </>
      )}
    </Flex>
  )
}

export const InsertSurveyButton = () => {
  const editor = usePlateEditorState()!

  const handleClick = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault()
      insertSurvey(editor, [])
    },
    [editor]
  )

  return (
    <BlockButton label="Dodaj ankietę" format="survey" icon={<MdQuestionAnswer />} onClick={handleClick} />
  )
}

const insertSurvey = (editor: PlateEditor, questions: SurveyQuestion[]) => {
  const text = {text: ''}
  const survey: SurveyElement = {children: [text], id: uuidv4(), questions, type: 'survey'}
  insertNodes<SurveyElement>(editor, survey)
}

export const createSurveyPlugin = createPluginFactory({
  component: SurveyRenderer,
  isElement: true,
  isVoid: true,
  key: 'survey',
  type: 'survey',
})
