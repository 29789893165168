import * as React from 'react'

import {
  HStack,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'

import {PGInterval} from '../../api/types'

type Props = {
  value: PGInterval
  onChange: (d: PGInterval) => void
  isDisabled?: boolean
}

const DurationPicker = ({value, onChange, isDisabled}: Props) => {
  const handleSecondsChange = React.useCallback(
    (seconds: string) => {
      if (+seconds === 60) {
        onChange({...value, minutes: value.minutes + 1, seconds: 0})
      } else {
        onChange({...value, seconds: +seconds})
      }
    },
    [value, onChange]
  )

  const handleMinutesChange = React.useCallback(
    (minutes: string) => {
      if (+minutes === 60) {
        onChange({...value, hours: value.hours + 1, minutes: 0})
      } else if (+minutes === -1) {
        onChange({...value, minutes: 0, seconds: 59})
      } else {
        onChange({...value, minutes: +minutes})
      }
    },
    [value, onChange]
  )

  const handleHoursChange = React.useCallback(
    (hours: string) => {
      if (+hours === -1) {
        onChange({...value, hours: 0, minutes: 59})
      } else {
        onChange({...value, hours: +hours})
      }
    },
    [value, onChange]
  )

  return (
    <HStack>
      <NumberInput
        step={1}
        min={-1}
        max={23}
        value={value.hours.toString()}
        onChange={handleHoursChange}
        isDisabled={isDisabled}
      >
        <InputGroup w="110px">
          <InputLeftAddon children="g" />
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </InputGroup>
      </NumberInput>
      <NumberInput
        step={1}
        min={-1}
        max={60}
        value={value.minutes.toString()}
        onChange={handleMinutesChange}
        isDisabled={isDisabled}
      >
        <InputGroup w="110px">
          <InputLeftAddon children="m" />
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </InputGroup>
      </NumberInput>
      <NumberInput
        step={1}
        min={0}
        max={60}
        value={value.seconds.toString()}
        onChange={handleSecondsChange}
        isDisabled={isDisabled}
      >
        <InputGroup w="110px">
          <InputLeftAddon children="s" />
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </InputGroup>
      </NumberInput>
    </HStack>
  )
}

export default DurationPicker
