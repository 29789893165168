import React from 'react'

import {supabase} from '@/api'
import {WebinarJoined, parseWebinarJoined} from '@/api/models'
import DataTable from '@/common/data-table'
import useTableState from '@/common/data-table/use-table-state'
import {deleteAction, editAction} from '@/common/data-table/utils'
import DeleteResourceModal from '@/common/delete-resource-modal'
import useSupabaseQuery from '@/common/use-custom-query'
import useValueDisclosure from '@/common/use-value-disclosure'

import {columns, defaultSelectedColumns, emptyWebinar, filterFields} from './constants'
import WebinarEditorModal from './editor-modal'
import {showReviewsAction} from './utils'

const Webinars = () => {
  const tableState = useTableState({descending: true, sortBy: 'created_at'})
  const {loading, data, fetch, rows} = useSupabaseQuery<WebinarJoined>({
    table: 'admin_webinars',
    fields: '*',
    pageNumber: tableState.page,
    descending: tableState.descending,
    itemsPerPage: tableState.rowsPerPage,
    order: tableState.sortBy.column as keyof WebinarJoined,
    filter: tableState.filter,
    parsingFunction: parseWebinarJoined,
  })

  const {
    value: deleteValue,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useValueDisclosure<WebinarJoined>()

  const {
    value: editorValue,
    onClose: onEditorClose,
    onOpen: onEditorOpen,
  } = useValueDisclosure<WebinarJoined>()

  const handleAdd = React.useCallback(() => {
    onEditorOpen(emptyWebinar)
  }, [onEditorOpen])

  const handleDelete = React.useCallback(async () => {
    const {error} = await supabase.rpc('delete_webinar', {
      meeting_id: deleteValue?.meeting,
    })
    if (error) throw error
  }, [deleteValue])

  const actions = React.useMemo(
    () => [deleteAction(onDeleteOpen), editAction(onEditorOpen), showReviewsAction],
    [onEditorOpen, onDeleteOpen]
  )

  return (
    <>
      <DataTable<WebinarJoined>
        columns={columns}
        data={data ?? []}
        totalCount={rows}
        tableState={tableState}
        onRefresh={fetch}
        loading={loading}
        actions={actions}
        onAdd={handleAdd}
        filterFields={filterFields}
        defaultSelectedColumns={defaultSelectedColumns}
      />
      <WebinarEditorModal
        item={editorValue}
        onClose={onEditorClose}
        open={!!editorValue}
        onComplete={fetch}
      />
      <DeleteResourceModal
        onDelete={handleDelete}
        onClose={onDeleteClose}
        open={!!deleteValue}
        onComplete={fetch}
      />
    </>
  )
}

export default Webinars
